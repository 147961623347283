const ADMIN_DEVICE_URL = 'shop/admin_device'
const ITEM_ALL = 'ITEM_ALL'
const ITEM_4BY3 = 'ITEM_4BY3'
const ITEM_ICON = 'ITEM_ICON'
const ITEM_DETAIL = 'ITEM_DETAIL'
const GLOBAL = 'GLOBAL'
const MOBILE = 'MOBILE'
const WECHAT_MP = 'WECHAT_MP'
const PAPER_TO_MOBILE = 'PAPER_TO_MOBILE'
const PAPER_TO_WECHAT_MP = 'PAPER_TO_WECHAT_MP'
const SPRING_BOARD = 'SPRING_BOARD'
const WEB = 'WEB'
const PACKAGE_ITEM = 'PACKAGE_ITEM'
const PACKAGE_SUB_ITEM = 'PACKAGE_SUB_ITEM'
const PREORDER_ITEM = 'PREORDER_ITEM'
const ECOUPON_ITEM = 'ECOUPON_ITEM'
const VALUE_UP_ECOUPON_ITEM = 'VALUE_UP_ECOUPON_ITEM'
const FREE_ECOUPON_ITEM = 'FREE_ECOUPON_ITEM'
const CATEGORY_ALL = 'CATEGORY_ALL'
const CATEGORY_ICON = 'CATEGORY_ICON'
const CATEGORY_4BY3 = 'CATEGORY_4BY3'
const CATEGORY_DETAIL = 'CATEGORY_DETAIL'
const FIXED_START_END = 'FIXED_START_END'
const FLEXIBLE_DAYS = 'FLEXIBLE_DAYS'
const PREORDER = 'PREORDER'
const TEXT = 'TEXT'
const SELECT = 'SELECT'
const AUTO_GENERATE = 'AUTO_GENERATE'
const AUTO_GENERATE_MEZZOFY = 'AUTO_GENERATE_MEZZOFY'
const CUSTOMIZE = 'CUSTOMIZE'
const CUSTOMIZE_TGR = 'CUSTOMIZE_TGR'
const CUSTOMIZE_MASS_CODE = 'CUSTOMIZE_MASS_CODE'
const WEB_CAMPAIGN_TITLE = 'WEB_CAMPAIGN_TITLE'
const WEB_CAMPAIGN_DESCRIPTION = 'WEB_CAMPAIGN_DESCRIPTION'
const GENERAL = 'GENERAL'
const DRAFT_VER = Number(process.env.REACT_APP_PRODUCT_DRAFT_VER)
export const GET_ID_FROM_PAYLOAD = 'GET_ID_FROM_PAYLOAD'

export const RESTAURANT_URL_TYPE = {
    UNIVERSAL: 'UNIVERSAL',
    DOWNLOAD_HTML: 'DOWNLOAD_HTML',
    OTHER: 'OTHER',
}

export const PICTURE_SIZE = {
    MID: 'pictures-mid-size',
}

export const SELECT_CATEGORY_TYPE = {
    ALL: 'ALL',
    PRODUCT: 'PRODUCT',
    FREE_ECOUPON: 'FREE_ECOUPON',
}

export const CONTACT_US_MESSAGE_TYPE = {
    MOBILE: 'MOBILE',
    MERCHANT: 'MERCHANT',
    WEB: 'WEB',
}

export const ENV = {
    LOCAL: 'LOCAL',
    DEVELOPMENT: 'DEVELOPMENT',
    PRODUCTION: 'PRODUCTION',
}

const CATEGORY = {
    categoryCode: '',
    codeDescription: '',
    channel: '',
    channelSave: '',
    tcName: '',
    enName: '',
    scName: '',
    scNameDisable: true,
    tcDescription: '',
    enDescription: '',
    scDescription: '',
    scDescriptionDisable: true,
    refCategoryCode: '',
    refCategoryTcName: '',
    refCategoryId: '',
    startDisplayTimestamp: 0,
    endDisplayTimestamp: 0,
    displaySequence: 0,
    imageList: [],
}

const PRODUCT = {
    productCode: 'Product_0',
    type: '',
    channel: '',
    displaySequence: 0,
    redeemGroupCode: '',
    settlementConfigId: '',
    systemGroupId: '',
    systemGroupName: '',
    brandCode: '',
    brandName: '',
    brandTcName: '',
    tcName: '',
    enName: '',
    scName: '',
    scNameDisable: true,
    tcDescription: '',
    enDescription: '',
    scDescription: '',
    scDescriptionDisable: true,
    tcTac: '',
    enTac: '',
    scTac: '',
    scTacDisable: true,
    tcRedeemDescription: '',
    enRedeemDescription: '',
    scRedeemDescription: '',
    scRedeemDescriptionDisable: true,
    tcDiscountDescription: '',
    enDiscountDescription: '',
    scDiscountDescription: '',
    scDiscountDescriptionDisable: true,
    tcTag: '',
    enTag: '',
    scTag: '',
    originalPrice: -1,
    sellingPrice: -1,
    startDisplayTimestamp: null,
    endDisplayTimestamp: null,
    personLimit: 50,
    quantityPerOrder: 50,
    dailyQuota: -1,
    bufferRedeemDay: 0,
    searchable: '',
    selfRedeem: '',
    selfRefund: false,
    shareable: '',
    packageCashCoupon: false,
    requireFillInContactPerson: '',
    requireFillInMobileNumber: '',
    requireFillInEmail: '',
    subProductMapList: [],
    imageList: [],
    lotInventoryList: [],
    blackoutPeriodList: [],
    attributeList: [],
}

export const REDEEM_GROUP = {
    redeemGroupCode: 'RedeemGroup_0',
    codeDescription: '',
    redeemMezzofy: false,
    selectLocation: false,
    selectDate: false,
    selectTime: false,
    type: '',
    startRedeemTimestamp: 0,
    endRedeemTimestamp: 0,
    startAfterDay: 0,
    endAfterDay: 366,
    redeemLocationList: [],
}

export const PRODUCT_TYPE = {
    ALL: true,
    ECOUPON_ITEM: true,
    PREORDER_ITEM: true,
    PACKAGE_ITEM: true,
    PACKAGE_SUB_ITEM: true,
    FREE_ECOUPON_ITEM: true,
}

export const BANNER_TYPE = {
    FOLDER: 'FOLDER',
    RESTAURANT: 'RESTAURANT',
    MERCHANT: 'MERCHANT',
    MERCHANT_LOGIN: 'MERCHANT_LOGIN',
    MERCHANT_LANDING: 'MERCHANT_LANDING',
    RESTAURANT_TAG: 'RESTAURANT_TAG',
    TICKET: 'TICKET',
    LANDING_ALL: 'LANDING_ALL',
    LANDING_MAIN: 'LANDING_MAIN',
    LANDING_QUEUE: 'LANDING_QUEUE',
    LANDING_TAKEAWAY: 'LANDING_TAKEAWAY',
    LANDING_RESERVATION: 'LANDING_RESERVATION',
    LANDING_APPOINTMENT: 'LANDING_APPOINTMENT',
    LANDING_BANQUET: 'LANDING_BANQUET',
    LANDING_RACK_PRODUCT: 'LANDING_RACK_PRODUCT',
    LANDING_ECOUPON: 'LANDING_ECOUPON',
    EXPRESS_TICKET: 'EXPRESS_TICKET',
}

export const BANNER_ACTION = {
    NONE: 'NONE',
    DOWNLOAD: 'DOWNLOAD',
    MERCHANT_NONE: 'MERCHANT_NONE',
    WEB_VIEW: 'WEB_VIEW',
    MERCHANT_WEB_VIEW: 'MERCHANT_WEB_VIEW',
    LINK: 'LINK',
    MERCHANT_LINK: 'MERCHANT_LINK',
    PIXEL: 'PIXEL',
    VIDEO: 'VIDEO',
    PRODUCT: 'PRODUCT',
    PRODUCT_CATEGORY: 'PRODUCT_CATEGORY',
    CATEGORY: 'CATEGORY',
    BRAND: 'BRAND',
    PRODUCT_BRAND: 'PRODUCT_BRAND',
    CATEGORY_PRODUCT: 'CATEGORY_PRODUCT',
    CATEGORY_PRODUCT_MAP: 'CATEGORY_PRODUCT_MAP',
    BRAND_PRODUCT: 'BRAND_PRODUCT',
    BRAND_PRODUCT_MAP: 'BRAND_PRODUCT_MAP',
    FOLDER: 'FOLDER',
    RESTAURANT: 'RESTAURANT',
    SEARCH: 'SEARCH',
    PRODUCT_COUPON_CATEGORY_MAP: 'PRODUCT_COUPON_CATEGORY_MAP',
    PRODUCT_COUPON_BRAND_MAP: 'PRODUCT_COUPON_BRAND_MAP',
    E_COUPON: 'E_COUPON',
    E_COUPON_CATEGORY: 'E_COUPON_CATEGORY',
    E_COUPON_BRAND: 'E_COUPON_BRAND',
}

export const LANDING_ACTION_PARAMETER = {
    WEB_VIEW: 'WEB_VIEW',
    LINK: 'LINK',
    PIXEL: 'PIXEL',
    VIDEO: 'VIDEO',
    PRODUCT: 'PRODUCT',
    PRODUCT_CATEGORY: 'PRODUCT_CATEGORY',
    CATEGORY: 'CATEGORY',
    BRAND: 'BRAND',
    PRODUCT_BRAND: 'PRODUCT_BRAND',
    CATEGORY_PRODUCT: 'CATEGORY_PRODUCT',
    CATEGORY_PRODUCT_MAP: 'CATEGORY_PRODUCT_MAP',
    BRAND_PRODUCT: 'BRAND_PRODUCT',
    BRAND_PRODUCT_MAP: 'BRAND_PRODUCT_MAP',
    FOLDER: 'FOLDER',
    RESTAURANT: 'RESTAURANT',
    SEARCH: 'SEARCH',
    PRODUCT_COUPON_CATEGORY_MAP: 'PRODUCT_COUPON_CATEGORY_MAP',
    PRODUCT_COUPON_BRAND_MAP: 'PRODUCT_COUPON_BRAND_MAP',
    E_COUPON: 'E_COUPON',
    E_COUPON_CATEGORY: 'E_COUPON_CATEGORY',
    E_COUPON_BRAND: 'E_COUPON_BRAND',
    RESTAURANT_CATEGORY: 'RESTAURANT_CATEGORY',
    YOUTUBE: 'YOUTUBE',
    COUPON: 'COUPON',
}

export const LANDING_SECTION_ACTION = {
    NONE: 'NONE',
    MERCHANT_NONE: 'MERCHANT_NONE',
    WEB_VIEW: 'WEB_VIEW',
    MERCHANT_WEB_VIEW: 'MERCHANT_WEB_VIEW',
    LINK: 'LINK',
    MERCHANT_LINK: 'MERCHANT_LINK',
    PIXEL: 'PIXEL',
    VIDEO: 'VIDEO',
    PRODUCT: 'PRODUCT',
    PRODUCT_CATEGORY: 'PRODUCT_CATEGORY',
    CATEGORY: 'CATEGORY',
    BRAND: 'BRAND',
    PRODUCT_BRAND: 'PRODUCT_BRAND',
    CATEGORY_PRODUCT: 'CATEGORY_PRODUCT',
    CATEGORY_PRODUCT_MAP: 'CATEGORY_PRODUCT_MAP',
    BRAND_PRODUCT: 'BRAND_PRODUCT',
    BRAND_PRODUCT_MAP: 'BRAND_PRODUCT_MAP',
    FOLDER: 'FOLDER',
    RESTAURANT: 'RESTAURANT',
    SEARCH: 'SEARCH',
    PRODUCT_COUPON_CATEGORY_MAP: 'PRODUCT_COUPON_CATEGORY_MAP',
    PRODUCT_COUPON_BRAND_MAP: 'PRODUCT_COUPON_BRAND_MAP',
    E_COUPON: 'E_COUPON',
    E_COUPON_CATEGORY: 'E_COUPON_CATEGORY',
    E_COUPON_BRAND: 'E_COUPON_BRAND',
    COUPON: 'COUPON',
    ROTATING_BANNER: 'ROTATING_BANNER',
    RESTAURANT_CATEGORY: 'RESTAURANT_CATEGORY',
    SEARCH_NEAR_BY: 'SEARCH_NEAR_BY',
    MY_TICKET: 'MY_TICKET',
    BOOKMARK: 'BOOKMARK',
    // SEARCH_NEAR_BY: "SEARCH_NEAR_BY",
    TRANSFORM_TICKET_FROM_PAPER: 'TRANSFORM_TICKET_FROM_PAPER',
    TRANSFORM_ECOUPON_FROM_PAPER: 'TRANSFORM_ECOUPON_FROM_PAPER',
    EXPRESS_TICKET_VOUCHER: 'EXPRESS_TICKET_VOUCHER',
    SHARE: 'SHARE',
    GIFT: 'GIFT',
    SCAN_QRCODE: 'SCAN_QRCODE',
    LANDING: 'LANDING',
    SPRINGBOARD: 'SPRINGBOARD',
    DOWNLOAD: 'DOWNLOAD',
    // SEARCH: 'SEARCH',
    // FOLDER: 'FOLDER',
    // RESTAURANT: 'RESTAURANT',
    // RESTAURANT_CATEGORY: 'RESTAURANT_CATEGORY',
    // PRODUCT: 'PRODUCT',
    // COUPON: 'COUPON',
    // WEB_VIEW: 'WEB_VIEW',
    HYPERLINK: 'HYPERLINK',
    // ROTATING_BANNER: 'ROTATING_BANNER',
    SEARCH_SUGGESTION: 'SEARCH_SUGGESTION',
    YOUTUBE: 'YOUTUBE',
    HISTORICAL_SHOP: 'HISTORICAL_SHOP',
}

export const LANDING_SECTION_SERVICE_TYPE = {
    EXPRESS_TICKET: 'EXPRESS_TICKET',
    QUEUE: 'QUEUE',
    TAKEAWAY: 'TAKEAWAY',
    RACK_PRODUCT: 'RACK_PRODUCT',
    RESERVE: 'RESERVE',
    APPOINTMENT: 'APPOINTMENT',
    ECOUPON: 'ECOUPON',
}

export const LANDING_SECTION_SECTION_TYPE = {
    HEADER_ITEMS: 'HEADER_ITEMS',
    ITEMS: 'ITEMS',
    ADS: 'ADS',
}

export const LANDING_ACTION = {
    NONE: 'NONE',
    MERCHANT_NONE: 'MERCHANT_NONE',
    WEB_VIEW: 'WEB_VIEW',
    MERCHANT_WEB_VIEW: 'MERCHANT_WEB_VIEW',
    LINK: 'LINK',
    MERCHANT_LINK: 'MERCHANT_LINK',
    PIXEL: 'PIXEL',
    VIDEO: 'VIDEO',
    PRODUCT: 'PRODUCT',
    PRODUCT_CATEGORY: 'PRODUCT_CATEGORY',
    CATEGORY: 'CATEGORY',
    BRAND: 'BRAND',
    PRODUCT_BRAND: 'PRODUCT_BRAND',
    CATEGORY_PRODUCT: 'CATEGORY_PRODUCT',
    CATEGORY_PRODUCT_MAP: 'CATEGORY_PRODUCT_MAP',
    BRAND_PRODUCT: 'BRAND_PRODUCT',
    BRAND_PRODUCT_MAP: 'BRAND_PRODUCT_MAP',
    FOLDER: 'FOLDER',
    RESTAURANT: 'RESTAURANT',
    SEARCH: 'SEARCH',
    PRODUCT_COUPON_CATEGORY_MAP: 'PRODUCT_COUPON_CATEGORY_MAP',
    PRODUCT_COUPON_BRAND_MAP: 'PRODUCT_COUPON_BRAND_MAP',
    E_COUPON: 'E_COUPON',
    E_COUPON_CATEGORY: 'E_COUPON_CATEGORY',
    E_COUPON_BRAND: 'E_COUPON_BRAND',
    COUPON: 'COUPON',
    ROTATING_BANNER: 'ROTATING_BANNER',
    RESTAURANT_CATEGORY: 'RESTAURANT_CATEGORY',
    SEARCH_NEAR_BY: 'SEARCH_NEAR_BY',
    MY_TICKET: 'MY_TICKET',
    BOOKMARK: 'BOOKMARK',
    TRANSFORM_TICKET_FROM_PAPER: 'TRANSFORM_TICKET_FROM_PAPER',
    TRANSFORM_ECOUPON_FROM_PAPER: 'TRANSFORM_ECOUPON_FROM_PAPER',
    EXPRESS_TICKET_VOUCHER: 'EXPRESS_TICKET_VOUCHER',
    SHARE: 'SHARE',
    GIFT: 'GIFT',
    SCAN_QRCODE: 'SCAN_QRCODE',
    LANDING: 'LANDING',
    SPRINGBOARD: 'SPRINGBOARD',
    DOWNLOAD: 'DOWNLOAD',
    HYPERLINK: 'HYPERLINK',
    SEARCH_SUGGESTION: 'SEARCH_SUGGESTION',
    YOUTUBE: 'YOUTUBE',
    HISTORICAL_SHOP: 'HISTORICAL_SHOP',
}

export const LANDING_ITEM_ACTION = {
    NONE: 'NONE',
    MERCHANT_NONE: 'MERCHANT_NONE',
    WEB_VIEW: 'WEB_VIEW',
    MERCHANT_WEB_VIEW: 'MERCHANT_WEB_VIEW',
    LINK: 'LINK',
    MERCHANT_LINK: 'MERCHANT_LINK',
    PIXEL: 'PIXEL',
    VIDEO: 'VIDEO',
    PRODUCT: 'PRODUCT',
    PRODUCT_CATEGORY: 'PRODUCT_CATEGORY',
    CATEGORY: 'CATEGORY',
    BRAND: 'BRAND',
    PRODUCT_BRAND: 'PRODUCT_BRAND',
    CATEGORY_PRODUCT: 'CATEGORY_PRODUCT',
    CATEGORY_PRODUCT_MAP: 'CATEGORY_PRODUCT_MAP',
    BRAND_PRODUCT: 'BRAND_PRODUCT',
    BRAND_PRODUCT_MAP: 'BRAND_PRODUCT_MAP',
    FOLDER: 'FOLDER',
    RESTAURANT: 'RESTAURANT',
    SEARCH: 'SEARCH',
    PRODUCT_COUPON_CATEGORY_MAP: 'PRODUCT_COUPON_CATEGORY_MAP',
    PRODUCT_COUPON_BRAND_MAP: 'PRODUCT_COUPON_BRAND_MAP',
    E_COUPON: 'E_COUPON',
    E_COUPON_CATEGORY: 'E_COUPON_CATEGORY',
    E_COUPON_BRAND: 'E_COUPON_BRAND',
    COUPON: 'COUPON',
    MY_TICKET: 'MY_TICKET',
    BOOKMARK: 'BOOKMARK',
    SEARCH_NEAR_BY: 'SEARCH_NEAR_BY',
    TRANSFORM_TICKET_FROM_PAPER: 'TRANSFORM_TICKET_FROM_PAPER',
    TRANSFORM_ECOUPON_FROM_PAPER: 'TRANSFORM_ECOUPON_FROM_PAPER',
    EXPRESS_TICKET_VOUCHER: 'EXPRESS_TICKET_VOUCHER',
    SHARE: 'SHARE',
    GIFT: 'GIFT',
    SCAN_QRCODE: 'SCAN_QRCODE',
    LANDING: 'LANDING',
    SPRINGBOARD: 'SPRINGBOARD',
    DOWNLOAD: 'DOWNLOAD',
}

export const LANDING_ITEM_TYPE = {
    PRODUCT: 'PRODUCT',
    VALUE_UP_ECOUPON: 'VALUE_UP_ECOUPON',
    PRODUCT_LARGE: 'PRODUCT_LARGE',
    BANNER_IMAGE: 'BANNER_IMAGE',
    LARGE_ROUND_IMAGE: 'LARGE_ROUND_IMAGE',
    LARGE_ROUND_IMAGE_AD: 'LARGE_ROUND_IMAGE_AD',
    SQUARE_ROUND_IMAGE: 'SQUARE_ROUND_IMAGE',
    SQUARE_ROUND_IMAGE_AD: 'SQUARE_ROUND_IMAGE_AD',
    LARGE_ROUND_IMAGE_TEXT: 'LARGE_ROUND_IMAGE_TEXT',
    LARGE_ROUND_IMAGE_TEXT_AD: 'LARGE_ROUND_IMAGE_TEXT_AD',
    COUPON: 'COUPON',
    IMAGE: 'IMAGE',
    IMAGE_AD: 'IMAGE_AD',
    IMAGE_TEXT: 'IMAGE_TEXT',
    IMAGE_TEXT_AD: 'IMAGE_TEXT_AD',
    TICKET_IMAGE: 'TICKET_IMAGE',
    TICKET_IMAGE_AD: 'TICKET_IMAGE_AD',
    TICKET_IMAGE_TEXT: 'TICKET_IMAGE_TEXT',
    TICKET_IMAGE_TEXT_AD: 'TICKET_IMAGE_TEXT_AD',
    RECOMMEND_BY_LOCATION: 'RECOMMEND_BY_LOCATION',
}

export const SYSTEM_USER = {
    USER_ROLE: {
        ROLE_ADMIN: 'ROLE_ADMIN',
        ROLE_USER: 'ROLE_USER',
        ROLE_MANAGER: 'ROLE_MANAGER',
        ROLE_REST: 'ROLE_REST',
        ROLE_COUPON: 'ROLE_COUPON',
        ROLE_SYSTEM_ADMIN: 'ROLE_SYSTEM_ADMIN',
    },
    PRIVILEGE_ATTRIBUTE: {
        S: 'S',
        G: 'G',
        SINGLE: 'S-SINGLE',
        GROUP: 'G-GROUP',
    },
    APIGROUP: {
        KAISHING: 'KAISHING',
        MAXIMS_ONE: 'MAXIMS_ONE',
        LINK: 'LINK',
        CHINACHEM: 'CHINACHEM',
        WECHAT: 'Wechat',
        SHKP: 'SHKP',
    },
}

export const BANNER_PRODUCT_PARENT = {
    NONE: 'NONE',
    BRAND: 'BRAND',
    CATEGORY: 'CATEGORY',
}

export const URL_MAP_ACTION_TYPE = {
    CUSTOM_URL: 'CUSTOM_URL',
    WEB_VIEW: 'WEB_VIEW',
    LINK: 'LINK',
    PIXEL: 'PIXEL',
    VIDEO: 'VIDEO',
    PRODUCT_CATEGORY: 'PRODUCT_CATEGORY',
    PRODUCT: 'PRODUCT',
    CATEGORY_PRODUCT: 'CATEGORY_PRODUCT',
    CATEGORY_PRODUCT_MAP: 'CATEGORY_PRODUCT_MAP',
    CATEGORY: 'CATEGORY',
    BRAND: 'BRAND',
    PRODUCT_BRAND: 'PRODUCT_BRAND',
    BRAND_PRODUCT: 'BRAND_PRODUCT',
    BRAND_PRODUCT_MAP: 'BRAND_PRODUCT_MAP',
    FOLDER: 'FOLDER',
    REST: 'REST',
    SEARCH: 'SEARCH',
    BOOKMARK: 'BOOKMARK',
    SEARCH_NEAR_BY: 'SEARCH_NEAR_BY',
    MY_TICKET: 'MY_TICKET',
    TRANSFORM_TICKET_FROM_PAPER: 'TRANSFORM_TICKET_FROM_PAPER',
    TRANSFORM_ECOUPON_FROM_PAPER: 'TRANSFORM_ECOUPON_FROM_PAPER',
    EXPRESS_TICKET_VOUCHER: 'EXPRESS_TICKET_VOUCHER',
    SHARE: 'SHARE',
    GIFT: 'GIFT',
    ECOUPON: 'ECOUPON',
    SCAN_QRCODE: 'SCAN_QRCODE',
    LANDING: 'LANDING',
    SPRINGBOARD: 'SPRINGBOARD',
    DOWNLOAD: 'DOWNLOAD',
    E_COUPON: 'E_COUPON',
    E_COUPON_CATEGORY: 'E_COUPON_CATEGORY',
    E_COUPON_BRAND: 'E_COUPON_BRAND',
    PRODUCT_COUPON_CATEGORY_MAP: 'PRODUCT_COUPON_CATEGORY_MAP',
    PRODUCT_COUPON_BRAND_MAP: 'PRODUCT_COUPON_BRAND_MAP',
}

export const QUEUE_CAMPAIGN_TYPE = {
    NONE: 'NONE',
    CHECKIN: 'CHECKIN',
    CHECKIN_WITH_SIZE: 'CHECKIN_WITH_SIZE',
    RESOURCES_REGISTRATION: 'RESOURCES_REGISTRATION',
}

export const TNC_TYPE = {
    BANQUET: 'BANQUET',
    BEAUTY_RESERVE: 'BEAUTY_RESERVE',
    BEAUTY_RES_CHARGE: 'BEAUTY_RES_CHARGE',
    H5_TAKEAWAY: 'H5_TAKEAWAY',
    H5_TAK_CHARGE: 'H5_TAK_CHARGE',
    MASK_CHECKIN: 'MASK_CHECKIN',
    MERCHANT_TICKET_TEMPLATE: 'MERCHANT_TICKET_TEMPLATE',
    PRODUCT_TEMPLATE: 'PRODUCT_TEMPLATE',
    PRODUCT_TEMPLATE_ECOUPON: 'PRODUCT_TEMPLATE_ECOUPON',
    PRODUCT_TEMPLATE_PRODUCT: 'PRODUCT_TEMPLATE_PRODUCT',
    PRODUCT_TEMPLATE_PUSH_MESSAGE: 'PRODUCT_TEMPLATE_PUSH_MESSAGE',
    PRODUCT_TEMPLATE_VALUE_UP_ECOUPON: 'PRODUCT_TEMPLATE_VALUE_UP_ECOUPON',
    PRODUCT_TEMPLATE_VALUE_UP_ECOUPON_OLD: 'PRODUCT_TEMPLATE_VALUE_UP_ECOUPON_OLD',
    RESERVE: 'RESERVE',
    RESERVE2: 'RESERVE2',
    RESERVE_D: 'RESERVE_D',
    RES_CHARGE: 'RES_CHARGE',
    TAKEAWAY: 'TAKEAWAY',
    TAKEAWAY_MESSAGE: 'TAKEAWAY_MESSAGE',
    TAK_CHARGE: 'TAK_CHARGE',
    TICKET: 'TICKET',
    TICKET2: 'TICKET2',
    TICKET_D: 'TICKET_D',
    TICKET_LEFT_REMIND: 'TICKET_LEFT_REMIND',
    TICKET_NOW: 'TICKET_NOW',
    TICKET_WEB: 'TICKET_WEB',
    TIC_CFM_1: 'TIC_CFM_1',
    TIC_CFM_FIN: 'TIC_CFM_FIN',
    TIC_CHECKIN: 'TIC_CHECKIN',
    TIC_CHECKIN_INVITEE: 'TIC_CHECKIN_INVITEE',
    TIC_INACT: 'TIC_INACT',
    TMID_ECOUPON_FIRST: 'TMID_ECOUPON_FIRST',
    TMID_ECOUPON_REMARK: 'TMID_ECOUPON_REMARK',
    WEB: 'WEB',
}

export const EMAIL_TEMPLATE_TYPE = {
    MERCHANT_EMAIL_CREATE_ACCOUNT_APPROVAL: 'merchant_email_create_account_approval',
    MERCHANT_EMAIL_FORGOT_PASSWORD: 'merchant_email_forgot_password',
    MERCHANT_EMAIL_SALES_CONTRACT_TEST: 'merchant_email_sales_contract_test',
    MERCHANT_EMAIL_SET_PASSWORD: 'merchant_email_set_password',
    MERCHANT_EMAIL_VERIFICATION: 'merchant_email_verification',
    MERCHANT_HSBC_CREATE_SHOP_NOTIFY: 'merchant_hsbc_create_shop_notify',
    MERCHANT_HSBC_EMAIL_VERIFICATION: 'merchant_hsbc_email_verification',
    MERCHANT_PUBLISH_PRODUCT_PASSCODE: 'merchant_publish_product_passcode',
    MERCHANT_YEDPAY_REGISTRAION: 'merchant_yedpay_registraion',
    MX_API: 'MX_API',
    PRODUCT_TRN_REC: 'PRODUCT_TRN_REC',
    QUEUE_TICKET: 'QUEUE_TICKET',
    RESERVATION_TICKET: 'RESERVATION_TICKET',
    RESET_PASSWORD: 'reset_password',
    RSV_TRN_REC: 'RSV_TRN_REC',
    RSV_UPDATE_NOTIFY: 'RSV_UPDATE_NOTIFY',
    SELF_REGISTER: 'SELF_REGISTER',
    SELF_REGISTER_NOTIFY: 'SELF_REGISTER_NOTIFY',
    TAKEAWAY_ACCEPT: 'takeaway_accept',
    TAKEAWAY_ITEM: 'takeaway_item',
    TAKEAWAY_REJECT: 'takeaway_reject',
    TAKEAWAY_SUBMIT: 'takeaway_submit',
    TKY_TRN_REC: 'TKY_TRN_REC',
    RACK_PRODUCT_ORDER: 'RACK_PRODUCT_ORDER',
}

export const TABLE_TYPE_DEFAULT_VALUE = {
    defaultStatus: 'A',
    tableType: '',
    tableTypeTcLabel: null,
    tableTypeEnLabel: null,
    tableTypeScLabel: null,
    disableLabel: true,
    tableTypeMobileTcLabel: null,
    tableTypeMobileEnLabel: null,
    tableTypeMobileScLabel: null,
    tableTypeTicketEnLabel: null,
    tableTypeTicketScLabel: null,
    tableTypeTicketTcLabel: null,
    disableMobileLabel: true,
    disableGroupedSection: true,
    disableGroupedSectionDescription: true,
    disableTicketLabel: true,
    tableTypeForegroundColor: null,
    tableTypeBackgroundColor: null,
    minSize: null,
    maxSize: null,
    cutOffMinute: null,
    sizeSelectable: true,
    ticketType: null,
    ticketTypeTcLabel: null,
    ticketTypeEnLabel: null,
    ticketTypeScLabel: null,
    nextRemind: 5,
    dailyQuota: -1,
    personalQuota: 1,
    mobileDisplay: true,
    mobileTagDisplay: true,
    mobileReadOnly: false,
    kioskDisplay: true,
    kioskTagDisplay: true,
    kioskReadOnly: false,
    groupedSection: null,
    groupedSectionEnDescription: null,
    groupedSectionTcDescription: null,
    groupedSectionScDescription: null,
    groupedSectionEnLabel: null,
    groupedSectionTcLabel: null,
    groupedSectionScLabel: null,
    displaySequence: 0,
    voiceCode: null,
}

export const RESERVATION_TABLE_TYPE_LIST_INPUT = {
    tableType: '',
    reservationType: 'TABLE',
    minSize: null,
    maxSize: null,
    autoAcceptFlag: 'A',
    autoRejectFlag: 'A',
    limitMinute: null,
    quota: 0,
    cutOffHour: 2,
    cutOffMinute: 0,
    reminderMinute: null,
    defaultStatus: 'A',
}

export const QUEUE_TEMPLATE_MAP_TYPE = {
    DAY_OF_WEEK: 'DAY_OF_WEEK',
    DATE_RANGE: 'DATE_RANGE',
}

export const RESERVATION_TEMPLATE_MAP_TYPE = {
    DAY_OF_WEEK: 'DAY_OF_WEEK',
    DATE_RANGE: 'DATE_RANGE',
}

export const MERCHANT_USER_ROLE = {
    ROLE_MERCHANT_USER: 'ROLE_MERCHANT_USER',
    ROLE_MERCHANT_USER_GUEST: 'ROLE_MERCHANT_USER_GUEST',
    ROLE_MERCHANT_API: 'ROLE_MERCHANT_API',
    ROLE_MERCHANT_REPORT_USER: 'ROLE_MERCHANT_REPORT_USER',
}

export const PRIVILEGE_ATTRIBUTE = {
    SHOP: 'SHOP',
    GROUP: 'GROUP',
}

export const MERCHANT_DEVICE_TYPE = {
    PR: 'pr',
    STAFF_PR: 'STAFF_PR',
    KIOSK: 'kiosk',
    TV: 'tv',
    COUNTER_KIOSK: 'counterKiosk',
    COUNTER_TV: 'counterTv',
    PICKUP_TV: 'pickupTV',
    CAMPAIGN_QUEUE: 'campaignQueue',
    TAILOR_MADE: 'tailorMade',
    MALL_KIOSK: 'mallKiosk',
    SLIDESHOW_PLAYER: 'slideshowPlayer',
    FALLBACK: 'fallback',
    MALL_TV: 'mallTv',
}

export const STAFF_ROLE = {
    ROLE_MANAGER: 'ROLE_MANAGER',
    ROLE_OPERATOR: 'ROLE_OPERATOR',
}

export const PRINT_TEMPLATE_TYPE = {
    FONT_SIZE: 0,
    ALIGNMENT: 2,
    PRINT_TEXT: 3,
    PRINT_IMAGE: 4,
    PRINT_QR_CODE: 5,
    FONT_SIZE_DIRECT: 6,
    PRINT_BAR_CODE: 7,
    // PRINT_BAR_CODE_THICK: 71,
    PRINT_TEXT_AS_IMAGE: 9,
    // PRINT_BAR_CODE_128: 10,
    FONT_STYLE: 11,
    DATE_FORMAT: 13,
    LOCALE: 14,
    PRINT_TEXT_AS_IMAGE_NO_LINE_WRAP: 15,
    LINE_FEED: 16,

    TERMS_AND_CONDITIONS: 'TERMS_AND_CONDITIONS',
    LOGO: 'LOGO',
    FOOTER: 'FOOTER',
    HEADER: 'HEADER',
}

export const constants = {
    PAGE: {
        PERPAGE: 25,
        PER_PAGE_10: 10,
    },
    MAP: {
        LAT: 22.29376230644168,
        LNG: 114.18574867444669,
        ZOOM: 17,
    },
    APIURL: {
        URL_MS: process.env.REACT_APP_API_URL_ADMIN_MS,
        URL_ADMIN: process.env.REACT_APP_API_URL_ADMIN_MERCHANT,
    },
    BOOLEAN: {
        TRUE: true,
        FALSE: false,
    },
    ACTION_KEY: {
        PRODUCT: {
            CAT: 'CAT',
            REDEEM: 'REDEEM',
            REDEEM_INFO: 'REDEEM_INFO',
            PRODUCT_INFO: 'PRODUCT_INFO',
            DRAFT: 'DRAFT',
            PRODUCT: 'PRODUCT',
            LOT_INVENTORY: 'LOT_INVENTORY',
            LOT_INVENTORY_SUB: 'LOT_INVENTORY_SUB',
        },
        SELECT_TAG: {
            DRAFT: 'DRAFT',
        },
    },
    LANG: {
        TC: 'TC',
        EN: 'EN',
        SC: 'SC',
    },
    I18N_OBJ_LANG: {
        EN: 'EN',
        ZH_CN: 'ZH_CN',
        ZH_HK: 'ZH_HK',
        ZH_TW: 'ZH_TW',
    },
    PATH: {
        SHOP: {
            RESTAURANT: 'shop/restaurant',
            SYSTEM_GROUP_RESTAURANT: 'shop/system_group_restaurant',
            URL_MAP: 'url_map',
            STATUS: 'shop/restaurant_status',
            ADMIN_DEVICE: ADMIN_DEVICE_URL,
            ADMIN_DEVICE_DEACTIVATE: ADMIN_DEVICE_URL + '/deactivate',
            RESTAURANT_BANNER: 'shop/restaurant_banner',
            RESTAURANT_BANNER_LIST: 'shop/restaurant_banner_list',
            RESTAURANT_CLONE: 'shop/clone',
            CLEAR_RESTAURANT_CACHE: 'shop/clear_redis_cache',
            CLEAR_RESTAURANT_REDIS_CACHE: 'shop/clear_redis_cache/site_detail',
            UPLOAD_RESTAURANT_MEDIA: 'media/upload_restaurant_medias/',
            RESTAURANT_AREA: 'shop/restaurant_area',
            RESTAURANT_AREA_OPTION: 'shop/restaurant_area_options',
            RESTAURANT_AREA_DISTRICT_MAP: 'shop/restaurant_area_district_map',
            RESTAURANT_DISTRICT: 'shop/restaurant_district',
            RESTAURANT_DISTRICT_WRAPPER: 'shop/restaurant_district_wrapper',
            RESTAURANT_DISTRICT_MAP_REST_LIST: 'shop/restaurant_district_restaurant_map',
            RESTAURANT_DISTRICT_LANDMARK_MAP_LIST: 'shop/restaurant_district_landmark_map',

            RESTAURANT_LANDMARK: 'shop/restaurant_landmark',
            RESTAURANT_LANDMARK_DISTRICT_MAP: 'shop/restaurant_landmark_district_map',
            RESTAURANT_LANDMARK_DISTRICT_MAP_LIST: 'shop/restaurant_landmark_district_map_list',
            RESTAURANT_LANDMARK_DISTRICT_LIST: 'shop/restaurant_landmark_district',

            //----------------------Landmark Restaurant------------------------
            RESTAURANT_LANDMARK_RESTAURANT_MAP: 'shop/restaurant_landmark_restaurant_map',
            RESTAURANT_LANDMARK_RESTAURANT_MAP_LIST: 'shop/restaurant_landmark_restaurant_map_list',
            RESTAURANT_LANDMARK_RESTAURANT: 'shop/restaurant_landmark_restaurant',
            //----------------------Landmark Restaurant------------------------

            REGION: 'shop/region',

            BRAND: 'shop/restaurant_brand',
            UPLOAD_BRAND_IMAGE: 'shop/upload_brand_images',
            BRAND_GROUP: 'shop/restaurant_brand_group',
            CAMPAIGN_DESCRIPTION: 'shop/campaign_description',
            TNC: 'shop/tac',
            RESTAURANT_MEDIA: 'shop/restaurant_media',
            RESTAURANT_SLIDESHOW: 'shop/slideshow',
            RESTAURANT_BLACKOUT: 'shop/rack_product_blackout',
            EMAIL_TEMPLATE: 'email_template',
            PAYMENT: {
                PAYMENT_CONFIG_MAP: 'shop/payment_config_map',
                SHOP_PAYMENT_CONFIG: 'shop/shop_payment_config',
                PAYMENT_TYPE: 'shop/payment_type',
            },
            QUEUE: {
                QUEUE_CONFIG: 'shop/queue/ticket_config',
                PRINT_CONFIG: 'shop/queue/print_config',
                UPLOAD_PRINT_CONFIG_IMAGE: 'shop/queue/upload_device_images',
                RESET_CURRENT_TIME_SECTION: 'shop/queue/reset_current_time_section',
                QUEUE_TEMPLATE: 'shop/queue/queue_template',
                QUEUE_TEMPLATE_MAP: 'shop/queue/restaurant_queue_template_map',
                QUEUE_TEMPLATE_MAP_LIST: 'shop/queue/restaurant_queue_template_map_list',
                QUEUE_TEMPLATE_TODAY_TEMPLATE: 'shop/queue/restaurant_current_queue_template_map',
                TICKET_TYPE: 'shop/queue/ticket_type',
                TICKET_TYPE_MAP: 'shop/queue/ticket_type_map',
                CLONE_QUEUE_TEMPLATE: 'shop/queue/clone_queue_template',
                QUEUE_TEMPLATE_APPLY_MAP_LIST: 'shop/queue/queue_template_apply_map_list',
                PRINT_TEMPLATE: 'shop/queue/print_template',
                PRINT_TEMPLATE_MAP: 'shop/queue/print_template_restaurant_map_list',
                QUEUE_REJECT_REASON: 'shop/queue/reject_reason',
            },
            RESERVATION: {
                RESERVATION_CONFIG: 'shop/reservation/reservation_config',
                RESERVATION_CHARGE_CODE_OPTIONS: 'shop/reservation/reservation_charge_code_options',
                RESERVATION_TEMPLATE: 'shop/reservation/reservation_template',
                CLONE_RESERVATION_TEMPLATE: 'shop/reservation/clone_reservation_template',
                RESERVATION_TEMPLATE_APPLY_MAP_LIST:
                    'shop/reservation/reservation_template_apply_map_list',
                RESERVATION_TEMPLATE_FROM_RESERVE_TEMPLATE:
                    'shop/reservation/generate_reservation_template_from_reserve_template',
                RESERVATION_TEMPLATE_MAP: 'shop/reservation/restaurant_reservation_template_map',
                RESERVATION_TEMPLATE_MAP_LIST:
                    'shop/reservation/restaurant_reservation_template_map_list',
                CHARGE_DATE: 'shop/reservation/charge_date',
                CHARGE_DATE_LIST: 'shop/reservation/charge_date_list',
                RESERVATION_REJECT_REASON: 'shop/reservation/reject_reason',
            },
            PICKUP: {
                PICKUP_TAG_LIST: 'shop/pickup/tag',
                PICKUP_TEMPLATE: 'shop/pickup/pickup_template',
            },
        },
        RESTAURANT_TAG: {
            RESTAURANT_CATEGORY: 'shop/restaurant_category',
            RESTAURANT_CATEGORY_MAP: 'shop/restaurant_category_map',
            RESTAURANT_CATEGORY_MAP_LIST: 'shop/restaurant_category_map_list',
            RESTAURANT_CUISINE: 'shop/restaurant_cuisine',
            RESTAURANT_CUISINE_MAP: 'shop/restaurant_cuisine_map',
            RESTAURANT_CUISINE_MAP_LIST: 'shop/restaurant_cuisine_map_list',
            RESTAURANT_KEYWORD: 'shop/restaurant_keyword',
            RESTAURANT_KEYWORD_MAP: 'shop/restaurant_keyword_map',
            RESTAURANT_KEYWORD_MAP_LIST: 'shop/restaurant_keyword_map_list',
            RESTAURANT_LIST: 'shop/restaurant_tag_restaurant',
        },
        FOLDER: {
            FOLDER: 'folder',
            UPLOAD_FOLDER_IMAGE: 'upload_folder_images',
            FOLDER_MAP: 'folder_map',
            FOLDER_MAP_LIST: 'folder_map_list',
            FOLDER_RESTAURANT: 'folder_restaurant',
            FOLDER_FOLDER: 'folder_folder',
            FOLDER_BANNER: 'folder_banner',
            FOLDER_BANNER_LIST: 'folder_banner_list',
            CLEAR_REDIS_CACHE: 'folder/clear_redis_cache',
        },
        LANDING: {
            LANDING_BANNER: 'landing/landing_banner',
            LANDING_BANNER_LIST: 'landing/landing_banner_list',
            LANDING_SECTION: 'landing/landing_section',
            LANDING_SECTION_UPLOAD_MEDIA: 'landing/upload_landing_medias',
            LANDING_SECTION_UPDATE_SEQUENCE: 'landing/landing_section_list_sequence',
            LANDING_GET_SHOP: 'landing/landing_shop',
            LANDING_GET_PRODUCT: 'landing/landing_product',
        },
        TICKET: {
            TICKET_BANNER: 'ticket/ticket_banner',
            TICKET_BANNER_LIST: 'ticket/ticket_banner_list',
            TICKET_BANNER_MAP: 'ticket/ticket_banner_map',
            TICKET_BANNER_MAP_LIST: 'ticket/ticket_banner_map_list',
        },
        SLIDESHOW: {
            SLIDESHOW: 'slideshow',
            ALL_SLIDESHOW: 'all_slideshow',
            SLIDESHOW_LIST: 'slideshow_list',
            SLIDESHOW_ALL_LIST: 'slideshows',
            REPLACE_SLIDESHOW: 'slideshows/replace_all',
            SLIDESHOW_BULK_UPDATE: 'slideshows/update_status',
        },
        BANNER: {
            BANNER: 'banner',
            BANNER_LIST: 'banner/banner_list',
            UPLOAD_BANNER_IMAGE: 'banner/upload_banner_images',
            UPDATE_BANNER_STATUS: 'banner/update_status',
        },
        PUSH: {
            PUSH: 'push/push_task',
            ESTIMATE_TARGET_USER_AMOUNT: 'push/estimate_target_user_amount',
            UPDATE_PUSH_TASK_STATUS: 'push/update_push_task_status',
            TEST_PUSH_TASK: 'push/test_push_task',
        },
        SELECTED_TAG: {
            SELECTED_TAG: 'shop/select_tag',
            SELECT_TAG_CONFIG: 'shop/select_tag_map',
            CREATE_SELECT_TAG_CONFIG: 'shop/select_tag_map',
            // ESTIMATE_TARGET_USER_AMOUNT: "push/estimate_target_user_amount",
            // UPDATE_PUSH_TASK_STATUS: "push/update_push_task_status",
            // TEST_PUSH_TASK: "push/test_push_task",
        },
        TAKEAWAY: 'takeaway',
        TOOLS: 'tools',
        CONFIGURATION: 'configuration',
        PASSWORD: {
            LANDING: 'resetPassword',
            RESET_PASSWORD: 'user/update_password',
        },

        UPLOAD_IMAGE: 'uploadimage',
        COS_IMAGE: 'cos/image',
        BRAND: {
            RESTAURANT_MAP: 'shop/restaurant_brand_map',
            RESTAURANT_MAP_REMOVE: 'shop/restaurant_brand_map/remove',
            BRAND_RESTAURANT_MAP: 'shop/brand_restaurant',
        },
        MERCHANT: {
            MERCHANT_CONFIG: 'merchant/shop_config',
            CLEAR_SOUND_FILES: 'merchant/clear_sound_files',
            QUEUE_PROFILE: 'merchant/profile/queue_profile',
            QUEUE_TRANSFER_PROFILE: 'merchant/profile/queue_transfer_profile',
            SOUND_TEMPLATE: 'merchant/sound_template',
            COUNTER_PROFILE: 'merchant/profile/counter_profile',
            COUNTER: 'merchant/counter',
            STAFF: 'merchant/staff',
            MERCHANT_USER: 'merchant/user',
            MERCHANT_SALES_USER: 'merchant/sales_user',
            UPLOAD_MERCHANT_IMAGE: 'merchant/upload_merchant_images',
            UPDATE_MERCHANT_USER_PASSWORD: 'merchant/user/update_password',
            UPDATE_STAFF_PASSWORD: 'merchant/staff/update_password',
            DRAFT: 'merchant/draft',
            PAYMENT_RECORD_OPTIONS: 'merchant/payment_record_options',
            BRAND: 'merchant/brand_approval',
            BRAND_APPROVE: 'merchant/approve_brand',
            BRAND_REJECT: 'merchant/reject_brand',
            MERCHANT_USER_GROUP: 'merchant/user_group',
            MERCHANT_USER_GROUP_RESTAURANT_MAP_EXCLUDE: 'merchant/user_group_restaurant',
            MERCHANT_USER_GROUP_RESTAURANT_MAP: 'merchant/user_group/restaurant_map',
            MERCHANT_USER_GROUP_REMOVE_RESTAURANT_MAP: 'merchant/user_group/restaurant_map/remove',
            MERCHANT_DEVICE: 'merchant/device',
            DEVICE_LOG: 'merchant/device_log',
            SOUND_INFO_VOICE: 'merchant/sound_info_voice',
        },
        PRODUCT: {
            PRODUCT_CREATE: 'product/product_create',
            DRAFT: 'rack_product/draft',
            USER_GROUP: 'rack_product/user_group',
            PRODUCT_CATEGORY: 'rack_product/category',
            PRODUCT_CATEGORY_MAP: 'rack_product/category_map',
            CATEGORY_PRODUCT_MAP: 'rack_product/category_product_map',
            CATEGORY_PRODUCT_MAP_LIST: 'rack_product/category_product_map_list',
            UPLOAD_PRODUCT_IMAGE: 'rack_product/upload_rack_product_images',
            UPLOAD_REDEEM_CODE_FILE: 'rack_product/upload_redeem_code_file',
            CONFIRM_CREATE_PRODUCT: 'rack_product/create_rack_product',
            SETTLEMENT_CONFIG: 'rack_product/settlement_config',
            REDEEM_GROUP: 'rack_product/redeem_group',
            REDEEM_LOCATION: 'rack_product/redeem_location',
            PRODUCT: 'rack_product/product',
            PRODUCT_STATUS_UPDATE: 'rack_product/update_product_status',
            MEZZOFY_COUPON_DATA: 'rack_product/mezzofy_coupon_data',
            SYSTEM_GROUP: 'system_group',
            BRAND_PRODUCT: 'rack_product/brand_product',
            PRODUCT_BRAND_MAP: 'rack_product/brand_map',
        },
        SYSTEM_GROUP: {
            RESTAURANT_MAP: 'system_group/restaurant_map',
            RESTAURANT_MAP_REMOVE: 'system_group/restaurant_map/remove',
            SYSTEM_GROUP_RESTAURANT: 'system_group/system_group_restaurant',
            SYSTEM_USER: 'system_group/system_user',
            SYSTEM_USER_UPDATE_PASSWORD: 'system_group/system_user/update_password',
        },
        APPROVAL: {
            RESTAURANT: 'approval/restaurant',
            RESTAURANT_APPROVE: 'approval/approve_restaurant',
            RESTAURANT_REJECT: 'approval/reject_restaurant',
            PAYMENT: 'approval/payment',
            PAYMENT_APPROVE: 'approval/approve_payment',
            UPLOAD_PAYMENT_IMAGE: 'approval/upload_payment_images',
            UPLOADPAYMENT: 'approval/match_payments',
            CREATE_PAYMENT_RECORD: 'approval/create_payment_record/',
            RESTAURANT_APPROVAL_SALES_RACK_PRODUCT: 'approval/sales_merchant_rack_product',
            MERCHANT_INFO: 'approval/merchant_info',
        },
        SALES: {
            SALES_RESTAURANT: 'sales/restaurant',
            SALES_SHOP_FRONT_IMAGE: 'sales/restaurant_shop_front',
            SALES_RACK_PRODUCT: 'sales/merchant_rack_product',
            SALES_USER: 'sales/sales_user',
        },
        TROUBLE_SHOOTING: 'troubleshooting/device_activation_list',
        CONTACT_US: 'contact_us',
    },
    ROLE: {
        REST_GROUP_ADMIN: 'ROLE_REST_GROUP_ADMIN',
        SYSTEM_ADMIN: 'ROLE_SYSTEM_ADMIN',
        ROLE_SUPER_ADMIN: 'ROLE_SUPER_ADMIN',
        ADMIN: 'ROLE_ADMIN',
        RESTAURANT_ADMIN: 'ROLE_RESTAURANT_ADMIN',
        FINANCE: 'ROLE_FINANCE',
        SUPPORT: 'ROLE_SUPPORT',
    },
    PERMISSION_KEY: {
        GET_STRING: 'GET_STRING',
        MENU_SYSTEM_ADMIN: 'MENU_SYSTEM_ADMIN',
        MENU_RESTAURANT_ADMIN: 'MENU_RESTAURANT_ADMIN',
        MENU_FINANCE: 'MENU_FINANCE',
        MENU_SUPPORT: 'MENU_SUPPORT',
        LIST_DISPLAY_SYSTEM_ADMIN: 'LIST_DISPLAY_SYSTEM_ADMIN',
        LIST_DISPLAY_FINANCE: 'LIST_DISPLAY_FINANCE',
        PRODUCT_DRAFT_LIST_SYSTEM_ADMIN: 'PRODUCT_DRAFT_LIST_SYSTEM_ADMIN',
        PRODUCT_CREATE_LIST_FINANCE: 'PRODUCT_CREATE_LIST_FINANCE',
        PRODUCT_CREATE_FINANCE: 'PRODUCT_CREATE_FINANCE',
        PRODUCT_CREATE_SYSTEM_ADMIN: 'PRODUCT_CREATE_SYSTEM_ADMIN',
        PRODUCT_CREATE_EDIT_FINANCE: 'PRODUCT_CREATE_EDIT_FINANCE',
        PRODUCT_LOT_CREATE_EDIT_FINANCE: 'PRODUCT_LOT_CREATE_EDIT_FINANCE',
        REDEEM_GROUP_LIST_FINANCE: 'REDEEM_GROUP_LIST_FINANCE',
        REDEEM_GROUP_CREATE_EDIT_FINANCE: 'REDEEM_GROUP_CREATE_EDIT_FINANCE',
        PRODUCT_EDIT_FINANCE: 'PRODUCT_EDIT_FINANCE',
        PRODUCT_EDIT_SYSTEM_ADMIN: 'PRODUCT_EDIT_SYSTEM_ADMIN',
        CAT_CREATE_FINANCE: 'CAT_CREATE_FINANCE',
        LANG_SYSTEM_ADMIN: 'LANG_SYSTEM_ADMIN',
        LANG_FINANCE: 'LANG_FINANCE',
        MERCHANT_CREATE_SYSTEM_ADMIN: 'MERCHANT_CREATE_SYSTEM_ADMIN',
        MERCHANT_CREATE_FINANCE: 'MERCHANT_CREATE_FINANCE',
    },
    STYLE: {
        PRIMARY: 'primary',
        SECONDARY: 'secondary',
        VARIANT: {
            CONTAINED: 'contained',
            OUTLINED: 'outlined',
            STANDARD: 'standard',
            FILLED: 'filled',
            H6: 'h6',
            BODY2: 'body2',
        },
    },
    LOCAL_STORAGE_KEY: {
        LANG: 'lang',
        TOKEN: 'token',
        ROLE: 'role',
        USERNAME: 'username',
        SELECTED_GROUP_OPTION: 'selectedGroupOption',
        UPLOAD_PAYMENT_FILE: 'uploadPaymentFile',
        PAYMENT_CREATE_ID: 'paymentCreateId',
        UPLOAD_PAYMENT_CHOSEN_TRANDATE: 'uploadPaymentChoseTranDate',
        UPLOAD_PAYMENT_CHOSEN_MATCHSTATUS: 'uploadPaymentChoseMatchStatus',
        PAYMENT_CREATE_RESTAURANT_RECORD: 'paymentCreateRestaurantRecord',
    },
    TYPE: {
        HIDDEN: 'hidden',
        NUMBER: 'number',
        CHECKBOX: 'checkbox',
        BOOLEAN: 'boolean',
        DATETIME_LOCAL: 'datetime-local',
        TIME: 'time',
        TEXT: 'text',
        BUTTON: 'button',
        IMAGE: 'IMAGE',
        IMAGE_MULTI: 'IMAGE_MULTI',
        SELECT: 'SELECT',
        RADIO_BUTTON: 'RADIO_BUTTON',
        DATE: 'Date',
        FUNCTION: 'Function',
        TITLE: 'Title',
        PASSWORD: 'password',
        GUIDE: 'GUIDE',
    },
    SIZE: {
        SMALL: 'small',
        MEDIUM: 'medium',
        LARGE: 'large',
        DEFAULT: 'default',
    },
    LIST_DISPLAY_TYPE: {
        STRING: 'String',
        IMAGE: 'Image',
        DATE: 'Date',
        DATE_TIME: 'DateTime',
        BOOLEAN: 'Boolean',
        FUNCTION: 'Function',
        BUTTON: 'Button',
        MEDIA: 'Media',
        ICON: 'ICON',
    },
    FILE_TYPE: {
        IMAGE: 'IMAGE',
        VIDEO: 'VIDEO',
    },
    APPROVAL_SALES_RACK_PRODUCT_STATUS: {
        ACTIVE: 'A',
        REJECT: 'R',
    },
    APPROVAL_STATUS: {
        ACTIVE: 'A',
        PENDING: 'P',
        REJECT: 'R',
    },
    PRODUCT_CREATE_STATUS: {
        ACTIVE: 'A',
        COMPLETE: 'C',
        PENDING: 'P',
        TO_BE_APPROVE: 'TBA',
        DEACTIVE: 'D',
    },
    PRODUCT_EDIT_STATUS: {
        ACTIVE: 'A',
        PENDING: 'P',
        TO_BE_APPROVE: 'TBA',
        TO_BE_EDIT: 'TBE',
        DEACTIVE: 'D',
    },
    SELECT_TAG_EDIT_STATUS: {
        ACTIVE: 'A',
        PENDING: 'P',
        TO_BE_APPROVE: 'TBA',
        TO_BE_EDIT: 'TBE',
        DEACTIVE: 'D',
    },
    LIST_TYPE: {
        NORMAL: 'NORMAL',
        GRID: 'GRID',
        SIMPLE: 'SIMPLE',
    },
    GET_IMAGE_TYPE: {
        PRODUCT: 'Product',
        CATEGORY: 'Category',
    },
    CAMPAIGN_DESCRIPTION_TYPE: {
        WEB_CAMPAIGN_TITLE: WEB_CAMPAIGN_TITLE,
        WEB_CAMPAIGN_DESCRIPTION: WEB_CAMPAIGN_DESCRIPTION,
    },
    DRAFT_VER: DRAFT_VER,
    REDEEM_GROUP: REDEEM_GROUP,
    PRODUCT: PRODUCT,
    CATEGORY: CATEGORY,
    NOTIFIER: {
        EMAIL: 'EMAIL',
        SMS: 'SMS',
        WHATSAPP: 'WHATSAPP',
    },
    PRODUCT_DRAFT: {
        draft: {
            category: CATEGORY,
            product: {},
            lotInventorySet: false,
            productCode: '',
            productType: '',
            redeemCodeApiType: null,
            lotInventory: [],
            productList: [],
            subProductList: [],
            redeemGroup: {},
            redeemGroupList: [],
            existingRedeemGroupList: [],
            userGroupCode: '',
            codeNumber: 1,
            panelSize: [0, 500, 500, 500, 500],
            productListCheckBox: PRODUCT_TYPE,
            productLotListCheckBox: PRODUCT_TYPE,
            step: 0,
        },
        draftName: '',
        id: '',
        status: '',
        draftVersion: DRAFT_VER,
    },
    CATEGORY_INPUT: {
        codeDescription: null,
        type: null,
        channel: null,
        tcName: null,
        enName: null,
        scName: null,
        displaySequence: 0,
        status: 'A',
        scNameDisable: true,
        scDescriptionDisable: true,
        tcDescription: null,
        enDescription: null,
        scDescription: null,
        refCategoryId: null,
        refCategoryCode: null,
        refCategoryTcName: null,
        refCategoryEnName: null,
        refCategoryScName: null,
        startDisplayTimestamp: null,
        endDisplayTimestamp: null,
        imageList: [],
        productMapList: [],
        forceUpdate: null,
    },
    MERCHANT_DRAFT: {
        draft: {
            shopTcName: null,
            shopEnName: null,
            shopScName: null,
            scNameDisable: true,
            shopTcAddress: null,
            shopEnAddress: null,
            shopScAddress: null,
            scAddressDisable: true,
            shopContactNumber: null,
            shopLogo: null,
            shopFrontImageList: [],
            brImage: null,
            userGroupCode: null,
            merchantUserList: [],
            planCode: null,
            brandCode: null,
        },
        draftName: '',
        id: '',
        status: '',
        draftVersion: DRAFT_VER,
    },
    CHANNEL: {
        GLOBAL: GLOBAL,
        MOBILE: MOBILE,
        WECHAT_MP: WECHAT_MP,
        SPRING_BOARD: SPRING_BOARD,
        WEB: WEB,
    },
    CAT_IMAGE_TYPE: {
        CATEGORY_ICON: CATEGORY_ICON,
        CATEGORY_4BY3: CATEGORY_4BY3,
        CATEGORY_DETAIL: CATEGORY_DETAIL,
    },
    REDEEM_TYPE: {
        FIXED_START_END: FIXED_START_END,
        FLEXIBLE_DAYS: FLEXIBLE_DAYS,
        PREORDER: PREORDER,
    },
    PRODUCT_IMAGE_TYPE: {
        ITEM_4BY3: ITEM_4BY3,
        ITEM_ICON: ITEM_ICON,
        ITEM_DETAIL: ITEM_DETAIL,
    },
    PRODUCT_TYPE: {
        PREORDER_ITEM: PREORDER_ITEM,
        PACKAGE_ITEM: PACKAGE_ITEM,
        PACKAGE_SUB_ITEM: PACKAGE_SUB_ITEM,
        ECOUPON_ITEM: ECOUPON_ITEM,
        VALUE_UP_ECOUPON_ITEM: VALUE_UP_ECOUPON_ITEM,
        FREE_ECOUPON_ITEM: FREE_ECOUPON_ITEM,
    },
    ATTRIBUTE_TYPE: {
        TEXT: TEXT,
        SELECT: SELECT,
    },
    LOT_INVENTORY_TYPE: {
        AUTO_GENERATE: AUTO_GENERATE,
        AUTO_GENERATE_MEZZOFY: AUTO_GENERATE_MEZZOFY,
        CUSTOMIZE: CUSTOMIZE,
        CUSTOMIZE_TGR: CUSTOMIZE_TGR,
        CUSTOMIZE_MASS_CODE: CUSTOMIZE_MASS_CODE,
        MEZZOFY: 'MEZZOFY',
    },
    BRAND: {
        INFO: 0,
        RESTAURANT_MAP_LIST: 1,
    },
    BRAND_GROUP: {
        INFO: 0,
    },
    SYSTEM_GROUP: {
        INFO: 0,
        RESTAURANT_LIST: 1,
        SYSTEM_USER: 2,
    },
    SYSTEM_USER: {
        INFO: 0,
    },
    RESTAURANT_TAB: {
        INFO: 0,
        QUEUE: 1,
        RESERVATION: 2,
        PICKUP: 3,
        MERCHANT: 4,
        MEDIA: 5,
        PRODUCT: 6,
        REPORT: 7,
    },
    PICKUP_TAB: {
        INFO: 0,
    },
    RESTAURANT_SUB_TAB: {
        INFO: 0,
        STATUS: 1,
        ADMIN_DEVICE: 2,
        CAMPAIGN_DESCRIPTION: 3,
        TNC: 4,
        SELECT_TAG: 5,
        PAYMENT: 6,
        SHOP_DASHBOARD: 7,
    },
    RESTAURANT_MEDIA_TAB: {
        INFO: 0,
    },
    RESTAURANT_BLACKOUT_TAB: {
        INFO: 0,
    },
    RESTAURANT_SLIDESHOW_TAB: {
        INFO: 0,
    },
    RESTAURANT_QUEUE_SUB_TAB: {
        QUEUE_CONFIG: 0,
        PRINT_CONFIG: 1,
        QUEUE_TEMPLATE: 2,
        QUEUE_TEMPLATE_MAP: 3,
        QUEUE_REJECT_REASON: 4,
        DASHBOARD: 5,
    },
    RESTAURANT_RESERVATION_SUB_TAB: {
        RESERVATION_CONFIG: 0,
        RESERVATON_REJECT_REASON: 1,
        // RESERVATION_TEMPLATE: 1,
        // RESERVATION_TEMPLATE_MAP: 2,
        // CHARGE_DATE: 3,
    },
    RESTAURANT_PICKUP_SUB_TAB: {
        PICKUP_CONFIG: 0,
        // RESERVATION_TEMPLATE: 1,
        // RESERVATION_TEMPLATE_MAP: 2,
        // CHARGE_DATE: 3,
    },
    RESTAURANT_MERCHANT_SUB_TAB: {
        MERCHANT_CONFIG: 0,
        MERCHANT_DEVICE: 1,
        COUNTER: 2,
        STAFF: 3,
        QUEUE_PROFILE: 4,
        QUEUE_TRANSFER_PROFILE: 5,
        COUNTER_PROFILE: 6,
    },
    RESTAURANT_MEDIA_SUB_TAB: {
        MEDIA: 0,
        BANNER_LIST: 1,
        SLIDESHOW: 2,
    },
    RESTAURANT_PRODUCT_SUB_TAB: {
        BLACKOUT: 0,
    },
    RESTAURANT_REPORT_SUB_TUB: {
        REPORT_CONFIG: 0,
    },
    RESTAURANT_SELECT_TAG_SUB_TAB: {
        SELECT_TAG_CONFIG: 0,
    },
    URL_MAP_TAB: {
        INFO: 0,
    },
    ADMIN_DEVICE_EDIT_TAB: {
        INFO: 0,
    },
    CAMPAIGN_DESCRIPTION_EDIT_TAB: {
        INFO: 0,
    },
    TNC_EDIT_TAB: {
        INFO: 0,
    },
    QUEUE_REJECT_REASON_EDIT_TAB: {
        INFO: 0,
    },
    RESERVATION_REJECT_REASON_EDIT_TAB: {
        INFO: 0,
    },
    EMAIL_TEMPLATE_EDIT_TAB: {
        INFO: 0,
    },
    PRINT_TEMPLATE_EDIT_TAB: {
        INFO: 0,
    },
    PRODUCT_CAT_TAB: {
        INFO: 0,
        PRODUCT_MAP_LIST: 1,
    },
    PRODUCT_MAP_TAB: {
        INFO: 0,
    },
    REDEEM_GROUP_TAB: {
        NFO: 0,
    },
    PRODUCT_TAB: {
        INFO: 0,
        IMAGE_LIST: 1,
        ATTRIBUTE_LIST: 3,
        BLACKOUT_PERIOD_LIST: 2,
    },
    PRODUCT_DRAFT_STEP: {
        // USER_GROUP: 0,
        CATEGORY: 0,
        REDEEM_GROUP: 1,
        PRODUCT: 2,
        PRODUCT_LOT: 3,
    },
    MERCHANT_DRAFT_STEP: {
        RESTAURANT: 0,
        IMAGE: 1,
        ACCOUNT: 2,
        SERVICE_AND_PLAN: 3,
        BRAND: 4,
    },
    FOLDER_TAB: {
        INFO: 0,
        FOLDER_MAP_LIST: 1,
        BANNER_LIST: 2,
    },
    FOLDER_MAP_TAB: {
        INFO: 0,
    },
    SLIDESHOW_BULK_CREATE_STEP: {
        SLIDESHOW: 0,
        RESTAURANT_MAP_LIST: 1,
    },
    BANNER_TAB: {
        INFO: 0,
    },
    BANNER_SUB_TAB: {
        BANNER_EN_IMAGE: 0,
        BANNER_TC_IMAGE: 1,
        BANNER_SC_IMAGE: 2,
    },
    TICKET_BANNER_TAB: {
        TICKET_BANNER_MAP_LIST: 1,
    },
    TICKET_BANNER_MAP_TAB: {
        INFO: 0,
    },
    PUSH_TAB: {
        INFO: 0,
    },
    AREA_EDIT_TAB: {
        INFO: 0,
        DISTRICT_LIST: 1,
    },
    DISTRICT_TAB: {
        INFO: 0,
        RESTAURANT_MAP_LIST: 1,
        LANDMARK_MAP_LIST: 2,
    },
    LANDMARK_TAB: {
        INFO: 0,
        DISTRICT_LIST: 1,
        RESTAURANT_LIST: 2,
    },
    LANDMARK_DISTRICT_MAP_TAB: {
        INFO: 0,
    },
    ALL_SLIDESHOW_RESTAURANT_MAP_CREATE: {
        INFO: 0,
    },
    TAG_EDIT_TAB: {
        INFO: 0,
        RESTAURANT_MAP_LIST: 1,
    },
    RESTAURANT_MAP_LIST_TAB: {
        INFO: 0,
    },
    QUEUE_TEMPLATE_TAB: {
        INFO: 0,
    },
    QUEUE_PROFILE_TAB: {
        INFO: 0,
    },
    QUEUE_TRANSFER_PROFILE_TAB: {
        INFO: 0,
    },
    QUEUE_TEMPLATE_TIME_SECTION_TAB: {
        INFO: 0,
        TABLE_TYPE: 1,
    },
    QUEUE_TEMPLATE_MAP_TAB: {
        INFO: 0,
    },
    RESERVATION_TEMPLATE_TAB: {
        INFO: 0,
    },
    RESERVATION_TEMPLATE_TIME_SECTION_TAB: {
        INFO: 0,
        TABLE_TYPE: 1,
    },
    RESERVATION_TEMPLATE_MAP_TAB: {
        INFO: 0,
    },
    CHARGE_DATE_TAB: {
        INFO: 0,
    },
    MERCHANT_DEVICE_EDIT_TAB: {
        INFO: 0,
        DEVICE_LOG: 1,
    },
    MERCHANT_USER_EDIT_TAB: {
        INFO: 0,
    },
    MERCHANT_USER_GROUP_EDIT_TAB: {
        INFO: 0,
        RESTAURANT_MAP: 1,
    },
    MERCHANT_SALES_USER_EDIT_TAB: {
        INFO: 0,
    },
    TICKET_TYPE: {
        INFO: 0,
        // TICKET_TYPE_MAP_LIST: 1
    },
    CONTACT_US_EDIT_TAB: {
        INFO: 0,
    },
    SELECT_TAG_TAB: {
        INFO: 0,
    },
    SELECT_TAG_EDIT_TAB: {
        INFO: 0,
    },
    RESTAURANT_PAYMENT_EDIT_TAB: {
        INFO: 0,
    },
    PAYMENT_EDIT_TAB: {
        INFO: 0,
    },
    RESTAURANT_APPROVE_EDIT_TAB: {
        INFO: 0,
        PAYMENT_LIST: 1,
        MERCHANT_INFO: 2,
    },
    BRAND_APPROVE_EDIT_TAB: {
        INFO: 0,
    },
    MERCHANT_INFO_EDIT_TAB: {
        INFO: 0,
    },
    RESTAURANT_INPUT: {
        restUrlId: '',
        enName: '',
        tcName: '',
        scName: '',
        scNameDisable: true,
        enSName: '',
        tcSName: '',
        scSName: '',
        scSNameDisable: true,
        enSearchName: '',
        tcSearchName: '',
        scSearchName: '',
        scSearchNameDisable: true,
        restType: '',
        phone: '',
        brandCode: '',
        brandTcName: '',
        brandEnName: '',
        brandScName: '',
        groupId: '',
        groupTcName: '',
        groupEnName: '',
        groupScName: '',
        districtTcName: '',
        districtEnName: '',
        districtScName: '',
        districtId: '',
        enAddress: '',
        tcAddress: '',
        scAddress: '',
        scAddressDisable: true,
        coorX: '',
        coorY: '',
        coorZ: '',
        enTicketMessage: '',
        tcTicketMessage: '',
        scTicketMessage: '',
        scTicketMessageDisable: true,
        enOpening: '',
        tcOpening: '',
        scOpening: '',
        scOpeningDisable: true,
        paymentMethod: '',
        avgSpend: '',
        enLastOrderTime: '',
        tcLastOrderTime: '',
        scLastOrderTime: '',
        scLastOrderTimeDisable: true,
        enDescription: '',
        tcDescription: '',
        scDescription: '',
        scDescriptionDisable: true,
        status: '',
        searchable: '',
        theme: '',
        aigensId: '',
        qrCode: [],
        picture: {},
        thumbnail: {},
        contact: '',
        contactPerson: '',
        jobTitle: '',
        restRefId: '',
        salesCreateTimestamp: '',
        salesName: '',
    },
    RESTAURANT_CLONE_INPUT: {
        restUrlIdSource: null,
        restUrlIdTarget: null,
        queue: false,
        reservation: false,
        systemGroup: false,
        banner: false,
        slideshow: false,
        systemGroupGorilla: true,
        payment: false,
        merchantUserCreate: true,
    },
    RESTAURANT_MEDIA_INPUT: {
        id: null,
        type: null,
        fileType: 'IMAGE',
        cosFileEnUrl: null,
        cosFileEnKey: null,
        cosFileTcUrl: null,
        cosFileTcKey: null,
        cosFileScUrl: null,
        cosFileScKey: null,
        displaySequence: 0,
        mediaKey: null,
    },
    RESTAURANT_BLACKOUT_INPUT: {
        type: 'SHOP',
        refCode: null,
        startTimestamp: null,
        endTimestamp: null,
    },
    RESTAURANT_SLIDESHOW_INPUT: {
        id: null,
        restUrlId: null,
        type: null,
        media: {
            cosFileKey: null,
            cosFileUrl: null,
        },
        startDisplayTimestamp: null,
        endDisplayTimestamp: null,
        sequence: 0,
        status: 'A',
    },
    PRODUCT_MAP_INPUT: [],
    BRAND_INPUT: {
        brandCode: null,
        enName: null,
        tcName: null,
        scName: null,
        scNameDisable: true,
        enDescription: null,
        tcDescription: null,
        scDescription: null,
        scDescriptionDisable: true,
        logoImage: null,
        imageList: [],
    },
    RESTAURANT_SLIDESHOW_BULK_CREATE_INPUT: {
        type: null,
        media: {
            cosFileKey: null,
            cosFileUrl: null,
        },
        startDisplayTimestamp: null,
        endDisplayTimestamp: null,
        sequence: 0,
        status: 'A',
    },
    BRAND_GROUP_INPUT: {
        groupId: '',
        enName: '',
        tcName: '',
        scName: '',
        scNameDisable: true,
    },
    SYSTEM_GROUP_INPUT: {
        id: '',
        name: '',
        groupEmails: '',
        groupEmailList: [],
    },
    SYSTEM_USER_INPUT: {
        id: '',
        systemGroupId: '',
        username: '',
        userRole: '',
        restUrlId: '',
        privilegeAttribute: '',
        status: '',
    },
    RESTAURANT_PAYMENT: {
        id: null,
        restUrlId: null,
        systemGroupId: null,
        paymentConfigId: null,
        paymentCode: null,
        cardType: null,
        serviceType: null,
        sequence: null,
        status: null,
    },
    SELECT_TAG_INPUT: {
        id: null,
        tagCode: null,
        enName: null,
        tcName: null,
        scName: null,
        scNameDisable: true,
    },
    SELECT_TAG_CONFIG_OPTIONS_INPUT: [],
    PICKUP_TAG: {
        status: 'A',
        type: 'DEFAULT',
        voiceCode: 'DEFAULT',
        sequence: 0,
        reverseSequence: false,
        foregroundColor: null,
        backgroundColor: null,
        restUrlId: '',
    },
    TAG_INPUT: {
        tcName: '',
        enName: '',
        scName: '',
        scNameDisable: true,
        orderSequence: 0,
        tcImage: null,
        enImage: null,
        scImage: null,
    },
    TAG_MAP_INPUT: {
        restUrlId: '',
        restEnName: '',
        restTcName: '',
        restScName: '',
        startTimestamp: null,
        endTimestamp: null,
        sequence: 0,
    },
    ADMIN_DEVICE_INPUT: {
        reportStatus: '',
        takeawayStatus: '',
        queueStatus: '',
        preorderStatus: '',
        tagStatus: '',
        reservationStatus: '',
        banquetStatus: '',
        ecouponStatus: '',
        menuStatus: '',
        ecouponDistributeStatus: '',
    },
    ADMIN_DEVICE_CREATE_INPUT: {
        restUrlId: '',
        deviceType: '',
        activationCode: '',
    },
    CAMPAIGN_DESCRIPTION_INPUT: {
        type: '',
        enTitle: null,
        tcTitle: null,
        scTitle: null,
        scTitleDisable: true,
        enMessage: null,
        tcMessage: null,
        scMessage: null,
        scMessageDisable: true,
        sequence: 0,
    },
    TNC_INPUT: {
        type: '',
        enDescription: null,
        tcDescription: null,
        scDescription: null,
        scDescriptionDisable: true,
        filterKey: null,
    },
    QUEUE_REJECT_REASON_INPUT: {
        reasonCode: '',
        restUrlId: '',
        status: 'A',
        tcReason: '',
        enReason: '',
        scReason: '',
        scReasonDisable: true,
    },
    QUEUE_TEMPLATE_INPUT_LIST: [],
    TIME_SECTION_DEFAULT_VALUE: {
        id: null,
        templateCode: null,
        timeSectionId: '',
        timeSectionTcLabel: '',
        timeSectionEnLabel: '',
        timeSectionScLabel: '',
        startTime: null,
        endTime: null,
        timeSectionDescription: null,
        isSyncTimeFieldsWithAllTableTypes: true,
        isSyncRequestTicketTimeFieldsWithAllTableTypes: true,
        disableLabel: true,
        tableTypeList: [],
        type: null,
    },
    QUEUE_TEMPLATE_GROUPED_SECTION: {
        groupedSection: '',
        groupedSectionEnDescription: '',
        groupedSectionEnLabel: '',
        groupedSectionScDescription: '',
        groupedSectionScLabel: '',
        groupedSectionTcDescription: '',
        groupedSectionTcLabel: '',
        disableGroupedSection: true,
        disableGroupedSectionDescription: true,
    },
    QUEUE_TEMPLATE_MAP_LIST: [],
    QUEUE_TEMPLATE_MAP_INPUT: {
        id: null,
        restUrlId: '',
        type: null,
        dayOfWeek: null,
        description: '',
        startDate: null,
        endDate: null,
        templateCode: null,
        templateDescription: null,
        dayOfWeekList: [],
    },
    RESERVATION_TEMPLATE_INPUT_LIST: [],
    RESERVATION_TEMPLATE_INPUT: {
        id: null,
        templateCode: null,
        timeSectionId: '',
        groupedSection: 'A',
        timeSectionTcLabel: '',
        timeSectionEnLabel: '',
        timeSectionScLabel: '',
        startTime: null,
        endTime: null,
        timeSectionDescription: null,
        disableLabel: true,
        tableTypeList: [],
        effectiveDate: null,
    },
    RESERVATION_TEMPLATE_MAP_LIST: [],
    RESERVATION_TEMPLATE_MAP_INPUT: {
        id: null,
        restUrlId: '',
        type: null,
        dayOfWeek: null,
        description: null,
        startDate: null,
        endDate: null,
        templateCode: null,
        templateDescription: null,
        effectiveDate: null,
    },
    CHARGE_DATE_LIST: [],
    CHARGE_DATE_INPUT: {
        chargeCode: null,
        startDate: null,
        endDate: null,
    },
    CHARGE_DATE_EDIT_INPUT: {
        id: null,
        restUrlId: null,
        chargeCode: null,
        date: null,
    },
    TICKET_TYPE_INPUT: {
        ticketType: null,
        enName: null,
        tcName: null,
        scName: null,
        scNameDisable: true,
        foregroundColor: null,
        backgroundColor: null,
    },
    PRINT_TEMPLATE_INPUT: {
        id: null,
        code: null,
        type: 'NORMAL',
        merchantTemplate: null,
        template: null,
        templateLabels: null,
    },
    TEMPLATE_LIST: [],
    TEMPLATE_LIST_INPUT: {
        customId: null,
        type: null,
        value: null,
    },
    TEMPLATE_LABEL_LIST: [],
    TEMPLATE_LABEL_LIST_INPUT: {
        customId: null,
        labelTitle: null,
        labelEn: null,
        labelTc: null,
        labelSc: null,
        disableScName: true,
    },
    EMAIL_TEMPLATE_INPUT: {
        restUrlId: '',
        type: '',
        receiver: [],
        subjectScDisable: true,
        enSubject: null,
        tcSubject: null,
        scSubject: null,
        htmlContentScDisable: true,
        htmlEnContent: '',
        htmlTcContent: '',
        htmlScContent: '',
        plainTextContentScDisable: true,
        plainTextEnContent: '',
        plainTextTcContent: '',
        plainTextScContent: '',
    },
    AREA_INPUT: {
        tcName: '',
        enName: '',
        scName: '',
        scNameDisable: true,
        landmarkOrder: 0,
    },
    DISTRICT_INPUT: {
        tcName: '',
        enName: '',
        scName: '',
        scNameDisable: true,
        areaId: '',
        districtLevel: 0,
        menuChiOrder: 0,
        latitude: 0,
        longitude: 0,
    },
    LANDMARK_INPUT: {
        tcName: '',
        enName: '',
        scName: '',
        scNameDisable: true,
        landmarkOrder: 0,
        favorite: '',
    },
    FOLDER_INPUT: {
        cityName: '',
        cityCode: '',
        folderCode: '',
        type: 'GRID_VIEW',
        tcName: '',
        enName: '',
        scName: '',
        scNameDisable: true,
        tcImage: null,
        enImage: null,
        scImage: null,
        showHidden: false,
        tcDescriptionHtml: null,
        enDescriptionHtml: null,
        scDescriptionHtml: null,
        scDescriptionDisable: true,
    },
    FOLDER_MAP_INPUT: [],
    CONFIG_MAP_INPUT: [],
    URL_MAP_INPUT_LIST: [],
    URL_MAP_INPUT: {
        mapCode: '',
        mapValue: '',
        restUrlId: '',
        agentType: 'web',
        actionType: '',
        parameters: {},
        url: '',
        customFieldName: '',
        isHideCustomField: true,
        isDisabledCustomField: false,
        mapValueDisable: false,
        customUrl: '',
        restaurantUrlType: RESTAURANT_URL_TYPE.DOWNLOAD_HTML,
    },
    BANNER_INPUT_LIST: [],
    BANNER_INPUT: {
        customId: '',
        refCode: null,
        type: '',
        startDisplayDate: '',
        endDisplayDate: '',
        sequence: 0,
        tcImage: null,
        enImage: null,
        scImage: null,
        tcName: null,
        enName: null,
        scName: null,
        scNameDisable: true,
        multiActionParameters: false,
        actionParameters: '',
        tcActionParameters: '',
        enActionParameters: '',
        scActionParameters: '',
        actionType: 'NONE',
        parameters: '',
        enParameters: [],
        tcParameters: [],
        scParameters: [],
        otherParameters: [],
        tcOtherParameters: [],
        enOtherParameters: [],
        scOtherParameters: [],
        inputName: '',
        parametersHidden: true,
        parametersDisable: false,
        mapValueDisable: false,
    },
    LANDING_ITEM_INPUT: {
        id: '',
        sectionId: null,
        itemType: null,
        tcTitle: null,
        enTitle: null,
        scTitle: null,
        tcSubTitle: null,
        enSubTitle: null,
        scSubTitle: null,
        serviceTypeList: null,
        startDisplayTimestamp: null,
        endDisplayTimestamp: null,
        sequence: null,
        status: null,
        tcImage: null,
        enImage: null,
        scImage: null,
        tagImage: null,
        foregroundColor: null,
        backgroundColor: null,
        action: null,
        actionParameters: null,
        scTitleDisable: true,
        scSubTitleDisable: true,
    },
    LANDING_SECTION_INPUT: {
        id: '',
        scNameDisable: true,
        serviceType: null,
        mainSection: true,
        sectionType: LANDING_SECTION_SECTION_TYPE.HEADER_ITEMS,
        tcTitle: null,
        enTitle: null,
        scTitle: null,
        titleColor: null,
        startDisplayTimestamp: null,
        endDisplayTimestamp: null,
        sequence: null,
        status: null,
        action: null,
        actionParameters: null,
        itemList: [],
    },
    PUSH_INPUT_LIST: [],
    PUSH_INPUT: {
        id: '',
        actionParameters: '',
        actionType: '',
        deliveryDate: '',
        enMessage: '',
        enMessageTitle: '',
        parameters: '',
        filterParameters: '',
        gender: 'all',
        os: 'all',
        ageGroupList: [],
        mobileList: [],
        inbox: '',
        name: '',
        notification: '',
        scMessage: '',
        scMessageTitle: '',
        status: 'draft',
        systemMessage: '',
        tcMessage: '',
        tcMessageTitle: '',
        total: '',
        otherParameters: [],
        parametersHidden: true,
        parametersDisable: false,
        messageTitleScDisable: true,
        messageScDisable: true,
    },
    STATUS_INPUT: {
        orderStatus: '',
        queueStatus: '',
        reserveStatus: '',
        takeawayStatus: '',
        takeawayDeliveryStatus: '',
        takeawayPickupStatus: '',
        takeawayPreorderStatus: '',
        wxTakeawayStatus: '',
        wxProductStatus: '',
        menuStatus: '',
        ecouponDistributeStatus: '',
        banquetStatus: '',
        havePreorder: '',
        autoSwitchTimeSection: '',
        adminLiteStatus: '',
        vipStatus: '',
        turboStatus: '',
        bookmarkStatus: '',
        reviewStatus: '',
        photoStatus: '',
        gpsRequired: '',
    },
    QUEUE_CONFIG_INPUT: {
        restUrlId: '',
        showTableNo: 'A',
        timeSectionQuota: null,
        rollTagShiftValue: null,
        ticketExpiredMinute: null,
        repeatQueue: null,
        checksumExpiryLimit: null,
        queueSteps: null,
        campaignQueue: null,
        tagSequenceDisplay: null,
        genFromTemplate: null,
        campaignStartTimestamp: null,
        campaignEndTimestamp: null,
        preparationDay: null,
        queuePeriod: null,
        customData: null,
        personalLimitType: null,
        queueType: null,
        deliveryType: null,
        quotaType: null,
        rollTagType: null,
        inputCheckInSize: null,
        minCheckInSize: null,
        maxCheckInSize: null,
        showTableType: null,
        autoTask: false,
        autoSwitchTimeSection: false,
    },
    PRINT_CONFIG_INPUT: {
        restUrlId: null,
        printTemplateCode: null,
        ticketRemarks: null,
        printQrcodeType: null,
    },
    RESERVATION_CONFIG_INPUT: {
        restUrlId: null,
        afterDay: 0,
        reserveDay: 0,
        timeInterval: 0,
        haveConfirm: null,
        showTableNumber: null,
        showRemarks: null,
        remarksTcMessage: null,
        remarksEnMessage: null,
        remarksScMessage: null,
        remarksScMessageDisable: true,
        disableTcMessage: null,
        disableEnMessage: null,
        disableScMessage: null,
        disableScMessageDisable: true,
        reminderTcMessage: null,
        reminderEnMessage: null,
        reminderScMessage: null,
        reminderScMessageDisable: true,
        noTimeSectionTcMessage: null,
        noTimeSectionEnMessage: null,
        noTimeSectionScMessage: null,
        noTimeSectionScMessageDisable: true,
        haveCharge: null,
        chargeType: null,
        chargeByDefault: null,
        chargeCode: null,
        defaultCharge: 0,
        chargeTcDescription: null,
        chargeEnDescription: null,
        chargeScDescription: null,
        chargeScDescriptionDisable: true,
        reserveStatus: null,
        startTimestamp: null,
        expiredTimestamp: null,
        notificationEmail: null,
        sendQrcodeToEmail: null,
    },
    MERCHANT_CONFIG_INPUT: {
        queueRemindResetQuota: false,
        queueChannelStatusControl: false,
        queueQuickCheckIn: false,
        queueRejectTicketEnable: false,
        queueStatusPerQueueControl: false,
        queueMultipleStatusControl: false,
        queueDisplayControl: false,
        publishProduct: false,
        createProduct: false,
        sendProduct: false,
        guluSlideshowEnable: true,
        soundSpeed: 1,
        voiceCode: 'DEFAULT',
    },
    QUEUE_PROFILE_INPUT: {
        enName: null,
        tcName: null,
        scName: null,
        refTemplateCode: null,
        refTemplateTimeSectionList: [],
        timeSectionList: [],
        status: null,
        submitTimeSectionList: [],
        scNameDisable: true,
    },
    QUEUE_TRANSFER_PROFILE_INPUT: {
        enName: null,
        tcName: null,
        scName: null,
        refTemplateCode: null,
        refTemplateTimeSectionList: [],
        timeSectionList: [],
        status: null,
        submitTimeSectionList: [],
        scNameDisable: true,
    },
    COUNTER_PROFILE_INPUT: {
        enName: null,
        tcName: null,
        scName: null,
        status: null,
        refCounterList: [],
        counterList: [],
        scNameDisable: true,
    },
    COUNTER_INPUT: {
        name: null,
        status: null,
        templateCode: null,
        scNameDisable: true,
        enDescription: null,
        restUrlId: null,
    },
    STAFF_INPUT: {
        username: null,
        staffRole: STAFF_ROLE.ROLE_OPERATOR,
        refCode: null,
        surname: null,
        firstName: null,
        displayName: null,
        email: null,
        restUrlId: null,
        queueProfileCode: null,
        queueTransferProfileCode: null,
        counterProfileCode: null,
        syncNameFieldsWithUsername: false,
    },
    MERCHANT_DEVICE_INPUT: {},
    MERCHANT_USER_INPUT: {
        username: null,
        password: null,
        email: null,
        restUrlIdList: [],
        userRole: MERCHANT_USER_ROLE.ROLE_MERCHANT_USER,
        merchantRole: STAFF_ROLE.ROLE_OPERATOR,
        privilegeAttribute: PRIVILEGE_ATTRIBUTE.SHOP,
        privilegeRestUrlId: null,
        privilegeGroupCode: null,
        merchantUserList: [],
    },
    MERCHANT_SALES_USER_INPUT: {
        username: null,
        password: null,
    },
    MERCHANT_USER_GROUP_INPUT: {
        id: null,
        groupDesc: null,
        name: null,
        restUrlIdList: [],
        emailList: [],
        settlementConfigList: [],
        parentUserGroupCodeList: [],
    },
    MERCHANT_USER_GROUP_SETTLEMENT_CONFIG_INPUT: {
        serviceType: null,
        description: null,
        profile: null,
        alipayHkRate: null,
        wechatPayRate: null,
        stripeRate: null,
        yedRate: null,
        mpayRate: null,
        guluRate: null,
        saleSettlementProportion: null,
        redemptionSettlementProportion: null,
        expiredSettlementRate: null,
        startTimestamp: null,
        endTimestamp: null,
    },
    LANDMARK_DISTRICT_MAP_INPUT: {
        districtId: '',
    },
    LANDMARK_RESTAURANT_MAP_INPUT: {
        restUrlId: '',
    },
    PAYMENT_INPUT: {
        bankInDate: '',
    },
    CONTACT_US_INPUT: {
        id: null,
        remarks: null,
        status: null,
    },
    SELECT_TAG_CONFIG_INPUT: {
        draft: [],
        //null
    },
    MERCHANT_INFO_INPUT: {
        contactPerson: null,
        contact: null,
        jobTitle: null,
        bankCode: null,
        bankName: null,
        bankAccount: null,
        bankAccountName: null,
        bankContactPerson: null,
        bankContact: null,
    },
    RESTAURANT_APPROVE_INPUT: {},
    BRAND_APPROVE_INPUT: {},
    PAYMENT_CREATE_INPUT: {
        restUrlId: null,
        planCode: null,
        paymentCode: null,
        bankCode: null,
        chequeNumber: null,
        bankAccountNumber: null,
        chequeImage: null,
    },
    CHOICES: {
        RESTAURANT_MEDIA: {
            TYPE: [
                // { value: "FOLDER_LOGO_IMAGE", name: "FOLDER_LOGO_IMAGE" },
                // { value: "FOLDER_LARGE_IMAGE", name: "FOLDER_LARGE_IMAGE" },
                { value: 'REST_LARGE_IMAGE', name: 'REST_LARGE_IMAGE' },
                { value: 'REST_LOGO_IMAGE', name: 'REST_LOGO_IMAGE' },
                { value: 'DEFAULT_MENU_ITEM_ICON', name: 'DEFAULT_MENU_ITEM_ICON' },
                { value: 'DEFAULT_MENU_ITEM_BANNER', name: 'DEFAULT_MENU_ITEM_BANNER' },
                { value: 'ECOUPON_LARGE_IMAGE', name: 'ECOUPON_LARGE_IMAGE' },
                // { value: "BRAND_ICON", name: "BRAND_ICON" },
                { value: 'APPOINTMENT_REST_IMAGE', name: 'APPOINTMENT_REST_IMAGE' },
                {
                    value: 'RESTAURANT_CAMPAIGN_IMAGE_TC',
                    name: 'RESTAURANT_CAMPAIGN_IMAGE_TC',
                },
                {
                    value: 'RESTAURANT_CAMPAIGN_IMAGE_SC',
                    name: 'RESTAURANT_CAMPAIGN_IMAGE_SC',
                },
                {
                    value: 'RESTAURANT_CAMPAIGN_IMAGE_EN',
                    name: 'RESTAURANT_CAMPAIGN_IMAGE_EN',
                },
                { value: 'REST_QUEUE_IMAGE_TC', name: 'REST_QUEUE_IMAGE_TC' },
                { value: 'REST_QUEUE_IMAGE_SC', name: 'REST_QUEUE_IMAGE_SC' },
                { value: 'REST_QUEUE_IMAGE_EN', name: 'REST_QUEUE_IMAGE_EN' },
                { value: 'WEB_CAMPAIGN_BANNER', name: 'WEB_CAMPAIGN_BANNER' },
                {
                    value: 'WEB_CAMPAIGN_QUEUE_BANNER',
                    name: 'WEB_CAMPAIGN_QUEUE_BANNER',
                },
                { value: 'REST_AOG_DEFAULT', name: 'REST_AOG_DEFAULT' },
                {
                    value: 'WEB_RACK_PRODUCT_ORDER_HEADER_BANNER',
                    name: 'WEB_RACK_PRODUCT_ORDER_HEADER_BANNER',
                },
                {
                    value: 'WEB_RACK_PRODUCT_ORDER_FOOTER_BANNER',
                    name: 'WEB_RACK_PRODUCT_ORDER_FOOTER_BANNER',
                },
            ],
            FILE_TYPE: [
                { value: 'IMAGE', name: 'IMAGE' },
                // { value: "PDF", name: "PDF" },
            ],
            FILTER_STATUS: [
                { value: 'A', name: { key: 'active' } },
                { value: 'P', name: { key: 'pending' } },
            ],
            STATUS: [
                { value: 'A', name: { key: 'active' } },
                { value: 'D', name: { key: 'deactive' } },
                { value: 'P', name: { key: 'pending' } },
            ],
        },
        RESTAURANT_BLACKOUT: {
            TYPE: [
                { value: 'SHOP', name: 'SHOP' },
                { value: 'PICKUP_ITEM', name: 'PICKUP_ITEM' },
            ],
            STATUS: [
                { value: 'A', name: { key: 'active' } },
                { value: 'D', name: { key: 'deactive' } },
                { value: 'P', name: { key: 'pending' } },
            ],
        },
        RESTAURANT_SLIDESHOW: {
            TYPE: [
                { value: 'IMAGE', name: 'IMAGE' },
                { value: 'VIDEO', name: 'VIDEO' },
            ],
            STATUS: [
                { value: 'A', name: { key: 'active' } },
                { value: 'D', name: { key: 'deactive' } },
                { value: 'P', name: { key: 'pending' } },
            ],
            FILTER_STATUS: [
                { value: 'A', name: { key: 'active' } },
                { value: 'P', name: { key: 'pending' } },
            ],
            SHOW_EXPIRED: [
                { value: true, name: 'true' },
                { value: false, name: 'false' },
            ],
            SHOW_DEFAULT: [
                { value: true, name: 'true' },
                { value: false, name: 'false' },
            ],
        },
        ADMIN_DEVICE: {
            DEVICE_TYPE: [
                { value: 'KIOSK', name: 'KIOSK' },
                { value: 'PR', name: 'PR' },
                { value: 'TV', name: 'TV' },
                { value: 'SERVICE', name: 'SERVICE' },
                { value: 'MALL_MODE', name: 'MALL_MODE' },
            ],
            ACTIVATION_STATUS: [
                { value: 'A', name: { key: 'active' } },
                { value: 'P', name: { key: 'pending' } },
            ],
            REPORT_STATUS: [
                { value: 'A', name: { key: 'active' } },
                { value: 'D', name: { key: 'deactive' } },
            ],
            TAKEAWAY_STATUS: [
                { value: 'A', name: { key: 'active' } },
                { value: 'D', name: { key: 'deactive' } },
            ],
            QUEUE_STATUS: [
                { value: 'A', name: { key: 'active' } },
                { value: 'D', name: { key: 'deactive' } },
            ],
            PREORDER_STATUS: [
                { value: 'A', name: { key: 'active' } },
                { value: 'D', name: { key: 'deactive' } },
            ],
            TAG_STATUS: [
                { value: 'A', name: { key: 'active' } },
                { value: 'D', name: { key: 'deactive' } },
            ],
            RESERVATION_STATUS: [
                { value: 'A', name: { key: 'active' } },
                { value: 'D', name: { key: 'deactive' } },
            ],
            BANQUET_STATUS: [
                { value: 'A', name: { key: 'active' } },
                { value: 'D', name: { key: 'deactive' } },
            ],
            ECOUPON_STATUS: [
                { value: 'A', name: { key: 'active' } },
                { value: 'D', name: { key: 'deactive' } },
            ],
            MENU_STATUS: [
                { value: 'A', name: { key: 'active' } },
                { value: 'D', name: { key: 'deactive' } },
            ],
            ECOUPON_DISTRIBUTE_STATUS: [
                { value: 'A', name: { key: 'active' } },
                { value: 'D', name: { key: 'deactive' } },
            ],
        },
        CAMPAIGN_DESCRIPTION: {
            TYPE: [
                { value: GENERAL, name: GENERAL },
                { value: WEB_CAMPAIGN_TITLE, name: WEB_CAMPAIGN_TITLE },
                { value: WEB_CAMPAIGN_DESCRIPTION, name: WEB_CAMPAIGN_DESCRIPTION },
            ],
            STATUS: [
                { value: 'A', name: { key: 'active' } },
                { value: 'D', name: { key: 'deactive' } },
                { value: 'P', name: { key: 'pending' } },
            ],
            MESSAGE_CODE: [{ value: 'queue_full_quota', name: 'queue_full_quota' }],
        },
        TNC: {
            TYPE: [
                { value: TNC_TYPE.BANQUET, name: TNC_TYPE.BANQUET },
                { value: TNC_TYPE.BEAUTY_RESERVE, name: TNC_TYPE.BEAUTY_RESERVE },
                { value: TNC_TYPE.BEAUTY_RES_CHARGE, name: TNC_TYPE.BEAUTY_RES_CHARGE },
                { value: TNC_TYPE.H5_TAKEAWAY, name: TNC_TYPE.H5_TAKEAWAY },
                { value: TNC_TYPE.H5_TAK_CHARGE, name: TNC_TYPE.H5_TAK_CHARGE },
                { value: TNC_TYPE.MASK_CHECKIN, name: TNC_TYPE.MASK_CHECKIN },
                {
                    value: TNC_TYPE.MERCHANT_TICKET_TEMPLATE,
                    name: TNC_TYPE.MERCHANT_TICKET_TEMPLATE,
                },
                { value: TNC_TYPE.PRODUCT_TEMPLATE, name: TNC_TYPE.PRODUCT_TEMPLATE },
                {
                    value: TNC_TYPE.PRODUCT_TEMPLATE_ECOUPON,
                    name: TNC_TYPE.PRODUCT_TEMPLATE_ECOUPON,
                },
                {
                    value: TNC_TYPE.PRODUCT_TEMPLATE_PRODUCT,
                    name: TNC_TYPE.PRODUCT_TEMPLATE_PRODUCT,
                },
                {
                    value: TNC_TYPE.PRODUCT_TEMPLATE_PUSH_MESSAGE,
                    name: TNC_TYPE.PRODUCT_TEMPLATE_PUSH_MESSAGE,
                },
                {
                    value: TNC_TYPE.PRODUCT_TEMPLATE_VALUE_UP_ECOUPON,
                    name: TNC_TYPE.PRODUCT_TEMPLATE_VALUE_UP_ECOUPON,
                },
                {
                    value: TNC_TYPE.PRODUCT_TEMPLATE_VALUE_UP_ECOUPON_OLD,
                    name: TNC_TYPE.PRODUCT_TEMPLATE_VALUE_UP_ECOUPON_OLD,
                },
                { value: TNC_TYPE.RESERVE, name: TNC_TYPE.RESERVE },
                { value: TNC_TYPE.RESERVE2, name: TNC_TYPE.RESERVE2 },
                { value: TNC_TYPE.RESERVE_D, name: TNC_TYPE.RESERVE_D },
                { value: TNC_TYPE.RES_CHARGE, name: TNC_TYPE.RES_CHARGE },
                { value: TNC_TYPE.TAKEAWAY, name: TNC_TYPE.TAKEAWAY },
                { value: TNC_TYPE.TAKEAWAY_MESSAGE, name: TNC_TYPE.TAKEAWAY_MESSAGE },
                { value: TNC_TYPE.TAK_CHARGE, name: TNC_TYPE.TAK_CHARGE },
                { value: TNC_TYPE.TICKET, name: TNC_TYPE.TICKET },
                { value: TNC_TYPE.TICKET2, name: TNC_TYPE.TICKET2 },
                { value: TNC_TYPE.TICKET_D, name: TNC_TYPE.TICKET_D },
                {
                    value: TNC_TYPE.TICKET_LEFT_REMIND,
                    name: TNC_TYPE.TICKET_LEFT_REMIND,
                },
                { value: TNC_TYPE.TICKET_NOW, name: TNC_TYPE.TICKET_NOW },
                { value: TNC_TYPE.TICKET_WEB, name: TNC_TYPE.TICKET_WEB },
                { value: TNC_TYPE.TIC_CFM_1, name: TNC_TYPE.TIC_CFM_1 },
                { value: TNC_TYPE.TIC_CFM_FIN, name: TNC_TYPE.TIC_CFM_FIN },
                { value: TNC_TYPE.TIC_CHECKIN, name: TNC_TYPE.TIC_CHECKIN },
                {
                    value: TNC_TYPE.TIC_CHECKIN_INVITEE,
                    name: TNC_TYPE.TIC_CHECKIN_INVITEE,
                },
                { value: TNC_TYPE.TIC_INACT, name: TNC_TYPE.TIC_INACT },
                {
                    value: TNC_TYPE.TMID_ECOUPON_FIRST,
                    name: TNC_TYPE.TMID_ECOUPON_FIRST,
                },
                {
                    value: TNC_TYPE.TMID_ECOUPON_REMARK,
                    name: TNC_TYPE.TMID_ECOUPON_REMARK,
                },
                { value: TNC_TYPE.WEB, name: TNC_TYPE.WEB },
            ],
            STATUS: [
                { value: 'A', name: { key: 'active' } },
                { value: 'D', name: { key: 'deactive' } },
                { value: 'P', name: { key: 'pending' } },
            ],
        },
        RESTAURANT_PAYMENT: {
            SERVICE_TYPE: [
                { value: 'APPOINTMENT', name: 'APPOINTMENT' },
                { value: 'CLP', name: 'CLP' },
                { value: 'QUEUE', name: 'QUEUE' },
                { value: 'RACK_PRODUCT', name: 'RACK_PRODUCT' },
                { value: 'RESERVE', name: 'RESERVE' },
                { value: 'TAKEAWAY', name: 'TAKEAWAY' },
            ],
            STATUS: [
                { value: 'A', name: 'A' },
                { value: 'D', name: 'D' },
            ],
        },
        QUEUE_REJECT_REASON: {
            STATUS: [
                { value: 'A', name: 'A' },
                { value: 'D', name: 'D' },
            ],
        },
        QUEUE_CONFIG: {
            SHOW_TABLE_NO: [
                { value: 'A', name: 'A' },
                { value: 'D', name: 'D' },
            ],
            REPEAT_QUEUE: [
                { value: true, name: 'true' },
                { value: false, name: 'false' },
            ],
            QUEUE_STEPS: [
                { value: 'CURRENT_SECTION', name: 'CURRENT_SECTION' },
                { value: 'DATE', name: 'DATE' },
                { value: 'TABLE_TYPE', name: 'TABLE_TYPE' },
                { value: 'SIZE', name: 'SIZE' },
                { value: 'TICKET_TYPE', name: 'TICKET_TYPE' },
                { value: 'TIME_SECTION', name: 'TIME_SECTION' },
                { value: 'TAGS', name: 'TAGS' },
                { value: 'PERSONAL_DATA', name: 'PERSONAL_DATA' },
                { value: 'PREVIEW', name: 'PREVIEW' },
                { value: 'TNC', name: 'TNC' },
            ],
            RESOURCES_STEPS: [
                { value: 'BLOCK', name: 'BLOCK' },
                { value: 'FLOOR', name: 'FLOOR' },
                { value: 'FLAT', name: 'FLAT' },
            ],
            AUTO_TIME_TYPE: [
                { value: 'CURRENT_DATE', name: 'CURRENT_DATE' },
                { value: 'CAMPAIGN_DATE', name: 'CAMPAIGN_DATE' },
                { value: 'WHOLE_CAMPAIGN', name: 'WHOLE_CAMPAIGN' },
            ],
            VIP_TYPE: [
                { value: 'NONE', name: 'NONE' },
                { value: 'RESERVE_FOR_SHARE', name: 'RESERVE_FOR_SHARE' },
                { value: 'RESERVE_FOR_REQUEST', name: 'RESERVE_FOR_REQUEST' },
                { value: 'INSERT_TO_QUEUE', name: 'INSERT_TO_QUEUE' },
            ],
            CAMPAIGN_QUEUE: [
                { value: true, name: 'true' },
                { value: false, name: 'false' },
            ],
            CAMPAIGN_TYPE: [
                { value: QUEUE_CAMPAIGN_TYPE.NONE, name: QUEUE_CAMPAIGN_TYPE.NONE },
                {
                    value: QUEUE_CAMPAIGN_TYPE.CHECKIN,
                    name: QUEUE_CAMPAIGN_TYPE.CHECKIN,
                },
                {
                    value: QUEUE_CAMPAIGN_TYPE.CHECKIN_WITH_SIZE,
                    name: QUEUE_CAMPAIGN_TYPE.CHECKIN_WITH_SIZE,
                },
                {
                    value: QUEUE_CAMPAIGN_TYPE.RESOURCES_REGISTRATION,
                    name: QUEUE_CAMPAIGN_TYPE.RESOURCES_REGISTRATION,
                },
            ],
            TAG_SEQUENCE_DISPLAY: [
                { value: true, name: 'true' },
                { value: false, name: 'false' },
            ],
            GEN_FROM_TEMPLATE: [
                { value: true, name: 'true' },
                { value: false, name: 'false' },
            ],
            EMAIL: [
                { value: true, name: 'true' },
                { value: false, name: 'false' },
            ],
            MOBILE: [
                { value: true, name: 'true' },
                { value: false, name: 'false' },
            ],
            PERSONAL_LIMIT_TYPE: [
                { value: 'TIME_SECTION', name: 'TIME_SECTION' },
                { value: 'WHOLE_CAMPAIGN', name: 'WHOLE_CAMPAIGN' },
            ],
            QUEUE_TYPE: [
                { value: 'NORMAL', name: 'NORMAL' },
                { value: 'EVENT', name: 'EVENT' },
                { value: 'MASK', name: 'MASK' },
                { value: 'AUTO_TIME', name: 'AUTO_TIME' },
                { value: 'COUNTER', name: 'COUNTER' },
                { value: 'TIME_SLOT', name: 'TIME_SLOT' },
            ],
            DELIVERY_TYPE: [
                { value: 'NONE', name: 'NONE' },
                { value: 'NORMAL', name: 'NORMAL' },
                { value: 'EMAIL', name: 'EMAIL' },
                { value: 'SMS', name: 'SMS' },
                { value: 'WHATSAPP', name: 'WHATSAPP' },
            ],
            REQUIRED_OTP: [
                { value: true, name: 'true' },
                { value: false, name: 'false' },
            ],
            REDIS_SEQUENCE: [
                { value: true, name: 'true' },
                { value: false, name: 'false' },
            ],
            QUOTA_TYPE: [
                { value: 'NORMAL', name: 'NORMAL' },
                { value: 'SIZE', name: 'SIZE' },
            ],
            ROLL_TAG_TYPE: [
                { value: 'NORMAL', name: 'NORMAL' },
                { value: 'BATCH', name: 'BATCH' },
            ],
            INPUT_CHECK_IN_SIZE: [
                { value: true, name: 'true' },
                { value: false, name: 'false' },
            ],
            SHOW_TABLE_TYPE: [
                { value: true, name: 'true' },
                { value: false, name: 'false' },
            ],
            QUEUE_CHECK_IN_TYPE: [
                { value: 'NORMAL', name: 'NORMAL' },
                { value: 'EVENT', name: 'EVENT' },
                { value: 'OFFLINE', name: 'OFFLINE' },
            ],
            QUEUE_CHECK_OUT_TYPE: [
                { value: 'NORMAL', name: 'NORMAL' },
                { value: 'NONE', name: 'NONE' },
            ],
            DISPLAY_TYPE: [
                { value: 'NORMAL', name: 'NORMAL' },
                { value: 'EVENT', name: 'EVENT' },
                { value: 'BATCH', name: 'BATCH' },
            ],
            COUNTER_TAG_TYPE: [
                { value: 'COUNTER_TO_TAG', name: 'COUNTER_TO_TAG' },
                { value: 'TAG_TO_COUNTER', name: 'TAG_TO_COUNTER' },
            ],
            WEB_SHARE: [
                { value: true, name: 'true' },
                { value: false, name: 'false' },
            ],
            MOBILE_SHARE: [
                { value: true, name: 'true' },
                { value: false, name: 'false' },
            ],
            RESET_QUEUE_STATUS: [
                { value: true, name: 'true' },
                { value: false, name: 'false' },
            ],
            TRANSFERABLE: [
                { value: true, name: 'true' },
                { value: false, name: 'false' },
            ],
            USE_TRANSFERABLE_LABEL: [
                { value: true, name: 'true' },
                { value: false, name: 'false' },
            ],
            DELAY_ENABLE: [
                { value: true, name: 'true' },
                { value: false, name: 'false' },
            ],
            RELEASE_QUOTA_ON_DELETE: [
                { value: true, name: 'true' },
                { value: false, name: 'false' },
            ],
            REQUIRED_PASS: [
                { value: true, name: 'true' },
                { value: false, name: 'false' },
            ],
        },
        PICKUP: {
            STATUS: [
                { value: 'A', name: { key: 'active' } },
                { value: 'P', name: { key: 'pending' } },
                { value: 'D', name: { key: 'deactive' } },
            ],
            REVERSE_SEQUENCE: [
                { value: true, name: 'true' },
                { value: false, name: 'false' },
            ],
        },
        PRINT_CONFIG: {
            PRINT_QRCODE_TYPE: [
                { value: 'NORMAL', name: 'NORMAL', tooltip: 'Mobile App' },
                { value: 'GULU_WEB_SHOP', name: 'GULU_WEB_SHOP' },
                { value: 'GULU_WEB_TICKET', name: 'GULU_WEB_TICKET' },
                { value: 'PEAK_TRAM_TICKET', name: 'PEAK_TRAM_TICKET', group: 'Peak Tram' },
                // { value: 'SPRING_BOARD', name: 'SPRING_BOARD' },
                {
                    value: 'EVENT_LINK',
                    name: 'EVENT_LINK',
                    tooltip: 'Mobile App and CNY/Tsang Tsui Web',
                    group: 'CNY/Tsang Tsui Web',
                },
                {
                    value: 'EVENT_WEB',
                    name: 'EVENT_WEB',
                    tooltip: 'CNY/Tsang Tsui',
                    group: 'CNY/Tsang Tsui Web',
                },
                {
                    value: 'PORTAL_WEB',
                    name: 'PORTAL_WEB',
                    tooltip: 'CNY/Tsang Tsui',
                    group: 'CNY/Tsang Tsui Web',
                },
                {
                    value: 'EVENT_SITE',
                    name: 'EVENT_SITE',
                    tooltip: 'Event Web Site Detail',
                    group: 'THE GULU Event Web',
                },
                {
                    value: 'EVENT_SITE_LANG',
                    name: 'EVENT_SITE_LANG',
                    tooltip: 'Event Web Site Detail (specific language)',
                    group: 'THE GULU Event Web',
                },
                {
                    value: 'EVENT_TICKET',
                    name: 'EVENT_TICKET',
                    tooltip: 'Mobile App and Event Web Ticket Page',
                    group: 'THE GULU Event Web',
                },
                {
                    value: 'EVENT_TICKET_LANG',
                    name: 'EVENT_TICKET_LANG',
                    tooltip: 'Mobile App and Event Web Ticket Page (specific language)',
                    group: 'THE GULU Event Web',
                },
                {
                    value: 'EVENT_TICKET_WEB_ONLY',
                    name: 'EVENT_TICKET_WEB_ONLY',
                    tooltip: 'Event Web Ticket Page Only',
                    group: 'THE GULU Event Web',
                },
                {
                    value: 'EVENT_TICKET_LANG_WEB_ONLY',
                    name: 'EVENT_TICKET_LANG_WEB_ONLY',
                    tooltip: 'Event Web Ticket Page Only (specific language)',
                    group: 'THE GULU Event Web',
                },
            ],
            DEVICE_TYPE: [
                { value: 'N', name: 'NORMAL' },
                { value: 'L', name: 'LITE' },
            ],
            PRINT_TEMPLATE_TYPE: [
                { value: 'NORMAL', name: 'NORMAL' },
                { value: 'SHORT', name: 'SHORT' },
            ],
        },
        QUEUE_TEMPLATE: {
            TIME_SECTION_ID: [
                { value: 'A', name: 'A' },
                { value: 'B', name: 'B' },
                { value: 'C', name: 'C' },
                { value: 'D', name: 'D' },
                { value: 'E', name: 'E' },
                { value: 'F', name: 'F' },
                { value: 'G', name: 'G' },
                { value: 'H', name: 'H' },
                { value: 'I', name: 'I' },
                { value: 'J', name: 'J' },
                { value: 'K', name: 'K' },
                { value: 'L', name: 'L' },
                { value: 'M', name: 'M' },
                { value: 'N', name: 'N' },
                { value: 'O', name: 'O' },
                { value: 'P', name: 'P' },
                { value: 'Q', name: 'Q' },
                { value: 'R', name: 'R' },
                { value: 'S', name: 'S' },
                { value: 'T', name: 'T' },
                { value: 'U', name: 'U' },
                { value: 'V', name: 'V' },
                { value: 'W', name: 'W' },
                { value: 'X', name: 'X' },
                { value: 'Y', name: 'Y' },
                { value: 'Z', name: 'Z' },
            ],
            GROUPED_SECTION: [
                { value: 'A', name: 'A' },
                { value: 'B', name: 'B' },
                { value: 'C', name: 'C' },
                { value: 'D', name: 'D' },
                { value: 'E', name: 'E' },
                { value: 'F', name: 'F' },
                { value: 'G', name: 'G' },
                { value: 'H', name: 'H' },
                { value: 'I', name: 'I' },
                { value: 'J', name: 'J' },
                { value: 'K', name: 'K' },
                { value: 'L', name: 'L' },
                { value: 'M', name: 'M' },
                { value: 'N', name: 'N' },
                { value: 'O', name: 'O' },
                { value: 'P', name: 'P' },
                { value: 'Q', name: 'Q' },
                { value: 'R', name: 'R' },
                { value: 'S', name: 'S' },
                { value: 'T', name: 'T' },
                { value: 'U', name: 'U' },
                { value: 'V', name: 'V' },
                { value: 'W', name: 'W' },
                { value: 'X', name: 'X' },
                { value: 'Y', name: 'Y' },
                { value: 'Z', name: 'Z' },
            ],
            TYPE: [
                { value: 'SINGLE', name: 'SINGLE' },
                { value: 'RESERVATION_TO_QUEUE', name: 'RESERVATION_TO_QUEUE' },
            ],
            SIZE_SELECTABLE: [
                { value: true, name: 'true' },
                { value: false, name: 'false' },
            ],
            STATUS: [
                { value: 'A', name: { key: 'active' } },
                { value: 'D', name: { key: 'deactive' } },
            ],
            DEFAULT_STATUS: [
                { value: 'A', name: { key: 'active' } },
                { value: 'P', name: { key: 'pending' } },
            ],
            TICKET_TYPE_STATUS: [
                { value: 'A', name: { key: 'active' } },
                { value: 'D', name: { key: 'deactive' } },
            ],
            MOBILE_DISPLAY: [
                { value: true, name: 'true' },
                { value: false, name: 'false' },
            ],
            MOBILE_TAG_DISPLAY: [
                { value: true, name: 'true' },
                { value: false, name: 'false' },
            ],
            MOBILE_READ_ONLY: [
                { value: true, name: 'true' },
                { value: false, name: 'false' },
            ],
            KIOSK_DISPLAY: [
                { value: true, name: 'true' },
                { value: false, name: 'false' },
            ],
            KIOSK_TAG_DISPLAY: [
                { value: true, name: 'true' },
                { value: false, name: 'false' },
            ],
            KIOSK_READ_ONLY: [
                { value: true, name: 'true' },
                { value: false, name: 'false' },
            ],
            QUEUE_TYPE: [
                { value: 'NORMAL', name: 'NORMAL' },
                { value: 'EVENT', name: 'EVENT' },
                { value: 'AUTO_TIME', name: 'AUTO_TIME' },
                { value: 'COUNTER', name: 'COUNTER' },
                { value: 'TIME_SLOT', name: 'TIME_SLOT' },
            ],
            DISPLAY_TYPE: [
                { value: 'NORMAL', name: 'NORMAL' },
                { value: 'EVENT', name: 'EVENT' },
                { value: 'BATCH', name: 'BATCH' },
                { value: 'PEAK_TRAM', name: 'PEAK_TRAM' },
            ],
            ROLL_TAG_TYPE: [
                { value: 'NORMAL', name: 'NORMAL' },
                { value: 'BATCH', name: 'BATCH' },
            ],
            DEFAULT_TABLE_TYPE_FOREGROUND_COLOR: [
                {
                    value: '#FD599A',
                    name: '#FD599A',
                    hex: '#FD599A',
                    hexList: ['#FD599A'],
                    tableTypeName: 'Table Type A',
                },
                {
                    value: '#17C400',
                    name: '#17C400',
                    hex: '#17C400',
                    hexList: ['#17C400'],
                    tableTypeName: 'Table Type B',
                },
                {
                    value: '#1981FB',
                    name: '#1981FB',
                    hex: '#1981FB',
                    hexList: ['#1981FB'],
                    tableTypeName: 'Table Type C',
                },
                {
                    value: '#FF9138',
                    name: '#FF9138',
                    hex: '#FF9138',
                    hexList: ['#FF9138'],
                    tableTypeName: 'Table Type D',
                },
                {
                    value: '#BC67F3',
                    name: '#BC67F3',
                    hex: '#BC67F3',
                    hexList: ['#BC67F3'],
                    tableTypeName: 'Table Type E',
                },
                {
                    value: '#F7D200',
                    name: '#F7D200',
                    hex: '#F7D200',
                    hexList: ['#F7D200'],
                    tableTypeName: 'Table Type F',
                },
            ],
            DEFAULT_TABLE_TYPE_BACKGROUND_COLOR: [
                {
                    value: '#6C1035',
                    name: '#6C1035',
                    hex: '#6C1035',
                    hexList: ['#6C1035'],
                    tableTypeName: 'Table Type A',
                },
                {
                    value: '#015110',
                    name: '#015110',
                    hex: '#015110',
                    hexList: ['#015110'],
                    tableTypeName: 'Table Type B',
                },
                {
                    value: '#051E3C',
                    name: '#051E3C',
                    hex: '#051E3C',
                    hexList: ['#051E3C'],
                    tableTypeName: 'Table Type C',
                },
                {
                    value: '#753908',
                    name: '#753908',
                    hex: '#753908',
                    hexList: ['#753908'],
                    tableTypeName: 'Table Type D',
                },
                {
                    value: '#641F91',
                    name: '#641F91',
                    hex: '#641F91',
                    hexList: ['#641F91'],
                    tableTypeName: 'Table Type E',
                },
                {
                    value: '#7A6801',
                    name: '#7A6801',
                    hex: '#7A6801',
                    hexList: ['#7A6801'],
                    tableTypeName: 'Table Type F',
                },
            ],
            DEFAULT_TABLE_TYPE_COLOR_SET: [
                { value: '#FD599A&#6C1035', name: 'Color Set 1', hexList: ['#FD599A', '#6C1035'] },
                { value: '#17C400&#015110', name: 'Color Set 2', hexList: ['#17C400', '#015110'] },
                { value: '#1981FB&#051E3C', name: 'Color Set 3', hexList: ['#1981FB', '#051E3C'] },
                { value: '#FF9138&#753908', name: 'Color Set 4', hexList: ['#FF9138', '#753908'] },
                { value: '#BC67F3&#641F91', name: 'Color Set 5', hexList: ['#BC67F3', '#641F91'] },
                { value: '#F7D200&#7A6801', name: 'Color Set 6', hexList: ['#F7D200', '#7A6801'] },
            ],
        },
        QUEUE_TEMPLATE_MAP: {
            TYPE: [
                {
                    value: QUEUE_TEMPLATE_MAP_TYPE.DAY_OF_WEEK,
                    name: QUEUE_TEMPLATE_MAP_TYPE.DAY_OF_WEEK,
                },
                {
                    value: QUEUE_TEMPLATE_MAP_TYPE.DATE_RANGE,
                    name: QUEUE_TEMPLATE_MAP_TYPE.DATE_RANGE,
                },
            ],
            DAY_OF_WEEK: [
                { value: 'Mon', name: 'Mon' },
                { value: 'Tue', name: 'Tue' },
                { value: 'Wed', name: 'Wed' },
                { value: 'Thu', name: 'Thu' },
                { value: 'Fri', name: 'Fri' },
                { value: 'Sat', name: 'Sat' },
                { value: 'Sun', name: 'Sun' },
                { value: 'PH', name: 'PH' },
            ],
        },
        QUEUE_PROFILE: {
            STATUS: [
                { value: 'A', name: { key: 'active' } },
                { value: 'D', name: { key: 'deactive' } },
            ],
        },
        QUEUE_TRANSFER_PROFILE: {
            STATUS: [
                { value: 'A', name: { key: 'active' } },
                { value: 'D', name: { key: 'deactive' } },
            ],
        },
        COUNTER_PROFILE: {
            STATUS: [
                { value: 'A', name: { key: 'active' } },
                { value: 'D', name: { key: 'deactive' } },
            ],
        },
        COUNTER: {
            SOUNDTYPE: [
                { value: '$toTicketLabel', name: 'To Ticket Label' },
                { value: '$counterName', name: 'Counter Name' },
            ],
            STATUS: [
                { value: 'A', name: { key: 'active' } },
                { value: 'D', name: { key: 'deactive' } },
            ],
        },
        RESERVATION_CONFIG: {
            HAVE_CONFIRM: [
                { value: 'A', name: { key: 'active' } },
                { value: 'D', name: { key: 'deactive' } },
            ],
            SHOW_TABLE_NUMBER: [
                { value: 'A', name: { key: 'active' } },
                { value: 'D', name: { key: 'deactive' } },
            ],
            SHOW_REMARKS: [
                { value: 'A', name: { key: 'active' } },
                { value: 'D', name: { key: 'deactive' } },
            ],
            HAVE_CHARGE: [
                { value: 'A', name: { key: 'active' } },
                { value: 'D', name: { key: 'deactive' } },
            ],
            CHARGE_TYPE: [
                { value: 'NONE', name: 'NONE' },
                { value: 'SURVEY', name: 'SURVEY' },
            ],
            CHARGE_BY_DEFAULT: [
                { value: 'A', name: { key: 'active' } },
                { value: 'D', name: { key: 'deactive' } },
                { value: 'WD', name: 'Weekday' },
            ],
            RESERVE_STATUS: [
                { value: 'A', name: { key: 'active' } },
                { value: 'D', name: { key: 'deactive' } },
            ],
            EDITABLE: [
                { value: true, name: 'true' },
                { value: false, name: 'false' },
            ],
            DELIVERY_TYPE: [
                { value: 'NONE', name: 'NONE' },
                { value: 'NORMAL', name: 'NORMAL' },
                { value: 'EMAIL', name: 'EMAIL' },
                { value: 'SMS', name: 'SMS' },
                // { value: 'WHATSAPP', name: 'WHATSAPP' },
            ],
            REQUIRED_OTP: [
                { value: true, name: 'true' },
                { value: false, name: 'false' },
            ],
            RESERVATION_MODE: [
                { value: 'SIZE', name: 'SIZE' },
                { value: 'SERVICE', name: 'SERVICE' },
                { value: 'NO_SELECTION', name: 'NO_SELECTION' },
                { value: 'SERVICE_WITH_SIZE', name: 'SERVICE_WITH_SIZE' },
            ],
            WEB_SHARE: [
                { value: true, name: 'true' },
                { value: false, name: 'false' },
            ],
            WEB_MULTI_RESERVE: [
                { value: true, name: 'true' },
                { value: false, name: 'false' },
            ],
            SEND_QRCODE_TO_EMAIL: [
                { value: true, name: 'true' },
                { value: false, name: 'false' },
            ],
        },
        RESERVATION_TEMPLATE: {
            GROUPED_SECTION: [
                { value: 'A', name: 'A' },
                { value: 'B', name: 'B' },
                { value: 'C', name: 'C' },
                { value: 'D', name: 'D' },
                { value: 'E', name: 'E' },
                { value: 'F', name: 'F' },
                { value: 'G', name: 'G' },
                { value: 'H', name: 'H' },
                { value: 'I', name: 'I' },
                { value: 'J', name: 'J' },
                { value: 'K', name: 'K' },
                { value: 'L', name: 'L' },
                { value: 'M', name: 'M' },
                { value: 'N', name: 'N' },
                { value: 'O', name: 'O' },
                { value: 'P', name: 'P' },
                { value: 'Q', name: 'Q' },
                { value: 'R', name: 'R' },
                { value: 'S', name: 'S' },
                { value: 'T', name: 'T' },
                { value: 'U', name: 'U' },
                { value: 'V', name: 'V' },
                { value: 'W', name: 'W' },
                { value: 'X', name: 'X' },
                { value: 'Y', name: 'Y' },
                { value: 'Z', name: 'Z' },
            ],
            TIME_SECTION_ID: [
                { value: 'A', name: 'A' },
                { value: 'B', name: 'B' },
                { value: 'C', name: 'C' },
                { value: 'D', name: 'D' },
                { value: 'E', name: 'E' },
                { value: 'F', name: 'F' },
                { value: 'G', name: 'G' },
                { value: 'H', name: 'H' },
                { value: 'I', name: 'I' },
                { value: 'J', name: 'J' },
                { value: 'K', name: 'K' },
                { value: 'L', name: 'L' },
                { value: 'M', name: 'M' },
                { value: 'N', name: 'N' },
                { value: 'O', name: 'O' },
                { value: 'P', name: 'P' },
                { value: 'Q', name: 'Q' },
                { value: 'R', name: 'R' },
                { value: 'S', name: 'S' },
                { value: 'T', name: 'T' },
                { value: 'U', name: 'U' },
                { value: 'V', name: 'V' },
                { value: 'W', name: 'W' },
                { value: 'X', name: 'X' },
                { value: 'Y', name: 'Y' },
                { value: 'Z', name: 'Z' },
            ],
            RESERVATION_TYPE: [
                { value: 'TABLE', name: 'TABLE' },
                { value: 'PPL', name: 'PPL' },
            ],
            AUTO_ACCEPT_FLAG: [
                { value: 'A', name: { key: 'active' } },
                { value: 'D', name: { key: 'deactive' } },
            ],
            AUTO_REJECT_FLAG: [
                { value: 'A', name: { key: 'active' } },
                { value: 'D', name: { key: 'deactive' } },
            ],
            DEFAULT_STATUS: [
                { value: 'A', name: { key: 'active' } },
                { value: 'P', name: { key: 'pending' } },
            ],
            STATUS: [
                { value: 'A', name: { key: 'active' } },
                { value: 'D', name: { key: 'deactive' } },
            ],
            TICKET_TYPE_STATUS: [
                { value: 'A', name: { key: 'active' } },
                { value: 'D', name: { key: 'deactive' } },
            ],
        },
        RESERVATION_TEMPLATE_MAP: {
            TYPE: [
                {
                    value: RESERVATION_TEMPLATE_MAP_TYPE.DAY_OF_WEEK,
                    name: RESERVATION_TEMPLATE_MAP_TYPE.DAY_OF_WEEK,
                },
                {
                    value: RESERVATION_TEMPLATE_MAP_TYPE.DATE_RANGE,
                    name: RESERVATION_TEMPLATE_MAP_TYPE.DATE_RANGE,
                },
            ],
            DAY_OF_WEEK: [
                { value: 'Mon', name: 'Mon' },
                { value: 'Tue', name: 'Tue' },
                { value: 'Wed', name: 'Wed' },
                { value: 'Thu', name: 'Thu' },
                { value: 'Fri', name: 'Fri' },
                { value: 'Sat', name: 'Sat' },
                { value: 'Sun', name: 'Sun' },
                { value: 'PH', name: 'PH' },
            ],
        },
        TICKET_TYPE: {
            STATUS: [
                { value: 'A', name: { key: 'active' } },
                { value: 'D', name: { key: 'deactive' } },
                { value: 'P', name: { key: 'pending' } },
            ],
        },
        PRINT_TEMPLATE: {
            FILTER_TYPE: [
                { value: 'NORMAL', name: 'NORMAL' },
                { value: 'LITE', name: 'LITE' },
                { value: 'PDF', name: 'PDF' },
                { value: 'SHORT', name: 'SHORT' },
            ],
            TYPE: [
                { value: 'NORMAL', name: 'NORMAL' },
                // { value: "LITE", name: "LITE" },
                // { value: "PDF", name: "PDF" }
            ],
            STATUS: [
                { value: 'A', name: { key: 'active' } },
                // { value: 'D', name: { key: 'deactive' } },
            ],
            TEMPLATE_TYPE: [
                { value: PRINT_TEMPLATE_TYPE.FONT_SIZE, name: 'Font Size' },
                { value: PRINT_TEMPLATE_TYPE.ALIGNMENT, name: 'Alignment' },
                { value: PRINT_TEMPLATE_TYPE.PRINT_TEXT, name: 'Print Text' },
                // { value: PRINT_TEMPLATE_TYPE.PRINT_IMAGE, name: "Print Image" },
                { value: PRINT_TEMPLATE_TYPE.LOGO, name: 'Logo' },
                { value: PRINT_TEMPLATE_TYPE.HEADER, name: 'Header' },
                { value: PRINT_TEMPLATE_TYPE.FOOTER, name: 'Footer' },
                { value: PRINT_TEMPLATE_TYPE.PRINT_QR_CODE, name: 'Print QRcode' },
                {
                    value: PRINT_TEMPLATE_TYPE.FONT_SIZE_DIRECT,
                    name: 'Font Size (Custom)',
                },
                {
                    value: PRINT_TEMPLATE_TYPE.PRINT_BAR_CODE,
                    name: 'Print Barcode',
                    tooltip: 'Max 14 Character.',
                },
                // { value: 7, name: "Print Barcode (code 128 /code 39 according to appsetting)" },
                // { value: 71, name: "Print Barcode (thick barcode)" },
                {
                    value: PRINT_TEMPLATE_TYPE.PRINT_TEXT_AS_IMAGE,
                    name: 'Print Text As Image',
                    tooltip:
                        'Only for Star printer. \nUse Font Size (Custom) instead of Font Size.',
                },
                // { value: 10, name: "Print Barcode in code 128 format" },
                {
                    value: PRINT_TEMPLATE_TYPE.FONT_STYLE,
                    name: 'Font Style',
                    tooltip: 'Only for Star printer. \n Only use in Print Text As Image.',
                },
                { value: PRINT_TEMPLATE_TYPE.DATE_FORMAT, name: 'Date Format' },
                {
                    value: PRINT_TEMPLATE_TYPE.LOCALE,
                    name: 'Locale',
                    tooltip: 'Only for Shop App.',
                },
                {
                    value: PRINT_TEMPLATE_TYPE.PRINT_TEXT_AS_IMAGE_NO_LINE_WRAP,
                    name: 'Print Text As Image in Single line',
                    tooltip:
                        'Only for Shop App. \nFont Size (Custom) instead of Font Size. \n Font Size will be adjusted automatically to fit in.',
                },
                { value: PRINT_TEMPLATE_TYPE.LINE_FEED, name: 'Line Feed' },
                { value: PRINT_TEMPLATE_TYPE.TERMS_AND_CONDITIONS, name: 'TnC' },
            ],
            TEMPLATE_TYPE_0: [
                { value: 1, name: '1' },
                { value: 2, name: '2' },
                { value: 3, name: '3' },
                { value: 4, name: '4' },
                { value: 5, name: '5' },
                { value: 6, name: '6' },
                { value: 7, name: '7' },
                { value: 8, name: '8' },
                { value: 9, name: '9' },
                { value: 10, name: '10' },
                { value: 101, name: 'Tiny (10px)' },
                { value: 102, name: 'Small (12px)' },
            ],
            TEMPLATE_TYPE_2: [
                { value: 0, name: '[0] left' },
                { value: 1, name: '[1] center' },
                { value: 2, name: '[2] right' },
            ],
            TEMPLATE_TYPE_11: [
                { value: 0, name: '[0] normal' },
                { value: 1, name: '[1] bold' },
            ],
            TEMPLATE_TYPE_14: [
                { value: 'en', name: 'English' },
                { value: 'zh_HK', name: 'Chinese - Traditional' },
                { value: 'zh_CN', name: 'Chinese - Simplified' },
            ],
            TEMPLATE_TEXT_TYPE: [
                { value: 'TEXT', name: 'TEXT' },
                { value: 'PREDEFINED_TEXT', name: 'PREDEFINED_TEXT' },
                { value: 'LABEL', name: 'LABEL' },
                { value: 'NEW_LINE', name: 'NEW_LINE' },
            ],
            TEMPLATE_PREDEFINED_TEXT: [
                { value: 'logo', name: 'Logo' },
                { value: 'date', name: 'Date' },
                { value: 'ticketLabel', name: 'Ticket Label', example: 'A001' },
                { value: 'qrcode', name: 'Qrcode' },
                {
                    value: 'remarks',
                    name: 'TnC',
                    example: 'Remarks Remarks Remarks Remarks Remarks',
                },
                { value: 'tc_name', name: 'Tc Name', example: 'TC Rest Name' },
                { value: 'e_name', name: 'En Name', example: 'Eng Rest Name' },
                { value: 'phone', name: 'Phone', example: '12345678' },
                { value: 'address', name: 'Address', example: 'Address' },
                { value: 'tc_address', name: 'Tc Address', example: 'TC Address' },
                { value: 'sc_address', name: 'Sc Address', example: 'SC Address' },
                { value: 'e_address', name: 'En Address', example: 'Eng Address' },
                { value: 'tableSize', name: 'Table Size', example: '10' },
                { value: 'enTableTypeName', name: 'En Table Type Name' },
                { value: 'tcTableTypeName', name: 'Tc Table Type Name' },
                { value: 'scTableTypeName', name: 'Sc Table Type Name' },
                { value: 'enTimeSessionLabel', name: 'En Time Session Label' },
                { value: 'tcTimeSessionLabel', name: 'Tc Time Session Label' },
                { value: 'scTimeSessionLabel', name: 'Sc Time Session Label' },
                { value: 'enShopAddress', name: 'En Shop Address' },
                { value: 'tcShopAddress', name: 'Tc Shop Address' },
                { value: 'scShopAddress', name: 'Sc Shop Address' },
                { value: 'enShopName', name: 'En Shop Name' },
                { value: 'tcShopName', name: 'Tc Shop Name' },
                { value: 'scShopName', name: 'Sc Shop Name' },
                { value: 'selectedTag', name: 'Selected Tag', example: 'Wheel Chair' },
                {
                    value: 'descTimeSection',
                    name: 'Desc Time Section',
                    example: '09:00-18:00',
                },
                {
                    value: 'timeSectionLabel',
                    name: 'Time Section Label',
                    example: 'ALL DAY',
                },
                { value: 'ticketType', name: 'Ticket Type', example: 'Hot Pot' },
                { value: 'footer', name: 'Footer' },
                { value: 'header', name: 'Header' },
                { value: 'ticketTypeName', name: 'Ticket Type Name' },
                { value: 'enTicketTypeName', name: 'En Ticket Type Name' },
                { value: 'tcTicketTypeName', name: 'Tc Ticket Type Name' },
                { value: 'scTicketTypeName', name: 'Sc Ticket Type Name' },
                { value: 'eventStartTimestamp', name: 'Event Start Timestamp' },
                { value: 'eventExpiredTimestamp', name: 'Event Expired Timestamp' },
                { value: 'customContent', name: 'Custom Content' },
            ],
            TEMPLATE_PREDEFINED_TEXT_IMAGE_USE: [
                { value: 'logo', name: 'Logo' },
                { value: 'footer', name: 'Footer' },
                { value: 'header', name: 'Header' },
            ],
            TEMPLATE_PREDEFINED_TEXT_QRCODE_USE: [{ value: 'qrcode', name: 'Qrcode' }],
            DATE_FORMAT_YEAR: [
                { value: 'yy', name: 'yy (2 Digits)' },
                { value: 'yyyy', name: 'yyyy (Min 4 Digits)' },
            ],
            DATE_FORMAT_MONTH: [
                { value: 'M', name: 'M (Numeric)' },
                { value: 'MM', name: 'MM (Numeric)' },
                { value: 'MMM', name: 'MMM (Short Name)' },
                { value: 'MMMM', name: 'MMMM (Full Name)' },
            ],
            DATE_FORMAT_DATE: [
                { value: 'd', name: 'd' },
                { value: 'dd', name: 'dd' },
            ],
            DATE_FORMAT_WEEK: [
                { value: 'E', name: 'E (Short Name)' },
                { value: 'EEEE', name: 'EEEE (Full Name)' },
            ],
            DATE_FORMAT_HOUR: [
                { value: 'h', name: 'h (12-hour)' },
                { value: 'hh', name: 'hh (12-hour)' },
                { value: 'H', name: 'H (24-hour)' },
                { value: 'HH', name: 'HH (24-hour)' },
            ],
            DATE_FORMAT_HOUR_FORMAT: [{ value: 'a', name: 'AM / PM' }],
            DATE_FORMAT_MINUTE: [
                { value: 'm', name: 'm' },
                { value: 'mm', name: 'mm' },
            ],
            DATE_FORMAT_SECOND: [
                { value: 's', name: 's' },
                { value: 'ss', name: 'ss' },
                { value: 'SSS', name: 'SSS (milliseconds)' },
            ],
            MIDDLE_SYMBOL: [
                { value: '-', name: '-' },
                { value: '/', name: '/' },
                { value: '.', name: '.' },
                { value: ',', name: ',' },
            ],
            WITH_BRACKET: [
                { value: true, name: 'true' },
                { value: false, name: 'false' },
            ],
        },
        EMAIL_TEMPLATE: {
            TYPE: [
                {
                    value: EMAIL_TEMPLATE_TYPE.MERCHANT_EMAIL_CREATE_ACCOUNT_APPROVAL,
                    name: EMAIL_TEMPLATE_TYPE.MERCHANT_EMAIL_CREATE_ACCOUNT_APPROVAL,
                },
                {
                    value: EMAIL_TEMPLATE_TYPE.MERCHANT_EMAIL_FORGOT_PASSWORD,
                    name: EMAIL_TEMPLATE_TYPE.MERCHANT_EMAIL_FORGOT_PASSWORD,
                },
                {
                    value: EMAIL_TEMPLATE_TYPE.MERCHANT_EMAIL_SALES_CONTRACT_TEST,
                    name: EMAIL_TEMPLATE_TYPE.MERCHANT_EMAIL_SALES_CONTRACT_TEST,
                },
                {
                    value: EMAIL_TEMPLATE_TYPE.MERCHANT_EMAIL_SET_PASSWORD,
                    name: EMAIL_TEMPLATE_TYPE.MERCHANT_EMAIL_SET_PASSWORD,
                },
                {
                    value: EMAIL_TEMPLATE_TYPE.MERCHANT_EMAIL_VERIFICATION,
                    name: EMAIL_TEMPLATE_TYPE.MERCHANT_EMAIL_VERIFICATION,
                },
                {
                    value: EMAIL_TEMPLATE_TYPE.MERCHANT_HSBC_CREATE_SHOP_NOTIFY,
                    name: EMAIL_TEMPLATE_TYPE.MERCHANT_HSBC_CREATE_SHOP_NOTIFY,
                },
                {
                    value: EMAIL_TEMPLATE_TYPE.MERCHANT_HSBC_EMAIL_VERIFICATION,
                    name: EMAIL_TEMPLATE_TYPE.MERCHANT_HSBC_EMAIL_VERIFICATION,
                },
                {
                    value: EMAIL_TEMPLATE_TYPE.MERCHANT_PUBLISH_PRODUCT_PASSCODE,
                    name: EMAIL_TEMPLATE_TYPE.MERCHANT_PUBLISH_PRODUCT_PASSCODE,
                },
                {
                    value: EMAIL_TEMPLATE_TYPE.MERCHANT_YEDPAY_REGISTRAION,
                    name: EMAIL_TEMPLATE_TYPE.MERCHANT_YEDPAY_REGISTRAION,
                },
                { value: EMAIL_TEMPLATE_TYPE.MX_API, name: EMAIL_TEMPLATE_TYPE.MX_API },
                {
                    value: EMAIL_TEMPLATE_TYPE.PRODUCT_TRN_REC,
                    name: EMAIL_TEMPLATE_TYPE.PRODUCT_TRN_REC,
                },
                {
                    value: EMAIL_TEMPLATE_TYPE.QUEUE_TICKET,
                    name: EMAIL_TEMPLATE_TYPE.QUEUE_TICKET,
                },
                {
                    value: EMAIL_TEMPLATE_TYPE.RESERVATION_TICKET,
                    name: EMAIL_TEMPLATE_TYPE.RESERVATION_TICKET,
                },
                {
                    value: EMAIL_TEMPLATE_TYPE.RESET_PASSWORD,
                    name: EMAIL_TEMPLATE_TYPE.RESET_PASSWORD,
                },
                {
                    value: EMAIL_TEMPLATE_TYPE.RSV_TRN_REC,
                    name: EMAIL_TEMPLATE_TYPE.RSV_TRN_REC,
                },
                {
                    value: EMAIL_TEMPLATE_TYPE.RSV_UPDATE_NOTIFY,
                    name: EMAIL_TEMPLATE_TYPE.RSV_UPDATE_NOTIFY,
                },
                {
                    value: EMAIL_TEMPLATE_TYPE.SELF_REGISTER,
                    name: EMAIL_TEMPLATE_TYPE.SELF_REGISTER,
                },
                {
                    value: EMAIL_TEMPLATE_TYPE.SELF_REGISTER_NOTIFY,
                    name: EMAIL_TEMPLATE_TYPE.SELF_REGISTER_NOTIFY,
                },
                {
                    value: EMAIL_TEMPLATE_TYPE.TAKEAWAY_ACCEPT,
                    name: EMAIL_TEMPLATE_TYPE.TAKEAWAY_ACCEPT,
                },
                {
                    value: EMAIL_TEMPLATE_TYPE.TAKEAWAY_ITEM,
                    name: EMAIL_TEMPLATE_TYPE.TAKEAWAY_ITEM,
                },
                {
                    value: EMAIL_TEMPLATE_TYPE.TAKEAWAY_REJECT,
                    name: EMAIL_TEMPLATE_TYPE.TAKEAWAY_REJECT,
                },
                {
                    value: EMAIL_TEMPLATE_TYPE.TAKEAWAY_SUBMIT,
                    name: EMAIL_TEMPLATE_TYPE.TAKEAWAY_SUBMIT,
                },
                {
                    value: EMAIL_TEMPLATE_TYPE.TKY_TRN_REC,
                    name: EMAIL_TEMPLATE_TYPE.TKY_TRN_REC,
                },
                {
                    value: EMAIL_TEMPLATE_TYPE.RACK_PRODUCT_ORDER,
                    name: EMAIL_TEMPLATE_TYPE.RACK_PRODUCT_ORDER,
                },
            ],
            STATUS: [
                { value: 'A', name: { key: 'active' } },
                { value: 'D', name: { key: 'deactive' } },
                { value: 'P', name: { key: 'pending' } },
            ],
        },
        PAYMENT: {
            APPROVAL_STATUS: [
                { value: 'A', name: { key: 'active' } },
                { value: 'P', name: { key: 'pending' } },
            ],
            TRAN_STATUS: [
                { value: 'A', name: { key: 'active' } },
                { value: 'P', name: { key: 'pending' } },
                { value: 'O', name: { key: 'overWritten' } },
            ],
            STATUS: [
                { id: 'A', name: { key: 'active' } },
                { id: 'D', name: { key: 'deactive' } },
                { id: 'P', name: { key: 'pending' } },
            ],
            ORDER_STATUS: [
                { id: 'A', name: { key: 'active' } },
                { id: 'D', name: { key: 'deactive' } },
                { id: 'P', name: { key: 'pending' } },
            ],
            CHANNEL: [
                { value: 'SALES', name: { resource: 'payment', key: 'sale' } },
                { value: 'SELF', name: { resource: 'payment', key: 'self' } },
                { value: 'HSBC', name: { resource: 'payment', key: 'hsbc' } },
            ],
        },
        PAYMENT_CREATE: {
            PAYMENT: [
                { value: 'CASH', name: { resource: 'payment', key: 'cash' } },
                { value: 'CHEQUE', name: { resource: 'payment', key: 'cheque' } },
            ],
        },
        PUSH: {
            ACTION_TYPE: [
                { value: BANNER_ACTION.WEB_VIEW, name: BANNER_ACTION.WEB_VIEW },
                { value: BANNER_ACTION.LINK, name: BANNER_ACTION.LINK },
                { value: BANNER_ACTION.VIDEO, name: BANNER_ACTION.VIDEO },
                { value: BANNER_ACTION.PRODUCT, name: BANNER_ACTION.PRODUCT },
                {
                    value: BANNER_ACTION.PRODUCT_CATEGORY,
                    name: BANNER_ACTION.PRODUCT_CATEGORY,
                },
                {
                    value: BANNER_ACTION.PRODUCT_BRAND,
                    name: BANNER_ACTION.PRODUCT_BRAND,
                },
                { value: BANNER_ACTION.E_COUPON, name: BANNER_ACTION.E_COUPON },
                {
                    value: BANNER_ACTION.E_COUPON_CATEGORY,
                    name: BANNER_ACTION.E_COUPON_CATEGORY,
                },
                {
                    value: BANNER_ACTION.E_COUPON_BRAND,
                    name: BANNER_ACTION.E_COUPON_BRAND,
                },
                //{ value: BANNER_ACTION.CATEGORY_PRODUCT, name: BANNER_ACTION.CATEGORY_PRODUCT },
                //{ value: BANNER_ACTION.BRAND_PRODUCT, name: BANNER_ACTION.BRAND_PRODUCT },
                { value: BANNER_ACTION.FOLDER, name: BANNER_ACTION.FOLDER },
                { value: BANNER_ACTION.RESTAURANT, name: BANNER_ACTION.RESTAURANT },
                { value: BANNER_ACTION.SEARCH, name: BANNER_ACTION.SEARCH },
            ],
            STATUS: [
                { value: 'draft', name: { resource: 'Push', key: 'draft' } },
                { value: 'scheduled', name: { resource: 'Push', key: 'scheduled' } },
                { value: 'sending', name: { resource: 'Push', key: 'sending' } },
                { value: 'finished', name: { resource: 'Push', key: 'finished' } },
            ],
            TO_NOTIF: [
                { value: true, name: 'true' },
                { value: false, name: 'false' },
            ],
            TO_INBOX: [
                { value: true, name: 'true' },
                { value: false, name: 'false' },
            ],
            SYSTEM_MESSAGE: [
                { value: true, name: 'true' },
                { value: false, name: 'false' },
            ],
        },
        URL_MAP: {
            STATUS: [
                { value: 'A', name: { key: 'active' } },
                { value: 'D', name: { key: 'deactive' } },
            ],
            RESTAURANT_URL_TYPE: [
                {
                    value: RESTAURANT_URL_TYPE.DOWNLOAD_HTML,
                    name: RESTAURANT_URL_TYPE.DOWNLOAD_HTML,
                },
                { value: RESTAURANT_URL_TYPE.UNIVERSAL, name: RESTAURANT_URL_TYPE.UNIVERSAL },
                { value: RESTAURANT_URL_TYPE.OTHER, name: RESTAURANT_URL_TYPE.OTHER },
            ],
            ACTION_TYPE: [
                {
                    value: URL_MAP_ACTION_TYPE.WEB_VIEW,
                    name: URL_MAP_ACTION_TYPE.WEB_VIEW,
                    group: 'Download GULU App',
                },
                {
                    value: URL_MAP_ACTION_TYPE.LINK,
                    name: URL_MAP_ACTION_TYPE.LINK,
                    group: 'Download GULU App',
                },
                {
                    value: URL_MAP_ACTION_TYPE.VIDEO,
                    name: URL_MAP_ACTION_TYPE.VIDEO,
                    group: 'Download GULU App',
                },
                {
                    value: URL_MAP_ACTION_TYPE.PRODUCT,
                    name: URL_MAP_ACTION_TYPE.PRODUCT,
                    group: 'Download GULU App',
                },
                {
                    value: URL_MAP_ACTION_TYPE.PRODUCT_CATEGORY,
                    name: URL_MAP_ACTION_TYPE.PRODUCT_CATEGORY,
                    group: 'Download GULU App',
                },
                {
                    value: URL_MAP_ACTION_TYPE.PRODUCT_BRAND,
                    name: URL_MAP_ACTION_TYPE.PRODUCT_BRAND,
                    group: 'Download GULU App',
                },
                {
                    value: URL_MAP_ACTION_TYPE.E_COUPON,
                    name: URL_MAP_ACTION_TYPE.E_COUPON,
                    group: 'Download GULU App',
                },
                {
                    value: URL_MAP_ACTION_TYPE.E_COUPON_CATEGORY,
                    name: URL_MAP_ACTION_TYPE.E_COUPON_CATEGORY,
                    group: 'Download GULU App',
                },
                {
                    value: URL_MAP_ACTION_TYPE.E_COUPON_BRAND,
                    name: URL_MAP_ACTION_TYPE.E_COUPON_BRAND,
                    group: 'Download GULU App',
                },
                {
                    value: URL_MAP_ACTION_TYPE.FOLDER,
                    name: URL_MAP_ACTION_TYPE.FOLDER,
                    group: 'Download GULU App',
                },
                {
                    value: URL_MAP_ACTION_TYPE.REST,
                    name: URL_MAP_ACTION_TYPE.REST,
                    group: 'Download GULU App',
                },
                {
                    value: URL_MAP_ACTION_TYPE.SEARCH,
                    name: URL_MAP_ACTION_TYPE.SEARCH,
                    group: 'Download GULU App',
                },
                {
                    value: URL_MAP_ACTION_TYPE.BOOKMARK,
                    name: URL_MAP_ACTION_TYPE.BOOKMARK,
                    group: 'Download GULU App',
                },
                {
                    value: URL_MAP_ACTION_TYPE.SEARCH_NEAR_BY,
                    name: URL_MAP_ACTION_TYPE.SEARCH_NEAR_BY,
                    group: 'Download GULU App',
                },
                {
                    value: URL_MAP_ACTION_TYPE.MY_TICKET,
                    name: URL_MAP_ACTION_TYPE.MY_TICKET,
                    group: 'Download GULU App',
                },
                {
                    value: URL_MAP_ACTION_TYPE.TRANSFORM_TICKET_FROM_PAPER,
                    name: URL_MAP_ACTION_TYPE.TRANSFORM_TICKET_FROM_PAPER,
                    group: 'Download GULU App',
                },
                {
                    value: URL_MAP_ACTION_TYPE.TRANSFORM_ECOUPON_FROM_PAPER,
                    name: URL_MAP_ACTION_TYPE.TRANSFORM_ECOUPON_FROM_PAPER,
                    group: 'Download GULU App',
                },
                {
                    value: URL_MAP_ACTION_TYPE.EXPRESS_TICKET_VOUCHER,
                    name: URL_MAP_ACTION_TYPE.EXPRESS_TICKET_VOUCHER,
                    group: 'Download GULU App',
                },
                {
                    value: URL_MAP_ACTION_TYPE.SHARE,
                    name: URL_MAP_ACTION_TYPE.SHARE,
                    group: 'Download GULU App',
                },
                {
                    value: URL_MAP_ACTION_TYPE.GIFT,
                    name: URL_MAP_ACTION_TYPE.GIFT,
                    group: 'Download GULU App',
                },
                {
                    value: URL_MAP_ACTION_TYPE.LANDING,
                    name: URL_MAP_ACTION_TYPE.LANDING,
                    group: 'Download GULU App',
                },
                {
                    value: URL_MAP_ACTION_TYPE.DOWNLOAD,
                    name: URL_MAP_ACTION_TYPE.DOWNLOAD,
                    group: 'Download GULU App',
                },
                {
                    value: URL_MAP_ACTION_TYPE.SCAN_QRCODE,
                    name: URL_MAP_ACTION_TYPE.SCAN_QRCODE,
                    group: 'Download GULU App',
                },
                {
                    value: URL_MAP_ACTION_TYPE.CUSTOM_URL,
                    name: URL_MAP_ACTION_TYPE.CUSTOM_URL,
                    group: 'Others',
                },
                {
                    value: URL_MAP_ACTION_TYPE.SPRINGBOARD,
                    name: URL_MAP_ACTION_TYPE.SPRINGBOARD,
                    group: 'Others',
                    isDisabled: true,
                },
                //{ value: URL_MAP_ACTION.CATEGORY_PRODUCT, name: URL_MAP_ACTION.CATEGORY_PRODUCT },
                //{ value: URL_MAP_ACTION.BRAND_PRODUCT, name: URL_MAP_ACTION.BRAND_PRODUCT },
                //{ value: URL_MAP_ACTION.ECOUPON, name: URL_MAP_ACTION.ECOUPON },
                // { value: URL_MAP_ACTION.PIXEL, name: URL_MAP_ACTION.PIXEL },
            ],
            AGENT_TYPE: [
                { value: 'web', name: 'web' },
                { value: 'weChat', name: 'weChat' },
            ],
            FOLDER_TYPE: [{ value: 'CAMPAIGN', name: 'CAMPAIGN' }],
            FROM: [
                { value: 'qrcode', name: 'qrcode' },
                { value: 'campaign_qrcode', name: 'campaign_qrcode' },
                { value: 'edm', name: 'edm' },
                { value: 'fb', name: 'fb' },
                { value: 'urlMap', name: 'urlMap' },
                { value: 'web', name: 'web' },
                { value: 'SPLASHAD', name: 'SPLASHAD' },
            ],
            MY_TICKET_SERVICE_TYPE: [
                { value: 'QUEUE', name: 'QUEUE' },
                { value: 'RESERVE', name: 'RESERVE' },
                { value: 'APPOINTMENT', name: 'APPOINTMENT' },
                { value: 'TAKEAWAY', name: 'TAKEAWAY' },
                { value: 'RACK_PRODUCT', name: 'RACK_PRODUCT' },
                { value: 'ECOUPON', name: 'ECOUPON' },
            ],
            MY_TICKET_RACK_PRODUCT_TYPE: [
                { value: 'ECOUPON', name: 'ECOUPON' },
                { value: 'ORDER', name: 'ORDER' },
            ],
            SHARE_SERVICE_TYPE: [
                { value: 'QUEUE', name: 'QUEUE' },
                { value: 'RESERVE', name: 'RESERVE' },
                { value: 'APPOINTMENT', name: 'APPOINTMENT' },
                { value: 'TAKEAWAY', name: 'TAKEAWAY' },
                { value: 'ECOUPON', name: 'ECOUPON' },
            ],
            GIFT_SERVICE_TYPE: [
                { value: 'RACK_PRODUCT', name: 'RACK_PRODUCT' },
                { value: 'ECOUPON', name: 'ECOUPON' },
            ],
            GIFT_RACK_PRODUCT_TYPE: [
                { value: 'ECOUPON', name: 'ECOUPON' },
                { value: 'ORDER', name: 'ORDER' },
            ],
            WEB_TYPE: [
                { value: 'CAMPAIGN', name: 'CAMPAIGN' },
                { value: 'CLP', name: 'CLP' },
            ],
        },
        RESTAURANT: {
            SHOW_BANNER: [
                { value: 'A', name: { key: 'active' } },
                { value: 'D', name: { key: 'deactive' } },
            ],
            FILTER_STATUS: [
                { value: 'A', name: { key: 'active' } },
                { value: 'P', name: { key: 'pending' } },
                { value: 'M', name: { key: 'merchant' } },
            ],
            STATUS: [
                { value: 'A', name: { key: 'active' } },
                { value: 'D', name: { key: 'deactive' } },
                { value: 'P', name: { key: 'pending' } },
                { value: 'M', name: { key: 'merchant' } },
            ],
            RESTAURANT_MAP_STATUS: [
                { value: 'A', name: { key: 'active' } },
                { value: 'D', name: { key: 'deactive' } },
                { value: 'P', name: { key: 'pending' } },
            ],
            REST_TYPE: [
                { value: 'FB', name: 'FB' },
                { value: 'BEAUTY', name: 'BEAUTY' },
                { value: 'HEALTH', name: 'HEALTH' },
                { value: 'SF', name: 'SF' },
                { value: 'PEAK_TRAM', name: 'PEAK_TRAM' },
                { value: 'MASK', name: 'MASK' },
                { value: 'CATHAY_PACIFIC', name: 'CATHAY_PACIFIC' },
                {
                    value: 'CATHAY_PACIFIC_FACILITIES',
                    name: 'CATHAY_PACIFIC_FACILITIES',
                },
                { value: 'CLP', name: 'CLP' },
                { value: 'QUEUE_CAMPAIGN', name: 'QUEUE_CAMPAIGN' },
                { value: 'SERVICE_COUNTER', name: 'SERVICE_COUNTER' },
                { value: 'BATCH', name: 'BATCH' },
            ],
            SEARCHABLE: [
                { value: true, name: 'true' },
                { value: false, name: 'false' },
            ],
            THEME: [
                { value: 'tv', name: 'tv' },
                { value: 'event', name: 'event' },
                { value: 'counter', name: 'counter' },
            ],
        },
        RESTAURANT_TAG: {
            STATUS: [
                { value: 'A', name: { key: 'active' } },
                { value: 'D', name: { key: 'deactive' } },
                { value: 'P', name: { key: 'pending' } },
            ],
        },
        RESTAURANT_APPROVE: {
            STATUS: [
                { value: 'A', name: { key: 'active' } },
                { value: 'R', name: { key: 'reject' } },
                { value: 'P', name: { key: 'pending' } },
            ],
            UPLOADEDCONTRACTSIGNATURE: [
                { value: 'true', name: 'true' },
                { value: 'false', name: 'false' },
            ],
            PUBLISHED: [
                { value: 'true', name: 'true' },
                { value: 'false', name: 'false' },
            ],
            APPROVAL_SALES_RACK_PRODUCT_STATUS: [
                { value: 'A', name: { key: 'active' } },
                { value: 'R', name: { key: 'removed' } },
            ],
        },
        BRAND_APPROVE: {
            STATUS: [
                { value: 'A', name: { key: 'active' } },
                { value: 'R', name: { key: 'reject' } },
                { value: 'P', name: { key: 'pending' } },
            ],
        },
        BRAND: {
            TYPE: [
                { value: 'MERCHANT_BRAND_LOGO', name: 'MERCHANT_BRAND_LOGO' },
                { value: 'MERCHANT_BRAND_IMAGE', name: 'MERCHANT_BRAND_IMAGE' },
            ],
            STATUS: [
                { value: 'A', name: { key: 'active' } },
                { value: 'P', name: { key: 'pending' } },
            ],
        },
        BRAND_GROUP: {
            STATUS: [
                { value: 'A', name: { key: 'active' } },
                { value: 'P', name: { key: 'pending' } },
            ],
        },
        BRAND_PRODUCT: {
            TYPE: [
                // { value: "VALUE_UP_ECOUPON_ITEM", name: "VALUE_UP_ECOUPON_ITEM" },
                { value: PACKAGE_ITEM, name: PACKAGE_ITEM },
                // { value: PACKAGE_SUB_ITEM, name: PACKAGE_SUB_ITEM },
                { value: PREORDER_ITEM, name: PREORDER_ITEM },
                { value: ECOUPON_ITEM, name: ECOUPON_ITEM },
            ],
            CHANNEL: [
                { value: GLOBAL, name: GLOBAL },
                { value: MOBILE, name: MOBILE },
                // { value: "WECHAT_H5", name: "WECHAT_H5" },
                { value: WECHAT_MP, name: WECHAT_MP },
            ],
            STATUS: [
                { value: 'A', name: { key: 'active' } },
                { value: 'D', name: { key: 'deactive' } },
                { value: 'P', name: { key: 'pending' } },
            ],
            MERCHANT_PRODUCT: [
                { value: true, name: 'true' },
                { value: false, name: 'false' },
            ],
        },
        FOLDER: {
            STATUS: [
                { value: 'A', name: { key: 'active' } },
                { value: 'D', name: { key: 'deactive' } },
                { value: 'P', name: { key: 'pending' } },
            ],
            TYPE: [
                { value: 'QUEUE_CAMPAIGN', name: 'QUEUE_CAMPAIGN' },
                { value: 'NEW_RESTAURANT', name: 'NEW_RESTAURANT' },
                { value: 'GRID_VIEW', name: 'GRID_VIEW' },
                { value: 'LIST_VIEW', name: 'LIST_VIEW' },
                { value: 'IMAGE_LIST_VIEW', name: 'IMAGE_LIST_VIEW' },
            ],
            SHOW_HIDDEN: [
                { value: false, name: 'FALSE' },
                { value: true, name: 'TRUE' },
            ],
        },
        FOLDER_MAP: {
            STATUS: [
                { value: 'A', name: { key: 'active' } },
                { value: 'D', name: { key: 'deactive' } },
                { value: 'P', name: { key: 'pending' } },
            ],
            TYPE: [
                { value: 'RESTAURANT', name: 'RESTAURANT' },
                { value: 'FOLDER', name: 'FOLDER' },
            ],
        },
        LANDING_ITEM: {
            STATUS: [
                { value: 'A', name: { key: 'active' } },
                { value: 'D', name: { key: 'deactive' } },
                { value: 'P', name: { key: 'pending' } },
            ],
            FROM: [
                { value: 'qrcode', name: 'qrcode' },
                { value: 'campaign_qrcode', name: 'campaign_qrcode' },
                { value: 'edm', name: 'edm' },
                { value: 'fb', name: 'fb' },
                { value: 'urlMap', name: 'urlMap' },
                { value: 'web', name: 'web' },
                { value: 'SPLASHAD', name: 'SPLASHAD' },
            ],
            WEB_TYPE: [
                { value: 'CAMPAIGN', name: 'CAMPAIGN' },
                { value: 'CLP', name: 'CLP' },
            ],
            FOLDER_TYPE: [{ value: 'CAMPAIGN', name: 'CAMPAIGN' }],
            QUERY: [
                { value: 'QUEUE', name: 'QUEUE' },
                { value: 'TAKEAWAY', name: 'TAKEAWAY' },
                { value: 'RESERVE', name: 'RESERVE' },
                { value: 'APPOINTMENT', name: 'APPOINTMENT' },
                { value: 'RACK_PRODUCT', name: 'RACK_PRODUCT' },
            ],
            SHOW_LIST: [
                { value: 'SHOW_CATEGORY_LIST', name: 'SHOW_CATEGORY_LIST' },
                { value: 'SHOW_BRAND_LIST', name: 'SHOW_BRAND_LIST' },
            ],
            SHARE_SERVICE_TYPE: [
                { value: 'QUEUE', name: 'QUEUE' },
                { value: 'RESERVE', name: 'RESERVE' },
                { value: 'APPOINTMENT', name: 'APPOINTMENT' },
                { value: 'TAKEAWAY', name: 'TAKEAWAY' },
                { value: 'ECOUPON', name: 'ECOUPON' },
            ],
        },
        CONFIG_MAP: {
            SERVICE_TYPE: [
                { value: 'QUEUE', name: 'QUEUE' },
                { value: 'TAKEAWAY', name: 'TAKEAWAY' },
                { value: 'RESERVE', name: 'RESERVE' },
                { value: 'APPOINTMENT', name: 'APPOINTMENT' },
            ],
            QUEUE: {
                TICKET_STATUS: [
                    { value: 'A', name: { key: 'a_assigned' } },
                    { value: 'D', name: { key: 'delete' } },
                    { value: 'P', name: { key: 'pending' } },
                    { value: 'I', name: { key: 'inactive' } },
                    { value: 'ALL', name: { key: 'all' } },
                ],
            },
            TAKEAWAY: {
                TICKET_STATUS: [
                    { value: 'P', name: { key: 'processing' } },
                    { value: 'A', name: { key: 'available' } },
                    { value: 'R', name: { key: 'reject' } },
                    { value: 'C', name: { key: 'cancel' } },
                    { value: 'F', name: { key: 'finish' } },
                    { value: 'S', name: { key: 's_checkIn' } },
                    { value: 'M', name: { key: 'reminder' } },
                    { value: 'D', name: { key: 'delete' } },
                    { value: 'O', name: { key: 'sentOut' } },
                    { value: 'H', name: { key: 'hold' } },
                    { value: 'E', name: { key: 'expired' } },
                    { value: 'V', name: { key: 'void' } },
                    { value: 'ALL', name: { key: 'all' } },
                ],
            },
            RESERVE: {
                TICKET_STATUS: [
                    { value: 'A', name: { key: 'accept' } },
                    { value: 'R', name: { key: 'reject' } },
                    { value: 'P', name: { key: 'pending' } },
                    { value: 'S', name: { key: 's_assigned' } },
                    { value: 'D', name: { key: 'delete' } },
                    { value: 'W', name: { key: 'waiting' } },
                    { value: 'H', name: { key: 'hold' } },
                    { value: 'L', name: { key: 'hold_release' } },
                    { value: 'MC', name: { key: 'maxims_cancel' } },
                    { value: 'ALL', name: { key: 'all' } },
                ],
            },
            APPOINTMENT: {
                TICKET_STATUS: [
                    { value: 'A', name: { key: 'accept' } },
                    { value: 'R', name: { key: 'reject' } },
                    { value: 'P', name: { key: 'pending' } },
                    { value: 'S', name: { key: 's_assigned' } },
                    { value: 'C', name: { key: 'c_checkIn' } },
                    { value: 'D', name: { key: 'delete' } },
                    { value: 'W', name: { key: 'waiting' } },
                    { value: 'H', name: { key: 'hold' } },
                    { value: 'L', name: { key: 'hold_release' } },
                    { value: 'ALL', name: { key: 'all' } },
                ],
            },
            CHANNEL: [
                { value: MOBILE, name: MOBILE },
                { value: WECHAT_MP, name: WECHAT_MP },
                // { value: SPRING_BOARD, name: SPRING_BOARD },
                { value: WEB, name: WEB },
                { value: PAPER_TO_MOBILE, name: PAPER_TO_MOBILE },
                { value: PAPER_TO_WECHAT_MP, name: PAPER_TO_WECHAT_MP },
            ],
            STATUS: [
                { value: 'A', name: { key: 'active' } },
                { value: 'D', name: { key: 'deactive' } },
                { value: 'P', name: { key: 'pending' } },
            ],
        },
        LANDING_SECTION: {
            CHANNEL: [
                { value: 'GLOBAL', name: 'GLOBAL' },
                { value: 'MOBILE', name: 'MOBILE' },
                { value: 'WECHAT_MP', name: 'WECHAT_MP' },
                { value: 'WEB', name: 'WEB' },
            ],
            MAIN_SECTION: [
                { value: true, name: 'true' },
                { value: false, name: 'false' },
            ],
            STATUS: [
                { value: 'A', name: { key: 'active' } },
                { value: 'D', name: { key: 'deactive' } },
                { value: 'P', name: { key: 'pending' } },
            ],
            COPY_DATA_SOURCE: [
                { value: 'SHOP', name: 'SHOP' },
                { value: 'PRODUCT', name: 'PRODUCT' },
            ],
            ACTION_TYPE: [
                // { value: LANDING_SECTION_ACTION.WEB_VIEW, name: LANDING_SECTION_ACTION.WEB_VIEW },
                // { value: LANDING_SECTION_ACTION.LINK, name: LANDING_SECTION_ACTION.LINK },
                // { value: LANDING_SECTION_ACTION.VIDEO, name: LANDING_SECTION_ACTION.VIDEO },
                // { value: LANDING_SECTION_ACTION.PRODUCT, name: LANDING_SECTION_ACTION.PRODUCT },
                // { value: LANDING_SECTION_ACTION.PRODUCT_CATEGORY, name: LANDING_SECTION_ACTION.PRODUCT_CATEGORY },
                // { value: LANDING_SECTION_ACTION.PRODUCT_BRAND, name: LANDING_SECTION_ACTION.PRODUCT_BRAND },
                // { value: LANDING_SECTION_ACTION.E_COUPON, name: LANDING_SECTION_ACTION.E_COUPON },
                // { value: LANDING_SECTION_ACTION.E_COUPON_CATEGORY, name: LANDING_SECTION_ACTION.E_COUPON_CATEGORY },
                // { value: LANDING_SECTION_ACTION.E_COUPON_BRAND, name: LANDING_SECTION_ACTION.E_COUPON_BRAND },
                // { value: LANDING_SECTION_ACTION.COUPON, name: LANDING_SECTION_ACTION.COUPON },
                // //{ value: BANNER_ACTION.CATEGORY_PRODUCT, name: BANNER_ACTION.CATEGORY_PRODUCT },
                // //{ value: BANNER_ACTION.BRAND_PRODUCT, name: BANNER_ACTION.BRAND_PRODUCT },
                // { value: LANDING_SECTION_ACTION.FOLDER, name: LANDING_SECTION_ACTION.FOLDER },
                // { value: LANDING_SECTION_ACTION.RESTAURANT, name: LANDING_SECTION_ACTION.RESTAURANT },
                // { value: LANDING_SECTION_ACTION.SEARCH, name: LANDING_SECTION_ACTION.SEARCH },
                // { value: LANDING_SECTION_ACTION.ROTATING_BANNER, name: LANDING_SECTION_ACTION.ROTATING_BANNER },
                // { value: LANDING_SECTION_ACTION.RESTAURANT_CATEGORY, name: LANDING_SECTION_ACTION.RESTAURANT_CATEGORY },
                // { value: LANDING_SECTION_ACTION.SEARCH_NEAR_BY, name: LANDING_SECTION_ACTION.SEARCH_NEAR_BY },
                // { value: LANDING_SECTION_ACTION.MY_TICKET, name: LANDING_SECTION_ACTION.MY_TICKET },
                // { value: URL_MAP_ACTION.TRANSFORM_TICKET_FROM_PAPER, name: URL_MAP_ACTION.TRANSFORM_TICKET_FROM_PAPER },
                // { value: URL_MAP_ACTION.TRANSFORM_ECOUPON_FROM_PAPER, name: URL_MAP_ACTION.TRANSFORM_ECOUPON_FROM_PAPER },
                // { value: URL_MAP_ACTION.EXPRESS_TICKET_VOUCHER, name: URL_MAP_ACTION.EXPRESS_TICKET_VOUCHER },
                // { value: URL_MAP_ACTION.SHARE, name: URL_MAP_ACTION.SHARE },
                // { value: URL_MAP_ACTION.GIFT, name: URL_MAP_ACTION.GIFT },
                // //{ value: URL_MAP_ACTION.ECOUPON, name: URL_MAP_ACTION.ECOUPON },
                // { value: URL_MAP_ACTION.SCAN_QRCODE, name: URL_MAP_ACTION.SCAN_QRCODE },
                // { value: URL_MAP_ACTION.SPRINGBOARD, name: URL_MAP_ACTION.SPRINGBOARD },
                // { value: URL_MAP_ACTION.LANDING, name: URL_MAP_ACTION.LANDING },
                // { value: URL_MAP_ACTION.DOWNLOAD, name: URL_MAP_ACTION.DOWNLOAD },
                // {
                //   value: LANDING_SECTION_ACTION.SEARCH,
                //   name: LANDING_SECTION_ACTION.SEARCH,
                // },
                // {
                //   value: LANDING_SECTION_ACTION.FOLDER,
                //   name: LANDING_SECTION_ACTION.FOLDER,
                // },
                // {
                //   value: LANDING_SECTION_ACTION.RESTAURANT,
                //   name: LANDING_SECTION_ACTION.RESTAURANT,
                // },
                // {
                //   value: LANDING_SECTION_ACTION.RESTAURANT_CATEGORY,
                //   name: LANDING_SECTION_ACTION.RESTAURANT_CATEGORY,
                // },
                // { value: LANDING_ACTION.PRODUCT, name: LANDING_SECTION_ACTION.PRODUCT },
                // {
                //   value: LANDING_SECTION_ACTION.COUPON,
                //   name: LANDING_SECTION_ACTION.COUPON,
                // },
                // {
                //   value: LANDING_SECTION_ACTION.WEB_VIEW,
                //   name: LANDING_SECTION_ACTION.WEB_VIEW,
                // },
                // {
                //   value: LANDING_SECTION_ACTION.HYPERLINK,
                //   name: LANDING_SECTION_ACTION.HYPERLINK,
                // },
                // {
                //   value: LANDING_SECTION_ACTION.ROTATING_BANNER,
                //   name: LANDING_SECTION_ACTION.ROTATING_BANNER,
                // },
                // {
                //   value: LANDING_SECTION_ACTION.SEARCH_SUGGESTION,
                //   name: LANDING_SECTION_ACTION.SEARCH_SUGGESTION,
                // },
                // {
                //   value: LANDING_SECTION_ACTION.YOUTUBE,
                //   name: LANDING_SECTION_ACTION.YOUTUBE,
                // },
                {
                    value: LANDING_SECTION_ACTION.HISTORICAL_SHOP,
                    name: LANDING_SECTION_ACTION.HISTORICAL_SHOP,
                },
                {
                    value: LANDING_ACTION_PARAMETER.WEB_VIEW,
                    name: LANDING_ACTION_PARAMETER.WEB_VIEW,
                },
                { value: LANDING_ACTION_PARAMETER.LINK, name: LANDING_ACTION_PARAMETER.LINK },
                // { value: LANDING_ACTION_PARAMETER.VIDEO, name: LANDING_ACTION_PARAMETER.VIDEO },
                { value: LANDING_ACTION_PARAMETER.PRODUCT, name: LANDING_ACTION_PARAMETER.PRODUCT },
                {
                    value: LANDING_ACTION_PARAMETER.PRODUCT_CATEGORY,
                    name: LANDING_ACTION_PARAMETER.PRODUCT_CATEGORY,
                },
                {
                    value: LANDING_ACTION_PARAMETER.PRODUCT_BRAND,
                    name: LANDING_ACTION_PARAMETER.PRODUCT_BRAND,
                },
                {
                    value: LANDING_ACTION_PARAMETER.E_COUPON,
                    name: LANDING_ACTION_PARAMETER.E_COUPON,
                },
                {
                    value: LANDING_ACTION_PARAMETER.E_COUPON_CATEGORY,
                    name: LANDING_ACTION_PARAMETER.E_COUPON_CATEGORY,
                },
                {
                    value: LANDING_ACTION_PARAMETER.E_COUPON_BRAND,
                    name: LANDING_ACTION_PARAMETER.E_COUPON_BRAND,
                },
                //{ value: BANNER_ACTION.CATEGORY_PRODUCT, name: BANNER_ACTION.CATEGORY_PRODUCT },
                //{ value: BANNER_ACTION.BRAND_PRODUCT, name: BANNER_ACTION.BRAND_PRODUCT },
                { value: LANDING_ACTION_PARAMETER.FOLDER, name: LANDING_ACTION_PARAMETER.FOLDER },
                {
                    value: LANDING_ACTION_PARAMETER.RESTAURANT,
                    name: LANDING_ACTION_PARAMETER.RESTAURANT,
                },
                { value: LANDING_ACTION_PARAMETER.SEARCH, name: LANDING_ACTION_PARAMETER.SEARCH },
                { value: LANDING_ACTION_PARAMETER.YOUTUBE, name: LANDING_ACTION_PARAMETER.YOUTUBE },
                {
                    value: LANDING_ACTION_PARAMETER.RESTAURANT_CATEGORY,
                    name: LANDING_ACTION_PARAMETER.RESTAURANT_CATEGORY,
                },
                {
                    value: LANDING_SECTION_ACTION.ROTATING_BANNER,
                    name: LANDING_SECTION_ACTION.ROTATING_BANNER,
                },
            ],
            ITEM_ACTION_TYPE: [
                {
                    value: LANDING_ACTION_PARAMETER.WEB_VIEW,
                    name: LANDING_ACTION_PARAMETER.WEB_VIEW,
                },
                { value: LANDING_ACTION_PARAMETER.LINK, name: LANDING_ACTION_PARAMETER.LINK },
                // { value: LANDING_ACTION_PARAMETER.VIDEO, name: LANDING_ACTION_PARAMETER.VIDEO },
                { value: LANDING_ACTION_PARAMETER.PRODUCT, name: LANDING_ACTION_PARAMETER.PRODUCT },
                {
                    value: LANDING_ACTION_PARAMETER.PRODUCT_CATEGORY,
                    name: LANDING_ACTION_PARAMETER.PRODUCT_CATEGORY,
                },
                {
                    value: LANDING_ACTION_PARAMETER.PRODUCT_BRAND,
                    name: LANDING_ACTION_PARAMETER.PRODUCT_BRAND,
                },
                {
                    value: LANDING_ACTION_PARAMETER.E_COUPON,
                    name: LANDING_ACTION_PARAMETER.E_COUPON,
                },
                {
                    value: LANDING_ACTION_PARAMETER.E_COUPON_CATEGORY,
                    name: LANDING_ACTION_PARAMETER.E_COUPON_CATEGORY,
                },
                {
                    value: LANDING_ACTION_PARAMETER.E_COUPON_BRAND,
                    name: LANDING_ACTION_PARAMETER.E_COUPON_BRAND,
                },
                //{ value: BANNER_ACTION.CATEGORY_PRODUCT, name: BANNER_ACTION.CATEGORY_PRODUCT },
                //{ value: BANNER_ACTION.BRAND_PRODUCT, name: BANNER_ACTION.BRAND_PRODUCT },
                { value: LANDING_ACTION_PARAMETER.FOLDER, name: LANDING_ACTION_PARAMETER.FOLDER },
                {
                    value: LANDING_ACTION_PARAMETER.RESTAURANT,
                    name: LANDING_ACTION_PARAMETER.RESTAURANT,
                },
                { value: LANDING_ACTION_PARAMETER.SEARCH, name: LANDING_ACTION_PARAMETER.SEARCH },
                { value: LANDING_ACTION_PARAMETER.YOUTUBE, name: LANDING_ACTION_PARAMETER.YOUTUBE },
                {
                    value: LANDING_ACTION_PARAMETER.RESTAURANT_CATEGORY,
                    name: LANDING_ACTION_PARAMETER.RESTAURANT_CATEGORY,
                },
                // {
                //   value: LANDING_SECTION_ACTION.WEB_VIEW,
                //   name: LANDING_SECTION_ACTION.WEB_VIEW,
                // },
                // {
                //   value: LANDING_SECTION_ACTION.LINK,
                //   name: LANDING_SECTION_ACTION.LINK,
                // },
                // {
                //   value: LANDING_SECTION_ACTION.VIDEO,
                //   name: LANDING_SECTION_ACTION.VIDEO,
                // },
                // {
                //   value: LANDING_SECTION_ACTION.PRODUCT,
                //   name: LANDING_SECTION_ACTION.PRODUCT,
                // },
                // {
                //   value: LANDING_SECTION_ACTION.PRODUCT_CATEGORY,
                //   name: LANDING_SECTION_ACTION.PRODUCT_CATEGORY,
                // },
                // {
                //   value: LANDING_SECTION_ACTION.PRODUCT_BRAND,
                //   name: LANDING_SECTION_ACTION.PRODUCT_BRAND,
                // },
                // {
                //   value: LANDING_SECTION_ACTION.E_COUPON,
                //   name: LANDING_SECTION_ACTION.E_COUPON,
                // },
                // {
                //   value: LANDING_SECTION_ACTION.E_COUPON_CATEGORY,
                //   name: LANDING_SECTION_ACTION.E_COUPON_CATEGORY,
                // },
                // {
                //   value: LANDING_SECTION_ACTION.E_COUPON_BRAND,
                //   name: LANDING_SECTION_ACTION.E_COUPON_BRAND,
                // },
                // {
                //   value: LANDING_SECTION_ACTION.COUPON,
                //   name: LANDING_SECTION_ACTION.COUPON,
                // },
                // //{ value: BANNER_ACTION.CATEGORY_PRODUCT, name: BANNER_ACTION.CATEGORY_PRODUCT },
                // //{ value: BANNER_ACTION.BRAND_PRODUCT, name: BANNER_ACTION.BRAND_PRODUCT },
                // {
                //   value: LANDING_SECTION_ACTION.FOLDER,
                //   name: LANDING_SECTION_ACTION.FOLDER,
                // },
                // {
                //   value: LANDING_SECTION_ACTION.RESTAURANT,
                //   name: LANDING_SECTION_ACTION.RESTAURANT,
                // },
                // {
                //   value: LANDING_SECTION_ACTION.SEARCH,
                //   name: LANDING_SECTION_ACTION.SEARCH,
                // },
                // {
                //   value: LANDING_SECTION_ACTION.SEARCH_NEAR_BY,
                //   name: LANDING_SECTION_ACTION.SEARCH_NEAR_BY,
                // },
                // {
                //   value: LANDING_SECTION_ACTION.MY_TICKET,
                //   name: LANDING_SECTION_ACTION.MY_TICKET,
                // },
                // {
                //   value: URL_MAP_ACTION.TRANSFORM_TICKET_FROM_PAPER,
                //   name: URL_MAP_ACTION.TRANSFORM_TICKET_FROM_PAPER,
                // },
                // {
                //   value: URL_MAP_ACTION.TRANSFORM_ECOUPON_FROM_PAPER,
                //   name: URL_MAP_ACTION.TRANSFORM_ECOUPON_FROM_PAPER,
                // },
                // {
                //   value: URL_MAP_ACTION.EXPRESS_TICKET_VOUCHER,
                //   name: URL_MAP_ACTION.EXPRESS_TICKET_VOUCHER,
                // },
                // { value: URL_MAP_ACTION.SHARE, name: URL_MAP_ACTION.SHARE },
                // { value: URL_MAP_ACTION.GIFT, name: URL_MAP_ACTION.GIFT },
                // //{ value: URL_MAP_ACTION.ECOUPON, name: URL_MAP_ACTION.ECOUPON },
                // { value: URL_MAP_ACTION.SCAN_QRCODE, name: URL_MAP_ACTION.SCAN_QRCODE },
                // { value: URL_MAP_ACTION.SPRINGBOARD, name: URL_MAP_ACTION.SPRINGBOARD },
                // { value: URL_MAP_ACTION.LANDING, name: URL_MAP_ACTION.LANDING },
                // { value: URL_MAP_ACTION.DOWNLOAD, name: URL_MAP_ACTION.DOWNLOAD },
            ],
            LANDING_SECTION_SERVICE_TYPE: [
                {
                    value: LANDING_SECTION_SERVICE_TYPE.EXPRESS_TICKET,
                    name: LANDING_SECTION_SERVICE_TYPE.EXPRESS_TICKET,
                },
                {
                    value: LANDING_SECTION_SERVICE_TYPE.QUEUE,
                    name: LANDING_SECTION_SERVICE_TYPE.QUEUE,
                },
                {
                    value: LANDING_SECTION_SERVICE_TYPE.TAKEAWAY,
                    name: LANDING_SECTION_SERVICE_TYPE.TAKEAWAY,
                },
                {
                    value: LANDING_SECTION_SERVICE_TYPE.RACK_PRODUCT,
                    name: LANDING_SECTION_SERVICE_TYPE.RACK_PRODUCT,
                },
                {
                    value: LANDING_SECTION_SERVICE_TYPE.RESERVE,
                    name: LANDING_SECTION_SERVICE_TYPE.RESERVE,
                },
                {
                    value: LANDING_SECTION_SERVICE_TYPE.APPOINTMENT,
                    name: LANDING_SECTION_SERVICE_TYPE.APPOINTMENT,
                },
                {
                    value: LANDING_SECTION_SERVICE_TYPE.ECOUPON,
                    name: LANDING_SECTION_SERVICE_TYPE.ECOUPON,
                },
            ],
            LANDING_SECTION_SECTION_TYPE: [
                {
                    value: LANDING_SECTION_SECTION_TYPE.HEADER_ITEMS,
                    name: LANDING_SECTION_SECTION_TYPE.HEADER_ITEMS,
                },
                {
                    value: LANDING_SECTION_SECTION_TYPE.ITEMS,
                    name: LANDING_SECTION_SECTION_TYPE.ITEMS,
                },
                {
                    value: LANDING_SECTION_SECTION_TYPE.ADS,
                    name: LANDING_SECTION_SECTION_TYPE.ADS,
                },
            ],
            LANDING_ITEM_ITEM_TYPE: [
                { value: LANDING_ITEM_TYPE.IMAGE, name: LANDING_ITEM_TYPE.IMAGE },
                { value: LANDING_ITEM_TYPE.IMAGE_AD, name: LANDING_ITEM_TYPE.IMAGE_AD },
                { value: LANDING_ITEM_TYPE.IMAGE_TEXT, name: LANDING_ITEM_TYPE.IMAGE_TEXT },
                { value: LANDING_ITEM_TYPE.IMAGE_TEXT_AD, name: LANDING_ITEM_TYPE.IMAGE_TEXT_AD },

                {
                    value: LANDING_ITEM_TYPE.TICKET_IMAGE,
                    name: LANDING_ITEM_TYPE.TICKET_IMAGE,
                },
                {
                    value: LANDING_ITEM_TYPE.TICKET_IMAGE_AD,
                    name: LANDING_ITEM_TYPE.TICKET_IMAGE_AD,
                },
                {
                    value: LANDING_ITEM_TYPE.TICKET_IMAGE_TEXT,
                    name: LANDING_ITEM_TYPE.TICKET_IMAGE_TEXT,
                },
                {
                    value: LANDING_ITEM_TYPE.TICKET_IMAGE_TEXT_AD,
                    name: LANDING_ITEM_TYPE.TICKET_IMAGE_TEXT_AD,
                },

                {
                    value: LANDING_ITEM_TYPE.SQUARE_ROUND_IMAGE,
                    name: LANDING_ITEM_TYPE.SQUARE_ROUND_IMAGE,
                },
                {
                    value: LANDING_ITEM_TYPE.SQUARE_ROUND_IMAGE_AD,
                    name: LANDING_ITEM_TYPE.SQUARE_ROUND_IMAGE_AD,
                },

                {
                    value: LANDING_ITEM_TYPE.LARGE_ROUND_IMAGE,
                    name: LANDING_ITEM_TYPE.LARGE_ROUND_IMAGE,
                },
                {
                    value: LANDING_ITEM_TYPE.LARGE_ROUND_IMAGE_AD,
                    name: LANDING_ITEM_TYPE.LARGE_ROUND_IMAGE_AD,
                },
                {
                    value: LANDING_ITEM_TYPE.LARGE_ROUND_IMAGE_TEXT,
                    name: LANDING_ITEM_TYPE.LARGE_ROUND_IMAGE_TEXT,
                },
                {
                    value: LANDING_ITEM_TYPE.LARGE_ROUND_IMAGE_TEXT_AD,
                    name: LANDING_ITEM_TYPE.LARGE_ROUND_IMAGE_TEXT_AD,
                },

                { value: LANDING_ITEM_TYPE.PRODUCT, name: LANDING_ITEM_TYPE.PRODUCT },
                {
                    value: LANDING_ITEM_TYPE.PRODUCT_LARGE,
                    name: LANDING_ITEM_TYPE.PRODUCT_LARGE,
                },
                {
                    value: LANDING_ITEM_TYPE.VALUE_UP_ECOUPON,
                    name: LANDING_ITEM_TYPE.VALUE_UP_ECOUPON,
                },
                { value: LANDING_ITEM_TYPE.COUPON, name: LANDING_ITEM_TYPE.COUPON },
                {
                    value: LANDING_ITEM_TYPE.BANNER_IMAGE,
                    name: LANDING_ITEM_TYPE.BANNER_IMAGE,
                },
                {
                    value: LANDING_ITEM_TYPE.RECOMMEND_BY_LOCATION,
                    name: LANDING_ITEM_TYPE.RECOMMEND_BY_LOCATION,
                },
            ],
            QUERY: [
                { value: 'QUEUE', name: 'QUEUE' },
                { value: 'TAKEAWAY', name: 'TAKEAWAY' },
                { value: 'RESERVE', name: 'RESERVE' },
                { value: 'APPOINTMENT', name: 'APPOINTMENT' },
                { value: 'RACK_PRODUCT', name: 'RACK_PRODUCT' },
            ],
            MY_TICKET_SERVICE_TYPE: [
                { value: 'QUEUE', name: 'QUEUE' },
                { value: 'RESERVE', name: 'RESERVE' },
                { value: 'APPOINTMENT', name: 'APPOINTMENT' },
                { value: 'TAKEAWAY', name: 'TAKEAWAY' },
                { value: 'RACK_PRODUCT', name: 'RACK_PRODUCT' },
                { value: 'ECOUPON', name: 'ECOUPON' },
            ],
            MY_TICKET_RACK_PRODUCT_TYPE: [
                { value: 'ECOUPON', name: 'ECOUPON' },
                { value: 'ORDER', name: 'ORDER' },
            ],
            SHARE_SERVICE_TYPE: [
                { value: 'QUEUE', name: 'QUEUE' },
                { value: 'RESERVE', name: 'RESERVE' },
                { value: 'APPOINTMENT', name: 'APPOINTMENT' },
                { value: 'TAKEAWAY', name: 'TAKEAWAY' },
                { value: 'ECOUPON', name: 'ECOUPON' },
            ],
            GIFT_SERVICE_TYPE: [
                { value: 'RACK_PRODUCT', name: 'RACK_PRODUCT' },
                { value: 'ECOUPON', name: 'ECOUPON' },
            ],
            GIFT_RACK_PRODUCT_TYPE: [
                { value: 'ECOUPON', name: 'ECOUPON' },
                { value: 'ORDER', name: 'ORDER' },
            ],
        },
        BANNER: {
            STATUS: [
                { value: 'A', name: { key: 'active' } },
                { value: 'D', name: { key: 'deactive' } },
                { value: 'P', name: { key: 'pending' } },
            ],
            TYPE: [
                { value: BANNER_TYPE.FOLDER, name: BANNER_TYPE.FOLDER },
                { value: BANNER_TYPE.RESTAURANT, name: BANNER_TYPE.RESTAURANT },
                // { value: BANNER_TYPE.MERCHANT, name: BANNER_TYPE.MERCHANT },
                { value: BANNER_TYPE.MERCHANT_LOGIN, name: BANNER_TYPE.MERCHANT_LOGIN },
                {
                    value: BANNER_TYPE.MERCHANT_LANDING,
                    name: BANNER_TYPE.MERCHANT_LANDING,
                },
                // { value: BANNER_TYPE.RESTAURANT_TAG, name: BANNER_TYPE.RESTAURANT_TAG },
                { value: BANNER_TYPE.TICKET, name: BANNER_TYPE.TICKET },
                { value: BANNER_TYPE.LANDING_ALL, name: BANNER_TYPE.LANDING_ALL },
                { value: BANNER_TYPE.LANDING_MAIN, name: BANNER_TYPE.LANDING_MAIN },
                { value: BANNER_TYPE.LANDING_QUEUE, name: BANNER_TYPE.LANDING_QUEUE },
                {
                    value: BANNER_TYPE.LANDING_TAKEAWAY,
                    name: BANNER_TYPE.LANDING_TAKEAWAY,
                },
                {
                    value: BANNER_TYPE.LANDING_RESERVATION,
                    name: BANNER_TYPE.LANDING_RESERVATION,
                },
                {
                    value: BANNER_TYPE.LANDING_APPOINTMENT,
                    name: BANNER_TYPE.LANDING_APPOINTMENT,
                },
                {
                    value: BANNER_TYPE.LANDING_BANQUET,
                    name: BANNER_TYPE.LANDING_BANQUET,
                },
                {
                    value: BANNER_TYPE.LANDING_RACK_PRODUCT,
                    name: BANNER_TYPE.LANDING_RACK_PRODUCT,
                },
                {
                    value: BANNER_TYPE.LANDING_ECOUPON,
                    name: BANNER_TYPE.LANDING_ECOUPON,
                },
                // { value: BANNER_TYPE.EXPRESS_TICKET, name: BANNER_TYPE.EXPRESS_TICKET },
            ],
            LANDING_BANNER_TYPE: [
                { value: BANNER_TYPE.LANDING_ALL, name: BANNER_TYPE.LANDING_ALL },
                { value: BANNER_TYPE.LANDING_MAIN, name: BANNER_TYPE.LANDING_MAIN },
                { value: BANNER_TYPE.LANDING_QUEUE, name: BANNER_TYPE.LANDING_QUEUE },
                {
                    value: BANNER_TYPE.LANDING_TAKEAWAY,
                    name: BANNER_TYPE.LANDING_TAKEAWAY,
                },
                {
                    value: BANNER_TYPE.LANDING_RESERVATION,
                    name: BANNER_TYPE.LANDING_RESERVATION,
                },
                {
                    value: BANNER_TYPE.LANDING_APPOINTMENT,
                    name: BANNER_TYPE.LANDING_APPOINTMENT,
                },
                {
                    value: BANNER_TYPE.LANDING_BANQUET,
                    name: BANNER_TYPE.LANDING_BANQUET,
                },
                {
                    value: BANNER_TYPE.LANDING_RACK_PRODUCT,
                    name: BANNER_TYPE.LANDING_RACK_PRODUCT,
                },
                {
                    value: BANNER_TYPE.LANDING_ECOUPON,
                    name: BANNER_TYPE.LANDING_ECOUPON,
                },
            ],
            ACTION_TYPE: [
                { value: BANNER_ACTION.NONE, name: BANNER_ACTION.NONE },
                { value: BANNER_ACTION.DOWNLOAD, name: BANNER_ACTION.DOWNLOAD },
                { value: BANNER_ACTION.WEB_VIEW, name: BANNER_ACTION.WEB_VIEW },
                { value: BANNER_ACTION.LINK, name: BANNER_ACTION.LINK },
                // { value: BANNER_ACTION.PIXEL, name: BANNER_ACTION.PIXEL },
                { value: BANNER_ACTION.VIDEO, name: BANNER_ACTION.VIDEO },
                { value: BANNER_ACTION.PRODUCT, name: BANNER_ACTION.PRODUCT },
                {
                    value: BANNER_ACTION.PRODUCT_CATEGORY,
                    name: BANNER_ACTION.PRODUCT_CATEGORY,
                },
                {
                    value: BANNER_ACTION.PRODUCT_BRAND,
                    name: BANNER_ACTION.PRODUCT_BRAND,
                },
                { value: BANNER_ACTION.E_COUPON, name: BANNER_ACTION.E_COUPON },
                {
                    value: BANNER_ACTION.E_COUPON_CATEGORY,
                    name: BANNER_ACTION.E_COUPON_CATEGORY,
                },
                {
                    value: BANNER_ACTION.E_COUPON_BRAND,
                    name: BANNER_ACTION.E_COUPON_BRAND,
                },
                //{ value: BANNER_ACTION.CATEGORY_PRODUCT, name: BANNER_ACTION.CATEGORY_PRODUCT },
                //{ value: BANNER_ACTION.BRAND_PRODUCT, name: BANNER_ACTION.BRAND_PRODUCT },
                { value: BANNER_ACTION.FOLDER, name: BANNER_ACTION.FOLDER },
                { value: BANNER_ACTION.RESTAURANT, name: BANNER_ACTION.RESTAURANT },
                { value: BANNER_ACTION.SEARCH, name: BANNER_ACTION.SEARCH },
            ],
            MERCHANT_ACTION_TYPE: [
                { value: BANNER_ACTION.NONE, name: BANNER_ACTION.NONE },
                {
                    value: BANNER_ACTION.MERCHANT_WEB_VIEW,
                    name: BANNER_ACTION.WEB_VIEW,
                },
                { value: BANNER_ACTION.MERCHANT_LINK, name: BANNER_ACTION.LINK },
            ],
            PRODUCT_PARENT_TYPE: [
                {
                    value: BANNER_PRODUCT_PARENT.BRAND,
                    name: BANNER_PRODUCT_PARENT.BRAND,
                },
                {
                    value: BANNER_PRODUCT_PARENT.CATEGORY,
                    name: BANNER_PRODUCT_PARENT.CATEGORY,
                },
            ],
            HAVE_REF: [
                { value: true, name: 'true' },
                { value: false, name: 'false' },
            ],
            FOLDER_TYPE: [{ value: 'CAMPAIGN', name: 'CAMPAIGN' }],
            QUERY: [
                { value: 'QUEUE', name: 'QUEUE' },
                { value: 'TAKEAWAY', name: 'TAKEAWAY' },
                { value: 'RESERVE', name: 'RESERVE' },
                { value: 'APPOINTMENT', name: 'APPOINTMENT' },
                { value: 'RACK_PRODUCT', name: 'RACK_PRODUCT' },
            ],
            RESTAURANT_ACTION: [
                { value: 'INFO', name: 'INFO' },
                { value: 'WALL', name: 'WALL' },
                { value: 'NEW_REVIEW', name: 'NEW_REVIEW' },
                { value: 'MENU', name: 'MENU' },
                { value: 'QUEUE', name: 'QUEUE' },
                { value: 'RESERVE', name: 'RESERVE' },
                { value: 'APPOINTMENT', name: 'APPOINTMENT' },
                { value: 'callback', name: 'APPOINTMENT_CALLBACK' },
                { value: 'appointmentContactShop', name: 'APPOINTMENT_CONTACT_SHOP' },
                { value: 'TAKEAWAY', name: 'TAKEAWAY' },
            ],
        },
        MERCHANT_CONFIG: {
            QUEUE_REMIND_RESET_QUOTA: [
                { value: true, name: 'true' },
                { value: false, name: 'false' },
            ],
            QUEUE_CHANNEL_STATUS_CONTROL: [
                { value: true, name: 'true' },
                { value: false, name: 'false' },
            ],
            QUEUE_QUICK_CHECK_IN: [
                { value: true, name: 'true' },
                { value: false, name: 'false' },
            ],
            COUNTER_CALL_ENABLE: [
                { value: true, name: 'true' },
                { value: false, name: 'false' },
            ],
            QUEUE_REJECT_TICKET_ENABLE: [
                { value: true, name: 'true' },
                { value: false, name: 'false' },
            ],
            PUBLISH_PRODUCT: [
                { value: true, name: 'true' },
                { value: false, name: 'false' },
            ],
            CREATE_PRODUCT: [
                { value: true, name: 'true' },
                { value: false, name: 'false' },
            ],
            SEND_PRODUCT: [
                { value: true, name: 'true' },
                { value: false, name: 'false' },
            ],
            GULU_SLIDESHOW_ENABLE: [
                { value: true, name: 'true' },
                { value: false, name: 'false' },
            ],
        },
        STAFF: {
            USER_ROLE: [
                {
                    value: STAFF_ROLE.ROLE_MANAGER,
                    name: STAFF_ROLE.ROLE_MANAGER,
                },
                {
                    value: STAFF_ROLE.ROLE_OPERATOR,
                    name: STAFF_ROLE.ROLE_OPERATOR,
                },
                // { value: MERCHANT_USER_ROLE.ROLE_MERCHANT_USER_GUEST, name: MERCHANT_USER_ROLE.ROLE_MERCHANT_USER_GUEST },
            ],
            ENABLED: [
                { value: true, name: 'true' },
                { value: false, name: 'false' },
            ],
        },
        MERCHANT_DEVICE: {
            DEVICE_TYPE: [
                {
                    value: MERCHANT_DEVICE_TYPE.PR,
                    name: 'PR',
                },
                {
                    value: MERCHANT_DEVICE_TYPE.STAFF_PR,
                    name: 'STAFF_PR',
                },
                {
                    value: MERCHANT_DEVICE_TYPE.KIOSK,
                    name: 'KIOSK',
                },
                {
                    value: MERCHANT_DEVICE_TYPE.TV,
                    name: 'TV',
                },
                {
                    value: MERCHANT_DEVICE_TYPE.COUNTER_KIOSK,
                    name: 'COUNTER_KIOSK',
                },
                {
                    value: MERCHANT_DEVICE_TYPE.COUNTER_TV,
                    name: 'COUNTER_TV',
                },
                {
                    value: MERCHANT_DEVICE_TYPE.PICKUP_TV,
                    name: 'PICKUP_TV',
                },
                {
                    value: MERCHANT_DEVICE_TYPE.CAMPAIGN_QUEUE,
                    name: 'CAMPAIGN_QUEUE',
                },
                {
                    value: MERCHANT_DEVICE_TYPE.TAILOR_MADE,
                    name: 'TAILOR_MADE',
                },
                {
                    value: MERCHANT_DEVICE_TYPE.MALL_KIOSK,
                    name: 'MALL_KIOSK',
                },
                {
                    value: MERCHANT_DEVICE_TYPE.SLIDESHOW_PLAYER,
                    name: 'SLIDESHOW_PLAYER',
                },
                {
                    value: MERCHANT_DEVICE_TYPE.FALLBACK,
                    name: 'FALLBACK',
                },
                {
                    value: MERCHANT_DEVICE_TYPE.MALL_TV,
                    name: 'MALL_TV',
                },
            ],
            ACTIVATION_STATUS: [
                { value: 'A', name: { key: 'active' } },
                { value: 'E', name: { key: 'expired' } },
                { value: 'D', name: { key: 'deactive' } },
            ],
        },
        MERCHANT_USER: {
            USER_ROLE_FILTER: [
                {
                    value: MERCHANT_USER_ROLE.ROLE_MERCHANT_USER,
                    name: MERCHANT_USER_ROLE.ROLE_MERCHANT_USER,
                },
                // {
                //   value: MERCHANT_USER_ROLE.ROLE_MERCHANT_USER_GUEST,
                //   name: MERCHANT_USER_ROLE.ROLE_MERCHANT_USER_GUEST,
                // },
                {
                    value: MERCHANT_USER_ROLE.ROLE_MERCHANT_API,
                    name: MERCHANT_USER_ROLE.ROLE_MERCHANT_API,
                },
                {
                    value: MERCHANT_USER_ROLE.ROLE_MERCHANT_REPORT_USER,
                    name: MERCHANT_USER_ROLE.ROLE_MERCHANT_REPORT_USER,
                },
            ],
            USER_ROLE: [
                {
                    value: MERCHANT_USER_ROLE.ROLE_MERCHANT_USER,
                    name: MERCHANT_USER_ROLE.ROLE_MERCHANT_USER,
                },
                {
                    value: MERCHANT_USER_ROLE.ROLE_MERCHANT_API,
                    name: MERCHANT_USER_ROLE.ROLE_MERCHANT_API,
                },
                {
                    value: MERCHANT_USER_ROLE.ROLE_MERCHANT_REPORT_USER,
                    name: MERCHANT_USER_ROLE.ROLE_MERCHANT_REPORT_USER,
                },
                // { value: MERCHANT_USER_ROLE.ROLE_MERCHANT_USER_GUEST, name: MERCHANT_USER_ROLE.ROLE_MERCHANT_USER_GUEST },
            ],
            CHANNEL: [
                { value: 'SELF', name: 'SELF' },
                { value: 'SALES', name: 'SALES' },
                { value: 'SALES_PRE_ASSIGN', name: 'SALES_PRE_ASSIGN' },
                { value: 'HSBC_GROUP', name: 'HSBC_GROUP' },
                { value: 'HSBC', name: 'HSBC' },
                { value: 'ADMIN', name: 'ADMIN' },
                { value: 'YEDPAY', name: 'YEDPAY' },
            ],
            ENABLED: [
                { value: true, name: 'true' },
                { value: false, name: 'false' },
            ],
            ENABLED_MULTI_LOGIN: [
                { value: true, name: 'true' },
                { value: false, name: 'false' },
            ],
            PRIVILEGE_ATTRIBUTE: [
                { value: 'SHOP', name: 'SHOP' },
                { value: 'GROUP', name: 'GROUP' },
            ],
            MERCHANT_ROLE: [
                { value: 'ROLE_OPERATOR', name: 'ROLE_OPERATOR' },
                { value: 'ROLE_MANAGER', name: 'ROLE_MANAGER' },
                { value: 'ROLE_PEAK_TRAM_OPERATOR', name: 'ROLE_PEAK_TRAM_OPERATOR' },
            ],
        },
        MERCHANT_USER_GROUP: {
            SERVICE_TYPE: [
                { value: 'QUEUE', name: 'QUEUE' },
                { value: 'TAKEAWAY', name: 'TAKEAWAY' },
                { value: 'RESERVE', name: 'RESERVE' },
                { value: 'APPOINTMENT', name: 'APPOINTMENT' },
                { value: 'RACK_PRODUCT', name: 'RACK_PRODUCT' },
            ],
        },
        SYSTEM_USER: {
            USER_ROLE: [
                {
                    value: SYSTEM_USER.USER_ROLE.ROLE_ADMIN,
                    name: SYSTEM_USER.USER_ROLE.ROLE_ADMIN,
                },
                {
                    value: SYSTEM_USER.USER_ROLE.ROLE_USER,
                    name: SYSTEM_USER.USER_ROLE.ROLE_USER,
                },
                {
                    value: SYSTEM_USER.USER_ROLE.ROLE_MANAGER,
                    name: SYSTEM_USER.USER_ROLE.ROLE_MANAGER,
                },
                {
                    value: SYSTEM_USER.USER_ROLE.ROLE_REST,
                    name: SYSTEM_USER.USER_ROLE.ROLE_REST,
                },
                {
                    value: SYSTEM_USER.USER_ROLE.ROLE_COUPON,
                    name: SYSTEM_USER.USER_ROLE.ROLE_COUPON,
                },
                {
                    value: SYSTEM_USER.USER_ROLE.ROLE_SYSTEM_ADMIN,
                    name: SYSTEM_USER.USER_ROLE.ROLE_SYSTEM_ADMIN,
                },
            ],
            PRIVILEGE_ATTRIBUTE: [
                {
                    value: SYSTEM_USER.PRIVILEGE_ATTRIBUTE.S,
                    name: SYSTEM_USER.PRIVILEGE_ATTRIBUTE.SINGLE,
                },
                {
                    value: SYSTEM_USER.PRIVILEGE_ATTRIBUTE.G,
                    name: SYSTEM_USER.PRIVILEGE_ATTRIBUTE.GROUP,
                },
            ],
            STATUS: [
                { value: 'A', name: { key: 'active' } },
                { value: 'E', name: { key: 'expired' } },
                { value: 'D', name: { key: 'deactive' } },
            ],
            STATUS_DISPLAY: [
                { value: 'A', name: { key: 'active' } },
                { value: 'E', name: { key: 'expired' } },
            ],
            APIGROUP: [
                {
                    value: SYSTEM_USER.APIGROUP.KAISHING,
                    name: SYSTEM_USER.APIGROUP.KAISHING,
                },
                {
                    value: SYSTEM_USER.APIGROUP.MAXIMS_ONE,
                    name: SYSTEM_USER.APIGROUP.MAXIMS_ONE,
                },
                { value: SYSTEM_USER.APIGROUP.LINK, name: SYSTEM_USER.APIGROUP.LINK },
                {
                    value: SYSTEM_USER.APIGROUP.CHINACHEM,
                    name: SYSTEM_USER.APIGROUP.CHINACHEM,
                },
                {
                    value: SYSTEM_USER.APIGROUP.WECHAT,
                    name: SYSTEM_USER.APIGROUP.WECHAT,
                },
                { value: SYSTEM_USER.APIGROUP.SHKP, name: SYSTEM_USER.APIGROUP.SHKP },
            ],
        },
        RESTAURANT_AREA: {
            STATUS: [
                { value: 'A', name: { key: 'active' } },
                { value: 'D', name: { key: 'deactive' } },
                { value: 'P', name: { key: 'pending' } },
            ],
            AREA_ID: [
                { value: '1', name: { resource: 'RestaurantDistrict', key: 'hk' } },
                { value: '2', name: { resource: 'RestaurantDistrict', key: 'kln' } },
                { value: '3', name: { resource: 'RestaurantDistrict', key: 'nt' } },
                { value: '4', name: { resource: 'RestaurantDistrict', key: 'island' } },
            ],
        },
        RESTAURANT_DISTRICT: {
            STATUS: [
                { value: 'A', name: { key: 'active' } },
                { value: 'D', name: { key: 'deactive' } },
                { value: 'P', name: { key: 'pending' } },
            ],
        },
        RESTAURANT_DISTRICT_MAP: {
            STATUS: [
                { value: 'A', name: { key: 'active' } },
                { value: 'D', name: { key: 'deactive' } },
                { value: 'P', name: { key: 'pending' } },
            ],
        },
        RESTAURANT_LANDMARK: {
            STATUS: [
                { value: 'A', name: { key: 'active' } },
                { value: 'D', name: { key: 'deactive' } },
                { value: 'P', name: { key: 'pending' } },
            ],
            FAVORITE: [
                { value: true, name: 'true' },
                { value: false, name: 'false' },
            ],
        },
        RESTAURANT_LANDMARK_MAP: {
            STATUS: [
                { value: 'A', name: { key: 'active' } },
                { value: 'D', name: { key: 'deactive' } },
                { value: 'P', name: { key: 'pending' } },
            ],
        },
        STATUS: {
            ORDER_STATUS: [
                { value: 'A', name: { key: 'active' } },
                { value: 'D', name: { key: 'deactive' } },
            ],
            QUEUE_STATUS: [
                { value: 'A', name: { key: 'active' } },
                { value: 'D', name: { key: 'deactive' } },
                { value: 'P', name: { key: 'pending' } },
            ],
            QUEUE_VISIBLE: [
                { value: true, name: 'true' },
                { value: false, name: 'false' },
            ],
            QUEUE_SPRINGBOARD_AVAILABLE: [
                { value: true, name: 'true' },
                { value: false, name: 'false' },
            ],
            QUEUE_TICKET_DELETABLE: [
                { value: true, name: 'true' },
                { value: false, name: 'false' },
            ],
            QUEUE_WEB_AVAILABLE: [
                { value: true, name: 'true' },
                { value: false, name: 'false' },
            ],
            QUEUE_WEB_READ_ONLY: [
                { value: true, name: 'true' },
                { value: false, name: 'false' },
            ],
            RESERVATION_WEB_AVAILABLE: [
                { value: true, name: 'true' },
                { value: false, name: 'false' },
            ],
            RESERVE_STATUS: [
                { value: 'A', name: { key: 'active' } },
                { value: 'D', name: { key: 'deactive' } },
            ],
            RESERVE_VISIBLE: [
                { value: true, name: 'true' },
                { value: false, name: 'false' },
            ],
            TAKEAWAY_STATUS: [
                { value: 'A', name: { key: 'active' } },
                { value: 'D', name: { key: 'deactive' } },
            ],
            TAKEAWAY_VISIBLE: [
                { value: true, name: 'true' },
                { value: false, name: 'false' },
            ],
            TAKEAWAY_DELIVERY_STATUS: [
                { value: 'A', name: { key: 'active' } },
                { value: 'D', name: { key: 'deactive' } },
            ],
            TAKEAWAY_PICKUP_STATUS: [
                { value: 'A', name: { key: 'active' } },
                { value: 'D', name: { key: 'deactive' } },
            ],
            TAKEAWAY_PREORDER_STATUS: [
                { value: 'A', name: { key: 'active' } },
                { value: 'D', name: { key: 'deactive' } },
            ],
            GUEST_RESERVATION_STATUS: [
                { value: 'A', name: { key: 'active' } },
                { value: 'D', name: { key: 'deactive' } },
            ],
            GUEST_TICKET_STATUS: [
                { value: 'A', name: { key: 'active' } },
                { value: 'D', name: { key: 'deactive' } },
            ],
            WX_TAKEAWAY_STATUS: [
                { value: 'A', name: { key: 'active' } },
                { value: 'D', name: { key: 'deactive' } },
            ],
            WX_PRODUCT_STATUS: [
                { value: 'A', name: { key: 'active' } },
                { value: 'D', name: { key: 'deactive' } },
            ],
            MENU_STATUS: [
                { value: 'A', name: { key: 'active' } },
                { value: 'D', name: { key: 'deactive' } },
            ],
            MENU_VISIBLE: [
                { value: true, name: 'true' },
                { value: false, name: 'false' },
            ],
            ECOUPON_DISTRIBUTE_STATUS: [
                { value: 'A', name: { key: 'active' } },
                { value: 'D', name: { key: 'deactive' } },
            ],
            PICKUP_VISIBLE: [
                { value: true, name: 'true' },
                { value: false, name: 'false' },
            ],
            BANQUET_STATUS: [
                { value: 'A', name: { key: 'active' } },
                { value: 'D', name: { key: 'deactive' } },
            ],
            BANQUET_VISIBLE: [
                { value: true, name: 'true' },
                { value: false, name: 'false' },
            ],
            HAVE_PREORDER: [
                { value: 'A', name: { key: 'active' } },
                { value: 'D', name: { key: 'deactive' } },
                { value: 'N', name: { key: 'N' } },
            ],
            AUTO_SWITCH_TIME_SECTION: [
                { value: 'A', name: { key: 'active' } },
                { value: 'D', name: { key: 'deactive' } },
            ],
            ADMIN_LITE_STATUS: [
                { value: 'A', name: { key: 'active' } },
                { value: 'D', name: { key: 'deactive' } },
            ],
            VIP_STATUS: [
                { value: 'A', name: { key: 'active' } },
                { value: 'D', name: { key: 'deactive' } },
            ],
            TURBO_STATUS: [
                { value: 'A', name: { key: 'active' } },
                { value: 'D', name: { key: 'deactive' } },
            ],
            BOOKMARK_STATUS: [
                { value: 'A', name: { key: 'active' } },
                { value: 'D', name: { key: 'deactive' } },
            ],
            REVIEW_STATUS: [
                { value: 'A', name: { key: 'active' } },
                { value: 'D', name: { key: 'deactive' } },
            ],
            SOCIAL_PHOTO_STATUS: [
                { value: 'A', name: { key: 'active' } },
                { value: 'D', name: { key: 'deactive' } },
            ],
            GPS_REQUIRED: [
                { value: true, name: 'true' },
                { value: false, name: 'false' },
            ],
        },
        UPLOAD_IMAGE: {
            TABLE_NAME: [
                { value: 'restaurant_media', name: 'restaurant_media' },
                { value: 'restaurant', name: 'restaurant' },
                { value: 'folder_info', name: 'folder_info' },
                { value: 'restaurant_banner', name: 'restaurant_banner' },
                { value: 'landing_item', name: 'landing_item' },
                { value: 'landing_section', name: 'landing_section' },
                { value: 'rack_product_media', name: 'rack_product_media' },
                { value: 'takeaway_media', name: 'takeaway_media' },
                { value: 'slideshow_media', name: 'slideshow_media' },
                { value: 'slideshow_info', name: 'slideshow_info' },
                { value: 'ecoupon_master', name: 'ecoupon_master' },
                { value: 'beauty_staff_info', name: 'beauty_staff_info' },
                { value: 'beauty_staff_profile', name: 'beauty_staff_profile' },
                { value: 'beauty_service_tag', name: 'beauty_service_tag' },
                { value: 'clp_icon', name: 'clp_icon' },
                { value: 'fast_pass_config', name: 'fast_pass_config' },
                { value: 'promotion_tag_map', name: 'promotion_tag_map' },
                { value: 'app_member', name: 'app_member' },
            ],
            FIELD_NAME: [
                { value: 'tc_tab_image_cos_url', name: 'tc_tab_image_cos_url' },
                { value: 'sc_tab_image_cos_url', name: 'sc_tab_image_cos_url' },
                { value: 'en_tab_image_cos_url', name: 'en_tab_image_cos_url' },
                { value: 'media_cos_url', name: 'media_cos_url' },
                { value: 'thumbnail_image_cos_url', name: 'thumbnail_image_cos_url' },
                { value: 'large_image_cos_url', name: 'large_image_cos_url' },
                { value: 'profile_image_cos_url', name: 'profile_image_cos_url' },
                { value: 'tc_image_cos_url', name: 'tc_image_cos_url' },
                { value: 'sc_image_cos_url', name: 'sc_image_cos_url' },
                { value: 'en_image_cos_url', name: 'en_image_cos_url' },
                { value: 'banner_image_cos_url', name: 'banner_image_cos_url' },
                { value: 'image_cos_url', name: 'image_cos_url' },
                { value: 'icon_image_cos_url', name: 'icon_image_cos_url' },
                { value: 'tag_image_cos_url', name: 'tag_image_cos_url' },
            ],
        },
        PARAMETER: {
            GENDER: [
                { value: 'all', name: { resource: 'Push', key: 'all' } },
                { value: 'F', name: { resource: 'Push', key: 'female' } },
                { value: 'M', name: { resource: 'Push', key: 'male' } },
            ],
            OS: [
                { value: 'all', name: { resource: 'Push', key: 'all' } },
                { value: 'Android', name: { resource: 'Push', key: 'android' } },
                { value: 'iOS', name: { resource: 'Push', key: 'iOS' } },
            ],
            AGE_GROUP: [
                { value: '0 - 12', name: '0 - 12' },
                { value: '13 - 18', name: '13 - 18' },
                { value: '19 - 29', name: '19 - 29' },
                { value: '30 - 39', name: '30 - 39' },
                { value: '40 - 49', name: '40 - 49' },
                { value: '50 - 59', name: '50 - 59' },
                { value: '60+', name: '60+' },
            ],
        },
        PRODUCT_CREATE: {
            CREATE_CATEGORY: {
                CHANNEL: [
                    { value: GLOBAL, name: GLOBAL },
                    { value: MOBILE, name: MOBILE },
                    // { value: "WECHAT_H5", name: "WECHAT_H5" },
                    { value: WECHAT_MP, name: WECHAT_MP },
                ],
                CAT_IMAGE_TYPE: [
                    { value: CATEGORY_ALL, name: CATEGORY_ALL },
                    { value: CATEGORY_ICON, name: CATEGORY_ICON },
                    { value: CATEGORY_DETAIL, name: CATEGORY_DETAIL },
                    { value: CATEGORY_4BY3, name: CATEGORY_4BY3 },
                ],
                STATUS: [
                    { value: 'A', name: { key: 'active' } },
                    { value: 'D', name: { key: 'deactive' } },
                    { value: 'P', name: { key: 'pending' } },
                ],
                PRODUCT_MAP_STATUS: [
                    { value: 'A', name: { key: 'active' } },
                    { value: 'D', name: { key: 'deactive' } },
                    // { value: "P", name: {resource:  "", key: "pending" }}
                ],
            },
            CREATE_REDEEM_GROUP: {
                SELECT_LOCATION: [
                    { value: true, name: 'true' },
                    { value: false, name: 'false' },
                ],
                SELECT_DATE: [
                    { value: true, name: 'true' },
                    { value: false, name: 'false' },
                ],
                SELECT_TIME: [
                    { value: true, name: 'true' },
                    { value: false, name: 'false' },
                ],
                REDEEM_TYPE: [
                    { value: FIXED_START_END, name: FIXED_START_END },
                    { value: FLEXIBLE_DAYS, name: FLEXIBLE_DAYS },
                    { value: PREORDER, name: PREORDER },
                ],
                REDEEM_TYPE_EDIT: [
                    { value: FIXED_START_END, name: FIXED_START_END },
                    { value: FLEXIBLE_DAYS, name: FLEXIBLE_DAYS },
                ],
            },
            CREATE_PRODUCT: {
                TYPE: [
                    // { value: "VALUE_UP_ECOUPON_ITEM", name: "VALUE_UP_ECOUPON_ITEM" },
                    { value: PACKAGE_ITEM, name: PACKAGE_ITEM },
                    { value: PACKAGE_SUB_ITEM, name: PACKAGE_SUB_ITEM },
                    { value: PREORDER_ITEM, name: PREORDER_ITEM },
                    { value: ECOUPON_ITEM, name: ECOUPON_ITEM },
                    { value: FREE_ECOUPON_ITEM, name: FREE_ECOUPON_ITEM },
                ],
                CHANNEL: [
                    { value: GLOBAL, name: GLOBAL },
                    { value: MOBILE, name: MOBILE },
                    // { value: "WECHAT_H5", name: "WECHAT_H5" },
                    { value: WECHAT_MP, name: WECHAT_MP },
                ],
                SEARCHABLE: [
                    { value: true, name: 'true' },
                    { value: false, name: 'false' },
                ],
                SELF_REDEEM: [
                    { value: true, name: 'true' },
                    { value: false, name: 'false' },
                ],
                SELF_REFUND: [
                    { value: true, name: 'true' },
                    { value: false, name: 'false' },
                ],
                SHAREABLE: [
                    { value: true, name: 'true' },
                    { value: false, name: 'false' },
                ],
                PACKAGE_CASH_COUPON: [
                    { value: true, name: 'true' },
                    { value: false, name: 'false' },
                ],
                REQUIRE_FILL_IN_EMAIL: [
                    { value: true, name: 'true' },
                    { value: false, name: 'false' },
                ],
                REQUIRE_FILL_IN_MOBILE_NUMBER: [
                    { value: true, name: 'true' },
                    { value: false, name: 'false' },
                ],
                REQUIRE_FILL_IN_CONTACT_PERSON: [
                    { value: true, name: 'true' },
                    { value: false, name: 'false' },
                ],
                PRODUCT_IMAGE_TYPE: [
                    { value: ITEM_ALL, name: ITEM_ALL },
                    { value: ITEM_ICON, name: ITEM_ICON },
                    { value: ITEM_DETAIL, name: ITEM_DETAIL },
                    { value: ITEM_4BY3, name: ITEM_4BY3 },
                ],
                PRODUCT_BLACKOUT_PERIOD_TYPE: [{ value: 'PICKUP_ITEM', name: 'PICKUP_ITEM' }],
                ATTRIBUTE_TYPE: [
                    { value: TEXT, name: TEXT },
                    { value: SELECT, name: SELECT },
                ],
                DISPLAY: [
                    { value: true, name: 'true' },
                    { value: false, name: 'false' },
                ],
                STATUS: [
                    { value: 'C', name: { key: 'complete' } },
                    // { value: "D", name: {resource:  "", key: "deactive" }},
                    { value: 'P', name: { key: 'pending' } },
                    { value: 'TBA', name: { key: 'tobeapprove' } },
                ],
                EDIT_STATUS: [
                    // { value: "A", name: {resource:  "", key: "active" }},
                    { value: 'D', name: { key: 'deactive' } },
                    { value: 'P', name: { key: 'pending' } },
                ],
            },
            SELECT_CATEGORY_TYPE: {
                ALL: 'ALL',
                PRODUCT: 'PRODUCT',
                FREE_ECOUPON: 'FREE_ECOUPON',
                FREE_ECOUPON_ITEM: 'FREE_ECOUPON_ITEM',
            },
            EDIT_PRODUCT: {
                CATEGORY_TYPE: [
                    { value: SELECT_CATEGORY_TYPE.ALL, name: SELECT_CATEGORY_TYPE.ALL },
                    {
                        value: SELECT_CATEGORY_TYPE.PRODUCT,
                        name: SELECT_CATEGORY_TYPE.PRODUCT,
                    },
                    {
                        value: SELECT_CATEGORY_TYPE.FREE_ECOUPON,
                        name: SELECT_CATEGORY_TYPE.FREE_ECOUPON,
                    },
                ],
                CHANNEL: [
                    { value: GLOBAL, name: GLOBAL },
                    { value: MOBILE, name: MOBILE },
                    // { value: "WECHAT_H5", name: "WECHAT_H5" },
                    { value: WECHAT_MP, name: WECHAT_MP },
                ],
                CATEGORY_STATUS: [
                    { value: 'A', name: { key: 'active' } },
                    { value: 'D', name: { key: 'deactive' } },
                ],
                PRODUCT_STATUS: [
                    { value: 'A', name: { key: 'active' } },
                    { value: 'P', name: { key: 'pending' } },
                    { value: 'D', name: { key: 'deactive' } },
                ],
                PRODUCT_STATUS_FILTER: [
                    { value: 'A', name: { key: 'active' } },
                    { value: 'P', name: { key: 'pending' } },
                    { value: 'D', name: { key: 'deactive' } },
                    { value: 'TBA', name: { key: 'tobeapprove' } },
                ],
                PRODUCT_MAP_STATUS: [
                    { value: 'A', name: { key: 'active' } },
                    { value: 'D', name: { key: 'deactive' } },
                ],
                PUBLISHED: [
                    { value: 'TO_BE_PUBLISHED', name: 'TO_BE_PUBLISHED' },
                    { value: 'PUBLISHING', name: 'PUBLISHING' },
                ],
                TYPE: [
                    // { value: "VALUE_UP_ECOUPON_ITEM", name: "VALUE_UP_ECOUPON_ITEM" },
                    { value: PACKAGE_ITEM, name: PACKAGE_ITEM },
                    { value: PACKAGE_SUB_ITEM, name: PACKAGE_SUB_ITEM },
                    { value: PREORDER_ITEM, name: PREORDER_ITEM },
                    { value: ECOUPON_ITEM, name: ECOUPON_ITEM },
                    { value: VALUE_UP_ECOUPON_ITEM, name: VALUE_UP_ECOUPON_ITEM },
                    { value: FREE_ECOUPON_ITEM, name: FREE_ECOUPON_ITEM },
                    { value: 'NOT_FOR_SALE_ITEM', name: 'NOT_FOR_SALE_ITEM' },
                ],
                MERCHANT_PRODUCT: [
                    { value: true, name: 'true' },
                    { value: false, name: 'false' },
                ],
            },
            LOT_INVENTORY: {
                TYPE: [
                    { value: AUTO_GENERATE, name: AUTO_GENERATE },
                    { value: AUTO_GENERATE_MEZZOFY, name: AUTO_GENERATE_MEZZOFY },
                    { value: CUSTOMIZE, name: CUSTOMIZE },
                    { value: CUSTOMIZE_TGR, name: CUSTOMIZE_TGR },
                    { value: CUSTOMIZE_MASS_CODE, name: CUSTOMIZE_MASS_CODE },
                ],
                MEZZOFY_COUPON_QUANTITY_SYNC: [
                    { value: true, name: 'true' },
                    { value: false, name: 'false' },
                ],
            },
        },
        CONTACT_US: {
            TOPIC: [
                { value: 'GENERAL', name: 'GENERAL' },
                { value: 'FEEDBACKS', name: 'FEEDBACKS' },
                { value: 'PLANS', name: 'PLANS' },
                { value: 'ADVERTISING', name: 'ADVERTISING' },
                { value: 'LOGIN', name: 'LOGIN' },
                { value: 'SHOP_INFORMATION', name: 'SHOP_INFORMATION' },
                { value: 'SERVICE_ACTIVATION', name: 'SERVICE_ACTIVATION' },
                { value: 'LAUNCH_SHOP', name: 'LAUNCH_SHOP' },
                { value: 'PAYMENT', name: 'PAYMENT' },
                { value: 'TECHNICAL_PROBLEM', name: 'TECHNICAL_PROBLEM' },
                { value: 'USER_ACCOUNT', name: 'USER_ACCOUNT' },
                { value: 'APP_FUNCTION', name: 'APP_FUNCTION' },
                { value: 'RESTAURANT_REGISTRATION', name: 'RESTAURANT_REGISTRATION' },
                { value: 'ORDER', name: 'ORDER' },
                { value: 'REFUND', name: 'REFUND' },
                { value: 'MISSING_QRCODE', name: 'MISSING_QRCODE' },
            ],
            MESSAGE_TYPE: [
                {
                    value: CONTACT_US_MESSAGE_TYPE.MOBILE,
                    name: CONTACT_US_MESSAGE_TYPE.MOBILE,
                },
                {
                    value: CONTACT_US_MESSAGE_TYPE.MERCHANT,
                    name: CONTACT_US_MESSAGE_TYPE.MERCHANT,
                },
                {
                    value: CONTACT_US_MESSAGE_TYPE.WEB,
                    name: CONTACT_US_MESSAGE_TYPE.WEB,
                },
            ],
            STATUS: [
                { value: 'A', name: { key: 'active' } },
                { value: 'P', name: { key: 'processing' } },
                { value: 'C', name: { key: 'complete' } },
            ],
        },
        SELECT_TAG: {
            TYPE: [
                { value: 'TICKET', name: 'TICKET' },
                { value: 'RESERVE', name: 'RESERVE' },
            ],
            STATUS: [
                { value: 'A', name: { key: 'active' } },
                { value: 'P', name: { key: 'pending' } },
                { value: 'D', name: { key: 'deactive' } },
            ],
        },
        SELECT_TAG_CONFIG: {
            TYPE: [
                { value: 'TICKET', name: 'TICKET' },
                { value: 'RESERVE', name: 'RESERVE' },
            ],
            STATUS: [
                { value: 'A', name: { key: 'active' } },
                { value: 'P', name: { key: 'pending' } },
            ],
        },
        TOOLS: {
            CORNERS_SQAURE_OPTIONS: [
                { value: 'zig-zag', name: 'ZIG-ZAG' },
                { value: 'square', name: 'SQUARE' },
                { value: 'round', name: 'ROUND' },
            ],
            DOTS_OPTIONS: [
                { value: 'square', name: 'SQUARE' },
                { value: 'dots', name: 'DOTS' },
            ],
            CORNERS_DOT_OPTIONS: [
                { value: 'square', name: 'SQUARE' },
                { value: 'dot', name: 'DOT' },
            ],
            ERROR_CORRECTION_LEVEL: [
                { value: 'H', name: '~30% correction' },
                { value: 'L', name: '~7% correction' },
                { value: 'M', name: '~15% correction' },
                { value: 'Q', name: '~25% correction' },
            ],
            LOGO: [
                { value: true, name: 'WITH LOGO' },
                { value: false, name: 'WITHOUT LOGO' },
            ],
            DOWNLOAD_FORMAT: [
                { value: 'png', name: 'PNG' },
                { value: 'svg', name: 'SVG' },
            ],
        },
    },
}

export const IMAGE_TYPE = {
    BRAND: 'Brand',
    PRODUCT: 'Product',
    CATEGORY: 'Category',
    CATEGORY_CREATE: 'Category Create',
    RESTAURANT_IMAGE: 'Restaurant Image',
    MERCHANT_SHOP: 'MerchantShop',
    CONTACT_US: 'contactUs',
    BRAND_APPROVE: 'BrandApprove',
    LANDING_SECTION: 'LandingSection',
    RESTAURANT_APPROVE_LIST: 'RestaurantApproveList',
    PAYMENT: 'Payment',
    PRINT_CONFIG: 'PrintConfig',
    SLIDESHOW: 'Slideshow',
    MEDIA: 'Media',
    ticketType: 'ticketType',
}

export const UPLOAD_TYPE = {
    FORMDATA: 'FORMDATA',
    BASE64: 'BASE64',
}

export const SLIDESHOW_TYPE = {
    MP4: 'video/mp4',
    VIDEO: 'VIDEO',
    IMAGE: 'IMAGE',
}

export const SLIDESHOW_VIDEO_FORMAT = {
    H264_BASELINE: 'avc1.42E0',
    H264_BASELINE_LOWERCASE: 'avc1.42e0',
    H264_MAIN: 'avc1.4D40',
    H264_MAIN_LOWERCASE: 'avc1.4d40',
    H264_HIGH: 'avc1.6400',
    AVC1: 'avc1',
}

export const STATUS = {
    active: 'A',
    pending: 'P',
    delete: 'D',
}

export const HOST = {
    REST: 'rest',
    FOLDER: 'folder',
    BOOKMARK: 'bookmark',
    SEARCH: 'search',
    SEARCH_NEAR_BY: 'searchnearby',
    MY_TICKET: 'myticket',
    TRANSFORM_TICKET_FROM_PAPER: 'transformticketfrompaper',
    TRANSFORM_ECOUPON_FROM_PAPER: 'transformecouponfrompaper',
    EXPRESS_TICKET_VOUCHER: 'expressticketvoucher',
    SHARE: 'share',
    GIFT: 'gift',
    PRODUCT: 'product',
    ECOUPON: 'e-coupon',
    WEB: 'web',
    SCAN_QR_CODE: 'scanqrcode',
    LAUNCH_APP: 'launchapp',
    LINK: 'link',
    SURVEY: 'survey',
    TAKEAWAY: 'takeaway',
    YOUTUBE: 'youtube',
    SHOP_CATEGORY: 'shopcategory',
}
