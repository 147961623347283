import { Divider, Stack } from '@mui/material'
import { LANDING_ACTION } from '../../../Constants/constants'
import { getString, t } from '../../../helper/util'
import { useSelector } from 'react-redux'

const Title = ({ item, isPreview }) => {
    if (isPreview) {
        return (
            <h4 style={{ color: item.titleColor ? item.titleColor : '#FFFFFF' }}>{item.tcTitle}</h4>
        )
    }

    return <h2 style={{ color: item.titleColor ? item.titleColor : '#FFFFFF' }}>{item.tcTitle}</h2>
}

const PlaceholderTitle = ({ isPreview, placeholder }) => {
    if (isPreview) {
        return <h4 style={{ color: '#FFF' }}>{placeholder}</h4>
    }

    return <h2 style={{ color: '#FFF' }}>{placeholder}</h2>
}

export const LandingHeader = ({ item, isPreview }) => {
    const lang = useSelector((state) => state.lang.ui.lang)
    const theme = useSelector((state) => state.theme.ui.theme)
    const resource = 'LandingSection'

    return (
        <div className='landing-header'>
            <Stack
                gap={1}
                direction='row'
                divider={<Divider orientation='vertical' flexItem variant='middle' />}
            >
                {item.image && (
                    <div className='landing-header-left-icon'>
                        <img src={item.image} alt='logo' />
                    </div>
                )}
                {item.channel && (
                    <PlaceholderTitle placeholder={`${item.channel} :`} isPreview={isPreview} />
                )}
                {item.tcTitle && <Title item={item} isPreview={isPreview} />}
                {item.action === LANDING_ACTION.ROTATING_BANNER && (
                    <PlaceholderTitle placeholder={item.action} isPreview={isPreview} />
                )}
                {isPreview && item.regionCode && (
                    <h4 style={{ color: '#FFF' }}>
                        {`${getString(lang, resource, 'region', theme)}: ${item.regionName}`}
                    </h4>
                )}
                {isPreview && item.cityCode && (
                    <h4 style={{ color: '#FFF' }}>
                        {`${getString(lang, resource, 'city', theme)}: ${item.cityName}`}
                    </h4>
                )}
            </Stack>
            {item.action && item.action !== LANDING_ACTION.ROTATING_BANNER && (
                <h4>{getString(lang, resource, 'more', theme)}</h4>
            )}
        </div>
    )
}
