import { useState, useEffect } from 'react'
import { useDispatch, useSelector, useStore } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import _ from 'lodash'

import { makeStyles } from '@mui/styles'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import Typography from '@mui/material/Typography'
import LowPriorityIcon from '@mui/icons-material/LowPriority'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ClearIcon from '@mui/icons-material/Clear'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import SaveIcon from '@mui/icons-material/Save'
import DoneIcon from '@mui/icons-material/Done'
import MTextField from '@mui/material/TextField'
// import SplitPane from 'react-split-pane';
import { Allotment } from 'allotment'
import 'allotment/dist/style.css'
import Card from '@mui/material/Card'

// import { UserGroupList } from "./UserGroup/UserGroupList";
import { ProductCategoryList } from './Category/ProductCategoryList'
import { ProductCategoryEdit } from './Category/ProductCategoryEdit'
import { getCetegory } from './Category/getCetegory'
import { ProductCreateRedeemGroupList } from './Redeem/RedeemGroupList'
import { RedeemGroupCreateEdit } from './Redeem/RedeemGroupCreateEdit'
import { ProductCreateProductList } from './Product/ProductCreateList'
import { ProductTabs } from './Product/ProductTabs'
import { ProductCreateProductLotList } from './ProductLot/ProductLotList'
import { ProductCreateProductLotCreateEdit } from './ProductLot/ProductLotCreateEdit'
import { constants as cons } from '../../Constants/constants'
import { getOne, put, post } from '../../helper/baseFetch'
import { BaseWrapper } from '../BaseWrapper'
import { setLoading } from '../../actions/systemAction'
import { setProductValue, setProduct, setProductDefault } from '../../actions/productCreateAction'
import { TempBtn } from '../Input/TempBtn'
import { getProductStepError } from './getProductStepError'
import { getString, permissionsCheck, getScDisabled, helperTextStyles } from '../../helper/util'
import { GlobalDialog } from '../GlobalDialog'

import ProductCreateValidation from '../../validations/productCreateValidation'
import { TempTextInput } from '../Input/TempTextInput'

export const PRODUCT_STEPS = [
  'Product Cat',
  'Redeem Group',
  'Product Detail',
  'Lot Inventory',
]

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100vh',
    overflow: 'none',
  },
  button: {
    margin: theme.spacing(1),
  },
  completed: {
    display: 'inline-block',
  },
  error: {
    display: 'flex',
    justifyContent: 'center',
  },
}))

// function getSteps() {
//   return [
//     // 'User Group',
//     'Product Cat',
//     'Redeem Group',
//     'Product Detail',
//     'Lot Inventory',
//   ]
// }

//   function SelectUserGroup(userGroup) {
//     dispatch(setLoading(true));
//     dispatch(setProductValue(constants.ACTION_KEY.PRODUCT.DRAFT, savedProduct, "userGroupCode", userGroup.groupCode));
//     dispatch(setLoading(false));
//   }

//   function clearUserGroup() {
//     dispatch(setLoading(true));
//     dispatch(setProductValue(constants.ACTION_KEY.PRODUCT.DRAFT, savedProduct, "userGroupCode", ""));
//     dispatch(setLoading(false));
//   }

function getStepContent(step, savedProduct, clearCategory, disableInput, handlePanelSize, onClickListRow, dispatch, lang, theme) {
  switch (step) {
    // case constants.PRODUCT_DRAFT_STEP.USER_GROUP:
    //   return (
    //     <div className="step-content-wrapper">
    //       <div className="sub-title">{getString(lang, "productCreateUserGroupList", "name", theme)} : </div>
    //       <MTextField
    //         className="step-content-show-box"
    //         size={constants.SIZE.SMALL}
    //         value={savedProduct.draft && savedProduct.draft.userGroupCode ? savedProduct.draft.userGroupCode : ""}
    //         disabled={true}
    //         helperTextIcon={true},
    //         helperText={ProductCreateValidation.getErrorMessages(productValidationConstants.KEY_USER_GROUP_CODE, savedProduct.draft.userGroupCode).map((error) => { return error })}
    //         FormHelperTextProps={{ className: helperTextStylesClasses.root }}
    //       />
    //       <TempBtn btnSetting={{
    //         className: "step-content-show-box",
    //         variant: constants.STYLE.VARIANT.CONTAINED,
    //         color: constants.STYLE.PRIMARY,
    //         onClick: clearUserGroup,
    //         label: {resource: "productCreateUserGroupList", key: "list.clearUserGroup"},
    //         icon: <ClearIcon />,
    //         disabled: disableInput,
    //       }} />
    //       <UserGroupList
    //         header=" "
    //         onClickListRow={(item) => { SelectUserGroup(item); }}
    //         editBtn={false}
    //         createBtn={false}
    //         listOverflow="step-content-content step-content-content-padding"
    //       />
    //     </div>
    //   )

    case cons.PRODUCT_DRAFT_STEP.CATEGORY:
      return (
        <div className="step-content-wrapper">
          {!disableInput ? (
            <Allotment defaultSizes={[100, 200]}>
              <div className="split-pane-left" style={{ height: '75vh', minHeight: '590px' }}>
                <div className="category-list-wrapper">
                  <div className="sub-title-wrapper">
                    <div className="sub-title">{getString(lang, 'productCreateProductCategoryList', 'mainTitle', theme)} : </div>
                    <MTextField
                      className="step-content-show-box"
                      variant={cons.STYLE.VARIANT.STANDARD}
                      value={savedProduct.draft && savedProduct.draft.category.categoryCode ? savedProduct.draft.category.categoryCode : ''}
                      disabled={true}
                    />
                    <TempBtn
                      btnSetting={{
                        className: 'step-content-show-box',
                        variant: cons.STYLE.VARIANT.CONTAINED,
                        color: cons.STYLE.PRIMARY,
                        onClick: clearCategory,
                        label: { key: 'clear' },
                        icon: <ClearIcon />,
                        disabled: disableInput,
                      }}
                    />
                  </div>
                  <ProductCategoryList
                    header=" "
                    selectBtn={false}
                    onClickListRow={(item) => {
                      onClickListRow(item)
                    }}
                    editBtn={false}
                    createBtn={false}
                    listOverflow="step-content-content step-content-content-padding"
                  />
                </div>
              </div>
              <div className="split-pane-right" style={{ height: '75vh', minHeight: '590px' }}>
                <ProductCategoryEdit listOverflow="step-content-content step-content-content-padding" />
              </div>
            </Allotment>
          ) : (
            <div className="split-pane-right flex-right" style={{ height: '75vh', minHeight: '590px' }}>
              <ProductCategoryEdit />
            </div>
          )}
        </div>
      )
    case cons.PRODUCT_DRAFT_STEP.REDEEM_GROUP:
      return (
        <div className="step-content-wrapper">
          <Allotment defaultSizes={[100, 200]}>
            <div className="split-pane-left" style={{ height: '75vh', minHeight: '590px' }}>
              <ProductCreateRedeemGroupList />
            </div>
            <div className="split-pane-right" style={{ height: '75vh', minHeight: '590px' }}>
              {savedProduct.draft.redeemGroup && <RedeemGroupCreateEdit />}
            </div>
          </Allotment>
        </div>
      )
    case cons.PRODUCT_DRAFT_STEP.PRODUCT:
      return (
        <div className="step-content-wrapper">
          <Allotment defaultSizes={[100, 200]}>
            <div className="split-pane-left" style={{ height: '75vh', minHeight: '590px' }}>
              <ProductCreateProductList />
            </div>
            <div className="split-pane-right" style={{ height: '75vh', minHeight: '590px' }}>
              {savedProduct.draft.product && <ProductTabs isEmbeded={true} />}
            </div>
          </Allotment>
        </div>
      )
    case cons.PRODUCT_DRAFT_STEP.PRODUCT_LOT:
      return (
        <div className="step-content-wrapper">
          <Allotment defaultSizes={[100, 200]}>
            <div className="split-pane-left" style={{ height: '75vh', minHeight: '590px' }}>
              <ProductCreateProductLotList />
            </div>
            <div className="split-pane-right" style={{ height: '75vh', minHeight: '590px' }}>
              {savedProduct.draft.lotInventory && <ProductCreateProductLotCreateEdit />}
            </div>
          </Allotment>
        </div>
      )
    default:
      return 'Unknown step'
  }
}

// In Product Create

export const ProductSteps = () => {
  const classes = useStyles()
  // const [activeStep, setActiveStep] = useState(0)
  const [completed, setCompleted] = useState(new Set())
  // const steps = getSteps()
  const steps = PRODUCT_STEPS
  const [ready, setReady] = useState(false)
  const history = useHistory()
  const [errorVersion, setErrorVersion] = useState(ProductCreateValidation.getVersion())
  const helperTextStylesClasses = helperTextStyles()
  const [draftNameError, setDraftNameError] = useState('')
  const [clear, setClear] = useState(true)

  const savedProduct = useSelector((state) => state.product.ui.product)
  const errorPage = useSelector((state) => state.product.ui.productStepError)
  const lang = useSelector((state) => state.lang.ui.lang)
  const theme = useSelector((state) => state.theme.ui.theme)
  const permission = localStorage.getItem(cons.LOCAL_STORAGE_KEY.ROLE)
  const dispatch = useDispatch()
  const store = useStore()
  const params = useParams()
  const [disableInput, setDisableInput] = useState(false)
  const [dialog, setDialog] = useState({
    open: false,
    dialogActions: [],
  })

  function handleProductDisable(product) {
    product.blackoutPeriodList = []
    product.scNameDisable = getScDisabled(product.tcName, product.scName)
    product.scDescriptionDisable = getScDisabled(product.tcDescription, product.scDescription)
    product.scTacDisable = getScDisabled(product.tcTac, product.scTac)
    product.scRedeemDescriptionDisable = getScDisabled(product.tcRedeemDescription, product.scRedeemDescription)
    product.scDiscountDescriptionDisable = getScDisabled(product.tcDiscountDescription, product.scDiscountDescription)

    if (product.attributeList && product.attributeList.length > 0) {
      product.attributeList.map((attribute, attributeIndex) => {
        if (attribute.optionList && attribute.optionList.length > 0) {
          attribute.optionList.map((optionItem, optionIndex) => {
            optionItem.scValueDisable = getScDisabled(optionItem.tcValue, optionItem.scValue)
            return optionItem
          })
        }
        return attribute
      })
    }
    return product
  }

  useEffect(() => {
    ProductCreateValidation.resetErrorMessages()

    if (!clear) {
      return
    }

    setReady(false)
    dispatch(setProductDefault())
    let tempId = params.draftId && _.cloneDeep(params.draftId).split('=')
    let draftId = tempId && (tempId[0] !== 'clone' ? params.draftId : tempId[1])
    if (draftId) {
      getOne(
        cons.PATH.PRODUCT.DRAFT + '/' + draftId,
        undefined,
        (payload) => {
          let tempSavedProduct = payload
          let draft = JSON.parse(tempSavedProduct.draft)
          const newDraft = _.merge({}, cons.PRODUCT_DRAFT.draft, draft)

          newDraft.productList.map((product) => {
            return handleProductDisable(product)
          })

          newDraft.subProductList.map((product) => {
            return handleProductDisable(product)
          })
          newDraft.scNameDisable = getScDisabled(newDraft.tcName, newDraft.scName)
          if (newDraft.category.categoryCode) {
            newDraft.categoryDisable = true
          } else {
            newDraft.categoryDisable = false
          }
          if (newDraft.productList.length > 0) {
            newDraft.productCode = newDraft.productList[0].productCode
            newDraft.product = newDraft.productList[0]
            newDraft.lotInventory = newDraft.productList[0].lotInventoryList.length > 0 ? newDraft.productList[0].lotInventoryList : []
            newDraft.lotInventorySet = true
            newDraft.redeemGroup =
              newDraft.redeemGroupList && newDraft.redeemGroupList.length > 0
                ? newDraft.redeemGroupList[0]
                : newDraft.existingRedeemGroupList && newDraft.existingRedeemGroupList.length > 0
                ? newDraft.existingRedeemGroupList[0]
                : newDraft.redeemGroup
          }
          if (tempId[0] === 'clone') {
            dispatch(
              setProduct({
                draft: newDraft,
                draftName: tempSavedProduct.draftName,
                id: '',
                status: tempSavedProduct.status,
              })
            )
          } else {
            dispatch(
              setProduct({
                draft: newDraft,
                draftName: tempSavedProduct.draftName,
                id: tempSavedProduct.id,
                status: tempSavedProduct.status,
              })
            )
          }
          setReady(true)
          setClear(false)
        },
        undefined,
        store
      )
    } else {
      dispatch(setProductDefault())
      setReady(true)
    }
  }, [clear]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (permissionsCheck(cons.PERMISSION_KEY.PRODUCT_CREATE_FINANCE)) {
      setDisableInput(true)
    }
  }, [permission]) // eslint-disable-line react-hooks/exhaustive-deps

  function clearCategory() {
    dispatch(setLoading(true))
    let tempSavedCategory = _.cloneDeep(savedProduct.draft)
    tempSavedCategory.category = _.cloneDeep(cons.CATEGORY)
    tempSavedCategory.categoryDisable = false
    history.push('/' + cons.PATH.PRODUCT.DRAFT + '/create/0')
    dispatch(setProductValue('', savedProduct, '', tempSavedCategory))
    dispatch(setLoading(false))
  }

  // const totalSteps = () => {
  //   return getSteps().length
  // }

  const handlePanelSize = (newSize, index) => {
    let tempDraft = _.cloneDeep(savedProduct.draft)
    tempDraft.panelSize[index] = newSize
    dispatch(setLoading(true))
    dispatch(setProductValue('', savedProduct, '', tempDraft))
    dispatch(setLoading(false))
  }

  const onClickListRow = async (data) => {
    let cetegory = await getCetegory(data.id, store)
    let tempSavedCategory = _.cloneDeep(savedProduct.draft)
    tempSavedCategory.category = _.cloneDeep(cetegory)
    tempSavedCategory.categoryDisable = true
    dispatch(setProductValue('', savedProduct, '', tempSavedCategory))
  }

  const onClickSaveButton = (type) => {
    if (type === 'finish') {
      ProductCreateValidation.validateDraft(savedProduct.draft)
      if (ProductCreateValidation.isValid()) {
        handleComplete(true)
      } else {
        setErrorVersion(ProductCreateValidation.getVersion())
      }
    } else if (type === 'save') {
      onClickSave(false)
    }
  }

  const saveDraftDialogClose = () => {
    handleSaveDraftDialog(false)
    history.push('/' + cons.PATH.PRODUCT.DRAFT)
  }

  const saveDraftFailDialogClose = () => {
    handleSaveDraftDialog(false)
  }

  const onClickSave = (toBeApproved) => {
    saveDraft(toBeApproved)
  }

  function onClickClear() {
    setClear(true)
  }

  const onClickBackToEdit = () => {
    setDialog({
      open: false,
      dialogActions: [],
    })
    onClickClear()
  }

  const handleRedirectEdit = (productId) => {
    setDialog({
      open: false,
      dialogActions: [],
    })
    history.push('/' + cons.PATH.PRODUCT.DRAFT + '/' + productId + '/' + params.tab)
  }

  const onClickBackToList = () => {
    setDialog({
      open: false,
      dialogActions: [],
    })
    saveDraftDialogClose()
  }

  const saveDraft = (toBeApproved) => {
    let tempSavedProduct = _.cloneDeep(savedProduct)
    delete tempSavedProduct.draft.redeemGroup
    delete tempSavedProduct.draft.product
    delete tempSavedProduct.draft.lotInventory
    delete tempSavedProduct.draft.lotInventorySet
    delete tempSavedProduct.draft.productCode
    // delete tempSavedProduct.draft.existingRedeemGroupList

    const confirmedSaveDraft = {
      draftName: tempSavedProduct.draftName,
      draft: JSON.stringify(tempSavedProduct.draft),
      draftVersion: cons.DRAFT_VER,
      toBeApproved: toBeApproved,
    }

    if (!savedProduct.draftName) {
      setDraftNameError('Missing Draft Name')
      return
    }

    setDraftNameError('')

    if (!savedProduct.id) {
      post(
        cons.PATH.PRODUCT.DRAFT,
        confirmedSaveDraft,
        (payload) => {
          setDialog({
            open: true,
            onClose: onClickBackToEdit,
            dialogContentText: { key: 'editSuccess' },
            dialogActions: [
              toBeApproved ? null : { label: { key: 'backEdit' }, onClick: () => handleRedirectEdit(payload.id), icon: <ArrowBackIcon /> },
              { label: { key: 'backList' }, onClick: onClickBackToList, icon: <LowPriorityIcon /> },
            ].filter((item) => item),
          })
          setClear(false)
        },
        undefined,
        store
      )
    } else {
      put(
        cons.PATH.PRODUCT.DRAFT + '/' + savedProduct.id,
        confirmedSaveDraft,
        (payload) => {
          setDialog({
            open: true,
            onClose: onClickBackToEdit,
            dialogContentText: { key: 'editSuccess' },
            dialogActions: [
              toBeApproved ? null : { label: { key: 'backEdit' }, onClick: onClickBackToEdit, icon: <ArrowBackIcon /> },
              { label: { key: 'backList' }, onClick: onClickBackToList, icon: <LowPriorityIcon /> },
            ].filter((item) => item),
          })
        },
        undefined,
        store
      )
    }
  }

  const lastStep = PRODUCT_STEPS.length - 1

  const onClickNextButton = () => {
    // const newActiveStep = activeStep === lastStep ? steps.findIndex((step, i) => !completed.has(i)) : activeStep + 1
    const activeStep = savedProduct.draft.step
    const newActiveStep = activeStep === lastStep ? steps.findIndex((_, idx) => !completed.has(idx)) : activeStep + 1
    const newSavedProduct = _.cloneDeep(savedProduct)
    dispatch(setProduct({ ...newSavedProduct, draft: { ...newSavedProduct.draft, step: newActiveStep } }))

    // setActiveStep(newActiveStep)
  }

  const onClickBackButton = () => {
    // const backStep = activeStep - 1
    // if (backStep < 0) {
    //   return
    // }
    // setActiveStep((prevActiveStep) => prevActiveStep - 1)

    const activeStep = savedProduct.draft.step
    const previousStep = activeStep - 1

    if (previousStep < 0) {
      return
    }

    const newSavedProduct = _.cloneDeep(savedProduct)
    dispatch(setProduct({ ...newSavedProduct, draft: { ...newSavedProduct.draft, step: previousStep } }))
  }

  const onClickStepLabel = (step) => () => {
    const newSavedProduct = _.cloneDeep(savedProduct)
    dispatch(setProduct({ ...newSavedProduct, draft: { ...newSavedProduct.draft, step: step } }))
    // setActiveStep(step)
  }

  function handleSaveDraftDialog(open, message, close) {
    setDialog({
      open: open,
      onClose: close === 'saveDraftDialogClose' ? () => saveDraftDialogClose() : () => saveDraftFailDialogClose(),
      dialogTitle: { key: 'dialogTitle' },
      dialogContentText: message,
      dialogActions: [
        {
          label: { key: 'backList' },
          onClick: close === 'saveDraftDialogClose' ? () => saveDraftDialogClose() : () => saveDraftFailDialogClose(),
          icon: <LowPriorityIcon />,
        },
      ],
    })
  }

  const handleComplete = (toBeApproved) => {
    ProductCreateValidation.validateDraft(savedProduct.draft)

    let tempSavedProduct = _.cloneDeep(savedProduct)
    delete tempSavedProduct.draft.redeemGroup
    delete tempSavedProduct.draft.product
    delete tempSavedProduct.draft.lotInventory
    delete tempSavedProduct.draft.lotInventorySet
    delete tempSavedProduct.draft.productCode
    // delete tempSavedProduct.draft.existingRedeemGroupList

    const confirmedSaveDraft = {
      id: tempSavedProduct.id,
      draftName: tempSavedProduct.draftName,
      draft: tempSavedProduct.draft,
      draftVersion: cons.DRAFT_VER,
    }

    if (permissionsCheck(cons.PERMISSION_KEY.PRODUCT_CREATE_FINANCE)) {
      if (ProductCreateValidation.isValid()) {
        const newCompleted = new Set(completed)
        const activeStep = savedProduct.draft.step
        newCompleted.add(activeStep)
        setCompleted(newCompleted)
        post(
          cons.PATH.PRODUCT.CONFIRM_CREATE_PRODUCT,
          confirmedSaveDraft,
          (payload) => {
            handleSaveDraftDialog(true, { key: 'editSuccess' }, 'saveDraftDialogClose')
          },
          undefined,
          store
        )
      } else {
        setErrorVersion(ProductCreateValidation.getVersion())
      }
    } else if (permissionsCheck(cons.PERMISSION_KEY.PRODUCT_CREATE_SYSTEM_ADMIN)) {
      if (ProductCreateValidation.isValid()) {
        const newCompleted = new Set(completed)
        const activeStep = savedProduct.draft.step
        newCompleted.add(activeStep)
        setCompleted(newCompleted)
        onClickSave(toBeApproved)
      } else {
        setErrorVersion(ProductCreateValidation.getVersion())
      }
    }
  }

  useEffect(() => {
    setClear(true)
  }, [params.draftId])

  useEffect(() => {
    getProductStepError(dispatch)
  }, [errorVersion]) // eslint-disable-line react-hooks/exhaustive-deps

  function onChangeInput(name, e) {
    switch (name) {
      case 'draftName':
        dispatch(
          setProduct({
            ...savedProduct,
            draftName: e,
          })
        )
        break
      default:
        break
    }
  }

  // useEffect(() => {
  //   console.log('savedProduct', savedProduct)
  // }, [savedProduct])

  return (
    ready && (
      <BaseWrapper>
        <div className="stepper-wrapper">
          <div className="header margin-left-16">
            <TempTextInput
              inputSetting={{
                className: 'full-size-input',
                size: cons.SIZE.SMALL,
                value: savedProduct.draftName,
                label: { resource: 'productCreateDraft', key: 'draftName' },
                name: 'draftName',
                helperTextIcon: true,
                helperText: draftNameError,
                FormHelperTextProps: { className: helperTextStylesClasses.root },
              }}
              index={0}
              handleChange={onChangeInput}
            />
          </div>
          <Stepper className="stepper" alternativeLabel nonLinear activeStep={savedProduct.draft.step}>
            {steps.map((label, index) => {
              const stepProps = {}
              const labelProps = {}
              for (let i = 0; i < errorPage.length; i++) {
                if (index === errorPage[i]) {
                  labelProps.optional = (
                    <Typography key={index} className={classes.error} variant="caption" color="error">
                      (Missing/Error Info)
                    </Typography>
                  )
                  if (index === errorPage[i] && index !== savedProduct.draft.step) {
                    labelProps.error = true
                  }
                }
              }
              return (
                <Step key={label} {...stepProps} className="step-button">
                  <StepLabel onClick={onClickStepLabel(index)} {...labelProps}>
                    {label}
                  </StepLabel>
                </Step>
              )
            })}
          </Stepper>
        </div>
        <div className="step-content-outer-wrapper">
          <Card>
            {getStepContent(savedProduct.draft.step, savedProduct, clearCategory, disableInput, handlePanelSize, onClickListRow, dispatch, lang, theme)}
          </Card>
          <div className="buttons">
            <TempBtn
              btnSetting={{
                className: classes.button,
                variant: cons.STYLE.VARIANT.CONTAINED,
                color: cons.STYLE.PRIMARY,
                onClick: onClickBackButton,
                icon: <ArrowBackIcon />,
                label: { key: 'back' },
                disabled: savedProduct.draft.step === 0,
              }}
            />
            <TempBtn
              btnSetting={{
                className: classes.button,
                variant: cons.STYLE.VARIANT.CONTAINED,
                color: cons.STYLE.PRIMARY,
                onClick: onClickNextButton,
                icon: <ArrowForwardIcon />,
                label: { key: 'next' },
                disabled: savedProduct.draft.step === lastStep,
              }}
            />
            {permissionsCheck(cons.PERMISSION_KEY.PRODUCT_CREATE_SYSTEM_ADMIN) && (
              <TempBtn
                btnSetting={{
                  className: classes.button,
                  variant: cons.STYLE.VARIANT.CONTAINED,
                  color: cons.STYLE.PRIMARY,
                  onClick: () => {
                    onClickSaveButton('save')
                  },
                  icon: <SaveIcon />,
                  label: { key: 'saveAsDraft' },
                }}
              />
            )}
            {savedProduct.draft.step === lastStep && (
              <TempBtn
                btnSetting={{
                  className: classes.button,
                  variant: cons.STYLE.VARIANT.CONTAINED,
                  color: cons.STYLE.PRIMARY,
                  onClick: () => {
                    permissionsCheck(cons.PERMISSION_KEY.PRODUCT_CREATE_SYSTEM_ADMIN) ? onClickSaveButton('finish') : handleComplete()
                  },
                  icon: <DoneIcon />,
                  label: permissionsCheck(cons.PERMISSION_KEY.PRODUCT_CREATE_FINANCE)
                    ? { key: 'approve' }
                    : permissionsCheck(cons.PERMISSION_KEY.PRODUCT_CREATE_SYSTEM_ADMIN)
                    ? { key: 'finishBtn' }
                    : '',
                }}
              />
            )}
          </div>
        </div>
        {dialog && dialog.open && <GlobalDialog dialogInfo={dialog} />}
      </BaseWrapper>
    )
  )
}
