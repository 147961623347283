import { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useDispatch, useSelector, useStore } from 'react-redux'
import moment from 'moment'
import { sify } from 'chinese-conv'
import _ from 'lodash'

import { Drawer } from '@mui/material'
import SaveIcon from '@mui/icons-material/Save'
import ClearIcon from '@mui/icons-material/Clear'
import LowPriorityIcon from '@mui/icons-material/LowPriority'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CancelIcon from '@mui/icons-material/Cancel'
import { makeStyles } from '@mui/styles'

import { constants as cons, IMAGE_TYPE } from '../../../Constants/constants'
import { put } from '../../../helper/baseFetch'
import {
    PRODUCT_CATEGORY_ACTIONS,
    setProductCategory,
    setProductValue,
} from '../../../actions/productCreateAction'
import { EditCreateDisplay } from '../../EditCreateDisplay/EditCreateDisplay'
import { TempTextInput } from '../../Input/TempTextInput'
import { TempAutoSelectInput } from '../../Input/TempAutoSelectInput'
import { TempBtn } from '../../Input/TempBtn'
import { TempCustomCheckBox } from '../../Custom/TempCustomCheckBox'
import { ProductCategoryList } from './ProductCategoryList'
import { getCetegory } from './getCetegory'
import ProductCreateValidation from '../../../validations/productCreateValidation'
import { productValidationConstants } from '../../../Constants/validationConstants'
import { ProductMapList } from './ProductMapList'
import { getString, permissionsCheck } from '../../../helper/util'
import { GlobalDialog } from '../../GlobalDialog'
import { ImageList } from '../../UploadImage/ImageList'
import useProduct from '../../../hooks/Product/useProduct'
import { Launch } from '@mui/icons-material'
import LocationFields, { LOCATION_FIELDS_KEY } from '../../common/location/LocationFields'

const helperTextStyles = makeStyles(() => ({
    root: {
        color: 'red !important',
    },
}))

export const ProductCategoryEdit = ({ listOverflow }) => {
    const dispatch = useDispatch()
    const store = useStore()
    const params = useParams()
    const history = useHistory()
    const helperTextStylesClasses = helperTextStyles()
    const savedProduct = useSelector((state) => state.product.ui.product)
    const lang = useSelector((state) => state.lang.ui.lang)
    const theme = useSelector((state) => state.theme.ui.theme)
    // eslint-disable-next-line no-unused-vars
    const [errorVersion, setErrorVersion] = useState(ProductCreateValidation.getVersion())

    const [ready, setReady] = useState(false)
    const [clear, setClear] = useState(false)

    const { getProductCategoryName } = useProduct()

    const [disableInput, setDisableInput] = useState(false)
    const [hiddenInput, setHiddenInput] = useState(false)
    const [showCategoryTextPanel, setShowCategoryTextPanel] = useState(false)
    const [productCategoryInput, setProductCategoryInput] = useState(
        _.cloneDeep(savedProduct.draft.category)
    )

    const [dialog, setDialog] = useState({
        open: false,
        dialogActions: [],
    })

    const [error, setError] = useState({})

    const resource = 'productCreateProductCategoryList'
    const tabs = [
        {
            label: { resource: resource, key: 'info' },
            tabIndex: cons.PRODUCT_CAT_TAB.INFO,
        },
        savedProduct.draft.category.id && {
            label: { resource: 'productCreateProductCategoryList', key: 'productMapList' },
            tabIndex: cons.PRODUCT_CAT_TAB.PRODUCT_MAP_LIST,
        },
    ].filter((tabTitle) => tabTitle)

    const permission = localStorage.getItem(cons.LOCAL_STORAGE_KEY.ROLE)

    const handleCategoryTextClick = () => {
        setShowCategoryTextPanel(true)
    }
    const handleCategoryTextCloseClick = () => {
        setShowCategoryTextPanel(false)
    }

    const edit = async (categoryCode) => {
        setReady(false)
        const data = await getCetegory(categoryCode, store)
        let tempSavedCategory = data
        dispatch(
            setProductValue(
                cons.ACTION_KEY.PRODUCT.DRAFT,
                savedProduct,
                'category',
                tempSavedCategory
            )
        )
        if (data) {
            setReady(true)
        } else {
            history.push('/' + cons.PATH.PRODUCT.PRODUCT_CATEGORY)
        }
        setClear(false)
    }

    function onSelectCheckbox(value, customName) {
        switch (customName.key) {
            case 'nameCustomize':
                dispatch(setProductCategory(savedProduct?.draft?.category, 'scNameDisable', value))
                break
            case 'descriptionCustomize':
                dispatch(
                    setProductCategory(savedProduct?.draft?.category, 'scDescriptionDisable', value)
                )
                break
            default:
        }
    }

    const onClickBackToEdit = () => {
        setDialog({
            open: false,
            dialogActions: [],
        })
        onClickClear()
    }
    const onClickBackToList = () => {
        setDialog({
            open: false,
            dialogActions: [],
        })
        history.push('/' + cons.PATH.PRODUCT.PRODUCT_CATEGORY)
    }

    function onChangeInput(name, e) {
        if (savedProduct.draft.category.scNameDisable === true && name === 'tcName') {
            dispatch(
                setProductCategory(savedProduct?.draft?.category, 'channelNameTranslate', {
                    tcName: e,
                    scName: sify(e),
                })
            )
        } else if (
            savedProduct.draft.category.scDescriptionDisable === true &&
            name === 'tcDescription'
        ) {
            dispatch(
                setProductCategory(savedProduct?.draft?.category, 'descriptionTranslate', {
                    tcDescription: e,
                    scDescription: sify(e),
                })
            )
        } else if (name === 'startDisplayTimestamp' || name === 'endDisplayTimestamp') {
            dispatch(
                setProductCategory(
                    savedProduct?.draft?.category,
                    name,
                    Number(moment(e).format('x'))
                )
            )
        } else if (name === 'displaySequence') {
            if (e) {
                dispatch(setProductCategory(savedProduct?.draft?.category, name, Number(e)))
            } else {
                dispatch(setProductCategory(savedProduct?.draft?.category, name, e))
            }
        } else if (name === 'channel') {
            dispatch(
                setProductCategory(savedProduct?.draft?.category, 'channelCreate', {
                    channel: e,
                    channelSave: e,
                })
            )
        } else {
            dispatch(setProductCategory(savedProduct?.draft?.category, name, e))
        }
    }

    function handleClearRefCategoryCode() {
        onChangeInput('refCategoryCode', '')
    }

    function setCity(city) {
        dispatch(
            setProductCategory(
                savedProduct?.draft?.category,
                PRODUCT_CATEGORY_ACTIONS.SET_CITY,
                city
            )
        )
    }

    function setRegion(region) {
        dispatch(
            setProductCategory(
                savedProduct?.draft?.category,
                PRODUCT_CATEGORY_ACTIONS.SET_REGION,
                region
            )
        )
    }

    const fields = [
        {
            type: cons.TYPE.TITLE,
            value: { resource: resource, key: 'name' },
            className: 'sub-title',
        },
        {
            className: 'edit-create-input',
            variant: cons.STYLE.VARIANT.STANDARD,
            label: { resource: resource, key: 'categoryCode' },
            value: savedProduct.draft.category.categoryCode,
            name: 'categoryCode',
            isEndOfLine: true,
            disabled: true,
            required: true,
        },
        {
            type: cons.TYPE.FUNCTION,
            value: (
                <LocationFields
                    setCity={setCity}
                    setRegion={setRegion}
                    savedData={savedProduct?.draft?.category}
                    error={error}
                    fields={[LOCATION_FIELDS_KEY.region, LOCATION_FIELDS_KEY.city]}
                />
            ),
            isEndOfLine: true,
        },
        {
            className: 'edit-create-input',
            variant: disableInput ? cons.STYLE.VARIANT.STANDARD : null,
            size: cons.SIZE.SMALL,
            label: { resource: resource, key: 'type' },
            value: savedProduct.draft.category.type,
            type: disableInput ? null : cons.TYPE.SELECT,
            selectValue: cons.CHOICES.PRODUCT_CREATE.EDIT_PRODUCT.CATEGORY_TYPE,
            name: 'type',
            isEndOfLine: true,
            disabled: disableInput,
            required: true,
            helperTextIcon: true,
            helperText: ProductCreateValidation.getErrorMessages(
                productValidationConstants.KEY_CATEGORY_TYPE,
                'category'
            ).map((error) => {
                return error
            }),
            FormHelperTextProps: { className: helperTextStylesClasses.root },
        },
        {
            className: 'edit-create-input',
            variant: disableInput ? cons.STYLE.VARIANT.STANDARD : null,
            size: cons.SIZE.SMALL,
            label: { resource: resource, key: 'codeDescription' },
            value: savedProduct.draft.category.codeDescription,
            name: 'codeDescription',
            isEndOfLine: false,
            disabled: disableInput,
            helperTextIcon: true,
            helperText: ProductCreateValidation.getErrorMessages(
                productValidationConstants.KEY_CATEGORY_DESCRIPTION,
                'category'
            ).map((error) => {
                return error
            }),
            FormHelperTextProps: { className: helperTextStylesClasses.root },
        },
        {
            className: 'edit-create-input',
            variant: disableInput ? cons.STYLE.VARIANT.STANDARD : null,
            size: cons.SIZE.SMALL,
            label: { resource: resource, key: 'channel' },
            value: savedProduct.draft.category.channel,
            type: disableInput ? null : cons.TYPE.SELECT,
            selectValue: cons.CHOICES.PRODUCT_CREATE.EDIT_PRODUCT.CHANNEL,
            name: 'channel',
            isEndOfLine: false,
            disabled: disableInput,
            helperTextIcon: true,
            helperText: ProductCreateValidation.getErrorMessages(
                productValidationConstants.KEY_CATEGORY_CHANNEL,
                'category'
            ).map((error) => {
                return error
            }),
            FormHelperTextProps: { className: helperTextStylesClasses.root },
        },
        {
            className: 'edit-create-input',
            variant: disableInput ? cons.STYLE.VARIANT.STANDARD : null,
            size: cons.SIZE.SMALL,
            label: { resource: 'productCreateProductCategoryList', key: 'refCategoryCode' },
            onClickEndAdornmentIconButton: disableInput ? null : handleCategoryTextClick,
            value: savedProduct.draft.category.refCategoryCode
                ? savedProduct.draft.category.refCategoryCode +
                  '    ' +
                  savedProduct.draft.category.refCategoryTcName
                : '',
            name: 'refCategoryCode',
            clear: disableInput ? false : handleClearRefCategoryCode,
            disabled: true,
            isEndOfLine: true,
            multiline: true,
            endAdornmentIconButtonList: savedProduct.draft.category.refCategoryId
                ? [
                      <Launch
                          fontSize={cons.SIZE.SMALL}
                          onClick={() =>
                              window.open(
                                  `${window.location.origin}/rack_product/category/${savedProduct.draft.category.refCategoryId}/0`,
                                  '_blank'
                              )
                          }
                      />,
                  ]
                : [],
        },
        {
            className: 'edit-create-input',
            variant: disableInput ? cons.STYLE.VARIANT.STANDARD : null,
            size: cons.SIZE.SMALL,
            label: { resource: resource, key: 'enName' },
            value: savedProduct.draft.category.enName,
            name: 'enName',
            custom: true,
            customName: { key: 'nameCustomize' },
            customValue: savedProduct.draft.category.scNameDisable,
            isEndOfLine: false,
            disabled: disableInput,
            helperTextIcon: true,
            helperText: ProductCreateValidation.getErrorMessages(
                productValidationConstants.KEY_CATEGORY_NAME_EN,
                'category'
            ).map((error) => {
                return error
            }),
            FormHelperTextProps: { className: helperTextStylesClasses.root },
        },
        {
            className: 'edit-create-input',
            variant: disableInput ? cons.STYLE.VARIANT.STANDARD : null,
            size: cons.SIZE.SMALL,
            label: { resource: resource, key: 'tcName' },
            value: savedProduct.draft.category.tcName,
            name: 'tcName',
            translateName: savedProduct.draft.category.scNameDisable ? ['scName'] : null,
            inputList: productCategoryInput,
            setInputList: setProductCategoryInput,
            isEndOfLine: false,
            disabled: disableInput,
            helperTextIcon: true,
            helperText: ProductCreateValidation.getErrorMessages(
                productValidationConstants.KEY_CATEGORY_NAME_TC,
                'category'
            ).map((error) => {
                return error
            }),
            FormHelperTextProps: { className: helperTextStylesClasses.root },
        },
        {
            className: 'edit-create-input',
            variant: disableInput ? cons.STYLE.VARIANT.STANDARD : null,
            size: cons.SIZE.SMALL,
            label: { resource: resource, key: 'scName' },
            value: savedProduct.draft.category.scName,
            name: 'scName',
            inputList: productCategoryInput,
            setInputList: setProductCategoryInput,
            isEndOfLine: true,
            disabled: disableInput ? disableInput : savedProduct.draft.category.scNameDisable,
            helperTextIcon: true,
            helperText: ProductCreateValidation.getErrorMessages(
                productValidationConstants.KEY_CATEGORY_NAME_SC,
                'category'
            ).map((error) => {
                return error
            }),
            FormHelperTextProps: { className: helperTextStylesClasses.root },
        },
        {
            className: 'edit-create-input',
            variant: disableInput ? cons.STYLE.VARIANT.STANDARD : null,
            size: cons.SIZE.SMALL,
            label: { resource: resource, key: 'enDescription' },
            value: savedProduct.draft.category.enDescription,
            name: 'enDescription',
            custom: true,
            customName: { key: 'descriptionCustomize' },
            customValue: savedProduct.draft.category.scDescriptionDisable,
            isEndOfLine: false,
            disabled: disableInput,
        },
        {
            className: 'edit-create-input',
            variant: disableInput ? cons.STYLE.VARIANT.STANDARD : null,
            size: cons.SIZE.SMALL,
            label: { resource: resource, key: 'tcDescription' },
            value: savedProduct.draft.category.tcDescription,
            name: 'tcDescription',
            translateName: savedProduct.draft.category.scDescriptionDisable
                ? ['scDescription']
                : null,
            inputList: productCategoryInput,
            setInputList: setProductCategoryInput,
            isEndOfLine: false,
            disabled: disableInput,
        },
        {
            className: 'edit-create-input',
            variant: disableInput ? cons.STYLE.VARIANT.STANDARD : null,
            size: cons.SIZE.SMALL,
            label: { resource: resource, key: 'scDescription' },
            value: savedProduct.draft.category.scDescription,
            name: 'scDescription',
            inputList: productCategoryInput,
            setInputList: setProductCategoryInput,
            isEndOfLine: true,
            disabled: disableInput
                ? disableInput
                : savedProduct.draft.category.scDescriptionDisable,
        },
        {
            className: 'edit-create-input',
            variant: disableInput ? cons.STYLE.VARIANT.STANDARD : null,
            size: cons.SIZE.SMALL,
            label: { resource: resource, key: 'startDisplayTimestamp' },
            value:
                savedProduct.draft.category.startDisplayTimestamp === 0
                    ? ''
                    : savedProduct.draft.category.startDisplayTimestamp,
            name: 'startDisplayTimestamp',
            type: cons.TYPE.DATETIME_LOCAL,
            step: 1,
            isEndOfLine: false,
            disabled: disableInput,
            helperTextIcon: true,
            helperText: ProductCreateValidation.getErrorMessages(
                productValidationConstants.KEY_CATEGORY_START_DISPLAY_TIMESTAMP,
                'category'
            ).map((error) => {
                return error
            }),
            FormHelperTextProps: { className: helperTextStylesClasses.root },
        },
        {
            className: 'edit-create-input',
            variant: disableInput ? cons.STYLE.VARIANT.STANDARD : null,
            size: cons.SIZE.SMALL,
            label: { resource: resource, key: 'endDisplayTimestamp' },
            value:
                savedProduct.draft.category.endDisplayTimestamp === 0
                    ? ''
                    : savedProduct.draft.category.endDisplayTimestamp,
            name: 'endDisplayTimestamp',
            type: cons.TYPE.DATETIME_LOCAL,
            step: 1,
            isEndOfLine: false,
            disabled: disableInput,
            helperTextIcon: true,
            helperText: ProductCreateValidation.getErrorMessages(
                productValidationConstants.KEY_CATEGORY_END_DISPLAY_TIMESTAMP,
                'category'
            ).map((error) => {
                return error
            }),
            FormHelperTextProps: { className: helperTextStylesClasses.root },
        },
        {
            className: 'edit-create-input',
            variant: disableInput ? cons.STYLE.VARIANT.STANDARD : null,
            size: cons.SIZE.SMALL,
            label: { resource: resource, key: 'displaySequence' },
            value: savedProduct.draft.category.displaySequence,
            type: cons.TYPE.NUMBER,
            min: 0,
            name: 'displaySequence',
            isEndOfLine: true,
            disabled: disableInput,
            helperTextIcon: true,
            helperText: ProductCreateValidation.getErrorMessages(
                productValidationConstants.KEY_CATEGORY_DISPLAY_SEQUENCE,
                'category'
            ).map((error) => {
                return error
            }),
            FormHelperTextProps: { className: helperTextStylesClasses.root },
        },
        {
            className: `edit-create-input ${!savedProduct.draft.category.id ? 'display-none' : ''}`,
            variant: disableInput ? cons.STYLE.VARIANT.STANDARD : null,
            size: cons.SIZE.SMALL,
            label: { resource: resource, key: 'status' },
            value: savedProduct.draft.category.status,
            type: disableInput ? '' : cons.TYPE.SELECT,
            selectValue: cons.CHOICES.PRODUCT_CREATE.CREATE_CATEGORY.STATUS,
            name: 'status',
            isEndOfLine: true,
            disabled: disableInput,
        },
        {
            className: 'edit-create-input',
            size: cons.SIZE.SMALL,
            type: cons.TYPE.FUNCTION,
            value: (
                <>
                    <h3 variant={cons.STYLE.VARIANT.H6}>
                        {getString(lang, 'productCreateProductCategoryList', 'imageList', theme)}
                    </h3>
                    {ProductCreateValidation.getErrorMessages(
                        productValidationConstants.KEY_CATEGORY_IMAGE_LIST,
                        'category'
                    ).map((error) => {
                        return error
                    }) &&
                        ProductCreateValidation.getErrorMessages(
                            productValidationConstants.KEY_CATEGORY_IMAGE_LIST,
                            'category'
                        ).map((error) => {
                            return error
                        }).length > 0 && (
                            <div className='error-message'>
                                <CancelIcon
                                    className='error-message-icon'
                                    fontSize={cons.SIZE.SMALL}
                                />
                                {ProductCreateValidation.getErrorMessages(
                                    productValidationConstants.KEY_CATEGORY_IMAGE_LIST,
                                    'category'
                                ).map((error) => {
                                    return error
                                })}
                            </div>
                        )}
                    {!hiddenInput && (
                        <ImageList
                            uploadPath={cons.PATH.PRODUCT.UPLOAD_PRODUCT_IMAGE}
                            resource={'productCreateProductCategoryList'}
                            type={IMAGE_TYPE.CATEGORY}
                            typeList={cons.CHOICES.PRODUCT_CREATE.CREATE_CATEGORY.CAT_IMAGE_TYPE}
                            savedData={savedProduct}
                            hiddenInput={hiddenInput}
                            disableInput={disableInput}
                            helperTextStylesClasses={helperTextStylesClasses}
                            validation={ProductCreateValidation}
                            validationConstants={productValidationConstants}
                            hideTitle={true}
                        />
                    )}
                </>
            ),
            isEndOfLine: true,
        },
    ]

    const content = [
        {
            tab: (
                <div className={listOverflow}>
                    {fields.map((inputSetting, index) => {
                        let content
                        switch (inputSetting.type) {
                            case cons.TYPE.SELECT:
                                content = (
                                    <TempAutoSelectInput
                                        inputSetting={inputSetting}
                                        handleChange={onChangeInput}
                                        index={index}
                                    />
                                )
                                break
                            case cons.TYPE.FUNCTION:
                                content = inputSetting.value
                                break
                            case cons.TYPE.BUTTON:
                                content = <TempBtn btnSetting={inputSetting} />
                                break
                            case cons.TYPE.TITLE:
                                content = (
                                    <div className={`${inputSetting.className + ' sub-title'}`}>
                                        {getString(
                                            lang,
                                            inputSetting.value.resource,
                                            inputSetting.value.key,
                                            theme
                                        )}
                                    </div>
                                )
                                break
                            default:
                                content = (
                                    <TempTextInput
                                        inputSetting={inputSetting}
                                        handleChange={onChangeInput}
                                    />
                                )
                        }
                        return (
                            <div className='edit-display-wrapper' key={index}>
                                {!disableInput && inputSetting.custom && (
                                    <TempCustomCheckBox
                                        className='customize-btn'
                                        value={inputSetting.customValue}
                                        customName={inputSetting.customName}
                                        onSelectCheckbox={onSelectCheckbox}
                                    />
                                )}
                                {content}
                                {inputSetting.isEndOfLine && <div></div>}
                            </div>
                        )
                    })}
                </div>
            ),
            btn: savedProduct.draft.category.id ? true : false,
        },
        {
            tab: (
                <ProductMapList
                    hiddenInput={hiddenInput}
                    disableInput={disableInput}
                    helperTextStylesClasses={helperTextStylesClasses}
                />
            ),
            btn: false,
        },
    ]
    const bottomBtns = [
        {
            variant: cons.STYLE.VARIANT.CONTAINED,
            color: cons.STYLE.PRIMARY,
            label: { key: 'save' },
            onClick: onClickSave,
            className: `margin-left-16`,
            icon: <SaveIcon />,
            disabled: disableInput ? disableInput : false,
        },
        {
            variant: cons.STYLE.VARIANT.CONTAINED,
            color: cons.STYLE.PRIMARY,
            label: { key: 'clear' },
            disabled: disableInput ? disableInput : false,
            onClick: onClickClear,
            className: 'margin-left-16',
            icon: <ClearIcon />,
        },
    ]

    function onClickListRow(row) {
        dispatch(
            setProductCategory(savedProduct?.draft?.category, 'refCategory', {
                refCategoryCode: row.categoryCode,
                refCategoryTcName: row.tcName,
                refCategoryId: row.id,
            })
        )
        handleCategoryTextCloseClick()
    }

    function onClickSave() {
        ProductCreateValidation.validateCategoryOnly(savedProduct.draft, true)
        if (savedProduct.draft.category.id) {
            if (ProductCreateValidation.isValid()) {
                put(
                    cons.PATH.PRODUCT.PRODUCT_CATEGORY + '/' + savedProduct.draft.category.id,
                    savedProduct.draft.category,
                    () => {
                        setDialog({
                            open: true,
                            onClose: onClickBackToEdit,
                            dialogContentText: { key: 'editSuccess' },
                            dialogActions: [
                                {
                                    label: { key: 'backEdit' },
                                    onClick: onClickBackToEdit,
                                    icon: <ArrowBackIcon />,
                                },
                                {
                                    label: { key: 'backList' },
                                    onClick: onClickBackToList,
                                    icon: <LowPriorityIcon />,
                                },
                            ],
                        })
                        setClear(false)
                    },
                    undefined,
                    store
                )
            } else {
                setErrorVersion(ProductCreateValidation.getVersion())
            }
        } else {
            setErrorVersion(ProductCreateValidation.getVersion())
        }
    }

    function onClickClear() {
        setClear(true)
    }

    useEffect(() => {
        if (params.id || savedProduct.draft.category.id) {
            ProductCreateValidation.resetErrorMessages()
            edit(params.id ? params.id : savedProduct.draft.category.id)
        } else {
            if (clear === true) {
                dispatch(
                    setProductValue(
                        cons.ACTION_KEY.PRODUCT.DRAFT,
                        savedProduct,
                        'category',
                        cons.CATEGORY
                    )
                )
            }
            setReady(true)
            setClear(false)
        }
    }, [clear]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (permissionsCheck(cons.PERMISSION_KEY.CAT_CREATE_FINANCE)) {
            setDisableInput(true)
            setHiddenInput(true)
        } else {
            setDisableInput(savedProduct.draft.categoryDisable)
            setHiddenInput(false)
        }
    }, [permission, savedProduct.draft.categoryDisable]) // eslint-disable-line react-hooks/exhaustive-deps

    const productCategoryName = getProductCategoryName()

    function validateProductCategory() {
        let newError = {}

        if (!savedProduct?.draft?.category?.regionCode) {
            newError.regionCode = 'Missing Region Code'
        }

        if (!savedProduct?.draft?.category?.cityCode) {
            newError.cityCode = 'Missing City Code'
        }

        setError(newError)
    }

    useEffect(() => {
        validateProductCategory()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [savedProduct])

    // useEffect(() => {
    //     console.log('savedProduct', savedProduct)
    // }, [savedProduct])

    return (
        ready && (
            <div className='step-content-content-wrapper'>
                <EditCreateDisplay
                    code={
                        savedProduct.draft.category.categoryCode
                            ? `${savedProduct.draft.category.categoryCode} - ${productCategoryName}`
                            : ''
                    }
                    resource={resource}
                    tabs={tabs}
                    content={content}
                    onChangeInput={onChangeInput}
                    bottomBtns={bottomBtns}
                    display={!savedProduct.draft.id ? 'create' : 'edit'}
                />
                <Drawer
                    classes={{ paper: 'drawer-margin-left' }}
                    anchor='right'
                    open={showCategoryTextPanel}
                    onClose={handleCategoryTextCloseClick}
                >
                    <ProductCategoryList
                        editBtn={false}
                        createBtn={false}
                        onClickListRow={(item) => onClickListRow(item)}
                        newFilter={{ excludedId: '4bf617a1-28f7-4018-a257-f93253698d38' }}
                        dialogFooterBtn={
                            <>
                                <TempBtn
                                    btnSetting={{
                                        variant: cons.STYLE.VARIANT.CONTAINED,
                                        color: cons.STYLE.PRIMARY,
                                        onClick: handleCategoryTextCloseClick,
                                        icon: <ClearIcon />,
                                        label: { key: 'close' },
                                    }}
                                />
                            </>
                        }
                    />
                </Drawer>
                {dialog && dialog.open && <GlobalDialog dialogInfo={dialog} />}
            </div>
        )
    )
}
