import React from 'react'
import SwapVertIcon from '@mui/icons-material/SwapVert'
import DeleteIcon from '@mui/icons-material/Delete'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import CancelIcon from '@mui/icons-material/Cancel'
import { useDispatch, useSelector } from 'react-redux'
import { setLoading } from '../../../actions/systemAction'
import { constants as cons } from '../../../Constants/constants'
import QueueTemplateValidation from '../../../validations/queueTemplateValidation'
import { queueTemplateValidationConstants } from '../../../Constants/validationConstants'
import { ArrowForward, People } from '@mui/icons-material'
import variables from '../../../styles/globalVariables.scss'
const { subItemColor } = variables

export const TableType = ({
  item,
  tableType,
  handleCloneTableType,
  handleDeleteTableTypeList,
  tableTypeBackgroundColor,
  tableTypeForegroundColor,
}) => {
  const dispatch = useDispatch()
  const savedTableType = useSelector((state) => state.queueTemplate.ui.tableType)
  return (
    <>
      <div className="sub-content">
        <SwapVertIcon className="queue-template-button-on-top" fontSize={cons.SIZE.SMALL} />
        <div className="sub-code">
          <div style={{ display: 'flex', gap: 2, justifyContent: 'center', alignItems: 'center' }}>
            <div
              className="sub-type-selected table-type-label"
              style={{ backgroundColor: tableTypeForegroundColor || 'white', color: tableTypeBackgroundColor || 'black' }}
            >
              {tableType.tableType}
            </div>
            {tableType.parentTableType && (
              <>
                <ArrowForward sx={{ color: '#808080', width: '15px' }} />
                <div
                  className="sub-type-selected table-type-label"
                  style={{ backgroundColor: tableTypeForegroundColor || 'white', color: tableTypeBackgroundColor || 'black' }}
                >
                  {tableType.parentTableType}
                </div>
              </>
            )}
            {tableType.sizeSelectable && (
              <div className="table-type-label" style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                <People fontSize={cons.SIZE.SMALL} sx={{ color: `${subItemColor}` }} />
                {tableType.minSize}-{tableType.maxSize}
              </div>
            )}
          </div>
        </div>
        <div className="sub-div">
          {tableType && tableType.tableTypeEnLabel && (
            <div className="sub-item-name list-content en">
              <div className="list-label-tab">EN</div> {tableType.tableTypeEnLabel}
            </div>
          )}
          {tableType && tableType.tableTypeTcLabel && (
            <div className="sub-item-name list-content en">
              <div className="list-label-tab">TC</div> {tableType.tableTypeTcLabel}
            </div>
          )}
        </div>
        <div
          className="queue-template-button-on-top"
          onClick={(e) => {
            dispatch(setLoading(true))
            e.stopPropagation()
            handleCloneTableType(item, tableType)
            dispatch(setLoading(false))
          }}
        >
          <FileCopyIcon fontSize="small" />
        </div>
        {!tableType.id && (
          <div
            className="queue-template-button-on-top"
            onClick={(e) => {
              dispatch(setLoading(true))
              e.stopPropagation()
              handleDeleteTableTypeList(item, tableType)
              dispatch(setLoading(false))
            }}
          >
            <DeleteIcon fontSize="small" />
          </div>
        )}
      </div>
      {QueueTemplateValidation.getErrorMessages(queueTemplateValidationConstants.KEY_TABLE_TYPE_ERROR, tableType.customId).map((error) => {
        return error
      }) &&
        QueueTemplateValidation.getErrorMessages(queueTemplateValidationConstants.KEY_TABLE_TYPE_ERROR, tableType.customId).map((error) => {
          return error
        }).length > 0 && (
          <div className={tableType.customId === savedTableType.customId ? 'error-message-dark-red' : 'error-message'}>
            <CancelIcon className="error-message-icon" fontSize={cons.SIZE.SMALL} />
            {QueueTemplateValidation.getErrorMessages(queueTemplateValidationConstants.KEY_TABLE_TYPE_ERROR, tableType.customId).map((error) => {
              return error
            })}
          </div>
        )}
    </>
  )
}
