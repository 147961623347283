import { queueTemplateValidationConstants as cons } from '../Constants/validationConstants'
import moment from 'moment'
import { isHexCodeValidate } from '../Page/QueueTemplate/queueTemplateUtil'
import { isMaxSmallerThanMin, isWithInRangeAndValidNumber } from '../helper/util'
import { STATUS } from '../Constants/constants'

class QueueTemplateValidation {
  version
  errorMap
  queueTemplateErrorMap
  timeSectionErrorMap
  tableTypeErrorMap
  valid

  constructor() {
    if (!QueueTemplateValidation.instance) {
      this.version = 0
      this.valid = true
      this.errorMap = new Map()
      this.queueTemplateErrorMap = new Map()
      this.timeSectionErrorMap = new Map()
      this.tableTypeErrorMap = new Map()
      QueueTemplateValidation.instance = this
    }
    return QueueTemplateValidation.instance
  }

  validateTemplate(template, description) {
    this.resetErrorMessages()
    this.validateQueueTemplate(description)
    this.validateTimeSection(template)
    this.validateTableType(template)
    this.version++
  }

  validateTemplateTimeSection(template) {
    this.timeSectionErrorMap = new Map()
    this.valid = true
    this.validateTimeSection(template)
    this.version++
  }

  validateTemplateTableType(template) {
    this.tableTypeErrorMap = new Map()
    this.valid = true
    this.validateTableType(template)
    this.version++
  }

  resetErrorMessages() {
    this.errorMap = new Map()
    this.queueTemplateErrorMap = new Map()
    this.timeSectionErrorMap = new Map()
    this.tableTypeErrorMap = new Map()
    this.valid = true
  }

  getVersion() {
    return this.version
  }

  isValid() {
    return this.valid
  }

  getErrorMessages(key, value) {
    if (!value) {
      return this.queueTemplateErrorMap.has(key) ? this.queueTemplateErrorMap.get(key) : []
    } else {
      switch (key) {
        case cons.KEY_QUEUE_TEMPLATE_ERROR:
        case cons.KEY_TIME_SECTION_STATUS:
        case cons.KEY_TIME_SECTION_ID:
        case cons.KEY_TIME_SECTION_EN_LABEL:
        case cons.KEY_TIME_SECTION_TC_LABEL:
        case cons.KEY_TIME_SECTION_SC_LABEL:
        case cons.KEY_TIME_SECTION_START_TIME:
        case cons.KEY_TIME_SECTION_END_TIME:
          if (this.timeSectionErrorMap.has(value) && this.timeSectionErrorMap.get(value).has(key)) {
            return this.timeSectionErrorMap.get(value).get(key)
          }
          return []
        case cons.KEY_TABLE_TYPE_LIST:
          if (this.queueTemplateErrorMap.has(value) && this.queueTemplateErrorMap.get(value).has(key)) {
            return this.queueTemplateErrorMap.get(value).get(key)
          }
          return []
        case cons.KEY_TABLE_TYPE_ERROR:
        case cons.KEY_TABLE_TYPE:
        case cons.KEY_MIN_SIZE:
        case cons.KEY_MAX_SIZE:
        case cons.KEY_SIZE_SELECTABLE:
        case cons.KEY_CUT_OFF_MIN:
        case cons.KEY_DAILY_QUOTA:
        case cons.KEY_PERSONAL_QUOTA:
        case cons.KEY_NEXT_REMIND:
        case cons.KEY_MOBILE_DISPLAY:
        case cons.KEY_MOBILE_READ_ONLY:
        case cons.KEY_KIOS_DISPLAY:
        case cons.KEY_KIOS_READ_ONLY:
        case cons.KEY_TABLE_TYPE_STATUS:
        case cons.KEY_TABLE_TYPE_START_TIME:
        case cons.KEY_TABLE_TYPE_END_TIME:
        case cons.KEY_TABLE_TYPE_BACKGROUND_COLOR:
        case cons.KEY_TABLE_TYPE_FOREGROUND_COLOR:
        case cons.KEY_TABLE_TYPE_MOBILE_BACKGROUND_COLOR:
        case cons.KEY_TABLE_TYPE_MOBILE_FOREGROUND_COLOR:
        case cons.KEY_PARENT_TABLE_TYPE:
        case cons.KEY_KIOSK_REQUEST_TICKET_START_TIME:
        case cons.KEY_MOBILE_REQUEST_TICKET_START_TIME:
          if (this.tableTypeErrorMap.has(value) && this.tableTypeErrorMap.get(value).has(key)) {
            return this.tableTypeErrorMap.get(value).get(key)
          }
          return []
        default:
          return []
      }
    }
  }

  validateQueueTemplate(description) {
    if (!description) {
      this.setQueueTemplateErrorMapMessages('description', 'Missing Description')
    } else {
      this.setQueueTemplateDeleteErrorMapMessages('description')
    }
  }

  validateTimeSection(queueTemplate) {
    let timeSectionList = []
    let timeSectionStatusList = []
    queueTemplate.map((timeSection) => {
      const queueTemplateMap = new Map()
      if (timeSection.id && !timeSection.status) {
        queueTemplateMap.set(cons.KEY_TIME_SECTION_STATUS, ['Missing Status'])
      }
      if (!timeSection.timeSectionId) {
        queueTemplateMap.set(cons.KEY_TIME_SECTION_ID, ['Missing Time Section Id'])
      } else if (timeSectionList.includes(timeSection.timeSectionId)) {
        queueTemplateMap.set(cons.KEY_TIME_SECTION_ID, ['Duplicate Time Section Id'])
      } else {
        if (!/^[A-Z0-9]{1,3}$/.test(timeSection.timeSectionId)) {
          queueTemplateMap.set(cons.KEY_TIME_SECTION_ID, ['Time Section must be 1-3 characters containing only A-Z or 0-9'])
        } else {
          timeSectionList.push(timeSection.timeSectionId)
        }
      }
      if (timeSection.isSyncTimeFieldsWithAllTableTypes) {
        if (!timeSection.startTime) {
          queueTemplateMap.set(cons.KEY_TIME_SECTION_START_TIME, ['Missing Time Section Start Time'])
        }
        if (!timeSection.endTime) {
          queueTemplateMap.set(cons.KEY_TIME_SECTION_END_TIME, ['Missing Time Section End Time'])
        }
        if (
          timeSection.startTime &&
          timeSection.endTime &&
          moment.duration(timeSection.startTime).asSeconds() > moment.duration(timeSection.endTime).asSeconds()
        ) {
          queueTemplateMap.set(cons.KEY_TIME_SECTION_START_TIME, ['Time Section Start Time should not be later than End Time'])
          queueTemplateMap.set(cons.KEY_TIME_SECTION_END_TIME, ['Time Section Start Time should not be later than End Time'])
        }
      }

      if (timeSection.status === 'D') {
        timeSectionStatusList.push(timeSection.status)
      }

      if (queueTemplateMap.size > 0) {
        queueTemplateMap.set(cons.KEY_QUEUE_TEMPLATE_ERROR, ['Has error(s)'])
        this.setTimeSectionErrorMapMessages(timeSection.customId, queueTemplateMap)
      }

      if (queueTemplate.length === 0) {
        this.setQueueTemplateErrorMapMessages('timeSection', 'Missing Time Section')
      } else if (timeSectionStatusList.length === queueTemplate.length) {
        this.setQueueTemplateErrorMapMessages('timeSection', 'Error: All Time Sections are D (Deactive)')
      } else {
        this.setQueueTemplateDeleteErrorMapMessages('timeSection')
      }
      return timeSection
    })
  }

  validateTableType(queueTemplate) {
    queueTemplate.map((item) => {
      const timeSectionMap = new Map()
      if (!item.tableTypeList || item.tableTypeList.length <= 0) {
        timeSectionMap.set(cons.KEY_TABLE_TYPE_LIST, ['Error: Please add at least one Table Type!'])
      } else {
        let tableTypeList = []
        let tableTypeStatusList = []
        item.tableTypeList.map((tableType) => {
          const tableTypeMap = new Map()

          const {
            isValid: isTableTypeValid,
            errorKey: TableTypeErrorKey,
            errorValue: TableTypeErrorValue,
          } = validateTableType(tableType, tableTypeList)

          if (!isTableTypeValid) {
            tableTypeMap.set(TableTypeErrorKey, TableTypeErrorValue)
          } else {
            tableTypeList.push(tableType.tableType)
          }

          // parent table type

          const {
            isValid: isParentTableTypeValid,
            errorKey: parentTableTypeErrorKey,
            errorValue: parentTableTypeErrorValue,
          } = validateParentTableType(tableType, item.tableTypeList)

          if (!isParentTableTypeValid) {
            tableTypeMap.set(parentTableTypeErrorKey, parentTableTypeErrorValue)
          }

          //table type background color, foreground color

          const {
            isValid: isForegroundColorValid,
            errorKey: foregroundColorErrorKey,
            errorValue: foregroundColorErrorValue,
          } = validateTableTypeForeground(tableType)

          if (!isForegroundColorValid) {
            tableTypeMap.set(foregroundColorErrorKey, foregroundColorErrorValue)
          }

          const {
            isValid: isBackgroundColorValid,
            errorKey: backgroundColorErrorKey,
            errorValue: backgroundColorErrorValue,
          } = validateTableTypeBackground(tableType)

          if (!isBackgroundColorValid) {
            tableTypeMap.set(backgroundColorErrorKey, backgroundColorErrorValue)
          }

          const {
            isValid: isMobileForegroundColorValid,
            errorKey: mobileForegroundColorErrorKey,
            errorValue: mobileForegroundColorErrorValue,
          } = validateTableTypeMobileForeground(tableType)

          if (!isMobileForegroundColorValid) {
            tableTypeMap.set(mobileForegroundColorErrorKey, mobileForegroundColorErrorValue)
          }

          const {
            isValid: isMobileBackgroundColorValid,
            errorKey: mobileBackgroundColorErrorKey,
            errorValue: mobileBackgroundColorErrorValue,
          } = validateTableTypeMobileBackground(tableType)

          if (!isMobileBackgroundColorValid) {
            tableTypeMap.set(mobileBackgroundColorErrorKey, mobileBackgroundColorErrorValue)
          }

          //table type minSize maxSize
          const { isValid: isMinSizeValid, errorKey: minSizeErrorKey, errorValue: minSizeErrorValue } = validateMinSize(tableType)

          if (!isMinSizeValid) {
            tableTypeMap.set(minSizeErrorKey, minSizeErrorValue)
          }

          const { isValid: isMaxSizeValid, errorKey: maxSizeErrorKey, errorValue: maxSizeErrorValue } = validateMaxSize(tableType)

          if (!isMaxSizeValid) {
            tableTypeMap.set(maxSizeErrorKey, maxSizeErrorValue)
          }

          //table type size selectable

          const {
            isValid: isSizeSelectableValid,
            errorKey: sizeSelectableErrorKey,
            errorValue: sizeSelectableErrorValue,
          } = validateSizeSelectable(tableType)

          if (!isSizeSelectableValid) {
            tableTypeMap.set(sizeSelectableErrorKey, sizeSelectableErrorValue)
          }

          //table type cutoff minute

          const { isValid: isCutOffMinuteValid, errorKey: cutOffMinuteErrorKey, errorValue: cutOffMinuteErrorValue } = validateCutOffMinute(tableType)

          if (!isCutOffMinuteValid) {
            tableTypeMap.set(cutOffMinuteErrorKey, cutOffMinuteErrorValue)
          }

          //table type group section

          const { isValid: isGroupSectionValid, errorKey: groupSectionErrorKey, errorValue: groupSectionErrorValue } = validateGroupSection(tableType)

          if (!isGroupSectionValid) {
            tableTypeMap.set(groupSectionErrorKey, groupSectionErrorValue)
          }

          //table type daily quota

          const { isValid: isDailyQuotaValid, errorKey: dailyQuotaErrorKey, errorValue: dailyQuotaErrorValue } = validateDailyQuota(tableType)

          if (!isDailyQuotaValid) {
            tableTypeMap.set(dailyQuotaErrorKey, dailyQuotaErrorValue)
          }

          //table type personal quota

          const {
            isValid: isPersonalQuotaValid,
            errorKey: personalQuotaErrorKey,
            errorValue: personalQuotaErrorValue,
          } = validatePersonalQuota(tableType)

          if (!isPersonalQuotaValid) {
            tableTypeMap.set(personalQuotaErrorKey, personalQuotaErrorValue)
          }

          //table type next reminder

          const { isValid: isNextRemindValid, errorKey: nextRemindErrorKey, errorValue: nextRemindErrorValue } = validateNextRemind(tableType)

          if (!isNextRemindValid) {
            tableTypeMap.set(nextRemindErrorKey, nextRemindErrorValue)
          }

          const {
            isValid: isMobileDisplayValid,
            errorKey: mobileDisplayErrorKey,
            errorValue: mobileDisplayErrorValue,
          } = validateMobileDisplay(tableType)

          if (!isMobileDisplayValid) {
            tableTypeMap.set(mobileDisplayErrorKey, mobileDisplayErrorValue)
          }

          const {
            isValid: isMobileReadOnlyValid,
            errorKey: mobileReadOnlyErrorKey,
            errorValue: mobileReadOnlyErrorValue,
          } = validateMobileReadOnly(tableType)

          if (!isMobileReadOnlyValid) {
            tableTypeMap.set(mobileReadOnlyErrorKey, mobileReadOnlyErrorValue)
          }

          const { isValid: isKioskDisplayValid, errorKey: kioskDisplayErrorKey, errorValue: kioskDisplayErrorValue } = validateKioskDisplay(tableType)

          if (!isKioskDisplayValid) {
            tableTypeMap.set(kioskDisplayErrorKey, kioskDisplayErrorValue)
          }

          const {
            isValid: isKioskReadOnlyValid,
            errorKey: kioskReadOnlyErrorKey,
            errorValue: kioskReadOnlyErrorValue,
          } = validateKioskReadOnly(tableType)

          if (!isKioskReadOnlyValid) {
            tableTypeMap.set(kioskReadOnlyErrorKey, kioskReadOnlyErrorValue)
          }

          const {
            isValid: isTableTypeStatusValid,
            errorKey: tableTypeStatusErrorKey,
            errorValue: tableTypeStatusErrorValue,
          } = validateTableTypeStatus(item.status, tableType)

          if (!isTableTypeStatusValid) {
            tableTypeMap.set(tableTypeStatusErrorKey, tableTypeStatusErrorValue)
          }

          if (tableType.status === 'D') {
            tableTypeStatusList.push(tableType.status)
          }

          const { isValid: isStartTimeValid, errorKey: startTimeErrorKey, errorValue: startTimeErrorValue } = validateStartTime(tableType)

          if (!isStartTimeValid) {
            tableTypeMap.set(startTimeErrorKey, startTimeErrorValue)
          }

          const { isValid: isEndTimeValid, errorKey: endTimeErrorKey, errorValue: endTimeErrorValue } = validateEndTime(tableType)

          if (!isEndTimeValid) {
            tableTypeMap.set(endTimeErrorKey, endTimeErrorValue)
          }

          if (tableTypeMap.size > 0) {
            tableTypeMap.set(cons.KEY_TABLE_TYPE_ERROR, ['Has error(s)'])
            this.setTableTypeErrorMapMessages(tableType.customId, tableTypeMap)
          }
          if (item.status === 'A' && tableTypeStatusList.length === item.tableTypeList.length) {
            timeSectionMap.set(cons.KEY_TABLE_TYPE_LIST, ['Error: All Table Types are D (Deactive)'])
          }

          return tableType
        })
      }
      if (timeSectionMap.size > 0) {
        this.setQueueTemplateErrorMapMessages(item.customId, timeSectionMap)
      } else {
        this.setQueueTemplateDeleteErrorMapMessages(item.customId, timeSectionMap)
      }
      return item
    })
  }

  setQueueTemplateErrorMapMessages(key, errorMessages, valid = false) {
    this.queueTemplateErrorMap.set(key, errorMessages)
    // console.log("queueTemplateErrorMap", this.queueTemplateErrorMap)
    if (!valid) {
      this.valid = false
    }
  }

  setQueueTemplateDeleteErrorMapMessages(key, errorMessages, valid = false) {
    this.queueTemplateErrorMap.delete(key, errorMessages)
    // console.log("queueTemplateErrorMap", this.queueTemplateErrorMap)
  }

  setTimeSectionErrorMapMessages(key, errorMessages, valid = false) {
    this.timeSectionErrorMap.set(key, errorMessages)
    // console.log("timeSectionErrorMap", this.timeSectionErrorMap)
    if (!valid) {
      this.valid = false
    }
  }

  setTimeSectionDeleteErrorMapMessages(key, errorMessages, valid = false) {
    this.timeSectionErrorMap.delete(key, errorMessages)
    // console.log("timeSectionErrorMap", this.timeSectionErrorMap)
  }

  setTableTypeErrorMapMessages(key, errorMessages, valid = false) {
    this.tableTypeErrorMap.set(key, errorMessages)
    // console.log("tableTypeErrorMap", this.tableTypeErrorMap)
    if (!valid) {
      this.valid = false
    }
  }
}

const validation = new QueueTemplateValidation()
export default validation

const validateTableType = (tableType, tableTypeList) => {
  const errorKey = cons.KEY_TABLE_TYPE

  if (!tableType.tableType) {
    return getValidationObject(false, errorKey, ['Missing Table Type'])
  }

  if (tableTypeList.includes(tableType.tableType)) {
    return getValidationObject(false, errorKey, ['Duplicate Table Type'])
  }

  if (!/^[A-Z0-9]{1,3}$/.test(tableType.tableType)) {
    return getValidationObject(false, errorKey, ['Table Type must be 1-3 characters containing only A-Z or 0-9'])
  }

  return getValidationObject(true, null, null)
}

const validateParentTableType = (tableType, tableTypeList) => {
  const errorKey = cons.KEY_PARENT_TABLE_TYPE
  const isParentTableTypeExist = tableTypeList?.find((tableTypeItem) => tableTypeItem.tableType === tableType.parentTableType)
  if (tableType.parentTableType && !isParentTableTypeExist) {
    return getValidationObject(false, errorKey, ['Parent Table Type Not Exist'])
  }

  if (tableType.tableType === tableType.parentTableType) {
    return getValidationObject(false, errorKey, ['Parent Table Type Should Not Be The Same As Table Type'])
  }

  return getValidationObject(true, null, null)
}

const validateTableTypeForeground = (tableType) => {
  const errorKey = cons.KEY_TABLE_TYPE_FOREGROUND_COLOR

  if (!tableType.tableTypeForegroundColor && tableType.tableTypeBackgroundColor) {
    return getValidationObject(false, errorKey, ['Foreground And Background Color Required Either Empty or Either Filled In'])
  }

  if (tableType.tableTypeForegroundColor && !isHexCodeValidate(tableType.tableTypeForegroundColor)) {
    return getValidationObject(false, errorKey, ['Table Type Foreground Color Invalid'])
  }

  return getValidationObject(true, null, null)
}

const validateTableTypeBackground = (tableType) => {
  const errorKey = cons.KEY_TABLE_TYPE_BACKGROUND_COLOR

  if (!tableType.tableTypeBackgroundColor && tableType.tableTypeForegroundColor) {
    return getValidationObject(false, errorKey, ['Foreground And Background Color Required Either Empty or Either Filled In'])
  }

  if (tableType.tableTypeBackgroundColor && !isHexCodeValidate(tableType.tableTypeBackgroundColor)) {
    return getValidationObject(false, errorKey, ['Table Type Background Color Invalid'])
  }

  return getValidationObject(true, null, null)
}

const validateTableTypeMobileForeground = (tableType) => {
  const errorKey = cons.KEY_TABLE_TYPE_MOBILE_FOREGROUND_COLOR

  if (!tableType.tableTypeMobileForegroundColor && tableType.tableTypeMobileBackgroundColor) {
    return getValidationObject(false, errorKey, ['Foreground And Background Color Required Either Empty or Either Filled In'])
  }

  if (tableType.tableTypeMobileForegroundColor && !isHexCodeValidate(tableType.tableTypeMobileForegroundColor)) {
    return getValidationObject(false, errorKey, ['Table Type Foreground Color Invalid'])
  }

  return getValidationObject(true, null, null)
}

const validateTableTypeMobileBackground = (tableType) => {
  const errorKey = cons.KEY_TABLE_TYPE_MOBILE_BACKGROUND_COLOR

  if (!tableType.tableTypeMobileBackgroundColor && tableType.tableTypeMobileForegroundColor) {
    return getValidationObject(false, errorKey, ['Foreground And Background Color Required Either Empty or Either Filled In'])
  }

  if (tableType.tableTypeMobileBackgroundColor && !isHexCodeValidate(tableType.tableTypeMobileBackgroundColor)) {
    return getValidationObject(false, errorKey, ['Table Type Background Color Invalid'])
  }

  return getValidationObject(true, null, null)
}

const validateMinSize = (tableType) => {
  const errorKey = cons.KEY_MIN_SIZE

  if (isMaxSmallerThanMin(tableType.minSize, tableType.maxSize) && tableType.sizeSelectable === true) {
    return getValidationObject(false, errorKey, ['Table Type min size should not be later than max size'])
  }

  if (!isWithInRangeAndValidNumber({ value: tableType.minSize }) && tableType.sizeSelectable === true) {
    return getValidationObject(false, errorKey, ['Missing Min Size'])
  }

  if (!isWithInRangeAndValidNumber({ min: 1, value: tableType.minSize }) && tableType.sizeSelectable === true) {
    return getValidationObject(false, errorKey, ['Invalid Min Size'])
  }

  return getValidationObject(true, null, null)
}

const validateMaxSize = (tableType) => {
  const errorKey = cons.KEY_MAX_SIZE

  if (tableType.sizeSelectable === true) {
    if (isMaxSmallerThanMin(tableType.minSize, tableType.maxSize)) {
      return getValidationObject(false, errorKey, ['Table Type min size should not be later than max size'])
    }

    if (!isWithInRangeAndValidNumber({ value: tableType.maxSize })) {
      return getValidationObject(false, errorKey, ['Missing Max Size'])
    }

    if (!isWithInRangeAndValidNumber({ min: 1, value: tableType.maxSize })) {
      return getValidationObject(false, errorKey, ['Invalid Max Size'])
    }
  }

  return getValidationObject(true, null, null)
}

const validateSizeSelectable = (tableType) => {
  const errorKey = cons.KEY_SIZE_SELECTABLE

  if (!tableType.sizeSelectable && tableType.sizeSelectable !== false) {
    return getValidationObject(false, errorKey, ['Missing Size Selectable'])
  }

  return getValidationObject(true, null, null)
}

const validateCutOffMinute = (tableType) => {
  if (tableType.cutOffMinute !== null && !isWithInRangeAndValidNumber({ value: tableType.cutOffMinute })) {
    return getValidationObject(false, cons.KEY_CUT_OFF_MIN, ['Invalid Cut Off Minute'])
  }

  return getValidationObject(true, null, null)
}

const validateGroupSection = (tableType) => {
  const errorKey = cons.KEY_GROUPED_SECTION
  if (tableType.groupedSection && !/^[A-Z0-9]{1}$/.test(tableType.groupedSection)) {
    return getValidationObject(false, errorKey, ['Grouped Section must be 1 characters containing only A-Z'])
  }

  return getValidationObject(true, null, null)
}

const validateDailyQuota = (tableType) => {
  const errorKey = cons.KEY_DAILY_QUOTA
  if (!isWithInRangeAndValidNumber({ value: tableType.dailyQuota })) {
    return getValidationObject(false, errorKey, ['Missing Daily Quota'])
  }

  if (!isWithInRangeAndValidNumber({ min: -1, value: tableType.dailyQuota })) {
    return getValidationObject(false, errorKey, ['Invalid Daily Quota'])
  }

  return getValidationObject(true, null, null)
}

const validatePersonalQuota = (tableType) => {
  const errorKey = cons.KEY_PERSONAL_QUOTA
  if (!isWithInRangeAndValidNumber({ value: tableType.personalQuota })) {
    return getValidationObject(false, errorKey, ['Missing Personal Quota'])
  }

  if (!isWithInRangeAndValidNumber({ min: -1, value: tableType.personalQuota })) {
    return getValidationObject(false, errorKey, ['Invalid Personal Quota'])
  }

  return getValidationObject(true, null, null)
}

const validateNextRemind = (tableType) => {
  const errorKey = cons.KEY_NEXT_REMIND
  if (!isWithInRangeAndValidNumber({ value: tableType.nextRemind })) {
    return getValidationObject(false, errorKey, ['Invalid Next Remind'])
  }

  return getValidationObject(true, null, null)
}

const validateMobileDisplay = (tableType) => {
  const errorKey = cons.KEY_MOBILE_DISPLAY
  if (!tableType.mobileDisplay && tableType.mobileDisplay !== false) {
    return getValidationObject(false, errorKey, ['Missing Mobile Display'])
  }

  return getValidationObject(true, null, null)
}

const validateMobileReadOnly = (tableType) => {
  const errorKey = cons.KEY_MOBILE_READ_ONLY
  if (!tableType.mobileReadOnly && tableType.mobileReadOnly !== false) {
    return getValidationObject(false, errorKey, ['Missing Mobile Read Only'])
  }

  return getValidationObject(true, null, null)
}

const validateKioskDisplay = (tableType) => {
  const errorKey = cons.KEY_KIOS_DISPLAY
  if (!tableType.kioskDisplay && tableType.kioskDisplay !== false) {
    return getValidationObject(false, errorKey, ['Missing Kiosk Display'])
  }

  return getValidationObject(true, null, null)
}

const validateKioskReadOnly = (tableType) => {
  const errorKey = cons.KEY_KIOS_READ_ONLY
  if (!tableType.kioskReadOnly && tableType.kioskReadOnly !== false) {
    return getValidationObject(false, errorKey, ['Missing Kiosk Read Only'])
  }

  return getValidationObject(true, null, null)
}

const validateTableTypeStatus = (timeSectionStatus, tableType) => {
  const errorKey = cons.KEY_TABLE_TYPE_STATUS
  if (timeSectionStatus === STATUS.delete && tableType.status === STATUS.active) {
    return getValidationObject(false, errorKey, ['Invalid Status, Time Section Status is D (deactive)'])
  }

  return getValidationObject(true, null, null)
}

const validateStartTime = (tableType) => {
  const errorKey = cons.KEY_TABLE_TYPE_START_TIME
  if (!tableType.startTime) {
    return getValidationObject(false, errorKey, ['Missing Time Section Start Time'])
  }

  if (tableType.endTime && moment.duration(tableType.startTime).asSeconds() > moment.duration(tableType.endTime).asSeconds()) {
    return getValidationObject(false, errorKey, ['Time Section Start Time should not be later than End Time'])
  }

  return getValidationObject(true, null, null)
}

const validateEndTime = (tableType) => {
  const errorKey = cons.KEY_TABLE_TYPE_END_TIME
  if (!tableType.endTime) {
    return getValidationObject(false, errorKey, ['Missing Time Section End Time'])
  }

  if (tableType.startTime && moment.duration(tableType.startTime).asSeconds() > moment.duration(tableType.endTime).asSeconds()) {
    return getValidationObject(false, errorKey, ['Time Section Start Time should not be later than End Time'])
  }

  return getValidationObject(true, null, null)
}

const getValidationObject = (isValid, errorKey, errorValue) => {
  return {
    isValid,
    errorKey,
    errorValue,
  }
}
