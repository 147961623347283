import { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useStore } from 'react-redux'
import QRCode from 'qrcode.react'
import _ from 'lodash'

import SaveIcon from '@mui/icons-material/Save'
import ClearIcon from '@mui/icons-material/Clear'
import LowPriorityIcon from '@mui/icons-material/LowPriority'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import GetAppIcon from '@mui/icons-material/GetApp'
import { Drawer } from '@mui/material'
import Paper from '@mui/material/Paper'
import { Card } from '@mui/material'
import { makeStyles } from '@mui/styles'

import { constants as cons, RESTAURANT_URL_TYPE, URL_MAP_ACTION_TYPE } from '../../Constants/constants'
import { getOne, put } from '../../helper/baseFetch'
import { EditCreateDisplay } from '../../Component/EditCreateDisplay/EditCreateDisplay'
import { TempTextInput } from '../../Component/Input/TempTextInput'
import { TempAutoSelectInput } from '../../Component/Input/TempAutoSelectInput'
import { TempBtn } from '../../Component/Input/TempBtn'
import { getUrlObjParameters } from '../../Component/UrlMap/getUrlObjParameters'
import { getUrlObjValues } from '../../Component/UrlMap/getUrlObjValues'
import { getUrlObjUrl } from '../../Component/UrlMap/getUrlObjUrl'
import { FolderList } from '../../Page/Folder/FolderList'
import { RestaurantList } from '../../Page/Restaurant/RestaurantList'
import { ProductList } from '../../Component/ProductCreate/Product/ProductList'
//import { ProductMapList } from "../../Component/ProductCreate/Category/ProductMapList";
import { ProductCategoryList } from '../../Component/ProductCreate/Category/ProductCategoryList'
import { GlobalDialog } from '../../Component/GlobalDialog'
import UrlMapValidation from '../../validations/urlMapValidation'
import { urlMapValidationConstants as validationCo } from '../../Constants/validationConstants'
import { BrandList } from '../Brand/BrandList'
import { checkIsUrlHasQueryParam } from '../../helper/regexUtil'
import { getRestaurantUrlType, getRestUrlIdFromUrl, parseQueryParamsStrToKeyValuePairs } from '../../helper/urlUtil'

const helperTextStyles = makeStyles((theme) => ({
  root: {
    color: 'red !important',
  },
}))

export const UrlMapEdit = ({ newPath, mapName, mapCode, mapId, redirectPath, tab, otherAdditionData }) => {
  const store = useStore()
  const params = useParams()
  const history = useHistory()
  const [clear, setClear] = useState(true)
  const [ready, setReady] = useState(false)
  const [hiddenQrcode, setHiddenQrcode] = useState(false)
  const [drawerType, setDrawerType] = useState('')
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [dialog, setDialog] = useState({
    open: false,
    dialogActions: [],
  })
  const [savedUrl, setSavedUrl] = useState(cons.URL_MAP_INPUT)

  const defaultInputObj = { from: '', realRestUrlId: '' }
  const [inputObj, setInputObj] = useState(defaultInputObj)

  // const savedUrl = useSelector((state) => state.urlMap.ui.urlMap)
  const resource = 'UrlMap'
  const helperTextStylesClasses = helperTextStyles()
  // eslint-disable-next-line no-unused-vars
  const [errorVersion, setErrorVersion] = useState(UrlMapValidation.getVersion())

  const tabs = [
    {
      label: { resource: resource, key: 'info' },
      tabIndex: cons.URL_MAP_TAB.INFO,
    },
    otherAdditionData && otherAdditionData.tabs,
  ].filter((tabTitle) => tabTitle)

  const fields = [
    {
      className: 'edit-create-input',
      variant: cons.STYLE.VARIANT.STANDARD,
      label: { resource: resource, key: 'mapCode' },
      value: savedUrl.mapCode,
      name: 'mapCode',
      isEndOfLine: false,
      disabled: true,
      required: true,
      helperTextIcon: true,
      helperText: UrlMapValidation.getErrMsg(validationCo.KEY_MAP_CODE),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: 'edit-create-input',
      variant: cons.STYLE.VARIANT.STANDARD,
      label: { resource: resource, key: 'status' },
      value: savedUrl.status,
      name: 'status',
      isEndOfLine: true,
      disabled: true,
      required: true,
    },
    {
      className: 'edit-create-input',
      variant: savedUrl.mapValueDisable ? cons.STYLE.VARIANT.STANDARD : '',
      label: { resource: resource, key: 'mapValue' },
      value: savedUrl.mapValue,
      name: 'mapValue',
      isEndOfLine: false,
      disabled: savedUrl.mapValueDisable,
    },
    {
      className: 'edit-create-input',
      label: { resource: resource, key: 'restUrlIdQRCode' },
      value: savedUrl.restUrlId,
      name: 'restUrlId',
      isEndOfLine: false,
    },
    {
      className: 'edit-create-input',
      label: { resource: resource, key: 'agentType' },
      value: savedUrl.agentType,
      type: cons.TYPE.SELECT,
      selectValue: cons.CHOICES.URL_MAP.AGENT_TYPE,
      name: 'agentType',
      isEndOfLine: true,
      required: true,
      helperTextIcon: true,
      helperText: UrlMapValidation.getErrMsg(validationCo.KEY_AGENT_TYPE),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: `edit-create-input ${savedUrl.agentType !== 'web' ? 'display-none' : ''}`,
      label: { resource: resource, key: 'actionType' },
      value: savedUrl.actionType,
      type: cons.TYPE.SELECT,
      selectValue: cons.CHOICES.URL_MAP.ACTION_TYPE,
      name: 'actionType',
      isEndOfLine: false,
      required: true,
      helperTextIcon: true,
      helperText: UrlMapValidation.getErrMsg(validationCo.KEY_ACTION_TYPE),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: `edit-create-input ${savedUrl.isHideCustomField || savedUrl.actionType === URL_MAP_ACTION_TYPE.SEARCH ? 'display-none' : ''}`,
      label: savedUrl.customFieldName ? { resource: resource, key: '' + savedUrl.customFieldName } : { key: '' },
      value: savedUrl.parameters && savedUrl.parameters[savedUrl.customFieldName],
      onClickEndAdornmentIconButton: savedUrl.isDisabledCustomField ? () => onClickOpenDrawer(savedUrl.actionType) : null,
      name: 'customField',
      isEndOfLine: false,
      disabled: savedUrl.isDisabledCustomField,
      required: true,
      helperTextIcon: true,
      helperText: UrlMapValidation.getErrMsg(validationCo.KEY_CUSTOM_FIELD),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: `edit-create-input ${
        savedUrl.actionType === URL_MAP_ACTION_TYPE.REST && savedUrl.restaurantUrlType === RESTAURANT_URL_TYPE.DOWNLOAD_HTML ? '' : 'display-none'
      }`,
      label: { resource: resource, key: 'action' },
      value: savedUrl.parameters && savedUrl.parameters['action'],
      type: cons.TYPE.SELECT,
      selectValue: cons.CHOICES.BANNER.RESTAURANT_ACTION,
      name: 'action',
      isEndOfLine: false,
    },
    {
      className: `edit-create-input ${
        (savedUrl.actionType !== URL_MAP_ACTION_TYPE.PRODUCT && savedUrl.actionType !== URL_MAP_ACTION_TYPE.E_COUPON) ||
        !savedUrl.parameters[savedUrl.customFieldName]
          ? 'display-none'
          : ''
      }`,
      label: { resource: resource, key: 'productParent' },
      value: savedUrl.parameters?.productParent,
      name: 'productParent',
      type: cons.TYPE.SELECT,
      selectValue: cons.CHOICES.BANNER.PRODUCT_PARENT_TYPE,
      isEndOfLine: false,
      required: false,
    },
    {
      className: `edit-create-input ${
        (savedUrl.actionType !== URL_MAP_ACTION_TYPE.PRODUCT && savedUrl.actionType !== URL_MAP_ACTION_TYPE.E_COUPON) ||
        savedUrl.parameters?.productParent !== 'CATEGORY'
          ? 'display-none'
          : ''
      }`,
      label: { resource: resource, key: 'categoryCode' },
      value: savedUrl.parameters?.categoryCode,
      name: 'categoryCode',
      onClickEndAdornmentIconButton: savedUrl.isDisabledCustomField ? () => onClickOpenDrawer(URL_MAP_ACTION_TYPE.PRODUCT_COUPON_CATEGORY_MAP) : null,
      isEndOfLine: false,
      disabled: savedUrl.isDisabledCustomField,
      required: true,
      helperText: UrlMapValidation.getErrMsg(validationCo.KEY_CHILDCODE),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: `edit-create-input ${
        (savedUrl.actionType !== URL_MAP_ACTION_TYPE.PRODUCT && savedUrl.actionType !== URL_MAP_ACTION_TYPE.E_COUPON) ||
        savedUrl.parameters?.productParent !== 'BRAND'
          ? 'display-none'
          : ''
      }`,
      label: { resource: resource, key: 'brandCode' },
      value: savedUrl.parameters?.brandCode,
      name: 'brandCode',
      onClickEndAdornmentIconButton: savedUrl.isDisabledCustomField ? () => onClickOpenDrawer(URL_MAP_ACTION_TYPE.PRODUCT_COUPON_BRAND_MAP) : null,
      isEndOfLine: false,
      disabled: savedUrl.isDisabledCustomField,
      required: true,
      helperText: UrlMapValidation.getErrMsg(validationCo.KEY_CHILDCODE),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: `edit-create-input ${savedUrl.actionType !== URL_MAP_ACTION_TYPE.FOLDER ? 'display-none' : ''}`,
      label: { resource: resource, key: 'folderType' },
      value: savedUrl.parameters && savedUrl.parameters['folderType'],
      type: cons.TYPE.SELECT,
      selectValue: cons.CHOICES.URL_MAP.FOLDER_TYPE,
      name: 'folderType',
      isEndOfLine: false,
    },
    {
      className: `edit-create-input ${savedUrl.actionType !== URL_MAP_ACTION_TYPE.SEARCH ? 'display-none' : ''}`,
      label: { resource: resource, key: 'name' },
      value: savedUrl.parameters && savedUrl.parameters['name'],
      name: 'name',
      isEndOfLine: false,
    },
    {
      className: `edit-create-input ${savedUrl.actionType !== URL_MAP_ACTION_TYPE.SEARCH ? 'display-none' : ''}`,
      size: cons.SIZE.SMALL,
      label: { resource: resource, key: 'query' },
      value: savedUrl.parameters && savedUrl.parameters['query'],
      type: cons.TYPE.SELECT,
      selectValue: cons.CHOICES.BANNER.QUERY,
      name: 'query',
      isEndOfLine: false,
    },
    {
      className: `edit-create-input ${savedUrl.actionType !== URL_MAP_ACTION_TYPE.WEB_VIEW ? 'display-none' : ''}`,
      size: cons.SIZE.SMALL,
      label: { resource: resource, key: 'title' },
      value: savedUrl.parameters && savedUrl.parameters['title'],
      name: 'title',
      clear: true,
      isEndOfLine: false,
    },
    {
      className: `edit-create-input ${savedUrl.actionType !== URL_MAP_ACTION_TYPE.WEB_VIEW ? 'display-none' : ''}`,
      size: cons.SIZE.SMALL,
      label: { resource: resource, key: 'type' },
      value: savedUrl.parameters && savedUrl.parameters.type,
      type: cons.TYPE.SELECT,
      selectValue: cons.CHOICES.URL_MAP.WEB_TYPE,
      name: 'type',
      isEndOfLine: false,
    },
    {
      className: `edit-create-input ${savedUrl.actionType !== URL_MAP_ACTION_TYPE.MY_TICKET ? 'display-none' : ''}`,
      label: { resource: resource, key: 'serviceType' },
      value: savedUrl.parameters && savedUrl.parameters.serviceType,
      type: cons.TYPE.SELECT,
      selectValue: cons.CHOICES.URL_MAP.MY_TICKET_SERVICE_TYPE,
      name: 'serviceType',
      isEndOfLine: false,
      required: true,
      helperTextIcon: true,
      helperText: UrlMapValidation.getErrMsg(validationCo.KEY_SERVICE_TYPE),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: `edit-create-input ${savedUrl.actionType !== URL_MAP_ACTION_TYPE.SHARE ? 'display-none' : ''}`,
      label: { resource: resource, key: 'serviceType' },
      value: savedUrl.parameters && savedUrl.parameters.serviceType,
      type: cons.TYPE.SELECT,
      selectValue: cons.CHOICES.URL_MAP.SHARE_SERVICE_TYPE,
      name: 'serviceType',
      isEndOfLine: false,
      required: true,
      helperTextIcon: true,
      helperText: UrlMapValidation.getErrMsg(validationCo.KEY_SERVICE_TYPE),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: `edit-create-input ${savedUrl.actionType !== URL_MAP_ACTION_TYPE.GIFT ? 'display-none' : ''}`,
      size: cons.SIZE.SMALL,
      label: { resource: resource, key: 'code' },
      value: savedUrl.parameters && savedUrl.parameters.code,
      name: 'code',
      isEndOfLine: false,
      required: true,
      helperTextIcon: true,
      helperText: UrlMapValidation.getErrMsg(validationCo.KEY_CODE),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: `edit-create-input ${savedUrl.actionType !== URL_MAP_ACTION_TYPE.GIFT ? 'display-none' : ''}`,
      size: cons.SIZE.SMALL,
      label: { resource: resource, key: 'serviceType' },
      value: savedUrl.parameters && savedUrl.parameters.serviceType,
      type: cons.TYPE.SELECT,
      selectValue: cons.CHOICES.URL_MAP.GIFT_SERVICE_TYPE,
      name: 'serviceType',
      isEndOfLine: false,
      required: true,
      helperTextIcon: true,
      helperText: UrlMapValidation.getErrMsg(validationCo.KEY_SERVICE_TYPE),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: `edit-create-input ${savedUrl.parameters.serviceType !== 'RACK_PRODUCT' ? 'display-none' : ''}`,
      label: { resource: resource, key: 'type' },
      type: cons.TYPE.SELECT,
      value: savedUrl.parameters && savedUrl.parameters.type,
      selectValue: cons.CHOICES.URL_MAP.MY_TICKET_RACK_PRODUCT_TYPE,
      name: 'type',
      isEndOfLine: false,
      required: true,
      helperTextIcon: true,
      helperText: UrlMapValidation.getErrMsg(validationCo.KEY_TYPE),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: `edit-create-input ${savedUrl.restaurantUrlType === RESTAURANT_URL_TYPE.DOWNLOAD_HTML ? '' : 'display-none'}`,
      label: { resource: resource, key: 'from' },
      value: inputObj.from,
      type: cons.TYPE.SELECT,
      selectValue: cons.CHOICES.URL_MAP.FROM,
      freeSolo: true,
      name: 'from',
      isEndOfLine: true,
    },
    {
      className: `edit-create-input ${savedUrl.actionType === URL_MAP_ACTION_TYPE.REST ? '' : 'display-none'}`,
      label: { resource: resource, key: 'restaurantUrlType' },
      value: savedUrl.restaurantUrlType,
      type: cons.TYPE.SELECT,
      selectValue: cons.CHOICES.URL_MAP.RESTAURANT_URL_TYPE,
      name: 'restaurantUrlType',
      isEndOfLine: false,
      required: true,
      helperTextIcon: true,
      helperText: UrlMapValidation.getErrMsg(validationCo.KEY_RESTAURANT_URL_TYPE),
      disableClearable: true,
    },
    {
      className: `edit-create-input ${
        savedUrl.actionType === URL_MAP_ACTION_TYPE.REST && savedUrl.restaurantUrlType !== RESTAURANT_URL_TYPE.OTHER ? '' : 'display-none'
      }`,
      label: { resource: resource, key: 'restUrlId' },
      value: inputObj.realRestUrlId,
      onClickEndAdornmentIconButton: () => onClickOpenDrawer(savedUrl.actionType),
      name: 'realRestUrlId',
      isEndOfLine: false,
      disabled: true,
      required: true,
      helperText: UrlMapValidation.getErrMsg(validationCo.KEY_REAL_REST_URL_ID),
    },
    {
      className: `full-size-input ${
        savedUrl.actionType === URL_MAP_ACTION_TYPE.CUSTOM_URL ||
        (savedUrl.actionType === URL_MAP_ACTION_TYPE.REST && savedUrl.restaurantUrlType === RESTAURANT_URL_TYPE.OTHER)
          ? ''
          : 'display-none'
      }`,
      label: { resource: resource, key: 'customUrl' },
      value: savedUrl.customUrl,
      name: 'customUrl',
      multiline: true,
      isEndOfLine: false,
      required: true,
      helperText: UrlMapValidation.getErrMsg(validationCo.KEY_CUSTOM_URL),
    },
    {
      className: 'full-size-input',
      variant: cons.STYLE.VARIANT.STANDARD,
      label: { resource: resource, key: 'url' },
      value: savedUrl.url,
      name: 'url',
      multiline: true,
      isEndOfLine: false,
      disabled: true,
      required: true,
      helperText: UrlMapValidation.getErrMsg(validationCo.KEY_URL),
    },
  ]

  const content = [
    {
      tab: (
        <>
          {savedUrl && (
            <div>
              {fields.map((inputSetting, idx) => {
                let content
                switch (inputSetting.type) {
                  case cons.TYPE.SELECT:
                    content = <TempAutoSelectInput inputSetting={inputSetting} handleChange={onChangeInput} index={idx} />
                    break
                  default:
                    content = <TempTextInput inputSetting={inputSetting} handleChange={onChangeInput} index={idx} />
                }
                return (
                  <div className="edit-display-wrapper" key={idx}>
                    {content}
                    {inputSetting.isEndOfLine && <div></div>}
                  </div>
                )
              })}
              {!hiddenQrcode && (
                <Card className="card-wrapper">
                  <QRCode id="qrCode" size={150} value={savedUrl.qrCodeUrl} level={'L'} renderAs={'svg'} />
                  <div>
                    <TempBtn
                      btnSetting={{
                        onClick: downloadQR,
                        variant: cons.STYLE.VARIANT.CONTAINED,
                        color: cons.STYLE.PRIMARY,
                        label: { key: 'downloadQRCode' },
                        icon: <GetAppIcon />,
                      }}
                    />
                  </div>
                  <a target="_blank" rel="noopener noreferrer" href={savedUrl.qrCodeUrl}>
                    <span className="restaurant-aside-qrCode">{savedUrl.qrCodeUrl}</span>
                  </a>
                  <div>{UrlMapValidation.getErrMsg(validationCo.KEY_CUSTOM_FIELD)}</div>
                </Card>
              )}
            </div>
          )}
        </>
      ),
      btn: true,
    },
    otherAdditionData && otherAdditionData.configMapList,
  ].filter((tabContent) => tabContent !== undefined)

  const bottomBtns = [
    {
      variant: cons.STYLE.VARIANT.CONTAINED,
      color: cons.STYLE.PRIMARY,
      label: { key: 'save' },
      onClick: onClickSave,
      className: 'margin-left-16',
      icon: <SaveIcon />,
    },
    {
      variant: cons.STYLE.VARIANT.CONTAINED,
      color: cons.STYLE.PRIMARY,
      label: { key: 'clear' },
      disabled: false,
      onClick: onClickClear,
      className: 'margin-left-16',
      icon: <ClearIcon />,
    },
  ]

  function downloadQR() {
    const svg = document.getElementById('qrCode')
    const s = new XMLSerializer().serializeToString(svg)
    const src = `data:image/svg+xml;base64,${window.btoa(s)}`
    const img = new Image()
    img.src = src
    img.onload = () => {
      const canvas = document.createElement('canvas')
      canvas.width = 1000
      canvas.height = 1000
      const context = canvas.getContext('2d')
      context.drawImage(img, 0, 0, 1000, 1000)
      const ImgBase64 = canvas.toDataURL('image/png')
      let downloadLink = document.createElement('a')
      downloadLink.href = ImgBase64
      downloadLink.download = `${window.btoa(s)}.png`
      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)
    }
  }

  const onClickBackToEdit = () => {
    setDialog({
      open: false,
      dialogActions: [],
    })
    onClickClear()
  }
  const onClickBackToList = () => {
    setDialog({
      open: false,
      dialogActions: [],
    })
    history.push('/' + cons.PATH.SHOP.URL_MAP)
  }

  const onClickOpenDrawer = (actionType) => {
    setDrawerType(actionType)
    switch (actionType) {
      case URL_MAP_ACTION_TYPE.PRODUCT_CATEGORY:
      case URL_MAP_ACTION_TYPE.PRODUCT:
      case URL_MAP_ACTION_TYPE.E_COUPON:
      case URL_MAP_ACTION_TYPE.E_COUPON_CATEGORY:
      case URL_MAP_ACTION_TYPE.E_COUPON_BRAND:
      case URL_MAP_ACTION_TYPE.PRODUCT_COUPON_CATEGORY_MAP:
      case URL_MAP_ACTION_TYPE.PRODUCT_COUPON_BRAND_MAP:
      case URL_MAP_ACTION_TYPE.PRODUCT_BRAND:
      case URL_MAP_ACTION_TYPE.FOLDER:
      case URL_MAP_ACTION_TYPE.REST:
        setDrawerOpen(true)
        break
      default:
        setDrawerOpen(false)
        break
    }
  }

  function onClickCloseDrawer() {
    setDrawerOpen(false)
  }

  function onChangeInput(name, e, actionType) {
    setHiddenQrcode(true)
    let newUrlObj = _.cloneDeep(savedUrl)
    switch (name) {
      case 'realRestUrlId':
        setInputObj({ ...inputObj, realRestUrlId: e.restUrlId })
        newUrlObj.url = getUrlObjUrl({}, newUrlObj, { ...inputObj, realRestUrlId: e.restUrlId })
        break
      case 'restaurantUrlType':
        newUrlObj.restaurantUrlType = e
        newUrlObj.url = getUrlObjUrl({}, newUrlObj, inputObj)
        if (e === RESTAURANT_URL_TYPE.OTHER) {
          newUrlObj.customUrl = ''
          newUrlObj.url = ''
        }
        break
      case 'customUrl':
        const urlStr = e.split('?')[0] || ''
        const queryParamStr = e.split('?')[1] || ''

        const keyValuePairs = checkIsUrlHasQueryParam(e) ? parseQueryParamsStrToKeyValuePairs(queryParamStr) : []
        const filteredKeyValuePairs = keyValuePairs.filter((pair) => pair.key !== 'from')

        const newQueryParamStr = filteredKeyValuePairs.reduce((result, param, idx) => {
          const paramStr = `${encodeURIComponent(param.key)}=${encodeURIComponent(param.value)}`
          return idx === 0 ? paramStr : `${result}&${paramStr}`
        }, '')

        if (inputObj.from && newUrlObj.actionType === URL_MAP_ACTION_TYPE.CUSTOM_URL) {
          newUrlObj.url = newQueryParamStr ? `${urlStr}?${newQueryParamStr}&from=${inputObj.from}` : `${urlStr}?from=${inputObj.from}`
        } else {
          newUrlObj.url = newQueryParamStr ? `${urlStr}?${newQueryParamStr}` : urlStr
        }

        newUrlObj.customUrl = newQueryParamStr ? `${urlStr}?${newQueryParamStr}` : urlStr

        break
      case 'agentType':
        newUrlObj[name] = e
        newUrlObj['mapValue'] = ''
        newUrlObj['actionType'] = ''
        newUrlObj['url'] = getUrlObjUrl({ customField: '' }, newUrlObj, inputObj)
        newUrlObj = triggerGetUrlObjValues(newUrlObj)
        newUrlObj = triggerGetUrlObjParameters(newUrlObj)
        break
      case 'actionType':
        UrlMapValidation.resetErrMsg(validationCo.KEY_CUSTOM_FIELD)
        newUrlObj[name] = e

        if (newUrlObj.actionType === URL_MAP_ACTION_TYPE.CUSTOM_URL) {
          if (inputObj?.from) {
            newUrlObj.url = `?from=${inputObj.from}`
          } else {
            newUrlObj.url = ''
          }
          newUrlObj.customUrl = ''
        } else {
          newUrlObj.url = getUrlObjUrl({ customField: '' }, newUrlObj, inputObj)
        }

        newUrlObj.mapValue = ''
        newUrlObj = triggerGetUrlObjValues(newUrlObj)
        newUrlObj = triggerGetUrlObjParameters(newUrlObj)
        break
      case 'customField':
        UrlMapValidation.resetErrMsg(validationCo.KEY_CUSTOM_FIELD)
        UrlMapValidation.resetErrMsg(validationCo.KEY_CHILDCODE)
        if (newUrlObj.agentType === 'weChat') {
          newUrlObj['url'] = getUrlObjUrl({ customField: e }, newUrlObj, inputObj)
        } else {
          switch (newUrlObj.actionType) {
            case URL_MAP_ACTION_TYPE.PRODUCT_CATEGORY:
            case URL_MAP_ACTION_TYPE.E_COUPON_CATEGORY:
              newUrlObj['url'] = getUrlObjUrl({ customField: e.categoryCode }, newUrlObj, inputObj)
              break
            case URL_MAP_ACTION_TYPE.PRODUCT:
              if (actionType === URL_MAP_ACTION_TYPE.PRODUCT_COUPON_CATEGORY_MAP) {
                newUrlObj['url'] = getUrlObjUrl(
                  {
                    customField: newUrlObj?.parameters?.productCode,
                    productParent: newUrlObj?.parameters?.productParent,
                    categoryCode: e.categoryCode,
                  },
                  newUrlObj,
                  inputObj
                )
              } else if (actionType === URL_MAP_ACTION_TYPE.PRODUCT_COUPON_BRAND_MAP) {
                newUrlObj['url'] = getUrlObjUrl(
                  {
                    customField: newUrlObj?.parameters?.productCode,
                    productParent: newUrlObj?.parameters?.productParent,
                    brandCode: e.brandCode,
                  },
                  newUrlObj,
                  inputObj
                )
              } else {
                newUrlObj['url'] = getUrlObjUrl({ customField: e.productCode }, newUrlObj, inputObj)
              }
              break
            case URL_MAP_ACTION_TYPE.E_COUPON:
              e.ecouponCode = e.productCode
              if (actionType === URL_MAP_ACTION_TYPE.PRODUCT_COUPON_CATEGORY_MAP) {
                newUrlObj['url'] = getUrlObjUrl(
                  {
                    customField: newUrlObj?.parameters?.ecouponCode,
                    productParent: newUrlObj?.parameters?.productParent,
                    categoryCode: e.categoryCode,
                  },
                  newUrlObj,
                  inputObj
                )
              } else if (actionType === URL_MAP_ACTION_TYPE.PRODUCT_COUPON_BRAND_MAP) {
                newUrlObj['url'] = getUrlObjUrl(
                  {
                    customField: newUrlObj?.parameters?.ecouponCode,
                    productParent: newUrlObj?.parameters?.productParent,
                    brandCode: e.brandCode,
                  },
                  newUrlObj,
                  inputObj
                )
              } else {
                newUrlObj['url'] = getUrlObjUrl({ customField: e.ecouponCode }, newUrlObj, inputObj)
              }
              break
            case URL_MAP_ACTION_TYPE.PRODUCT_BRAND:
            case URL_MAP_ACTION_TYPE.E_COUPON_BRAND:
              // case URL_MAP_ACTION.BRAND_PRODUCT:
              newUrlObj['url'] = getUrlObjUrl({ customField: e.brandCode }, newUrlObj, inputObj)
              break
            case URL_MAP_ACTION_TYPE.FOLDER:
              newUrlObj['url'] = getUrlObjUrl({ customField: e.folderCode, folderType: newUrlObj.parameters.folderType }, newUrlObj, inputObj)
              break
            case URL_MAP_ACTION_TYPE.REST:
              newUrlObj['url'] = getUrlObjUrl({ customField: e.restUrlId, action: newUrlObj.parameters['action'] }, newUrlObj, inputObj)
              break
            case URL_MAP_ACTION_TYPE.PIXEL:
              newUrlObj.url = getUrlObjUrl(
                {
                  // customField: e.restUrlId,
                  action: newUrlObj.parameters.action,
                },
                newUrlObj,
                inputObj
              )
              break
            case URL_MAP_ACTION_TYPE.CUSTOM_URL:
              newUrlObj.url = ''
              break
            default:
              newUrlObj.url = getUrlObjUrl(
                {
                  customField: e,
                  code: newUrlObj.parameters['code'],
                  title: newUrlObj.parameters['title'],
                  serviceType: newUrlObj.parameters['serviceType'],
                  type: newUrlObj.parameters['type'],
                },
                newUrlObj,
                inputObj
              )
              break
          }
        }
        newUrlObj = triggerGetUrlObjParameters(newUrlObj)
        break
      case 'folderType':
        newUrlObj.url = getUrlObjUrl(
          {
            customField: newUrlObj.parameters[newUrlObj.customFieldName],
            folderType: e,
          },
          newUrlObj,
          inputObj
        )
        newUrlObj = triggerGetUrlObjParameters(newUrlObj)
        break
      case 'code':
        newUrlObj.url = getUrlObjUrl(
          {
            customField: newUrlObj.parameters[newUrlObj.customFieldName],
            code: e,
            serviceType: newUrlObj.parameters['serviceType'],
            type: newUrlObj.parameters['type'],
          },
          newUrlObj,
          inputObj
        )
        newUrlObj = triggerGetUrlObjParameters(newUrlObj)
        break
      case 'title':
        newUrlObj['url'] = getUrlObjUrl(
          {
            customField: newUrlObj.parameters[newUrlObj.customFieldName],
            type: newUrlObj.parameters['type'],
            title: e,
          },
          newUrlObj,
          inputObj
        )
        newUrlObj = triggerGetUrlObjParameters(newUrlObj)
        break
      case 'serviceType':
        newUrlObj['url'] = getUrlObjUrl(
          {
            customField: newUrlObj.parameters[newUrlObj.customFieldName],
            code: newUrlObj.parameters['code'],
            serviceType: e,
            type: '',
          },
          newUrlObj,
          inputObj
        )
        newUrlObj = triggerGetUrlObjParameters(newUrlObj)
        break
      case 'name':
        newUrlObj['url'] = getUrlObjUrl(
          {
            customField: newUrlObj.parameters[newUrlObj.customFieldName],
            code: newUrlObj.parameters['code'],
            name: e,
            query: newUrlObj.parameters['query'],
          },
          newUrlObj,
          inputObj
        )
        newUrlObj = triggerGetUrlObjParameters(newUrlObj)
        break
      case 'query':
        newUrlObj['url'] = getUrlObjUrl(
          {
            customField: newUrlObj.parameters[newUrlObj.customFieldName],
            code: newUrlObj.parameters['code'],
            name: newUrlObj.parameters['name'],
            query: e,
          },
          newUrlObj,
          inputObj
        )
        newUrlObj = triggerGetUrlObjParameters(newUrlObj)
        break
      case 'type':
        newUrlObj['url'] = getUrlObjUrl(
          {
            customField: newUrlObj.parameters[newUrlObj.customFieldName],
            code: newUrlObj.parameters['code'],
            title: newUrlObj.parameters['title'],
            serviceType: newUrlObj.parameters['serviceType'],
            type: e,
          },
          newUrlObj,
          inputObj
        )
        newUrlObj = triggerGetUrlObjParameters(newUrlObj)
        break
      case 'action':
        newUrlObj['url'] = getUrlObjUrl({ customField: newUrlObj.parameters[newUrlObj.customFieldName], action: e }, newUrlObj, inputObj)
        newUrlObj = triggerGetUrlObjParameters(newUrlObj)
        break
      case 'from':
        setInputObj({ ...inputObj, from: e })

        if (newUrlObj.actionType === URL_MAP_ACTION_TYPE.CUSTOM_URL) {
          const urlStr = newUrlObj.url.split('?')[0] || ''
          const queryParamStr = newUrlObj.url.split('?')[1] || ''

          const keyValuePairs = checkIsUrlHasQueryParam(e) ? parseQueryParamsStrToKeyValuePairs(queryParamStr) : []
          const filteredKeyValuePairs = keyValuePairs.filter((pair) => pair.key !== 'from')

          const newQueryParamStr = filteredKeyValuePairs.reduce((result, param, idx) => {
            const paramStr = `${encodeURIComponent(param.key)}=${encodeURIComponent(param.value)}`
            return idx === 0 ? paramStr : `${result}&${paramStr}`
          }, '')

          if (e) {
            newUrlObj.url = newQueryParamStr ? `${urlStr}?${newQueryParamStr}&from=${e}` : `${urlStr}?from=${e}`
          } else {
            newUrlObj.url = newQueryParamStr ? `${urlStr}?${newQueryParamStr}` : urlStr
          }

          newUrlObj.customUrl = newQueryParamStr ? `${urlStr}?${newQueryParamStr}` : urlStr
        } else {
          newUrlObj.url = getUrlObjUrl(
            {
              customField: newUrlObj.parameters[newUrlObj.customFieldName],
              catProductCode: newUrlObj.parameters.productCode,
              productParent: newUrlObj?.parameters?.productParent,
              categoryCode: newUrlObj?.parameters?.categoryCode,
              brandCode: newUrlObj?.parameters?.brandCode,
              brandProductCode: newUrlObj.parameters.productCode,
              folderType: newUrlObj.parameters.folderType,
              title: newUrlObj.parameters.title,
              type: newUrlObj.parameters.type,
              name: newUrlObj.parameters.name,
              haveRef: newUrlObj.parameters.haveRef,
              query: newUrlObj.parameters.query,
              action: newUrlObj.parameters.action,
            },
            newUrlObj,
            { ...inputObj, from: e }
          )

          newUrlObj = triggerGetUrlObjParameters(newUrlObj)
        }
        break
      case 'productParent':
        UrlMapValidation.resetErrMsg(validationCo.KEY_CHILDCODE)
        newUrlObj['parameters'][`${name}`] = e
        newUrlObj['url'] = getUrlObjUrl(
          {
            customField: newUrlObj.parameters[newUrlObj.customFieldName],
            productParent: e,
            action: e,
          },
          newUrlObj,
          inputObj
        )
        newUrlObj = triggerGetUrlObjParameters(newUrlObj)
        break
      default:
        newUrlObj[`${name}`] = e
    }
    setSavedUrl(newUrlObj)
  }

  function onClickListRow(e, actionType) {
    if (actionType === URL_MAP_ACTION_TYPE.CATEGORY_PRODUCT_MAP) {
      onChangeInput(URL_MAP_ACTION_TYPE.CATEGORY_PRODUCT_MAP, e)
    } else if (actionType === URL_MAP_ACTION_TYPE.BRAND_PRODUCT_MAP) {
      onChangeInput(URL_MAP_ACTION_TYPE.BRAND_PRODUCT_MAP, e)
    } else if (actionType === URL_MAP_ACTION_TYPE.REST) {
      onChangeInput('realRestUrlId', e, actionType)
    } else {
      onChangeInput('customField', e, actionType)
    }
    onClickCloseDrawer()
  }

  function List() {
    let list
    switch (drawerType) {
      case URL_MAP_ACTION_TYPE.PRODUCT_CATEGORY:
        list = (
          <Paper>
            <ProductCategoryList
              newFilter={{
                typeList: [cons.CHOICES.PRODUCT_CREATE.SELECT_CATEGORY_TYPE.PRODUCT, cons.CHOICES.PRODUCT_CREATE.SELECT_CATEGORY_TYPE.ALL],
              }}
              editBtn={false}
              createBtn={false}
              onClickListRow={(item) => onClickListRow(item)}
              dialogFooterBtn={
                <TempBtn
                  btnSetting={{
                    variant: cons.STYLE.VARIANT.CONTAINED,
                    color: cons.STYLE.PRIMARY,
                    onClick: onClickCloseDrawer,
                    icon: <ClearIcon />,
                    label: { key: 'close' },
                  }}
                />
              }
            />
          </Paper>
        )
        break
      case URL_MAP_ACTION_TYPE.E_COUPON_CATEGORY:
        list = (
          <Paper>
            <ProductCategoryList
              newFilter={{
                typeList: [cons.CHOICES.PRODUCT_CREATE.SELECT_CATEGORY_TYPE.FREE_ECOUPON, cons.CHOICES.PRODUCT_CREATE.SELECT_CATEGORY_TYPE.ALL],
              }}
              editBtn={false}
              createBtn={false}
              onClickListRow={(item) => onClickListRow(item)}
              dialogFooterBtn={
                <TempBtn
                  btnSetting={{
                    variant: cons.STYLE.VARIANT.CONTAINED,
                    color: cons.STYLE.PRIMARY,
                    onClick: onClickCloseDrawer,
                    icon: <ClearIcon />,
                    label: { key: 'close' },
                  }}
                />
              }
            />
          </Paper>
        )
        break
      case URL_MAP_ACTION_TYPE.PRODUCT:
        list = (
          <Paper>
            <ProductList
              newFilter={{ excludedTypeList: [cons.PRODUCT_TYPE.PACKAGE_SUB_ITEM, cons.PRODUCT_TYPE.FREE_ECOUPON_ITEM] }}
              editBtn={false}
              createBtn={false}
              onClickListRow={(item) => onClickListRow(item)}
              dialogFooterBtn={
                <TempBtn
                  btnSetting={{
                    variant: cons.STYLE.VARIANT.CONTAINED,
                    color: cons.STYLE.PRIMARY,
                    onClick: onClickCloseDrawer,
                    icon: <ClearIcon />,
                    label: { key: 'close' },
                  }}
                />
              }
            />
          </Paper>
        )
        break
      case URL_MAP_ACTION_TYPE.E_COUPON:
        list = (
          <Paper>
            <ProductList
              newFilter={{ type: cons.PRODUCT_TYPE.FREE_ECOUPON_ITEM, excludedTypeList: [cons.PRODUCT_TYPE.PACKAGE_SUB_ITEM] }}
              editBtn={false}
              createBtn={false}
              onClickListRow={(item) => onClickListRow(item)}
              dialogFooterBtn={
                <TempBtn
                  btnSetting={{
                    variant: cons.STYLE.VARIANT.CONTAINED,
                    color: cons.STYLE.PRIMARY,
                    onClick: onClickCloseDrawer,
                    icon: <ClearIcon />,
                    label: { key: 'close' },
                  }}
                />
              }
            />
          </Paper>
        )
        break
      case URL_MAP_ACTION_TYPE.PRODUCT_COUPON_CATEGORY_MAP:
        list = (
          <Paper>
            <ProductCategoryList
              newFilter={{
                productCode: savedUrl?.parameters?.productCode ? savedUrl?.parameters?.productCode : savedUrl?.parameters?.ecouponCode,
              }}
              newPath={cons.PATH.PRODUCT.PRODUCT_CATEGORY_MAP}
              editBtn={false}
              createBtn={false}
              onClickListRow={(item) => onClickListRow(item, URL_MAP_ACTION_TYPE.PRODUCT_COUPON_CATEGORY_MAP)}
              resource={resource}
              dialogFooterBtn={
                <TempBtn
                  btnSetting={{
                    variant: cons.STYLE.VARIANT.CONTAINED,
                    color: cons.STYLE.PRIMARY,
                    onClick: onClickCloseDrawer,
                    icon: <ClearIcon />,
                    label: { key: 'close' },
                  }}
                />
              }
            />
          </Paper>
        )
        break
      case URL_MAP_ACTION_TYPE.PRODUCT_COUPON_BRAND_MAP:
        list = (
          <Paper>
            <BrandList
              editBtn={false}
              createBtn={false}
              newPath={cons.PATH.PRODUCT.PRODUCT_BRAND_MAP}
              newFilter={{
                productCode: savedUrl?.parameters?.productCode ? savedUrl?.parameters?.productCode : savedUrl?.parameters?.ecouponCode,
              }}
              onClickListRow={(item) => onClickListRow(item, URL_MAP_ACTION_TYPE.PRODUCT_COUPON_BRAND_MAP)}
              dialogFooterBtn={
                <>
                  <TempBtn
                    btnSetting={{
                      variant: cons.STYLE.VARIANT.CONTAINED,
                      color: cons.STYLE.PRIMARY,
                      onClick: onClickCloseDrawer,
                      icon: <ClearIcon />,
                      label: { key: 'close' },
                    }}
                  />
                </>
              }
            />
          </Paper>
        )
        break
      case URL_MAP_ACTION_TYPE.PRODUCT_BRAND:
      case URL_MAP_ACTION_TYPE.E_COUPON_BRAND:
        list = (
          <Paper>
            <BrandList
              editBtn={false}
              createBtn={false}
              onClickListRow={(item) => onClickListRow(item)}
              dialogFooterBtn={
                <>
                  <TempBtn
                    btnSetting={{
                      variant: cons.STYLE.VARIANT.CONTAINED,
                      color: cons.STYLE.PRIMARY,
                      onClick: onClickCloseDrawer,
                      icon: <ClearIcon />,
                      label: { key: 'close' },
                    }}
                  />
                </>
              }
            />
          </Paper>
        )
        break
      case URL_MAP_ACTION_TYPE.FOLDER:
        list = (
          <Paper>
            <FolderList
              editBtn={false}
              createBtn={false}
              onClickListRow={(item) => onClickListRow(item)}
              dialogFooterBtn={
                <>
                  <TempBtn
                    btnSetting={{
                      variant: cons.STYLE.VARIANT.CONTAINED,
                      color: cons.STYLE.PRIMARY,
                      onClick: onClickCloseDrawer,
                      icon: <ClearIcon />,
                      label: { key: 'close' },
                    }}
                  />
                </>
              }
            />
          </Paper>
        )
        break
      case URL_MAP_ACTION_TYPE.REST:
        list = (
          <Paper>
            <RestaurantList
              editBtn={false}
              createBtn={false}
              btnList={false}
              onClickListRow={(item) => onClickListRow(item, URL_MAP_ACTION_TYPE.REST)}
              dialogFooterBtn={
                <>
                  <TempBtn
                    btnSetting={{
                      variant: cons.STYLE.VARIANT.CONTAINED,
                      color: cons.STYLE.PRIMARY,
                      onClick: onClickCloseDrawer,
                      icon: <ClearIcon />,
                      label: { key: 'close' },
                    }}
                  />
                </>
              }
            />
          </Paper>
        )
        break
      default:
        list = <></>
        break
    }
    return list
  }

  function getNewPayload(payload) {
    payload = triggerGetUrlObjValues(payload)
    payload = triggerGetUrlObjParameters(payload)
    return payload
  }

  function onClickSave() {
    const newSavedUrl = _.cloneDeep(savedUrl)

    UrlMapValidation.validateEditUrlMap(newSavedUrl, inputObj)
    if (UrlMapValidation.isValid()) {
      put(
        newPath ? newPath : cons.PATH.SHOP.URL_MAP + '/' + newSavedUrl.id,
        newSavedUrl,
        (payload) => {
          setDialog({
            open: true,
            onClose: onClickBackToEdit,
            dialogContentText: { key: 'editSuccess' },
            dialogActions: [
              { label: { key: 'backEdit' }, onClick: onClickBackToEdit, icon: <ArrowBackIcon /> },
              { label: { key: 'backList' }, onClick: onClickBackToList, icon: <LowPriorityIcon /> },
            ],
          })
          payload = getNewPayload(payload)
          setSavedUrl(payload)
          setClear(false)
        },
        undefined,
        store
      )
      UrlMapValidation.resetErrMsg()
    } else {
      setErrorVersion(UrlMapValidation.getVersion())
    }
  }

  function onClickClear() {
    setHiddenQrcode(false)
    setClear(true)
  }

  function triggerGetUrlObjValues(urlObj) {
    if (urlObj && (urlObj.actionType || urlObj.agentType === 'weChat')) {
      urlObj = getUrlObjValues(urlObj, inputObj)
    }
    return urlObj
  }

  function triggerGetUrlObjParameters(urlObj) {
    if (urlObj && (urlObj.url || urlObj.agentType === 'weChat')) {
      getUrlObjParameters(urlObj)
    } else {
      urlObj.parameters = {}
      urlObj.isHideCustomField = true
      urlObj.isDisabledCustomField = true
    }
    return urlObj
  }

  useEffect(() => {
    if (clear === true) {
      setInputObj({ from: '' })
      getOne(
        newPath || cons.PATH.SHOP.URL_MAP + '/' + params.id,
        undefined,
        (payload) => {
          if (payload.actionType === URL_MAP_ACTION_TYPE.CATEGORY_PRODUCT) {
            payload.actionType = URL_MAP_ACTION_TYPE.PRODUCT_CATEGORY
          }

          if (payload.actionType === URL_MAP_ACTION_TYPE.BRAND_PRODUCT) {
            payload.actionType = URL_MAP_ACTION_TYPE.PRODUCT_BRAND
          }

          if (payload.actionType === URL_MAP_ACTION_TYPE.CATEGORY) {
            payload.actionType = URL_MAP_ACTION_TYPE.PRODUCT_CATEGORY
          }

          if (payload.actionType === URL_MAP_ACTION_TYPE.BRAND) {
            payload.actionType = URL_MAP_ACTION_TYPE.PRODUCT_BRAND
          }

          const newPayload = getNewPayload(payload)

          const newInputObj = { ...defaultInputObj }

          if (newPayload.url && (newPayload.actionType === URL_MAP_ACTION_TYPE.CUSTOM_URL || newPayload.actionType === URL_MAP_ACTION_TYPE.REST)) {
            const urlStr = newPayload.url.split('?')[0] || ''
            const queryParamStr = newPayload.url.split('?')[1] || ''

            const keyValuePairs = checkIsUrlHasQueryParam(newPayload.url) ? parseQueryParamsStrToKeyValuePairs(queryParamStr) : []
            const filteredKeyValuePairs = keyValuePairs.filter((pair) => pair.key !== 'from')

            const newQueryParamStr = filteredKeyValuePairs.reduce((result, param, idx) => {
              const paramStr = `${encodeURIComponent(param.key)}=${encodeURIComponent(param.value)}`
              return idx === 0 ? paramStr : `${result}&${paramStr}`
            }, '')

            newPayload.customUrl = newQueryParamStr ? `${urlStr}?${newQueryParamStr}` : urlStr
            newInputObj.from = keyValuePairs.find((param) => param.key === 'from')?.value || ''
          }

          if (newPayload.url && newPayload.actionType === URL_MAP_ACTION_TYPE.REST) {
            const restaurantUrlType = getRestaurantUrlType(newPayload.url)
            const restUrlId = getRestUrlIdFromUrl(newPayload.url)

            newPayload.restaurantUrlType = restaurantUrlType
            newInputObj.realRestUrlId = restUrlId || ''
          }

          setInputObj(newInputObj)
          setSavedUrl(newPayload)
          setClear(false)
          setReady(true)
        },
        undefined,
        store
      )
    }
    UrlMapValidation.resetErrMsg()
  }, [clear]) // eslint-disable-line react-hooks/exhaustive-deps

  // useEffect(() => {
  //   console.log('savedUrl', savedUrl)
  // }, [savedUrl])

  // useEffect(() => {
  //   console.log('inputObj', inputObj)
  // }, [inputObj])

  return (
    ready && (
      <>
        <EditCreateDisplay
          code={savedUrl && (savedUrl[0] && savedUrl[0].id ? savedUrl[0].id : '')}
          resource={resource}
          tabs={tabs}
          content={content}
          onChangeInput={onChangeInput}
          bottomBtns={bottomBtns}
          mapName={mapName}
          mapCode={mapCode}
          display="edit"
        />
        <Drawer classes={{ paper: 'drawer-margin-left' }} anchor="right" open={drawerOpen} onClose={onClickCloseDrawer}>
          {List()}
        </Drawer>
        {dialog && dialog.open && <GlobalDialog dialogInfo={dialog} />}
      </>
    )
  )
}
