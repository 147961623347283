import { useState, useEffect } from 'react'
import { useDispatch, useSelector, useStore } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import _ from 'lodash'

import SaveIcon from '@mui/icons-material/Save'
import ClearIcon from '@mui/icons-material/Clear'
import LowPriorityIcon from '@mui/icons-material/LowPriority'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { constants as cons, PICTURE_SIZE } from '../../Constants/constants'
import { getOne, post, put } from '../../helper/baseFetch'
import { setFolder, setFolderValue } from '../../actions/folderAction'
import { setFolderMap } from '../../actions/folderMapAction'
import { setBannerList } from '../../actions/bannerAction'
import { EditCreateDisplay } from '../../Component/EditCreateDisplay/EditCreateDisplay'
import { TempTextInput } from '../../Component/Input/TempTextInput'
import { TempAutoSelectInput } from '../../Component/Input/TempAutoSelectInput'
import { TempCustomCheckBox } from '../../Component/Custom/TempCustomCheckBox'
import { FolderMapCreate } from './FolderMapCreate'
import { FolderBannerCreate } from './FolderBannerCreate'
import { UploadImage } from '../../Component/UploadImage/UploadImage'
import { getScDisabled, getString } from '../../helper/util'
import { GlobalDialog } from '../../Component/GlobalDialog'
import FolderValidation from '../../validations/folderValidation'
import { FOLDER_FIELDS_KEY as FIELDS_KEY } from '../../Constants/validationConstants'
import BannerValidation from '../../validations/bannerValidation'
import FolderMapValidation from '../../validations/folderMapValidation'
import GridContainer from '../../Component/common/field/GridContainer'
import { ErrorMsg } from '../../Component/ErrorMsg/ErrorMsg'
import { FolderMapList } from './FolderMapList'
import { BannerList } from '../Banner/BannerList'
import MUISelect from '../../Component/common/field/MUISelect'
import { STATUS_OPTIONS } from '../../Constants/option'
import { ArrowBack, Cached, LowPriority } from '@mui/icons-material'
import LocationFields, { LOCATION_FIELDS_KEY } from '../../Component/common/location/LocationFields'
import { getI18nObjValue } from '../../helper/i18n'

export default function Folder({ isCreateMode = true }) {
    const params = useParams()
    const dispatch = useDispatch()
    const store = useStore()
    const history = useHistory()
    const [clear, setClear] = useState(false)
    const [ready, setReady] = useState(false)
    const [dialog, setDialog] = useState({
        open: false,
        dialogActions: [],
    })
    const resource = 'Folder'
    const savedFolder = useSelector((state) => state.folder.ui.folder)
    const savedFolderMap = useSelector((state) => state.folderMap.ui.folderMap)
    const savedBannerList = useSelector((state) => state.banner.ui.bannerList)
    const guluLang = useSelector((state) => state.lang.ui.lang)
    const theme = useSelector((state) => state.theme.ui.theme)
    const [errorVersion, setErrorVersion] = useState(FolderValidation.getVersion())
    const [error, setError] = useState({})

    let tabs = [
        {
            label: { resource: resource, key: 'info' },
            tabIndex: cons.FOLDER_TAB.INFO,
        },
    ]

    let fields = [
        {
            label: { resource: resource, key: 'type' },
            value: savedFolder.type,
            type: cons.TYPE.SELECT,
            selectValue: cons.CHOICES.FOLDER.TYPE,
            name: 'type',
            isEndOfLine: true,
        },
        {
            label: { resource: resource, key: 'showHidden' },
            value: savedFolder.showHidden,
            type: cons.TYPE.SELECT,
            selectValue: cons.CHOICES.FOLDER.SHOW_HIDDEN,
            name: 'showHidden',
            isEndOfLine: true,
        },
        {
            label: { resource: resource, key: 'enName' },
            value: savedFolder.enName,
            name: 'enName',
            custom: true,
            customName: { key: 'nameCustomize' },
            customValue: savedFolder.scNameDisable,
            isEndOfLine: false,
            required: true,
            helperText: FolderValidation.getErrorMessages(FIELDS_KEY.enName),
        },
        {
            label: { resource: resource, key: 'tcName' },
            value: savedFolder.tcName,
            name: 'tcName',
            isEndOfLine: false,
            required: true,
            helperText: FolderValidation.getErrorMessages(FIELDS_KEY.tcName),
        },
        {
            label: { resource: resource, key: 'scName' },
            value: savedFolder.scName,
            name: 'scName',
            isEndOfLine: true,
            disabled: savedFolder.scNameDisable,
            required: true,
            helperText: FolderValidation.getErrorMessages(FIELDS_KEY.scName),
        },
        {
            label: { resource: resource, key: 'enDescriptionHtml' },
            value: savedFolder.enDescriptionHtml,
            name: 'enDescriptionHtml',
            custom: true,
            customName: { resource: resource, key: 'descriptionCustomize' },
            customValue: savedFolder.scDescriptionDisable,
            multiline: true,
            isEndOfLine: false,
        },
        {
            label: { resource: resource, key: 'tcDescriptionHtml' },
            value: savedFolder.tcDescriptionHtml,
            name: 'tcDescriptionHtml',
            translateName: savedFolder.scDescriptionDisable ? ['scDescriptionHtml'] : null,
            multiline: true,
            isEndOfLine: false,
        },
        {
            label: { resource: resource, key: 'scDescriptionHtml' },
            value: savedFolder.scDescriptionHtml,
            name: 'scDescriptionHtml',
            multiline: true,
            isEndOfLine: true,
            disabled: savedFolder.scDescriptionDisable,
        },
    ]

    if (isCreateMode) {
        fields = [
            {
                label: { resource: resource, key: 'folderCode' },
                value: savedFolder.folderCode,
                name: 'folderCode',
                isEndOfLine: false,
                required: true,
                helperText: FolderValidation.getErrorMessages(FIELDS_KEY.folderCode),
            },
            ...fields,
        ]
    }

    let content = [
        {
            tab: (
                <>
                    <LocationFields
                        savedData={savedFolder}
                        setCity={setCity}
                        setRegion={setRegion}
                        fields={[LOCATION_FIELDS_KEY.region, LOCATION_FIELDS_KEY.city]}
                        error={error}
                    />
                    {fields.map((setting, idx) => {
                        let content
                        switch (setting.type) {
                            case cons.TYPE.IMAGE:
                                content = (
                                    <div className='image'>
                                        <UploadImage
                                            picture={setting.picture}
                                            title={setting.label}
                                            name={setting.name}
                                            uploadImagePath={setting.uploadImagePath}
                                            saveImage={setting.saveImage}
                                            required={setting.required}
                                            showApplyToAll={true}
                                            size={PICTURE_SIZE.MID}
                                        />
                                    </div>
                                )
                                break
                            case cons.TYPE.SELECT:
                                content = (
                                    <TempAutoSelectInput
                                        inputSetting={setting}
                                        handleChange={onChangeInput}
                                    />
                                )
                                break
                            case cons.TYPE.TITLE:
                                content = (
                                    <div className={`${setting.className + ' sub-title'}`}>
                                        {getString(
                                            guluLang,
                                            setting.value.resource,
                                            setting.value.key,
                                            theme
                                        )}
                                    </div>
                                )
                                break
                            default:
                                content = (
                                    <TempTextInput
                                        inputSetting={setting}
                                        handleChange={onChangeInput}
                                    />
                                )
                        }
                        return (
                            <>
                                {setting.custom && (
                                    <TempCustomCheckBox
                                        className='customize-btn'
                                        value={setting.customValue}
                                        customName={setting.customName}
                                        onSelectCheckbox={onSelectCheckbox}
                                    />
                                )}
                                {content}
                                {setting.isEndOfLine && <div></div>}
                            </>
                        )
                    })}
                    <GridContainer>
                        {!isCreateMode && (
                            <MUISelect
                                onChange={(option) => onChangeInput('status', option.value)}
                                label='Status'
                                name='status'
                                value={savedFolder.status}
                                options={STATUS_OPTIONS}
                            />
                        )}
                    </GridContainer>
                    <div className='image'>
                        <UploadImage
                            picture={savedFolder.enImage}
                            title={{ resource: resource, key: 'enImage' }}
                            name='enImage'
                            uploadImagePath={cons.PATH.FOLDER.UPLOAD_FOLDER_IMAGE}
                            saveImage={onSaveImage}
                            required={true}
                            showApplyToAll={true}
                            size={PICTURE_SIZE.MID}
                        />
                    </div>
                    <div className='image'>
                        <UploadImage
                            picture={savedFolder.enImage}
                            title={{ resource: resource, key: 'tcImage' }}
                            name='tcImage'
                            uploadImagePath={cons.PATH.FOLDER.UPLOAD_FOLDER_IMAGE}
                            saveImage={onSaveImage}
                            required={true}
                            showApplyToAll={true}
                            size={PICTURE_SIZE.MID}
                        />
                    </div>
                    <div className='image'>
                        <UploadImage
                            picture={savedFolder.enImage}
                            title={{ resource: resource, key: 'scImage' }}
                            name='scImage'
                            uploadImagePath={cons.PATH.FOLDER.UPLOAD_FOLDER_IMAGE}
                            saveImage={onSaveImage}
                            required={true}
                            showApplyToAll={true}
                            size={PICTURE_SIZE.MID}
                        />
                    </div>
                    {FolderValidation.getErrorMessages(FIELDS_KEY.tcImage) && (
                        <ErrorMsg
                            errorMsg={FolderValidation.getErrorMessages(FIELDS_KEY.tcImage)}
                        />
                    )}
                    {FolderValidation.getErrorMessages(FIELDS_KEY.scImage) && (
                        <ErrorMsg
                            errorMsg={FolderValidation.getErrorMessages(FIELDS_KEY.scImage)}
                        />
                    )}
                    {FolderValidation.getErrorMessages(FIELDS_KEY.enImage) && (
                        <ErrorMsg
                            errorMsg={FolderValidation.getErrorMessages(FIELDS_KEY.enImage)}
                        />
                    )}
                    {isCreateMode && (
                        <>
                            <FolderMapCreate toolBar={false} createClear={clear} />
                            <FolderBannerCreate toolBar={false} createClear={clear} />
                        </>
                    )}
                </>
            ),
            btn: true,
        },
    ]

    let bottomBtns = [
        {
            variant: cons.STYLE.VARIANT.CONTAINED,
            color: cons.STYLE.PRIMARY,
            label: { key: 'save' },
            onClick: onClickSave,
            className: 'margin-left-16',
            icon: <SaveIcon />,
            disabled: Object.keys(error).length > 0,
        },
        {
            variant: cons.STYLE.VARIANT.CONTAINED,
            color: cons.STYLE.PRIMARY,
            label: { key: 'clear' },
            disabled: false,
            onClick: onClickClear,
            className: 'margin-left-16',
            icon: <ClearIcon />,
        },
    ]

    if (!isCreateMode) {
        content = [
            ...content,
            {
                tab: (
                    <FolderMapList
                        newFilter={{ folderId: params.id }}
                        editBtn={true}
                        createBtn={true}
                    />
                ),
                btn: false,
            },
            {
                tab: (
                    <BannerList
                        newListMainTitle={{ resource: resource, key: 'folderBannerList' }}
                        newFilter={{ id: params.id }}
                        newPath={cons.PATH.FOLDER.FOLDER_BANNER}
                        newListTitles={[
                            {
                                key: cons.LIST_DISPLAY_TYPE.IMAGE,
                                value: 'enImage',
                                sort: false,
                            },
                            {
                                key: cons.LIST_DISPLAY_TYPE.IMAGE,
                                value: 'tcImage',
                                sort: false,
                            },
                            {
                                key: cons.LIST_DISPLAY_TYPE.IMAGE,
                                value: 'scImage',
                                sort: false,
                            },
                            {
                                key: cons.LIST_DISPLAY_TYPE.STRING,
                                value: 'actionType',
                                sort: true,
                            },
                            {
                                key: cons.LIST_DISPLAY_TYPE.DATE_TIME,
                                value: 'startDisplayDate',
                                sort: false,
                            },
                            {
                                key: cons.LIST_DISPLAY_TYPE.DATE_TIME,
                                value: 'endDisplayDate',
                                sort: false,
                            },
                            {
                                key: cons.LIST_DISPLAY_TYPE.STRING,
                                value: 'sequence',
                                sort: true,
                            },
                            {
                                key: cons.LIST_DISPLAY_TYPE.STRING,
                                value: 'status',
                                sort: true,
                            },
                        ]}
                        editBtn={true}
                        createBtn={true}
                    />
                ),
                btn: false,
            },
        ]

        tabs = [
            ...tabs,
            {
                label: { resource: resource, key: 'folderMapList' },
                tabIndex: cons.FOLDER_TAB.FOLDER_MAP_LIST,
            },
            {
                label: { resource: resource, key: 'bannerList' },
                tabIndex: cons.FOLDER_TAB.BANNER_LIST,
            },
        ]

        bottomBtns = [
            ...bottomBtns,
            {
                className: 'special-button special-button-margin-left',
                variant: cons.STYLE.VARIANT.CONTAINED,
                icon: <Cached />,
                label: { key: 'clearRedisCache' },
                onClick: onClickClearRedisCache,
            },
        ]
    }

    function onSaveImage(name, image, index, applyToAll) {
        const newSavedFolder = _.cloneDeep(savedFolder)

        if (applyToAll) {
            newSavedFolder[FIELDS_KEY.enImage] = image
            newSavedFolder[FIELDS_KEY.scImage] = image
            newSavedFolder[FIELDS_KEY.tcImage] = image
            dispatch(setFolder(newSavedFolder))
            return
        }

        dispatch(setFolderValue(name, savedFolder, '', image))
    }

    function onSelectCheckbox(value, customName) {
        switch (customName.key) {
            case 'nameCustomize':
                dispatch(setFolderValue('scNameDisable', savedFolder, '', value))
                break
            case 'descriptionCustomize':
                dispatch(setFolderValue('scDescriptionDisable', savedFolder, '', value))
                break
            default:
        }
    }

    function onClickBackToCreateEdit() {
        setDialog({
            open: false,
            dialogActions: [],
        })
        onClickClear()
    }

    function onClickBackToList() {
        setDialog({
            open: false,
            dialogActions: [],
        })
        history.push('/' + cons.PATH.FOLDER.FOLDER)
    }

    function onClickClearRedisCache() {
        put(
            cons.PATH.FOLDER.CLEAR_REDIS_CACHE + '/' + savedFolder.folderCode,
            {},
            () => {
                setDialog({
                    open: true,
                    onClose: onClickBackToCreateEdit,
                    dialogContentText: { key: 'clearCacheSuccess' },
                    dialogActions: [
                        {
                            label: { key: 'backEdit' },
                            onClick: onClickBackToCreateEdit,
                            icon: <ArrowBackIcon />,
                        },
                        {
                            label: { key: 'backList' },
                            onClick: onClickBackToCreateEdit,
                            icon: <LowPriorityIcon />,
                        },
                    ],
                })
                setClear(false)
            },
            undefined,
            store
        )
    }

    function onChangeInput(name, e) {
        if (savedFolder.scNameDisable === true && name === 'tcName') {
            dispatch(setFolderValue('CHANGE_TC_NAME_AND_SC_NAME', savedFolder, '', e))
        } else if (savedFolder.scDescriptionDisable === true && name === 'tcDescriptionHtml') {
            dispatch(setFolderValue('CHANGE_TC_NAME_AND_SC_DESCRIPTION', savedFolder, '', e))
        } else {
            dispatch(setFolderValue(name, savedFolder, '', e))
        }
    }

    function onClickSave() {
        switch (isCreateMode) {
            case true:
                let newSavedFolder = _.cloneDeep(savedFolder)
                let newSavedFolderMap = _.cloneDeep(savedFolderMap)
                let newSavedBanner = _.cloneDeep(savedBannerList)

                newSavedBanner.map((item) => {
                    item.refCode = newSavedFolder.folderCode
                    return item
                })

                FolderValidation.validateCreateFolder(savedFolder, newSavedBanner)

                if (newSavedBanner.length > 0) {
                    BannerValidation.validateCreateBanner(newSavedBanner, true)
                }

                if (newSavedFolderMap.length > 0) {
                    FolderMapValidation.validateCreateFolderMap(newSavedFolderMap)
                }

                console.log('FolderValidation', FolderValidation)

                if (
                    FolderValidation.isValid() &&
                    (newSavedBanner.length > 0 ? BannerValidation.isValid() : true) &&
                    (newSavedFolderMap.length > 0 ? FolderMapValidation.isValid() : true)
                ) {
                    newSavedFolderMap = newSavedFolderMap.map((item) => {
                        return {
                            type: item.type,
                            sequence: item.sequence,
                            refCode: item.refCode,
                        }
                    })

                    newSavedFolder.folderMapList = newSavedFolderMap
                    newSavedFolder.bannerList = newSavedBanner

                    post(
                        cons.PATH.FOLDER.FOLDER,
                        newSavedFolder,
                        () => {
                            setDialog({
                                open: true,
                                onClose: onClickBackToCreateEdit,
                                dialogContentText: { key: 'createSuccess' },
                                dialogActions: [
                                    {
                                        label: { key: 'backCreate' },
                                        onClick: onClickBackToCreateEdit,
                                        icon: <ArrowBack />,
                                    },
                                    {
                                        label: { key: 'backList' },
                                        onClick: onClickBackToList,
                                        icon: <LowPriority />,
                                    },
                                ],
                            })
                            setClear(false)
                        },
                        undefined,
                        store
                    )

                    FolderValidation.resetErrorMessages()
                    BannerValidation.resetErrorMessages()
                    FolderMapValidation.resetErrorMessages()
                } else {
                    if (errorVersion !== FolderValidation.getVersion()) {
                        setErrorVersion(FolderValidation.getVersion())
                    } else if (errorVersion !== BannerValidation.getVersion()) {
                        setErrorVersion(BannerValidation.getVersion())
                    } else if (errorVersion !== FolderMapValidation.getVersion()) {
                        setErrorVersion(FolderMapValidation.getVersion())
                    }
                }
                break
            case false:
                FolderValidation.validateEditFolder(savedFolder)

                if (FolderValidation.isValid()) {
                    put(
                        cons.PATH.FOLDER.FOLDER + '/' + params.id,
                        savedFolder,
                        () => {
                            setDialog({
                                open: true,
                                onClose: onClickBackToCreateEdit,
                                dialogContentText: { key: 'editSuccess' },
                                dialogActions: [
                                    {
                                        label: { key: 'backEdit' },
                                        onClick: onClickBackToCreateEdit,
                                        icon: <ArrowBack />,
                                    },
                                    {
                                        label: { key: 'backList' },
                                        onClick: onClickBackToList,
                                        icon: <LowPriority />,
                                    },
                                ],
                            })
                            setClear(false)
                        },
                        undefined,
                        store
                    )
                    FolderValidation.resetErrorMessages()
                } else {
                    setErrorVersion(FolderValidation.getVersion())
                }
                break
            default:
                break
        }
    }

    function onClickClear() {
        setClear(true)
    }

    function reset() {
        dispatch(setFolder(_.cloneDeep(cons.FOLDER_INPUT)))
        dispatch(setFolderMap([]))
        dispatch(setFolderMap([]))
        dispatch(setBannerList([]))
        setClear(false)
        setReady(true)
        FolderValidation.resetErrorMessages()
        BannerValidation.resetErrorMessages()
    }

    function setCity(city) {
        dispatch(
            setFolder({
                ...savedFolder,
                cityCode: city?.cityCode || '',
                cityName: getI18nObjValue(guluLang, city?.name) || '',
            })
        )
    }

    function setRegion(region) {
        const isSelectedPreRegion = savedFolder?.regionCode === region?.regionCode

        dispatch(
            setFolder({
                ...savedFolder,
                regionCode: region?.regionCode || '',
                regionName: getI18nObjValue(guluLang, region?.name) || '',
                cityCode: isSelectedPreRegion ? savedFolder?.cityCode : null,
                cityName: isSelectedPreRegion ? savedFolder?.cityName : null,
            })
        )
    }

    function validateLocation() {
        let newError = {}

        if (!savedFolder.regionCode) {
            newError.regionCode = 'Missing Region Code'
        }

        if (!savedFolder.cityCode) {
            newError.cityCode = 'Missing City Code'
        }

        setError(newError)
    }

    useEffect(() => {
        validateLocation()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [savedFolder])

    useEffect(() => {
        if (isCreateMode) {
            reset()
        } else {
            getOne(
                cons.PATH.FOLDER.FOLDER + '/' + params.id,
                undefined,
                (payload) => {
                    payload.scNameDisable = getScDisabled(payload.tcName, payload.scName)
                    payload.scDescriptionDisable = getScDisabled(
                        payload.tcDescriptionHtml,
                        payload.scDescriptionHtml
                    )
                    dispatch(setFolder(_.cloneDeep(payload)))
                    setReady(true)
                    setClear(false)
                },
                undefined,
                store
            )
        }

        FolderMapValidation.resetErrorMessages()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clear])

    return (
        ready && (
            <>
                <EditCreateDisplay
                    code={isCreateMode ? '' : savedFolder?.folderCode}
                    resource={resource}
                    content={content}
                    onChangeInput={onChangeInput}
                    bottomBtns={bottomBtns}
                    display={isCreateMode ? 'create' : 'edit'}
                    tabs={tabs}
                />
                {dialog && dialog.open && <GlobalDialog dialogInfo={dialog} />}
            </>
        )
    )
}
