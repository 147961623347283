import _ from "lodash";
import { URL_MAP } from '../actions/types';
import { constants as cons } from '../Constants/constants';

const INITIAL_STATE = {
    ui: {
        // urlMap: _.cloneDeep(constants.URL_MAP_INPUT_LIST)
        urlMap: _.cloneDeep(cons.URL_MAP_INPUT)
    },
}

const urlMapReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case URL_MAP.SET_URL_MAP_DEFAULT:
            return Object.assign({}, INITIAL_STATE)
        case URL_MAP.SET_URL_MAP:
        case URL_MAP.SET_URL_MAP_VALUE:
            return Object.assign({}, {
                ...state,
                ui: {
                    ...state.ui,
                    urlMap: action.urlMap
                }
            })
        default:
            return state
    }
}

export default urlMapReducer