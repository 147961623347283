import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector, useStore } from 'react-redux'
import moment from 'moment'
import { sify } from 'chinese-conv'
import _ from 'lodash'

import { Drawer } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import LowPriorityIcon from '@mui/icons-material/LowPriority'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CancelIcon from '@mui/icons-material/Cancel'
import { makeStyles } from '@mui/styles'

import { constants as cons, IMAGE_TYPE } from '../../../Constants/constants'
import { post } from '../../../helper/baseFetch'
import { EditCreateDisplay } from '../../EditCreateDisplay/EditCreateDisplay'
import { TempBtn } from '../../Input/TempBtn'
import { TempCustomCheckBox } from '../../Custom/TempCustomCheckBox'
import { ProductCategoryList } from './ProductCategoryList'
import ProductCreateValidation from '../../../validations/productCreateValidation'
import { productValidationConstants } from '../../../Constants/validationConstants'
import { getString } from '../../../helper/util'
import { GlobalDialog } from '../../GlobalDialog'
import { ImageList } from '../../UploadImage/ImageList'
import { ProductMapCreate } from './ProductMapCreate'
import { getContent } from '../../EditCreateDisplay/EditUtil'
import { Clear, Launch, Save } from '@mui/icons-material'
import LocationFields, { LOCATION_FIELDS_KEY } from '../../common/location/LocationFields'
import { getI18nObjValue } from '../../../helper/i18n'

const helperTextStyles = makeStyles((theme) => ({
    root: {
        color: 'red !important',
    },
}))

export const ProductCategoryCreate = ({ listOverflow }) => {
    const store = useStore()
    const history = useHistory()
    const helperTextStylesClasses = helperTextStyles()
    const guluLang = useSelector((state) => state.lang.ui.lang)
    const theme = useSelector((state) => state.theme.ui.theme)
    // eslint-disable-next-line no-unused-vars
    const [errorVersion, setErrorVersion] = useState(ProductCreateValidation.getVersion())

    const [ready, setReady] = useState(false)
    const [clear, setClear] = useState(false)
    const [showCategoryDrawer, setShowCategoryDrawer] = useState(false)
    const [productCategory, setProductCategory] = useState({})
    const [dialog, setDialog] = useState({
        open: false,
        dialogActions: [],
    })

    const [error, setError] = useState({})

    const resource = 'productCreateProductCategoryList'
    const tabs = [
        {
            label: { resource: resource, key: 'info' },
            tabIndex: cons.PRODUCT_CAT_TAB.INFO,
        },
        // {
        //   label: { resource: resource, key: 'productMapList' },
        //   tabIndex: constants.PRODUCT_CAT_TAB.PRODUCT_MAP_LIST,
        // },
    ]

    const onClickRefCategoryCodeEndAdornmentIconButton = () => {
        setShowCategoryDrawer(true)
    }
    const onCloseCategoryDrawer = () => {
        setShowCategoryDrawer(false)
    }

    function onSelectCheckbox(value, customName) {
        const newSelectedRefCategory = _.cloneDeep(productCategory)
        switch (customName.key) {
            case 'nameCustomize':
                newSelectedRefCategory.scNameDisable = value
                if (value) {
                    newSelectedRefCategory.scName = newSelectedRefCategory.tcName
                        ? sify(newSelectedRefCategory.tcName)
                        : null
                }
                break
            case 'descriptionCustomize':
                newSelectedRefCategory.scDescriptionDisable = value
                if (value) {
                    newSelectedRefCategory.scDescription = newSelectedRefCategory.tcDescription
                        ? sify(newSelectedRefCategory.tcDescription)
                        : null
                }
                break
            default:
        }
        setProductCategory(newSelectedRefCategory)
    }

    const onClickBackToCreate = () => {
        setDialog({
            open: false,
            dialogActions: [],
        })
        onClickClear()
    }
    const onClickBackToList = () => {
        setDialog({
            open: false,
            dialogActions: [],
        })
        history.push('/' + cons.PATH.PRODUCT.PRODUCT_CATEGORY)
    }

    function setCity(city) {
        setProductCategory((pre) => ({
            ...pre,
            cityCode: city?.cityCode || '',
            cityName: getI18nObjValue(guluLang, city?.name) || '',
        }))
    }

    function setRegion(region) {
        const isSelectedPreRegion = productCategory.regionCode === region?.regionCode

        setProductCategory((pre) => ({
            ...pre,
            regionCode: region?.regionCode || '',
            regionName: getI18nObjValue(guluLang, region?.name) || '',
            cityCode: isSelectedPreRegion ? pre.cityCode : null,
            cityName: isSelectedPreRegion ? pre.cityName : null,
        }))
    }

    function onChangeInput(name, e) {
        if (e === '') {
            e = null
        }

        const newProductCategory = _.cloneDeep(productCategory)

        if (productCategory.scNameDisable && name === 'tcName') {
            newProductCategory.tcName = e
            newProductCategory.scName = e ? sify(e) : null
        } else if (productCategory.scDescriptionDisable && name === 'tcDescription') {
            newProductCategory.tcDescription = e
            newProductCategory.scDescription = e ? sify(e) : null
        } else if (name === 'startDisplayTimestamp' || name === 'endDisplayTimestamp') {
            newProductCategory[name] = Number(moment(e).format('x'))
        } else if (name === 'displaySequence') {
            newProductCategory.displaySequence = e ? Number(e) : 0
        } else if (name === 'channel') {
            newProductCategory.channel = e
        } else {
            newProductCategory[name] = e
        }

        setProductCategory(newProductCategory)
    }

    function onClearRefCategoryCode() {
        onChangeInput('refCategoryCode', '')
    }

    const fields = [
        {
            type: cons.TYPE.FUNCTION,
            value: <p className='sub-title'>Location</p>,
            isEndOfLine: true,
        },
        {
            type: cons.TYPE.FUNCTION,
            value: (
                <LocationFields
                    savedData={productCategory}
                    setCity={setCity}
                    setRegion={setRegion}
                    error={error}
                    fields={[LOCATION_FIELDS_KEY.region, LOCATION_FIELDS_KEY.city]}
                />
            ),
            isEndOfLine: true,
        },
        {
            type: cons.TYPE.TITLE,
            value: { resource: resource, key: 'name' },
            className: 'sub-title',
        },
        {
            className: 'edit-create-input',
            variant: cons.STYLE.VARIANT.STANDARD,
            size: cons.SIZE.SMALL,
            label: { resource: resource, key: 'type' },
            value: productCategory.type,
            type: cons.TYPE.SELECT,
            selectValue: cons.CHOICES.PRODUCT_CREATE.EDIT_PRODUCT.CATEGORY_TYPE,
            name: 'type',
            isEndOfLine: true,
            required: true,
            helperTextIcon: true,
            helperText: ProductCreateValidation.getErrorMessages(
                productValidationConstants.KEY_CATEGORY_TYPE,
                'category'
            ).map((error) => {
                return error
            }),
            FormHelperTextProps: { className: helperTextStylesClasses.root },
        },
        {
            className: 'edit-create-input',
            size: cons.SIZE.SMALL,
            label: { resource: resource, key: 'codeDescription' },
            value: productCategory.codeDescription,
            name: 'codeDescription',
            isEndOfLine: false,
            helperTextIcon: true,
            helperText: ProductCreateValidation.getErrorMessages(
                productValidationConstants.KEY_CATEGORY_DESCRIPTION,
                'category'
            ).map((error) => {
                return error
            }),
            FormHelperTextProps: { className: helperTextStylesClasses.root },
        },
        {
            className: 'edit-create-input',
            variant: null,
            size: cons.SIZE.SMALL,
            label: { resource: resource, key: 'channel' },
            value: productCategory.channel,
            type: cons.TYPE.SELECT,
            selectValue: cons.CHOICES.PRODUCT_CREATE.EDIT_PRODUCT.CHANNEL,
            name: 'channel',
            isEndOfLine: false,
            helperTextIcon: true,
            helperText: ProductCreateValidation.getErrorMessages(
                productValidationConstants.KEY_CATEGORY_CHANNEL,
                'category'
            ).map((error) => {
                return error
            }),
            FormHelperTextProps: { className: helperTextStylesClasses.root },
        },
        {
            className: 'edit-create-input',
            variant: null,
            size: cons.SIZE.SMALL,
            label: { resource: 'productCreateProductCategoryList', key: 'refCategoryCode' },
            onClickEndAdornmentIconButton: onClickRefCategoryCodeEndAdornmentIconButton,
            value: productCategory.refCategoryCode
                ? productCategory.refCategoryCode + '    ' + productCategory.refCategoryTcName
                : '',
            name: 'refCategoryCode',
            clear: onClearRefCategoryCode,
            disabled: true,
            isEndOfLine: true,
            endAdornmentIconButtonList: productCategory.id
                ? [
                      <Launch
                          fontSize={cons.SIZE.SMALL}
                          onClick={() =>
                              window.open(
                                  `${window.location.origin}/rack_product/category/${productCategory.id}/0`,
                                  '_blank'
                              )
                          }
                      />,
                  ]
                : [],
        },
        {
            className: 'edit-create-input',
            variant: null,
            size: cons.SIZE.SMALL,
            label: { resource: resource, key: 'enName' },
            value: productCategory.enName,
            name: 'enName',
            custom: true,
            customName: { key: 'nameCustomize' },
            customValue: productCategory.scNameDisable,
            isEndOfLine: false,
            helperTextIcon: true,
            helperText: ProductCreateValidation.getErrorMessages(
                productValidationConstants.KEY_CATEGORY_NAME_EN,
                'category'
            ).map((error) => {
                return error
            }),
            FormHelperTextProps: { className: helperTextStylesClasses.root },
        },
        {
            className: 'edit-create-input',
            size: cons.SIZE.SMALL,
            label: { resource: resource, key: 'tcName' },
            value: productCategory.tcName,
            name: 'tcName',
            translateName: productCategory.scNameDisable ? ['scName'] : null,
            inputList: productCategory,
            setInputList: setProductCategory,
            isEndOfLine: false,
            helperTextIcon: true,
            helperText: ProductCreateValidation.getErrorMessages(
                productValidationConstants.KEY_CATEGORY_NAME_TC,
                'category'
            ).map((error) => {
                return error
            }),
            FormHelperTextProps: { className: helperTextStylesClasses.root },
        },
        {
            className: 'edit-create-input',
            size: cons.SIZE.SMALL,
            label: { resource: resource, key: 'scName' },
            value: productCategory.scName,
            name: 'scName',
            inputList: productCategory,
            setInputList: setProductCategory,
            isEndOfLine: true,
            disabled: productCategory.scNameDisable,
            helperTextIcon: true,
            helperText: ProductCreateValidation.getErrorMessages(
                productValidationConstants.KEY_CATEGORY_NAME_SC,
                'category'
            ).map((error) => {
                return error
            }),
            FormHelperTextProps: { className: helperTextStylesClasses.root },
        },
        {
            className: 'edit-create-input',
            size: cons.SIZE.SMALL,
            label: { resource: resource, key: 'enDescription' },
            value: productCategory.enDescription,
            name: 'enDescription',
            custom: true,
            customName: { key: 'descriptionCustomize' },
            customValue: productCategory.scDescriptionDisable,
            isEndOfLine: false,
        },
        {
            className: 'edit-create-input',
            size: cons.SIZE.SMALL,
            label: { resource: resource, key: 'tcDescription' },
            value: productCategory.tcDescription,
            name: 'tcDescription',
            translateName: productCategory.scDescriptionDisable ? ['scDescription'] : null,
            inputList: productCategory,
            setInputList: setProductCategory,
            isEndOfLine: false,
        },
        {
            className: 'edit-create-input',
            size: cons.SIZE.SMALL,
            label: { resource: resource, key: 'scDescription' },
            value: productCategory.scDescription,
            name: 'scDescription',
            inputList: productCategory,
            setInputList: setProductCategory,
            isEndOfLine: true,
            disabled: productCategory.scDescriptionDisable,
        },
        {
            className: 'edit-create-input',
            size: cons.SIZE.SMALL,
            label: { resource: resource, key: 'startDisplayTimestamp' },
            value:
                productCategory.startDisplayTimestamp === 0
                    ? ''
                    : productCategory.startDisplayTimestamp,
            name: 'startDisplayTimestamp',
            type: cons.TYPE.DATETIME_LOCAL,
            step: 1,
            isEndOfLine: false,
            helperTextIcon: true,
            helperText: ProductCreateValidation.getErrorMessages(
                productValidationConstants.KEY_CATEGORY_START_DISPLAY_TIMESTAMP,
                'category'
            ).map((error) => {
                return error
            }),
            FormHelperTextProps: { className: helperTextStylesClasses.root },
        },
        {
            className: 'edit-create-input',
            size: cons.SIZE.SMALL,
            label: { resource: resource, key: 'endDisplayTimestamp' },
            value:
                productCategory.endDisplayTimestamp === 0
                    ? ''
                    : productCategory.endDisplayTimestamp,
            name: 'endDisplayTimestamp',
            type: cons.TYPE.DATETIME_LOCAL,
            step: 1,
            isEndOfLine: false,
            helperTextIcon: true,
            helperText: ProductCreateValidation.getErrorMessages(
                productValidationConstants.KEY_CATEGORY_END_DISPLAY_TIMESTAMP,
                'category'
            ).map((error) => {
                return error
            }),
            FormHelperTextProps: { className: helperTextStylesClasses.root },
        },
        {
            className: 'edit-create-input',
            size: cons.SIZE.SMALL,
            label: { resource: resource, key: 'displaySequence' },
            value: productCategory.displaySequence,
            type: cons.TYPE.NUMBER,
            min: 0,
            name: 'displaySequence',
            isEndOfLine: true,
            helperTextIcon: true,
            helperText: ProductCreateValidation.getErrorMessages(
                productValidationConstants.KEY_CATEGORY_DISPLAY_SEQUENCE,
                'category'
            ).map((error) => {
                return error
            }),
            FormHelperTextProps: { className: helperTextStylesClasses.root },
        },
        {
            className: `edit-create-input`,
            size: cons.SIZE.SMALL,
            label: { resource: resource, key: 'status' },
            value: productCategory.status,
            type: cons.TYPE.SELECT,
            selectValue: cons.CHOICES.PRODUCT_CREATE.CREATE_CATEGORY.STATUS,
            name: 'status',
            isEndOfLine: true,
        },
        {
            className: 'edit-create-input',
            size: cons.SIZE.SMALL,
            type: cons.TYPE.FUNCTION,
            value: (
                <>
                    <h3 variant={cons.STYLE.VARIANT.H6}>
                        {getString(
                            guluLang,
                            'productCreateProductCategoryList',
                            'imageList',
                            theme
                        )}
                    </h3>
                    {ProductCreateValidation.getErrorMessages(
                        productValidationConstants.KEY_CATEGORY_IMAGE_LIST,
                        'category'
                    ).map((error) => {
                        return error
                    }) &&
                        ProductCreateValidation.getErrorMessages(
                            productValidationConstants.KEY_CATEGORY_IMAGE_LIST,
                            'category'
                        ).map((error) => {
                            return error
                        }).length > 0 && (
                            <div className='error-message'>
                                <CancelIcon
                                    className='error-message-icon'
                                    fontSize={cons.SIZE.SMALL}
                                />
                                {ProductCreateValidation.getErrorMessages(
                                    productValidationConstants.KEY_CATEGORY_IMAGE_LIST,
                                    'category'
                                ).map((error) => {
                                    return error
                                })}
                            </div>
                        )}
                    {
                        <ImageList
                            uploadPath={cons.PATH.PRODUCT.UPLOAD_PRODUCT_IMAGE}
                            resource={'productCreateProductCategoryList'}
                            type={IMAGE_TYPE.CATEGORY_CREATE}
                            typeList={cons.CHOICES.PRODUCT_CREATE.CREATE_CATEGORY.CAT_IMAGE_TYPE}
                            savedData={productCategory}
                            helperTextStylesClasses={helperTextStylesClasses}
                            validation={ProductCreateValidation}
                            validationConstants={productValidationConstants}
                            hideTitle={true}
                            onUploadImageList={onUploadImageList}
                        />
                    }
                </>
            ),
            isEndOfLine: true,
        },
    ]

    const content = [
        {
            tab: (
                <div className={listOverflow}>
                    {fields.map((inputSetting, index) => {
                        let content = getContent(inputSetting, index, onChangeInput)
                        return (
                            <div className='edit-display-wrapper' key={index}>
                                {inputSetting.custom && (
                                    <TempCustomCheckBox
                                        className='customize-btn'
                                        value={inputSetting.customValue}
                                        customName={inputSetting.customName}
                                        onSelectCheckbox={onSelectCheckbox}
                                    />
                                )}
                                {content}
                                {inputSetting.isEndOfLine && <div></div>}
                            </div>
                        )
                    })}
                </div>
            ),
            btn: true,
        },
        {
            tab: <ProductMapCreate helperTextStylesClasses={helperTextStylesClasses} />,
            btn: false,
        },
    ]

    function onClickListRow(e) {
        const newProductCategory = _.cloneDeep(productCategory)
        newProductCategory.refCategoryCode = e.categoryCode
        newProductCategory.refCategoryTcName = e.tcName
        newProductCategory.id = e.id
        setProductCategory(newProductCategory)
        onCloseCategoryDrawer()
    }

    function onClickSave() {
        const productCreateForValidation = {
            category: productCategory,
        }
        ProductCreateValidation.validateCategoryOnly(productCreateForValidation)

        if (ProductCreateValidation.isValid()) {
            post(
                cons.PATH.PRODUCT.PRODUCT_CATEGORY,
                productCategory,
                (payload) => {
                    setDialog({
                        open: true,
                        onClose: onClickBackToCreate,
                        dialogContentText: { key: 'createSuccess' },
                        dialogActions: [
                            {
                                label: { key: 'backCreate' },
                                onClick: onClickBackToCreate,
                                icon: <ArrowBackIcon />,
                            },
                            {
                                label: { key: 'backList' },
                                onClick: onClickBackToList,
                                icon: <LowPriorityIcon />,
                            },
                        ],
                    })
                    setClear(false)
                },
                undefined,
                store
            )
            setErrorVersion(ProductCreateValidation.getVersion())
        } else {
            setErrorVersion(ProductCreateValidation.getVersion())
        }
    }

    function onUploadImageList(imageList) {
        const temptCategoryInputList = _.cloneDeep(productCategory)
        temptCategoryInputList.imageList = imageList
        setProductCategory(temptCategoryInputList)
    }

    function onClickClear() {
        setClear(true)
    }

    function validateProductCategory() {
        let newError = {}

        if (!productCategory?.regionCode) {
            newError.regionCode = 'Missing Region Code'
        }

        if (!productCategory?.cityCode) {
            newError.cityCode = 'Missing City Code'
        }

        setError(newError)
    }

    useEffect(() => {
        validateProductCategory()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productCategory])

    useEffect(() => {
        setProductCategory(cons.CATEGORY_INPUT)
        setReady(true)
        setClear(false)
    }, [clear === true]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        ready && (
            <div className='step-content-content-wrapper'>
                <EditCreateDisplay
                    code={productCategory.categoryCode ? productCategory.categoryCode : ''}
                    resource={resource}
                    tabs={tabs}
                    content={content}
                    onChangeInput={onChangeInput}
                    bottomBtns={[
                        {
                            variant: cons.STYLE.VARIANT.CONTAINED,
                            color: cons.STYLE.PRIMARY,
                            label: { key: 'save' },
                            onClick: onClickSave,
                            className: 'margin-left-16',
                            icon: <Save />,
                            disabled: Object.keys(error).length > 0
                        },
                        {
                            variant: cons.STYLE.VARIANT.CONTAINED,
                            color: cons.STYLE.PRIMARY,
                            label: { key: 'clear' },
                            disabled: false,
                            onClick: onClickClear,
                            className: 'margin-left-16',
                            icon: <Clear />,
                        },
                    ]}
                    display={'create'}
                />
                <Drawer
                    classes={{ paper: 'drawer-margin-left' }}
                    anchor='right'
                    open={showCategoryDrawer}
                    onClose={onCloseCategoryDrawer}
                >
                    <ProductCategoryList
                        editBtn={false}
                        createBtn={false}
                        onClickListRow={(item) => onClickListRow(item)}
                        newFilter={{ excludedId: '4bf617a1-28f7-4018-a257-f93253698d38' }}
                        dialogFooterBtn={
                            <TempBtn
                                btnSetting={{
                                    variant: cons.STYLE.VARIANT.CONTAINED,
                                    color: cons.STYLE.PRIMARY,
                                    onClick: onCloseCategoryDrawer,
                                    icon: <ClearIcon />,
                                    label: { key: 'close' },
                                }}
                            />
                        }
                    />
                </Drawer>
                {dialog && dialog.open && <GlobalDialog dialogInfo={dialog} />}
            </div>
        )
    )
}
