import _ from 'lodash'
import { PRODUCT } from '../actions/types'
import { constants } from '../Constants/constants'

const INITIAL_STATE = {
    ui: {
        product: _.cloneDeep(constants.PRODUCT_DRAFT),
        productStepError: [],
    },
}

const productReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case PRODUCT.SET_PRODUCT_DEFAULT:
            return Object.assign({}, INITIAL_STATE)
        case PRODUCT.SET_PRODUCT:
        case PRODUCT.SET_PRODUCT_VALUE:
            console.log('action', action)
            return Object.assign(
                {},
                {
                    ...state,
                    ui: {
                        ...state.ui,
                        product: action.product,
                    },
                }
            )
        case PRODUCT.SET_PRODUCT_STEP_ERROR_VALUE:
            return Object.assign(
                {},
                {
                    ...state,
                    ui: {
                        ...state.ui,
                        productStepError: action.productStepError,
                    },
                }
            )
        case PRODUCT.SET_PRODUCT_CATEGORY:
            return {
                ...state,
                ui: {
                    ...state.ui,
                    product: {
                        ...state.ui.product,
                        draft: {
                            ...state.ui.product.draft,
                            category: action.category,
                        },
                    },
                },
            }
        default:
            return state
    }
}

export default productReducer
