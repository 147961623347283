import _ from 'lodash'
import { useState, useEffect } from 'react'
import { useStore } from 'react-redux'
import { useParams } from 'react-router-dom'
import { constants as cons } from '../../Constants/constants'
import { getList } from '../../helper/baseFetch'
import { ListDisplay } from '../../Component/ListDisplay/ListDisplay'
import { generateTableCellToMultiLanguageName } from '../../helper/util'

export default function TicketTypeMapList({ newFilter }) {
  const store = useStore()
  const params = useParams()

  const resource = 'TicketTypeMapList'

  const [ticketTypeMapList, setTicketTypeMapList] = useState([])
  const [ready, setReady] = useState(false)

  const listTitles = [
    {
      key: cons.LIST_DISPLAY_TYPE.STRING,
      value: 'restUrlId',
      sort: true,
    },
    {
      key: cons.LIST_DISPLAY_TYPE.STRING,
      value: 'restName',
      sort: true,
    },
    {
      key: cons.LIST_DISPLAY_TYPE.STRING,
      value: 'queueTemplateCode',
      sort: false,
      newTabHyperLink: true,
    },
    {
      key: cons.LIST_DISPLAY_TYPE.STRING,
      value: 'queueTemplateTimeSectionId',
      sort: false,
    },
    {
      key: cons.LIST_DISPLAY_TYPE.STRING,
      value: 'queueTemplateTimeSectionLabel',
      sort: false,
    },
    {
      key: cons.LIST_DISPLAY_TYPE.STRING,
      value: 'queueTemplateTableType',
      sort: false,
    },
    {
      key: cons.LIST_DISPLAY_TYPE.STRING,
      value: 'queueTemplateTableTypeLabel',
      sort: false,
    },
  ]

  const listFilters = [
    {
      className: 'filter-input',
      label: { resource: resource, key: 'restUrlId' },
      clear: true,
      value: '',
      name: 'restUrlId',
    },
    {
      className: 'filter-input',
      label: { resource: resource, key: 'restName' },
      clear: true,
      value: '',
      name: 'restName',
    },
    // {
    //   className: 'filter-input',
    //   label: { resource: resource, key: 'queueTemplateCode' },
    //   clear: true,
    //   value: '',
    //   name: 'queueTemplateCode',
    // },
    // {
    //   className: 'filter-input',
    //   label: { resource: resource, key: 'queueTemplateTimeSectionId' },
    //   clear: true,
    //   value: '',
    //   name: 'queueTemplateTimeSectionId',
    // },
    // {
    //   className: 'filter-input',
    //   label: { resource: resource, key: 'queueTemplateTimeSectionLabel' },
    //   clear: true,
    //   value: '',
    //   name: 'queueTemplateTimeSectionLabel',
    // },
    // {
    //   className: 'filter-input',
    //   label: { resource: resource, key: 'queueTemplateTableType' },
    //   clear: true,
    //   value: '',
    //   name: 'queueTemplateTableType',
    // },
    // {
    //   className: 'filter-input',
    //   label: { resource: resource, key: 'queueTemplateTableTypeLabel' },
    //   clear: true,
    //   value: '',
    //   name: 'queueTemplateTableTypeLabel',
    // },
  ]

  const [filterOption, setFilterOption] = useState({
    path: '',
    page: 0,
    rowsPerPage: 10,
    sort: 'updateTimestamp',
    direction: 'DESC',
    listFilters: listFilters,
    count: 0,
  })

  const onChangeFilterOption = (filterOption) => {
    setFilterOption({ ...filterOption, count: filterOption.count + 1 })
  }

  const onChangeListFilters = (listFilters) => {
    onChangeFilterOption({ ...filterOption, listFilters: listFilters, page: 0 })
  }

  const onChangeListSort = (sort) => {
    onChangeFilterOption({ ...filterOption, sort: sort, direction: filterOption.direction === 'DESC' ? 'ASC' : 'DESC' })
  }

  const onChangeListPage = (page) => {
    onChangeFilterOption({ ...filterOption, page: page })
  }

  const onChangeListRowsPerPage = (rowsPerPage) => {
    onChangeFilterOption({ ...filterOption, page: 0, rowsPerPage: rowsPerPage })
  }

  useEffect(() => {
    let newFilterOption = _.cloneDeep(filterOption)

    let filter = newFilter || { id: params.id }

    newFilterOption.listFilters.forEach((item) => {
      if (item.value !== '' && item.value !== undefined) {
        filter[item.name] = item.value
      }
    })

    getList(
      cons.PATH.SHOP.QUEUE.TICKET_TYPE_MAP,
      {
        sort: { field: newFilterOption.sort, order: newFilterOption.direction },
        filter: filter,
        pagination: { page: newFilterOption.page + 1, perPage: newFilterOption.rowsPerPage },
      },
      (payload) => {
        payload.forEach((item) => {
          item.queueTemplateCodeNewTabHyperLink = `/shop/queue/queue_template/${item.queueTemplateId}/0`
          item.restName = generateTableCellToMultiLanguageName(item.restTcName, item.restEnName, item.restScName)
          item.queueTemplateTimeSectionLabel = generateTableCellToMultiLanguageName(
            item.queueTemplateTimeSectionTcLabel,
            item.queueTemplateTimeSectionEnLabel,
            item.queueTemplateTimeSectionScLabel
          )
          item.queueTemplateTableTypeLabel = generateTableCellToMultiLanguageName(
            item.queueTemplateTableTypeTcLabel,
            item.queueTemplateTableTypeEnLabel,
            item.queueTemplateTableTypeScLabel
          )
        })

        setTicketTypeMapList(payload)
        setReady(true)
      },
      undefined,
      store
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterOption.count])

  return (
    ready && (
      <ListDisplay
        page={filterOption.page}
        rowsPerPage={filterOption.rowsPerPage}
        onChangeListSort={onChangeListSort}
        resource={resource}
        dataList={ticketTypeMapList}
        sort={filterOption.sort}
        direction={filterOption.direction}
        listTitles={listTitles}
        listFilters={filterOption.listFilters}
        onChangeListPage={onChangeListPage}
        onChangeListRowsPerPage={onChangeListRowsPerPage}
        idParam={params.id}
        onChangeListFilters={onChangeListFilters}
        editBtn={false}
        createBtn={false}
      />
    )
  )
}
