import { useState, useEffect } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import { useParams, useHistory } from "react-router-dom";

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import LowPriorityIcon from '@mui/icons-material/LowPriority';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { makeStyles } from '@mui/styles';

import { constants, IMAGE_TYPE } from '../../Constants/constants';
import { getOne, put } from "../../helper/baseFetch";
import { setBrandApprove, setBrandApproveValue } from "../../actions/brandApproveAction";
import { EditCreateDisplay } from '../../Component/EditCreateDisplay/EditCreateDisplay';
import { TempTextInput } from "../../Component/Input/TempTextInput";
import { UploadImage } from "../../Component/UploadImage/UploadImage"
import { getString } from "../../helper/util";
import { GlobalDialog } from "../../Component/GlobalDialog";
import BrandApproveValidation from "../../validations/brandApproveValidation";
import { brandApproveValidationConstants } from '../../Constants/validationConstants';

const helperTextStyles = makeStyles(theme => ({
  root: {
    margin: 4,
    color: "red",
    fontSize: "15px"
  },
}));

export const BrandApproveEdit = () => {
  const dispatch = useDispatch();
  const store = useStore();
  const params = useParams();
  const history = useHistory();
  const [ready, setReady] = useState(false);
  const resource = "brandApprove";
  const savedBrandApprove = useSelector(state => state.brandApprove.ui.brandApprove)
  const lang = useSelector(state => state.lang.ui.lang);
  const theme = useSelector(state => state.theme.ui.theme);
  const helperTextStylesClasses = helperTextStyles();
  const [clear, setClear] = useState(true);
  const [dialog, setDialog] = useState({
    open: false,
    dialogActions: []
  });
  const [, setErrorVersion] = useState(BrandApproveValidation.getVersion());

  const tabs = [
    {
      label: { resource: resource, key: "info" },
      tabIndex: constants.BRAND_APPROVE_EDIT_TAB.INFO,
    },
  ]
  const tempBrandApproveEditList = [
    {
      type: constants.TYPE.TITLE,
      value: { resource: resource, key: "name" },
      className: "sub-title",
    },
    {
      className: "edit-create-input",
      variant: constants.STYLE.VARIANT.STANDARD,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "restUrlId" },
      value: savedBrandApprove.restUrlId,
      name: "restUrlId",
      type: constants.TYPE.TEXT,
      disabled: true,
      isEndOfLine: false,
    },
    {
      className: "edit-create-input",
      variant: constants.STYLE.VARIANT.STANDARD,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "status" },
      value: savedBrandApprove.status,
      name: "status",
      type: constants.TYPE.TEXT,
      disabled: true,
      isEndOfLine: true,
    },
    {
      className: "edit-create-input",
      variant: constants.STYLE.VARIANT.STANDARD,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "enName" },
      value: savedBrandApprove.enName,
      name: "enName",
      type: constants.TYPE.TEXT,
      disabled: true,
      isEndOfLine: false,
    },
    {
      className: "edit-create-input",
      variant: constants.STYLE.VARIANT.STANDARD,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "tcName" },
      value: savedBrandApprove.tcName,
      name: "tcName",
      type: constants.TYPE.TEXT,
      disabled: true,
      isEndOfLine: false,
    },
    {
      className: "edit-create-input",
      variant: constants.STYLE.VARIANT.STANDARD,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "scName" },
      value: savedBrandApprove.scName,
      name: "scName",
      type: constants.TYPE.TEXT,
      disabled: true,
      isEndOfLine: true,
    },
    {
      className: "edit-create-input",
      variant: constants.STYLE.VARIANT.STANDARD,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "enDescription" },
      value: savedBrandApprove.enDescription,
      name: "enDescription",
      type: constants.TYPE.TEXT,
      disabled: true,
      isEndOfLine: false,
    },
    {
      className: "edit-create-input",
      variant: constants.STYLE.VARIANT.STANDARD,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "tcDescription" },
      value: savedBrandApprove.tcDescription,
      name: "tcDescription",
      type: constants.TYPE.TEXT,
      disabled: true,
      isEndOfLine: false,
    },
    {
      className: "edit-create-input",
      variant: constants.STYLE.VARIANT.STANDARD,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "scDescription" },
      value: savedBrandApprove.scDescription,
      name: "scDescription",
      type: constants.TYPE.TEXT,
      disabled: true,
      isEndOfLine: true,
    },
    {
      type: constants.TYPE.IMAGE,
      picture: savedBrandApprove.logoImage,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "logoImage" },
      name: "logoImage",
      disableInput: true,
      isEndOfLine: true,
    },
    {
      type: constants.TYPE.IMAGE_MULTI,
      picture: savedBrandApprove.imageList,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "imageList" },
      name: "imageList",
      disableInput: true,
      isEndOfLine: false,
    },
    {
      type: constants.TYPE.TITLE,
      value: { resource: resource, key: "rejectReason" },
    },
    {
      className: "full-size-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "rejectReason" },
      value: savedBrandApprove.rejectReason,
      name: "rejectReason",
      type: constants.TYPE.TEXT,
      backgroundColorClass: true,
      helperTextIcon: true,
      helperText: BrandApproveValidation.getErrorMessages(brandApproveValidationConstants.KEY_REJECT_REASON),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
  ]
  const content = [
    {
      tab: tempBrandApproveEditList.map((inputSetting, index) => {
        let content = <></>;
        switch (inputSetting.type) {
          case constants.TYPE.TITLE:
            content =
              inputSetting.className ?
                <div className={`${inputSetting.className + " sub-title"}`}>{getString(lang, inputSetting.value.resource, inputSetting.value.key, theme)}</div>
                : <div className="line"><div className="sub-title">{getString(lang, inputSetting.value.resource, inputSetting.value.key, theme)}</div></div>
            break;
          case constants.TYPE.FUNCTION:
            content = inputSetting.value
            break;
          case constants.TYPE.TEXT:
            content =
              <TempTextInput inputSetting={inputSetting} handleChange={onChangeInput} />
            break;
          case constants.TYPE.DATETIME_LOCAL:
            content =
              <TempTextInput inputSetting={inputSetting} handleChange={onChangeInput} />
            break;
          case constants.TYPE.IMAGE:
            content =
              <div className="image">
                <UploadImage
                  picture={inputSetting.picture}
                  title={inputSetting.label}
                  name={inputSetting.name}
                  type = {IMAGE_TYPE.BRAND_APPROVE}
                  uploadImagePath={inputSetting.uploadImagePath}
                  saveImage={inputSetting.saveImage}
                  disableInput={inputSetting.disableInput} 
                  />
              </div>
            break;
          case constants.TYPE.IMAGE_MULTI:
            function handleContent(newPicture, index) {
              return <div key={index} className="image">
                <UploadImage
                  picture={newPicture}
                  title={inputSetting.label}
                  type = {IMAGE_TYPE.BRAND_APPROVE}
                  name={inputSetting.name}
                  uploadImagePath={inputSetting.uploadImagePath}
                  saveImage={inputSetting.saveImage}
                  disableInput={inputSetting.disableInput} />
              </div>
            }
            content = inputSetting.picture && inputSetting.picture.length > 0
              ? inputSetting.picture.map((pictureValue, index) => {
                return handleContent(pictureValue, index);
              })
              : handleContent();
            break;
          default:
            <></>
        }
        return <div className={inputSetting.backgroundColorClass ? "image-card-background card-border" : "edit-display-wrapper"} key={index}>
          {content}
          {inputSetting.isEndOfLine && <div></div>}
        </div>
      }),
      btn: true
    },
  ]
  const bottomBtns = [
    {
      className: "margin-left-16",
      disabled: savedBrandApprove.status !== constants.APPROVAL_STATUS.PENDING,
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: "approve" },
      onClick: handleApproveBrand,
      icon: <CheckIcon />
    },
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: "rejectBtn" },
      disabled: savedBrandApprove.status !== constants.APPROVAL_STATUS.PENDING,
      onClick: handleRejectBrand,
      className: "margin-left-16",
      icon: <CloseIcon />
    },
  ]
  const onClickBackToEdit = () => {
    setDialog({
      open: false,
      dialogActions: []
    });
    onClickClear();
  };
  const onClickBackToList = () => {
    setDialog({
      open: false,
      dialogActions: []
    })
    history.push("/" + constants.PATH.MERCHANT.BRAND);
  };

  function onChangeInput(name, e) {
    dispatch(setBrandApproveValue(name, savedBrandApprove, "", e));
  }

  function handleApproveBrand() {
    put(constants.PATH.MERCHANT.BRAND_APPROVE + "/" + params.id, {}, (payload) => {
      setDialog({
        open: true,
        onClose: onClickBackToEdit,
        dialogContentText: { key: "approveSuccess" },
        dialogActions: [
          { label: { key: "backEdit" }, onClick: onClickBackToEdit, icon: <ArrowBackIcon /> },
          { label: { key: "backList" }, onClick: onClickBackToList, icon: <LowPriorityIcon /> }
        ]
      })
      setClear(false)
    }, undefined, store);
  }

  function handleRejectBrand() {
    BrandApproveValidation.validateEditBrandApprove(savedBrandApprove);
    if (BrandApproveValidation.isValid()) {
      put(constants.PATH.MERCHANT.BRAND_REJECT + "/" + params.id, { rejectReason: savedBrandApprove.rejectReason }, (payload) => {
        setDialog({
          open: true,
          onClose: onClickBackToEdit,
          dialogContentText: { key: "rejectSuccess" },
          dialogActions: [
            { label: { key: "backEdit" }, onClick: onClickBackToEdit, icon: <ArrowBackIcon /> },
            { label: { key: "backList" }, onClick: onClickBackToList, icon: <LowPriorityIcon /> }
          ]
        })
        setClear(false)
      }, undefined, store);
      BrandApproveValidation.resetErrorMessages();
    } else {
      setErrorVersion(BrandApproveValidation.getVersion());
    }
  }

  function onClickClear() {
    setClear(true)
  }

  useEffect(() => {
    if (clear === true) {
      getOne(constants.PATH.MERCHANT.BRAND + "/" + params.id, undefined, (payload) => {
        dispatch(setBrandApprove(payload));
        setReady(true)
        setClear(false)
      }, undefined, store);
    }
    BrandApproveValidation.resetErrorMessages();
  }, [clear]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    ready &&
    <>
      <EditCreateDisplay
        code={savedBrandApprove && savedBrandApprove.id ? savedBrandApprove.id : ""}
        resource={resource}
        tabs={tabs}
        content={content}
        onChangeInput={onChangeInput}
        bottomBtns={bottomBtns}
        display="edit"
      />
      {dialog && dialog.open &&
        <GlobalDialog dialogInfo={dialog} />
      }
    </>
  )
};

