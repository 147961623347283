import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import _ from 'lodash'

import MTextField from '@mui/material/TextField'
import { makeStyles } from '@mui/styles'
import Chip from '@mui/material/Chip'
import Autocomplete from '@mui/lab/Autocomplete'

import { constants as cons } from '../../../Constants/constants'
import { setProduct, setProductValue } from '../../../actions/productCreateAction'
import { EditCreateDisplay } from '../../EditCreateDisplay/EditCreateDisplay'
import { TempTextInput } from '../../Input/TempTextInput'
import { TempAutoSelectInput } from '../../Input/TempAutoSelectInput'
import { TempBtn } from '../../Input/TempBtn'
import { TempCustomCheckBox } from '../../Custom/TempCustomCheckBox'
import ProductCreateValidation from '../../../validations/productCreateValidation'
import { productValidationConstants } from '../../../Constants/validationConstants'
import { ProductRedeemGroup } from './ProductRedeemGroup'
import { ProductBrandCode } from './ProductBrandCode'
import { ProductSystemGroupId } from './ProductSystemGroupId'
import { ProductEditCategory } from './ProductEditCategory'
import { SubProductMap } from './SubProductMap'
import { ProductType } from './ProductType'
import { getString } from '../../../helper/util'
import LocationFields, { LOCATION_FIELDS_KEY } from '../../common/location/LocationFields'
import { getI18nObjValue } from '../../../helper/i18n'

const helperTextStyles = makeStyles(() => ({
    root: {
        color: 'red !important',
    },
}))

const normalTextStyle = makeStyles(() => ({
    label: {
        '& .MuiFormLabel-root': {
            color: '#121212',
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#121212',
        },
        '& .MuiFormLabel-root.Mui-disabled': {
            color: 'rgba(18, 18, 18, 0.54)',
        },
        '& .MuiChip-outlined': {
            borderColor: '#121212',
            color: '#121212',
        },
    },
}))

// Product > Product > Edit Button > Product Tab
export const ProductTab = ({ listOverflow, disableInput, hiddenInput, isEmbeded, error }) => {
    const dispatch = useDispatch()
    const helperTextStylesClasses = helperTextStyles()
    const normalTextStyleClasses = normalTextStyle()
    const savedProduct = useSelector((state) => state.product.ui.product)
    const guluLang = useSelector((state) => state.lang.ui.lang)
    const theme = useSelector((state) => state.theme.ui.theme)
    const [productInfo, setProductInfo] = useState(_.cloneDeep(savedProduct.draft.product))
    const resource = 'productCreateProductList'

    // console.log('isEmbeded', isEmbeded)

    function onSelectCheckbox(value, customName) {
        switch (customName.key) {
            case 'nameCustomize':
                dispatch(
                    setProductValue(
                        cons.ACTION_KEY.PRODUCT.PRODUCT,
                        savedProduct,
                        'scNameDisable',
                        value
                    )
                )
                break
            case 'descriptionCustomize':
                dispatch(
                    setProductValue(
                        cons.ACTION_KEY.PRODUCT.PRODUCT,
                        savedProduct,
                        'scDescriptionDisable',
                        value
                    )
                )
                break
            case 'tacCustomize':
                dispatch(
                    setProductValue(
                        cons.ACTION_KEY.PRODUCT.PRODUCT,
                        savedProduct,
                        'scTacDisable',
                        value
                    )
                )
                break
            case 'redeemDescriptionCustomize':
                dispatch(
                    setProductValue(
                        cons.ACTION_KEY.PRODUCT.PRODUCT,
                        savedProduct,
                        'scRedeemDescriptionDisable',
                        value
                    )
                )
                break
            case 'discountDescriptionCustomize':
                dispatch(
                    setProductValue(
                        cons.ACTION_KEY.PRODUCT.PRODUCT,
                        savedProduct,
                        'scDiscountDescriptionDisable',
                        value
                    )
                )
                break
            default:
        }
    }

    function onChangeInput(name, e) {
        if (savedProduct.draft.product.scNameDisable && name === 'tcName') {
            dispatch(
                setProductValue(
                    cons.ACTION_KEY.PRODUCT.PRODUCT,
                    savedProduct,
                    'productNameTranslate',
                    e
                )
            )
        } else if (savedProduct.draft.product.scDescriptionDisable && name === 'tcDescription') {
            dispatch(
                setProductValue(
                    cons.ACTION_KEY.PRODUCT.PRODUCT,
                    savedProduct,
                    'productDescriptionTranslate',
                    e
                )
            )
        } else if (savedProduct.draft.product.scTacDisable && name === 'tcTac') {
            dispatch(
                setProductValue(
                    cons.ACTION_KEY.PRODUCT.PRODUCT,
                    savedProduct,
                    'productTacTranslate',
                    e
                )
            )
        } else if (
            savedProduct.draft.product.scRedeemDescriptionDisable &&
            name === 'tcRedeemDescription'
        ) {
            dispatch(
                setProductValue(
                    cons.ACTION_KEY.PRODUCT.PRODUCT,
                    savedProduct,
                    'productRedeemDescriptionTranslate',
                    e
                )
            )
        } else if (
            savedProduct.draft.product.scDiscountDescriptionDisable &&
            name === 'tcDiscountDescription'
        ) {
            dispatch(
                setProductValue(
                    cons.ACTION_KEY.PRODUCT.PRODUCT,
                    savedProduct,
                    'productDiscountDescriptionTranslate',
                    e
                )
            )
        } else if (name === 'startDisplayTimestamp' || name === 'endDisplayTimestamp') {
            dispatch(
                setProductValue(
                    cons.ACTION_KEY.PRODUCT.PRODUCT,
                    savedProduct,
                    name,
                    Number(moment(e).format('x'))
                )
            )
        } else if (name === 'dailyQuota') {
            if (!e) {
                dispatch(setProductValue(cons.ACTION_KEY.PRODUCT.PRODUCT, savedProduct, name, -1))
            } else {
                dispatch(
                    setProductValue(cons.ACTION_KEY.PRODUCT.PRODUCT, savedProduct, name, Number(e))
                )
            }
        } else if (
            name === 'displaySequence' ||
            name === 'originalPrice' ||
            name === 'sellingPrice' ||
            name === 'personLimit' ||
            name === 'quantityPerOrder' ||
            name === 'bufferRedeemDay'
        ) {
            if (e) {
                dispatch(
                    setProductValue(cons.ACTION_KEY.PRODUCT.PRODUCT, savedProduct, name, Number(e))
                )
            } else {
                dispatch(setProductValue(cons.ACTION_KEY.PRODUCT.PRODUCT, savedProduct, name, e))
            }
        } else {
            dispatch(setProductValue(cons.ACTION_KEY.PRODUCT.PRODUCT, savedProduct, name, e))
        }
    }

    function handleTcTag(event, value, reason) {
        dispatch(
            setProductValue(
                cons.ACTION_KEY.PRODUCT.PRODUCT,
                savedProduct,
                'tcTag',
                value.map((item) => item.trim()).join(',')
            )
        )
    }

    function handleEnTag(event, value, reason) {
        dispatch(
            setProductValue(
                cons.ACTION_KEY.PRODUCT.PRODUCT,
                savedProduct,
                'enTag',
                value.map((item) => item.trim()).join(',')
            )
        )
    }

    function handleScTag(event, value, reason) {
        dispatch(
            setProductValue(
                cons.ACTION_KEY.PRODUCT.PRODUCT,
                savedProduct,
                'scTag',
                value.map((item) => item.trim()).join(',')
            )
        )
    }

    const fields = [
        {
            size: cons.SIZE.SMALL,
            value: { resource: 'productCreateProductList', key: 'info' },
            name: 'info',
            type: cons.TYPE.TITLE,
            isEndOfLine: true,
            className: 'sub-title',
        },
        {
            className: 'edit-create-input',
            variant: cons.STYLE.VARIANT.STANDARD,
            label: { resource: resource, key: 'productCode' },
            value: savedProduct.draft.product.productCode,
            name: 'productCode',
            isEndOfLine: true,
            disabled: true,
            multiline: true,
        },
        {
            className: 'edit-create-input',
            type: cons.TYPE.FUNCTION,
            value: (
                <ProductType
                    disableInput={disableInput}
                    helperTextStylesClasses={helperTextStylesClasses}
                />
            ),
            isEndOfLine: false,
            helperTextIcon: true,
            helperText: ProductCreateValidation.getErrorMessages(
                productValidationConstants.KEY_PRODUCT_TYPE,
                savedProduct.draft.product.productCode
            ).map((error) => {
                return error
            }),
        },
        {
            className: 'edit-create-input',
            variant: disableInput ? cons.STYLE.VARIANT.STANDARD : null,
            size: cons.SIZE.SMALL,
            label: { resource: resource, key: 'channel' },
            value: savedProduct.draft.product.channel,
            type: disableInput ? '' : cons.TYPE.SELECT,
            selectValue: cons.CHOICES.PRODUCT_CREATE.CREATE_CATEGORY.CHANNEL,
            name: 'channel',
            isEndOfLine: false,
            disabled: disableInput,
            helperTextIcon: true,
            helperText: ProductCreateValidation.getErrorMessages(
                productValidationConstants.KEY_PRODUCT_CHANNEL,
                savedProduct.draft.product.productCode
            ).map((error) => {
                return error
            }),
            FormHelperTextProps: { className: helperTextStylesClasses.root },
        },
        {
            className: 'edit-create-input',
            size: cons.SIZE.SMALL,
            type: cons.TYPE.FUNCTION,
            value: <ProductRedeemGroup disableInput={disableInput} isEmbeded={isEmbeded} />,
            isEndOfLine: true,
        },
        {
            className: `edit-create-input ${
                !savedProduct.draft.product.id ||
                (savedProduct.draft.product.id &&
                    (savedProduct.draft.product.status === cons.PRODUCT_EDIT_STATUS.ACTIVE ||
                        savedProduct.draft.product.status ===
                            cons.PRODUCT_EDIT_STATUS.TO_BE_APPROVE))
                    ? 'display-none'
                    : ''
            }`,
            variant: disableInput ? cons.STYLE.VARIANT.STANDARD : null,
            size: cons.SIZE.SMALL,
            label: { resource: resource, key: 'status' },
            value: savedProduct.draft.product.status,
            type: disableInput ? '' : cons.TYPE.SELECT,
            selectValue: cons.CHOICES.PRODUCT_CREATE.CREATE_PRODUCT.EDIT_STATUS,
            name: 'status',
            isEndOfLine: false,
            disabled: disableInput,
        },
        {
            className: `edit-create-input ${
                !savedProduct.draft.product.id ||
                (savedProduct.draft.product.id &&
                    savedProduct.draft.product.status !== cons.PRODUCT_EDIT_STATUS.ACTIVE &&
                    savedProduct.draft.product.status !== cons.PRODUCT_EDIT_STATUS.TO_BE_APPROVE)
                    ? 'display-none'
                    : ''
            }`,
            variant: cons.STYLE.VARIANT.STANDARD,
            size: cons.SIZE.SMALL,
            label: { resource: resource, key: 'status' },
            value: savedProduct.draft.product.status,
            name: 'status',
            isEndOfLine: false,
            disabled: true,
        },
        {
            className: `edit-create-input ${!savedProduct.draft.product.id ? 'display-none' : ''}`,
            variant: cons.STYLE.VARIANT.STANDARD,
            size: cons.SIZE.SMALL,
            label: { resource: resource, key: 'merchantProduct' },
            value: savedProduct.draft.product.merchantProduct,
            name: 'merchantProduct',
            disabled: true,
        },
        {
            className: 'edit-create-input',
            size: cons.SIZE.SMALL,
            type: cons.TYPE.FUNCTION,
            value: (
                <>
                    <div className='brand-id-wrapper'>
                        <ProductSystemGroupId
                            disableInput={disableInput}
                            helperTextStylesClasses={helperTextStylesClasses}
                        />
                        <ProductBrandCode
                            disableInput={disableInput}
                            hiddenInput={hiddenInput}
                            savedData={savedProduct.draft.product ? savedProduct.draft.product : ''}
                            helperTextStylesClasses={helperTextStylesClasses}
                        />
                    </div>
                </>
            ),
            isEndOfLine: true,
        },
        {
            size: cons.SIZE.SMALL,
            value: { resource: 'productCreateProductList', key: 'name' },
            name: 'name',
            type: cons.TYPE.TITLE,
            isEndOfLine: true,
        },
        {
            className: 'edit-create-input',
            variant: disableInput ? cons.STYLE.VARIANT.STANDARD : null,
            size: cons.SIZE.SMALL,
            label: { resource: resource, key: 'enName' },
            value: savedProduct.draft.product.enName,
            name: 'enName',
            custom: true,
            customName: { resource: resource, key: 'nameCustomize' },
            customValue: savedProduct.draft.product.scNameDisable,
            isEndOfLine: false,
            disabled: disableInput,
            helperTextIcon: true,
            helperText: ProductCreateValidation.getErrorMessages(
                productValidationConstants.KEY_PRODUCT_EN_NAME,
                savedProduct.draft.product.productCode
            ).map((error) => {
                return error
            }),
            FormHelperTextProps: { className: helperTextStylesClasses.root },
            multiline: true,
        },
        {
            className: 'edit-create-input',
            variant: disableInput ? cons.STYLE.VARIANT.STANDARD : null,
            size: cons.SIZE.SMALL,
            label: { resource: resource, key: 'tcName' },
            value: savedProduct.draft.product.tcName,
            name: 'tcName',
            translateName: savedProduct.draft.product.scNameDisable ? ['scName'] : null,
            inputList: productInfo,
            setInputList: setProductInfo,
            isEndOfLine: false,
            disabled: disableInput,
            helperTextIcon: true,
            helperText: ProductCreateValidation.getErrorMessages(
                productValidationConstants.KEY_PRODUCT_TC_NAME,
                savedProduct.draft.product.productCode
            ).map((error) => {
                return error
            }),
            FormHelperTextProps: { className: helperTextStylesClasses.root },
            multiline: true,
        },
        {
            className: 'edit-create-input',
            variant: disableInput ? cons.STYLE.VARIANT.STANDARD : null,
            size: cons.SIZE.SMALL,
            label: { resource: resource, key: 'scName' },
            value: savedProduct.draft.product.scName,
            name: 'scName',
            inputList: productInfo,
            setInputList: setProductInfo,
            isEndOfLine: true,
            disabled:
                disableInput !== true ? savedProduct.draft.product.scNameDisable : disableInput,
            helperTextIcon: true,
            helperText: ProductCreateValidation.getErrorMessages(
                productValidationConstants.KEY_PRODUCT_SC_NAME,
                savedProduct.draft.product.productCode
            ).map((error) => {
                return error
            }),
            FormHelperTextProps: { className: helperTextStylesClasses.root },
            multiline: true,
        },
        {
            size: cons.SIZE.SMALL,
            value: { resource: 'productCreateProductList', key: 'description' },
            name: 'description',
            type: cons.TYPE.TITLE,
            isEndOfLine: true,
        },
        {
            className: 'edit-create-input',
            variant: disableInput ? cons.STYLE.VARIANT.STANDARD : null,
            size: cons.SIZE.SMALL,
            label: { resource: resource, key: 'enDescription' },
            value: savedProduct.draft.product.enDescription,
            name: 'enDescription',
            custom: true,
            customName: { resource: resource, key: 'descriptionCustomize' },
            isEndOfLine: false,
            customValue: savedProduct.draft.product.scDescriptionDisable,
            disabled: disableInput,
            multiline: true,
        },
        {
            className: 'edit-create-input',
            variant: disableInput ? cons.STYLE.VARIANT.STANDARD : null,
            size: cons.SIZE.SMALL,
            label: { resource: resource, key: 'tcDescription' },
            value: savedProduct.draft.product.tcDescription,
            name: 'tcDescription',
            translateName: savedProduct.draft.product.scDescriptionDisable
                ? ['scDescription']
                : null,
            inputList: productInfo,
            setInputList: setProductInfo,
            isEndOfLine: false,
            disabled: disableInput,
            multiline: true,
        },
        {
            className: 'edit-create-input',
            variant: disableInput ? cons.STYLE.VARIANT.STANDARD : null,
            size: cons.SIZE.SMALL,
            label: { resource: resource, key: 'scDescription' },
            value: savedProduct.draft.product.scDescription,
            name: 'scDescription',
            inputList: productInfo,
            setInputList: setProductInfo,
            isEndOfLine: true,
            disabled:
                disableInput !== true
                    ? savedProduct.draft.product.scDescriptionDisable
                    : disableInput,
            multiline: true,
        },
        {
            className: 'edit-create-input',
            variant: disableInput ? cons.STYLE.VARIANT.STANDARD : null,
            size: cons.SIZE.SMALL,
            label: { resource: resource, key: 'enTac' },
            value: savedProduct.draft.product.enTac,
            name: 'enTac',
            custom: true,
            customName: { resource: resource, key: 'tacCustomize' },
            customValue: savedProduct.draft.product.scTacDisable,
            isEndOfLine: false,
            disabled: disableInput,
            multiline: true,
        },
        {
            className: 'edit-create-input',
            variant: disableInput ? cons.STYLE.VARIANT.STANDARD : null,
            size: cons.SIZE.SMALL,
            label: { resource: resource, key: 'tcTac' },
            value: savedProduct.draft.product.tcTac,
            name: 'tcTac',
            translateName: savedProduct.draft.product.scTacDisable ? ['scTac'] : null,
            inputList: productInfo,
            setInputList: setProductInfo,
            isEndOfLine: false,
            disabled: disableInput,
            multiline: true,
        },
        {
            className: 'edit-create-input',
            variant: disableInput ? cons.STYLE.VARIANT.STANDARD : null,
            size: cons.SIZE.SMALL,
            label: { resource: resource, key: 'scTac' },
            value: savedProduct.draft.product.scTac,
            name: 'scTac',
            inputList: productInfo,
            setInputList: setProductInfo,
            isEndOfLine: true,
            disabled:
                disableInput !== true ? savedProduct.draft.product.scTacDisable : disableInput,
            multiline: true,
        },
        {
            className: 'edit-create-input',
            variant: disableInput ? cons.STYLE.VARIANT.STANDARD : null,
            size: cons.SIZE.SMALL,
            label: { resource: resource, key: 'enRedeemDescription' },
            value: savedProduct.draft.product.enRedeemDescription,
            name: 'enRedeemDescription',
            custom: true,
            customName: { resource: resource, key: 'redeemDescriptionCustomize' },
            customValue: savedProduct.draft.product.scRedeemDescriptionDisable,
            isEndOfLine: false,
            disabled: disableInput,
            multiline: true,
        },
        {
            className: 'edit-create-input',
            variant: disableInput ? cons.STYLE.VARIANT.STANDARD : null,
            size: cons.SIZE.SMALL,
            label: { resource: resource, key: 'tcRedeemDescription' },
            value: savedProduct.draft.product.tcRedeemDescription,
            name: 'tcRedeemDescription',
            translateName: savedProduct.draft.product.scRedeemDescriptionDisable
                ? ['scRedeemDescription']
                : null,
            inputList: productInfo,
            setInputList: setProductInfo,
            isEndOfLine: false,
            disabled: disableInput,
            multiline: true,
        },
        {
            className: 'edit-create-input',
            variant: disableInput ? cons.STYLE.VARIANT.STANDARD : null,
            size: cons.SIZE.SMALL,
            label: { resource: resource, key: 'scRedeemDescription' },
            value: savedProduct.draft.product.scRedeemDescription,
            name: 'scRedeemDescription',
            inputList: productInfo,
            setInputList: setProductInfo,
            isEndOfLine: true,
            disabled:
                disableInput !== true
                    ? savedProduct.draft.product.scRedeemDescriptionDisable
                    : disableInput,
            multiline: true,
        },
        {
            className: 'edit-create-input',
            variant: disableInput ? cons.STYLE.VARIANT.STANDARD : null,
            size: cons.SIZE.SMALL,
            label: { resource: resource, key: 'enDiscountDescription' },
            value: savedProduct.draft.product.enDiscountDescription,
            name: 'enDiscountDescription',
            custom: true,
            customName: { resource: resource, key: 'discountDescriptionCustomize' },
            customValue: savedProduct.draft.product.scDiscountDescriptionDisable,
            isEndOfLine: false,
            disabled: disableInput,
            multiline: true,
        },
        {
            className: 'edit-create-input',
            variant: disableInput ? cons.STYLE.VARIANT.STANDARD : null,
            size: cons.SIZE.SMALL,
            label: { resource: resource, key: 'tcDiscountDescription' },
            value: savedProduct.draft.product.tcDiscountDescription,
            name: 'tcDiscountDescription',
            translateName: savedProduct.draft.product.scDiscountDescriptionDisable
                ? ['scDiscountDescription']
                : null,
            inputList: productInfo,
            setInputList: setProductInfo,
            isEndOfLine: false,
            disabled: disableInput,
            multiline: true,
        },
        {
            className: 'edit-create-input',
            variant: disableInput ? cons.STYLE.VARIANT.STANDARD : null,
            size: cons.SIZE.SMALL,
            label: { resource: resource, key: 'scDiscountDescription' },
            value: savedProduct.draft.product.scDiscountDescription,
            name: 'scDiscountDescription',
            inputList: productInfo,
            setInputList: setProductInfo,
            isEndOfLine: true,
            disabled:
                disableInput !== true
                    ? savedProduct.draft.product.scDiscountDescriptionDisable
                    : disableInput,
            multiline: true,
        },
        {
            className: 'edit-create-input',
            size: cons.SIZE.SMALL,
            type: cons.TYPE.FUNCTION,
            value: (
                <div className='draft-name-wrapper'>
                    {disableInput ? (
                        <TempTextInput
                            inputSetting={{
                                className: 'edit-create-input',
                                variant: disableInput ? cons.STYLE.VARIANT.STANDARD : null,
                                size: cons.SIZE.SMALL,
                                label: { resource: 'productCreateProductList', key: 'enTag' },
                                value: savedProduct.draft.product.enTag,
                                disabled: disableInput,
                            }}
                        />
                    ) : (
                        <Autocomplete
                            className='edit-create-input'
                            size={cons.SIZE.SMALL}
                            multiple
                            options={[]}
                            freeSolo
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                    <Chip
                                        key={index}
                                        variant='outlined'
                                        color='primary'
                                        label={option}
                                        {...getTagProps({ index })}
                                    />
                                ))
                            }
                            renderInput={(params) => (
                                <MTextField
                                    {...params}
                                    className={normalTextStyleClasses.label}
                                    variant={cons.STYLE.VARIANT.OUTLINED}
                                    label={getString(
                                        guluLang,
                                        'productCreateProductList',
                                        'enTag',
                                        theme
                                    )}
                                />
                            )}
                            onChange={(chip, value, reason) => handleEnTag(chip, value, reason)}
                            value={
                                savedProduct.draft.product.enTag
                                    ? savedProduct.draft.product.enTag.split(',')
                                    : []
                            }
                            disabled={disableInput}
                        />
                    )}
                    {disableInput ? (
                        <TempTextInput
                            inputSetting={{
                                className: 'edit-create-input',
                                variant: disableInput ? cons.STYLE.VARIANT.STANDARD : null,
                                size: cons.SIZE.SMALL,
                                label: { resource: 'productCreateProductList', key: 'tcTag' },
                                value: savedProduct.draft.product.tcTag,
                                disabled: disableInput,
                            }}
                        />
                    ) : (
                        <Autocomplete
                            className='edit-create-input'
                            size={cons.SIZE.SMALL}
                            multiple
                            options={[]}
                            freeSolo
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                    <Chip
                                        key={index}
                                        variant='outlined'
                                        color='primary'
                                        label={option}
                                        {...getTagProps({ index })}
                                    />
                                ))
                            }
                            renderInput={(params) => (
                                <MTextField
                                    {...params}
                                    className={normalTextStyleClasses.label}
                                    variant={cons.STYLE.VARIANT.OUTLINED}
                                    label={getString(
                                        guluLang,
                                        'productCreateProductList',
                                        'tcTag',
                                        theme
                                    )}
                                />
                            )}
                            onChange={(chip, value, reason) => handleTcTag(chip, value, reason)}
                            value={
                                savedProduct.draft.product.tcTag
                                    ? savedProduct.draft.product.tcTag.split(',')
                                    : []
                            }
                            disabled={disableInput}
                        />
                    )}
                    {disableInput ? (
                        <TempTextInput
                            inputSetting={{
                                className: 'edit-create-input',
                                variant: disableInput ? cons.STYLE.VARIANT.STANDARD : null,
                                size: cons.SIZE.SMALL,
                                label: { resource: 'productCreateProductList', key: 'scTag' },
                                value: savedProduct.draft.product.scTag,
                                disabled: disableInput,
                            }}
                        />
                    ) : (
                        <Autocomplete
                            className='edit-create-input'
                            size={cons.SIZE.SMALL}
                            multiple
                            options={[]}
                            freeSolo
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                    <Chip
                                        key={index}
                                        variant='outlined'
                                        color='primary'
                                        label={option}
                                        {...getTagProps({ index })}
                                    />
                                ))
                            }
                            renderInput={(params) => (
                                <MTextField
                                    {...params}
                                    className={normalTextStyleClasses.label}
                                    variant={cons.STYLE.VARIANT.OUTLINED}
                                    label={getString(
                                        guluLang,
                                        'productCreateProductList',
                                        'scTag',
                                        theme
                                    )}
                                />
                            )}
                            onChange={(chip, value, reason) => handleScTag(chip, value, reason)}
                            value={
                                savedProduct.draft.product.scTag
                                    ? savedProduct.draft.product.scTag.split(',')
                                    : []
                            }
                            disabled={disableInput}
                        />
                    )}
                </div>
            ),
            isEndOfLine: true,
        },
        {
            size: cons.SIZE.SMALL,
            value: { resource: 'productCreateProductList', key: 'display' },
            name: 'display',
            type: cons.TYPE.TITLE,
            isEndOfLine: true,
        },
        {
            className: 'edit-create-input',
            variant: disableInput ? cons.STYLE.VARIANT.STANDARD : null,
            size: cons.SIZE.SMALL,
            label: { resource: resource, key: 'startDisplayTimestamp' },
            value: savedProduct.draft.product.startDisplayTimestamp,
            name: 'startDisplayTimestamp',
            type: cons.TYPE.DATETIME_LOCAL,
            step: 1,
            isEndOfLine: false,
            helperTextIcon: true,
            helperText: ProductCreateValidation.getErrorMessages(
                productValidationConstants.KEY_PRODUCT_START_DISPLAY_TIMESTAMP,
                savedProduct.draft.product.productCode
            ).map((error) => {
                return error
            }),
            FormHelperTextProps: { className: helperTextStylesClasses.root },
            disabled: disableInput,
        },
        {
            className: 'edit-create-input',
            variant: disableInput ? cons.STYLE.VARIANT.STANDARD : null,
            size: cons.SIZE.SMALL,
            label: { resource: resource, key: 'endDisplayTimestamp' },
            value: savedProduct.draft.product.endDisplayTimestamp,
            name: 'endDisplayTimestamp',
            type: cons.TYPE.DATETIME_LOCAL,
            step: 1,
            isEndOfLine: false,
            helperTextIcon: true,
            helperText: ProductCreateValidation.getErrorMessages(
                productValidationConstants.KEY_PRODUCT_END_DISPLAY_TIMESTAMP,
                savedProduct.draft.product.productCode
            ).map((error) => {
                return error
            }),
            FormHelperTextProps: { className: helperTextStylesClasses.root },
            disabled: disableInput,
        },
        {
            className: 'edit-create-input',
            variant: disableInput ? cons.STYLE.VARIANT.STANDARD : null,
            size: cons.SIZE.SMALL,
            label: { resource: resource, key: 'displaySequence' },
            value: savedProduct.draft.product.displaySequence,
            name: 'displaySequence',
            type: cons.TYPE.NUMBER,
            isEndOfLine: true,
            helperTextIcon: true,
            helperText: ProductCreateValidation.getErrorMessages(
                productValidationConstants.KEY_PRODUCT_DISPLAY_SEQUENCE,
                savedProduct.draft.product.productCode
            ).map((error) => {
                return error
            }),
            FormHelperTextProps: { className: helperTextStylesClasses.root },
            disabled: disableInput,
        },
        {
            size: cons.SIZE.SMALL,
            value: { resource: 'productCreateProductList', key: 'sellingInfo' },
            name: 'sellingInfo',
            type: cons.TYPE.TITLE,
            isEndOfLine: true,
        },
        {
            className: 'edit-create-input',
            variant: disableInput ? cons.STYLE.VARIANT.STANDARD : null,
            size: cons.SIZE.SMALL,
            label: { resource: resource, key: 'originalPrice' },
            value:
                savedProduct.draft.product.originalPrice === -1
                    ? ''
                    : savedProduct.draft.product.originalPrice,
            name: 'originalPrice',
            type: cons.TYPE.NUMBER,
            isEndOfLine: false,
            helperTextIcon: true,
            helperText: ProductCreateValidation.getErrorMessages(
                productValidationConstants.KEY_PRODUCT_ORIGINAL_PRICE,
                savedProduct.draft.product.productCode
            ).map((error) => {
                return error
            }),
            FormHelperTextProps: { className: helperTextStylesClasses.root },
            disabled:
                disableInput ||
                savedProduct.draft.product.type === cons.PRODUCT_TYPE.FREE_ECOUPON_ITEM,
        },
        {
            className: 'edit-create-input',
            variant: disableInput ? cons.STYLE.VARIANT.STANDARD : null,
            size: cons.SIZE.SMALL,
            label: { resource: resource, key: 'sellingPrice' },
            value:
                savedProduct.draft.product.sellingPrice === -1
                    ? ''
                    : savedProduct.draft.product.sellingPrice,
            name: 'sellingPrice',
            type: cons.TYPE.NUMBER,
            isEndOfLine: false,
            helperTextIcon: true,
            helperText: ProductCreateValidation.getErrorMessages(
                productValidationConstants.KEY_PRODUCT_SELLING_PRICE,
                savedProduct.draft.product.productCode
            ).map((error) => {
                return error
            }),
            FormHelperTextProps: { className: helperTextStylesClasses.root },
            disabled:
                disableInput ||
                savedProduct.draft.product.type === cons.PRODUCT_TYPE.FREE_ECOUPON_ITEM,
        },
        {
            className: 'edit-create-input',
            variant: disableInput ? cons.STYLE.VARIANT.STANDARD : null,
            size: cons.SIZE.SMALL,
            label: { resource: resource, key: 'personLimit' },
            value: savedProduct.draft.product.personLimit,
            name: 'personLimit',
            type: cons.TYPE.NUMBER,
            isEndOfLine: true,
            helperTextIcon: true,
            helperText: ProductCreateValidation.getErrorMessages(
                productValidationConstants.KEY_PRODUCT_PERSON_LIMIT,
                savedProduct.draft.product.productCode
            ).map((error) => {
                return error
            }),
            FormHelperTextProps: { className: helperTextStylesClasses.root },
            disabled: disableInput,
        },
        {
            className: 'edit-create-input',
            variant: disableInput ? cons.STYLE.VARIANT.STANDARD : null,
            size: cons.SIZE.SMALL,
            label: { resource: resource, key: 'quantityPerOrder' },
            value: savedProduct.draft.product.quantityPerOrder,
            name: 'quantityPerOrder',
            type: cons.TYPE.NUMBER,
            isEndOfLine: false,
            helperTextIcon: true,
            helperText: ProductCreateValidation.getErrorMessages(
                productValidationConstants.KEY_PRODUCT_QUANTITY_PER_ORDER,
                savedProduct.draft.product.productCode
            ).map((error) => {
                return error
            }),
            FormHelperTextProps: { className: helperTextStylesClasses.root },
            disabled: disableInput,
        },
        {
            className: 'edit-create-input',
            variant: disableInput ? cons.STYLE.VARIANT.STANDARD : null,
            size: cons.SIZE.SMALL,
            label: { resource: resource, key: 'dailyQuota' },
            value:
                savedProduct.draft.product.dailyQuota === -1
                    ? ''
                    : savedProduct.draft.product.dailyQuota,
            name: 'dailyQuota',
            type: cons.TYPE.NUMBER,
            isEndOfLine: false,
            helperTextIcon: true,
            helperText: ProductCreateValidation.getErrorMessages(
                productValidationConstants.KEY_PRODUCT_DAILY_QUOTA,
                savedProduct.draft.product.productCode
            ).map((error) => {
                return error
            }),
            FormHelperTextProps: { className: helperTextStylesClasses.root },
            disabled: disableInput,
        },
        {
            className: 'edit-create-input',
            variant: disableInput ? cons.STYLE.VARIANT.STANDARD : null,
            size: cons.SIZE.SMALL,
            label: { resource: resource, key: 'bufferRedeemDay' },
            value: savedProduct.draft.product.bufferRedeemDay,
            name: 'bufferRedeemDay',
            type: cons.TYPE.NUMBER,
            isEndOfLine: true,
            helperTextIcon: true,
            helperText: ProductCreateValidation.getErrorMessages(
                productValidationConstants.KEY_PRODUCT_BUFFER_REDEEM_DAY,
                savedProduct.draft.product.productCode
            ).map((error) => {
                return error
            }),
            FormHelperTextProps: { className: helperTextStylesClasses.root },
            disabled: disableInput,
        },
        {
            className: 'edit-create-input',
            variant: disableInput ? cons.STYLE.VARIANT.STANDARD : null,
            size: cons.SIZE.SMALL,
            label: { resource: resource, key: 'selfRedeem' },
            value: savedProduct.draft.product.selfRedeem,
            type: disableInput ? '' : cons.TYPE.SELECT,
            selectValue: cons.CHOICES.PRODUCT_CREATE.CREATE_PRODUCT.SELF_REDEEM,
            name: 'selfRedeem',
            isEndOfLine: false,
            disabled: disableInput,
            helperTextIcon: true,
            helperText: ProductCreateValidation.getErrorMessages(
                productValidationConstants.KEY_PRODUCT_SELF_REDEEM,
                savedProduct.draft.product.productCode
            ).map((error) => {
                return error
            }),
            FormHelperTextProps: { className: helperTextStylesClasses.root },
        },
        {
            className: 'edit-create-input',
            variant: disableInput ? cons.STYLE.VARIANT.STANDARD : null,
            size: cons.SIZE.SMALL,
            label: { resource: resource, key: 'selfRefund' },
            value: savedProduct.draft.product.selfRefund,
            type: disableInput ? '' : cons.TYPE.SELECT,
            selectValue: cons.CHOICES.PRODUCT_CREATE.CREATE_PRODUCT.SELF_REFUND,
            name: 'selfRefund',
            isEndOfLine: false,
            disabled: disableInput,
            helperTextIcon: true,
            helperText: ProductCreateValidation.getErrorMessages(
                productValidationConstants.KEY_PRODUCT_SELF_REFUND,
                savedProduct.draft.product.productCode
            ).map((error) => {
                return error
            }),
            FormHelperTextProps: { className: helperTextStylesClasses.root },
        },
        {
            className: 'edit-create-input',
            variant:
                disableInput ||
                savedProduct.draft.product.type === cons.PRODUCT_TYPE.PACKAGE_SUB_ITEM
                    ? cons.STYLE.VARIANT.STANDARD
                    : null,
            size: cons.SIZE.SMALL,
            label: { resource: resource, key: 'searchable' },
            value: savedProduct.draft.product.searchable,
            type:
                disableInput ||
                savedProduct.draft.product.type === cons.PRODUCT_TYPE.PACKAGE_SUB_ITEM
                    ? ''
                    : cons.TYPE.SELECT,
            selectValue: cons.CHOICES.PRODUCT_CREATE.CREATE_PRODUCT.SEARCHABLE,
            name: 'searchable',
            isEndOfLine: true,
            disabled:
                disableInput ||
                savedProduct.draft.product.type === cons.PRODUCT_TYPE.PACKAGE_SUB_ITEM
                    ? true
                    : false,
            helperTextIcon: true,
            helperText: ProductCreateValidation.getErrorMessages(
                productValidationConstants.KEY_PRODUCT_SEARCHABLE,
                savedProduct.draft.product.productCode
            ).map((error) => {
                return error
            }),
            FormHelperTextProps: { className: helperTextStylesClasses.root },
        },
        {
            className: 'edit-create-input',
            variant:
                savedProduct.draft.product.type === cons.PRODUCT_TYPE.PACKAGE_ITEM || disableInput
                    ? cons.STYLE.VARIANT.STANDARD
                    : null,
            size: cons.SIZE.SMALL,
            label: { resource: resource, key: 'shareable' },
            value: savedProduct.draft.product.shareable,
            type:
                savedProduct.draft.product.type === cons.PRODUCT_TYPE.PACKAGE_ITEM || disableInput
                    ? ''
                    : cons.TYPE.SELECT,
            selectValue: cons.CHOICES.PRODUCT_CREATE.CREATE_PRODUCT.SHAREABLE,
            name: 'shareable',
            isEndOfLine: false,
            disabled:
                savedProduct.draft.product.type === cons.PRODUCT_TYPE.PACKAGE_ITEM || disableInput
                    ? true
                    : false,
            helperTextIcon: true,
            helperText: ProductCreateValidation.getErrorMessages(
                productValidationConstants.KEY_PRODUCT_SHAREABLE,
                savedProduct.draft.product.productCode
            ).map((error) => {
                return error
            }),
            FormHelperTextProps: { className: helperTextStylesClasses.root },
        },
        {
            className: 'edit-create-input',
            variant:
                savedProduct.draft.product.type !== cons.PRODUCT_TYPE.PACKAGE_ITEM || disableInput
                    ? cons.STYLE.VARIANT.STANDARD
                    : null,
            size: cons.SIZE.SMALL,
            label: { resource: resource, key: 'packageCashCoupon' },
            value: savedProduct.draft.product.packageCashCoupon,
            type:
                savedProduct.draft.product.type !== cons.PRODUCT_TYPE.PACKAGE_ITEM || disableInput
                    ? ''
                    : cons.TYPE.SELECT,
            selectValue: cons.CHOICES.PRODUCT_CREATE.CREATE_PRODUCT.PACKAGE_CASH_COUPON,
            name: 'packageCashCoupon',
            isEndOfLine: true,
            disabled:
                savedProduct.draft.product.type !== cons.PRODUCT_TYPE.PACKAGE_ITEM || disableInput
                    ? true
                    : false,
            helperTextIcon: true,
            helperText: ProductCreateValidation.getErrorMessages(
                productValidationConstants.KEY_PRODUCT_PACKAGE_CASH_COUPON,
                savedProduct.draft.product.productCode
            ).map((error) => {
                return error
            }),
            FormHelperTextProps: { className: helperTextStylesClasses.root },
        },
        {
            variant: disableInput ? cons.STYLE.VARIANT.STANDARD : null,
            label: { resource: resource, key: 'requireFillInContactPerson' },
            value: savedProduct.draft.product.requireFillInContactPerson,
            type: disableInput ? '' : cons.TYPE.SELECT,
            selectValue: cons.CHOICES.PRODUCT_CREATE.CREATE_PRODUCT.REQUIRE_FILL_IN_CONTACT_PERSON,
            name: 'requireFillInContactPerson',
            isEndOfLine: false,
            disabled: disableInput,
            helperTextIcon: true,
            helperText: ProductCreateValidation.getErrorMessages(
                productValidationConstants.KEY_PRODUCT_REQUIRE_FILL_IN_CONTACT_PERSON,
                savedProduct.draft.product.productCode
            ).map((error) => {
                return error
            }),
        },
        {
            className: 'edit-create-input',
            variant: disableInput ? cons.STYLE.VARIANT.STANDARD : null,
            size: cons.SIZE.SMALL,
            label: { resource: resource, key: 'requireFillInMobileNumber' },
            value: savedProduct.draft.product.requireFillInMobileNumber,
            type: disableInput ? '' : cons.TYPE.SELECT,
            selectValue: cons.CHOICES.PRODUCT_CREATE.CREATE_PRODUCT.REQUIRE_FILL_IN_MOBILE_NUMBER,
            name: 'requireFillInMobileNumber',
            isEndOfLine: false,
            disabled: disableInput,
            helperTextIcon: true,
            helperText: ProductCreateValidation.getErrorMessages(
                productValidationConstants.KEY_PRODUCT_REQUIRE_FILL_IN_MOBILE_NUMBER,
                savedProduct.draft.product.productCode
            ).map((error) => {
                return error
            }),
            FormHelperTextProps: { className: helperTextStylesClasses.root },
        },
        {
            variant: disableInput ? cons.STYLE.VARIANT.STANDARD : null,
            label: { resource: resource, key: 'requireFillInEmail' },
            value: savedProduct.draft.product.requireFillInEmail,
            type: disableInput ? '' : cons.TYPE.SELECT,
            selectValue: cons.CHOICES.PRODUCT_CREATE.CREATE_PRODUCT.REQUIRE_FILL_IN_EMAIL,
            name: 'requireFillInEmail',
            isEndOfLine: true,
            disabled: disableInput,
            helperTextIcon: true,
            helperText: ProductCreateValidation.getErrorMessages(
                productValidationConstants.KEY_PRODUCT_REQUIRE_FILL_IN_EMAIL,
                savedProduct.draft.product.productCode
            ).map((error) => {
                return error
            }),
            FormHelperTextProps: { className: helperTextStylesClasses.root },
        },
        {
            type: cons.TYPE.FUNCTION,
            value: (
                <SubProductMap
                    hiddenInput={hiddenInput}
                    disableInput={
                        savedProduct.draft.product.redeemCodeApiType ===
                        cons.LOT_INVENTORY_TYPE.MEZZOFY
                            ? true
                            : disableInput
                    }
                    helperTextStylesClasses={helperTextStylesClasses}
                />
            ),
            isEndOfLine: true,
        },
        {
            type: cons.TYPE.FUNCTION,
            value: (
                <ProductEditCategory
                    hiddenInput={hiddenInput}
                    disableInput={disableInput}
                    helperTextStylesClasses={helperTextStylesClasses}
                />
            ),
            isEndOfLine: true,
        },
    ]

    const content = [
        {
            tab: (
                <div className={listOverflow}>
                    <>
                        <p className='sub-title'>Location</p>
                        <LocationFields
                            savedData={savedProduct?.draft?.product}
                            setRegion={setRegion}
                            setCity={setCity}
                            error={error}
                            fields={[LOCATION_FIELDS_KEY.region, LOCATION_FIELDS_KEY.city]}
                        />
                    </>
                    {fields.map((setting, idx) => {
                        let content
                        switch (setting.type) {
                            case cons.TYPE.SELECT:
                                content = (
                                    <TempAutoSelectInput
                                        inputSetting={setting}
                                        handleChange={onChangeInput}
                                    />
                                )
                                break
                            case cons.TYPE.FUNCTION:
                                content = setting.value
                                break
                            case cons.TYPE.BUTTON:
                                content = <TempBtn btnSetting={setting} />
                                break
                            case cons.TYPE.TITLE:
                                content = setting.value ? (
                                    <div className='sub-title'>
                                        {getString(
                                            guluLang,
                                            setting.value.resource,
                                            setting.value.key,
                                            theme
                                        )}
                                    </div>
                                ) : (
                                    <></>
                                )
                                break
                            default:
                                content = (
                                    <TempTextInput
                                        inputSetting={setting}
                                        handleChange={onChangeInput}
                                    />
                                )
                        }
                        return (
                            <div className='edit-display-wrapper' key={idx}>
                                {!disableInput && setting.custom && (
                                    <TempCustomCheckBox
                                        className='customize-btn'
                                        value={setting.customValue}
                                        customName={setting.customName}
                                        onSelectCheckbox={onSelectCheckbox}
                                    />
                                )}
                                {content}
                                {setting.isEndOfLine && <div></div>}
                            </div>
                        )
                    })}
                </div>
            ),
            btn: false,
        },
    ]

    function setRegion(region) {
        const isSelectedPreRegion = savedProduct?.draft?.product?.regionCode === region?.regionCode

        dispatch(
            setProduct({
                ...savedProduct,
                draft: {
                    ...savedProduct.draft,
                    product: {
                        ...savedProduct.draft.product,
                        regionName: getI18nObjValue(guluLang, region?.name) || '',
                        regionCode: region?.regionCode || '',
                        cityName: isSelectedPreRegion ? savedProduct?.draft?.product?.cityName : null,
                        cityCode: isSelectedPreRegion ? savedProduct?.draft?.product?.cityCode : null,
                    },
                },
            })
        )
    }

    function setCity(city) {
        dispatch(
            setProduct({
                ...savedProduct,
                draft: {
                    ...savedProduct.draft,
                    product: {
                        ...savedProduct.draft.product,
                        cityName: getI18nObjValue(guluLang, city?.name) || '',
                        cityCode: city?.cityCode || '',
                    },
                },
            })
        )
    }

    useEffect(() => {
        console.log('savedProduct', savedProduct)
    }, [savedProduct])

    return (
        <EditCreateDisplay
            code={
                savedProduct.draft.category.categoryCode
                    ? savedProduct.draft.category.categoryCode
                    : ''
            }
            resource={resource}
            tabs={false}
            content={content}
            onChangeInput={onChangeInput}
            saveDialog={{
                open: false,
                close: () => {},
                dialogActions: [],
            }}
            display='none'
        />
    )
}
