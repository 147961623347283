import { enMessages } from '../i18n/en'
import { tcMessages } from '../i18n/tc'
import { scMessages } from '../i18n/sc'
import { enMyMessages } from '../i18n/enMy'
import { tcMyMessages } from '../i18n/tcMy'
import { scMyMessages } from '../i18n/scMy'
import { constants as cons } from '../Constants/constants'
import { sify } from 'chinese-conv'
import queryString from 'query-string'
import moment from 'moment'

import variables from '../styles/globalVariables.scss'
import { makeStyles } from '@mui/styles'
const { errorColor } = variables

export function isBlank(value) {
    return value === null || value === undefined
}

export const validateEmail = (email) => {
    return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)(\.[a-zA-Z0-9-]+){0,1}$/i.test(
        email
    )
}

export function addSpace(value, spaceValue) {
    return value.replaceAll(spaceValue, spaceValue + ' ')
}

export const tableColor = (tableType) => {
    switch (tableType) {
        case 'B':
        case 'H':
        case 'N':
        case 'T':
        case 'Z':
            return 'B'
        case 'C':
        case 'I':
        case 'O':
        case 'U':
            return 'C'
        case 'D':
        case 'J':
        case 'P':
        case 'V':
            return 'D'
        case 'E':
        case 'K':
        case 'Q':
        case 'W':
            return 'E'
        case 'F':
        case 'L':
        case 'R':
        case 'X':
            return 'F'
        default:
            return 'A'
    }
}

export const getTableTypeForegroundColor = (customColor, index, colorList) => {
    if (customColor) {
        return customColor
    }

    if (colorList?.length) {
        return colorList[index % colorList.length].foregroundColor
    }

    return cons.CHOICES.QUEUE_TEMPLATE.DEFAULT_TABLE_TYPE_FOREGROUND_COLOR[
        index % cons.CHOICES.QUEUE_TEMPLATE.DEFAULT_TABLE_TYPE_FOREGROUND_COLOR.length
    ].hex
}

export const getTableTypeBackgroundColor = (customColor, index, colorList) => {
    if (customColor) {
        return customColor
    }

    if (colorList?.length) {
        return colorList[index % colorList.length].backgroundColor
    }

    return cons.CHOICES.QUEUE_TEMPLATE.DEFAULT_TABLE_TYPE_BACKGROUND_COLOR[
        index % cons.CHOICES.QUEUE_TEMPLATE.DEFAULT_TABLE_TYPE_BACKGROUND_COLOR.length
    ].hex
}

export const permissionsCheck = (key) => {
    const permission = localStorage.getItem(cons.LOCAL_STORAGE_KEY.ROLE)
    const SYSTEM_ADMIN = cons.ROLE.SYSTEM_ADMIN
    const SUPER_ADMIN = cons.ROLE.ROLE_SUPER_ADMIN
    const RESTAURANT_ADMIN = cons.ROLE.RESTAURANT_ADMIN
    const FINANCE = cons.ROLE.FINANCE
    const SUPPORT = cons.ROLE.SUPPORT

    let permissionShow = false

    if (permission) {
        switch (key) {
            case cons.PERMISSION_KEY.GET_STRING:
            case cons.PERMISSION_KEY.MENU_RESTAURANT_ADMIN:
                if (permission.includes(RESTAURANT_ADMIN)) {
                    permissionShow = true
                }
                break
            case cons.PERMISSION_KEY.MENU_SYSTEM_ADMIN:
            case cons.PERMISSION_KEY.LIST_DISPLAY_SYSTEM_ADMIN:
            case cons.PERMISSION_KEY.PRODUCT_CREATE_SYSTEM_ADMIN:
            case cons.PERMISSION_KEY.PRODUCT_EDIT_SYSTEM_ADMIN:
            case cons.PERMISSION_KEY.PRODUCT_DRAFT_LIST_SYSTEM_ADMIN:
            case cons.PERMISSION_KEY.MERCHANT_CREATE_SYSTEM_ADMIN:
            case cons.PERMISSION_KEY.LANG_SYSTEM_ADMIN:
                if (permission.includes(SYSTEM_ADMIN) || permission.includes(SUPER_ADMIN)) {
                    permissionShow = true
                }
                break
            case cons.PERMISSION_KEY.MENU_FINANCE:
            case cons.PERMISSION_KEY.LIST_DISPLAY_FINANCE:
            case cons.PERMISSION_KEY.PRODUCT_CREATE_LIST_FINANCE:
            case cons.PERMISSION_KEY.PRODUCT_CREATE_FINANCE:
            case cons.PERMISSION_KEY.PRODUCT_CREATE_EDIT_FINANCE:
            case cons.PERMISSION_KEY.CAT_CREATE_FINANCE:
            case cons.PERMISSION_KEY.PRODUCT_EDIT_FINANCE:
            case cons.PERMISSION_KEY.PRODUCT_LOT_CREATE_EDIT_FINANCE:
            case cons.PERMISSION_KEY.REDEEM_GROUP_CREATE_EDIT_FINANCE:
            case cons.PERMISSION_KEY.REDEEM_GROUP_LIST_FINANCE:
            case cons.PERMISSION_KEY.MERCHANT_CREATE_FINANCE:
            case cons.PERMISSION_KEY.LANG_FINANCE:
                if (permission.includes(FINANCE)) {
                    permissionShow = true
                }
                break
            case cons.PERMISSION_KEY.MENU_SUPPORT:
                if (permission.includes(SUPPORT)) {
                    permissionShow = true
                }
                break
            default:
        }
    }

    return permissionShow
}

export const getString = (lang = cons.LANG.EN, resource, key, theme) => {
    // NOTE: use below t translate function

    let tempMessages = undefined
    let defaultMessages = undefined

    if (!key) {
        return ''
    }

    switch (theme) {
        case 'my':
            switch (lang) {
                case cons.LANG.TC:
                    tempMessages = tcMyMessages
                    defaultMessages = tcMessages
                    break
                case cons.LANG.SC:
                    tempMessages = scMyMessages
                    defaultMessages = scMessages
                    break
                default:
                    tempMessages = enMyMessages
                    defaultMessages = enMessages
            }
            break
        default:
            switch (lang) {
                case cons.LANG.TC:
                    tempMessages = tcMessages
                    defaultMessages = tcMessages
                    break
                case cons.LANG.SC:
                    tempMessages = scMessages
                    defaultMessages = scMessages
                    break
                default:
                    tempMessages = enMessages
                    defaultMessages = enMessages
            }
    }

    if (!resource) {
        return tempMessages[key]
            ? tempMessages[key]
            : defaultMessages[key]
            ? defaultMessages[key]
            : ''
    } else if (tempMessages.resource[resource] && tempMessages.resource[resource][key]) {
        return tempMessages.resource[resource][key]
    } else if (tempMessages.resource[resource] && tempMessages[key]) {
        return tempMessages[key]
    } else if (defaultMessages.resource[resource] && defaultMessages.resource[resource][key]) {
        return defaultMessages.resource[resource][key]
    } else if (defaultMessages.resource[resource] && defaultMessages[key]) {
        return defaultMessages[key]
    }
    return ''
}

export const t = (guluLang, key) => {
    // guluLang: TC, SC, EN
    // key: common.message.hello | common | common.message
    const keys = key.split('.')
    let obj = enMessages

    if (guluLang === cons.LANG.TC) {
        obj = tcMessages
    }

    if (guluLang === cons.LANG.SC) {
        obj = scMessages
    }

    let result = obj

    for (const k of keys) {
        result = result[k]
    }

    return result
}

export const getImageType = (imageValue, type) => {
    let imageType = ''
    let deviation = 0.1
    let item4BY3 = 480 / 360
    let itemIcon = 500 / 500
    switch (type) {
        case cons.GET_IMAGE_TYPE.PRODUCT:
            if (
                item4BY3 * (1 - deviation) <= imageValue.width / imageValue.height &&
                imageValue.width / imageValue.height <= item4BY3 * (1 + deviation)
            ) {
                imageType = cons.PRODUCT_IMAGE_TYPE.ITEM_4BY3
            } else if (
                itemIcon * (1 - deviation) <= imageValue.width / imageValue.height &&
                imageValue.width / imageValue.height <= itemIcon * (1 + deviation)
            ) {
                imageType = cons.PRODUCT_IMAGE_TYPE.ITEM_ICON
            } else {
                imageType = cons.PRODUCT_IMAGE_TYPE.ITEM_DETAIL
            }
            return imageType
        case cons.GET_IMAGE_TYPE.CATEGORY:
            if (
                item4BY3 * (1 - deviation) <= imageValue.width / imageValue.height &&
                imageValue.width / imageValue.height <= item4BY3 * (1 + deviation)
            ) {
                imageType = cons.CAT_IMAGE_TYPE.CATEGORY_4BY3
            } else if (
                itemIcon * (1 - deviation) <= imageValue.width / imageValue.height &&
                imageValue.width / imageValue.height <= itemIcon * (1 + deviation)
            ) {
                imageType = cons.CAT_IMAGE_TYPE.CATEGORY_ICON
            } else {
                imageType = cons.CAT_IMAGE_TYPE.CATEGORY_DETAIL
            }
            return imageType
        default:
            return imageType
    }
}

export const generateTableCellToMultiLanguageName = (tc, en, sc) => {
    return (
        <>
            <div className='list-content'>
                <div className='list-label-tab'>TC</div>
                {tc}
            </div>
            <div className='list-content'>
                <div className='list-label-tab'>EN</div>
                {en}
            </div>
            <div className='list-content'>
                <div className='list-label-tab'>SC</div>
                {sc}
            </div>
        </>
    )
}

export const getScDisabled = (tc, sc) => {
    let scDisable = true
    if ((!tc && !sc) || (tc && sify(tc) === sc)) {
        scDisable = true
    } else {
        scDisable = false
    }
    return scDisable
}

export const helperTextStyles = makeStyles((theme) => ({
    root: {
        color: `${errorColor} !important`,
    },
}))

export const getPreviewText = (text, range = 10) => {
    if (!text) return ''
    if (text.length <= range) return text
    const preview = text.substring(0, range + 1) + '...'
    return preview
}

export const getQueryParamObject = (defaultParam, listFilters, sortList) => {
    const query = getQuery()
    const validFilterMap = getValidFilterList(listFilters)
    const validSortList = getValidSortList(sortList)
    const validQuery = {}
    const paramFilterList = [...listFilters]

    for (const [key, queryValue] of Object.entries(query)) {
        switch (key) {
            case 'sort':
                if (validSortList.includes(queryValue)) {
                    validQuery.sort = queryValue
                }
                break
            case 'direction':
                if (queryValue === 'ASC' || queryValue === 'DESC') {
                    validQuery.direction = queryValue
                }
                break
            case 'rowsPerPage':
                validQuery.rowsPerPage = getRow(queryValue)
                break
            case 'page':
                const page = Number(queryValue)
                if (!isNaN(page)) {
                    validQuery.page = page > 0 ? page - 1 : 0
                }
                break
            default:
                const { validate, value } = getAcceptableParam(key, queryValue, validFilterMap)
                if (validate) {
                    paramFilterList.forEach((filter) => {
                        if (filter.name === key) {
                            filter.value = value
                        }
                    })
                }
                break
        }
    }

    validQuery.listFilters = [...paramFilterList]
    return { ...defaultParam, ...validQuery }
}

export const getValidFilterList = (listFilters) => {
    const validFilterMap = new Map()

    listFilters?.forEach((filter) => {
        validFilterMap.set(filter.name, {
            type: filter.type || cons.TYPE.TEXT,
            availableList: filter.selectValue,
        })
    })

    return validFilterMap
}

export const getAcceptableParam = (currentParamName, currentParamValue, validFilterMap) => {
    // moment(inputSetting.value).format('YYYY-MM-DDTHH:mm:ss')
    if (!currentParamName || !currentParamValue) {
        return { validate: false }
    }

    if (!currentParamValue) {
        return { validate: false }
    }

    const criteria = validFilterMap.get(currentParamName)

    if (!criteria) {
        return { validate: false }
    }

    switch (criteria.type) {
        case cons.TYPE.DATETIME_LOCAL:
            const regex = /^\d{13}$/
            // const date = moment(currentParamValue);
            // moment(inputSetting.value).format('YYYY-MM-DDTHH:mm:ss')
            return regex.test(currentParamValue)
                ? {
                      validate: true,
                      value: moment(currentParamValue, 'x').format('YYYY-MM-DDTHH:mm:ss'),
                  }
                : { validate: false }
        case cons.TYPE.SELECT:
            //number?
            // console.log('availableList', criteria.availableList)
            const requiredNumber =
                criteria.availableList && criteria.availableList.length > 0
                    ? typeof criteria.availableList[0].value === 'number'
                    : false
            const requiredBoolean =
                criteria.availableList && criteria.availableList.length > 0
                    ? typeof criteria.availableList[0].value === 'boolean'
                    : false
            let modifiedValue = currentParamValue
            if (requiredNumber && !isNaN(currentParamValue)) {
                modifiedValue = Number(currentParamValue)
            }

            if (requiredBoolean) {
                if (currentParamValue === 'true') {
                    modifiedValue = true
                }
                if (currentParamValue === 'false') {
                    modifiedValue = false
                }
            }

            return criteria.availableList.find((option) => option.value === modifiedValue)
                ? { validate: true, value: modifiedValue }
                : { validate: false }
        default:
            return { validate: true, value: currentParamValue }
    }
}

export const getValidSortList = (sortList) => {
    return sortList?.filter((sort) => sort.sort)?.map((sortableItem) => sortableItem.value) || []
}

export const getQuery = () => {
    const search = window.location.search
    const params = queryString.parse(search)
    return params
}

export const getRow = (row) => {
    switch (row) {
        case '25':
            return 25
        case '50':
            return 50
        case '100':
            return 100
        default:
            return 10
    }
}

export const getParamQueryString = (defaultParam, currentParam) => {
    const differentParam = {}

    for (const [key, queryValue] of Object.entries(currentParam)) {
        switch (key) {
            case 'listFilters':
                currentParam.listFilters.forEach((filter) => {
                    if (filter.value !== '' && filter.value !== null) {
                        if (
                            filter.type === cons.TYPE.DATETIME_LOCAL &&
                            filter.value !== defaultParam.listFilters[`${filter.name}`]
                        ) {
                            differentParam[`${filter.name}`] = moment(filter.value).format('x')
                            return
                        }
                        if (
                            filter.value !==
                            defaultParam.listFilters.find((item) => item.name === filter.name)
                                ?.value
                        ) {
                            differentParam[`${filter.name}`] = filter.value
                        }
                    }
                })
                break
            case 'count':
                break
            case 'page':
                const page = Number(queryValue)
                if (!isNaN(page) && page >= 1) {
                    differentParam.page = page + 1
                }
                break
            default:
                if (queryValue !== defaultParam[`${key}`]) {
                    differentParam[`${key}`] = queryValue
                }
        }
    }

    return queryString.stringify(differentParam)
}

export const toDataURL = (url) =>
    fetch(url)
        .then((response) => response.blob())
        .then(
            (blob) =>
                new Promise((resolve, reject) => {
                    const reader = new FileReader()
                    reader.onloadend = () => resolve(reader.result)
                    reader.onerror = reject
                    reader.readAsDataURL(blob)
                })
        )

export const isMaxSmallerThanMin = (min = Number.MIN_VALUE, max = Number.MAX_VALUE) => {
    return !isNaN(Number(min)) && !isNaN(Number(max)) && Number(min) > Number(max)
}

export const isWithInRangeAndValidNumber = ({ min, max, value }) => {
    if ((!Number.isInteger(Number(value)) || isNaN(Number(value))) && value !== 0) {
        return false
    }

    if (Number(value) < min) {
        return false
    }

    if (Number(value) > max) {
        return false
    }

    return true
}

export const getTodayShopAppGeneralCode = () => {
    const year = moment().year()
    const month = moment().month() + 1
    const date = moment().date()

    const result = year * month * date * date + ''

    return result.substring(result.length - 4)
}

export const getTodayShopAppSettingCode = () => {
    const year = moment().year()
    const month = moment().month() + 1
    const date = moment().date()

    const result = (year - month) * date + ''

    return result.substring(result.length - 4)
}

export const isWebActionValidUrl = (url) => {
    if (!url) {
        return false
    }

    return (
        url.match('^https?://+?[-a-zA-Z0-9@:%_+.~#?&//=]+') ||
        url.match('^thegulu://+?[-a-zA-Z0-9@:%_+.~#?&//=]+')
    )
}

export const isObj = (value) => {
    return value && typeof value === 'object' && value.constructor === Object
}

export const formatMultipleSelectArray = (selectedArray, options) => {
    if (selectedArray && typeof selectedArray === 'string') {
        return [selectedArray]
    }

    if (!selectedArray || !options) {
        return []
    }

    const optionsMap = new Map(options.map((option) => [option.value, option]))
    const formattedArray = selectedArray.map((selected) => optionsMap.get(selected))

    return formattedArray
}
