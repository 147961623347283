import { useState, useEffect } from 'react'
import { useDispatch, useSelector, useStore } from 'react-redux'
import { useHistory } from 'react-router-dom'
import _ from 'lodash'
import CachedIcon from '@mui/icons-material/Cached'

import SaveIcon from '@mui/icons-material/Save'
import ClearIcon from '@mui/icons-material/Clear'
import AddIcon from '@mui/icons-material/Add'
import LowPriorityIcon from '@mui/icons-material/LowPriority'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { makeStyles } from '@mui/styles'
import { Drawer } from '@mui/material'

import { constants } from '../../Constants/constants'
import { post } from '../../helper/baseFetch'
import { setMerchantUser, setMerchantUserValue } from '../../actions/merchantUserAction'
import { getString } from '../../helper/util'
import { EditCreateDisplay } from '../../Component/EditCreateDisplay/EditCreateDisplay'
import { TempTextInput } from '../../Component/Input/TempTextInput'
import { TempAutoSelectInput } from '../../Component/Input/TempAutoSelectInput'
import { TempCheckBox } from '../../Component/Input/TempCheckBox'
import { TempBtn } from '../../Component/Input/TempBtn'
import { RestaurantList } from '../Restaurant/RestaurantList'
import { GlobalDialog } from '../../Component/GlobalDialog'
import MerchantUserValidation from '../../validations/merchantUserValidation'
import { merchantUserValidationConstants } from '../../Constants/validationConstants'
import { PrivilegeSetting } from './MerchantUserEdit/PrivilegeSetting'

import '../../styles/merchantUser.scss'
import { testMerchantUserPassword } from '../../helper/passwordUtil'

const helperTextStyles = makeStyles((theme) => ({
  root: {
    color: 'red !important',
  },
}))

const LOCAL_CONSTANT = {
  PRIVILEGE_ATTRIBUTE: {
    SHOP: 'SHOP',
    GROUP: 'GROUP',
  },
  PRIVILEGE_RESTURLID: 'privilegeRestUrlId',
  PRIVILEGE_GROUP_CODE: 'privilegeGroupCode',
}

export const MerchantUserCreate = ({
  currentEditUser,
  setCurrentEditUser,
  hideTab,
  hideBottomBtnList,
  hidePrivilegeRestUrlIdEditButton = false,
  embeddedInRestaurantClone,
}) => {
  const dispatch = useDispatch()
  const store = useStore()
  const history = useHistory()
  const helperTextStylesClasses = helperTextStyles()
  const [clear, setClear] = useState(false)
  const [previewSelectedList, setPreviewSelectedList] = useState([])
  const [restUrlIdList, setRestUrlIdList] = useState([])
  const [showRestaurantTextPanel, setShowRestaurantTextPanel] = useState(false)
  const [dialog, setDialog] = useState({
    open: false,
    dialogActions: [],
  })
  const resource = 'MerchantUser'
  const savedMerchantUser = useSelector((state) => state.merchantUser.ui.merchantUser)
  const lang = useSelector((state) => state.lang.ui.lang)
  const theme = useSelector((state) => state.theme.ui.theme)
  const [, setErrorVersion] = useState(MerchantUserValidation.getVersion())
  const id = currentEditUser ? savedMerchantUser.customId : savedMerchantUser.id

  const tabs = [
    {
      label: { resource: resource, key: 'info' },
      tabIndex: constants.MERCHANT_USER_EDIT_TAB.INFO,
    },
  ]

  const fields = [
    {
      className: 'edit-create-input',
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'userRole' },
      value: savedMerchantUser.userRole,
      disableClearable: true,
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.MERCHANT_USER.USER_ROLE,
      name: 'userRole',
      isEndOfLine: true,
      required: true,
      helperTextIcon: true,
      helperText: MerchantUserValidation.getErrorMessages(`${id}-${merchantUserValidationConstants.KEY_USER_ROLE}`),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: `edit-create-input`,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'merchantRole' },
      value: savedMerchantUser.merchantRole,
      name: 'merchantRole',
      type: constants.TYPE.SELECT,
      disableClearable: true,
      selectValue: constants.CHOICES.MERCHANT_USER.MERCHANT_ROLE,
      isEndOfLine: true,
      required: true,
      helperTextIcon: true,
      helperText: MerchantUserValidation.getErrorMessages(`${id}-${merchantUserValidationConstants.KEY_MERCHANT_ROLE}`),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: 'edit-create-input',
      size: constants.SIZE.SMALL,
      type: constants.TYPE.FUNCTION,
      value: <PrivilegeSetting onChangeInput={onChangeInput} id={id} hidePrivilegeRestUrlIdEditButton={hidePrivilegeRestUrlIdEditButton} />,
    },
    {
      className: 'edit-create-input',
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'username' },
      value: savedMerchantUser.username,
      name: 'username',
      isEndOfLine: false,
      required: true,
      onClickEndAdornmentIconButton: () => generateMerchantUserName(),
      endAdornmentIconButton: <CachedIcon />,
      disabledOnClickInputField: true,
      disabledEndAdornmentIconButton: savedMerchantUser.privilegeAttribute !== LOCAL_CONSTANT.PRIVILEGE_ATTRIBUTE.SHOP || !savedMerchantUser.privilegeRestUrlId,
      helperTextIcon: true,
      helperText: MerchantUserValidation.getErrorMessages(`${id}-${merchantUserValidationConstants.KEY_USERNAME}`),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: 'edit-create-input',
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'password' },
      value: savedMerchantUser.password,
      name: 'password',
      isEndOfLine: false,
      required: true,
      helperTextIcon: true,
      onClickEndAdornmentIconButton: () => generatePassword(),
      endAdornmentIconButton: <CachedIcon />,
      disabledOnClickInputField: true,
      helperText: MerchantUserValidation.getErrorMessages(`${id}-${merchantUserValidationConstants.KEY_PASSWORD}`),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: 'edit-create-input',
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'email' },
      value: savedMerchantUser.email,
      name: 'email',
      isEndOfLine: true,
      helperTextIcon: true,
      helperText: MerchantUserValidation.getErrorMessages(`${id}-${merchantUserValidationConstants.KEY_EMAIL}`),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    // {
    //   type: constants.TYPE.TITLE,
    //   value: { resource: resource, key: "restUrlIdList" },
    //   className: "sub-title",
    // },
    // {
    //   className: "full-size-input",
    //   size: constants.SIZE.SMALL,
    //   label: { resource: resource, key: "restUrlIdList" },
    //   value: savedMerchantUser.restUrlIdList && savedMerchantUser.restUrlIdList.map((item) => { return " " + item }),
    //   name: "restUrlIdList",
    //   variant: constants.STYLE.VARIANT.OUTLINED,
    //   onClick: handleAddRestUrlIdList,
    //   disabled: true,
    //   clear: false,
    //   multiline: true,
    //   isEndOfLine: true,
    //   required: true,
    //   helperTextIcon: true,
    //   helperText: MerchantUserValidation.getErrorMessages(merchantUserValidationConstants.KEY_REST_URL_ID_LIST),
    //   FormHelperTextProps: { className: helperTextStylesClasses.root },
    // },
    // {
    //   type: constants.TYPE.TITLE,
    //   value: { resource: resource, key: "permission" },
    //   className: "sub-title",
    // },
    // {
    //   className: "text",
    //   size: constants.SIZE.SMALL,
    //   label: { resource: resource, key: "reportPermission" },
    //   value: savedMerchantUser.reportPermission.read,
    //   name: "reportPermission",
    //   type: constants.TYPE.CHECKBOX,
    //   isEndOfLine: false
    // },
    // {
    //   className: "text",
    //   size: constants.SIZE.SMALL,
    //   label: { resource: resource, key: "deviceManagementPermission" },
    //   value: savedMerchantUser.deviceManagementPermission.read,
    //   name: "deviceManagementPermission",
    //   type: constants.TYPE.CHECKBOX,
    //   isEndOfLine: false
    // },
    // {
    //   className: "text",
    //   size: constants.SIZE.SMALL,
    //   label: { resource: resource, key: "featurePermission" },
    //   value: savedMerchantUser.featurePermission.read,
    //   name: "featurePermission",
    //   type: constants.TYPE.CHECKBOX,
    //   isEndOfLine: false
    // },
    // {
    //   className: "text",
    //   size: constants.SIZE.SMALL,
    //   label: { resource: resource, key: "restaurantManagementPermission" },
    //   value: savedMerchantUser.restaurantManagementPermission.read,
    //   name: "restaurantManagementPermission",
    //   type: constants.TYPE.CHECKBOX,
    //   isEndOfLine: false
    // },
    // {
    //   className: "text",
    //   size: constants.SIZE.SMALL,
    //   label: { resource: resource, key: "userGroupManagementPermission" },
    //   value: savedMerchantUser.userGroupManagementPermission.read,
    //   name: "userGroupManagementPermission",
    //   type: constants.TYPE.CHECKBOX,
    //   isEndOfLine: false
    // },
  ]
  const content = [
    {
      tab: fields.map((inputSetting, index) => {
        let content
        switch (inputSetting.type) {
          case constants.TYPE.SELECT:
            content = <TempAutoSelectInput inputSetting={inputSetting} handleChange={onChangeInput} />
            break
          case constants.TYPE.CHECKBOX:
            content = (
              <span className="merchant-user-check-box-wrapper">
                <TempCheckBox
                  checkBoxSetting={{
                    className: inputSetting.className,
                    name: inputSetting.name,
                    label: inputSetting.label,
                    value: inputSetting.value,
                  }}
                  handleChange={handleCheckBoxChange}
                />
              </span>
            )
            break
          case constants.TYPE.FUNCTION:
            content = inputSetting.value
            break
          case constants.TYPE.TITLE:
            content = (
              <div className={`${inputSetting.className + ' sub-title'}`}>
                {getString(lang, inputSetting.value.resource, inputSetting.value.key, theme)}
              </div>
            )
            break
          default:
            content = <TempTextInput inputSetting={inputSetting} handleChange={onChangeInput} />
        }
        return (
          <div className="edit-display-wrapper" key={index}>
            {content}
            {inputSetting.isEndOfLine && <div></div>}
          </div>
        )
      }),
      btn: hideBottomBtnList ? false : true,
    },
  ]

  const bottomBtns = [
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: 'save' },
      onClick: onClickSave,
      className: 'margin-left-16',
      icon: <SaveIcon />,
    },
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: 'clear' },
      disabled: false,
      onClick: onClickClear,
      className: 'margin-left-16',
      icon: <ClearIcon />,
    },
  ]

  const onClickBackToCreate = () => {
    setDialog({
      open: false,
      dialogActions: [],
    })
    onClickClear()
  }
  const onClickBackToList = () => {
    setDialog({
      open: false,
      dialogActions: [],
    })
    history.push('/' + constants.PATH.MERCHANT.MERCHANT_USER)
  }

  function onChangeInput(name, e) {
    if (currentEditUser) {
      const temp = _.cloneDeep(currentEditUser)
      temp[`${name}`] = e
      setCurrentEditUser(temp)
    }

    dispatch(setMerchantUserValue(name, savedMerchantUser, '', e))
  }

  const generateMerchantUserName = () => {
    onChangeInput('username', `shop${savedMerchantUser.privilegeRestUrlId}`)
  }

  const generatePassword = () => {
    let password

    do {
      password = Math.random().toString(36).slice(-6)
    } while (!testMerchantUserPassword(password))

    onChangeInput('password', password)
  }

  // const generatePassword = () => {

  //   let password = '';
  //   let direction = null;
  //   let consecutive = false;
  //   let sameDigitConsecutive = false;

  //   while(password.length<6){
  //     const disableSet = new Set();

  //     if(password.length === 0){
  //       disableSet.add(0);
  //     }

  //     if(direction && consecutive) {
  //       const disableNumber = Number(password[password.length-1]) + direction;
  //       disableSet.add(disableNumber);
  //     }

  //     if(sameDigitConsecutive) {
  //       disableSet.add(Number(password[password.length-1]));
  //     }

  //     const currentNumber = getRandomNumber(disableSet);
  //     // console.log('curr', currentNumber);
  //     password += ''+currentNumber;

  //     if(Math.abs(password[password.length-1] - password[password.length-2]) === 1){
  //       direction = password[password.length-1] - password[password.length-2];
  //       consecutive = true;
  //     }
  //     else if (password[password.length-1] === password[password.length-2]) {
  //       sameDigitConsecutive = true;
  //     }
  //     else{
  //       direction = null;
  //       consecutive = false;
  //       sameDigitConsecutive = false;
  //     }

  //     // console.log('state', direction, consecutive, sameDigitConsecutive)

  //   }

  //   // console.log(password)
  //   onChangeInput('password', password)
  // }

  // const getRandomNumber = (disableSet) => {
  //   // console.log('disable set', disableSet)

  //   const randomList = []

  //   for (let i = 0; i <= 9; i++) {
  //     if (!disableSet.has(i)) {
  //       randomList.push(i)
  //     }
  //   }

  //   // console.log(randomList)

  //   const randomNumber = randomList[Math.floor(Math.random() * randomList.length)]

  //   return randomNumber
  // }

  function handleCheckBoxChange(name, e) {
    let tempMerchantUser = _.cloneDeep(savedMerchantUser)
    let permission = tempMerchantUser[name]
    if (!e) {
      permission['read'] = true
      permission['write'] = true
    } else {
      permission['read'] = false
      permission['write'] = false
    }
    dispatch(setMerchantUserValue(name, savedMerchantUser, '', permission))
  }

  // function handleAddRestUrlIdList() {
  //   setShowRestaurantTextPanel(true);
  // }

  function handleRestaurantTextCloseClick() {
    setShowRestaurantTextPanel(false)
    setPreviewSelectedList([])
    setRestUrlIdList(savedMerchantUser.restUrlIdList)
  }

  const processAddRestUrlId = (restUrlId) => {
    setShowRestaurantTextPanel(true)
    return new Promise((resolve) => {
      let tempRestUrlIdList = _.cloneDeep(savedMerchantUser.restUrlIdList)
      let newTempRestUrlIdList = []

      newTempRestUrlIdList = tempRestUrlIdList.filter((restUrlIdList) => {
        return restUrlId.includes(restUrlIdList)
      })

      let oldRestUrlId
      oldRestUrlId = restUrlId.filter((oldRestUrlId) => {
        return !tempRestUrlIdList.includes(oldRestUrlId)
      })

      oldRestUrlId.map((item) => {
        newTempRestUrlIdList.push(item)
        return item
      })

      resolve(newTempRestUrlIdList)
    })
  }

  const handleRestUrlId = async () => {
    const tempRestUrlIdList = await processAddRestUrlId(restUrlIdList)
    setShowRestaurantTextPanel(false)
    setPreviewSelectedList([])
    dispatch(setMerchantUserValue('restUrlIdList', savedMerchantUser, '', tempRestUrlIdList))
  }

  function onClickSave() {
    MerchantUserValidation.validateCreateMerchantUser(savedMerchantUser)
    if (MerchantUserValidation.isValid()) {
      post(
        constants.PATH.MERCHANT.MERCHANT_USER,
        savedMerchantUser,
        (payload) => {
          setDialog({
            open: true,
            onClose: onClickBackToCreate,
            dialogContentText: { key: 'createSuccess' },
            dialogActions: [
              { label: { key: 'backCreate' }, onClick: onClickBackToCreate, icon: <ArrowBackIcon /> },
              { label: { key: 'backList' }, onClick: onClickBackToList, icon: <LowPriorityIcon /> },
            ],
          })
          setClear(false)
        },
        undefined,
        store
      )
      MerchantUserValidation.resetErrorMessages()
    } else {
      setErrorVersion(MerchantUserValidation.getVersion())
    }
  }

  function onClickClear() {
    setClear(true)
  }

  useEffect(() => {
    if (!currentEditUser && !embeddedInRestaurantClone) {
      dispatch(setMerchantUser(constants.MERCHANT_USER_INPUT))
      setClear(false)
      MerchantUserValidation.resetErrorMessages()
    }
  }, [clear === true]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <EditCreateDisplay
        code={savedMerchantUser?.customId ? savedMerchantUser.customId : ''}
        resource={resource}
        tabs={hideTab ? false : tabs}
        content={content}
        onChangeInput={onChangeInput}
        bottomBtns={bottomBtns}
        display={savedMerchantUser.customId ? 'edit' : 'create'}
      />
      <Drawer classes={{ paper: 'drawer-margin-left' }} anchor="right" open={showRestaurantTextPanel} onClose={handleRestaurantTextCloseClick}>
        <RestaurantList
          editBtn={false}
          createBtn={false}
          btnList={false}
          mutipleSelect={true}
          setSelectedIdList={setRestUrlIdList}
          selectedIdList={restUrlIdList}
          setPreviewSelectedList={setPreviewSelectedList}
          previewSelectedList={previewSelectedList}
          newPath={constants.PATH.SHOP.RESTAURANT}
          newFilter={
            restUrlIdList && restUrlIdList.length > 0
              ? {
                  selected: {
                    column: 'restUrlId',
                    valueList: restUrlIdList
                      .map((item) => {
                        return item
                      })
                      .reverse(),
                  },
                }
              : null
          }
          savedData={savedMerchantUser.restUrlIdList}
          dialogFooterBtn={
            <>
              <TempBtn
                btnSetting={{
                  className: 'get-push-card-cancel-btn',
                  variant: constants.STYLE.VARIANT.CONTAINED,
                  color: constants.STYLE.PRIMARY,
                  onClick: handleRestUrlId,
                  icon: <AddIcon />,
                  label: { key: 'submit' },
                }}
              />
              <TempBtn
                btnSetting={{
                  className: 'get-push-card-cancel-btn margin-left-16',
                  variant: constants.STYLE.VARIANT.CONTAINED,
                  color: constants.STYLE.PRIMARY,
                  onClick: handleRestaurantTextCloseClick,
                  icon: <ClearIcon />,
                  label: { key: 'close' },
                }}
              />
            </>
          }
        />
      </Drawer>
      {dialog && dialog.open && <GlobalDialog dialogInfo={dialog} />}
    </>
  )
}
