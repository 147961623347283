import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { sify } from 'chinese-conv'
import _ from 'lodash'
import ClearIcon from '@mui/icons-material/Clear'
import CachedIcon from '@mui/icons-material/Cached'
import LowPriorityIcon from '@mui/icons-material/LowPriority'
import AllInclusiveIcon from '@mui/icons-material/AllInclusive'
import { Drawer } from '@mui/material'
import { constants as cons } from '../../Constants/constants'
import { setQueueTemplate, setQueueTemplateTimeSectionValue, setQueueTemplateTableTypeValue } from '../../actions/queueTemplateAction'
import { EditCreateDisplay } from '../../Component/EditCreateDisplay/EditCreateDisplay'
import { TempTextInput } from '../../Component/Input/TempTextInput'
import { TempAutoSelectInput } from '../../Component/Input/TempAutoSelectInput'
import { TempBtn } from '../../Component/Input/TempBtn'
import { TempCustomCheckBox } from '../../Component/Custom/TempCustomCheckBox'
import { TicketTypeList } from '../TicketType/TicketTypeList'
import { TicketTypeCreate } from '../TicketType/TicketTypeCreate'
import QueueTemplateValidation from '../../validations/queueTemplateValidation'
import { queueTemplateValidationConstants as validationCo } from '../../Constants/validationConstants'
import { getIsSyncRequestTicketTimeFieldsWithAllTableTypes, getIsSyncTimeFieldsWithAllTableTypes } from './queueTemplateUtil'
import '../../styles/colorPicker.scss'
import { QueueTemplateColorPicker } from './QueueTemplateColorPicker'
import { Clear } from '@mui/icons-material'
import { getTableTypeBackgroundColor, getTableTypeForegroundColor } from '../../helper/util'
import { SoundInfoVoiceDropdown } from '../MerchantConfig/SoundInfoVoiceDropdown'
import { DefaultColorSetDropDown } from './DefaultColorSetDropDown'
import MUIAccordion from '../../Component/common/collapse/MUIAccordion'

export const QueueTemplateTableType = ({ setCustomIdNumber, customIdNumber, soundVoiceList }) => {
  const dispatch = useDispatch()
  const savedQueueTemplate = useSelector((state) => state.queueTemplate.ui.queueTemplate)
  const savedTableType = useSelector((state) => state.queueTemplate.ui.tableType)
  const savedTableTypeColorList = useSelector((state) => state.queueTemplate.ui.tableTypeColorList)
  const savedDefaultTableTypeColorList = useSelector((state) => state.queueTemplate.ui.defaultTableTypeColorList)
  const [tableTypeInputList, setTableTypeInputList] = useState(_.cloneDeep(savedTableType))
  const [reload, setReload] = useState(false)
  const [showTicketTypeTextPanel, setShowTicketTypeTextPanel] = useState(false)
  const [showTicketTypeCreatePanel, setShowTicketTypeCreatePanel] = useState(false)

  // eslint-disable-next-line no-unused-vars
  const [errorVersion, setErrorVersion] = useState(QueueTemplateValidation.getVersion())

  const resource = 'TableType'
  const contents = [
    {
      tab: (
        <>
          <TempAutoSelectInput
            inputSetting={{
              label: { resource: resource, key: 'type' },
              value: savedTableType.type,
              selectValue: cons.CHOICES.QUEUE_TEMPLATE.TYPE,
              name: 'type',
            }}
            handleChange={onChangeInput}
          />
          {savedTableType.id && (
            <TempAutoSelectInput
              inputSetting={{
                label: { resource: resource, key: 'status' },
                value: savedTableType.status,
                selectValue: cons.CHOICES.QUEUE_TEMPLATE.TICKET_TYPE_STATUS,
                name: 'status',
                disableClearable: true,
                helperTextIcon: true,
                helperText: QueueTemplateValidation.getErrorMessages(validationCo.KEY_TABLE_TYPE_STATUS, savedTableType.customId).map((error) => {
                  return error
                }),
              }}
              handleChange={onChangeInput}
            />
          )}
          <TempAutoSelectInput
            inputSetting={{
              label: { resource: resource, key: 'defaultStatus' },
              value: savedTableType.defaultStatus,
              selectValue: cons.CHOICES.QUEUE_TEMPLATE.DEFAULT_STATUS,
              name: 'defaultStatus',
              disableClearable: true,
              helperTextIcon: true,
              helperText: QueueTemplateValidation.getErrorMessages(validationCo.KEY_TABLE_TYPE_STATUS, savedTableType.customId).map((error) => {
                return error
              }),
            }}
            handleChange={onChangeInput}
          />
          <div></div>
          <TempAutoSelectInput
            inputSetting={{
              label: { resource: resource, key: 'tableType' },
              value: savedTableType.tableType,
              selectValue: cons.CHOICES.QUEUE_TEMPLATE.TIME_SECTION_ID,
              name: 'tableType',
              disableClearable: true,
              helperTextIcon: true,
              helperText: QueueTemplateValidation.getErrorMessages(validationCo.KEY_TABLE_TYPE, savedTableType.customId).map((error) => {
                return error
              }),
              freeSolo: true,
              disableStatusThemeColor: true,
            }}
            handleChange={onChangeInput}
          />
          <TempAutoSelectInput
            inputSetting={{
              label: { resource: resource, key: 'parentTableType' },
              value: savedTableType.parentTableType,
              name: 'parentTableType',
              helperTextIcon: true,
              helperText: QueueTemplateValidation.getErrorMessages(validationCo.KEY_PARENT_TABLE_TYPE, savedTableType.customId).map((error) => {
                return error
              }),
              freeSolo: true,
              disableStatusThemeColor: true,
              selectValue: getCurrentTimeSectionPossibleParentTableType(),
              // endAdornmentIconButton: <Clear fontSize={cons.SIZE.SMALL} />,
              // onClickEndAdornmentIconButton: () => {
              //   onChangeFieldToNull('parentTableType')
              // },
            }}
            handleChange={onChangeInput}
          />
          <MUIAccordion title="START/ END TIME">
            <TempTextInput
              inputSetting={{
                label: { resource: resource, key: 'startTime' },
                value: savedTableType.startTime,
                name: 'startTime',
                step: '2',
                helperTextIcon: true,
                helperText: QueueTemplateValidation.getErrorMessages(validationCo.KEY_TABLE_TYPE_START_TIME, savedTableType.customId).map((error) => {
                  return error
                }),
                disabled: getIsSyncTimeFieldsWithAllTableTypes(savedTableType.timeSectionCustomId, savedQueueTemplate),
                type: cons.TYPE.TIME,
              }}
              handleChange={onChangeInput}
            />
            <TempTextInput
              inputSetting={{
                label: { resource: resource, key: 'endTime' },
                value: savedTableType.endTime,
                name: 'endTime',
                step: '2',
                helperTextIcon: true,
                helperText: QueueTemplateValidation.getErrorMessages(validationCo.KEY_TABLE_TYPE_END_TIME, savedTableType.customId).map((error) => {
                  return error
                }),
                disabled: getIsSyncTimeFieldsWithAllTableTypes(savedTableType.timeSectionCustomId, savedQueueTemplate),
                type: cons.TYPE.TIME,
              }}
              handleChange={onChangeInput}
            />
            <TempTextInput
              inputSetting={{
                label: { resource: resource, key: 'timeSectionDescription' },
                value: savedTableType.timeSectionDescription,
                name: 'timeSectionDescription',
                onClickEndAdornmentIconButton: () => generateTimeSectionDescription(),
                endAdornmentIconButton: <CachedIcon />,
                disabledEndAdornmentIconButton: !!(
                  !savedTableType.startTime ||
                  !savedTableType.endTime ||
                  getIsSyncTimeFieldsWithAllTableTypes(savedTableType.timeSectionCustomId, savedQueueTemplate)
                ),
                disabledOnClickInputField: true,
                disabledStyle: true,
                multiline: true,
                disabled: getIsSyncTimeFieldsWithAllTableTypes(savedTableType.timeSectionCustomId, savedQueueTemplate),
              }}
              handleChange={onChangeInput}
            />
            <TempTextInput
              inputSetting={{
                label: { resource: resource, key: 'cutOffTime' },
                value: savedTableType.cutOffTime,
                name: 'cutOffTime',
                step: '2',
                type: cons.TYPE.TIME,
                endAdornmentIconButton: <Clear fontSize={cons.SIZE.SMALL} />,
                onClickEndAdornmentIconButton: () => {
                  onChangeFieldToNull('cutOffTime')
                },
              }}
              handleChange={onChangeInput}
            />
            {/* <TempTextInput
              inputSetting={{
                label: { resource: resource, key: 'cutOffMinute' },
                value: savedTableType.cutOffMinute,
                name: 'cutOffMinute',
                helperTextIcon: true,
                helperText: QueueTemplateValidation.getErrorMessages(validationCo.KEY_CUT_OFF_MIN, savedTableType.customId).map((error) => {
                  return error
                }),
                type: cons.TYPE.NUMBER,
                disabled: true,
                disabledOnClickInputField: true,
              }}
              handleChange={onChangeInput}
            /> */}
          </MUIAccordion>
          <MUIAccordion title="DISPLAY CONFIGS">
            <TempAutoSelectInput
              inputSetting={{
                label: { resource: resource, key: 'mobileDisplay' },
                value: savedTableType.mobileDisplay,
                selectValue: cons.CHOICES.QUEUE_TEMPLATE.MOBILE_DISPLAY,
                name: 'mobileDisplay',
                disableClearable: true,
                helperTextIcon: true,
                helperText: QueueTemplateValidation.getErrorMessages(validationCo.KEY_MOBILE_DISPLAY, savedTableType.customId).map((error) => {
                  return error
                }),
              }}
              handleChange={onChangeInput}
            />
            <TempAutoSelectInput
              inputSetting={{
                label: { resource: resource, key: 'mobileTagDisplay' },
                value: savedTableType.mobileTagDisplay,
                selectValue: cons.CHOICES.QUEUE_TEMPLATE.MOBILE_TAG_DISPLAY,
                name: 'mobileTagDisplay',
                disableClearable: true,
              }}
              handleChange={onChangeInput}
            />
            <TempAutoSelectInput
              inputSetting={{
                label: { resource: resource, key: 'mobileReadOnly' },
                value: savedTableType.mobileReadOnly,
                selectValue: cons.CHOICES.QUEUE_TEMPLATE.MOBILE_READ_ONLY,
                name: 'mobileReadOnly',
                disableClearable: true,
                helperTextIcon: true,
                helperText: QueueTemplateValidation.getErrorMessages(validationCo.KEY_MOBILE_READ_ONLY, savedTableType.customId).map((error) => {
                  return error
                }),
              }}
              handleChange={onChangeInput}
            />
            <TempAutoSelectInput
              inputSetting={{
                label: { resource: resource, key: 'kioskDisplay' },
                value: savedTableType.kioskDisplay,
                selectValue: cons.CHOICES.QUEUE_TEMPLATE.KIOSK_DISPLAY,
                name: 'kioskDisplay',
                disableClearable: true,
                helperTextIcon: true,
                helperText: QueueTemplateValidation.getErrorMessages(validationCo.KEY_KIOS_DISPLAY, savedTableType.customId).map((error) => {
                  return error
                }),
              }}
              handleChange={onChangeInput}
            />
            <TempAutoSelectInput
              inputSetting={{
                label: { resource: resource, key: 'kioskTagDisplay' },
                value: savedTableType.kioskTagDisplay,
                selectValue: cons.CHOICES.QUEUE_TEMPLATE.KIOSK_TAG_DISPLAY,
                name: 'kioskTagDisplay',
                disableClearable: true,
              }}
              handleChange={onChangeInput}
            />
            <TempAutoSelectInput
              inputSetting={{
                label: { resource: resource, key: 'kioskReadOnly' },
                value: savedTableType.kioskReadOnly,
                selectValue: cons.CHOICES.QUEUE_TEMPLATE.KIOSK_READ_ONLY,
                name: 'kioskReadOnly',
                disableClearable: true,
                helperTextIcon: true,
                helperText: QueueTemplateValidation.getErrorMessages(validationCo.KEY_KIOS_READ_ONLY, savedTableType.customId).map((error) => {
                  return error
                }),
              }}
              handleChange={onChangeInput}
            />
          </MUIAccordion>
          <MUIAccordion title="DISPLAY LABELS">
            <TempCustomCheckBox
              className="customize-btn"
              value={savedTableType.disableLabel}
              customName={{ resource: resource, key: 'isAutoTranslateTableTypeScLabel' }}
              onSelectCheckbox={onSelectCheckbox}
            />
            <TempTextInput
              inputSetting={{
                label: { resource: resource, key: 'tableTypeEnLabel' },
                value: savedTableType.tableTypeEnLabel,
                name: 'tableTypeEnLabel',
                multiline: true,
              }}
              handleChange={onChangeInput}
            />
            <TempTextInput
              inputSetting={{
                label: { resource: resource, key: 'tableTypeTcLabel' },
                value: savedTableType.tableTypeTcLabel,
                name: 'tableTypeTcLabel',
                translateName: savedTableType.disableLabel ? ['tableTypeScLabel'] : null,
                inputList: tableTypeInputList,
                setInputList: setTableTypeInputList,
                multiline: true,
              }}
              handleChange={onChangeInput}
            />
            <TempTextInput
              inputSetting={{
                label: { resource: resource, key: 'tableTypeScLabel' },
                value: savedTableType.tableTypeScLabel,
                name: 'tableTypeScLabel',
                inputList: tableTypeInputList,
                setInputList: setTableTypeInputList,
                disabled: savedTableType.disableLabel,
                multiline: true,
              }}
              handleChange={onChangeInput}
            />
            <TempCustomCheckBox
              className="customize-btn"
              value={savedTableType.disableMobileLabel}
              customName={{ resource: resource, key: 'isAutoTranslateTableTypeMobileScLabel' }}
              onSelectCheckbox={onSelectCheckbox}
            />
            <TempTextInput
              inputSetting={{
                label: { resource: resource, key: 'tableTypeMobileEnLabel' },
                value: savedTableType.tableTypeMobileEnLabel,
                name: 'tableTypeMobileEnLabel',
                multiline: true,
              }}
              handleChange={onChangeInput}
            />
            <TempTextInput
              inputSetting={{
                label: { resource: resource, key: 'tableTypeMobileTcLabel' },
                value: savedTableType.tableTypeMobileTcLabel,
                name: 'tableTypeMobileTcLabel',
                translateName: savedTableType.disableMobileLabel ? ['tableTypeMobileScLabel'] : null,
                multiline: true,
              }}
              handleChange={onChangeInput}
            />
            <TempTextInput
              inputSetting={{
                label: { resource: resource, key: 'tableTypeMobileScLabel' },
                value: savedTableType.tableTypeMobileScLabel,
                name: 'tableTypeMobileScLabel',
                disabled: savedTableType.disableMobileLabel,
                multiline: true,
              }}
              handleChange={onChangeInput}
            />
            <TempCustomCheckBox
              className="customize-btn"
              value={savedTableType.disableTicketLabel}
              customName={{ resource: resource, key: 'isAutoTranslateTableTypeTicketScLabel' }}
              onSelectCheckbox={onSelectCheckbox}
            />
            <TempTextInput
              inputSetting={{
                label: { resource: resource, key: 'tableTypeTicketEnLabel' },
                value: savedTableType.tableTypeTicketEnLabel,
                name: 'tableTypeTicketEnLabel',
                multiline: true,
                placeholder: savedTableType.tableTypeTicketEnLabel === null ? 'Not defined' : '',
                endAdornmentIconButton: <Clear fontSize={cons.SIZE.SMALL} />,
                onClickEndAdornmentIconButton: () => {
                  onChangeFieldToNull('tableTypeTicketEnLabel')
                },
              }}
              handleChange={onChangeInput}
            />
            <TempTextInput
              inputSetting={{
                label: { resource: resource, key: 'tableTypeTicketTcLabel' },
                value: savedTableType.tableTypeTicketTcLabel,
                name: 'tableTypeTicketTcLabel',
                multiline: true,
                placeholder: savedTableType.tableTypeTicketTcLabel === null ? 'Not defined' : '',
                endAdornmentIconButton: <Clear fontSize={cons.SIZE.SMALL} />,
                onClickEndAdornmentIconButton: () => {
                  onChangeFieldToNull('tableTypeTicketTcLabel')
                },
              }}
              handleChange={onChangeInput}
            />
            <TempTextInput
              inputSetting={{
                label: { resource: resource, key: 'tableTypeTicketScLabel' },
                value: savedTableType.tableTypeTicketScLabel,
                name: 'tableTypeTicketScLabel',
                multiline: true,
                disabled: savedTableType.disableTicketLabel,
                disabledEndAdornmentIconButton: savedTableType.disableTicketLabel,
                placeholder: savedTableType.tableTypeTicketScLabel === null ? 'Not defined' : '',
                endAdornmentIconButton: <Clear fontSize={cons.SIZE.SMALL} />,
                onClickEndAdornmentIconButton: savedTableType.disableTicketLabel
                  ? null
                  : () => {
                      onChangeFieldToNull('tableTypeTicketScLabel')
                    },
              }}
              handleChange={onChangeInput}
            />
            <TempTextInput
              inputSetting={{
                label: { resource: resource, key: 'ticketType' },
                value: savedTableType.ticketType,
                onClickEndAdornmentIconButton: () => {
                  onClickTicketTypeInput()
                },
                clear: true,
                name: 'ticketType',
                disabled: true,
                multiline: true,
              }}
              handleChange={onChangeInput}
            />
            <div></div>
            <TempTextInput
              inputSetting={{
                variant: cons.STYLE.VARIANT.STANDARD,
                label: { resource: resource, key: 'ticketTypeEnLabel' },
                value: savedTableType.ticketTypeEnLabel,
                name: 'ticketTypeEnLabel',
                disabled: true,
                multiline: true,
              }}
              handleChange={onChangeInput}
            />
            <TempTextInput
              inputSetting={{
                variant: cons.STYLE.VARIANT.STANDARD,
                label: { resource: resource, key: 'ticketTypeTcLabel' },
                value: savedTableType.ticketTypeTcLabel,
                name: 'ticketTypeTcLabel',
                disabled: true,
                multiline: true,
              }}
              handleChange={onChangeInput}
            />
            <TempTextInput
              inputSetting={{
                variant: cons.STYLE.VARIANT.STANDARD,
                label: { resource: resource, key: 'ticketTypeScLabel' },
                value: savedTableType.ticketTypeScLabel,
                name: 'ticketTypeScLabel',
                disabled: true,
                multiline: true,
              }}
              handleChange={onChangeInput}
            />
            <TempAutoSelectInput
              inputSetting={{
                label: { resource: resource, key: 'groupedSection' },
                value: savedTableType.groupedSection,
                selectValue: cons.CHOICES.QUEUE_TEMPLATE.TIME_SECTION_ID,
                variant: cons.STYLE.VARIANT.STANDARD,
                name: 'groupedSection',
                disableStatusThemeColor: true,
                helperTextIcon: true,
                helperText: QueueTemplateValidation.getErrorMessages(validationCo.KEY_GROUPED_SECTION, savedTableType.customId).map((error) => {
                  return error
                }),
              }}
              handleChange={onChangeInput}
            />
            <div></div>
            <TempTextInput
              inputSetting={{
                variant: cons.STYLE.VARIANT.STANDARD,
                label: { resource: resource, key: 'groupedSectionEnLabel' },
                value: savedTableType.groupedSectionEnLabel,
                name: 'groupedSectionEnLabel',
                disabled: true,
                multiline: true,
              }}
              handleChange={onChangeInput}
            />
            <TempTextInput
              inputSetting={{
                variant: cons.STYLE.VARIANT.STANDARD,
                label: { resource: resource, key: 'groupedSectionTcLabel' },
                value: savedTableType.groupedSectionTcLabel,
                name: 'groupedSectionTcLabel',
                disabled: true,
                multiline: true,
              }}
              handleChange={onChangeInput}
            />
            <TempTextInput
              inputSetting={{
                variant: cons.STYLE.VARIANT.STANDARD,
                label: { resource: resource, key: 'groupedSectionScLabel' },
                value: savedTableType.groupedSectionScLabel,
                name: 'groupedSectionScLabel',
                disabled: true,
                multiline: true,
              }}
              handleChange={onChangeInput}
            />
          </MUIAccordion>
          <MUIAccordion title="QUOTA">
            <div></div>
            <TempTextInput
              inputSetting={{
                label: { resource: resource, key: 'dailyQuota' },
                value: savedTableType.dailyQuota === -1 ? '' : savedTableType.dailyQuota,
                name: 'dailyQuota',
                placeholder: savedTableType.dailyQuota === -1 ? 'Unlimited' : '',
                onClickEndAdornmentIconButton: () => {
                  onClickUnlimitedEndButton('dailyQuota')
                },
                endAdornmentIconButton: <AllInclusiveIcon fontSize={cons.SIZE.SMALL} />,
                helperTextIcon: true,
                helperText: QueueTemplateValidation.getErrorMessages(validationCo.KEY_DAILY_QUOTA, savedTableType.customId).map((error) => {
                  return error
                }),
                type: cons.TYPE.NUMBER,
              }}
              handleChange={onChangeInput}
            />
            <TempTextInput
              inputSetting={{
                label: { resource: resource, key: 'personalQuota' },
                value: savedTableType.personalQuota === -1 ? '' : savedTableType.personalQuota,
                name: 'personalQuota',
                placeholder: savedTableType.personalQuota === -1 ? 'Unlimited' : '',
                onClickEndAdornmentIconButton: () => {
                  onClickUnlimitedEndButton('personalQuota')
                },
                endAdornmentIconButton: <AllInclusiveIcon fontSize={cons.SIZE.SMALL} />,
                helperTextIcon: true,
                helperText: QueueTemplateValidation.getErrorMessages(validationCo.KEY_PERSONAL_QUOTA, savedTableType.customId).map((error) => {
                  return error
                }),
                type: cons.TYPE.NUMBER,
              }}
              handleChange={onChangeInput}
            />
          </MUIAccordion>
          <MUIAccordion title="SIZE">
            {savedTableType.sizeSelectable && (
              <>
                <TempTextInput
                  inputSetting={{
                    label: { resource: resource, key: 'minSize' },
                    value: savedTableType.minSize,
                    name: 'minSize',
                    helperTextIcon: true,
                    helperText: QueueTemplateValidation.getErrorMessages(validationCo.KEY_MIN_SIZE, savedTableType.customId).map((error) => {
                      return error
                    }),
                    type: cons.TYPE.NUMBER,
                  }}
                  handleChange={onChangeInput}
                />
                <TempTextInput
                  inputSetting={{
                    label: { resource: resource, key: 'maxSize' },
                    value: savedTableType.maxSize,
                    name: 'maxSize',
                    helperTextIcon: true,
                    helperText: QueueTemplateValidation.getErrorMessages(validationCo.KEY_MAX_SIZE, savedTableType.customId).map((error) => {
                      return error
                    }),
                    type: cons.TYPE.NUMBER,
                  }}
                  handleChange={onChangeInput}
                />
              </>
            )}

            <TempAutoSelectInput
              inputSetting={{
                label: { resource: resource, key: 'sizeSelectable' },
                value: savedTableType.sizeSelectable,
                selectValue: cons.CHOICES.QUEUE_TEMPLATE.SIZE_SELECTABLE,
                name: 'sizeSelectable',
                helperTextIcon: true,
                helperText: QueueTemplateValidation.getErrorMessages(validationCo.KEY_SIZE_SELECTABLE, savedTableType.customId).map((error) => {
                  return error
                }),
                disableClearable: true,
              }}
              handleChange={onChangeInput}
            />
          </MUIAccordion>
          <MUIAccordion title="COLORS">
            <DefaultColorSetDropDown
              resource={resource}
              resourceKey={'applyTableTypeBackgroundColor'}
              onChangeInput={onChangeInput}
              value={savedTableType.applyTableTypeBackgroundColor}
              savedDefaultTableTypeColorList={savedDefaultTableTypeColorList}
            />
            <TempTextInput
              inputSetting={{
                label: { resource: resource, key: 'tableTypeBackgroundColor' },
                value: savedTableType.tableTypeBackgroundColor,
                name: 'tableTypeBackgroundColor',
                freeSolo: true,
                endAdornmentIconButton: (
                  <QueueTemplateColorPicker
                    color={savedTableType.tableTypeBackgroundColor}
                    setColor={(color) => onChangeInput('tableTypeBackgroundColor', color)}
                  />
                ),
                onClickEndAdornmentIconButton: () => {},
                helperTextIcon: true,
                helperText: QueueTemplateValidation.getErrorMessages(validationCo.KEY_TABLE_TYPE_BACKGROUND_COLOR, savedTableType.customId).map(
                  (error) => {
                    return error
                  }
                ),
                freeSoloUppercase: true,
                isColorFieldSpecialCase: true,
                clear: true,
              }}
              handleChange={onChangeInput}
            />
            <TempTextInput
              inputSetting={{
                label: { resource: resource, key: 'tableTypeForegroundColor' },
                value: savedTableType.tableTypeForegroundColor,
                name: 'tableTypeForegroundColor',
                freeSolo: true,
                endAdornmentIconButton: (
                  <QueueTemplateColorPicker
                    color={savedTableType.tableTypeForegroundColor}
                    setColor={(color) => onChangeInput('tableTypeForegroundColor', color)}
                  />
                ),
                onClickEndAdornmentIconButton: () => {},
                helperTextIcon: true,
                helperText: QueueTemplateValidation.getErrorMessages(validationCo.KEY_TABLE_TYPE_FOREGROUND_COLOR, savedTableType.customId).map(
                  (error) => {
                    return error
                  }
                ),
                freeSoloUppercase: true,
                isColorFieldSpecialCase: true,
                clear: true,
              }}
              handleChange={onChangeInput}
            />
            <DefaultColorSetDropDown
              resource={resource}
              resourceKey={'applyTableTypeMobileBackgroundColor'}
              onChangeInput={onChangeInput}
              value={savedTableType.applyTableTypeMobileBackgroundColor}
              savedDefaultTableTypeColorList={savedDefaultTableTypeColorList}
            />
            <TempTextInput
              inputSetting={{
                label: { resource: resource, key: 'tableTypeMobileBackgroundColor' },
                value: savedTableType.tableTypeMobileBackgroundColor,
                name: 'tableTypeMobileBackgroundColor',
                freeSolo: true,
                endAdornmentIconButton: (
                  <QueueTemplateColorPicker
                    color={savedTableType.tableTypeMobileBackgroundColor}
                    setColor={(color) => onChangeInput('tableTypeMobileBackgroundColor', color)}
                  />
                ),
                onClickEndAdornmentIconButton: () => {},
                helperTextIcon: true,
                helperText: QueueTemplateValidation.getErrorMessages(
                  validationCo.KEY_TABLE_TYPE_MOBILE_BACKGROUND_COLOR,
                  savedTableType.customId
                ).map((error) => {
                  return error
                }),
                freeSoloUppercase: true,
                isColorFieldSpecialCase: true,
                clear: true,
              }}
              handleChange={onChangeInput}
            />
            <TempTextInput
              inputSetting={{
                label: { resource: resource, key: 'tableTypeMobileForegroundColor' },
                value: savedTableType.tableTypeMobileForegroundColor,
                name: 'tableTypeMobileForegroundColor',
                endAdornmentIconButton: (
                  <QueueTemplateColorPicker
                    color={savedTableType.tableTypeMobileForegroundColor}
                    setColor={(color) => onChangeInput('tableTypeMobileForegroundColor', color)}
                  />
                ),
                onClickEndAdornmentIconButton: () => {},
                helperTextIcon: true,
                helperText: QueueTemplateValidation.getErrorMessages(
                  validationCo.KEY_TABLE_TYPE_MOBILE_FOREGROUND_COLOR,
                  savedTableType.customId
                ).map((error) => {
                  return error
                }),
                freeSoloUppercase: true,
                isColorFieldSpecialCase: true,
                clear: true,
              }}
              handleChange={onChangeInput}
            />
          </MUIAccordion>
          <MUIAccordion title="OTHER CONFIGS">
            <TempAutoSelectInput
              inputSetting={{
                label: { resource: resource, key: 'queueType' },
                value: savedTableType.queueType,
                selectValue: cons.CHOICES.QUEUE_TEMPLATE.QUEUE_TYPE,
                name: 'queueType',
              }}
              handleChange={onChangeInput}
            />
            <TempAutoSelectInput
              inputSetting={{
                label: { resource: resource, key: 'displayType' },
                value: savedTableType.displayType,
                selectValue: cons.CHOICES.QUEUE_TEMPLATE.DISPLAY_TYPE,
                name: 'displayType',
              }}
              handleChange={onChangeInput}
            />
            <TempAutoSelectInput
              inputSetting={{
                label: { resource: resource, key: 'rollTagType' },
                value: savedTableType.rollTagType,
                selectValue: cons.CHOICES.QUEUE_TEMPLATE.ROLL_TAG_TYPE,
                name: 'rollTagType',
              }}
              handleChange={onChangeInput}
            />
            <TempTextInput
              inputSetting={{
                label: { resource: resource, key: 'nextRemind' },
                value: savedTableType.nextRemind,
                name: 'nextRemind',
                helperTextIcon: true,
                helperText: QueueTemplateValidation.getErrorMessages(validationCo.KEY_NEXT_REMIND, savedTableType.customId).map((error) => {
                  return error
                }),
              }}
              handleChange={onChangeInput}
            />
            <SoundInfoVoiceDropdown
              value={savedTableType.voiceCode}
              resource={resource}
              onChangeInput={onChangeInput}
              soundVoiceList={soundVoiceList}
            />
          </MUIAccordion>
          <MUIAccordion title="AUTO TASK">
            <TempTextInput
              inputSetting={{
                label: { resource: resource, key: 'kioskRequestTicketStartTime' },
                value: savedTableType.kioskRequestTicketStartTime,
                name: 'kioskRequestTicketStartTime',
                step: '2',
                helperText: QueueTemplateValidation.getErrorMessages(validationCo.KEY_KIOSK_REQUEST_TICKET_START_TIME, savedTableType.customId).map(
                  (error) => {
                    return error
                  }
                ),
                helperTextIcon: true,
                disabled: getIsSyncRequestTicketTimeFieldsWithAllTableTypes(savedTableType.timeSectionCustomId, savedQueueTemplate),
                endAdornmentIconButton: <Clear fontSize={cons.SIZE.SMALL} />,
                onClickEndAdornmentIconButton: () => {
                  onChangeInput('kioskRequestTicketStartTime', null)
                },
                disabledStyle: true,
                type: cons.TYPE.TIME,
                disabledEndAdornmentIconButton: getIsSyncRequestTicketTimeFieldsWithAllTableTypes(
                  savedTableType.timeSectionCustomId,
                  savedQueueTemplate
                ),
              }}
              handleChange={onChangeInput}
            />
            <TempTextInput
              inputSetting={{
                label: { resource: resource, key: 'kioskRequestTicketEndTime' },
                value: savedTableType.kioskRequestTicketEndTime,
                name: 'kioskRequestTicketEndTime',
                step: '2',
                helperText: QueueTemplateValidation.getErrorMessages(validationCo.KEY_KIOSK_REQUEST_TICKET_END_TIME, savedTableType.customId).map(
                  (error) => {
                    return error
                  }
                ),
                helperTextIcon: true,
                disabled: getIsSyncRequestTicketTimeFieldsWithAllTableTypes(savedTableType.timeSectionCustomId, savedQueueTemplate),
                endAdornmentIconButton: <Clear fontSize={cons.SIZE.SMALL} />,
                onClickEndAdornmentIconButton: () => {
                  onChangeInput('kioskRequestTicketEndTime', null)
                },
                disabledStyle: true,
                type: cons.TYPE.TIME,
                disabledEndAdornmentIconButton: getIsSyncRequestTicketTimeFieldsWithAllTableTypes(
                  savedTableType.timeSectionCustomId,
                  savedQueueTemplate
                ),
              }}
              handleChange={onChangeInput}
            />
            <div></div>
            <TempTextInput
              inputSetting={{
                label: { resource: resource, key: 'mobileRequestTicketStartTime' },
                value: savedTableType.mobileRequestTicketStartTime,
                name: 'mobileRequestTicketStartTime',
                step: '2',
                helperText: QueueTemplateValidation.getErrorMessages(validationCo.KEY_MOBILE_REQUEST_TICKET_START_TIME, savedTableType.customId).map(
                  (error) => {
                    return error
                  }
                ),
                helperTextIcon: true,
                disabled: getIsSyncRequestTicketTimeFieldsWithAllTableTypes(savedTableType.timeSectionCustomId, savedQueueTemplate),
                endAdornmentIconButton: <Clear fontSize={cons.SIZE.SMALL} />,
                onClickEndAdornmentIconButton: () => {
                  onChangeInput('mobileRequestTicketStartTime', null)
                },
                disabledStyle: true,
                type: cons.TYPE.TIME,
                disabledEndAdornmentIconButton: getIsSyncRequestTicketTimeFieldsWithAllTableTypes(
                  savedTableType.timeSectionCustomId,
                  savedQueueTemplate
                ),
              }}
              handleChange={onChangeInput}
            />
            <TempTextInput
              inputSetting={{
                label: { resource: resource, key: 'mobileRequestTicketEndTime' },
                value: savedTableType.mobileRequestTicketEndTime,
                name: 'mobileRequestTicketEndTime',
                step: '2',
                helperText: QueueTemplateValidation.getErrorMessages(validationCo.KEY_MOBILE_REQUEST_TICKET_END_TIME, savedTableType.customId).map(
                  (error) => {
                    return error
                  }
                ),
                helperTextIcon: true,
                disabled: getIsSyncRequestTicketTimeFieldsWithAllTableTypes(savedTableType.timeSectionCustomId, savedQueueTemplate),
                endAdornmentIconButton: <Clear fontSize={cons.SIZE.SMALL} />,
                onClickEndAdornmentIconButton: () => {
                  onChangeInput('mobileRequestTicketEndTime', null)
                },
                disabledStyle: true,
                type: cons.TYPE.TIME,
                disabledEndAdornmentIconButton: getIsSyncRequestTicketTimeFieldsWithAllTableTypes(
                  savedTableType.timeSectionCustomId,
                  savedQueueTemplate
                ),
              }}
              handleChange={onChangeInput}
            />
          </MUIAccordion>
        </>
      ),
      btn: false,
    },
  ]

  function onSelectCheckbox(e, name) {
    const newSavedQueueTemplate = _.cloneDeep(savedQueueTemplate)
    const newSavedTableType = _.cloneDeep(savedTableType)

    let onUseTimeSectionIdx = newSavedQueueTemplate.findIndex((timeSection) => timeSection.customId === newSavedTableType.timeSectionCustomId)
    let onUseTimeSection = newSavedQueueTemplate.find((timeSection) => timeSection.customId === newSavedTableType.timeSectionCustomId)

    switch (name.key) {
      case 'isAutoTranslateTableTypeScLabel':
        newSavedTableType.disableLabel = e

        if (e) {
          newSavedTableType.tableTypeScLabel = newSavedTableType.tableTypeTcLabel
            ? sify(newSavedTableType.tableTypeTcLabel)
            : newSavedTableType.tableTypeTcLabel
        }
        break
      case 'isAutoTranslateTableTypeMobileScLabel':
        newSavedTableType.disableMobileLabel = e
        if (e) {
          newSavedTableType.tableTypeMobileScLabel = newSavedTableType.tableTypeMobileTcLabel
            ? sify(newSavedTableType.tableTypeMobileTcLabel)
            : newSavedTableType.tableTypeMobileTcLabel
        }
        break
      case 'isAutoTranslateTableTypeTicketScLabel':
        newSavedTableType.disableTicketLabel = e
        if (e) {
          newSavedTableType.tableTypeTicketScLabel = newSavedTableType.tableTypeTicketTcLabel
            ? sify(newSavedTableType.tableTypeTicketTcLabel)
            : newSavedTableType.tableTypeTicketTcLabel
        }
        break
      case 'isAutoTranslateGroupedSectionLabel':
        newSavedTableType.disableGroupedSection = e
        if (e) {
          newSavedTableType.groupedSectionScLabel = newSavedTableType.groupedSectionTcLabel
            ? sify(newSavedTableType.groupedSectionTcLabel)
            : newSavedTableType.groupedSectionTcLabel
        }
        break
      case 'isAutoTranslateGroupedSectionDescription':
        newSavedTableType.disableGroupedSectionDescription = e
        if (e) {
          newSavedTableType.groupedSectionScDescription = newSavedTableType.groupedSectionTcDescription
            ? sify(newSavedTableType.groupedSectionTcDescription)
            : newSavedTableType.groupedSectionTcDescription
        }
        break
      default:
    }

    let onUseTableTypeIndex = onUseTimeSection.tableTypeList.findIndex((item) => item.customId === newSavedTableType.customId)
    let onUseTableType = onUseTimeSection.tableTypeList.find((item) => item.customId === newSavedTableType.customId)

    onUseTableType = newSavedTableType
    if (onUseTableTypeIndex >= 0) {
      newSavedQueueTemplate[onUseTimeSectionIdx].tableTypeList[onUseTableTypeIndex] = onUseTableType
      dispatch(setQueueTemplateTableTypeValue(onUseTableType))
    } else {
      onUseTableType.customId = 'table_' + customIdNumber
      setCustomIdNumber(customIdNumber + 1)
      newSavedQueueTemplate[onUseTimeSectionIdx]?.tableTypeList.push(onUseTableType)
      dispatch(setQueueTemplateTableTypeValue(onUseTableType))
    }

    dispatch(setQueueTemplate(newSavedQueueTemplate))
    const newQueueTemplateInput = _.cloneDeep(cons.TIME_SECTION_DEFAULT_VALUE)
    dispatch(setQueueTemplateTimeSectionValue(newQueueTemplateInput))

    QueueTemplateValidation.validateTemplateTableType(newSavedQueueTemplate)
    setErrorVersion(QueueTemplateValidation.getVersion())
  }

  const onClickUnlimitedEndButton = (name) => {
    onChangeInput(name, -1)
  }

  const onChangeFieldToNull = (name) => {
    onChangeInput(name, null)
  }

  const onClickTicketTypeInput = () => {
    setShowTicketTypeTextPanel(true)
  }

  const onCloseTicketTypeDrawer = () => {
    setShowTicketTypeTextPanel(false)
  }

  const onRowClick = (data) => {
    onChangeInput('ticketType', data)
    setShowTicketTypeTextPanel(false)
  }

  const onCloseCreateTicketTypeDrawer = () => {
    setShowTicketTypeCreatePanel(false)
    setReload(true)
  }

  const generateTimeSectionDescription = () => {
    let label = ''

    label += savedTableType.startTime.substring(0, 5) + ' - '

    if (savedTableType.endTime && savedTableType.endTime.substring(3, 5) === '59' && savedTableType.endTime.substring(6, 8) === '59') {
      label += Number(savedTableType.endTime.substring(0, 2)) + 1 + ':00'
    } else if (savedTableType.endTime && savedTableType.endTime.substring(6, 8) === '59') {
      let endTime = Number(savedTableType.endTime.substring(3, 5)) + 1

      if (endTime < 10) {
        endTime = '0' + endTime
      }

      label += savedTableType.endTime.substring(0, 2) + ':' + endTime
    } else {
      label += savedTableType.endTime.substring(0, 5)
    }

    onChangeInput('timeSectionDescription', label)
  }

  function onChangeInput(name, e) {
    // TODO: refacfor for readability
    const newSavedQueueTemplate = _.cloneDeep(savedQueueTemplate)
    const newSavedTableType = _.cloneDeep(savedTableType)

    let onUseTimeSectionIdx = newSavedQueueTemplate.findIndex((timeSection) => timeSection.customId === newSavedTableType.timeSectionCustomId)
    let onUseTimeSection = newSavedQueueTemplate.find((timeSection) => timeSection.customId === newSavedTableType.timeSectionCustomId)
    let onUseTableTypeIdx = onUseTimeSection?.tableTypeList.findIndex((tableType) => tableType.customId === newSavedTableType.customId)
    let onUseTableType = onUseTimeSection?.tableTypeList.find((tableType) => tableType.customId === newSavedTableType.customId)

    switch (name) {
      case 'minSize':
      case 'maxSize':
      case 'nextRemind':
        newSavedTableType[`${name}`] = e && Number.isInteger(Number(e)) ? Number(e) : 0
        break
      // case 'cutOffMinute':
      //   newSavedTableType[name] = e && Number.isInteger(Number(e)) && e > 0 ? Number(e) : 0
      //   break
      case 'cutOffTime':
        newSavedTableType[name] = e
        break
      case 'dailyQuota':
      case 'personalQuota':
        newSavedTableType[`${name}`] = e && Number.isInteger(Number(e)) ? Number(e) : -1
        break
      case 'tableTypeScLabel':
      case 'tableTypeEnLabel':
        if (e) {
          newSavedTableType[`${name}`] = e
        } else {
          newSavedTableType[`${name}`] = null
        }
        break
      case 'tableTypeTcLabel':
        if (newSavedTableType.disableLabel === true && e) {
          newSavedTableType[`${name}`] = e
          newSavedTableType['tableTypeScLabel'] = sify(e)
        } else if (newSavedTableType.disableLabel && !e) {
          newSavedTableType[`${name}`] = null
          newSavedTableType['tableTypeScLabel'] = null
        } else if (e) {
          newSavedTableType[`${name}`] = e
        } else {
          newSavedTableType[`${name}`] = null
        }
        break
      case 'tableTypeMobileEnLabel':
      case 'tableTypeMobileScLabel':
      case 'tableTypeTicketEnLabel':
      case 'tableTypeTicketScLabel':
      case 'queueType':
      case 'displayType':
      case 'rollTagType':
      case 'voiceCode':
        newSavedTableType[name] = e || null
        if (e) {
          newSavedTableType[`${name}`] = e
        } else {
          newSavedTableType[`${name}`] = null
        }
        break
      case 'tableTypeMobileTcLabel':
        if (newSavedTableType.disableMobileLabel === true && e) {
          newSavedTableType[`${name}`] = e
          newSavedTableType['tableTypeMobileScLabel'] = sify(e)
        } else if (newSavedTableType.disableMobileLabel && !e) {
          newSavedTableType[`${name}`] = null
          newSavedTableType['tableTypeMobileScLabel'] = null
        } else if (e) {
          newSavedTableType[`${name}`] = e
        } else {
          newSavedTableType[`${name}`] = null
        }
        break
      case 'ticketType':
        newSavedTableType[`${name}`] = e.ticketType
        newSavedTableType['ticketTypeTcLabel'] = e.tcName
        newSavedTableType['ticketTypeEnLabel'] = e.enName
        newSavedTableType['ticketTypeScLabel'] = e.scName
        break
      case 'tableTypeTicketTcLabel':
        if (newSavedTableType.disableTicketLabel === true && e) {
          newSavedTableType[`${name}`] = e
          newSavedTableType['tableTypeTicketScLabel'] = sify(e)
        } else if (newSavedTableType.disableTicketLabel && !e) {
          newSavedTableType[`${name}`] = null
          newSavedTableType['tableTypeTicketScLabel'] = null
        } else if (e) {
          newSavedTableType[`${name}`] = e
        } else {
          newSavedTableType[`${name}`] = null
        }
        break
      case 'tableTypeBackgroundColor':
        newSavedTableType[`${name}`] = e ? e : null
        newSavedTableType.displayTableTypeBackgroundColor = getTableTypeBackgroundColor(
          e,
          onUseTableTypeIdx,
          savedTableTypeColorList || savedDefaultTableTypeColorList
        )
        break
      case 'tableTypeForegroundColor':
        newSavedTableType[`${name}`] = e ? e : null
        newSavedTableType.displayTableTypeForegroundColor = getTableTypeForegroundColor(
          e,
          onUseTableTypeIdx,
          savedTableTypeColorList || savedDefaultTableTypeColorList
        )
        break
      case 'tableTypeMobileBackgroundColor':
        newSavedTableType[`${name}`] = e ? e : null
        break
      case 'tableTypeMobileForegroundColor':
        newSavedTableType[`${name}`] = e ? e : null
        break
      case 'applyTableTypeBackgroundColor':
        if (e) {
          const [foregroundColor, backgroundColor] = e.split('&')
          newSavedTableType.tableTypeBackgroundColor = backgroundColor
          newSavedTableType.displayTableTypeBackgroundColor = backgroundColor
          newSavedTableType.tableTypeForegroundColor = foregroundColor
          newSavedTableType.displayTableTypeForegroundColor = foregroundColor
        } else {
          newSavedTableType.tableTypeBackgroundColor = null
          newSavedTableType.displayTableTypeBackgroundColor = getTableTypeBackgroundColor(
            e,
            onUseTableTypeIdx,
            savedTableTypeColorList || savedDefaultTableTypeColorList
          )
          newSavedTableType.tableTypeForegroundColor = null
          newSavedTableType.displayTableTypeForegroundColor = getTableTypeForegroundColor(
            e,
            onUseTableTypeIdx,
            savedTableTypeColorList || savedDefaultTableTypeColorList
          )
        }
        break
      case 'applyTableTypeMobileBackgroundColor':
        if (e) {
          const [foregroundColor, backgroundColor] = e.split('&')
          newSavedTableType.tableTypeMobileBackgroundColor = backgroundColor
          newSavedTableType.tableTypeMobileForegroundColor = foregroundColor
        } else {
          newSavedTableType.tableTypeMobileBackgroundColor = null
          newSavedTableType.tableTypeMobileForegroundColor = null
        }
        break
      case 'groupedSection':
        if (!e) {
          newSavedTableType[name] = null
          newSavedTableType.groupedSectionEnDescription = null
          newSavedTableType.groupedSectionEnLabel = null
          newSavedTableType.groupedSectionScDescription = null
          newSavedTableType.groupedSectionScLabel = null
          newSavedTableType.groupedSectionTcDescription = null
          newSavedTableType.groupedSectionTcLabel = null
        } else {
          const newGroupedSection = e
          newSavedTableType[`${name}`] = e
          const originalIdx = onUseTimeSection?.tableTypeList.findIndex(
            (tableType) => (tableType.customId || tableType.id) === (newSavedTableType.customId || newSavedTableType.id)
          )
          let newTableTypeList = [...onUseTimeSection.tableTypeList.slice(0, originalIdx), ...onUseTimeSection.tableTypeList.slice(originalIdx + 1)]
          const newTableTypeIdx = onUseTimeSection?.tableTypeList.findLastIndex((tableType) => tableType.groupedSection === newGroupedSection)
          newTableTypeList.splice(newTableTypeIdx === -1 ? originalIdx : newTableTypeIdx + 1, 0, newSavedTableType)
          onUseTimeSection.tableTypeList = newTableTypeList
        }
        break
      case 'sizeSelectable':
        newSavedTableType[name] = e
        if (!e) {
          newSavedTableType.minSize = 1
          newSavedTableType.maxSize = 1
        }
        break
      case 'parentTableType':
        newSavedTableType[name] = e
        if (!e) {
          newSavedTableType.parentTableType = null
        }
        break
      default:
        newSavedTableType[name] = e
    }

    onUseTableType = newSavedTableType

    if (onUseTableTypeIdx >= 0) {
      newSavedQueueTemplate[onUseTimeSectionIdx].tableTypeList[onUseTableTypeIdx] = onUseTableType
      dispatch(setQueueTemplateTableTypeValue(onUseTableType))
    } else {
      onUseTableType.customId = 'table_' + customIdNumber
      setCustomIdNumber(customIdNumber + 1)
      newSavedQueueTemplate[onUseTimeSectionIdx]?.tableTypeList.push(onUseTableType)
      dispatch(setQueueTemplateTableTypeValue(onUseTableType))
    }

    dispatch(setQueueTemplate(newSavedQueueTemplate))
    const newQueueTemplateInput = _.cloneDeep(cons.TIME_SECTION_DEFAULT_VALUE)
    dispatch(setQueueTemplateTimeSectionValue(newQueueTemplateInput))

    QueueTemplateValidation.validateTemplateTableType(newSavedQueueTemplate)
    setErrorVersion(QueueTemplateValidation.getVersion())
  }

  function getCurrentTimeSectionPossibleParentTableType() {
    const newSavedQueueTemplate = _.cloneDeep(savedQueueTemplate)
    const newSavedTableType = _.cloneDeep(savedTableType)

    let onUseTimeSection = newSavedQueueTemplate.find((item) => item.customId === newSavedTableType.timeSectionCustomId)
    return (
      onUseTimeSection?.tableTypeList
        ?.filter((tableType) => tableType.tableType !== savedTableType.tableType)
        ?.map((tableType) => ({ name: tableType.tableType, value: tableType.tableType })) || []
    )
  }

  // useEffect(() => {
  //   console.log('savedTableType', savedTableType)
  // }, [savedTableType])

  return (
    <>
      <EditCreateDisplay
        code={savedTableType && savedTableType.customId ? savedTableType.customId : ''}
        resource={resource}
        tabs={false}
        content={contents}
        saveDialog={{
          open: false,
          dialogActions: [],
        }}
        display={savedTableType.customId ? 'edit' : 'create'}
      />
      <Drawer classes={{ paper: 'drawer-margin-left' }} anchor="right" open={showTicketTypeTextPanel} onClose={onCloseTicketTypeDrawer}>
        <TicketTypeList
          editBtn={false}
          createBtn="custom"
          reload={reload}
          setReload={setReload}
          customCreate={() => {
            setShowTicketTypeCreatePanel(true)
          }}
          onClickListRow={(item) => onRowClick(item)}
          dialogFooterBtn={
            <TempBtn
              btnSetting={{
                variant: cons.STYLE.VARIANT.CONTAINED,
                color: cons.STYLE.PRIMARY,
                onClick: () => onCloseTicketTypeDrawer(),
                icon: <ClearIcon />,
                label: { key: 'close' },
              }}
            />
          }
        />
      </Drawer>
      <Drawer
        classes={{ paper: 'drawer-margin-left' }}
        anchor="right"
        open={showTicketTypeCreatePanel}
        onClose={() => onCloseCreateTicketTypeDrawer()}
      >
        <TicketTypeCreate
          newDialogBtn={[
            {
              label: { key: 'backList' },
              onClick: () => onCloseCreateTicketTypeDrawer(),
              icon: <LowPriorityIcon />,
            },
          ]}
        />
      </Drawer>
    </>
  )
}
