import { constants as cons } from '../Constants/constants'

export const getI18nObjValue = (guluLang, i18nObj) => {
    let locale = cons.I18N_OBJ_LANG.EN

    if (guluLang === cons.LANG.TC) {
        locale = cons.I18N_OBJ_LANG.ZH_HK
    }

    if (guluLang === cons.LANG.SC) {
        locale = cons.I18N_OBJ_LANG.ZH_CN
    }

    return i18nObj[locale] || ''
}

