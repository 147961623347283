import { useState, useEffect } from 'react'
import { useStore, useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import _ from 'lodash'

import { Card } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { constants as cons, STATUS } from '../../../Constants/constants'
import { getList, put } from '../../../helper/baseFetch'
import { generateTableCellToMultiLanguageName, getParamQueryString, getQuery, getQueryParamObject, getString } from '../../../helper/util'

import { ListDisplay } from '../../../Component/ListDisplay/ListDisplay'
import { setLoading, setMessage } from '../../../actions/systemAction'
import { TempBtn } from '../../../Component/Input/TempBtn'

const resourceName = 'RestaurantSlideshow'

export const AllSlideshowList = ({
  editBtn,
  createBtn,
  newFilter,
  newPath,
  newRedirectPath,
  newListTitles,
  newListFilters,
  changeUrlQueryString,
}) => {
  const dispatch = useDispatch()
  const store = useStore()
  const params = useParams()
  const [slideShowList, setSlideShowList] = useState([])
  const [ready, setReady] = useState(false)
  const [clear, setClear] = useState(false)
  const [selectedAllCheck, setSelectedAllCheck] = useState(false)
  const [slideShowMap, setSlideShowMap] = useState(new Map())
  const lang = useSelector((state) => state.lang.ui.lang)
  const history = useHistory()
  const location = useLocation()
  const filterParams = getQuery()

  const listTitles = newListTitles || [
    {
      key: cons.LIST_DISPLAY_TYPE.STRING,
      value: 'restUrlId',
      sort: true,
    },
    {
      key: cons.LIST_DISPLAY_TYPE.STRING,
      value: 'name',
      sort: true,
    },
    {
      key: cons.LIST_DISPLAY_TYPE.STRING,
      value: 'type',
      sort: true,
    },
    {
      key: cons.LIST_DISPLAY_TYPE.MEDIA,
      value: 'mediaUrl',
      sort: false,
      type: 'type',
    },
    {
      key: cons.LIST_DISPLAY_TYPE.DATE_TIME,
      value: 'startDisplayTimestamp',
      sort: true,
    },
    {
      key: cons.LIST_DISPLAY_TYPE.DATE_TIME,
      value: 'endDisplayTimestamp',
      sort: true,
    },
    {
      key: cons.LIST_DISPLAY_TYPE.STRING,
      value: 'status',
      sort: true,
    },
  ]
  const listFilters = newListFilters || [
    {
      className: 'filter-input',
      label: { resource: resourceName, key: 'showDefaultSlideshow' },
      clear: false,
      value: false,
      type: cons.TYPE.SELECT,
      selectValue: cons.CHOICES.RESTAURANT_SLIDESHOW.SHOW_DEFAULT,
      name: 'showDefaultSlideshow',
      size: cons.SIZE.SMALL,
    },
    {
      className: 'filter-input',
      label: { resource: resourceName, key: 'restUrlId' },
      clear: true,
      value: '',
      name: 'restUrlId',
      size: cons.SIZE.SMALL,
    },
    {
      className: 'filter-input',
      label: { resource: resourceName, key: 'type' },
      value: '',
      type: cons.TYPE.SELECT,
      selectValue: cons.CHOICES.RESTAURANT_SLIDESHOW.TYPE,
      name: 'type',
      size: cons.SIZE.SMALL,
    },
    {
      className: 'filter-input',
      label: { resource: resourceName, key: 'status' },
      value: '',
      type: cons.TYPE.SELECT,
      selectValue: cons.CHOICES.RESTAURANT_SLIDESHOW.FILTER_STATUS,
      name: 'status',
      size: cons.SIZE.SMALL,
    },
    {
      className: 'filter-input',
      label: { resource: resourceName, key: 'showExpired' },
      clear: false,
      value: false,
      type: cons.TYPE.SELECT,
      selectValue: cons.CHOICES.RESTAURANT_SLIDESHOW.SHOW_EXPIRED,
      name: 'showExpired',
      size: cons.SIZE.SMALL,
    },
  ]

  const [param, setParam] = useState(
    !changeUrlQueryString
      ? {
          path: newPath ? newPath : '',
          page: 0,
          rowsPerPage: 10,
          sort: 'updateTimestamp',
          direction: 'DESC',
          listFilters: _.cloneDeep(listFilters),
          count: 0,
        }
      : getQueryParamObject(
          {
            path: newPath ? newPath : '',
            page: 0,
            rowsPerPage: 10,
            sort: 'updateTimestamp',
            direction: 'DESC',
            listFilters: _.cloneDeep(listFilters),
            count: 0,
          },
          _.cloneDeep(listFilters),
          listTitles
        )
  )

  useEffect(() => {
    if (!changeUrlQueryString) {
      return
    }

    const querySyring = getParamQueryString(
      {
        path: newPath || '',
        page: 0,
        rowsPerPage: 10,
        sort: 'updateTimestamp',
        direction: 'DESC',
        listFilters: _.cloneDeep(listFilters),
        count: param.count,
      },
      param
    )

    const currentQueryString = window.location.search?.substring(1)

    if (querySyring === currentQueryString) {
      return
    }

    const newParam = getQueryParamObject(
      {
        path: newPath || '',
        page: 0,
        rowsPerPage: 10,
        sort: 'updateTimestamp',
        direction: 'DESC',
        listFilters: _.cloneDeep(listFilters),
        count: param.count + 1,
      },
      _.cloneDeep(listFilters),
      listTitles
    )

    setParam(newParam)

    const newQuery = getParamQueryString(
      {
        path: newPath ? newPath : '',
        page: 0,
        rowsPerPage: 10,
        sort: 'updateTimestamp',
        direction: 'DESC',
        listFilters: _.cloneDeep(listFilters),
        count: 0,
      },
      newParam
    )

    history.replace({ pathname: location.pathname, search: newQuery })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterParams])

  function onChangeParam(value) {
    value.count = value.count + 1

    if (!changeUrlQueryString) {
      setParam(value)
      return
    }

    const querySyring = getParamQueryString(
      {
        path: newPath || '',
        page: 0,
        rowsPerPage: 10,
        sort: 'updateTimestamp',
        direction: 'DESC',
        listFilters: _.cloneDeep(listFilters),
        count: 0,
      },
      value
    )

    history.push({ pathname: location.pathname, search: querySyring })
  }

  function onChangeListFilters(listFilters) {
    let newParam = _.cloneDeep(param)
    newParam.listFilters = listFilters
    newParam.page = 0
    onChangeParam(newParam)
  }

  function onChangeListSort(sort) {
    let newParam = _.cloneDeep(param)
    newParam.sort = sort
    newParam.direction = newParam.direction === 'DESC' ? 'ASC' : 'DESC'
    onChangeParam(newParam)
  }

  function onChangeListPage(page) {
    let newParam = _.cloneDeep(param)
    newParam.page = page
    onChangeParam(newParam)
  }

  function onChangeListRowsPerPage(rowsPerPage) {
    let newParam = _.cloneDeep(param)
    newParam.page = 0
    newParam.rowsPerPage = rowsPerPage
    onChangeParam(newParam)
  }

  useEffect(() => {
    let newParam = _.cloneDeep(param)
    const filter = newFilter ? _.cloneDeep(newFilter) : {}
    newParam.listFilters.forEach((filterItem) => {
      if (filterItem.value !== '' && filterItem.value !== undefined) {
        if (filterItem.name === 'showDefaultSlideshow') {
          if (filterItem.value) {
            filter.id = 'DEFAULT'
          } else {
            filter.id = ''
          }
          return
        }
        filter[filterItem.name] = filterItem.value
      }
    })

    getList(
      newParam.path || cons.PATH.SLIDESHOW.SLIDESHOW_ALL_LIST,
      {
        sort: { field: newParam.sort, order: newParam.direction },
        filter: filter,
        pagination: { page: newParam.page + 1, perPage: newParam.rowsPerPage },
      },
      (payload) => {
        payload.forEach((item) => (item.mediaUrl = item.media?.cosFileUrl))
        payload.forEach((item) => {
          if (!item.restEnName) {
            return
          }
          item.name = generateTableCellToMultiLanguageName(item.restTcName, item.restEnName, item.restScName)
        })
        setSlideShowList(payload)
        setSlideShowMap(new Map())
        setSelectedAllCheck(false)
        setReady(true)
      },
      undefined,
      store
    )
  }, [param.count]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (clear) {
      let newParam = _.cloneDeep(param)
      const filter = newFilter ? _.cloneDeep(newFilter) : {}
      newParam.listFilters.forEach((filterItem) => {
        if (filterItem.value !== '' && filterItem.value !== undefined) {
          if (filterItem.name === 'showDefaultSlideshow') {
            if (filterItem.value) {
              filter.id = 'DEFAULT'
            } else {
              filter.id = ''
            }
            return
          }
          filter[filterItem.name] = filterItem.value
        }
      })

      getList(
        newParam.path || cons.PATH.SLIDESHOW.SLIDESHOW_ALL_LIST,
        {
          sort: { field: newParam.sort, order: newParam.direction },
          filter: filter,
          pagination: { page: newParam.page + 1, perPage: newParam.rowsPerPage },
        },
        (payload) => {
          payload.forEach((item) => (item.mediaUrl = item.media?.cosFileUrl))
          payload.forEach((item) => {
            if (!item.restEnName) {
              return
            }
            item.name = generateTableCellToMultiLanguageName(item.restTcName, item.restEnName, item.restScName)
          })
          setSlideShowList(payload)
          setSlideShowMap(new Map())
          setSelectedAllCheck(false)
          setReady(true)
          setClear(false)
        },
        undefined,
        store
      )
    }
  }, [clear]) // eslint-disable-line react-hooks/exhaustive-deps

  const onMultiSelectList = (id, dataList, row) => {
    // id = row.id;

    let newSlideShowMap = _.cloneDeep(slideShowMap)
    if (id === 'all') {
      if (!selectedAllCheck) {
        setSlideShowMap(addNewEntryToMap(newSlideShowMap, slideShowList))
        //setSelectedAllCheck(true);
        return
      }

      slideShowList.forEach((item) => newSlideShowMap.delete(item.id))
      setSlideShowMap(newSlideShowMap)
      //setSelectedAllCheck(false);
      return
    }

    if (slideShowMap.has(id)) {
      newSlideShowMap.delete(id)
    } else {
      newSlideShowMap.set(id, row)
    }

    setSlideShowMap(newSlideShowMap)
    checkAllSelected(newSlideShowMap)
  }

  const addNewEntryToMap = (map, array) => {
    for (const item of array) {
      map.set(item.id, item)
    }
    return map
  }

  const checkAllSelected = (map) => {
    let selectedPerPage = slideShowList.filter((item) => {
      return map.has(item.id)
    }).length

    let currentPageEntry = slideShowList.length

    if (selectedPerPage === currentPageEntry && selectedPerPage !== 0) {
      setSelectedAllCheck(true)
    } else {
      setSelectedAllCheck(false)
    }
  }

  const onClickSubmitButton = (status) => {
    const replaceSlideshowList = Array.from(slideShowMap, ([name, value]) => name)

    dispatch(setLoading(true))

    put(
      cons.PATH.SLIDESHOW.SLIDESHOW_BULK_UPDATE,
      { idList: replaceSlideshowList, status: status },
      () => {
        dispatch(setLoading(false))

        let newParam = _.cloneDeep(param)
        onChangeParam(newParam)
        dispatch(setMessage(getString(lang, '', 'editSuccess')))
        setClear(true)
      },
      undefined,
      store
    )

    let newParam = _.cloneDeep(param)
    const filter = newFilter ? _.cloneDeep(newFilter) : {}
    newParam.listFilters.forEach((filterItem) => {
      if (filterItem.value !== '' && filterItem.value !== undefined) {
        if (filterItem.name === 'showDefaultSlideshow') {
          if (filterItem.value) {
            filter.id = 'DEFAULT'
          } else {
            filter.id = ''
          }
          return
        }
        filter[filterItem.name] = filterItem.value
      }
    })

    getList(
      newParam.path || cons.PATH.SLIDESHOW.SLIDESHOW_ALL_LIST,
      {
        sort: { field: newParam.sort, order: newParam.direction },
        filter: filter,
        pagination: { page: newParam.page + 1, perPage: newParam.rowsPerPage },
      },
      (payload) => {
        payload.forEach((item) => (item.mediaUrl = item.media?.cosFileUrl))
        payload.forEach((item) => {
          if (!item.restEnName) {
            return
          }
          item.name = generateTableCellToMultiLanguageName(item.restTcName, item.restEnName, item.restScName)
        })
        setSlideShowList(payload)
        setSlideShowMap(new Map())
        setSelectedAllCheck(false)
        setReady(true)
      },
      undefined,
      store
    )
  }

  useEffect(() => {
    if (!slideShowMap) {
      return
    }

    checkAllSelected(slideShowMap)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slideShowList, slideShowMap])

  // useEffect(() => {
  //   console.log('param', param)
  // }, [param])

  // useEffect(() => {
  //   console.log('clear', clear)
  // }, [clear])

  return (
    ready && (
      <Card className="card-wrapper overflow">
        <ListDisplay
          page={param.page}
          rowsPerPage={param.rowsPerPage}
          onChangeListSort={onChangeListSort}
          resource={resourceName}
          dataList={slideShowList}
          sort={param.sort}
          direction={param.direction}
          listTitles={listTitles}
          listFilters={param.listFilters}
          mutipleSelect={true}
          onMultiSelectList={onMultiSelectList}
          selectMap={slideShowMap}
          selectedAllCheck={selectedAllCheck}
          idName="id"
          dialogFooterBtn={
            slideShowMap?.size > 0 ? (
              <div className="get-push-card-wrapper">
                <TempBtn
                  btnSetting={{
                    className: 'get-push-card-cancel-btn margin-right-16',
                    variant: cons.STYLE.VARIANT.CONTAINED,
                    color: cons.STYLE.PRIMARY,
                    onClick: () => onClickSubmitButton(STATUS.delete),
                    icon: <DeleteIcon />,
                    label: { resource: resourceName, key: 'deactivate' },
                  }}
                ></TempBtn>
                <TempBtn
                  btnSetting={{
                    className: 'get-push-card-cancel-btn margin-right-16',
                    variant: cons.STYLE.VARIANT.CONTAINED,
                    color: cons.STYLE.PRIMARY,
                    onClick: () => onClickSubmitButton(STATUS.pending),
                    icon: <VisibilityOffIcon />,
                    label: { resource: resourceName, key: 'pending' },
                  }}
                ></TempBtn>
                <TempBtn
                  btnSetting={{
                    className: 'get-push-card-cancel-btn',
                    variant: cons.STYLE.VARIANT.CONTAINED,
                    color: cons.STYLE.PRIMARY,
                    onClick: () => onClickSubmitButton(STATUS.active),
                    icon: <VisibilityIcon />,
                    label: { resource: resourceName, key: 'active' },
                  }}
                ></TempBtn>
              </div>
            ) : (
              <></>
            )
          }
          onChangeListPage={onChangeListPage}
          onChangeListRowsPerPage={onChangeListRowsPerPage}
          editBtn={editBtn}
          createBtn={createBtn}
          idParam={params.id}
          onChangeListFilters={onChangeListFilters}
          redirectPath={'/' + (newRedirectPath ? newRedirectPath : cons.PATH.SLIDESHOW.ALL_SLIDESHOW) + '/'}
        />
      </Card>
    )
  )
}
