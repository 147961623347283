import { useState, useEffect } from 'react'
import { useDispatch, useSelector, useStore } from 'react-redux'
import { useHistory } from 'react-router-dom'
import _ from 'lodash'
import moment from 'moment'

import SaveIcon from '@mui/icons-material/Save'
import ClearIcon from '@mui/icons-material/Clear'
import LowPriorityIcon from '@mui/icons-material/LowPriority'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import { Drawer } from '@mui/material'
import { Card } from '@mui/material'
import Paper from '@mui/material/Paper'

import { constants } from '../../Constants/constants'
import { post } from '../../helper/baseFetch'
import { setLoading } from '../../actions/systemAction'
import { setConfigMap } from '../../actions/configMapAction'
import { setBannerList, setBanner } from '../../actions/bannerAction'
import { EditCreateDisplay } from '../../Component/EditCreateDisplay/EditCreateDisplay'
import { TempBtn } from '../../Component/Input/TempBtn'
import { RestaurantList } from '../Restaurant/RestaurantList'
import { GlobalDialog } from '../../Component/GlobalDialog'
import BannerValidation from '../../validations/bannerValidation'
import { bannerValidationConstants } from '../../Constants/validationConstants'
import { formatMultipleSelectArray, helperTextStyles } from '../../helper/util'
import { AddRestaurantButton } from './AddRestaurantButton'
import { getContent } from '../../Component/EditCreateDisplay/EditUtil'
import { formatConfigMapList } from '../Banner/BannerUtil'

export const ConfigMapCreate = ({ toolBar, type }) => {
  const dispatch = useDispatch()
  const store = useStore()
  const history = useHistory()
  const helperTextStylesClasses = helperTextStyles()
  const savedBannerList = useSelector((state) => state.banner.ui.bannerList)
  const savedBanner = useSelector((state) => state.banner.ui.banner)
  const orignalConfigMap = useSelector((state) => state.configMap.ui.configMap)
  const savedConfigMap = type === 'Banner' ? savedBanner.configMapList : orignalConfigMap
  const [clear, setClear] = useState(false)
  const [drawerOpen, setDrawerOpen] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [drawerIndex, setDrawerIndex] = useState('')
  const [dialog, setDialog] = useState({
    open: false,
    dialogActions: [],
  })
  const resource = 'ConfigMap'
  const tabs = [
    {
      label: { resource: resource, key: 'info' },
      tabIndex: constants.TICKET_BANNER_MAP_TAB.INFO,
    },
  ]

  const handleConfigMap = () => {
    let tempSavedConfigMapList = _.cloneDeep(savedConfigMap || [])

    tempSavedConfigMapList.push({
      serviceType: '',
      ticketStatus: '',
      startDisplayDate: '',
      endDisplayDate: '',
      restUrlId: '',
      restEnName: '',
      restTcName: '',
      restScName: '',
      channel: '',
      restUrlMap: [],
    })

    if (type === 'Banner') {
      let tempSavedBanner = _.cloneDeep(savedBanner)
      let tempBannerList = _.cloneDeep(savedBannerList)
      tempSavedBanner.configMapList = tempSavedConfigMapList
      dispatch(setBanner(tempSavedBanner))
      tempBannerList = tempBannerList.map((bannerListItem) => {
        if (bannerListItem.customId === tempSavedBanner.customId) {
          bannerListItem = tempSavedBanner
        }
        return bannerListItem
      })
      dispatch(setBannerList(tempBannerList))
    } else {
      dispatch(setConfigMap(tempSavedConfigMapList))
    }
  }

  function handeleDelete(item, index) {
    let tempSavedConfigMap = _.cloneDeep(savedConfigMap)
    tempSavedConfigMap = tempSavedConfigMap.filter((tempSavedConfigMap, tempSavedConfigMapIndex) => tempSavedConfigMapIndex !== index)
    if (type === 'Banner') {
      let tempSavedBanner = _.cloneDeep(savedBanner)
      let tempBannerList = _.cloneDeep(savedBannerList)
      tempSavedBanner.configMapList = tempSavedConfigMap
      dispatch(setBanner(tempSavedBanner))
      tempBannerList = tempBannerList.map((bannerListItem) => {
        if (bannerListItem.customId === tempSavedBanner.customId) {
          bannerListItem = tempSavedBanner
        }
        return bannerListItem
      })
      dispatch(setBannerList(tempBannerList))
    } else {
      dispatch(setConfigMap(tempSavedConfigMap))
    }
  }

  const bottomBtns = [
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: 'save' },
      onClick: onClickSave,
      className: 'margin-left-16',
      icon: <SaveIcon />,
    },
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: 'clear' },
      disabled: false,
      onClick: onClickClear,
      className: 'margin-left-16',
      icon: <ClearIcon />,
    },
  ]

  const content = [
    {
      tab: (
        <>
          <TempBtn
            btnSetting={{
              className: 'margin-left-16',
              onClick: () => {
                handleAddConfigMapList()
              },
              variant: constants.STYLE.VARIANT.CONTAINED,
              color: constants.STYLE.PRIMARY,
              label: { resource: resource, key: 'addConfigMap' },
              icon: <AddIcon />,
            }}
          >
            <AddIcon />
          </TempBtn>
          {savedConfigMap &&
            savedConfigMap.length > 0 &&
            savedConfigMap.map((savedConfigMapData, index) => {
              let newTempConfigMapList = [
                {
                  className: 'edit-create-input',
                  size: constants.SIZE.SMALL,
                  label: { resource: resource, key: 'serviceType' },
                  value: savedConfigMapData.serviceType,
                  type: constants.TYPE.SELECT,
                  selectValue: constants.CHOICES.CONFIG_MAP.SERVICE_TYPE,
                  name: 'serviceType',
                  isEndOfLine: false,
                },
                {
                  className: 'edit-create-input',
                  size: constants.SIZE.SMALL,
                  label: { resource: resource, key: 'ticketStatus' },
                  value: savedConfigMapData.ticketStatus,
                  type: constants.TYPE.SELECT,
                  selectValue: savedConfigMapData.serviceType ? constants.CHOICES.CONFIG_MAP[savedConfigMapData.serviceType].TICKET_STATUS : [],
                  name: 'ticketStatus',
                  isEndOfLine: true,
                  disabled: savedConfigMapData.serviceType ? false : true,
                },
                {
                  className: 'edit-create-input',
                  size: constants.SIZE.SMALL,
                  label: { resource: resource, key: 'startDisplayDate' },
                  required: true,
                  value: savedConfigMapData.startDisplayDate,
                  name: 'startDisplayDate',
                  helperTextIcon: true,
                  helperText: BannerValidation.getErrorMessages(
                    `${index}-${bannerValidationConstants.KEY_CONFIG_MAP_START_DISPLAY_DATE}`,
                    savedBanner.customId ? savedBanner.customId : ''
                  ),
                  FormHelperTextProps: { className: helperTextStylesClasses.root },
                  type: constants.TYPE.DATETIME_LOCAL,
                  step: 1,
                  isEndOfLine: false,
                },
                {
                  className: 'edit-create-input',
                  size: constants.SIZE.SMALL,
                  label: { resource: resource, key: 'endDisplayDate' },
                  required: true,
                  value: savedConfigMapData.endDisplayDate,
                  name: 'endDisplayDate',
                  helperTextIcon: true,
                  helperText: BannerValidation.getErrorMessages(
                    `${index}-${bannerValidationConstants.KEY_CONFIG_MAP_END_DISPLAY_DATE}`,
                    savedBanner.customId ? savedBanner.customId : ''
                  ),
                  FormHelperTextProps: { className: helperTextStylesClasses.root },
                  type: constants.TYPE.DATETIME_LOCAL,
                  step: 1,
                  isEndOfLine: true,
                },
                {
                  label: { resource: resource, key: 'channel' },
                  value: formatMultipleSelectArray(savedConfigMapData.channel, constants.CHOICES.CONFIG_MAP.CHANNEL),
                  type: constants.TYPE.SELECT,
                  selectValue: constants.CHOICES.CONFIG_MAP.CHANNEL,
                  multiple: true,
                  name: 'channel',
                  isEndOfLine: true,
                },
                {
                  type: constants.TYPE.FUNCTION,
                  value: (
                    <AddRestaurantButton
                      idx={index}
                      resource={resource}
                      restUrlMap={savedConfigMapData.restUrlMap || []}
                      onChangeInput={onChangeInput}
                    />
                  ),
                },
              ]

              return (
                <>
                  {' '}
                  <Card key={index} className="image-list">
                    <>
                      <div className="delete-icon">
                        <TempBtn
                          btnSetting={{
                            className: 'edit-create-btn',
                            variant: constants.STYLE.VARIANT.CONTAINED,
                            color: constants.STYLE.PRIMARY,
                            onClick: () => handeleDelete(savedConfigMapData, index),
                            label: { key: 'deleteBtn' },
                            icon: <DeleteIcon />,
                          }}
                        />
                      </div>
                      {newTempConfigMapList.map((inputSetting, newTempConfigMapindex) => {
                        let content = getContent(inputSetting, index, onChangeInput)
                        return (
                          <div className="edit-display-wrapper" key={newTempConfigMapindex}>
                            {content}
                            {inputSetting.isEndOfLine && <div></div>}
                          </div>
                        )
                      })}
                    </>
                  </Card>
                  <Drawer classes={{ paper: 'drawer-margin-left' }} anchor="right" open={drawerOpen} onClose={onClickCloseDrawer}>
                    <Paper>
                      <RestaurantList
                        editBtn={false}
                        createBtn={false}
                        btnList={false}
                        onClickListRow={(item) => onClickListRow(item, drawerIndex)}
                        dialogFooterBtn={
                          <>
                            <TempBtn
                              btnSetting={{
                                variant: constants.STYLE.VARIANT.CONTAINED,
                                color: constants.STYLE.PRIMARY,
                                onClick: onClickCloseDrawer,
                                icon: <ClearIcon />,
                                label: { key: 'close' },
                              }}
                            />
                          </>
                        }
                      />
                    </Paper>
                  </Drawer>
                </>
              )
            })}
        </>
      ),
      btn: toolBar !== false ? true : false,
    },
  ]
  function onChangeInput(name, e, index) {
    let tempSavedConfigMap = _.cloneDeep(savedConfigMap)
    if (name === 'clearRestUrlId') {
      tempSavedConfigMap[index]['restUrlId'] = ''
      tempSavedConfigMap[index]['restEnName'] = ''
      tempSavedConfigMap[index]['restTcName'] = ''
      tempSavedConfigMap[index]['restScName'] = ''
    } else if (name === 'restUrlMap') {
      tempSavedConfigMap[index][`${name}`] = e
    } else if (name === 'startDisplayDate' || name === 'endDisplayDate') {
      tempSavedConfigMap[index][`${name}`] = Number(moment(e).format('x'))
    } else {
      tempSavedConfigMap[index][`${name}`] = e
    }
    if (name === 'restUrlId' && e === '') {
      tempSavedConfigMap[index]['restEnName'] = ''
      tempSavedConfigMap[index]['restTcName'] = ''
      tempSavedConfigMap[index]['restScName'] = ''
    }
    if (type === 'Banner') {
      let tempSavedBanner = _.cloneDeep(savedBanner)
      let tempBannerList = _.cloneDeep(savedBannerList)
      tempSavedBanner.configMapList = tempSavedConfigMap
      dispatch(setBanner(tempSavedBanner))
      tempBannerList = tempBannerList.map((bannerListItem) => {
        if (bannerListItem.customId === tempSavedBanner.customId) {
          bannerListItem = tempSavedBanner
        }
        return bannerListItem
      })
      dispatch(setBannerList(tempBannerList))
    } else {
      dispatch(setConfigMap(tempSavedConfigMap))
    }
  }

  function onClickCloseDrawer() {
    setDrawerOpen(false)
  }

  function onClickListRow(data, index) {
    let tempSavedConfigMap = _.cloneDeep(savedConfigMap)
    tempSavedConfigMap.forEach((item, tempSavedConfigMapIndex) => {
      if (tempSavedConfigMapIndex === index) {
        item.restUrlId = data.restUrlId
        item.restEnName = data.enName
        item.restTcName = data.tcName
        item.restScName = data.scName
      }
    })
    if (type === 'Banner') {
      let tempSavedBanner = _.cloneDeep(savedBanner)
      let tempBannerList = _.cloneDeep(savedBannerList)
      tempSavedBanner.configMapList = tempSavedConfigMap
      dispatch(setBanner(tempSavedBanner))
      tempBannerList = tempBannerList.map((bannerListItem) => {
        if (bannerListItem.customId === tempSavedBanner.customId) {
          bannerListItem = tempSavedBanner
        }
        return bannerListItem
      })
      dispatch(setBannerList(tempBannerList))
    } else {
      dispatch(setConfigMap(tempSavedConfigMap))
    }
    onClickCloseDrawer()
  }

  function handleAddConfigMapList() {
    dispatch(setLoading(true))
    handleConfigMap()
    dispatch(setLoading(false))
  }

  const onClickBackToCreate = () => {
    setDialog({
      open: false,
      dialogActions: [],
    })
    onClickClear()
  }
  const onClickBackToList = () => {
    setDialog({
      open: false,
      dialogActions: [],
    })
    history.push(
      `/${constants.PATH.TICKET.TICKET_BANNER}/${savedBanner && savedBanner[0] ? savedBanner[0].id : savedBanner.id}/${
        constants.TICKET_BANNER_TAB.TICKET_BANNER_MAP_LIST
      }`
    )
  }

  function onClickSave() {
    const bannerId = savedBanner && savedBanner[0] ? savedBanner[0].id : savedBanner.id
    const configMapList = formatConfigMapList(savedConfigMap)

    post(
      constants.PATH.TICKET.TICKET_BANNER_MAP_LIST,
      { bannerId: bannerId, configMapList: configMapList },
      () => {
        setDialog({
          open: true,
          onClose: onClickBackToCreate,
          dialogContentText: { key: 'editSuccess' },
          dialogActions: [
            { label: { key: 'backEdit' }, onClick: onClickBackToCreate, icon: <ArrowBackIcon /> },
            { label: { key: 'backList' }, onClick: onClickBackToList, icon: <LowPriorityIcon /> },
          ],
        })
        setClear(false)
      },
      undefined,
      store
    )
  }

  function onClickClear() {
    setClear(true)
  }

  useEffect(() => {
    dispatch(setConfigMap([]))
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (clear === true) {
      dispatch(setLoading(true))
      dispatch(setConfigMap([]))
      dispatch(setLoading(false))
      setClear(false)
    }
  }, [clear]) // eslint-disable-line react-hooks/exhaustive-deps

  // useEffect(() => {
  //   console.log('savedConfigMap', savedConfigMap)
  // }, [savedConfigMap])

  return (
    <>
      <EditCreateDisplay
        code={''}
        resource={resource}
        tabs={toolBar !== false ? tabs : ''}
        content={content}
        bottomBtns={bottomBtns}
        onChangeInput={onChangeInput}
        mapName={{ resource: 'Banner', key: 'bannerId' }}
        mapCode={savedBanner.id}
        display={'create'}
      />
      {dialog && dialog.open && <GlobalDialog dialogInfo={dialog} />}
    </>
  )
}
