import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import QRCodeStyling from 'qr-code-styling'

import { Card } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import GetAppIcon from '@mui/icons-material/GetApp'

import { UploadImage } from '../UploadImage/UploadImage'
import MapModal from '../../Component/Restaurant/GoogleMap/MapModal'
import SmallGoogleMap from '../../Component/Restaurant/GoogleMap/SmallGoogleMap'
import { constants, IMAGE_TYPE, UPLOAD_TYPE } from '../../Constants/constants'
import { TempBtn } from '../Input/TempBtn'
import { getString, toDataURL } from '../../helper/util'

import '../../styles/map.css'
import '../../styles/restaurant.scss'
import { ZigZagQRCode } from '../ZigZagQRCode/ZigZagQRCode'
import { UrlGenerator } from '../../Page/Restaurant/UrlGenerator'
import logoImage from '../../pictures/logo-white-border.svg'

const qrCode = new QRCodeStyling({
  width: 1000,
  height: 1000,
  data: 'Hey this is a QR code. Change this value in the main_screen.dart file.',
  image: logoImage,
  dotsOptions: {
    type: 'dots',
  },
  cornersDotOptions: {
    type: 'square',
  },
  cornersSquareOptions: {
    type: 'zig-zag',
  },
  qrOptions: {
    errorCorrectionLevel: 'L',
  },
  imageOptions: {
    crossOrigin: 'anonymous',
    imageSize: 0.5,
  },
})

export const Aside = ({ handleSaveImage, handleAddress }) => {
  const params = useParams()
  const savedRestaurant = useSelector((state) => state.restaurant.ui.restaurant)
  const lang = useSelector((state) => state.lang.ui.lang)
  const theme = useSelector((state) => state.theme.ui.theme)
  const [isShowing, setIsShowing] = useState(false)
  const [qRCodeSetting, setQRCodeSetting] = useState(null)
  let myPosition = {
    enAddress: savedRestaurant.enAddress,
    lat: savedRestaurant.coorX ? savedRestaurant.coorX : constants.MAP.LAT,
    lng: savedRestaurant.coorY ? savedRestaurant.coorY : constants.MAP.LNG,
    tcAddress: savedRestaurant.tcAddress,
    z: savedRestaurant.coorZ ? savedRestaurant.coorZ : constants.MAP.ZOOM,
  }

  function toggle() {
    setIsShowing(!isShowing)
  }

  const downloadQR = async (value) => {
    const base64Image = await toDataURL(logoImage)

    qrCode.update({
      data: value,
      width: 1000,
      height: 1000,
      image: base64Image,
    })

    const blobFile = await qrCode.getRawData('svg')
    const img = document.createElement('img')
    img.src = URL.createObjectURL(blobFile)
    img.onload = () => {
      const canvas = document.createElement('canvas')
      canvas.width = 1000
      canvas.height = 1000
      const context = canvas.getContext('2d')
      context.drawImage(img, 0, 0, 1000, 1000)
      const ImgBase64 = canvas.toDataURL('image/png')
      let downloadLink = document.createElement('a')
      downloadLink.href = ImgBase64
      downloadLink.download = `${`qrCode-${qRCodeSetting.domain}-${qRCodeSetting.specificLang}-${
        qRCodeSetting.universalLink === 'true' ? 'App_and_Website' : 'Website'
      }`}.png`
      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)
    }
  }

  return (
    <div className="restaurant-aside-wrapper">
      <Card className="restaurant-aside-qrCode-card">
        <div className="url-map-title">
          <div className="sub-title">{getString(lang, 'restaurant', 'qrCode', theme)}</div>
        </div>
        <UrlGenerator restUrlId={params.id} handleSetting={setQRCodeSetting} />
        {qRCodeSetting && (
          <div className="url-map-wrapper">
            <ZigZagQRCode id="qrCode" value={qRCodeSetting.url} />
            <TempBtn
              btnSetting={{
                className: 'download-text',
                onClick: () => downloadQR(qRCodeSetting.url),
                variant: constants.STYLE.VARIANT.CONTAINED,
                color: constants.STYLE.PRIMARY,
                label: { key: 'downloadQRCode' },
                icon: <GetAppIcon />,
              }}
            />
            <a target="_blank" rel="noopener noreferrer" href={qRCodeSetting.url}>
              <span className="restaurant-aside-qrCode">{qRCodeSetting.url}</span>
            </a>
          </div>
        )}
      </Card>
      <UploadImage
        picture={
          savedRestaurant.picture
            ? savedRestaurant.picture.cosFileUrl
              ? savedRestaurant.picture
              : savedRestaurant.picture.cosFileUrl
            : savedRestaurant.imageCosUrl.cosFileUrl
        }
        title={{ resource: 'restaurant', key: 'image' }}
        name="picture"
        type={IMAGE_TYPE.RESTAURANT_IMAGE}
        uploadType={UPLOAD_TYPE.FORMDATA}
        uploadImagePath={constants.PATH.SHOP.UPLOAD_RESTAURANT_MEDIA}
        saveImage={handleSaveImage}
      />
      <Card className="restaurant-aside-map-card">
        <div className="url-map-title">
          <div className="sub-title">{getString(lang, 'restaurant', 'googleMap', theme)}</div>
          <TempBtn
            btnSetting={{
              onClick: toggle,
              color: constants.STYLE.PRIMARY,
              label: { key: 'edit' },
              icon: <EditIcon />,
            }}
          ></TempBtn>
          <MapModal isShowing={isShowing} hide={toggle} myPosition={myPosition} sendAddress={handleAddress} />
        </div>
        <span variant={constants.STYLE.VARIANT.BODY2}>
          <div>{myPosition && <SmallGoogleMap getPosition={myPosition} />}</div>
        </span>
      </Card>
    </div>
  )
}
