
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector, useStore } from "react-redux";
import { useHistory } from "react-router-dom";
import _ from "lodash";

import SaveIcon from '@mui/icons-material/Save';
import ClearIcon from '@mui/icons-material/Clear';
import LowPriorityIcon from '@mui/icons-material/LowPriority';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { makeStyles } from '@mui/styles';

import { constants as cons } from '../../../Constants/constants';
import { getOne, put } from "../../../helper/baseFetch";
import { setProductMap } from "../../../actions/productMapAction";
import { EditCreateDisplay } from '../../EditCreateDisplay/EditCreateDisplay';
import { TempTextInput } from "../../Input/TempTextInput";
import { TempAutoSelectInput } from "../../Input/TempAutoSelectInput";
import { GlobalDialog } from "../../GlobalDialog";
import ProductMapValidation from "../../../validations/productMapValidation";
import { productMapValidationConstants } from '../../../Constants/validationConstants';
import { Launch } from "@mui/icons-material";

const helperTextStyles = makeStyles(theme => ({
  root: {
    color: "red !important",
  },
}));

export const ProductMapEdit = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const store = useStore();
  const history = useHistory();
  const [ready, setReady] = useState(false);
  const [clear, setClear] = useState(true);
  const [dialog, setDialog] = useState({
    open: false,
    dialogActions: []
  });
  const resource = "ProductMapList";
  const savedProductMap = useSelector(state => state.productMap.ui.productMap)
  const savedProduct = useSelector(state => state.product.ui.product);
  const helperTextStylesClasses = helperTextStyles();
  const [, setErrorVersion] = useState(ProductMapValidation.getVersion());

  const tabs = [
    {
      label: { resource: resource, key: "info" },
      tabIndex: cons.PRODUCT_MAP_TAB.INFO
    },
  ]

  const content = [
    {
      tab: <>
        {savedProductMap && savedProductMap.length > 0 && savedProductMap.map((savedProduct, idx) => {
          const fields = [            
            {
              className: "edit-create-input",
              variant: cons.STYLE.VARIANT.STANDARD,
              label: { resource: resource, key: "productCode" },
              value: savedProduct.productCode,
              name: "productCode",
              isEndOfLine: true,
              disabled: true,
              endAdornmentIconButtonList: [
                <Launch
                  fontSize={cons.SIZE.SMALL}
                  onClick={() =>
                    window.open(`${window.location.origin}/rack_product/product/${savedProduct.productId}/0`, '_blank')
                  }
                />,
              ],
            },
            {
              className: "edit-create-input",
              variant: cons.STYLE.VARIANT.STANDARD,
              size: cons.SIZE.SMALL,
              label: { resource: resource, key: "productEnName" },
              value: savedProduct.productEnName,
              name: "productEnName",
              isEndOfLine: false,
              disabled: true,
            },
            {
              className: "edit-create-input",
              variant: cons.STYLE.VARIANT.STANDARD,
              size: cons.SIZE.SMALL,
              label: { resource: resource, key: "productTcName" },
              value: savedProduct.productTcName,
              name: "productTcName",
              isEndOfLine: false,
              disabled: true,
            },
            {
              className: "edit-create-input",
              variant: cons.STYLE.VARIANT.STANDARD,
              size: cons.SIZE.SMALL,
              label: { resource: resource, key: "productScName" },
              value: savedProduct.productScName,
              name: "productScName",
              isEndOfLine: true,
              disabled: true,
            },
            {
              className: "edit-create-input",
              variant: cons.STYLE.VARIANT.STANDARD,
              size: cons.SIZE.SMALL,
              label: { resource: resource, key: "productType" },
              value: savedProduct.productType,
              name: "productType",
              isEndOfLine: false,
              disabled: true,
            },
            {
              className: "edit-create-input",
              variant: cons.STYLE.VARIANT.STANDARD,
              size: cons.SIZE.SMALL,
              label: { resource: resource, key: "productChannel" },
              value: savedProduct.productChannel,
              name: "productChannel",
              isEndOfLine: true,
              disabled: true,
            },
            {
              className: "edit-create-input",
              size: cons.SIZE.SMALL,
              label: { resource: resource, key: "displaySequence" },
              value: savedProduct.displaySequence,
              name: "displaySequence",
              //eslint-disable-next-line
              pattern: "^\d*$",
              isEndOfLine: false,
              required: true,
              helperTextIcon: true,
              helperText: ProductMapValidation.getErrorMessages(productMapValidationConstants.KEY_DISPLAY_SEQUENCE),
              FormHelperTextProps: { className: helperTextStylesClasses.root },
            },
            {
              className: "edit-create-input",
              size: cons.SIZE.SMALL,
              label: { resource: resource, key: "status" },
              value: savedProduct.status,
              type: cons.TYPE.SELECT,
              selectValue: cons.CHOICES.PRODUCT_CREATE.CREATE_CATEGORY.PRODUCT_MAP_STATUS,
              name: "status",
              isEndOfLine: true,
              disableClearable: true,
              required: true,
              helperTextIcon: true,
              helperText: ProductMapValidation.getErrorMessages(productMapValidationConstants.KEY_STATUS),
              FormHelperTextProps: { className: helperTextStylesClasses.root },
            },
          ]

          return fields.map((inputSetting, index) => {
            let content;
            switch (inputSetting.type) {
              case cons.TYPE.SELECT:
                content =
                  <TempAutoSelectInput inputSetting={inputSetting} handleChange={onChangeInput} index={idx} />
                break;
              default:
                content =
                  <TempTextInput inputSetting={inputSetting} handleChange={onChangeInput} index={idx} />
            }
            return <div className="edit-display-wrapper" key={index}>
              {content}
              {inputSetting.isEndOfLine && <div></div>}
            </div>
          })
        })}</>,
      btn: true
    },
  ]

  const bottomBtns = [
    {
      variant: cons.STYLE.VARIANT.CONTAINED,
      color: cons.STYLE.PRIMARY,
      label: { key: "save" },
      onClick: onClickSave,
      className: "margin-left-16",
      icon: <SaveIcon />
    },
    {
      variant: cons.STYLE.VARIANT.CONTAINED,
      color: cons.STYLE.PRIMARY,
      label: { key: "clear" },
      disabled: false,
      onClick: onClickClear,
      className: "margin-left-16",
      icon: <ClearIcon />
    }
  ]

  const onClickBackToEdit = () => {
    setDialog({
      open: false,
      dialogActions: []
    });
    onClickClear();
  };
  const onClickBackToList = () => {
    setDialog({
      open: false,
      dialogActions: []
    })
    history.push("/" + cons.PATH.PRODUCT.PRODUCT_CATEGORY + "/" + savedProduct.draft.category.id + "/" + cons.PRODUCT_CAT_TAB.PRODUCT_MAP_LIST);
  };

  function onChangeInput(name, e, index) {
    const tempProductMap = _.cloneDeep(savedProductMap);
    if (name === "displaySequence") {
      tempProductMap[index][`${name}`] = Number.isInteger(Number(e)) ? Number(e) : 0;
    } else {
      tempProductMap[index][`${name}`] = e;
    }
    dispatch(setProductMap(tempProductMap));
  }

  function onClickSave() {
    ProductMapValidation.validateEditProductMap(savedProductMap[0]);
    if (ProductMapValidation.isValid()) {
      put(cons.PATH.PRODUCT.CATEGORY_PRODUCT_MAP + "/" + params.id, savedProductMap[0], (payload) => {
        setDialog({
          open: true,
          onClose: onClickBackToEdit,
          dialogContentText: { key: "editSuccess" },
          dialogActions: [
            { label: { key: "backEdit" }, onClick: onClickBackToEdit, icon: <ArrowBackIcon /> },
            { label: { key: "backList" }, onClick: onClickBackToList, icon: <LowPriorityIcon /> }
          ]
        })
        setClear(false)
      }, undefined, store);
      ProductMapValidation.resetErrorMessages();
    } else {
      setErrorVersion(ProductMapValidation.getVersion());
    }
  }

  function onClickClear() {
    setClear(true)
  }

  useEffect(() => {
    if (clear === true) {
      getOne(cons.PATH.PRODUCT.CATEGORY_PRODUCT_MAP + "/" + params.id, undefined, (payload) => {
        let newProductMap = [];
        newProductMap.push(payload);
        dispatch(setProductMap(newProductMap));
        setReady(true)
        setClear(false)
      }, undefined, store);
    }
    ProductMapValidation.resetErrorMessages();
  }, [clear]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    ready &&
    <>
      <EditCreateDisplay
        code={savedProductMap && (savedProductMap[0] && savedProductMap[0].productCode ? savedProductMap[0].productCode : "")}
        resource={resource}
        tabs={tabs}
        content={content}
        onChangeInput={onChangeInput}
        bottomBtns={bottomBtns}
        mapName={{ resource: "productCreateProductCategoryList", key: "categoryCode" }}
        mapCode={savedProduct.draft.category.categoryCode}
        display="edit"
      />
      {dialog && dialog.open &&
        <GlobalDialog dialogInfo={dialog} />
      }
    </>
  )
};