import { useState } from 'react'
import { Box, Drawer, IconButton } from '@mui/material'
import Iconify from '../iconify/Iconify'
import { ICONIFY } from '../../../Constants/iconify'
import MUIInput from '../field/MUIInput'
import RegionList from '../../../Page/Region/RegionList'

export function RegionField({ setRegion, error, value, required = true, disabled }) {
    const [showDrawer, setShowDrawer] = useState(false)

    const onClickShowDrawer = () => {
        setShowDrawer(true)
    }

    const onClickCloseDrawer = () => {
        setShowDrawer(false)
    }

    const onClickListRow = (row) => {
        setShowDrawer(false)
        setRegion(row)
    }

    return (
        <>
            <MUIInput
                label='Region'
                value={value || ''}
                name='regionCode'
                endAdornment={
                    <IconButton onClick={onClickShowDrawer}>
                        <Iconify icon={ICONIFY.edit} />
                    </IconButton>
                }
                readOnly
                required={required}
                error={error}
                disabled={disabled}
            />
            <Drawer open={showDrawer} onClose={onClickCloseDrawer} anchor='right'>
                <Box sx={{ p: 2, overflow: 'auto' }}>
                    <RegionList onClickListRow={onClickListRow} readOnly />
                </Box>
            </Drawer>
        </>
    )
}
