import { useState, useEffect } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import { useHistory } from "react-router-dom";
import _ from "lodash";

import SaveIcon from '@mui/icons-material/Save';
import ClearIcon from '@mui/icons-material/Clear';
import LowPriorityIcon from '@mui/icons-material/LowPriority';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { constants as cons, BANNER_ACTION, BANNER_TYPE, GET_ID_FROM_PAYLOAD } from '../../Constants/constants';
import { put } from "../../helper/baseFetch";
import { setBannerList } from "../../actions/bannerAction";
import { EditCreateDisplay } from '../../Component/EditCreateDisplay/EditCreateDisplay';
import { BannerCreateTemp } from "./BannerCreateTemp";
import BannerValidation from "../../validations/bannerValidation";
import { GlobalDialog } from "../../Component/GlobalDialog";
import { getScDisabled } from "../../helper/util";
import { handleParameters, handleParametersInputBox } from "./handleParameters";
import { ConfigMapList } from "../Ticket/ConfigMapList";

export const BannerEdit = ({ newPath, bannerType, mapName, mapCode, mapId, redirectPath, tab, dataId }) => {
  const dispatch = useDispatch();
  const store = useStore();
  const history = useHistory();
  const [clear, setClear] = useState(true);
  const [ready, setReady] = useState(false);
  const savedBanner = useSelector(state => state.banner.ui.banner);
  const [customIdNumber, setCustomIdNumber] = useState(0);
  const [dialog, setDialog] = useState({
    open: false,
    dialogActions: []
  });
  // eslint-disable-next-line no-unused-vars
  const [errorVersion, setErrorVersion] = useState(BannerValidation.getVersion());

  const resource = "Banner";

  const tabs = [
    {
      label: { resource: resource, key: "info" },
      tabIndex: cons.BANNER_TAB.INFO
    },
    savedBanner.id && savedBanner.type === BANNER_TYPE.TICKET &&
    {
      label: {resource: "ConfigMap", key: "configMapList"},
      tabIndex: cons.TICKET_BANNER_TAB.TICKET_BANNER_MAP_LIST
    }
  ].filter(tabTitle => tabTitle)

  const content = [
    {
      tab: <BannerCreateTemp
        dataId={dataId}
        display="none"
        bannerType={bannerType}
        setCustomIdNumber={setCustomIdNumber}
        customIdNumber={customIdNumber}
        handleApiCallBack={handleApiCallBack}
        newPath={newPath}
        clear={clear}
        setClear={setClear}
        ready={ready}
        setReady={setReady}
        create={true}
      />,
      btn: true,
    },
    savedBanner.id && savedBanner.type === BANNER_TYPE.TICKET &&
    {
      tab: <ConfigMapList />,
      btn: true
    }
  ].filter(tabTitle => tabTitle)

  const bottomBtns = [
    {
      variant: cons.STYLE.VARIANT.CONTAINED,
      color: cons.STYLE.PRIMARY,
      label: { key: "save" },
      onClick: onClickSave,
      className: "margin-left-16",
      icon: <SaveIcon />,
      disabled: !savedBanner || savedBanner.length === 0,
    },
    {
      variant: cons.STYLE.VARIANT.CONTAINED,
      color: cons.STYLE.PRIMARY,
      label: { key: "clear" },
      disabled: false,
      onClick: onClickClear,
      className: "margin-left-16",
      icon: <ClearIcon />
    }
  ]

  const onClickBackToEdit = () => {
    setDialog({
      open: false,
      dialogActions: []
    });
    onClickClear();
  };

  const onClickBackToList = () => {
    setDialog({
      open: false,
      dialogActions: []
    })
    const id = mapId===GET_ID_FROM_PAYLOAD?savedBanner.refCode:mapId;
    if (redirectPath === cons.PATH.SHOP.SYSTEM_GROUP_RESTAURANT) {
      history.push(mapId ? "/" + redirectPath + "/" + id + "/" + cons.RESTAURANT_TAB.MEDIA + "/" + tab : "/" + redirectPath);
    } else if (!redirectPath) {
      history.push("/" + cons.PATH.BANNER.BANNER);
    } else {
      history.push(mapId ? "/" + redirectPath + "/" + id + "/" + tab : "/" + redirectPath);
    }
  };

  function onClickSave() {
    let tempSavedBanner = _.cloneDeep(savedBanner);
    
    if(tempSavedBanner.type === BANNER_TYPE.MERCHANT_LANDING||tempSavedBanner.type === BANNER_TYPE.MERCHANT_LOGIN){
      if(tempSavedBanner.actionType === BANNER_ACTION.MERCHANT_WEB_VIEW){
        tempSavedBanner.actionType = BANNER_ACTION.WEB_VIEW;
      }
      if(tempSavedBanner.actionType === BANNER_ACTION.MERCHANT_LINK){
        tempSavedBanner.actionType = BANNER_ACTION.LINK;
      }
    }
    BannerValidation.validateEditBanner(tempSavedBanner);
    if (BannerValidation.isValid()) {
      if (tempSavedBanner.actionType === BANNER_ACTION.NONE || tempSavedBanner.actionType === BANNER_ACTION.DOWNLOAD) {
        tempSavedBanner.actionParameters = null;
      } else {
        let tempEnActionParameters = JSON.parse(tempSavedBanner.enActionParameters);
        let tempTcActionParameters = JSON.parse(tempSavedBanner.tcActionParameters);
        let tempScActionParameters = JSON.parse(tempSavedBanner.scActionParameters);

        if (tempSavedBanner.enOtherParameters.length > 0) {
          tempSavedBanner.enOtherParameters.forEach((otherParameter) => {
            if (otherParameter.key && otherParameter.value) {
              let key = {};
              key[otherParameter.key] = otherParameter.value;
              tempEnActionParameters = Object.assign(tempEnActionParameters, key)
            }
          })
        }
        tempSavedBanner.enActionParameters = JSON.stringify(tempEnActionParameters)

        if (tempSavedBanner.tcOtherParameters.length > 0) {
          tempSavedBanner.tcOtherParameters.forEach((otherParameter) => {
            if (otherParameter.key && otherParameter.value) {
              let key = {};
              key[otherParameter.key] = otherParameter.value;
              tempTcActionParameters = Object.assign(tempTcActionParameters, key)
            }
          })
        }
        tempSavedBanner.tcActionParameters = JSON.stringify(tempTcActionParameters)

        if (tempSavedBanner.scOtherParameters.length > 0) {
          tempSavedBanner.scOtherParameters.forEach((otherParameter) => {
            if (otherParameter.key && otherParameter.value) {
              let key = {};
              key[otherParameter.key] = otherParameter.value;
              tempScActionParameters = Object.assign(tempScActionParameters, key)
            }
          })
        }
        tempSavedBanner.scActionParameters = JSON.stringify(tempScActionParameters)
      }
      //console.log("IN", tempsavedBanner)
      //console.log(tempsavedBanner)
      put(newPath ? newPath + "/" + dataId : cons.PATH.BANNER.BANNER + "/" + tempSavedBanner.id, tempSavedBanner, (payload) => {
        setDialog({
          open: true,
          onClose: onClickBackToEdit,
          dialogContentText: { key: "editSuccess" },
          dialogActions: [
            { label: { key: "backEdit" }, onClick: onClickBackToEdit, icon: <ArrowBackIcon /> },
            { label: { key: "backList" }, onClick: onClickBackToList, icon: <LowPriorityIcon /> }
          ]
        })
        setClear(false)
      }, undefined, store);
      BannerValidation.resetErrorMessages();
    } else {
      setErrorVersion(BannerValidation.getVersion());
    }
  }

  function handleApiCallBack(data) {
    data.scNameDisable = getScDisabled(data.tcName, data.scName);
    data = handleParametersInputBox(data);
    data = handleParameters(data, "en");
    data = handleParameters(data, "tc");
    data = handleParameters(data, "sc");
    if (data.actionType !== BANNER_ACTION.NONE && data.actionType !== BANNER_ACTION.DOWNLOAD) {
      let tempEnActionParameters = JSON.parse(data.enActionParameters);
      let tempTcActionParameters = JSON.parse(data.tcActionParameters);
      let tempScActionParameters = JSON.parse(data.scActionParameters);
      let tempEnOtherParameters = data.enOtherParameters ? JSON.parse(data.enOtherParameters) : [];
      let tempTcOtherParameters = data.tcOtherParameters ? JSON.parse(data.tcOtherParameters) : [];
      let tempScOtherParameters = data.scOtherParameters ? JSON.parse(data.scOtherParameters) : [];
      delete tempEnActionParameters.url;
      delete tempTcActionParameters.url;
      delete tempScActionParameters.url;
      const enObjectArray = Object.entries(tempEnActionParameters);
      const tcObjectArray = Object.entries(tempTcActionParameters);
      const scObjectArray = Object.entries(tempScActionParameters);
      enObjectArray.forEach(([key, value]) => {
        tempEnOtherParameters.push(
          {
            key: key,
            value: value
          }
        );
      });
      tcObjectArray.forEach(([key, value]) => {
        tempTcOtherParameters.push(
          {
            key: key,
            value: value
          }
        );
      });
      scObjectArray.forEach(([key, value]) => {
        tempScOtherParameters.push(
          {
            key: key,
            value: value
          }
        );
      });
      data.enOtherParameters = tempEnOtherParameters ? tempEnOtherParameters : [];
      data.tcOtherParameters = tempTcOtherParameters ? tempTcOtherParameters : [];
      data.scOtherParameters = tempScOtherParameters ? tempScOtherParameters : [];
    } else {
      data.enOtherParameters = [];
      data.tcOtherParameters = [];
      data.scOtherParameters = [];
    }
    return data;
  }

  function onClickClear() {
    setClear(true)
  }

  useEffect(() => {
    dispatch(setBannerList(cons.BANNER_INPUT_LIST));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    console.log('savedBanner', savedBanner)
  }, [savedBanner])

  return <>
    <EditCreateDisplay
      code={savedBanner.id}
      resource={resource}
      tabs={tabs}
      content={content}
      bottomBtns={bottomBtns}
      mapName={mapName}
      mapCode={mapCode===GET_ID_FROM_PAYLOAD?savedBanner.refCode:mapCode}
      display="edit"
    />
    {dialog && dialog.open &&
      <GlobalDialog dialogInfo={dialog} />
    }
  </>
};