import { useState } from 'react'
import { Drawer, IconButton } from '@mui/material'
import Iconify from '../iconify/Iconify'
import { ICONIFY } from '../../../Constants/iconify'
import MUIInput from '../field/MUIInput'
import { AreaList } from '../../../Page/Area/AreaList'

export function AreaField({ setArea, value, error, required = true }) {
    const [showDrawer, setShowDrawer] = useState(false)

    const onClickShowDrawer = () => {
        setShowDrawer(true)
    }

    const onClickCloseDrawer = () => {
        setShowDrawer(false)
    }

    const onClickListRow = (row) => {
        setShowDrawer(false)
        setArea(row)
    }

    return (
        <>
            <MUIInput
                label='Area'
                value={value || ''}
                name='areaId'
                endAdornment={
                    <IconButton onClick={onClickShowDrawer}>
                        <Iconify icon={ICONIFY.edit} />
                    </IconButton>
                }
                readOnly
                error={error}
                required={required}
            />
            <Drawer anchor='right' open={showDrawer} onClose={onClickCloseDrawer}>
                <AreaList onClickListRow={onClickListRow} readOnly />
            </Drawer>
        </>
    )
}
