import { useRef } from 'react'
import { useSelector } from 'react-redux'

import MButton from '@mui/material/Button'

import { getString, isObj } from '../../helper/util'
import { constants as cons } from '../../Constants/constants'

export const TempBtn = ({ btnSetting: p }) => {
  const lang = useSelector((state) => state.lang.ui.lang)
  const theme = useSelector((state) => state.theme.ui.theme)
  const buttonRef = useRef(null)

  return (
    <MButton
      ref={buttonRef}
      variant={cons.STYLE.VARIANT.CONTAINED}
      color={p.color || cons.STYLE.PRIMARY}
      disabled={p.disabled}
      onClick={(e) => {
        p.onClick(e)
        buttonRef.current.blur()
      }}
      className={p.className}
    >
      {p.icon}
      {p.label && isObj(p.label) ? getString(lang, p.label.resource || '', p.label.key || '', theme) : p.label}
    </MButton>
  )
}
