import { LANDING_SECTION_SECTION_TYPE, LANDING_ACTION } from '../../../../Constants/constants'
import { LandingItem } from '../LandingItem'
import { LandingHeader } from '../LandingHeader'

const historicalData = [
  {
    id: 'demo',
    sectionId: null,
    itemType: 'TICKET_IMAGE',
    tcTitle: 'demo',
    enTitle: 'demo',
    scTitle: 'demo',
    tcSubTitle: 'demo',
    enSubTitle: 'demo',
    scSubTitle: 'demo',
    color: '#a53100',
    borderColor: '#ff5000',
    serviceTypeList: null,
    startDisplayTimestamp: null,
    endDisplayTimestamp: null,
    sequence: 1,
    status: 'A',
    tcImage: null,
    enImage: null,
    scImage: null,
    tagImage: null,
    foregroundColor: null,
    backgroundColor: null,
    action: null,
    actionParameters: null,
  },
  {
    id: 'demo2',
    sectionId: null,
    itemType: 'TICKET_IMAGE',
    tcTitle: 'demo',
    enTitle: 'demo',
    scTitle: 'demo',
    tcSubTitle: 'demo',
    enSubTitle: 'demo',
    scSubTitle: 'demo',
    color: '#a53100',
    borderColor: '#ff5000',
    serviceTypeList: null,
    startDisplayTimestamp: null,
    endDisplayTimestamp: null,
    sequence: 1,
    status: 'A',
    tcImage: null,
    enImage: null,
    scImage: null,
    tagImage: null,
    foregroundColor: null,
    backgroundColor: null,
    action: null,
    actionParameters: null,
  },
  {
    id: 'demo3',
    sectionId: null,
    itemType: 'TICKET_IMAGE',
    tcTitle: 'demo',
    enTitle: 'demo',
    scTitle: 'demo',
    tcSubTitle: 'demo',
    enSubTitle: 'demo',
    scSubTitle: 'demo',
    color: '#a53100',
    borderColor: '#ff5000',
    serviceTypeList: null,
    startDisplayTimestamp: null,
    endDisplayTimestamp: null,
    sequence: 1,
    status: 'A',
    tcImage: null,
    enImage: null,
    scImage: null,
    tagImage: null,
    foregroundColor: null,
    backgroundColor: null,
    action: null,
    actionParameters: null,
  },
]

const suggestData = [
  {
    id: 'demo',
    sectionId: null,
    itemType: 'RECOMMEND_BY_LOCATION',
    tcTitle: 'demo',
    enTitle: 'demo',
    scTitle: 'demo',
    tcSubTitle: 'demo',
    enSubTitle: 'demo',
    scSubTitle: 'demo',
    serviceTypeList: null,
    startDisplayTimestamp: null,
    endDisplayTimestamp: null,
    sequence: 1,
    status: 'A',
    tcImage: null,
    enImage: null,
    scImage: null,
    tagImage: null,
    foregroundColor: null,
    backgroundColor: null,
    action: null,
    actionParameters: null,
  },
  {
    id: 'demo2',
    sectionId: null,
    itemType: 'RECOMMEND_BY_LOCATION',
    tcTitle: 'demo',
    enTitle: 'demo',
    scTitle: 'demo',
    tcSubTitle: 'demo',
    enSubTitle: 'demo',
    scSubTitle: 'demo',
    serviceTypeList: null,
    startDisplayTimestamp: null,
    endDisplayTimestamp: null,
    sequence: 1,
    status: 'A',
    tcImage: null,
    enImage: null,
    scImage: null,
    tagImage: null,
    foregroundColor: null,
    backgroundColor: null,
    action: null,
    actionParameters: null,
  },
]

export const renderLandingSectionListItem = (item) => {
  const render = (data) => {
    return (
      <div className="landing-section">
        {(item.sectionType === LANDING_SECTION_SECTION_TYPE.HEADER_ITEMS || item.sectionType === LANDING_SECTION_SECTION_TYPE.ITEMS) && <LandingHeader item={item} isPreview={true} />}
        <div className="landing-section-item">
          {data.map((item, index) => {
            return (
              <div key={index} className="landing-section-item-box">
                <LandingItem item={item} previewMode={true} />
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  switch (item.sectionType) {
    case LANDING_SECTION_SECTION_TYPE.ADS:
      return (
        <div className="landing-section" style={{ color: '#FFFFFF' }}>
          {`${item.channel?`${item.channel} :`: ''}`}ADS
        </div>
      )
    default:
      switch (item.action) {
        case LANDING_ACTION.HISTORICAL_SHOP:
          return render(historicalData)
        case LANDING_ACTION.SEARCH_SUGGESTION:
          return render(suggestData)
        default:
          const itemData = item.itemList ? item.itemList.slice(0, 3) : []
          return render(itemData)
      }
  }
}
