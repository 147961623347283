import { useState, useEffect } from 'react'
import { useDispatch, useSelector, useStore } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import moment from 'moment'
import _ from 'lodash'

import SaveIcon from '@mui/icons-material/Save'
import ClearIcon from '@mui/icons-material/Clear'
import LowPriorityIcon from '@mui/icons-material/LowPriority'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

import { constants as cons } from '../../Constants/constants'
import { getOne, put, post } from '../../helper/baseFetch'
import { setStatus, setStatusValue } from '../../actions/statusAction'
import { EditCreateDisplay } from '../../Component/EditCreateDisplay/EditCreateDisplay'
import { GlobalDialog } from '../../Component/GlobalDialog'
import { getContent } from '../../Component/EditCreateDisplay/EditUtil'
import { BOOLEAN_OPTIONS, STATUS_OPTIONS } from '../../Constants/type'

export const StatusEdit = () => {
  const dispatch = useDispatch()
  const store = useStore()
  const history = useHistory()
  const params = useParams()
  const [ready, setReady] = useState(false)
  const [clear, setClear] = useState(true)
  const [dialog, setDialog] = useState({
    open: false,
    dialogActions: [],
  })
  const resource = 'Status'
  const savedStatus = useSelector((state) => state.status.ui.status)
  // const lang = useSelector(state => state.lang.ui.lang);
  // const theme = useSelector(state => state.theme.ui.theme);
  const tempStatusEditList = [
    {
      type: cons.TYPE.TITLE,
      value: { resource: resource, key: 'queue' },
      className: 'sub-title',
    },
    {
      label: { resource: resource, key: 'queueStatus' },
      value: savedStatus.queueStatus,
      type: cons.TYPE.SELECT,
      selectValue: cons.CHOICES.STATUS.QUEUE_STATUS,
      name: 'queueStatus',
      disableClearable: true,
      isEndOfLine: false,
    },
    {
      label: { resource: resource, key: 'orderStatus' },
      value: savedStatus.orderStatus,
      type: cons.TYPE.SELECT,
      selectValue: cons.CHOICES.STATUS.ORDER_STATUS,
      name: 'orderStatus',
      disableClearable: true,
      isEndOfLine: false,
    },
    {
      className: 'edit-create-input',
      label: { resource: resource, key: 'gpsRequired' },
      value: savedStatus.gpsRequired,
      type: cons.TYPE.SELECT,
      selectValue: cons.CHOICES.STATUS.GPS_REQUIRED,
      name: 'gpsRequired',
      disableClearable: true,
      isEndOfLine: true,
    },
    {
      label: { resource: resource, key: 'queueVisible' },
      value: savedStatus.queueVisible,
      type: cons.TYPE.SELECT,
      selectValue: cons.CHOICES.STATUS.QUEUE_VISIBLE,
      name: 'queueVisible',
      disableClearable: true,
      isEndOfLine: false,
    },
    {
      label: { resource: resource, key: 'queueTicketDeletable' },
      value: savedStatus.queueTicketDeletable,
      type: cons.TYPE.SELECT,
      selectValue: cons.CHOICES.STATUS.QUEUE_TICKET_DELETABLE,
      name: 'queueTicketDeletable',
      disableClearable: true,
      isEndOfLine: true,
    },
    {
      label: { resource: resource, key: 'queueWebOfficialReadOnly' },
      value: savedStatus.queueWebOfficialReadOnly,
      type: cons.TYPE.SELECT,
      selectValue: BOOLEAN_OPTIONS,
      name: 'queueWebOfficialReadOnly',
      disableClearable: true,
      isEndOfLine: false,
    },
    {
      label: { resource: resource, key: 'queueWebReadOnly' },
      value: savedStatus.queueWebReadOnly,
      type: cons.TYPE.SELECT,
      selectValue: cons.CHOICES.STATUS.QUEUE_WEB_READ_ONLY,
      name: 'queueWebReadOnly',
      disableClearable: true,
      isEndOfLine: true,
    },
    {
      label: { resource: resource, key: 'queueWebAvailable' },
      value: savedStatus.queueWebAvailable,
      type: cons.TYPE.SELECT,
      selectValue: cons.CHOICES.STATUS.QUEUE_WEB_AVAILABLE,
      name: 'queueWebAvailable',
      disableClearable: true,
      isEndOfLine: false,
    },
    {
      label: { resource: resource, key: 'queueWebTvAvailable' },
      value: savedStatus.queueWebTvAvailable,
      type: cons.TYPE.SELECT,
      selectValue: cons.CHOICES.STATUS.QUEUE_VISIBLE,
      name: 'queueWebTvAvailable',
      disableClearable: true,
      isEndOfLine: false,
    },
    {
      label: { resource: resource, key: 'queueSpringboardAvailable' },
      value: savedStatus.queueSpringboardAvailable,
      type: cons.TYPE.SELECT,
      selectValue: cons.CHOICES.STATUS.QUEUE_SPRINGBOARD_AVAILABLE,
      name: 'queueSpringboardAvailable',
      disableClearable: true,
      isEndOfLine: false,
    },
    {
      type: cons.TYPE.TITLE,
      value: { resource: resource, key: 'guest' },
      className: 'sub-title',
    },
    {
      label: { resource: resource, key: 'guestReservationStatus' },
      value: savedStatus.guestReservationStatus,
      type: cons.TYPE.SELECT,
      selectValue: cons.CHOICES.STATUS.GUEST_RESERVATION_STATUS,
      name: 'guestReservationStatus',
      disableClearable: true,
      isEndOfLine: false,
    },
    {
      label: { resource: resource, key: 'guestTicketStatus' },
      value: savedStatus.guestTicketStatus,
      type: cons.TYPE.SELECT,
      selectValue: cons.CHOICES.STATUS.GUEST_TICKET_STATUS,
      name: 'guestTicketStatus',
      disableClearable: true,
      isEndOfLine: false,
    },
    {
      type: cons.TYPE.TITLE,
      value: { resource: resource, key: 'product' },
    },
    {
      label: { resource: resource, key: 'wxProductStatus' },
      value: savedStatus.wxProductStatus,
      type: cons.TYPE.SELECT,
      selectValue: cons.CHOICES.STATUS.WX_PRODUCT_STATUS,
      name: 'wxProductStatus',
      disableClearable: true,
      isEndOfLine: false,
    },
    {
      label: { resource: resource, key: 'ecouponDistributeStatus' },
      value: savedStatus.ecouponDistributeStatus,
      type: cons.TYPE.SELECT,
      selectValue: cons.CHOICES.STATUS.ECOUPON_DISTRIBUTE_STATUS,
      name: 'ecouponDistributeStatus',
      disableClearable: true,
      isEndOfLine: true,
    },
    {
      type: cons.TYPE.TITLE,
      value: { resource: resource, key: 'reserve' },
    },
    {
      label: { resource: resource, key: 'reserveStatus' },
      value: savedStatus.reserveStatus,
      type: cons.TYPE.SELECT,
      selectValue: cons.CHOICES.STATUS.RESERVE_STATUS,
      name: 'reserveStatus',
      disableClearable: true,
      isEndOfLine: false,
    },
    {
      label: { resource: resource, key: 'reservationVisible' },
      value: savedStatus.reservationVisible,
      type: cons.TYPE.SELECT,
      selectValue: cons.CHOICES.STATUS.RESERVE_VISIBLE,
      name: 'reservationVisible',
      disableClearable: true,
      isEndOfLine: false,
    },
    {
      label: { resource: resource, key: 'reservationWebAvailable' },
      value: savedStatus.reservationWebAvailable,
      type: cons.TYPE.SELECT,
      selectValue: cons.CHOICES.STATUS.RESERVATION_WEB_AVAILABLE,
      name: 'reservationWebAvailable',
      disableClearable: true,
      isEndOfLine: true,
    },
    {
      type: cons.TYPE.TITLE,
      value: { resource: resource, key: 'takeaway' },
    },
    {
      label: { resource: resource, key: 'takeawayStatus' },
      value: savedStatus.takeawayStatus,
      type: cons.TYPE.SELECT,
      selectValue: cons.CHOICES.STATUS.TAKEAWAY_STATUS,
      name: 'takeawayStatus',
      disableClearable: true,
      isEndOfLine: false,
    },
    {
      label: { resource: resource, key: 'takeawayVisible' },
      value: savedStatus.takeawayVisible,
      type: cons.TYPE.SELECT,
      selectValue: cons.CHOICES.STATUS.TAKEAWAY_VISIBLE,
      name: 'takeawayVisible',
      disableClearable: true,
      isEndOfLine: false,
    },
    {
      label: { resource: resource, key: 'takeawayDeliveryStatus' },
      value: savedStatus.takeawayDeliveryStatus,
      type: cons.TYPE.SELECT,
      selectValue: cons.CHOICES.STATUS.TAKEAWAY_DELIVERY_STATUS,
      name: 'takeawayDeliveryStatus',
      disableClearable: true,
      isEndOfLine: true,
    },
    {
      label: { resource: resource, key: 'takeawayPickupStatus' },
      value: savedStatus.takeawayPickupStatus,
      type: cons.TYPE.SELECT,
      selectValue: cons.CHOICES.STATUS.TAKEAWAY_PICKUP_STATUS,
      name: 'takeawayPickupStatus',
      disableClearable: true,
      isEndOfLine: false,
    },
    {
      label: { resource: resource, key: 'takeawayPreorderStatus' },
      value: savedStatus.takeawayPreorderStatus,
      type: cons.TYPE.SELECT,
      selectValue: cons.CHOICES.STATUS.TAKEAWAY_PREORDER_STATUS,
      name: 'takeawayPreorderStatus',
      disableClearable: true,
      isEndOfLine: false,
    },
    {
      label: { resource: resource, key: 'wxTakeawayStatus' },
      value: savedStatus.wxTakeawayStatus,
      type: cons.TYPE.SELECT,
      selectValue: cons.CHOICES.STATUS.WX_TAKEAWAY_STATUS,
      name: 'wxTakeawayStatus',
      disableClearable: true,
      isEndOfLine: true,
    },
    {
      label: { resource: resource, key: 'menuStatus' },
      value: savedStatus.menuStatus,
      type: cons.TYPE.SELECT,
      selectValue: cons.CHOICES.STATUS.MENU_STATUS,
      name: 'menuStatus',
      disableClearable: true,
      isEndOfLine: false,
    },
    {
      label: { resource: resource, key: 'menuVisible' },
      value: savedStatus.menuVisible,
      type: cons.TYPE.SELECT,
      selectValue: cons.CHOICES.STATUS.MENU_VISIBLE,
      name: 'menuVisible',
      disableClearable: true,
      isEndOfLine: false,
    },
    {
      type: cons.TYPE.TITLE,
      value: { resource: resource, key: 'pickup' },
    },
    {
      className: 'edit-create-input',
      label: { resource: resource, key: 'pickupStatus' },
      value: savedStatus.pickupStatus,
      type: cons.TYPE.SELECT,
      selectValue: STATUS_OPTIONS,
      name: 'pickupStatus',
      disableClearable: true,
      isEndOfLine: false,
    },
    {
      className: 'edit-create-input',
      label: { resource: resource, key: 'pickupVisible' },
      value: savedStatus.pickupVisible,
      type: cons.TYPE.SELECT,
      selectValue: cons.CHOICES.STATUS.PICKUP_VISIBLE,
      name: 'pickupVisible',
      disableClearable: true,
      isEndOfLine: false,
    },
    {
      type: cons.TYPE.TITLE,
      value: { resource: resource, key: 'notifier' },
    },
    {
      className: 'edit-create-input edit-display-wrapper',
      label: { resource: resource, key: 'email' },
      value: savedStatus.notifiersArray?.includes(cons.NOTIFIER.EMAIL),
      name: 'email',
      type: cons.TYPE.CHECKBOX,
      isEndOfLine: false,
    },
    {
      className: 'edit-create-input edit-display-wrapper',
      label: { resource: resource, key: 'sms' },
      value: savedStatus.notifiersArray?.includes(cons.NOTIFIER.SMS),
      name: 'sms',
      type: cons.TYPE.CHECKBOX,
      isEndOfLine: false,
    },
    {
      className: 'edit-create-input edit-display-wrapper',
      label: { resource: resource, key: 'whatsapp' },
      value: savedStatus.notifiersArray?.includes(cons.NOTIFIER.WHATSAPP),
      name: 'whatsapp',
      type: cons.TYPE.CHECKBOX,
      isEndOfLine: false,
    },
    {
      type: cons.TYPE.TITLE,
      value: { resource: resource, key: 'banquet' },
    },
    {
      label: { resource: resource, key: 'banquetStatus' },
      value: savedStatus.banquetStatus,
      type: cons.TYPE.SELECT,
      selectValue: cons.CHOICES.STATUS.BANQUET_STATUS,
      name: 'banquetStatus',
      disableClearable: true,
      isEndOfLine: false,
    },
    {
      label: { resource: resource, key: 'banquetVisible' },
      value: savedStatus.banquetVisible,
      type: cons.TYPE.SELECT,
      selectValue: cons.CHOICES.STATUS.BANQUET_VISIBLE,
      name: 'banquetVisible',
      disableClearable: true,
      isEndOfLine: true,
    },
    {
      type: cons.TYPE.TITLE,
      value: { resource: resource, key: 'other' },
    },
    {
      label: { resource: resource, key: 'havePreorder' },
      value: savedStatus.havePreorder,
      type: cons.TYPE.SELECT,
      selectValue: cons.CHOICES.STATUS.HAVE_PREORDER,
      name: 'havePreorder',
      disableClearable: true,
      isEndOfLine: false,
    },
    {
      label: { resource: resource, key: 'adminLiteStatus' },
      value: savedStatus.adminLiteStatus,
      type: cons.TYPE.SELECT,
      selectValue: cons.CHOICES.STATUS.ADMIN_LITE_STATUS,
      name: 'adminLiteStatus',
      disableClearable: true,
      isEndOfLine: false,
    },
    {
      label: { resource: resource, key: 'vipStatus' },
      value: savedStatus.vipStatus,
      type: cons.TYPE.SELECT,
      selectValue: cons.CHOICES.STATUS.VIP_STATUS,
      name: 'vipStatus',
      disableClearable: true,
      isEndOfLine: true,
    },
    {
      label: { resource: resource, key: 'turboStatus' },
      value: savedStatus.turboStatus,
      type: cons.TYPE.SELECT,
      selectValue: cons.CHOICES.STATUS.TURBO_STATUS,
      name: 'turboStatus',
      disableClearable: true,
      isEndOfLine: false,
    },
    {
      label: { resource: resource, key: 'bookmarkStatus' },
      value: savedStatus.bookmarkStatus,
      type: cons.TYPE.SELECT,
      selectValue: cons.CHOICES.STATUS.BOOKMARK_STATUS,
      name: 'bookmarkStatus',
      disableClearable: true,
      isEndOfLine: false,
    },
    {
      label: { resource: resource, key: 'reviewStatus' },
      value: savedStatus.reviewStatus,
      type: cons.TYPE.SELECT,
      selectValue: cons.CHOICES.STATUS.REVIEW_STATUS,
      name: 'reviewStatus',
      disableClearable: true,
      isEndOfLine: true,
    },
    {
      label: { resource: resource, key: 'socialPhotoStatus' },
      value: savedStatus.photoStatus,
      type: cons.TYPE.SELECT,
      selectValue: cons.CHOICES.STATUS.SOCIAL_PHOTO_STATUS,
      name: 'photoStatus',
      disableClearable: true,
      isEndOfLine: false,
    },
    {
      label: { resource: resource, key: 'shareRestStatus' },
      value: savedStatus.shareRestStatus,
      type: cons.TYPE.SELECT,
      selectValue: cons.CHOICES.STATUS.REVIEW_STATUS,
      name: 'shareRestStatus',
      disableClearable: true,
      isEndOfLine: true,
    },
  ]
  const tabContents = [
    {
      tab: tempStatusEditList.map((inputSetting, index) => {
        let content = getContent(inputSetting, index, onChangeInput)
        // let content;
        // switch (inputSetting.type) {
        //   case constants.TYPE.SELECT:
        //     content =
        //       <TempAutoSelectInput inputSetting={inputSetting} handleChange={onChangeInput} />
        //     break;
        //   case constants.TYPE.TITLE:
        //     content = <div className={`${inputSetting.className + " sub-title"}`}>{getString(lang, inputSetting.value.resource, inputSetting.value.key, theme)}</div>;
        //     break;
        //   case constants.TYPE.CHECKBOX:
        //     content =
        //       <span className="merchant-user-check-box-wrapper">
        //         <TempCheckBox
        //           checkBoxSetting={{
        //             className: inputSetting.className,
        //             name: inputSetting.name,
        //             label: inputSetting.label,
        //             value: inputSetting.value,
        //           }}
        //           handleChange={handleCheckBoxChange} />
        //       </span>;
        //     break;
        //   default:
        //     content =
        //       <TempTextInput inputSetting={inputSetting} handleChange={onChangeInput} />
        // }
        return (
          <div className="edit-display-wrapper" key={index}>
            {content}
            {inputSetting.isEndOfLine && <div></div>}
          </div>
        )
      }),
      btn: true,
    },
  ]

  const bottomButtons = [
    {
      variant: cons.STYLE.VARIANT.CONTAINED,
      color: cons.STYLE.PRIMARY,
      label: { key: 'save' },
      onClick: onClickSave,
      className: 'margin-left-16',
      icon: <SaveIcon />,
    },
    {
      variant: cons.STYLE.VARIANT.CONTAINED,
      color: cons.STYLE.PRIMARY,
      label: { key: 'clear' },
      disabled: false,
      onClick: onClickClear,
      className: 'margin-left-16',
      icon: <ClearIcon />,
    },
    {
      className: 'margin-left-16',
      type: cons.TYPE.TEXT,
      label: savedStatus.updateTimestampForDisplay ? { key: 'updateTimestamp' } : '',
      value: savedStatus.updateTimestampForDisplay ? ': ' + moment(savedStatus.updateTimestampForDisplay).format('YYYY-MM-DD HH:mm:ss') : '',
    },
  ]

  const onClickBackToEdit = () => {
    setDialog({
      open: false,
      dialogActions: [],
    })
    onClickClear()
  }
  const onClickBackToList = () => {
    setDialog({
      open: false,
      dialogActions: [],
    })
    history.push('/' + cons.PATH.SHOP.SYSTEM_GROUP_RESTAURANT)
  }

  function onChangeInput(name, e) {
    const clonedSavedStatus = _.cloneDeep(savedStatus)

    switch (name) {
      case LOCAL_CO.EMAIL:
      case LOCAL_CO.SMS:
      case LOCAL_CO.WHATSAPP:
        if (e) {
          clonedSavedStatus.notifiersArray = clonedSavedStatus.notifiersArray.filter((item) => item !== name.toUpperCase())
        } else {
          clonedSavedStatus.notifiersArray.push(name.toUpperCase())
        }
        clonedSavedStatus.notifiers = JSON.stringify(clonedSavedStatus.notifiersArray)
        dispatch(setStatus(clonedSavedStatus))
        break
      default:
        dispatch(setStatusValue(name, savedStatus, '', e))
    }
  }

  function onClickSave() {
    let clonedSavedStatus = _.cloneDeep(savedStatus)
    if (savedStatus.id) {
      put(
        cons.PATH.SHOP.STATUS + '/' + params.id,
        savedStatus,
        (payload) => {
          setDialog({
            open: true,
            onClose: onClickBackToEdit,
            dialogContentText: { key: 'editSuccess' },
            dialogActions: [
              { label: { key: 'backEdit' }, onClick: onClickBackToEdit, icon: <ArrowBackIcon /> },
              { label: { key: 'backList' }, onClick: onClickBackToList, icon: <LowPriorityIcon /> },
            ],
          })
          setClear(false)
        },
        undefined,
        store
      )
    } else {
      clonedSavedStatus.restUrlId = params.id
      post(
        cons.PATH.SHOP.STATUS,
        clonedSavedStatus,
        (payload) => {
          setDialog({
            open: true,
            onClose: onClickBackToEdit,
            dialogContentText: { key: 'editSuccess' },
            dialogActions: [
              { label: { key: 'backEdit' }, onClick: onClickBackToEdit, icon: <ArrowBackIcon /> },
              { label: { key: 'backList' }, onClick: onClickBackToList, icon: <LowPriorityIcon /> },
            ],
          })
          setClear(false)
        },
        undefined,
        store
      )
    }
  }

  function onClickClear() {
    setClear(true)
  }

  useEffect(() => {
    if (clear === true) {
      getOne(
        cons.PATH.SHOP.STATUS + '/' + params.id,
        undefined,
        (payload) => {
          payload.updateTimestampForDisplay = payload.updateTimestamp
          delete payload.updateTimestamp
          if (payload.notifiers) {
            payload.notifiersArray = JSON.parse(payload.notifiers)
          } else {
            payload.notifiersArray = []
          }
          dispatch(setStatus(payload))
          setReady(true)
          setClear(false)
        },
        undefined,
        store
      )
    }
  }, [clear]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    ready && (
      <>
        <EditCreateDisplay
          code={params.id ? params.id : ''}
          resource={resource}
          tabs={false}
          content={tabContents}
          onChangeInput={onChangeInput}
          bottomBtns={bottomButtons}
          display="none"
        />
        {dialog && dialog.open && <GlobalDialog dialogInfo={dialog} />}
      </>
    )
  )
}

const LOCAL_CO = {
  EMAIL: 'email',
  SMS: 'sms',
  WHATSAPP: 'whatsapp',
}
