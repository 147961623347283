import { RESTAURANT } from './types'
import { sify } from 'chinese-conv'
import _ from 'lodash'
import { getI18nObjValue } from '../helper/i18n'
import store from '../store'
import { constants as cons } from '../Constants/constants'

export const RESTAURANT_ACTIONS = {
    SET_REGION: 'SET_REGION',
    SET_CITY: 'SET_CITY',
    SET_DISTRICT: 'SET_DISTRICT',
}

export const setRestaurantDefault = () => {
    return {
        type: RESTAURANT.SET_RESTAURANT_DEFAULT,
    }
}

export const setRestaurant = (restaurant) => {
    return {
        type: RESTAURANT.SET_RESTAURANT,
        restaurant: restaurant,
    }
}

export const setRestaurantValue = (action, restaurant, key, value) => {
    // console.log('value', value)
    const newRestaurant = _.cloneDeep(restaurant)
    const guluLang = store.getState().lang.ui.lang

    const isSelectedPreRegion = restaurant?.regionCode === value?.regionCode
    const isSelectedPreCity = restaurant?.cityCode === value?.cityCode

    const isSelectedPreRegionAndCity = isSelectedPreRegion && isSelectedPreCity

    switch (action) {
        case 'CHANGE_TC_NAME_AND_SC_NAME':
            newRestaurant.tcName = value
            newRestaurant.tcSName = value
            newRestaurant.scName = sify(value)
            newRestaurant.scSName = sify(value)
            break
        case 'CHANGE_TC_WECHAT_NAME_AND_SC_WECHAT_NAME':
            newRestaurant.tcName = value
            newRestaurant.tcSName = value
            newRestaurant.scSName = newRestaurant.scName
            break
        case 'CHANGE_TC_NAME_AND_SC_NAME_WITHOUT_WECHAT':
            newRestaurant.tcName = value
            newRestaurant.scName = sify(value)
            break
        case 'CHANGE_EN_NAME':
            newRestaurant.enName = value
            newRestaurant.enSName = value
            break
        case 'CHANGE_TC_SEARCHING_NAME_AND_SC_SEARCHING_NAME':
            newRestaurant.tcSearchName = value
            newRestaurant.scSearchName = sify(value)
            break
        case 'CHANGE_TC_TICKET_MESSAGE_AND_SC_TICKET_MESSAGE':
            newRestaurant.tcTicketMessage = value
            newRestaurant.scTicketMessage = sify(value)
            break
        case 'CHANGE_TC_OPENING_AND_SC_OPENING':
            newRestaurant.tcOpening = value
            newRestaurant.scOpening = sify(value)
            break
        case 'CHANGE_TC_LAST_ORDER_TIME_AND_SC_LAST_ORDER_TIME':
            newRestaurant.tcLastOrderTime = value
            newRestaurant.scLastOrderTime = sify(value)
            break
        case 'CHANGE_TC_DESCRIPTION_AND_SC_DESCRIPTION':
            newRestaurant.tcDescription = value
            newRestaurant.scDescription = sify(value)
            break
        case 'CHANGE_TC_ADDRESS_AND_SC_ADDRESS':
            newRestaurant.tcAddress = value
            newRestaurant.scAddress = sify(value)
            break
        case 'CHANGE_BRAND':
            newRestaurant.brandCode = value.brandCode
            newRestaurant.brandTcName = value.brandTcName
            newRestaurant.brandEnName = value.brandEnName
            newRestaurant.brandScName = value.brandScName
            break
        case 'CHANGE_ADDRESS':
            newRestaurant.coorX = value.coorX
            newRestaurant.coorY = value.coorY
            newRestaurant.coorZ = value.coorZ
            newRestaurant.tcAddress = value.tcAddress
            newRestaurant.enAddress = value.enAddress
            newRestaurant.scAddress = sify(value.tcAddress)
            break
        case 'CHANGE_IMAGE':
            newRestaurant.picture = value.picture
            newRestaurant.thumbnail = value.thumbnail
            break
        case 'scNameDisable':
            newRestaurant[action] = value
            if (value) {
                newRestaurant.scName = newRestaurant.tcName
                    ? sify(newRestaurant.tcName)
                    : newRestaurant.tcName
            }
            break
        case 'scSNameDisable':
            newRestaurant[action] = value
            if (value) {
                newRestaurant.enSName = newRestaurant.enName
                newRestaurant.tcSName = newRestaurant.tcName
                newRestaurant.scSName = newRestaurant.scName
            }
            break
        case 'scSearchNameDisable':
            newRestaurant[action] = value
            if (value) {
                newRestaurant.scSearchName = newRestaurant.tcSearchName
                    ? sify(newRestaurant.tcSearchName)
                    : newRestaurant.tcSearchName
            }
            break
        case 'scTicketMessageDisable':
            newRestaurant[action] = value
            if (value) {
                newRestaurant.scTicketMessage = newRestaurant.tcTicketMessage
                    ? sify(newRestaurant.tcTicketMessage)
                    : newRestaurant.tcTicketMessage
            }
            break
        case 'scOpeningDisable':
            newRestaurant[action] = value
            if (value) {
                newRestaurant.scOpening = newRestaurant.tcOpening
                    ? sify(newRestaurant.tcOpening)
                    : newRestaurant.tcOpening
            }
            break
        case 'scLastOrderTimeDisable':
            newRestaurant[action] = value
            if (value) {
                newRestaurant.scLastOrderTime = newRestaurant.tcLastOrderTime
                    ? sify(newRestaurant.tcLastOrderTime)
                    : newRestaurant.tcLastOrderTime
            }
            break
        case 'scDescriptionDisable':
            newRestaurant[action] = value
            if (value) {
                newRestaurant.scDescription = newRestaurant.tcDescription
                    ? sify(newRestaurant.tcDescription)
                    : newRestaurant.tcDescription
            }
            break
        case 'scAddressDisable':
            newRestaurant[action] = value
            if (value) {
                newRestaurant.scAddress = newRestaurant.tcAddress
                    ? sify(newRestaurant.tcAddress)
                    : newRestaurant.tcAddress
            }
            break
        case RESTAURANT_ACTIONS.SET_REGION:
            // Region
            newRestaurant.regionCode = value?.regionCode || ''
            newRestaurant.regionName = getI18nObjValue(guluLang, value?.name) || ''
            // City
            newRestaurant.cityCode = isSelectedPreRegion ? newRestaurant?.cityCode : null
            newRestaurant.cityName = isSelectedPreRegion ? newRestaurant?.cityName : null
            // District
            newRestaurant.districtId = isSelectedPreRegion ? newRestaurant?.districtId : null
            newRestaurant.districtName = isSelectedPreRegion ? newRestaurant?.districtName : null
            break
        case RESTAURANT_ACTIONS.SET_CITY:
            // City
            newRestaurant.cityCode = value?.cityCode || ''
            newRestaurant.cityName = getI18nObjValue(guluLang, value?.name) || ''
            // District
            newRestaurant.districtId = isSelectedPreRegionAndCity ? newRestaurant?.districtId : null
            newRestaurant.districtName = isSelectedPreRegion ? newRestaurant?.districtName : null
            break
        case RESTAURANT_ACTIONS.SET_DISTRICT:
            let districtName = value.enName

            if (guluLang === cons.LANG.TC) {
                districtName = value.tcName
            }

            if (guluLang === cons.LANG.SC) {
                districtName = value.scName
            }

            newRestaurant.districtId = value.districtId
            newRestaurant.districtName = districtName
            break
        default:
            newRestaurant[action] = value
            if (value) {
                newRestaurant.scName = newRestaurant.tcName
                    ? sify(newRestaurant.tcName)
                    : newRestaurant.tcName
            }
    }
    return {
        type: RESTAURANT.SET_RESTAURANT_VALUE,
        restaurant: newRestaurant,
    }
}

export const setRestaurantClone = (restaurantClone) => {
    return {
        type: RESTAURANT.SET_RESTAURANT_CLONE,
        restaurantClone: restaurantClone,
    }
}

export const setRestaurantCloneValue = (draftType, orignalProduct, key, value, index) => {
    const restaurantClone = _.cloneDeep(orignalProduct)
    restaurantClone[draftType] = value
    return {
        type: RESTAURANT.SET_RESTAURANT_CLONE_VALUE,
        restaurantClone: restaurantClone,
    }
}
