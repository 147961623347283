import { Box } from '@mui/material'
import MUIInput from './MUIInput'

export default function MultiLangInput({ langObj = {}, objKey, onChangeMultiLangInput, disabled, error, label }) {
  return (
    <Box sx={{ gridColumn: '1 / -1', display: 'grid', rowGap: 3, columnGap: 1, gridTemplateColumns: 'repeat(3, 1fr)' }}>
      {Object.keys(langObj).map((lang) => {
        const value = langObj[lang]

        return (
          <MUIInput
            label={`${lang} ${label}`}
            name={lang}
            value={value}
            onChange={(e) => onChangeMultiLangInput(objKey, lang, e.target.value)}
            key={`${lang}-${label}`}
            disabled={disabled}
            error={error}
          />
        )
      })}
    </Box>
  )
}
