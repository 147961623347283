import { useState } from 'react'
import { Box, Drawer, IconButton } from '@mui/material'
import Iconify from '../iconify/Iconify'
import { ICONIFY } from '../../../Constants/iconify'
import CityList from '../../../Page/Region/CityList'
import MUIInput from '../field/MUIInput'
import { useSelector } from 'react-redux'
import { t } from '../../../helper/util'

export function CityField({ setCity, error, value, required = true, disabled, filterRegionCode }) {
    const guluLang = useSelector((state) => state.system.ui.lang)
    const [showDrawer, setShowDrawer] = useState(false)

    const onClickShowDrawer = () => {
        setShowDrawer(true)
    }

    const onClickCloseDrawer = () => {
        setShowDrawer(false)
    }

    const onClickListRow = (row) => {
        setShowDrawer(false)
        setCity(row)
    }

    return (
        <>
            <MUIInput
                label='City'
                value={value || ''}
                name={t(guluLang, 'resource.city.city')}
                endAdornment={
                    <IconButton onClick={onClickShowDrawer}>
                        <Iconify icon={ICONIFY.edit} />
                    </IconButton>
                }
                readOnly
                required={required}
                error={error}
                disabled={disabled}
            />
            <Drawer open={showDrawer} onClose={onClickCloseDrawer} anchor='right'>
                <Box sx={{ p: 2, overflow: 'auto' }}>
                    <CityList
                        onClickListRow={onClickListRow}
                        readOnly
                        newFilter={filterRegionCode ? { regionCode: filterRegionCode } : {}}
                    />
                </Box>
            </Drawer>
        </>
    )
}
