import { useState, useEffect } from 'react'
import { useStore } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import _ from 'lodash'

import { Card } from '@mui/material'

import { ListDisplay } from '../../Component/ListDisplay/ListDisplay'

import { constants as cons } from '../../Constants/constants'
import { getList } from '../../helper/baseFetch'
import { generateTableCellToMultiLanguageName, getParamQueryString, getQuery, getQueryParamObject } from '../../helper/util'

export const AreaList = ({ changeUrlQueryString, onClickListRow, readOnly }) => {
  const store = useStore()
  const [areaList, setAreaList] = useState([])
  const [ready, setReady] = useState(false)
  const resource = 'Area'
  const history = useHistory()
  const location = useLocation()
  const filterParams = getQuery()

  const listTitles = [
    {
      key: cons.LIST_DISPLAY_TYPE.STRING,
      value: 'areaId',
      sort: true,
    },
    {
      key: cons.LIST_DISPLAY_TYPE.STRING,
      value: 'name',
      sort: false,
      minSize: true,
    },
    {
      key: cons.LIST_DISPLAY_TYPE.STRING,
      value: 'status',
      sort: true,
    },
  ]

  const listFilters = [
    {
      className: 'filter-input',
      label: { resource: resource, key: 'name' },
      clear: true,
      value: '',
      name: 'name',
      size: cons.SIZE.SMALL,
    },
    {
      className: 'filter-input',
      label: { resource: resource, key: 'status' },
      value: '',
      type: cons.TYPE.SELECT,
      selectValue: cons.CHOICES.RESTAURANT_AREA.STATUS,
      name: 'status',
      size: cons.SIZE.SMALL,
    },
  ]

  const [param, setParam] = useState(
    !changeUrlQueryString
      ? {
          path: '',
          page: 0,
          rowsPerPage: 10,
          sort: 'updateTimestamp',
          direction: 'DESC',
          listFilters: _.cloneDeep(listFilters),
          count: 0,
        }
      : getQueryParamObject(
          {
            path: '',
            page: 0,
            rowsPerPage: 10,
            sort: 'updateTimestamp',
            direction: 'DESC',
            listFilters: _.cloneDeep(listFilters),
            count: 0,
          },
          _.cloneDeep(listFilters),
          listTitles
        )
  )

  useEffect(() => {
    if (!changeUrlQueryString) {
      return
    }

    const querySyring = getParamQueryString(
      {
        path: '',
        page: 0,
        rowsPerPage: 10,
        sort: 'updateTimestamp',
        direction: 'DESC',
        listFilters: _.cloneDeep(listFilters),
        count: param.count,
      },
      param
    )

    const currentQueryString = window.location.search?.substring(1)

    if (querySyring === currentQueryString) {
      return
    }

    const newParam = getQueryParamObject(
      {
        path: '',
        page: 0,
        rowsPerPage: 10,
        sort: 'updateTimestamp',
        direction: 'DESC',
        listFilters: _.cloneDeep(listFilters),
        count: param.count + 1,
      },
      _.cloneDeep(listFilters),
      listTitles
    )

    setParam(newParam)

    const newQuery = getParamQueryString(
      {
        path: '',
        page: 0,
        rowsPerPage: 10,
        sort: 'updateTimestamp',
        direction: 'DESC',
        listFilters: _.cloneDeep(listFilters),
        count: 0,
      },
      newParam
    )

    history.replace({ pathname: location.pathname, search: newQuery })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterParams])

  function onChangeParam(value) {
    value.count = value.count + 1

    if (!changeUrlQueryString) {
      setParam(value)
      return
    }

    const queryString = getParamQueryString(
      {
        path: '',
        page: 0,
        rowsPerPage: 10,
        sort: 'updateTimestamp',
        direction: 'DESC',
        listFilters: _.cloneDeep(listFilters),
        count: 0,
      },
      value
    )

    history.push({ pathname: location.pathname, search: queryString })
  }

  function onChangeListFilters(list) {
    let tempParam = _.cloneDeep(param)
    tempParam.listFilters = list
    tempParam.page = 0
    onChangeParam(tempParam)
  }

  function onChangeListSort(sortItem) {
    let tempParam = _.cloneDeep(param)
    tempParam.sort = sortItem
    tempParam.direction = tempParam.direction === 'DESC' ? 'ASC' : 'DESC'
    onChangeParam(tempParam)
  }

  function onChangeListPage(page) {
    let tempParam = _.cloneDeep(param)
    tempParam.page = page
    onChangeParam(tempParam)
  }

  function onChangeListRowsPerPage(rowsPerPage) {
    let tempParam = _.cloneDeep(param)
    tempParam.page = 0
    tempParam.rowsPerPage = rowsPerPage
    onChangeParam(tempParam)
  }

  useEffect(() => {
    let tempParam = _.cloneDeep(param)
    const filter = {}
    tempParam.listFilters.forEach((filterItem) => {
      if (filterItem.value !== '' && filterItem.value !== undefined) {
        filter[filterItem.name] = filterItem.value
      }
    })

    getList(
      cons.PATH.SHOP.RESTAURANT_AREA,
      {
        sort: { field: tempParam.sort, order: tempParam.direction },
        filter: filter,
        pagination: { page: tempParam.page + 1, perPage: tempParam.rowsPerPage },
      },
      (payload) => {
        payload.map((item) => {
          item.name = generateTableCellToMultiLanguageName(item.tcName, item.enName, item.scName)
          return item
        })
        setAreaList(payload)
        setReady(true)
      },
      undefined,
      store
    )
  }, [param.count]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    ready && (
      <Card className="card-wrapper overflow">
        <ListDisplay
          page={param.page}
          rowsPerPage={param.rowsPerPage}
          onChangeListSort={onChangeListSort}
          resource={resource}
          dataList={areaList}
          sort={param.sort}
          direction={param.direction}
          listTitles={listTitles}
          listFilters={param.listFilters}
          onChangeListPage={onChangeListPage}
          onChangeListRowsPerPage={onChangeListRowsPerPage}
          onChangeListFilters={onChangeListFilters}
          redirectPath={'/' + cons.PATH.SHOP.RESTAURANT_AREA + '/'}
          onClickListRow={onClickListRow}
        />
      </Card>
    )
  )
}
