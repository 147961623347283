import { useCallback, useEffect, useState } from 'react'

import { Drawer } from '@mui/material'
import Paper from '@mui/material/Paper'
import ClearIcon from '@mui/icons-material/Clear'
import { constants, LANDING_ACTION } from '../../../Constants/constants'
import { TempTextInput } from '../../../Component/Input/TempTextInput'
import { TempAutoSelectInput } from '../../../Component/Input/TempAutoSelectInput'
import { TempBtn } from '../../../Component/Input/TempBtn'
import { helperTextStyles } from './landingUtil'
import { ProductList } from '../../../Component/ProductCreate/Product/ProductList'
import { RestaurantList } from '../../Restaurant/RestaurantList'
import { FolderList } from '../../Folder/FolderList'
import { BrandList } from '../../Brand/BrandList'
import { ProductCategoryList } from '../../../Component/ProductCreate/Category/ProductCategoryList'
import { landingValidationConstants } from '../../../Constants/validationConstants'
import { SubTabEditCreateDisplay } from '../../../Component/EditCreateDisplay/SubTabEditCreateDisplay'
import { RestaurantCategoryList } from '../../RestaurantCategory/RestaurantCategoryList'

export const LandingAction = ({ actionSelectList, resource, tempItem, onChangeInput, validation, id }) => {
  const helperTextStylesClasses = helperTextStyles()
  const [drawerType, setDrawerType] = useState(null)
  const [drawerOpen, setDrawerOpen] = useState(false)

  const getShowCatBrandListValue = (param) => {
    if (!param) return null

    if (param.showCategoryList) {
      return 'SHOW_CATEGORY_LIST'
    }

    if (param.showBrandList) {
      return 'SHOW_BRAND_LIST'
    }

    return null
  }

  function onClickCloseDrawer() {
    setDrawerOpen(false)
  }

  const handleOnClickDrawer = (action, type) => {
    switch (action) {
      case LANDING_ACTION.PRODUCT:
      case LANDING_ACTION.RESTAURANT:
      case LANDING_ACTION.FOLDER:
      case LANDING_ACTION.COUPON:
      case LANDING_ACTION.E_COUPON:
      case LANDING_ACTION.PRODUCT_COUPON_CATEGORY_MAP:
      case LANDING_ACTION.PRODUCT_COUPON_BRAND_MAP:
      case LANDING_ACTION.PRODUCT_CATEGORY:
      case LANDING_ACTION.E_COUPON_CATEGORY:
      case LANDING_ACTION.PRODUCT_BRAND:
      case LANDING_ACTION.E_COUPON_BRAND:
      case LANDING_ACTION.RESTAURANT_CATEGORY:
        setDrawerOpen(true)
        switch (type) {
          case 'ecouponCode':
            setDrawerType(LANDING_ACTION.E_COUPON)
            break
          default:
            setDrawerType(action)
            break
        }
        renderDrawer()
        break
      default:
        setDrawerOpen(false)
        break
    }
  }

  const onClickListRow = useCallback((item, type) => {
    switch (type) {
      case LANDING_ACTION.PRODUCT:
        onChangeInput('productCode', item.productCode)
        break
      case LANDING_ACTION.RESTAURANT:
        onChangeInput('restUrlId', item.restUrlId)
        break
      case LANDING_ACTION.FOLDER:
        onChangeInput('folderCode', item.folderCode)
        break
      case LANDING_ACTION.E_COUPON:
        item.ecouponCode = item.productCode
        onChangeInput('ecouponCode', item.ecouponCode)
        break
      case LANDING_ACTION.PRODUCT_COUPON_CATEGORY_MAP:
      case LANDING_ACTION.PRODUCT_CATEGORY:
      case LANDING_ACTION.E_COUPON_CATEGORY:
        onChangeInput('categoryCode', item.categoryCode)
        break
      case LANDING_ACTION.RESTAURANT_CATEGORY:
        onChangeInput('categoryCode', item.tagCode)
        break
      case LANDING_ACTION.PRODUCT_COUPON_BRAND_MAP:
      case LANDING_ACTION.PRODUCT_BRAND:
      case LANDING_ACTION.E_COUPON_BRAND:
        onChangeInput('brandCode', item.brandCode)
        break
      default:
        break
    }

    onClickCloseDrawer()
  }, [onChangeInput])

  const renderDrawer = () => {
    let drawer
    switch (drawerType) {
      case LANDING_ACTION.PRODUCT:
        drawer = (
          <Paper>
            <ProductList
              newFilter={{ excludedTypeList: [constants.PRODUCT_TYPE.PACKAGE_SUB_ITEM, constants.PRODUCT_TYPE.FREE_ECOUPON_ITEM] }}
              editBtn={false}
              createBtn={false}
              onClickListRow={(item) => onClickListRow(item, drawerType)}
              dialogFooterBtn={
                <>
                  <TempBtn
                    btnSetting={{
                      variant: constants.STYLE.VARIANT.CONTAINED,
                      color: constants.STYLE.PRIMARY,
                      onClick: onClickCloseDrawer,
                      icon: <ClearIcon />,
                      label: { key: 'close' },
                    }}
                  />
                </>
              }
            />
          </Paper>
        )
        break
      case LANDING_ACTION.E_COUPON:
        drawer = (
          <Paper>
            <ProductList
              newFilter={{ type: constants.PRODUCT_TYPE.FREE_ECOUPON_ITEM, excludedTypeList: [constants.PRODUCT_TYPE.PACKAGE_SUB_ITEM] }}
              editBtn={false}
              createBtn={false}
              onClickListRow={(item) => onClickListRow(item, drawerType)}
              dialogFooterBtn={
                <>
                  <TempBtn
                    btnSetting={{
                      variant: constants.STYLE.VARIANT.CONTAINED,
                      color: constants.STYLE.PRIMARY,
                      onClick: onClickCloseDrawer,
                      icon: <ClearIcon />,
                      label: { key: 'close' },
                    }}
                  />
                </>
              }
            />
          </Paper>
        )
        break
      case LANDING_ACTION.PRODUCT_COUPON_CATEGORY_MAP:
        drawer = (
          <Paper>
            <ProductCategoryList
              newFilter={{ productCode: tempItem.parameters?.productCode ? tempItem?.parameters?.productCode : tempItem?.parameters?.ecouponCode }}
              newPath={constants.PATH.PRODUCT.PRODUCT_CATEGORY_MAP}
              editBtn={false}
              createBtn={false}
              onClickListRow={(item) => onClickListRow(item, LANDING_ACTION.PRODUCT_COUPON_CATEGORY_MAP)}
              resource={resource}
              dialogFooterBtn={
                <>
                  <TempBtn
                    btnSetting={{
                      variant: constants.STYLE.VARIANT.CONTAINED,
                      color: constants.STYLE.PRIMARY,
                      onClick: onClickCloseDrawer,
                      icon: <ClearIcon />,
                      label: { key: 'close' },
                    }}
                  />
                </>
              }
            />
          </Paper>
        )
        break
      case LANDING_ACTION.PRODUCT_COUPON_BRAND_MAP:
        drawer = (
          <Paper>
            <BrandList
              editBtn={false}
              createBtn={false}
              newPath={constants.PATH.PRODUCT.PRODUCT_BRAND_MAP}
              newFilter={{ productCode: tempItem.parameters?.productCode ? tempItem.parameters?.productCode : tempItem.parameters?.ecouponCode }}
              onClickListRow={(item) => onClickListRow(item, LANDING_ACTION.PRODUCT_COUPON_BRAND_MAP)}
              dialogFooterBtn={
                <>
                  <TempBtn
                    btnSetting={{
                      variant: constants.STYLE.VARIANT.CONTAINED,
                      color: constants.STYLE.PRIMARY,
                      onClick: onClickCloseDrawer,
                      icon: <ClearIcon />,
                      label: { key: 'close' },
                    }}
                  />
                </>
              }
            />
          </Paper>
        )
        break
      case LANDING_ACTION.RESTAURANT:
        drawer = (
          <Paper>
            <RestaurantList
              editBtn={false}
              createBtn={false}
              btnList={false}
              onClickListRow={(item) => onClickListRow(item, drawerType)}
              dialogFooterBtn={
                <>
                  <TempBtn
                    btnSetting={{
                      variant: constants.STYLE.VARIANT.CONTAINED,
                      color: constants.STYLE.PRIMARY,
                      onClick: onClickCloseDrawer,
                      icon: <ClearIcon />,
                      label: { key: 'close' },
                    }}
                  />
                </>
              }
            />
          </Paper>
        )
        break
      case LANDING_ACTION.FOLDER:
        drawer = (
          <Paper>
            <FolderList
              editBtn={false}
              createBtn={false}
              onClickListRow={(item) => onClickListRow(item, drawerType)}
              dialogFooterBtn={
                <>
                  <TempBtn
                    btnSetting={{
                      variant: constants.STYLE.VARIANT.CONTAINED,
                      color: constants.STYLE.PRIMARY,
                      onClick: onClickCloseDrawer,
                      icon: <ClearIcon />,
                      label: { key: 'close' },
                    }}
                  />
                </>
              }
            />
          </Paper>
        )
        break
      case LANDING_ACTION.E_COUPON_CATEGORY:
        drawer = (
          <Paper>
            <ProductCategoryList
              newFilter={{
                typeList: [
                  constants.CHOICES.PRODUCT_CREATE.SELECT_CATEGORY_TYPE.FREE_ECOUPON,
                  constants.CHOICES.PRODUCT_CREATE.SELECT_CATEGORY_TYPE.ALL,
                ],
              }}
              onClickListRow={(item) => onClickListRow(item, drawerType)}
              dialogFooterBtn={
                <>
                  <TempBtn
                    btnSetting={{
                      variant: constants.STYLE.VARIANT.CONTAINED,
                      color: constants.STYLE.PRIMARY,
                      onClick: onClickCloseDrawer,
                      icon: <ClearIcon />,
                      label: { key: 'close' },
                    }}
                  />
                </>
              }
            />
          </Paper>
        )
        break
      case LANDING_ACTION.PRODUCT_CATEGORY:
        drawer = (
          <Paper>
            <ProductCategoryList
              newFilter={{
                typeList: [constants.CHOICES.PRODUCT_CREATE.SELECT_CATEGORY_TYPE.PRODUCT, constants.CHOICES.PRODUCT_CREATE.SELECT_CATEGORY_TYPE.ALL],
              }}
              editBtn={false}
              createBtn={false}
              onClickListRow={(item) => onClickListRow(item, drawerType)}
              dialogFooterBtn={
                <>
                  <TempBtn
                    btnSetting={{
                      variant: constants.STYLE.VARIANT.CONTAINED,
                      color: constants.STYLE.PRIMARY,
                      onClick: onClickCloseDrawer,
                      icon: <ClearIcon />,
                      label: { key: 'close' },
                    }}
                  />
                </>
              }
            />
          </Paper>
        )
        break
      case LANDING_ACTION.RESTAURANT_CATEGORY:
        drawer = (
          <Paper>
            <RestaurantCategoryList
              onClickListRow={(item) => onClickListRow(item, drawerType)}
              createBtn={false}
              editBtn={false}
            />
          </Paper>
        )
        break 
      case LANDING_ACTION.PRODUCT_BRAND:
      case LANDING_ACTION.E_COUPON_BRAND:
        drawer = (
          <Paper>
            <BrandList
              editBtn={false}
              createBtn={false}
              onClickListRow={(item) => onClickListRow(item, drawerType)}
              dialogFooterBtn={
                <>
                  <TempBtn
                    btnSetting={{
                      variant: constants.STYLE.VARIANT.CONTAINED,
                      color: constants.STYLE.PRIMARY,
                      onClick: onClickCloseDrawer,
                      icon: <ClearIcon />,
                      label: { key: 'close' },
                    }}
                  />
                </>
              }
            />
          </Paper>
        )
        break
      default:
        drawer = <></>
        break
    }
    return drawer
  }

  const contentList = [
    {
      className: 'edit-create-input',
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'displayActionType' },
      value: tempItem?.displayActionType,
      name: 'displayActionType',
      type: constants.TYPE.SELECT,
      selectValue: actionSelectList,
      isEndOfLine: true,
    },
    //restaurant
    {
      className: `edit-create-input ${tempItem.displayActionType !== LANDING_ACTION.RESTAURANT ? 'display-none' : ''}`,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'restUrlId' },
      value: tempItem?.parameters?.restUrlId,
      name: 'restUrlId',
      isEndOfLine: false,
      required: true,
      disabled: true,
      onClickEndAdornmentIconButton: () => handleOnClickDrawer(tempItem.displayActionType),
      helperText: validation.getErrorMessages(`${id}-${landingValidationConstants.KEY_PARAMETERS}`),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    //product
    {
      className: `edit-create-input ${tempItem.displayActionType !== LANDING_ACTION.PRODUCT ? 'display-none' : ''}`,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'productCode' },
      value: tempItem?.parameters?.productCode,
      name: 'productCode',
      isEndOfLine: false,
      required: true,
      disabled: true,
      onClickEndAdornmentIconButton: () => handleOnClickDrawer(tempItem.displayActionType),
      helperText: validation.getErrorMessages(`${id}-${landingValidationConstants.KEY_PARAMETERS}`),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    //ecoupon
    {
      className: `edit-create-input ${tempItem.displayActionType !== LANDING_ACTION.E_COUPON ? 'display-none' : ''}`,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'ecouponCode' },
      value: tempItem?.parameters?.ecouponCode,
      name: 'ecouponCode',
      isEndOfLine: false,
      required: true,
      disabled: true,
      onClickEndAdornmentIconButton: () => handleOnClickDrawer(tempItem.displayActionType, 'ecouponCode'),
      helperText: validation.getErrorMessages(`${id}-${landingValidationConstants.KEY_PARAMETERS}`),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    // {
    //   className: `edit-create-input ${tempItem.displayActionType !== LANDING_ACTION.COUPON ? 'display-none' : ''}`,
    //   size: constants.SIZE.SMALL,
    //   label: { resource: resource, key: 'couponCode' },
    //   value: tempItem?.parameters?.couponCode,
    //   name: 'couponCode',
    //   clear: true,
    //   isEndOfLine: false,
    //   required: true,
    //   disabled: true,
    // },
    // {
    //   className: `edit-create-input ${tempItem.displayActionType !== LANDING_ACTION.COUPON ? "display-none" : ""}`,
    //   size: constants.SIZE.SMALL,
    //   label: { resource: resource, key: "showCategoryList" },
    //   value: tempItem?.parameters?.showCategoryList,
    //   name: "showCategoryList",
    //   type: constants.TYPE.SELECT,
    //   selectValue: constants.CHOICES.LANDING_ITEM.SHOW_CATEGORY_LIST,
    //   clear: true,
    //   isEndOfLine: false
    // },
    //PRODUCT && E_COUPON
    {
      className: `edit-create-input ${
        (tempItem.displayActionType === LANDING_ACTION.PRODUCT && tempItem.parameters?.productCode) ||
        (tempItem.displayActionType === LANDING_ACTION.E_COUPON && tempItem.parameters?.ecouponCode)
          ? ''
          : 'display-none'
      }`,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'showCategoryBrandList' },
      value: getShowCatBrandListValue(tempItem.parameters),
      name: 'showCategoryBrandList',
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.LANDING_ITEM.SHOW_LIST,
      isEndOfLine: false,
      required: false,
    },
    {
      className: `edit-create-input ${
        (tempItem.displayActionType !== LANDING_ACTION.PRODUCT && tempItem.displayActionType !== LANDING_ACTION.E_COUPON) ||
        (tempItem.displayActionType === LANDING_ACTION.PRODUCT && tempItem.parameters?.showBrandList) ||
        (tempItem.displayActionType === LANDING_ACTION.E_COUPON && tempItem.parameters?.showBrandList) ||
        (tempItem.displayActionType === LANDING_ACTION.PRODUCT && tempItem.parameters?.brandCode) ||
        (tempItem.displayActionType === LANDING_ACTION.E_COUPON && tempItem.parameters?.brandCode) ||
        (tempItem.displayActionType === LANDING_ACTION.PRODUCT && !tempItem.parameters?.productCode) ||
        (tempItem.displayActionType === LANDING_ACTION.E_COUPON && !tempItem.parameters?.ecouponCode)
          ? 'display-none'
          : ''
      }`,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'categoryCode' },
      value: tempItem.parameters?.categoryCode,
      name: 'categoryCode',
      required: true,
      disabled: true,
      onClickEndAdornmentIconButton: () => handleOnClickDrawer(LANDING_ACTION.PRODUCT_COUPON_CATEGORY_MAP),
      isEndOfLine: false,
      helperText: validation.getErrorMessages(`${id}-${landingValidationConstants.KEY_PARAMETERS}`),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: `edit-create-input ${
        (tempItem.displayActionType !== LANDING_ACTION.PRODUCT && tempItem.displayActionType !== LANDING_ACTION.E_COUPON) ||
        (tempItem.displayActionType === LANDING_ACTION.PRODUCT && tempItem.parameters?.showCategoryList) ||
        (tempItem.displayActionType === LANDING_ACTION.E_COUPON && tempItem.parameters?.showCategoryList) ||
        (tempItem.displayActionType === LANDING_ACTION.PRODUCT && tempItem.parameters?.categoryCode) ||
        (tempItem.displayActionType === LANDING_ACTION.E_COUPON && tempItem.parameters?.categoryCode) ||
        (tempItem.displayActionType === LANDING_ACTION.PRODUCT && !tempItem.parameters?.productCode) ||
        (tempItem.displayActionType === LANDING_ACTION.E_COUPON && !tempItem.parameters?.ecouponCode)
          ? 'display-none'
          : ''
      }`,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'brandCode' },
      value: tempItem.parameters?.brandCode,
      name: 'brandCode',
      onClickEndAdornmentIconButton: () => handleOnClickDrawer(LANDING_ACTION.PRODUCT_COUPON_BRAND_MAP),
      isEndOfLine: false,
      required: true,
      disabled: true,
      helperText: validation.getErrorMessages(`${id}-${landingValidationConstants.KEY_PARAMETERS}`),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    //web-view
    {
      className: `edit-create-input ${
        tempItem.displayActionType !== LANDING_ACTION.WEB_VIEW && tempItem.displayActionType !== LANDING_ACTION.YOUTUBE ? 'display-none' : ''
      }`,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'title' },
      value: tempItem?.parameters?.title,
      name: 'title',
      clear: true,
      isEndOfLine: false,
    },
    {
      className: `edit-create-input ${tempItem.displayActionType !== LANDING_ACTION.WEB_VIEW ? 'display-none' : ''}`,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'type' },
      value: tempItem?.parameters?.type,
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.LANDING_ITEM.WEB_TYPE,
      name: 'type',
      isEndOfLine: false,
    },
    {
      className: `full-size-input ${
        tempItem.displayActionType !== LANDING_ACTION.WEB_VIEW &&
        tempItem.displayActionType !== LANDING_ACTION.LINK &&
        // && tempItem.displayActionType !== LANDING_ACTION.YOUTUBE
        tempItem.displayActionType !== LANDING_ACTION.HYPERLINK
          ? 'display-none'
          : ''
      }`,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'url' },
      value: tempItem?.parameters?.action,
      name: 'urlAction',
      isEndOfLine: false,
      helperText: validation.getErrorMessages(`${id}-${landingValidationConstants.KEY_PARAMETERS}`),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: `full-size-input ${tempItem.displayActionType !== LANDING_ACTION.YOUTUBE ? 'display-none' : ''}`,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'youtubeCode' },
      value: tempItem?.parameters?.youtubeCode || tempItem?.parameters?.videoId,
      name: 'youtubeCode',
      isEndOfLine: false,
      helperText: validation.getErrorMessages(`${id}-${landingValidationConstants.KEY_PARAMETERS}`),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    //subAction
    {
      className: `edit-create-input ${
        tempItem.displayActionType !== LANDING_ACTION.VIDEO && tempItem.displayActionType !== LANDING_ACTION.SPRINGBOARD ? 'display-none' : ''
      }`,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'subAction' },
      value: tempItem?.parameters?.displayActionType,
      name: 'subAction',
      clear: true,
      isEndOfLine: false,
      helperText: validation.getErrorMessages(`${id}-${landingValidationConstants.KEY_PARAMETERS}`),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    // Category code
    {
      className: `edit-create-input ${
        tempItem.displayActionType !== LANDING_ACTION.PRODUCT_CATEGORY && tempItem.displayActionType !== LANDING_ACTION.RESTAURANT_CATEGORY
          ? 'display-none'
          : ''
      }`,
      label: { resource: resource, key: 'categoryCode' },
      value: tempItem?.parameters?.categoryCode,
      name: 'categoryCode',
      isEndOfLine: false,
      onClickEndAdornmentIconButton: () => handleOnClickDrawer(tempItem.displayActionType),
      required: true,
      disabled: true,
      helperText: validation.getErrorMessages(`${id}-${landingValidationConstants.KEY_PARAMETERS}`),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    //ecoupon_category
    {
      className: `edit-create-input ${tempItem.displayActionType !== LANDING_ACTION.E_COUPON_CATEGORY ? 'display-none' : ''}`,
      label: { resource: resource, key: 'categoryCode' },
      value: tempItem?.parameters?.categoryCode,
      name: 'categoryCode',
      isEndOfLine: false,
      onClickEndAdornmentIconButton: () => handleOnClickDrawer(LANDING_ACTION.E_COUPON_CATEGORY),
      required: true,
      disabled: true,
      helperText: validation.getErrorMessages(`${id}-${landingValidationConstants.KEY_PARAMETERS}`),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    //product_brand && ecoupon_brand
    {
      className: `edit-create-input ${
        tempItem.displayActionType !== LANDING_ACTION.PRODUCT_BRAND && tempItem.displayActionType !== LANDING_ACTION.E_COUPON_BRAND
          ? 'display-none'
          : ''
      }`,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'brandCode' },
      value: tempItem?.parameters?.brandCode,
      name: 'brandCode',
      isEndOfLine: false,
      required: true,
      disabled: true,
      onClickEndAdornmentIconButton: () => handleOnClickDrawer(LANDING_ACTION.PRODUCT_BRAND),
      helperText: validation.getErrorMessages(`${id}-${landingValidationConstants.KEY_PARAMETERS}`),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    //search
    {
      className: `edit-create-input ${tempItem.displayActionType !== LANDING_ACTION.SEARCH ? 'display-none' : ''}`,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'name' },
      value: tempItem?.parameters?.name,
      name: 'name',
      clear: true,
      isEndOfLine: false,
    },
    {
      className: `edit-create-input ${tempItem.displayActionType !== LANDING_ACTION.SEARCH ? 'display-none' : ''}`,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'query' },
      value: tempItem?.parameters?.query,
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.LANDING_ITEM.QUERY,
      name: 'query',
      isEndOfLine: false,
      helperText: validation.getErrorMessages(`${id}-${landingValidationConstants.KEY_PARAMETERS}`),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    //TRANSFORM_TICKET_FROM_PAPER
    {
      className: `edit-create-input ${tempItem.displayActionType !== LANDING_ACTION.TRANSFORM_TICKET_FROM_PAPER ? 'display-none' : ''}`,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'ticketId' },
      value: tempItem?.parameters?.ticketId,
      name: 'ticketId',
      isEndOfLine: false,
      helperText: validation.getErrorMessages(`${id}-${landingValidationConstants.KEY_PARAMETERS}`),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    //TRANSFORM_ECOUPON_FROM_PAPER
    {
      className: `edit-create-input ${tempItem.displayActionType !== LANDING_ACTION.TRANSFORM_ECOUPON_FROM_PAPER ? 'display-none' : ''}`,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'infoId' },
      value: tempItem?.parameters?.infoId,
      name: 'infoId',
      isEndOfLine: false,
      helperText: validation.getErrorMessages(`${id}-${landingValidationConstants.KEY_PARAMETERS}`),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    //EXPRESS_TICKET_VOUCHER
    {
      className: `edit-create-input ${tempItem.displayActionType !== LANDING_ACTION.EXPRESS_TICKET_VOUCHER ? 'display-none' : ''}`,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'groupCode' },
      value: tempItem?.parameters?.groupCode,
      name: 'groupCode',
      isEndOfLine: false,
      helperText: validation.getErrorMessages(`${id}-${landingValidationConstants.KEY_PARAMETERS}`),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    //folder
    {
      className: `edit-create-input ${tempItem.displayActionType !== LANDING_ACTION.FOLDER ? 'display-none' : ''}`,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'folderCode' },
      value: tempItem?.parameters?.folderCode,
      name: 'folderCode',
      isEndOfLine: false,
      onClickEndAdornmentIconButton: () => handleOnClickDrawer(tempItem.displayActionType),
      required: true,
      disabled: true,
      helperText: validation.getErrorMessages(`${id}-${landingValidationConstants.KEY_PARAMETERS}`),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: `edit-create-input ${tempItem.displayActionType !== LANDING_ACTION.FOLDER ? 'display-none' : ''}`,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'folderType' },
      value: tempItem?.parameters?.folderType,
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.LANDING_ITEM.FOLDER_TYPE,
      name: 'folderType',
      isEndOfLine: false,
    },
    //share
    {
      className: `edit-create-input ${tempItem.displayActionType !== LANDING_ACTION.SHARE ? 'display-none' : ''}`,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'serviceType' },
      value: tempItem?.parameters?.serviceType,
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.LANDING_ITEM.SHARE_SERVICE_TYPE,
      name: 'serviceType',
      isEndOfLine: false,
      required: true,
      helperTextIcon: true,
      helperText: validation.getErrorMessages(`${id}-${landingValidationConstants.KEY_SERVICE_TYPE}`),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    //MY_TICKET
    {
      className: `edit-create-input ${
        tempItem.displayActionType !== LANDING_ACTION.MY_TICKET && tempItem.displayActionType !== LANDING_ACTION.GIFT ? 'display-none' : ''
      }`,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'refId' },
      value: tempItem?.parameters?.refId,
      name: 'refId',
      clear: true,
      isEndOfLine: false,
      helperText: validation.getErrorMessages(`${id}-${landingValidationConstants.KEY_PARAMETERS}`),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: `edit-create-input ${tempItem.displayActionType !== LANDING_ACTION.MY_TICKET ? 'display-none' : ''}`,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'serviceType' },
      value: tempItem?.parameters?.serviceType,
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.LANDING_SECTION.MY_TICKET_SERVICE_TYPE,
      name: 'serviceType',
      isEndOfLine: false,
      required: true,
      helperTextIcon: true,
      helperText: validation.getErrorMessages(`${id}-${landingValidationConstants.KEY_SERVICE_TYPE}`),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    //gift
    {
      className: `edit-create-input ${tempItem.displayActionType !== LANDING_ACTION.GIFT ? 'display-none' : ''}`,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'code' },
      value: tempItem?.parameters?.code,
      name: 'code',
      isEndOfLine: false,
      required: true,
      helperTextIcon: true,
      helperText: validation.getErrorMessages(`${id}-${landingValidationConstants.KEY_CODE}`),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: `edit-create-input ${tempItem.displayActionType !== LANDING_ACTION.GIFT ? 'display-none' : ''}`,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'serviceType' },
      value: tempItem?.parameters?.serviceType,
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.LANDING_SECTION.GIFT_SERVICE_TYPE,
      name: 'serviceType',
      isEndOfLine: false,
      required: true,
      // helperTextIcon: true,
      helperText: validation.getErrorMessages(`${id}-${landingValidationConstants.KEY_SERVICE_TYPE}`),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    //myticket && gift
    {
      className: `edit-create-input ${tempItem.parameters?.serviceType !== 'RACK_PRODUCT' ? 'display-none' : ''}`,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'type' },
      type: constants.TYPE.SELECT,
      value: tempItem.parameters?.type,
      selectValue: constants.CHOICES.LANDING_SECTION.MY_TICKET_RACK_PRODUCT_TYPE,
      name: 'type',
      isEndOfLine: false,
      required: true,
      helperTextIcon: true,
      helperText: validation.getErrorMessages(`${id}-${landingValidationConstants.KEY_TYPE}`),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    //form
    {
      className: `edit-create-input ${
        !tempItem.displayActionType ||
        tempItem.displayActionType === LANDING_ACTION.ROTATING_BANNER ||
        tempItem.displayActionType === LANDING_ACTION.YOUTUBE ||
        tempItem.displayActionType === LANDING_ACTION.RESTAURANT_CATEGORY
          ? 'display-none'
          : ''
      }`,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'from' },
      value: tempItem?.parameters?.from,
      name: 'from',
      isEndOfLine: false,
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.LANDING_ITEM.FROM,
      freeSolo: true,
    },
    {
      className: 'full-size-input',
      size: constants.SIZE.SMALL,
      variant: constants.STYLE.VARIANT.STANDARD,
      label: { resource: resource, key: 'actionParameters' },
      value: tempItem?.actionParameters,
      name: 'actionParameters',
      isEndOfLine: true,
      disabled: true,
      multiline: true,
    },
  ]

  const content = [
    {
      tab: (
        <>
          {contentList.map((inputSetting, index) => {
            let content = <></>
            switch (inputSetting.type) {
              case constants.TYPE.SELECT:
                content = <TempAutoSelectInput inputSetting={inputSetting} handleChange={onChangeInput} />
                break
              default:
                content = <TempTextInput inputSetting={inputSetting} handleChange={onChangeInput} />
            }
            return (
              <div className="edit-display-wrapper" key={index}>
                {content}
                {inputSetting.isEndOfLine && <div></div>}
              </div>
            )
          })}
        </>
      ),
      btn: false,
    },
  ]

  return (
    <>
      <SubTabEditCreateDisplay
        code={''}
        resource={resource}
        tabs={false}
        content={content}
        onChangeInput={onChangeInput}
        display={'none'}
      />
      <Drawer classes={{ paper: 'drawer-margin-left' }} anchor="right" open={drawerOpen} onClose={onClickCloseDrawer}>
        {renderDrawer()}
      </Drawer>
    </>
  )
}
