export const RESTAURANT = {
  RESET: 'RESTAURANT.RESET',
  SET_RESTAURANT: 'RESTAURANT.SET_RESTAURANT',
  SET_RESTAURANT_DEFAULT: 'RESTAURANT.SET_RESTAURANT_DEFAULT',
  SET_RESTAURANT_VALUE: 'RESTAURANT.SET_RESTAURANT_VALUE',
  SET_RESTAURANT_CLONE: 'RESTAURANT.SET_RESTAURANT_CLONE',
  SET_RESTAURANT_CLONE_VALUE: 'RESTAURANT.SET_RESTAURANT_CLONE_VALUE',
}

export const SYSTEM = {
  SET_LOADING: 'SYSTEM.SET_LOADING',
  SET_MESSAGE: 'SYSTEM.SET_MESSAGE',
  SET_TOTAL: 'SET_TOTAL',
}

export const LANG = {
  SET_LANG: 'LANG.SET_LANG',
}

export const THEME = {
  SET_THEME: 'THEME.SET_THEME',
}

export const CHANGE_REMIND = {
  SET_CHANGE_REMIND_DEFAULT: 'CHANGE_REMIND.SET_CHANGE_REMIND_DEFAULT',
  SET_ON_CHANGE: 'CHANGE_REMIND.SET_ON_CHANGE',
  SET_CHANGE_REMIND_DIALOG: 'CHANGE_REMIND.SET_CHANGE_REMIND_DIALOG',
}

export const PRODUCT = {
  SET_PRODUCT_DEFAULT: 'PRODUCT.SET_PRODUCT_DEFAULT',
  SET_PRODUCT: 'PRODUCT.SET_PRODUCT',
  SET_PRODUCT_VALUE: 'PRODUCT.SET_PRODUCT_VALUE',
  SET_PRODUCT_STEP_ERROR_VALUE: 'SET_PRODUCT_STEP_ERROR_VALUE',
  SET_PRODUCT_CATEGORY: 'PRODUCT.SET_PRODUCT_CATEGORY',
}

export const PRODUCT_MAP = {
  SET_PRODUCT_MAP_DEFAULT: 'PRODUCT_MAP.SET_PRODUCT_MAP_DEFAULT',
  SET_PRODUCT_MAP: 'PRODUCT_MAP.SET_PRODUCT_MAP',
  SET_PRODUCT_MAP_VALUE: 'PRODUCT_MAP.SET_PRODUCT_MAP_VALUE',
}

export const BRAND = {
  SET_BRAND_DEFAULT: 'BRAND.SET_BRAND_DEFAULT',
  SET_BRAND: 'BRAND.BRAND',
  SET_BRAND_VALUE: 'BRAND.SET_BRAND_VALUE',
}

export const BRAND_GROUP = {
  SET_BRAND_GROUP_DEFAULT: 'BRAND_GROUP.SET_BRAND_GROUP_DEFAULT',
  SET_BRAND_GROUP: 'BRAND_GROUP.BRAND_GROUP',
  SET_BRAND_GROUP_VALUE: 'BRAND_GROUP.SET_BRAND_GROUP_VALUE',
}

export const SYSTEM_GROUP = {
  SET_SYSTEM_GROUP_DEFAULT: 'SYSTEM_GROUP.SET_SYSTEM_GROUP_DEFAULT',
  SET_SYSTEM_GROUP: 'SYSTEM_GROUP.SYSTEM_GROUP',
  SET_SYSTEM_GROUP_VALUE: 'SYSTEM_GROUP.SET_SYSTEM_GROUP_VALUE',
}

export const SYSTEM_USER = {
  SET_SYSTEM_USER_DEFAULT: 'SYSTEM_USER.SET_SYSTEM_USER_DEFAULT',
  SET_SYSTEM_USER: 'SYSTEM_USER.SYSTEM_USER',
  SET_SYSTEM_USER_VALUE: 'SYSTEM_USER.SET_SYSTEM_USER_VALUE',
}

export const RESTAURANT_PAYMENT = {
  SET_RESTAURANT_PAYMENT_DEFAULT: 'RESTAURANT_PAYMENT.SET_RESTAURANT_PAYMENT_DEFAULT',
  SET_RESTAURANT_PAYMENT: 'RESTAURANT_PAYMENT.SET_RESTAURANT_PAYMENT',
  SET_RESTAURANT_PAYMENT_VALUE: 'RESTAURANT_PAYMENT.SET_RESTAURANT_PAYMENT_VALUE',
}

export const RESTAURANT_MEDIA = {
  SET_RESTAURANT_MEDIA_DEFAULT: 'RESTAURANT_MEDIA.SET_RESTAURANT_MEDIA_DEFAULT',
  SET_RESTAURANT_MEDIA: 'RESTAURANT_MEDIA.SET_RESTAURANT_MEDIA',
  SET_RESTAURANT_MEDIA_VALUE: 'RESTAURANT_MEDIA.SET_RESTAURANT_MEDIA_VALUE',
}

export const RESTAURANT_BLACKOUT = {
  SET_RESTAURANT_BLACKOUT_DEFAULT: 'RESTAURANT_BLACKOUT.SET_RESTAURANT_BLACKOUT_DEFAULT',
  SET_RESTAURANT_BLACKOUT: 'RESTAURANT_BLACKOUT.SET_RESTAURANT_BLACKOUT',
  SET_RESTAURANT_BLACKOUT_VALUE: 'RESTAURANT_BLACKOUT.SET_RESTAURANT_BLACKOUT_VALUE',
}

export const RESTAURANT_SLIDESHOW = {
  SET_RESTAURANT_SLIDESHOW_DEFAULT: 'RESTAURANT_SLIDESHOW.SET_RESTAURANT_SLIDESHOW_DEFAULT',
  SET_RESTAURANT_SLIDESHOW: 'RESTAURANT_SLIDESHOW.SET_RESTAURANT_SLIDESHOW',
  SET_RESTAURANT_SLIDESHOW_VALUE: 'RESTAURANT_SLIDESHOW.SET_RESTAURANT_SLIDESHOW_VALUE',
}

export const RESTAURANT_TAG = {
  SET_RESTAURANT_TAG_DEFAULT: 'RESTAURANT_TAG.SET_RESTAURANT_TAG_DEFAULT',
  SET_RESTAURANT_TAG: 'RESTAURANT_TAG.SET_RESTAURANT_TAG',
  SET_RESTAURANT_TAG_VALUE: 'RESTAURANT_TAG.SET_RESTAURANT_TAG_VALUE',
}

export const RESTAURANT_TAG_MAP = {
  SET_RESTAURANT_TAG_MAP_DEFAULT: 'RESTAURANT_TAG_MAP.SET_RESTAURANT_TAG_MAP_DEFAULT',
  SET_RESTAURANT_TAG_MAP: 'RESTAURANT_TAG_MAP.SET_RESTAURANT_TAG',
  SET_RESTAURANT_TAG_MAP_VALUE: 'RESTAURANT_TAG_MAP.SET_RESTAURANT_TAG_MAP_VALUE',
}

export const URL_MAP = {
  SET_URL_MAP_DEFAULT: 'URL_MAP.SET_URL_MAP_DEFAULT',
  SET_URL_MAP: 'URL_MAP.SET_URL_MAP',
  SET_URL_MAP_VALUE: 'URL_MAP.SET_URL_MAP_VALUE',
}

export const ADMIN_DEVICE = {
  SET_ADMIN_DEVICE_DEFAULT: 'ADMIN_DEVICE.SET_ADMIN_DEVICE_DEFAULT',
  SET_ADMIN_DEVICE: 'ADMIN_DEVICE.SET_ADMIN_DEVICE',
  SET_ADMIN_DEVICE_VALUE: 'ADMIN_DEVICE.SET_ADMIN_DEVICE_VALUE',
}

export const CAMPAIGN_DESCRIPTION = {
  SET_CAMPAIGN_DESCRIPTION_DEFAULT: 'CAMPAIGN_DESCRIPTION.SET_CAMPAIGN_DESCRIPTION_DEFAULT',
  SET_CAMPAIGN_DESCRIPTION: 'CAMPAIGN_DESCRIPTION.SET_CAMPAIGN_DESCRIPTION',
  SET_CAMPAIGN_DESCRIPTION_VALUE: 'CAMPAIGN_DESCRIPTION.SET_CAMPAIGN_DESCRIPTION_VALUE',
}

export const TNC = {
  SET_TNC_DEFAULT: 'TNC.SET_TNC_DEFAULT',
  SET_TNC: 'TNC.SET_TNC',
  SET_TNC_VALUE: 'TNC.SET_TNC_VALUE',
}

export const PRINT_TEMPLATE = {
  SET_PRINT_TEMPLATE_DEFAULT: 'PRINT_TEMPLATE.SET_PRINT_TEMPLATE_DEFAULT',
  SET_PRINT_TEMPLATE: 'PRINT_TEMPLATE.SET_PRINT_TEMPLATE',
  SET_PRINT_TEMPLATE_VALUE: 'PRINT_TEMPLATE.SET_PRINT_TEMPLATE_VALUE',
  SET_PRINT_TEMPLATE_TEMPLATE: 'PRINT_TEMPLATE.SET_PRINT_TEMPLATE_TEMPLATE',
  SET_PRINT_TEMPLATE_TEMPLATE_LABEL: 'PRINT_TEMPLATE.SET_PRINT_TEMPLATE_TEMPLATE_LABEL',
  SET_PRINT_TEMPLATE_TEMPLATE_VALUE: 'PRINT_TEMPLATE.SET_PRINT_TEMPLATE_TEMPLATE_VALUE',
  SET_PRINT_TEMPLATE_TEMPLATE_LABEL_VALUE: 'PRINT_TEMPLATE.SET_PRINT_TEMPLATE_TEMPLATE_LABEL_VALUE',
  SET_TEMPLATE: 'PRINT_TEMPLATE.SET_TEMPLATE',
  SET_TEMPLATE_VALUE: 'PRINT_TEMPLATE.SET_TEMPLATE_VALUE',
  SET_TEMPLATE_LABEL: 'PRINT_TEMPLATE.SET_TEMPLATE_LABEL',
  SET_TEMPLATE_LABEL_VALUE: 'PRINT_TEMPLATE.SET_TEMPLATE_LABEL_VALUE',
  SET_CUSTOM_ID_NUMBER: 'PRINT_TEMPLATE.SET_CUSTOM_ID_NUMBER',
}

export const EMAIL_TEMPLATE = {
  SET_EMAIL_TEMPLATE_DEFAULT: 'EMAIL_TEMPLATE.SET_EMAIL_TEMPLATE_DEFAULT',
  SET_EMAIL_TEMPLATE: 'EMAIL_TEMPLATE.SET_EMAIL_TEMPLATE',
  SET_EMAIL_TEMPLATE_VALUE: 'EMAIL_TEMPLATE.SET_EMAIL_TEMPLATE_VALUE',
}

export const MERCHANT_DEVICE = {
  SET_MERCHANT_DEVICE_DEFAULT: 'MERCHANT_DEVICE.SET_MERCHANT_DEVICE_DEFAULT',
  SET_MERCHANT_DEVICE: 'MERCHANT_DEVICE.SET_MERCHANT_DEVICE',
  SET_MERCHANT_DEVICE_VALUE: 'MERCHANT_DEVICE.SET_MERCHANT_DEVICE_VALUE',
}

export const MERCHANT_USER = {
  SET_MERCHANT_USER_DEFAULT: 'MERCHANT_USER.SET_MERCHANT_USER_DEFAULT',
  SET_MERCHANT_USER: 'MERCHANT_USER.SET_MERCHANT_USER',
  SET_MERCHANT_USER_VALUE: 'MERCHANT_USER.SET_MERCHANT_USER_VALUE',
}

export const MERCHANT_USER_GROUP = {
  SET_MERCHANT_USER_GROUP_DEFAULT: 'MERCHANT_USER_GROUP.SET_MERCHANT_USER_GROUP_DEFAULT',
  SET_MERCHANT_USER_GROUP: 'MERCHANT_USER.SET_MERCHANT_USER_GROUP',
  SET_MERCHANT_USER_GROUP_VALUE: 'MERCHANT_USER.SET_MERCHANT_USER_GROUP_VALUE',
}

export const MERCHANT_SALES_USER = {
  SET_MERCHANT_SALES_USER_DEFAULT: 'MERCHANT_SALES_USER.SET_MERCHANT_SALES_USER_DEFAULT',
  SET_MERCHANT_SALES_USER: 'MERCHANT_SALES_USER.SET_MERCHANT_SALES_USER',
  SET_MERCHANT_SALES_USER_VALUE: 'MERCHANT_SALES_USER.SET_MERCHANT_SALES_USER_VALUE',
}

export const AREA = {
  SET_AREA_DEFAULT: 'AREA.SET_AREA_DEFAULT',
  SET_AREA: 'AREA.SET_AREA',
  SET_AREA_VALUE: 'AREA.SET_AREA_VALUE',
}

export const DISTRICT = {
  SET_DISTRICT_DEFAULT: 'DISTRICT.SET_DISTRICT_DEFAULT',
  SET_DISTRICT: 'DISTRICT.SET_DISTRICT',
  SET_DISTRICT_VALUE: 'DISTRICT.SET_DISTRICT_VALUE',
}

export const LANDMARK = {
  SET_LANDMARK_DEFAULT: 'LANDMARK.SET_LANDMARK_DEFAULT',
  SET_LANDMARK: 'LANDMARK.SET_LANDMARK',
  SET_LANDMARK_VALUE: 'LANDMARK.SET_LANDMARK_VALUE',
}

export const FOLDER = {
  SET_FOLDER_DEFAULT: 'FOLDER.SET_FOLDER_DEFAULT',
  SET_FOLDER: 'FOLDER.SET_FOLDER',
  SET_FOLDER_VALUE: 'FOLDER.SET_FOLDER_VALUE',
}

export const FOLDER_MAP = {
  SET_FOLDER_MAP_DEFAULT: 'FOLDER_MAP.SET_FOLDER_MAP_DEFAULT',
  SET_FOLDER_MAP: 'FOLDER_MAP.SET_FOLDER_MAP',
  SET_FOLDER_MAP_VALUE: 'FOLDER_MAP.SET_FOLDER_MAP_VALUE',
}

export const CONFIG_MAP = {
  SET_CONFIG_MAP_DEFAULT: 'CONFIG_MAP.SET_CONFIG_MAP_DEFAULT',
  SET_CONFIG_MAP: 'CONFIG_MAP.SET_CONFIG_MAP',
  SET_CONFIG_MAP_VALUE: 'CONFIG_MAP.SET_CONFIG_MAP_VALUE',
}

export const BANNER = {
  SET_BANNER_DEFAULT: 'BANNER.SET_BANNER_DEFAULT',
  SET_BANNER_LIST: 'BANNER.SET_BANNER_LIST',
  SET_BANNER: 'BANNER.SET_BANNER',
  SET_BANNER_VALUE: 'BANNER.SET_BANNER_VALUE',
}

export const PUSH = {
  SET_PUSH_DEFAULT: 'PUSH.SET_PUSH_DEFAULT',
  SET_PUSH: 'PUSH.SET_PUSH',
  SET_PUSH_VALUE: 'PUSH.SET_PUSH_VALUE',
}

export const STATUS = {
  SET_STATUS_DEFAULT: 'STATUS.SET_STATUS_DEFAULT',
  SET_STATUS: 'STATUS.SET_STATUS',
  SET_STATUS_VALUE: 'STATUS.SET_STATUS_VALUE',
}

export const QUEUE_CONFIG = {
  SET_QUEUE_CONFIG_DEFAULT: 'QUEUE_CONFIG.SET_QUEUE_CONFIG_DEFAULT',
  SET_QUEUE_CONFIG: 'QUEUE_CONFIG.SET_QUEUE_CONFIG',
  SET_QUEUE_CONFIG_VALUE: 'QUEUE_CONFIG.SET_QUEUE_CONFIG_VALUE',
}

export const PRINT_CONFIG = {
  SET_PRINT_CONFIG_DEFAULT: 'PRINT_CONFIG.SET_PRINT_CONFIG_DEFAULT',
  SET_PRINT_CONFIG: 'PRINT_CONFIG.SET_PRINT_CONFIG',
  SET_PRINT_CONFIG_VALUE: 'PRINT_CONFIG.SET_PRINT_CONFIG_VALUE',
}

export const TICKET_TYPE = {
  SET_TICKET_TYPE_DEFAULT: 'TICKET_TYPE.SET_TICKET_TYPE_DEFAULT',
  SET_TICKET_TYPE: 'TICKET_TYPE.SET_TICKET_TYPE',
  SET_TICKET_TYPE_VALUE: 'TICKET_TYPE.SET_TICKET_TYPE_VALUE',
}

export const QUEUE_TEMPLATE = {
  SET_QUEUE_TEMPLATE_DEFAULT: 'QUEUE_TEMPLATE.SET_QUEUE_TEMPLATE_DEFAULT',
  SET_QUEUE_TEMPLATE: 'QUEUE_TEMPLATE.SET_QUEUE_TEMPLATE',
  SET_QUEUE_TEMPLATE_VALUE: 'QUEUE_TEMPLATE.SET_QUEUE_TEMPLATE_VALUE',
  SET_QUEUE_TEMPLATE_TIME_SECTION_VALUE: 'QUEUE_TEMPLATE.SET_QUEUE_TEMPLATE_TIME_SECTION_VALUE',
  SET_QUEUE_TEMPLATE_TABLE_TYPE_VALUE: 'QUEUE_TEMPLATE.SET_QUEUE_TEMPLATE_TABLE_TYPE_VALUE',
  SET_QUEUE_TEMPLATE_GROUPED_SECTION: 'SET_QUEUE_TEMPLATE_GROUPED_SECTION',
  SET_QUEUE_TEMPLATE_GROUPED_SECTION_VALUE: 'SET_QUEUE_TEMPLATE_GROUPED_SECTION_VALUE',
  SET_QUEUE_TEMPLATE_TABLE_TYPE_COLOR_LIST: 'SET_QUEUE_TEMPLATE_TABLE_TYPE_COLOR_LIST',
  SET_QUEUE_TEMPLATE_DEFAULT_TABLE_TYPE_COLOR_LIST: 'SET_QUEUE_TEMPLATE_DEFAULT_TABLE_TYPE_COLOR_LIST',
}

export const QUEUE_TEMPLATE_MAP = {
  SET_QUEUE_TEMPLATE_MAP_DEFAULT: 'QUEUE_TEMPLATE_MAP.SET_QUEUE_TEMPLATE_MAP_DEFAULT',
  SET_QUEUE_TEMPLATE_MAP: 'QUEUE_TEMPLATE_MAP.SET_QUEUE_TEMPLATE_MAP',
  SET_QUEUE_TEMPLATE_MAP_VALUE: 'QUEUE_TEMPLATE_MAP.SET_QUEUE_TEMPLATE_MAP_VALUE',
  SET_QUEUE_TEMPLATE_MAP_LIST: 'QUEUE_TEMPLATE_MAP.SET_QUEUE_TEMPLATE_MAP_LIST',
}

export const PAYMENT = {
  SET_PAYMENT_DEFAULT: 'PAYMENT.SET_PAYMENT_DEFAULT',
  SET_PAYMENT: 'PAYMENT.SET_PAYMENT',
  SET_PAYMENT_VALUE: 'PAYMENT.SET_PAYMENT_VALUE',
  SET_UPLOAD_PAYMENT_LIST: 'SET_UPLOAD_PAYMENT_LIST',
}

export const RESTAURANT_APPROVE = {
  SET_RESTAURANT_APPROVE_DEFAULT: 'RESTAURANT_APPROVE.SET_RESTAURANT_APPROVE_DEFAULT',
  SET_RESTAURANT_APPROVE: 'RESTAURANT_APPROVE.SET_RESTAURANT_APPROVE',
  SET_RESTAURANT_APPROVE_VALUE: 'RESTAURANT_APPROVE.SET_RESTAURANT_APPROVE_VALUE',
}

export const BRAND_APPROVE = {
  SET_BRAND_APPROVE_DEFAULT: 'BRAND_APPROVE.SET_BRAND_APPROVE_DEFAULT',
  SET_BRAND_APPROVE: 'BRAND_APPROVE.SET_BRAND_APPROVE',
  SET_BRAND_APPROVE_VALUE: 'BRAND_APPROVE.SET_BRAND_APPROVE_VALUE',
}

export const MERCHANT_INFO = {
  SET_MERCHANT_INFO_DEFAULT: 'MERCHANT_INFO.SET_MERCHANT_INFO_DEFAULT',
  SET_MERCHANT_INFO: 'MERCHANT_INFO.SET_MERCHANT_INFO',
  SET_MERCHANT_INFO_VALUE: 'MERCHANT_INFO.SET_MERCHANT_INFO_VALUE',
}

export const MERCHANT_CONFIG = {
  SET_MERCHANT_CONFIG_DEFAULT: 'MERCHANT_CONFIG.SET_MERCHANT_CONFIG_DEFAULT',
  SET_MERCHANT_CONFIG: 'MERCHANT_CONFIG.SET_MERCHANT_CONFIG',
  SET_MERCHANT_CONFIG_VALUE: 'MERCHANT_CONFIG.SET_MERCHANT_CONFIG_VALUE',
}

export const QUEUE_PROFILE = {
  SET_QUEUE_PROFILE_DEFAULT: 'QUEUE_PROFILE.SET_QUEUE_PROFILE_DEFAULT',
  SET_QUEUE_PROFILE: 'QUEUE_PROFILE.SET_QUEUE_PROFILE',
  SET_QUEUE_PROFILE_VALUE: 'QUEUE_PROFILE.SET_QUEUE_PROFILE_VALUE',
}

export const QUEUE_TRANSFER_PROFILE = {
  SET_QUEUE_TRANSFER_PROFILE_DEFAULT: 'QUEUE_TRANSFER_PROFILE.SET_QUEUE_PROFILE_DEFAULT',
  SET_QUEUE_TRANSFER_PROFILE: 'QUEUE_TRANSFER_PROFILE.SET_QUEUE_PROFILE',
  SET_QUEUE_TRANSFER_PROFILE_VALUE: 'QUEUE_TRANSFER_PROFILE.SET_QUEUE_PROFILE_VALUE',
}

export const COUNTER_PROFILE = {
  SET_COUNTER_PROFILE_DEFAULT: 'COUNTER_PROFILE.SET_COUNTER_PROFILE_DEFAULT',
  SET_COUNTER_PROFILE: 'COUNTER_PROFILE.SET_COUNTER_PROFILE',
  SET_COUNTER_PROFILE_VALUE: 'COUNTER_PROFILE.SET_COUNTER_PROFILE_VALUE',
}

export const COUNTER = {
  SET_COUNTER_DEFAULT: 'COUNTER.SET_COUNTER_DEFAULT',
  SET_COUNTER: 'COUNTER.SET_COUNTER',
  SET_COUNTER_VALUE: 'COUNTER.SET_COUNTER_VALUE',
}

export const STAFF = {
  SET_STAFF_DEFAULT: 'STAFF.SET_STAFF_DEFAULT',
  SET_STAFF: 'STAFF.SET_STAFF',
  SET_STAFF_VALUE: 'STAFF.SET_STAFF_VALUE',
}

export const RESERVATION_CONFIG = {
  SET_RESERVATION_CONFIG_DEFAULT: 'RESERVATION_CONFIG.SET_RESERVATION_CONFIG_DEFAULT',
  SET_RESERVATION_CONFIG: 'RESERVATION_CONFIG.SET_RESERVATION_CONFIG',
  SET_RESERVATION_CONFIG_VALUE: 'RESERVATION_CONFIG.SET_RESERVATION_CONFIG_VALUE',
}

export const RESERVATION_TEMPLATE = {
  SET_RESERVATION_TEMPLATE_DEFAULT: 'RESERVATION_TEMPLATE.SET_RESERVATION_TEMPLATE_DEFAULT',
  SET_RESERVATION_TEMPLATE: 'RESERVATION_TEMPLATE.SET_RESERVATION_TEMPLATE',
  SET_RESERVATION_TEMPLATE_VALUE: 'RESERVATION_TEMPLATE.SET_RESERVATION_TEMPLATE_VALUE',
  SET_RESERVATION_TEMPLATE_TIME_SECTION_VALUE: 'RESERVATION_TEMPLATE.SET_RESERVATION_TEMPLATE_TIME_SECTION_VALUE',
  SET_RESERVATION_TEMPLATE_TABLE_TYPE_VALUE: 'RESERVATION_TEMPLATE.SET_RESERVATION_TEMPLATE_TABLE_TYPE_VALUE',
}

export const RESERVATION_TEMPLATE_MAP = {
  SET_RESERVATION_TEMPLATE_MAP_DEFAULT: 'RESERVATION_TEMPLATE_MAP.SET_RESERVATION_TEMPLATE_MAP_DEFAULT',
  SET_RESERVATION_TEMPLATE_MAP: 'RESERVATION_TEMPLATE_MAP.SET_RESERVATION_TEMPLATE_MAP',
  SET_RESERVATION_TEMPLATE_MAP_VALUE: 'RESERVATION_TEMPLATE_MAP.SET_RESERVATION_TEMPLATE_MAP_VALUE',
  SET_RESERVATION_TEMPLATE_MAP_LIST: 'RESERVATION_TEMPLATE_MAP.SET_RESERVATION_TEMPLATE_MAP_LIST',
}

export const CHARGE_DATE = {
  SET_CHARGE_DATE_DEFAULT: 'CHARGE_DATE.SET_CHARGE_DATE_DEFAULT',
  SET_CHARGE_DATE: 'CHARGE_DATE.SET_CHARGE_DATE',
  SET_CHARGE_DATE_VALUE: 'CHARGE_DATE.SET_CHARGE_DATE_VALUE',
  SET_CHARGE_DATE_LIST: 'CHARGE_DATE.SET_CHARGE_DATE_LIST',
}

export const CONTACT_US = {
  SET_CONTACT_US_DEFAULT: 'CONTACT_US.SET_CONTACT_US_DEFAULT',
  SET_CONTACT_US: 'CONTACT_US.SET_CONTACT_US',
  SET_CONTACT_US_VALUE: 'CONTACT_US.SET_CONTACT_US_VALUE',
}

export const SELECT_TAG = {
  SET_SELECT_TAG_DEFAULT: 'CONTACT_US.SET_SELECT_TAG_DEFAULT',
  SET_SELECT_TAG: 'CONTACT_US.SET_SELECT_TAG',
  SET_SELECT_TAG_VALUE: 'CONTACT_US.SET_SELECT_TAG_VALUE',
}

export const SELECT_TAG_CONFIG = {
  SET_SELECT_TAG_CONFIG_DEFAULT: 'SELECT_TAG_CONFIG.SET_SELECT_TAG_CONFIG_DEFAULT',
  SET_SELECT_TAG_CONFIG: 'SELECT_TAG_CONFIG.SET_SELECT_TAG_CONFIG',
  SET_SELECT_TAG_CONFIG_VALUE: 'SELECT_TAG_CONFIG.SET_SELECT_TAG_CONFIG_VALUE',
}

export const SELECT_TAG_CONFIG_OPTIONS = {
  SET_SELECT_TAG_CONFIG_OPTIONS_DEFAULT: 'SELECT_TAG_CONFIG_OPTIONS.SET_SELECT_TAG_CONFIG_OPTIONS_DEFAULT',
  SET_SELECT_TAG_CONFIG_OPTIONS: 'SELECT_TAG_CONFIG_OPTIONS.SET_SELECT_TAG_CONFIG_OPTIONS',
  SET_SELECT_TAG_CONFIG_OPTIONS_VALUE: 'SELECT_TAG_CONFIG_OPTIONS.SET_SELECT_TAG_CONFIG_OPTIONS_VALUE',
}

export const MERCHANT_SHOP = {
  SET_MERCHANT_SHOP_DEFAULT: 'MERCHANT_SHOP.SET_MERCHANT_SHOP_DEFAULT',
  SET_MERCHANT_SHOP: 'MERCHANT_SHOP.SET_MERCHANT_SHOP',
  SET_MERCHANT_SHOP_VALUE: 'MERCHANT_SHOP.SET_MERCHANT_SHOP_VALUE',
  SET_MERCHANT_SHOP_STEP_ERROR_VALUE: 'SET_MERCHANT_SHOP_STEP_ERROR_VALUE',
}

export const LANDING_SECTION = {
  SET_LANDING_SECTION_DEFAULT: 'LANDING_SECTION.SET_LANDING_SECTION_DEFAULT',
  SET_LANDING_SECTION_VALUE: 'LANDING_SECTION.SET_LANDING_SECTION_VALUE',
  SET_LANDING_SECTION: 'LANDING_SECTION.SET_LANDING_SECTION',
  SET_LANDING_ITEM_DEFAULT: 'SET_LANDING_ITEM_DEFAULT',
  SET_LANDING_ITEM: 'SET_LANDING_ITEM',
  SET_LANDING_ITEM_VALUE: 'SET_LANDING_ITEM_VALUE',
}

export const LANDMARK_RESTAURANT_MAP = {
  setRestaurantMapDefault: 'setRestaurantMapDefault',
  setRestaurantMap: 'setRestaurantMap',
  setRestaurantMapValue: 'setRestaurantMapValue',
}
