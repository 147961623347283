import { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useDispatch, useSelector, useStore } from 'react-redux'
import _ from 'lodash'

import SaveIcon from '@mui/icons-material/Save'
import ClearIcon from '@mui/icons-material/Clear'
import LowPriorityIcon from '@mui/icons-material/LowPriority'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { makeStyles } from '@mui/styles'

import { constants as cons } from '../../Constants/constants'
import { getOne, put } from '../../helper/baseFetch'
import { getScDisabled } from '../../helper/util'
import { setDistrict, setDistrictValue } from '../../actions/districtAction'
import { EditCreateDisplay } from '../../Component/EditCreateDisplay/EditCreateDisplay'
import { TempTextInput } from '../../Component/Input/TempTextInput'
import { TempAutoSelectInput } from '../../Component/Input/TempAutoSelectInput'
import { TempCustomCheckBox } from '../../Component/Custom/TempCustomCheckBox'
import { DistrictRestaurantMapList } from './DistrictRestaurantMapList'
import { LandMarkList } from '../LandMark/LandMarkList'
import { GlobalDialog } from '../../Component/GlobalDialog'
import validation from '../../validations/districtValidation'
import { districtValidationConstants as validationCons } from '../../Constants/validationConstants'
import { getI18nObjValue } from '../../helper/i18n'
import LocationFields, { LOCATION_FIELDS_KEY } from '../../Component/common/location/LocationFields'

const helperTextStyles = makeStyles(() => ({
    root: {
        color: 'red !important',
    },
}))

export const DistrictEdit = () => {
    const dispatch = useDispatch()
    const store = useStore()
    const params = useParams()
    const history = useHistory()

    const guluLang = useSelector((state) => state.system.ui.lang)

    const [ready, setReady] = useState(false)
    const [clear, setClear] = useState(true)
    const [dialog, setDialog] = useState({
        open: false,
        dialogActions: [],
    })
    const resource = 'RestaurantDistrict'
    const savedDistrict = useSelector((state) => state.district.ui.district)
    const [districtInputList, setDistrictInputList] = useState(_.cloneDeep(savedDistrict))
    const helperTextStylesClasses = helperTextStyles()
    // eslint-disable-next-line no-unused-vars
    const [errorVersion, setErrorVersion] = useState(validation.getVersion())
    const [error, setError] = useState({})

    const tabs = [
        {
            label: { resource: resource, key: 'info' },
            tabIndex: cons.DISTRICT_TAB.INFO,
        },
        {
            label: { resource: resource, key: 'restaurantMapList' },
            tabIndex: cons.DISTRICT_TAB.RESTAURANT_MAP_LIST,
        },
        {
            label: { resource: resource, key: 'landMarkMapList' },
            tabIndex: cons.DISTRICT_TAB.LANDMARK_MAP_LIST,
        },
    ]

    const fields = [
        {
            className: 'edit-create-input',
            size: cons.SIZE.SMALL,
            label: { resource: resource, key: 'enName' },
            value: savedDistrict.enName,
            name: 'enName',
            custom: true,
            customName: { key: 'nameCustomize' },
            customValue: savedDistrict.scNameDisable,
            isEndOfLine: false,
            required: true,
            helperTextIcon: true,
            helperText: validation.getErrorMessages(validationCons.KEY_EN_NAME),
            FormHelperTextProps: { className: helperTextStylesClasses.root },
        },
        {
            className: 'edit-create-input',
            size: cons.SIZE.SMALL,
            label: { resource: resource, key: 'tcName' },
            value: savedDistrict.tcName,
            name: 'tcName',
            translateName: savedDistrict.scNameDisable ? ['scName'] : null,
            inputList: districtInputList,
            setInputList: setDistrictInputList,
            isEndOfLine: false,
            required: true,
            helperTextIcon: true,
            helperText: validation.getErrorMessages(validationCons.KEY_TC_NAME),
            FormHelperTextProps: { className: helperTextStylesClasses.root },
        },
        {
            className: 'edit-create-input',
            size: cons.SIZE.SMALL,
            label: { resource: resource, key: 'scName' },
            value: savedDistrict.scName,
            name: 'scName',
            inputList: districtInputList,
            setInputList: setDistrictInputList,
            isEndOfLine: true,
            disabled: savedDistrict.scNameDisable,
            required: true,
            helperTextIcon: true,
            helperText: validation.getErrorMessages(validationCons.KEY_SC_NAME),
            FormHelperTextProps: { className: helperTextStylesClasses.root },
        },
        {
            className: 'edit-create-input',
            size: cons.SIZE.SMALL,
            label: { resource: resource, key: 'districtLevel' },
            value: savedDistrict.districtLevel,
            type: cons.TYPE.NUMBER,
            min: 0,
            name: 'districtLevel',
            isEndOfLine: false,
            required: true,
            helperTextIcon: true,
            helperText: validation.getErrorMessages(validationCons.KEY_DISTRICT_LEVEL),
            FormHelperTextProps: { className: helperTextStylesClasses.root },
        },
        {
            className: 'edit-create-input',
            size: cons.SIZE.SMALL,
            label: { resource: resource, key: 'menuChiOrder' },
            value: savedDistrict.menuChiOrder,
            type: cons.TYPE.NUMBER,
            min: 0,
            name: 'menuChiOrder',
            isEndOfLine: true,
            required: true,
            helperTextIcon: true,
            helperText: validation.getErrorMessages(validationCons.KEY_MENU_CHI_ORDER),
            FormHelperTextProps: { className: helperTextStylesClasses.root },
        },
        {
            className: 'edit-create-input',
            size: cons.SIZE.SMALL,
            label: { resource: resource, key: 'latitude' },
            value: savedDistrict.latitude,
            type: cons.TYPE.NUMBER,
            name: 'latitude',
            isEndOfLine: false,
            required: true,
            helperTextIcon: true,
            helperText: validation.getErrorMessages(validationCons.KEY_LATITUDE),
            FormHelperTextProps: { className: helperTextStylesClasses.root },
        },
        {
            className: 'edit-create-input',
            size: cons.SIZE.SMALL,
            label: { resource: resource, key: 'longitude' },
            value: savedDistrict.longitude,
            type: cons.TYPE.NUMBER,
            name: 'longitude',
            isEndOfLine: false,
            required: true,
            helperTextIcon: true,
            helperText: validation.getErrorMessages(validationCons.KEY_LONGITUDE),
            FormHelperTextProps: { className: helperTextStylesClasses.root },
        },
        {
            className: 'edit-create-input',
            size: cons.SIZE.SMALL,
            label: { resource: resource, key: 'status' },
            value: savedDistrict.status,
            type: cons.TYPE.SELECT,
            selectValue: cons.CHOICES.RESTAURANT_DISTRICT.STATUS,
            name: 'status',
            isEndOfLine: true,
            disableClearable: true,
            required: true,
            helperTextIcon: true,
            helperText: validation.getErrorMessages(validationCons.KEY_STATUS),
            FormHelperTextProps: { className: helperTextStylesClasses.root },
        },
    ]

    const content = [
        {
            tab: (
                <>
                    <LocationFields
                        fields={[LOCATION_FIELDS_KEY.city, LOCATION_FIELDS_KEY.area]}
                        setCity={setCity}
                        setArea={setArea}
                        savedData={savedDistrict}
                        error={error}
                    />
                    {fields.map((setting, idx) => {
                        let content
                        switch (setting.type) {
                            case cons.TYPE.SELECT:
                                content = (
                                    <TempAutoSelectInput
                                        inputSetting={setting}
                                        handleChange={onChangeInput}
                                    />
                                )
                                break
                            default:
                                content = (
                                    <TempTextInput
                                        inputSetting={setting}
                                        handleChange={onChangeInput}
                                    />
                                )
                        }
                        return (
                            <div className='edit-display-wrapper' key={idx}>
                                {setting.custom && (
                                    <TempCustomCheckBox
                                        className='customize-btn'
                                        value={setting.customValue}
                                        customName={setting.customName}
                                        onSelectCheckbox={onSelectCheckbox}
                                    />
                                )}
                                {content}
                                {setting.isEndOfLine && <div></div>}
                            </div>
                        )
                    })}
                </>
            ),
            btn: true,
        },
        {
            tab: <DistrictRestaurantMapList editBtn={false} createBtn={false} />,
            btn: false,
        },
        {
            tab: (
                <LandMarkList
                    newPath={cons.PATH.SHOP.RESTAURANT_DISTRICT_LANDMARK_MAP_LIST}
                    newFilter={{ id: params.id }}
                    newListTitles={[
                        {
                            key: cons.LIST_DISPLAY_TYPE.STRING,
                            value: 'landmarkId',
                            sort: true,
                        },
                        {
                            key: cons.LIST_DISPLAY_TYPE.STRING,
                            value: 'name',
                            sort: false,
                        },
                        {
                            key: cons.LIST_DISPLAY_TYPE.STRING,
                            value: 'status',
                            sort: true,
                        },
                    ]}
                    editBtn={false}
                    createBtn={false}
                />
            ),
            btn: false,
        },
    ]

    const bottomBtns = [
        {
            variant: cons.STYLE.VARIANT.CONTAINED,
            color: cons.STYLE.PRIMARY,
            label: { key: 'save' },
            onClick: onClickSave,
            className: 'margin-left-16',
            icon: <SaveIcon />,
            disabled: Object.keys(error).length > 0,
        },
        {
            variant: cons.STYLE.VARIANT.CONTAINED,
            color: cons.STYLE.PRIMARY,
            label: { key: 'clear' },
            disabled: false,
            onClick: onClickClear,
            className: 'margin-left-16',
            icon: <ClearIcon />,
        },
    ]

    function onSelectCheckbox(value) {
        dispatch(setDistrictValue('scNameDisable', savedDistrict, '', value))
    }

    const onClickBackToEdit = () => {
        setDialog({
            open: false,
            dialogActions: [],
        })
        onClickClear()
    }

    const onClickBackToList = () => {
        setDialog({
            open: false,
            dialogActions: [],
        })
        history.push('/' + cons.PATH.SHOP.RESTAURANT_DISTRICT_WRAPPER)
    }

    function onChangeInput(name, e) {
        if (savedDistrict.scNameDisable === true && name === 'tcName') {
            dispatch(setDistrictValue('CHANGE_TC_NAME_AND_SC_NAME', savedDistrict, '', e))
        } else if (name === 'districtLevel' || name === 'menuChiOrder') {
            dispatch(
                setDistrictValue(
                    name,
                    savedDistrict,
                    '',
                    Number.isInteger(Number(e)) ? Number(e) : 0
                )
            )
        } else if (name === 'latitude' || name === 'longitude') {
            if (e) {
                dispatch(setDistrictValue(name, savedDistrict, '', Number(e)))
            } else {
                dispatch(setDistrictValue(name, savedDistrict, '', e))
            }
        } else {
            dispatch(setDistrictValue(name, savedDistrict, '', e))
        }
    }

    function onClickSave() {
        validation.validateEditDistrict(savedDistrict)
        if (validation.isValid()) {
            put(
                cons.PATH.SHOP.RESTAURANT_DISTRICT + '/' + params.id,
                savedDistrict,
                () => {
                    setDialog({
                        open: true,
                        onClose: onClickBackToEdit,
                        dialogContentText: { key: 'editSuccess' },
                        dialogActions: [
                            {
                                label: { key: 'backEdit' },
                                onClick: onClickBackToEdit,
                                icon: <ArrowBackIcon />,
                            },
                            {
                                label: { key: 'backList' },
                                onClick: onClickBackToList,
                                icon: <LowPriorityIcon />,
                            },
                        ],
                    })
                    setClear(false)
                },
                undefined,
                store
            )
            validation.resetErrorMessages()
        } else {
            setErrorVersion(validation.getVersion())
        }
    }

    function onClickClear() {
        setClear(true)
    }

    function setCity(city) {
        console.log('city', city)

        const isSelectedPreCity = savedDistrict.cityCode === city.cityCode

        dispatch(
            setDistrict({
                ...savedDistrict,
                cityName: getI18nObjValue(guluLang, city?.name),
                cityCode: city.cityCode,
                areaId: isSelectedPreCity ? savedDistrict.areaId : null,
                areaName: isSelectedPreCity ? savedDistrict.areaName : null,
            })
        )
    }

    function setArea(area) {
        console.log('area', area)
        let newAreaName = area.enName

        if (guluLang === cons.LANG.TC) {
            newAreaName = area.tcName
        }

        if (guluLang === cons.LANG.SC) {
            newAreaName = area.scName
        }

        dispatch(
            setDistrict({
                ...savedDistrict,
                areaId: area.areaId,
                areaName: newAreaName,
            })
        )
    }

    function validateLocation() {
        let newError = {}

        if (!savedDistrict.cityCode) {
            newError.cityCode = 'Missing City Code'
        }

        if (!savedDistrict.areaId) {
            newError.areaId = 'Missing Area Id'
        }

        setError(newError)
    }

    useEffect(() => {
        validateLocation()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [savedDistrict])

    useEffect(() => {
        if (clear === true) {
            getOne(
                cons.PATH.SHOP.RESTAURANT_DISTRICT + '/' + params.id,
                undefined,
                (payload) => {
                    payload.scNameDisable = getScDisabled(payload.tcName, payload.scName)

                    if (!payload?.areaName) {
                        let areaName = payload.areaEnName

                        if (guluLang === cons.LANG.TC) {
                            areaName = payload.areaTcName
                        }

                        if (guluLang === cons.LANG.SC) {
                            areaName = payload.areaScName
                        }

                        payload.areaName = areaName
                    }

                    dispatch(setDistrict(_.cloneDeep(payload)))
                    setDistrictInputList(_.cloneDeep(payload))

                    setClear(false)
                    setReady(true)
                },
                undefined,
                store
            )
        }
        validation.resetErrorMessages()
    }, [clear === true]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        ready && (
            <>
                <EditCreateDisplay
                    code={savedDistrict && savedDistrict.districtId ? savedDistrict.districtId : ''}
                    resource={resource}
                    tabs={tabs}
                    content={content}
                    onChangeInput={onChangeInput}
                    bottomBtns={bottomBtns}
                    display='edit'
                />
                {dialog && dialog.open && <GlobalDialog dialogInfo={dialog} />}
            </>
        )
    )
}
