import { Icon } from '@iconify/react'
import { Box } from '@mui/material'

export default function Iconify({ icon = 'solar:pulse-outline', sx, ...other }) {
    return (
        <Box
            component={Icon}
            icon={icon}
            sx={{
                flexShrink: 0,
                width: '24px',
                height: '24px',
                color: (theme) => theme.palette.primary.main,
                ...sx,
            }}
            {...other}
        />
    )
}
