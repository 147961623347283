import { useState, useEffect } from 'react'
import { useStore } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { Card, Stepper, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { TempBtn } from '../../../Component/Input/TempBtn'
import { constants as cons } from '../../../Constants/constants'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'

import LowPriorityIcon from '@mui/icons-material/LowPriority'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import DoneIcon from '@mui/icons-material/Done'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { BaseWrapper } from '../../../Component/BaseWrapper'
// import SplitPane from 'react-split-pane';
import 'allotment/dist/style.css'
import { GlobalDialog } from '../../../Component/GlobalDialog'
import { AllSlideshowCreateContent } from './AllSlideshowCreateContent'
import SlideshowBulkCreateValidation from '../../../validations/SlideshowBulkCreateValidation'
import { slideshowBulkCreateValidationConstants } from '../../../Constants/validationConstants'
import { post } from '../../../helper/baseFetch'
import { AllSlideshowCreateRestaurantMapCreate } from './AllSlideshowCreateRestaurantMapCreate'

const getStepContent = (
  step,
  addRestaurantMap,
  setAddRestaurantMap,
  selectedRestaurantMap,
  setSelectedRestaurantMap,
  slideshowList,
  setSlideshowList,
  customIdNumber,
  setCustomIdNumber
) => {
  switch (step) {
    case cons.SLIDESHOW_BULK_CREATE_STEP.SLIDESHOW:
      return (
        <div className="step-content-wrapper">
          <AllSlideshowCreateContent
            slideshowList={slideshowList}
            setSlideshowList={setSlideshowList}
            customIdNumber={customIdNumber}
            setCustomIdNumber={setCustomIdNumber}
          />
        </div>
      )
    case cons.SLIDESHOW_BULK_CREATE_STEP.RESTAURANT_MAP_LIST:
      return (
        <div className="step-content-wrapper">
          <div className="flex-1">
            <AllSlideshowCreateRestaurantMapCreate
              addRestaurantMap={addRestaurantMap}
              setAddRestaurantMap={setAddRestaurantMap}
              selectedRestaurantMap={selectedRestaurantMap}
              setSelectedRestaurantMap={setSelectedRestaurantMap}
            />
          </div>
        </div>
      )
    default:
      return 'Unknown step'
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100vh',
    overflow: 'none',
  },
  button: {
    margin: theme.spacing(1),
  },
  completed: {
    display: 'inline-block',
  },
  error: {
    display: 'flex',
    justifyContent: 'center',
  },
}))

const SLIDE_SHOW_STEPS = ['Slideshow', 'Restaurant Map List']

function getSteps() {
  return ['Slideshow', 'Restaurant Map List']
}

export const AllSlideshowCreate = () => {
  const classes = useStyles()
  const [activeStep, setActiveStep] = useState(0)
  const [completed] = useState(new Set())
  // const steps = getSteps()
  const steps = SLIDE_SHOW_STEPS
  const history = useHistory()
  const [, setErrorVersion] = useState(SlideshowBulkCreateValidation.getVersion())

  const store = useStore()
  const [clear, setClear] = useState(false)
  const [customIdNumber, setCustomIdNumber] = useState(0)
  const [addRestaurantMap, setAddRestaurantMap] = useState(new Map())
  const [selectedRestaurantMap, setSelectedRestaurantMap] = useState(new Map())
  const [slideshowList, setSlideshowList] = useState([])
  const [dialog, setDialog] = useState({
    open: false,
    dialogActions: [],
  })

  // const totalSteps = () => {
  //   return getSteps().length
  // }

  const lastStep = SLIDE_SHOW_STEPS.length - 1

  const onClickNextButton = () => {
    const newActiveStep = activeStep === lastStep ? steps.findIndex((step, i) => !completed.has(i)) : activeStep + 1

    setActiveStep(newActiveStep)
    history.push('/' + cons.PATH.SLIDESHOW.ALL_SLIDESHOW + '/create/0')
  }

  const onClickBackButton = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
    history.push('/' + cons.PATH.SLIDESHOW.ALL_SLIDESHOW + '/create/0')
  }

  const onChangeStep = (step) => () => {
    setActiveStep(step)
    history.push('/' + cons.PATH.SLIDESHOW.ALL_SLIDESHOW + '/create/0')
  }

  const onClickBackToCreate = () => {
    setDialog({
      open: false,
      dialogActions: [],
    })
    setClear(true)
  }

  const onClickBackToList = () => {
    setDialog({
      open: false,
      dialogActions: [],
    })
    history.push('/' + cons.PATH.SLIDESHOW.ALL_SLIDESHOW)
  }

  const onClickSave = () => {
    const restUrlIdList = Array.from(addRestaurantMap, ([id, value]) => id)

    SlideshowBulkCreateValidation.validateSlideshowBulkCreate({ slideshowList: slideshowList, restUrlIdList: restUrlIdList })

    if (SlideshowBulkCreateValidation.isValid()) {
      post(
        cons.PATH.SLIDESHOW.SLIDESHOW_ALL_LIST,
        { slideshowList: slideshowList, restUrlIdList: restUrlIdList },
        () => {
          setDialog({
            open: true,
            onClose: onClickBackToCreate,
            dialogContentText: { key: 'createSuccess' },
            dialogActions: [
              { label: { key: 'backCreate' }, onClick: onClickBackToCreate, icon: <ArrowBackIcon /> },
              { label: { key: 'backList' }, onClick: onClickBackToList, icon: <LowPriorityIcon /> },
            ],
          })
          setClear(false)
        },
        undefined,
        store
      )

      SlideshowBulkCreateValidation.resetErrorMessages()
    } else {
      setErrorVersion(SlideshowBulkCreateValidation.getVersion())
    }
  }

  useEffect(() => {
    if (clear === true) {
      setSlideshowList([])
      setAddRestaurantMap(new Map())
      setSelectedRestaurantMap(new Map())
      setActiveStep(0)
      SlideshowBulkCreateValidation.resetErrorMessages()
      setClear(false)
    }
  }, [clear]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <BaseWrapper>
      <div className="stepper-wrapper">
        <Stepper className="stepper" alternativeLabel nonLinear activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps = {}
            const labelProps = {}
            switch (index) {
              case cons.SLIDESHOW_BULK_CREATE_STEP.SLIDESHOW:
                if (SlideshowBulkCreateValidation.getErrorMessages(slideshowBulkCreateValidationConstants.KEY_SLIDESHOW)) {
                  labelProps.optional = (
                    <Typography key={index} className={classes.error} variant="caption" color="error">
                      (Missing/Error Info)
                    </Typography>
                  )
                  if (index !== activeStep) {
                    labelProps.error = true
                  }
                }
                break
              case cons.SLIDESHOW_BULK_CREATE_STEP.RESTAURANT_MAP_LIST:
                if (SlideshowBulkCreateValidation.getErrorMessages(slideshowBulkCreateValidationConstants.KEY_RESTURLID)) {
                  labelProps.optional = (
                    <Typography key={index} className={classes.error} variant="caption" color="error">
                      (Missing/Error Info)
                    </Typography>
                  )
                  if (index !== activeStep) {
                    labelProps.error = true
                  }
                }
                break
              default:
            }
            return (
              <Step key={label} {...stepProps} className="step-button">
                <StepLabel onClick={onChangeStep(index)} {...labelProps}>
                  {label}
                </StepLabel>
              </Step>
            )
          })}
        </Stepper>
      </div>
      <div className="step-content-outer-wrapper">
        <Card>
          {getStepContent(
            activeStep,
            addRestaurantMap,
            setAddRestaurantMap,
            selectedRestaurantMap,
            setSelectedRestaurantMap,
            slideshowList,
            setSlideshowList,
            customIdNumber,
            setCustomIdNumber
          )}
        </Card>
        <div className="buttons">
          <TempBtn
            btnSetting={{
              className: classes.button,
              variant: cons.STYLE.VARIANT.CONTAINED,
              color: cons.STYLE.PRIMARY,
              onClick: onClickBackButton,
              icon: <ArrowBackIcon />,
              label: { key: 'back' },
              disabled: activeStep === 0,
            }}
          />
          <TempBtn
            btnSetting={{
              className: classes.button,
              variant: cons.STYLE.VARIANT.CONTAINED,
              color: cons.STYLE.PRIMARY,
              onClick: onClickNextButton,
              icon: <ArrowForwardIcon />,
              label: { key: 'next' },
              disabled: activeStep === lastStep,
            }}
          />
          {activeStep === lastStep && (
            <TempBtn
              btnSetting={{
                className: classes.button,
                variant: cons.STYLE.VARIANT.CONTAINED,
                color: cons.STYLE.PRIMARY,
                onClick: () => onClickSave(),
                icon: <DoneIcon />,
                label: { key: 'save' },
              }}
            />
          )}
        </div>
      </div>
      {dialog && dialog.open && <GlobalDialog dialogInfo={dialog} />}
    </BaseWrapper>
  )
}
