import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector, useStore } from 'react-redux'
import moment from 'moment'
import _ from 'lodash'

import SaveIcon from '@mui/icons-material/Save'
import ClearIcon from '@mui/icons-material/Clear'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import LowPriorityIcon from '@mui/icons-material/LowPriority'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Drawer } from '@mui/material'
import { Card } from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'
import { makeStyles } from '@mui/styles'

import { constants as cons } from '../../../Constants/constants'
import { post } from '../../../helper/baseFetch'
import { setLoading } from '../../../actions/systemAction'
import { setProductMap } from '../../../actions/productMapAction'
import { EditCreateDisplay } from '../../EditCreateDisplay/EditCreateDisplay'
import { TempTextInput } from '../../Input/TempTextInput'
import { TempBtn } from '../../Input/TempBtn'
import { ProductList } from '../Product/ProductList'
import { GlobalDialog } from '../../GlobalDialog'
import ProductMapValidation from '../../../validations/productMapValidation'
import { productMapValidationConstants } from '../../../Constants/validationConstants'
import { Launch } from '@mui/icons-material'

const helperTextStyles = makeStyles((theme) => ({
  root: {
    color: 'red !important',
  },
}))

export const ProductMapCreate = ({ restaurantTagCreate }) => {
  const dispatch = useDispatch()
  const store = useStore()
  const history = useHistory()
  const [showProductPanel, setShowProductPanel] = useState(false)
  const [clear, setClear] = useState(false)
  const [previewSelectedList, setPreviewSelectedList] = useState([])
  const [productIdList, setProductIdList] = useState([])
  const [dialog, setDialog] = useState({
    open: false,
    dialogActions: [],
  })
  const savedProductMap = useSelector((state) => state.productMap.ui.productMap)
  const savedProduct = useSelector((state) => state.product.ui.product)
  const resource = 'ProductMapList'
  const helperTextStylesClasses = helperTextStyles()
  const [, setErrorVersion] = useState(ProductMapValidation.getVersion())

  const tabs = [
    {
      label: { resource: resource, key: 'info' },
      tabIndex: cons.AREA_EDIT_TAB.INFO,
    },
  ]

  function handeleDelete(deleteIndex) {
    let tempProductMapList = _.cloneDeep(savedProductMap)
    tempProductMapList = tempProductMapList.filter((item, index) => {
      return index !== deleteIndex
    })
    setProductIdList(
      productIdList.filter((item, index) => {
        return index !== deleteIndex
      })
    )
    setPreviewSelectedList(
      previewSelectedList.filter((item, index) => {
        return index !== deleteIndex
      })
    )
    dispatch(setProductMap(tempProductMapList))
  }

  function onClickAddProductMapButton() {
    dispatch(setLoading(true))
    setProductIdList(
      savedProductMap.map((item) => {
        return item.productId
      })
    )
    setShowProductPanel(true)
    dispatch(setLoading(false))
  }

  function handleProductCloseClick() {
    dispatch(setLoading(true))
    setShowProductPanel(false)
    dispatch(setLoading(false))
  }

  const handleProductCodeSubmit = async () => {
    setShowProductPanel(false)
    const tempProductMapList = await processAddProduct(productIdList)
    dispatch(setProductMap(tempProductMapList))
  }

  const processAddProduct = (productId) => {
    return new Promise((resolve) => {
      let tempProductMapList = _.cloneDeep(savedProductMap)
      let oldProductList = tempProductMapList.map((item) => {
        return item.productId
      })
      let newTempProductList = []

      productId = productId.filter((item) => {
        return item
      })

      let tempProductId = new Map(productId.map((item) => [item]))
      tempProductMapList.forEach((item) =>
        tempProductId.has(item.productId) ? (item.status = cons.PRODUCT_EDIT_STATUS.ACTIVE) : (item.status = cons.PRODUCT_EDIT_STATUS.DEACTIVE)
      )
      newTempProductList = tempProductMapList

      newTempProductList = newTempProductList.filter((product) => {
        if (!product.id) {
          return productId.includes(product.productId)
        } else {
          return product
        }
      })

      let oldProduct
      oldProduct = productId.filter((oldProduct) => {
        return !oldProductList.includes(oldProduct)
      })

      oldProduct.forEach((item) => {
        previewSelectedList.forEach((previewSelected) => {
          if (previewSelected.id === item) {
            newTempProductList.push({
              id: '',
              productId: previewSelected.id,
              productCode: previewSelected.productCode,
              productChannel: previewSelected.channel,
              productTcName: previewSelected.tcName,
              productEnName: previewSelected.enName,
              productScName: previewSelected.scName,
              productType: previewSelected.type,
              displaySequence: previewSelected.displaySequence ? previewSelected.displaySequence : 0,
            })
          }
        })

        resolve(newTempProductList)
      })
    })
  }

  const onClickBackToCreate = () => {
    setDialog({
      open: false,
      dialogActions: [],
    })
    onClickClear()
  }
  const onClickBackToList = () => {
    setDialog({
      open: false,
      dialogActions: [],
    })
    history.push('/' + cons.PATH.PRODUCT.PRODUCT_CATEGORY + '/' + savedProduct.draft.category.id + '/' + cons.PRODUCT_CAT_TAB.PRODUCT_MAP_LIST)
  }

  function onChangeInput(name, e, index) {
    let tempSavedProductMap = _.cloneDeep(savedProductMap)
    if (name === 'startTimestamp' || name === 'endTimestamp') {
      if (e === '') {
        e = null
      }
      tempSavedProductMap[index][`${name}`] = e ? Number(moment(e).format('x')) : e
    } else if (name === 'displaySequence') {
      tempSavedProductMap[index][`${name}`] = Number.isInteger(Number(e)) ? Number(e) : 0
    } else {
      tempSavedProductMap[index][`${name}`] = e
    }

    dispatch(setProductMap(tempSavedProductMap))
  }

  function onClickSave() {
    ProductMapValidation.validateCreateProductMap(savedProductMap)
    if (ProductMapValidation.isValid()) {
      post(
        cons.PATH.PRODUCT.CATEGORY_PRODUCT_MAP_LIST,
        { categoryId: savedProduct.draft.category.id, categoryProductMapList: savedProductMap },
        (payload) => {
          setDialog({
            open: true,
            onClose: onClickBackToCreate,
            dialogContentText: { key: 'createSuccess' },
            dialogActions: [
              { label: { key: 'backCreate' }, onClick: onClickBackToCreate, icon: <ArrowBackIcon /> },
              { label: { key: 'backList' }, onClick: onClickBackToList, icon: <LowPriorityIcon /> },
            ],
          })
          setClear(false)
        },
        undefined,
        store
      )
      ProductMapValidation.resetErrorMessages()
    } else {
      setErrorVersion(ProductMapValidation.getVersion())
    }
  }

  function onClickClear() {
    setClear(true)
  }

  // console.log('savedProductMap', savedProductMap)

  let content = [
    {
      tab: (
        <>
          {ProductMapValidation.getErrorMessages(productMapValidationConstants.KEY_PRODUCT_MAP_ERROR) &&
            ProductMapValidation.getErrorMessages(productMapValidationConstants.KEY_PRODUCT_MAP_ERROR).length > 0 && (
              <div className="error-message">
                <CancelIcon className="error-message-icon" fontSize={cons.SIZE.SMALL} />
                {ProductMapValidation.getErrorMessages(productMapValidationConstants.KEY_PRODUCT_MAP_ERROR)}
              </div>
            )}
          <TempBtn
            btnSetting={{
              onClick: onClickAddProductMapButton,
              variant: cons.STYLE.VARIANT.CONTAINED,
              color: cons.STYLE.PRIMARY,
              icon: <AddIcon />,
              label: { resource: resource, key: 'addProductMap' },
            }}
          ></TempBtn>
          {savedProductMap &&
            savedProductMap.length > 0 &&
            savedProductMap.map((savedProduct, idx) => {
              const fields = [
                {
                  className: 'edit-create-input',
                  variant: cons.STYLE.VARIANT.STANDARD,
                  label: { resource: resource, key: 'productCode' },
                  value: savedProduct.productCode,
                  name: 'productCode',
                  isEndOfLine: true,
                  disabled: true,
                  endAdornmentIconButtonList: [
                    <Launch
                      fontSize={cons.SIZE.SMALL}
                      onClick={() =>
                        window.open(`${window.location.origin}/rack_product/product/${savedProduct.productId}/0`, '_blank')
                      }
                    />,
                  ],
                },
                {
                  className: 'edit-create-input',
                  variant: cons.STYLE.VARIANT.STANDARD,
                  size: cons.SIZE.SMALL,
                  label: { resource: resource, key: 'productEnName' },
                  value: savedProduct.productEnName,
                  name: 'productEnName',
                  isEndOfLine: false,
                  disabled: true,
                },
                {
                  className: 'edit-create-input',
                  variant: cons.STYLE.VARIANT.STANDARD,
                  size: cons.SIZE.SMALL,
                  label: { resource: resource, key: 'productTcName' },
                  value: savedProduct.productTcName,
                  name: 'productTcName',
                  isEndOfLine: false,
                  disabled: true,
                },
                {
                  className: 'edit-create-input',
                  variant: cons.STYLE.VARIANT.STANDARD,
                  size: cons.SIZE.SMALL,
                  label: { resource: resource, key: 'productScName' },
                  value: savedProduct.productScName,
                  name: 'productScName',
                  isEndOfLine: true,
                  disabled: true,
                },
                {
                  className: 'edit-create-input',
                  variant: cons.STYLE.VARIANT.STANDARD,
                  size: cons.SIZE.SMALL,
                  label: { resource: resource, key: 'productType' },
                  value: savedProduct.productType,
                  name: 'productType',
                  isEndOfLine: false,
                  disabled: true,
                },
                {
                  className: 'edit-create-input',
                  variant: cons.STYLE.VARIANT.STANDARD,
                  size: cons.SIZE.SMALL,
                  label: { resource: resource, key: 'productChannel' },
                  value: savedProduct.productChannel,
                  name: 'productChannel',
                  isEndOfLine: false,
                  disabled: true,
                },
                {
                  className: 'edit-create-input',
                  size: cons.SIZE.SMALL,
                  label: { resource: resource, key: 'displaySequence' },
                  value: savedProduct.displaySequence,
                  name: 'displaySequence',
                  //eslint-disable-next-line
                  pattern: '^d*$',
                  isEndOfLine: true,
                  required: true,
                  helperTextIcon: true,
                  helperText: ProductMapValidation.getErrorMessages(
                    productMapValidationConstants.KEY_DISPLAY_SEQUENCE,
                    savedProduct.productCode
                  ),
                  FormHelperTextProps: { className: helperTextStylesClasses.root },
                },
              ]
              return (
                <Card key={idx} className="image-list">
                  {
                    <>
                      <div className="delete-icon">
                        <TempBtn
                          btnSetting={{
                            className: 'edit-create-btn',
                            variant: cons.STYLE.VARIANT.CONTAINED,
                            color: cons.STYLE.PRIMARY,
                            onClick: () => handeleDelete(idx),
                            label: { key: 'deleteBtn' },
                            icon: <DeleteIcon />,
                          }}
                        />
                      </div>
                      {fields.map((inputSetting, index) => {
                        return (
                          <div className="edit-display-wrapper" key={index}>
                            <TempTextInput inputSetting={inputSetting} handleChange={onChangeInput} index={idx} />
                            {inputSetting.isEndOfLine && <div></div>}
                          </div>
                        )
                      })}
                    </>
                  }
                </Card>
              )
            })}
          <Drawer classes={{ paper: 'drawer-margin-left' }} anchor="right" open={showProductPanel} onClose={handleProductCloseClick}>
            <ProductList
              editBtn={false}
              createBtn={false}
              btnList={false}
              mutipleSelect={true}
              setSelectedIdList={setProductIdList}
              selectedIdList={productIdList}
              setPreviewSelectedList={setPreviewSelectedList}
              previewSelectedList={previewSelectedList}
              newFilter={
                productIdList.length > 0
                  ? {
                      categoryId: savedProduct.draft.category.id,
                      excludedTypeList: ['PACKAGE_SUB_ITEM'],
                      selected: {
                        column: 'id',
                        valueList: productIdList
                          .map((item) => {
                            return item
                          })
                          .reverse(),
                      },
                    }
                  : { categoryId: savedProduct.draft.category.id, excludedTypeList: ['PACKAGE_SUB_ITEM'] }
              }
              savedData={savedProductMap}
              dialogFooterBtn={
                <div className="get-push-card-wrapper">
                  <TempBtn
                    btnSetting={{
                      className: 'edit-create-btn',
                      variant: cons.STYLE.VARIANT.CONTAINED,
                      color: cons.STYLE.PRIMARY,
                      onClick: handleProductCodeSubmit,
                      label: { key: 'submit' },
                      icon: <AddIcon />,
                    }}
                  />
                  <TempBtn
                    btnSetting={{
                      className: 'edit-create-btn',
                      variant: cons.STYLE.VARIANT.CONTAINED,
                      color: cons.STYLE.PRIMARY,
                      onClick: handleProductCloseClick,
                      label: { key: 'close' },
                      icon: <ClearIcon />,
                    }}
                  />
                </div>
              }
            />
          </Drawer>
        </>
      ),
      btn: !restaurantTagCreate ? true : false,
    },
  ]

  const bottomBtns = [
    {
      variant: cons.STYLE.VARIANT.CONTAINED,
      color: cons.STYLE.PRIMARY,
      label: { key: 'save' },
      onClick: onClickSave,
      className: 'margin-left-16',
      icon: <SaveIcon />,
    },
    {
      variant: cons.STYLE.VARIANT.CONTAINED,
      color: cons.STYLE.PRIMARY,
      label: { key: 'clear' },
      disabled: false,
      onClick: onClickClear,
      className: 'margin-left-16',
      icon: <ClearIcon />,
    },
  ]

  useEffect(() => {
    dispatch(setLoading(true))
    dispatch(setProductMap([]))
    setProductIdList([])
    setPreviewSelectedList([])
    dispatch(setLoading(false))
    setClear(false)
    ProductMapValidation.resetErrorMessages()
  }, [clear === true]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <EditCreateDisplay
        code=""
        resource={resource}
        tabs={!restaurantTagCreate ? tabs : ''}
        content={content}
        onChangeInput={onChangeInput}
        bottomBtns={bottomBtns}
        mapName={{ resource: 'productCreateProductCategoryList', key: 'categoryCode' }}
        mapCode={savedProduct.draft.category.categoryCode}
        display={restaurantTagCreate === 'RestaurantTagCreate' ? 'none' : 'create'}
      />
      {dialog && dialog.open && <GlobalDialog dialogInfo={dialog} />}
    </>
  )
}
