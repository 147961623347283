import { useState, useEffect } from 'react'
import { useSelector, useStore } from 'react-redux'
import { useParams } from 'react-router-dom'
import _ from 'lodash'
import moment from 'moment'

import { Card } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import LowPriorityIcon from '@mui/icons-material/LowPriority'

import { ListDisplay } from '../../Component/ListDisplay/ListDisplay'

import { constants as cons } from '../../Constants/constants'
import { getList, deleteFetch } from '../../helper/baseFetch'
import { GlobalDialog } from '../../Component/GlobalDialog'

export const QueueTemplateMapList = ({
  newFilter,
  editBtn,
  createBtn,
  btnList,
  mutipleSelect,
  setSelectedIdList,
  selectedIdList,
  setPreviewSelectedList,
  previewSelectedList,
  dialogFooterBtn,
  savedData,
}) => {
  const store = useStore()
  const params = useParams()
  const [queueTemplateMapList, setQueueTemplateMapList] = useState([])
  const total = useSelector((state) => state.system.ui.total)
  const [ready, setReady] = useState(false)
  const [clear, setClear] = useState(false)
  const [selectedAllCheck, setSelectedAllCheck] = useState(false)
  const [dialog, setDialog] = useState({
    open: false,
    dialogActions: [],
  })
  const resource = 'QueueTemplateMap'

  const listTitles = [
    {
      key: cons.LIST_DISPLAY_TYPE.STRING,
      value: 'templateCode',
      sort: true,
    },
    {
      key: cons.LIST_DISPLAY_TYPE.STRING,
      value: 'templateDescription',
      sort: true,
    },
    {
      key: cons.LIST_DISPLAY_TYPE.STRING,
      value: 'description',
      sort: true,
    },
    {
      key: cons.LIST_DISPLAY_TYPE.STRING,
      value: 'type',
      sort: true,
    },
    {
      key: cons.LIST_DISPLAY_TYPE.STRING,
      value: 'dayOfWeek',
      sort: true,
    },
    {
      key: cons.LIST_DISPLAY_TYPE.DATE,
      value: 'startDate',
      sort: true,
    },
    {
      key: cons.LIST_DISPLAY_TYPE.DATE,
      value: 'endDate',
      sort: true,
    },
  ]

  const listFilters = [
    {
      className: 'filter-input',
      label: { resource: resource, key: 'templateCode' },
      clear: true,
      value: '',
      name: 'templateCode',
    },
    {
      className: 'filter-input',
      label: { resource: resource, key: 'templateDescription' },
      clear: true,
      value: '',
      name: 'templateDescription',
    },
    {
      className: 'filter-input',
      label: { resource: resource, key: 'description' },
      clear: true,
      value: '',
      name: 'description',
    },
    {
      className: 'filter-input',
      label: { resource: resource, key: 'type' },
      clear: true,
      value: '',
      type: cons.TYPE.SELECT,
      selectValue: cons.CHOICES.QUEUE_TEMPLATE_MAP.TYPE,
      name: 'type',
    },
    {
      className: 'filter-input',
      label: { resource: resource, key: 'dayOfWeek' },
      clear: true,
      value: '',
      type: cons.TYPE.SELECT,
      selectValue: cons.CHOICES.QUEUE_TEMPLATE_MAP.DAY_OF_WEEK,
      name: 'dayOfWeek',
    },
    {
      className: 'filter-input',
      label: { resource: resource, key: 'date' },
      clear: true,
      value: '',
      name: 'date',
      type: cons.TYPE.DATE,
    },
  ]

  const [param, setParam] = useState({
    path: '',
    page: 0,
    rowsPerPage: 10,
    sort: 'updateTimestamp',
    direction: 'DESC',
    listFilters: listFilters,
    count: 0,
  })

  function onChangeParam(value) {
    value.count = value.count + 1
    setParam(value)
  }

  function onChangeListFilters(list) {
    let tempParam = _.cloneDeep(param)
    tempParam.listFilters = list
    tempParam.page = 0
    onChangeParam(tempParam)
  }

  function onChangeListSort(sortItem) {
    let tempParam = _.cloneDeep(param)
    tempParam.sort = sortItem
    tempParam.direction = tempParam.direction === 'DESC' ? 'ASC' : 'DESC'
    onChangeParam(tempParam)
  }

  function onChangeListPage(page) {
    let tempParam = _.cloneDeep(param)
    tempParam.page = page
    onChangeParam(tempParam)
  }

  function onChangeListRowsPerPage(rowsPerPage) {
    let tempParam = _.cloneDeep(param)
    tempParam.page = 0
    tempParam.rowsPerPage = rowsPerPage
    onChangeParam(tempParam)
  }

  function handleDeleteDialog(id, data) {
    setDialog({
      open: true,
      onClose: onClickBackToList,
      dialogContent: <>Delete {data.description ? data.description : data.dayOfWeek ? data.dayOfWeek : 'Confirm'} ?</>,
      dialogActions: [
        {
          label: { key: 'deleteBtn' },
          onClick: () => handleDelete(id),
          icon: <DeleteIcon />,
        },
        {
          label: { key: 'backList' },
          onClick: onClickBackToList,
          icon: <LowPriorityIcon />,
        },
      ],
    })
  }

  function handleDelete(id) {
    deleteFetch(
      cons.PATH.SHOP.QUEUE.QUEUE_TEMPLATE_MAP + '/' + id,
      undefined,
      () => {
        setDialog({
          open: true,
          onClose: onClickBackToList,
          dialogContentText: { key: 'deleteSuccess' },
          dialogActions: [
            {
              label: { key: 'backList' },
              onClick: onClickBackToList,
              icon: <LowPriorityIcon />,
            },
          ],
        })
      },
      undefined,
      store
    )
  }

  const onClickBackToList = () => {
    setClear(true)
    setDialog({
      open: false,
      dialogActions: [],
    })
  }

  function onMultiSelectList(id, dataList) {
    let selectedList = selectedIdList ? _.cloneDeep(selectedIdList) : []
    let newSelectedQueueTemplateList = _.cloneDeep(previewSelectedList)
    let tempDataList = _.cloneDeep(dataList)
    if (id === 'all') {
      if (!selectedAllCheck) {
        queueTemplateMapList.map((item) => {
          newSelectedQueueTemplateList.push(item)
          return selectedList.push(item.id)
        })
      } else {
        selectedList = selectedList.filter((item) => {
          let selectedQueueTemplate = queueTemplateMapList.map((queueTemplateMap) => {
            newSelectedQueueTemplateList = newSelectedQueueTemplateList.filter(
              (newSelectedQueueTemplate) => newSelectedQueueTemplate.id !== queueTemplateMap.id
            )
            return queueTemplateMap.id === item && queueTemplateMap.id
          })
          return !selectedQueueTemplate.includes(item)
        })
      }
      setSelectedAllCheck(!selectedAllCheck)
    } else {
      if (selectedList.includes(id)) {
        selectedList = selectedList.filter((item) => item !== id)
        newSelectedQueueTemplateList = newSelectedQueueTemplateList.filter((item) => item.id !== id)
      } else {
        selectedList.push(id)
        newSelectedQueueTemplateList.push.apply(
          newSelectedQueueTemplateList,
          tempDataList.filter((item) => item.id === id)
        )
      }

      let selectedPerPage = queueTemplateMapList.filter((queueTemplateMap) => {
        return selectedList.includes(queueTemplateMap.id)
      })
      if (selectedPerPage.length === param.rowsPerPage && selectedPerPage.length !== 0) {
        setSelectedAllCheck(true)
      } else {
        setSelectedAllCheck(false)
      }
    }

    setSelectedIdList(selectedList)

    setPreviewSelectedList(
      newSelectedQueueTemplateList.map((queueTemplateMap) => {
        return queueTemplateMap
      })
    )
  }

  useEffect(() => {
    let tempParam = _.cloneDeep(param)
    const filter = newFilter ? _.cloneDeep(newFilter) : { id: params.id }

    tempParam.listFilters.forEach((filterItem) => {
      if (filterItem.value !== '' && filterItem.value !== undefined) {
        if (filterItem.name === 'date') {
          filter[filterItem.name] = moment(filterItem.value).format('x')
        } else {
          filter[filterItem.name] = filterItem.value
        }
      }
    })

    getList(
      cons.PATH.SHOP.QUEUE.QUEUE_TEMPLATE_MAP,
      {
        sort: { field: tempParam.sort, order: tempParam.direction },
        filter: filter,
        pagination: { page: tempParam.page + 1, perPage: tempParam.rowsPerPage },
      },
      (payload) => {
        setQueueTemplateMapList(payload)
        let selectedPerPage = payload.filter((queueTemplate) => {
          return (
            savedData &&
            savedData
              .map((savedData) => {
                return savedData
              })
              .includes(queueTemplate.id)
          )
        })
        if (selectedPerPage.length === Number(tempParam.rowsPerPage < total ? tempParam.rowsPerPage : total) && selectedPerPage.length !== 0) {
          setSelectedAllCheck(true)
        } else {
          setSelectedAllCheck(false)
        }
        setReady(true)
        setClear(false)
      },
      undefined,
      store
    )
  }, [param.count, clear === true]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    ready && (
      <Card className="card-wrapper overflow">
        <ListDisplay
          page={param.page}
          rowsPerPage={param.rowsPerPage}
          onChangeListSort={onChangeListSort}
          resource={resource}
          dataList={queueTemplateMapList}
          sort={param.sort}
          direction={param.direction}
          listTitles={listTitles}
          listFilters={param.listFilters}
          onChangeListPage={onChangeListPage}
          onChangeListRowsPerPage={onChangeListRowsPerPage}
          editBtn={editBtn}
          createBtn={createBtn}
          mutipleSelect={mutipleSelect}
          idName="id"
          idParam={params.id}
          onMultiSelectList={onMultiSelectList}
          selectedAllCheck={selectedAllCheck}
          previewSelectedIdList={selectedIdList ? selectedIdList : []}
          dialogFooterBtn={dialogFooterBtn}
          btnList={
            btnList || btnList === false
              ? btnList
              : [
                  {
                    handleChange: handleDeleteDialog,
                    color: cons.STYLE.PRIMARY,
                    label: { key: 'deleteBtn' },
                    icon: <DeleteIcon />,
                  },
                ]
          }
          onChangeListFilters={onChangeListFilters}
          redirectPath={'/' + cons.PATH.SHOP.QUEUE.QUEUE_TEMPLATE_MAP + '/'}
        />
        {dialog && dialog.open && <GlobalDialog dialogInfo={dialog} />}
      </Card>
    )
  )
}
