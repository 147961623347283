import _ from 'lodash'
import { useState, useEffect } from 'react'
import { useStore } from 'react-redux'
import { useParams } from 'react-router-dom'
import { constants as cons } from '../../Constants/constants'
import { getList } from '../../helper/baseFetch'
import { Card } from '@mui/material'
import { ListDisplay } from '../../Component/ListDisplay/ListDisplay'
import { generateTableCellToMultiLanguageName } from '../../helper/util'

export default function CounterProfileList({ onClickListRow, newFilter, editBtn, createBtn = true, dialogFooterBtn }) {
  const store = useStore()
  const params = useParams()

  const resource = 'CounterProfile'

  const [counterProfileList, setCounterProfileList] = useState([])
  const [ready, setReady] = useState(false)
  const [clear, setClear] = useState(false)

  const listTitles = [
    {
      key: cons.LIST_DISPLAY_TYPE.STRING,
      value: 'profileCode',
      sort: true,
    },
    {
      key: cons.LIST_DISPLAY_TYPE.STRING,
      value: 'name',
      sort: false,
    },
  ]

  const listFilters = [
    {
      className: 'filter-input',
      label: { resource: resource, key: 'profileCode' },
      clear: true,
      value: '',
      name: 'profileCode',
    },
    {
      className: 'filter-input',
      label: { resource: resource, key: 'name' },
      clear: true,
      value: '',
      name: 'name',
    },
  ]

  const [filterOption, setFilterOption] = useState({
    path: '',
    page: 0,
    rowsPerPage: 10,
    sort: 'updateTimestamp',
    direction: 'DESC',
    listFilters: listFilters,
    count: 0,
  })

  const onChangeFilterOption = (filterOption) => {
    setFilterOption({ ...filterOption, count: filterOption.count + 1 })
  }

  const onChangeListFilters = (listFilters) => {
    onChangeFilterOption({ ...filterOption, listFilters: listFilters, page: 0 })
  }

  const onChangeListSort = (sort) => {
    onChangeFilterOption({ ...filterOption, sort: sort, direction: filterOption.direction === 'DESC' ? 'ASC' : 'DESC' })
  }

  const onChangeListPage = (page) => {
    onChangeFilterOption({ ...filterOption, page: page })
  }

  const onChangeListRowsPerPage = (rowsPerPage) => {
    onChangeFilterOption({ ...filterOption, page: 0, rowsPerPage: rowsPerPage })
  }

  useEffect(() => {
    let newFilterOption = _.cloneDeep(filterOption)

    let filter = newFilter || { id: params.id }

    newFilterOption.listFilters.forEach((item) => {
      if (item.value !== '' && item.value !== undefined) {
        filter[item.name] = item.value
      }
    })

    getList(
      cons.PATH.MERCHANT.COUNTER_PROFILE,
      {
        sort: { field: newFilterOption.sort, order: newFilterOption.direction },
        filter: filter,
        pagination: { page: newFilterOption.page + 1, perPage: newFilterOption.rowsPerPage },
      },
      (payload) => {
        payload.forEach((item) => {
          item.name = generateTableCellToMultiLanguageName(item.tcName, item.enName, item.scName)
        })
        setCounterProfileList(payload)
        setReady(true)
        setClear(false)
      },
      undefined,
      store
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterOption.count, clear === true])

  return (
    ready && (
      <>
        <Card className="card-wrapper overflow">
          <ListDisplay
            page={filterOption.page}
            rowsPerPage={filterOption.rowsPerPage}
            onChangeListSort={onChangeListSort}
            resource={resource}
            dataList={counterProfileList}
            sort={filterOption.sort}
            direction={filterOption.direction}
            listTitles={listTitles}
            listFilters={filterOption.listFilters}
            onChangeListPage={onChangeListPage}
            onChangeListRowsPerPage={onChangeListRowsPerPage}
            idParam={params.id}
            onChangeListFilters={onChangeListFilters}
            onClickListRow={onClickListRow}
            editBtn={editBtn}
            createBtn={createBtn ? createBtn : false}
            dialogFooterBtn={dialogFooterBtn}
            // redirectPath={'/' + constants.PATH.MERCHANT.COUNTER_PROFILE + '/'}
            editRedirectPath={`/${cons.PATH.MERCHANT.COUNTER_PROFILE}/edit/${params.id}/`}
            redirectPath={`/${cons.PATH.MERCHANT.COUNTER_PROFILE}/`}
          />
        </Card>
      </>
    )
  )
}
