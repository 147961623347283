import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

import { Card } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import PhotoCamera from '@mui/icons-material/PhotoCamera'
import SwapVertIcon from '@mui/icons-material/SwapVert'
import PropTypes from 'prop-types'
import Paper from '@mui/material/Paper'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'

import { setBrandValue } from '../../actions/brandAction'
import { setProductCategory, setProductValue } from '../../actions/productCreateAction'
import { setMerchantShopValue } from '../../actions/merchantShopCreateAction'
import { constants as cons, IMAGE_TYPE } from '../../Constants/constants'
import { setLoading } from '../../actions/systemAction'
import { UploadImage } from './UploadImage'
import { TempAutoSelectInput } from '../Input/TempAutoSelectInput'
import { TempTextInput } from '../Input/TempTextInput'
import { TempBtn } from '../Input/TempBtn'
import { getString } from '../../helper/util'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box p={4}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

export const ImageList = ({
  resource,
  type,
  mediaType,
  typeList,
  uploadPath,
  savedData,
  hiddenInput,
  disableInput,
  validation,
  validationConstants,
  helperTextStylesClasses,
  hideTitle,
  onUploadImageList,
}) => {
  const dispatch = useDispatch()
  const lang = useSelector((state) => state.lang.ui.lang)
  const theme = useSelector((state) => state.theme.ui.theme)

  const cloneDeepTarget = (type) => {
    switch (type) {
      case IMAGE_TYPE.PRODUCT:
        return savedData.draft.product.imageList
      case IMAGE_TYPE.CATEGORY:
        return savedData.draft.category.imageList
      case IMAGE_TYPE.MERCHANT_SHOP:
        return savedData.draft.shopFrontImageList
      default:
        return savedData.imageList
    }
  }

  let imageList = _.cloneDeep(cloneDeepTarget(type))

  const [tabNumber, setTabNumber] = useState(0)
  const [openUploadImage, setOpenUploadImage] = useState(false)

  const onClickImage = () => setOpenUploadImage(true)
  const onCloseDialog = () => setOpenUploadImage(false)
  const onConfirmDialog = async (image) => {
    let newImageList = _.cloneDeep(imageList)

    if (image.length <= 1 && (image[0].index || image[0].index === 0)) {
      newImageList.forEach((item, index) => {
        if (index === image[0].index) {
          item['cosFileKey'] = image[0].cosFileKey
          item['cosFileUrl'] = image[0].cosFileUrl
          item['height'] = image[0].height
          item['width'] = image[0].width
        }
      })
    } else {
      image.forEach((imageValue) => {
        let imageType = ''
        switch (type) {
          case IMAGE_TYPE.BRAND:
            imageType = 'MERCHANT_BRAND_IMAGE'
            break
          case IMAGE_TYPE.MERCHANT_SHOP:
            imageType = 'REST_SHOP_FRONT'
            break
          default:
            //imageType = getImageType(imageValue, type)
            imageType = ''
        }
        newImageList = [
          {
            type: imageType,
            fileType: 'png',
            cosFileKey: imageValue.cosFileKey,
            cosFileUrl: imageValue.cosFileUrl,
            height: imageValue.height,
            width: imageValue.width,
            displaySequence: (newImageList.length + 1) * 10,
          },
        ].concat(newImageList)
      })
    }
    newImageList = onChangeDisplaySequence(newImageList)
    if (type === IMAGE_TYPE.BRAND) {
      dispatch(setBrandValue('imageList', savedData, '', newImageList))
    } else if (type === IMAGE_TYPE.PRODUCT) {
      dispatch(setProductValue(cons.ACTION_KEY.PRODUCT.PRODUCT, savedData, 'imageList', newImageList))
    } else if (type === IMAGE_TYPE.CATEGORY) {
      dispatch(
          setProductCategory(savedData?.draft?.category, 'imageList', newImageList)
      )
    } else if (type === IMAGE_TYPE.MERCHANT_SHOP) {
      dispatch(setMerchantShopValue('DRAFT', savedData, 'shopFrontImageList', newImageList))
    } else {
      onUploadImageList(newImageList)
    }
    setOpenUploadImage(false)
  }

  const onDeleteImage = (imageIndex) => {
    dispatch(setLoading(true))
    let newImageList = _.cloneDeep(imageList)
    newImageList = newImageList.filter((item, index) => {
      return index !== imageIndex
    })
    newImageList = onChangeDisplaySequence(newImageList)
    if (type === IMAGE_TYPE.BRAND) {
      dispatch(setBrandValue('imageList', savedData, '', newImageList))
    } else if (type === IMAGE_TYPE.PRODUCT) {
      dispatch(setProductValue(cons.ACTION_KEY.PRODUCT.PRODUCT, savedData, 'imageList', newImageList))
    } else if (type === IMAGE_TYPE.CATEGORY) {
      dispatch(
        setProductCategory(savedData?.draft?.category, 'imageList', newImageList)
      )
    } else if (type === IMAGE_TYPE.MERCHANT_SHOP) {
      dispatch(setMerchantShopValue('DRAFT', savedData, 'shopFrontImageList', newImageList))
    } else {
      onUploadImageList(newImageList)
    }
    dispatch(setLoading(false))
  }

  function onChangeDisplaySequence(newImageList) {
    let newIndex = newImageList.length
    if (typeList) {
      newIndex = []
      newImageList.map((item) => {
        if (newIndex[item.type]) {
          newIndex[item.type] = newIndex[item.type] + 1
        } else {
          newIndex[item.type] = 1
        }
        return item
      })
      newImageList.map((item) => {
        if (item.type) item.displaySequence = newIndex[item.type] * 10
        newIndex[item.type] = newIndex[item.type] - 1
        return item
      })
    } else {
      newImageList.map((item) => {
        item.displaySequence = newIndex * 10
        newIndex = newIndex - 1
        return item
      })
    }
    return newImageList
  }

  function onChangeImageType(name, imageType, imageListIndex) {
    let newImageList = _.cloneDeep(imageList)
    newImageList.map((item, index) => {
      if (imageListIndex === index) {
        item.type = imageType
      }
      return item
    })
    newImageList = onChangeDisplaySequence(newImageList)
    // if (type === "Brand") {
    //   dispatch(setBrandValue("imageList", savedData, "", tempImageList));
    // } else
    if (type === IMAGE_TYPE.PRODUCT) {
      dispatch(setProductValue(cons.ACTION_KEY.PRODUCT.PRODUCT, savedData, 'imageList', newImageList))
    } else if (type === IMAGE_TYPE.CATEGORY) {
      dispatch(
        setProductCategory(savedData?.draft?.category, 'imageList', newImageList)
      )
    } else {
      onUploadImageList(newImageList)
    }
    // else if (type === "MerchantShop") {
    //   dispatch(setMerchantShopValue("DRAFT", savedData, "shopFrontImageList", tempImageList));
    // }
  }

  function handleImageStatus(name, status, imageListIndex) {
    let tempImageList = _.cloneDeep(imageList)
    tempImageList.map((item, index) => {
      if (imageListIndex === index) {
        item.status = status
      }
      return {}
    })
    if (type === IMAGE_TYPE.BRAND) {
      dispatch(setBrandValue('imageList', savedData, '', tempImageList))
    } else if (type === IMAGE_TYPE.PRODUCT) {
      dispatch(setProductValue(cons.ACTION_KEY.PRODUCT.PRODUCT, savedData, 'imageList', tempImageList))
    } else if (type === IMAGE_TYPE.CATEGORY) {
      dispatch(
        setProductCategory(savedData?.draft?.category, 'imageList', tempImageList)
      )
    } else if (type === IMAGE_TYPE.MERCHANT_SHOP) {
      dispatch(setMerchantShopValue('DRAFT', savedData, 'shopFrontImageList', tempImageList))
    } else {
      onUploadImageList(tempImageList)
    }
  }

  function onDragEnd(result) {
    dispatch(setLoading(true))
    let newImageList = _.cloneDeep(imageList)
    let newSavedData = _.cloneDeep(savedData)
    if (!result.destination) return
    const [reorderedItem] = newImageList.splice(result.source.index, 1)
    newImageList.splice(result.destination.index, 0, reorderedItem)
    newImageList = onChangeDisplaySequence(newImageList)
    if (type === IMAGE_TYPE.BRAND) {
      dispatch(setBrandValue('imageList', newSavedData, '', newImageList))
    } else if (type === IMAGE_TYPE.PRODUCT) {
      dispatch(setProductValue(cons.ACTION_KEY.PRODUCT.PRODUCT, newSavedData, 'imageList', newImageList))
    } else if (type === IMAGE_TYPE.CATEGORY) {
      dispatch(
        setProductCategory(savedData?.draft?.category, 'imageList', newImageList)
      )
    } else if (type === IMAGE_TYPE.MERCHANT_SHOP) {
      dispatch(setMerchantShopValue('DRAFT', savedData, 'shopFrontImageList', newImageList))
    } else {
      onUploadImageList(newImageList)
    }
    dispatch(setLoading(false))
  }

  const onChangeTab = (event, newValue) => {
    setTabNumber(newValue)
  }

  function showImageList(tab) {
    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="templateValue">
          {(provided) => {
            return (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {imageList &&
                  imageList.length > 0 &&
                  imageList.map((item, index) => {
                    const showAll = showAllList(tab?.value)
                    //console.log(disableInput, typeList)
                    return (
                      (showAll || (tab ? item.type === tab.value : item)) && (
                        <Draggable key={index} draggableId={'' + index} index={index} isDragDisabled={disableInput || showAll ? true : false}>
                          {(provided) => {
                            return (
                              <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                <Card key={index} className="image-list image-card-background">
                                  <div className="image-list-content">
                                    {!showAll && <SwapVertIcon color={cons.STYLE.PRIMARY} fontSize={cons.SIZE.LARGE} />}
                                    <div className="image-show">
                                      <UploadImage
                                        type={type}
                                        picture={item}
                                        title={{ resource: 'UploadImage', key: 'image' }}
                                        name="image"
                                        uploadImagePath={uploadPath}
                                        saveImage={onConfirmDialog}
                                        index={index}
                                        disableInput={disableInput}
                                      />
                                    </div>
                                    {typeList && disableInput !== true ? (
                                      <TempAutoSelectInput
                                        inputSetting={{
                                          className: 'edit-create-input',
                                          size: cons.SIZE.SMALL,
                                          variant: cons.STYLE.VARIANT.OUTLINED,
                                          type: cons.TYPE.SELECT,
                                          selectValue: typeList.filter((item) => item.value !== 'CATEGORY_ALL' && item.value !== 'ITEM_ALL'),
                                          label: { resource: 'productCreateProductList', key: 'type' },
                                          value: item.type,
                                          disabled: disableInput,
                                          disableClearable: true,
                                          helperTextIcon: true,
                                          helperText: validation
                                            ?.getErrorMessages(
                                              validationConstants.KEY_PRODUCT_IMAGE_TYPE,
                                              savedData?.draft?.product?.productCode,
                                              item.id ? item.id : item.cosFileKey
                                            )
                                            .map((error) => {
                                              return error
                                            }),
                                          FormHelperTextProps: { className: helperTextStylesClasses.root },
                                        }}
                                        handleChange={onChangeImageType}
                                        index={index}
                                      />
                                    ) : (
                                      <TempTextInput
                                        inputSetting={{
                                          className: 'edit-create-input',
                                          variant: cons.STYLE.VARIANT.STANDARD,
                                          size: cons.SIZE.SMALL,
                                          label: { resource: resource, key: 'type' },
                                          value: item.type,
                                          disabled: true,
                                        }}
                                        index={index}
                                      />
                                    )}
                                    <TempTextInput
                                      inputSetting={{
                                        className: 'edit-create-input',
                                        variant: cons.STYLE.VARIANT.STANDARD,
                                        type: cons.TYPE.NUMBER,
                                        min: -1,
                                        size: cons.SIZE.SMALL,
                                        label: { resource: resource, key: 'displaySequence' },
                                        value: item.displaySequence,
                                        disabled: true,
                                      }}
                                      index={index}
                                    />
                                    {!item.id ? (
                                      !hiddenInput && (
                                        <div className="edit-create-input">
                                          <TempBtn
                                            btnSetting={{
                                              className: 'edit-create-btn',
                                              variant: cons.STYLE.VARIANT.CONTAINED,
                                              color: cons.STYLE.PRIMARY,
                                              onClick: () => onDeleteImage(index),
                                              label: { key: 'deleteBtn' },
                                              icon: <DeleteIcon />,
                                              disabled: disableInput,
                                            }}
                                          />
                                        </div>
                                      )
                                    ) : !disableInput ? (
                                      <TempAutoSelectInput
                                        inputSetting={{
                                          className: 'edit-create-input',
                                          size: cons.SIZE.SMALL,
                                          variant: cons.STYLE.VARIANT.OUTLINED,
                                          type: cons.TYPE.SELECT,
                                          selectValue: cons.CHOICES.PRODUCT_CREATE.CREATE_CATEGORY.STATUS,
                                          label: { resource: resource, key: 'status' },
                                          value: item.status ? item.status : '',
                                          disabled: disableInput,
                                        }}
                                        handleChange={handleImageStatus}
                                        index={index}
                                      />
                                    ) : (
                                      <TempTextInput
                                        inputSetting={{
                                          className: 'edit-create-input',
                                          variant: cons.STYLE.VARIANT.STANDARD,
                                          size: cons.SIZE.SMALL,
                                          label: { resource: resource, key: 'status' },
                                          value: item.status ? item.status : '',
                                          disabled: true,
                                        }}
                                        index={index}
                                      />
                                    )}
                                  </div>
                                </Card>
                              </div>
                            )
                          }}
                        </Draggable>
                      )
                    )
                  })}
              </div>
            )
          }}
        </Droppable>
      </DragDropContext>
    )
  }

  // const isReadOnlyList = (element) =>{
  //   switch(element){
  //     case "CATEGORY_ALL":
  //     case "ITEM_ALL":
  //       return true;
  //     default:
  //       return false;
  //   }
  // }

  const showAllList = (element) => {
    switch (element) {
      case 'CATEGORY_ALL':
      case 'ITEM_ALL':
        return true
      default:
        return false
    }
  }

  // useEffect(() => {
  //   console.log('savedData', savedData)
  // }, [savedData])

  return (
    <>
      {!hideTitle && <div className="title">{getString(lang, resource, 'imageList', theme)}</div>}
      <div className="image-list-wrapper brand-image-list-wrapper">
        <TempBtn
          btnSetting={{
            className: 'button-on-top',
            variant: cons.STYLE.VARIANT.CONTAINED,
            color: cons.STYLE.PRIMARY,
            onClick: onClickImage,
            label: { resource: resource, key: 'uploadImage' },
            disabled: disableInput,
            icon: <PhotoCamera />,
          }}
        />
      </div>
      {type === IMAGE_TYPE.PRODUCT && (
        <>
          <div className="imageInfo">{getString(lang, 'imageWarning', 'name', theme)}</div>
          <div className="imageInfo">{getString(lang, 'imageWarning', 'weChat', theme)}</div>
          <div className="imageInfo">{getString(lang, 'imageWarning', 'icon', theme)}</div>
          <div className="imageInfo">{getString(lang, 'imageWarning', 'detail', theme)}</div>
        </>
      )}
      {typeList && (
        <Paper position="static" color="default">
          <Tabs value={Number(tabNumber)} onChange={onChangeTab} aria-label="simple tabs example">
            {typeList.map((tab, index) => {
              return <Tab key={index} label={tab.name} {...a11yProps(tab)} />
            })}
          </Tabs>
        </Paper>
      )}
      {typeList
        ? typeList.map((tab, tabIndex) => {
            return (
              <TabPanel key={tabIndex} value={tabNumber} index={tabIndex}>
                {showImageList(tab)}
              </TabPanel>
            )
          })
        : showImageList()}
      <UploadImage
        type={type}
        mediaType={mediaType}
        withoutDisplay={true}
        openUploadImage={openUploadImage}
        title={{ resource: 'UploadImage', key: 'image' }}
        name="image"
        filesLimit={1000}
        uploadImagePath={uploadPath}
        close={onCloseDialog}
        saveImage={onConfirmDialog}
      />
    </>
  )
}
