import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
} from '@mui/material'
import { useEffect } from 'react'

export default function MUITable({
    columns,
    rows,
    pagination,
    setPagination,
    listLength,
    onClickListRow,
}) {
    const renderCell = (column, row) => {
        if (column.renderCell) {
            return column.renderCell({
                row,
                value: row[column.field],
            })
        }

        return row[column.field]
    }

    const onChangePage = (e, newPage) => {
        setPagination({ ...pagination, page: newPage })
    }

    const onChangePageSize = (e) => {
        setPagination({ ...pagination, pageSize: e.target.value })
    }

    useEffect(() => {
        const maxPage = Math.ceil(listLength / pagination.pageSize) - 1

        if (pagination.page > maxPage && maxPage >= 0) {
            setPagination((pre) => ({
                ...pre,
                page: 0,
            }))
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listLength, pagination.pageSize])

    return (
        <TableContainer component={Paper} sx={{ height: '100%' }}>
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        {columns.map((column, columnIdx) => (
                            <TableCell
                                key={columnIdx}
                                sx={{
                                    width: column.width,
                                    flex: column.flex || 1,
                                    fontWeight: 700,
                                    backgroundColor: '#eee',
                                }}
                                component='th'
                            >
                                {column.headerName}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row, rowIdx) => (
                        <TableRow
                            key={`row-${row.id}`}
                            onClick={onClickListRow ? () => onClickListRow(row) : () => {}}
                            sx={
                                onClickListRow
                                    ? {
                                          cursor: 'pointer',
                                          '&:hover': {
                                              backgroundColor: 'rgba(0, 0, 0, 0.04)',
                                          },
                                      }
                                    : {}
                            }
                        >
                            {columns.map((column, columnIdx) => (
                                <TableCell
                                    key={`cell-${columnIdx}-${rowIdx}`}
                                    sx={{ width: column.width, flex: column.flex || 1 }}
                                    component='td'
                                >
                                    {renderCell(column, row)}
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <TablePagination
                component='div'
                count={parseInt(listLength)}
                page={pagination.page}
                onPageChange={onChangePage}
                rowsPerPage={pagination.pageSize}
                rowsPerPageOptions={[10, 20, 30]}
                onRowsPerPageChange={onChangePageSize}
                sx={{ position: 'sticky', bottom: 0, backgroundColor: '#fff' }}
            />
        </TableContainer>
    )
}
