import _ from 'lodash'
import { constants as cons } from '../Constants/constants'
import { LANDMARK_RESTAURANT_MAP } from '../actions/types'

const INITIAL_STATE = {
  ui: {
    restaurantMap: _.cloneDeep(cons.LANDMARK_RESTAURANT_MAP_INPUT),
  },
}

const landmarkRestaurantMapReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LANDMARK_RESTAURANT_MAP.setRestaurantMapDefault:
      return Object.assign({}, INITIAL_STATE)
    case LANDMARK_RESTAURANT_MAP.setRestaurantMap:
    case LANDMARK_RESTAURANT_MAP.setRestaurantMapValue:
      return Object.assign(
        {},
        {
          ...state,
          ui: {
            ...state.ui,
            restaurantMap: action.restaurantMap,
          },
        }
      )
    default:
      return state
  }
}

export default landmarkRestaurantMapReducer
