import { Drawer } from '@mui/material'
import { constants as cons } from '../../../Constants/constants'
import { Clear } from '@mui/icons-material'
import { TempBtn } from '../../../Component/Input/TempBtn'
import RestaurantList from './RestaurantList'

export default function RestaurantListDrawer({ idList, setIdList, objList, setObjList, showRestaurantMapList, setShowRestaurantMapList }) {
  const footerBtns = [
    {
      variant: cons.STYLE.VARIANT.CONTAINED,
      color: cons.STYLE.PRIMARY,
      label: { key: 'close' },
      disabled: false,
      onClick: () => setShowRestaurantMapList(false),
      className: 'margin-left-16',
      icon: <Clear />,
    },
  ]

  return (
    <Drawer classes={{ paper: 'drawer-margin-left' }} anchor="right" open={showRestaurantMapList} onClose={() => setShowRestaurantMapList(false)}>
      <RestaurantList
        editBtn={false}
        createBtn={false}
        btnList={false}
        idList={idList}
        setIdList={setIdList}
        objList={objList}
        setObjList={setObjList}
        dialogFooterBtn={
          <div className="edit-footer-wrapper toolbar">
            {footerBtns.map((setting, i) => {
              return <TempBtn key={i} btnSetting={setting} />
            })}
          </div>
        }
        multipleSelect={true}
      />
    </Drawer>
  )
}
