import { useState } from 'react'
import { Drawer, IconButton } from '@mui/material'
import Iconify from '../iconify/Iconify'
import { ICONIFY } from '../../../Constants/iconify'
import MUIInput from '../field/MUIInput'
import { DistrictList } from '../../../Page/District/DistrictList'
import { constants as cons } from '../../../Constants/constants'

export function DistrictField({ setDistrict, value, filterCityCode }) {
    const [showDrawer, setShowDrawer] = useState(false)

    const onClickShowDrawer = () => {
        setShowDrawer(true)
    }

    const onClickCloseDrawer = () => {
        setShowDrawer(false)
    }

    const onClickListRow = (row) => {
        setShowDrawer(false)
        setDistrict(row)
    }

    return (
        <>
            <MUIInput
                label='District'
                value={value || ''}
                name='districtId'
                endAdornment={
                    <IconButton onClick={onClickShowDrawer}>
                        <Iconify icon={ICONIFY.edit} />
                    </IconButton>
                }
                readOnly
            />
            <Drawer anchor='right' open={showDrawer} onClose={onClickCloseDrawer}>
                <DistrictList
                    newListTitles={[
                        {
                            key: cons.LIST_DISPLAY_TYPE.STRING,
                            value: 'districtId',
                            sort: true,
                        },
                        {
                            key: cons.LIST_DISPLAY_TYPE.STRING,
                            value: 'name',
                            sort: false,
                        },
                        {
                            key: cons.LIST_DISPLAY_TYPE.STRING,
                            value: 'status',
                            sort: true,
                        },
                    ]}
                    newListFilters={[
                        {
                            className: 'filter-input',
                            label: { resource: 'RestaurantDistrict', key: 'cityCode' },
                            clear: true,
                            value: filterCityCode,
                            name: 'cityCode',
                            disabled: !!filterCityCode
                        },
                        {
                            className: 'filter-input',
                            label: { resource: 'RestaurantDistrict', key: 'name' },
                            clear: true,
                            value: '',
                            name: 'name',
                        },
                        {
                            className: 'filter-input',
                            label: { resource: 'RestaurantDistrict', key: 'status' },
                            value: '',
                            type: cons.TYPE.SELECT,
                            selectValue: cons.CHOICES.RESTAURANT_AREA.STATUS.filter(
                                (status) => status.value !== 'D'
                            ),
                            name: 'status',
                        },
                    ]}
                    editBtn={false}
                    createBtn={false}
                    onClickListRow={onClickListRow}
                />
            </Drawer>
        </>
    )
}
