import { Stack, Tooltip } from '@mui/material'
import MUILabel from './MUILabel'

export default function MultiLangLabel({
    nameObj,
    tooltip,
    itemWidth = '200px',
    direction = 'horizontal',
    gap = 2,
}) {
    return (
        <Tooltip title={tooltip || ''} followCursor>
            <Stack
                gap={gap}
                direction={direction === 'horizontal' ? 'row' : 'column'}
                sx={{ fontSize: '15px' }}
            >
                {Object.entries(nameObj).map(([key, value]) => (
                    <Stack
                        direction='row'
                        sx={{ gap: 1, width: itemWidth }}
                        key={`${key}-${value}`}
                    >
                        <MUILabel variant='ghost' color='primary'>
                            {key.toUpperCase()}
                        </MUILabel>
                        {value}
                    </Stack>
                ))}
            </Stack>
        </Tooltip>
    )
}
