export const scMessages = {
    serverError: '未能完成请求',
    loginError: '请先登录',

    //menu
    shopMenu: '店铺',
    approveMenu: '批准',
    takeawayMenu: '外卖',
    productMenu: '产品',
    restaurantTag: '餐厅标签',
    productCreateMenuAdmin: '新增产品',
    productCreateMenuFinance: '产品草稿',
    merchantMenu: 'Merchant',
    tools: '工具',
    configuration: '设置',
    template: '模板',
    brand: '品牌',
    location: '地点',
    changePassword: '更改密码',

    //warning
    selectWarning: '请选择餐厅！',
    warning: '警告: ',
    warningContent: '您的所以行为都会受到监控！',

    //btn
    login: '登录',
    create: '新增',
    back: '返回',
    next: '下一页',
    saveAsDraft: '另存为草稿',
    approve: '批准',
    unpublish: '下架',
    publish: '上架',
    finishBtn: '完成',
    submit: '提交',
    save: '保存',
    remove: '移除',
    applyToAllRelatedShop: '套用到所有相关店铺',
    applyToAll: '全部套用',
    search: '搜索',
    close: '关闭',
    cancelBtn: '取消',
    clear: '清除',
    Clone: '新增',
    clearCache: '清除缓存',
    clearRedisCache: '清除缓存',
    clearGuluWebSiteDetailCache: '清除 GULU 网站详细信息',
    add: '新增',
    edit: '编辑',
    rejectBtn: '拒绝',
    select: '选择',
    confirm: '确定',
    deleteBtn: '删除',
    resetPassword: '重设密码',
    reset: '重设',
    resetAccountNameAndPassword: '重设账户名称及密码',
    preview: '预览',

    //dialog
    backEdit: '继续编辑',
    backList: '返回目录',
    backCreate: '返回新增',
    backClone: '返回新增',
    goToMapList: 'Go To Map List',
    goToMapCreate: 'Go To Map Create',
    dialogTitle: '资讯',
    editSuccess: '编辑成功！',
    editFailure: '编辑失败!',
    createSuccess: '新增成功！',
    createFailure: '新增失败！',
    cloneSuccess: '新增成功！ 请按刷新键刷新列表！(**Searchable 已设订为 false!)',
    cloneFailure: '新增失败！',
    addSuccess: '新增成功！',
    addFailure: '新增失败！',
    clearSuccess: '清除成功!',
    clearFailure: '清除失败!',
    clearCacheSuccess: '清除成功!',
    clearCacheFailure: '清除失败!',
    resetSuccess: '重置成功!',
    resetFailure: '重置失败!',
    previewSuccess: '预览成功！',
    previewFailure: '预览失败!',
    testingSuccess: '测试成功！',
    testingFailure: '测试失败!',
    approveSuccess: '批准成功！',
    approveFailure: '批准成功！',
    rejectSuccess: '批准失败！',
    rejectFailure: '拒绝失败!',
    paymentSuccess: '付款成功！',
    paymentFailure: '付款失败!',
    deleteSuccess: '删除成功！',
    deleteFailure: '删除失败!',
    applySuccess: '应用成功！',
    applyFailure: '应用失败!',

    //CustomizenName
    nameCustomize: '名称（自动将繁体翻译成简体）',
    descriptionCustomize: '描述（自动将繁体翻译成简体）',
    multiActionParameters: 'Multiple Language Parameters',

    //Time
    lastModified: '上次修改时间：',
    updateTimestamp: '更新时间',
    lastLoginTimestamp: '上次登入时间',
    resetUpdateTimestamp: '上次重设时间',

    //other
    sales: '销售员',
    true: 'true',
    false: 'false',
    pleaseEnter: 'Please Enter',
    applyToMap: 'Apply To Map',
    apply: 'Apply',
    downloadQRCode: '下载二维码',
    zhHK: '繁中',
    sc: '簡中',
    en: 'English',

    //select
    active: 'A (生效)',
    deactive: 'D (不生效)',
    pending: 'P (待定)',
    merchant: 'M (Merchant)',
    overWritten: 'O (重复)',
    reject: 'R (拒绝)',
    removed: 'R (落架)',
    N: 'N (不活跃)',
    complete: 'C (完成)',
    a_assigned: 'A (已分配)',
    delete: 'D (删除)',
    inactive: 'I (不活动)',
    process: 'P (处理中)',
    available: 'A (可用)',
    cancel: 'C (取消)',
    finish: 'F (完成)',
    s_checkIn: 'S (报到)',
    reminder: 'M (提醒)',
    sentOut: 'O (送出)',
    hold: 'H (保留)',
    expired: 'E (过期)',
    void: 'V (Void)',
    accept: 'A (接受)',
    s_assigned: 'S (已分配)',
    waiting: 'W (等待)',
    hold_release: 'L (Hold Release)',
    maxims_cancel: 'MC (Maxims Cancel)',
    c_checkIn: 'C (报到)',
    all: '全部',
    tobeapprove: 'TBA (待批准)',
    yes: '是',
    no: '不',

    resource: {
        login: {
            title: 'Please Login!',
            username: 'username',
            password: 'password',
        },

        restaurant: {
            mainTitle: '餐厅',
            restUrlId: 'Rest Url ID',
            status: '状态',
            restRefId: 'Ref ID',
            enName: '餐厅名称(英文)',
            tcName: '餐厅名称(繁体中文)',
            scName: '餐厅名称(简体中文)',
            salesName: '销售人员姓名',
            salesCreateTimestamp: '销售创建时间戳',
            startDisplayTimestamp: '开始显示时间戳',
            //餐厅列表
            address: '地址',
            imageUrl: 'imageUrl',
            refID: 'Ref ID',
            name: '名称',
            salesCreateStartDate: '销售创建开始日期',
            salesCreateEndDate: '销售创建结束日期',
            //restaurantClone
            newRestUrlID: 'New Rest Url ID',
            cloneTitle: 'Clone Restaurant',
            systemGroup: 'System Group',
            slideshow: 'Slideshow',
            banner: 'Banner',
            payment: '付款',
            systemGroupGorilla: 'System Group Gorilla',
            merchantUserCreate: 'Merchant User Create',
            //restaurantEdit
            info: '资料',
            qrCode: '二维码',
            queue: '排队',
            reservation: '订座',
            pickup: '提取叫号',
            merchant: '商户',
            media: '媒体',
            product: '产品',
            select_tag: 'Select Tag',
            restaurantName: '餐厅名称',
            eSearchingName: '搜索名称(英文)',
            tcSearchingName: '搜索名称(繁体中文)',
            scSearchingName: '搜索名称(简体中文)',
            weChatEName: '微信餐厅名称(英文)',
            weChatTcName: '微信餐厅名称(繁体中文)',
            weChatScName: '微信餐厅名称(简体中文)',
            information: '资料',
            brandCode: '品牌',
            editBrandCode: '编辑品牌',
            addBrandCode: '新增品牌',
            clearBrandCode: '清除品牌',
            editBrandAndBrandGroupID: '编辑品牌及集团',
            groupID: '集团',
            editGroupID: '编辑集团',
            addGroupID: '新增集团',
            restaurantType: '餐厅类型',
            phoneAddress: '电话及地址',
            phone: '电话',
            district: '区域',
            addDistrictID: '新增区域',
            tcAddress: '地址(繁体中文)',
            enAddress: '地址(英文)',
            scAddress: '地址(简体中文)',
            description: '详细资料',
            enTicketMessage: 'Ticket Message(英文)',
            tcTicketMessage: 'Ticket Message(繁体中文)',
            scTicketMessage: 'Ticket Message(简体中文)',
            enOpening: '营业时间(英文)',
            tcOpening: '营业时间(繁体中文)',
            scOpening: '营业时间(简体中文)',
            config: 'Configuration',
            paymentMethod: '付款方法',
            avgSpend: '平均花费',
            enLastOrderTime: '最后订单时间(英文)',
            tcLastOrderTime: '最后订单时间(繁体中文)',
            scLastOrderTime: '最后订单时间(简体中文)',
            enDescription: '详细资料(英文)',
            tcDescription: '详细资料(繁体中文)',
            scDescription: '详细资料(简体中文)',
            aigensId: "Aigens ID(Maxim's)",
            searchable: 'Searchable',
            theme: '主题',
            showBanner: '显示横幅',
            nameCustomize: '名称（自动将繁体翻译成简体）',
            weChatNameCustomize: ' 微信名称（与 「名称」 相同）',
            searchingNameCustomize: '搜索名称（自动将繁体翻译成简体）',
            ticketMessageCustomize: 'Ticket Message（自动将繁体翻译成简体）',
            openingCustomize: '营业时间（自动将繁体翻译成简体）',
            lastOrderTimeCustomize: '最后订单时间（自动将繁体翻译成简体）',
            descriptionCustomize: '详细资料（自动将繁体翻译成简体）',
            addressCustomize: '地址（自动将繁体翻译成简体）',
            image: '图片',
            googleMap: '地图',
            uploadImage: '上载图片',
            relatedPictures: '相关图片',
            dropFile: '按此上载图片',
            retaurantBannerList: '餐厅横幅列表',
            campaignDescription: '信息显示',
            tnc: '条款及细则',
            editTitle: '编辑餐厅',
            salesAndContact: '销售员及联系人',
            contact: '联络电话',
            contactPerson: '联系人',
            jobTitle: '职称',
            folderMapList: 'Folder Map List (Restaurant)',
            addFolderMap: 'Add Restaurant',
            urlGenerator: 'Url Generator',
            website: 'Website',
            specificLang:
                'Language (EN/TC/SC only work above GULU 4.2.11, Do NOT add if you need wechat scan)',
            universalLink: 'Open App / Website',
            defaultService: '默认服务',
            generate: 'Generate',
            url: 'Url',
            dashboard: 'Dashboard',
            report: 'Report',
            cityName: 'City Name',
            regionName: 'Region Name',
        },

        //Map
        Map: {
            mainTitle: '地图',
            addressEdit: '地址编辑',
            chineseAddressSave: '中文地址 (储存版本):',
            chineseAddress: '中文地址:',
            engAddressSave: '英文地址 (储存版本):',
            engAddress: '英文地址:',
            lat: '纬度:',
            lng: '经度:',
            zoom: '缩放地图',
            search: '搜寻(地址):',
            reset: '重置',
        },

        //RestaurantMedia
        RestaurantMedia: {
            mainTitle: '媒体',
            type: '类型',
            cosFileUrl: '媒体',
            fileType: '文件类型',
            displaySequence: '显示顺序',
            status: '状态',
            image: '图像',
            cosFileEnUrl: '图像(英文)',
            cosFileTcUrl: '图像(繁体中文)',
            cosFileScUrl: '图像(简体中文)',
            tcImage: '图像(繁体中文)',
            enImage: '图像(英文)',
            scImage: '图像(简体中文)',
            editTitle: '媒体编辑',
            createTitle: '新增媒体',
            info: '资料',
        },

        RestaurantBlackout: {
            mainTitle: 'Blackout',
            type: 'Type',
            startTimestamp: 'Start Timestamp',
            endTimestamp: 'End Timestamp',
            refCode: 'Refcode',
            status: '状态',
            info: '资料',
            editTitle: 'Blackout Period Edit',
            createTitle: 'Blackout Period Create',
        },

        reportConfig: {
            reportConfig: 'Report Config',
            queue: 'Queue',
            reservation: 'Reservation',
            appointment: 'Appointment',
            product: 'Product',
            takeaway: 'Takeaway',
            backToEdit: 'Back to edit',
        },

        RestaurantSlideshow: {
            mainTitle: 'Slideshow',
            restUrlId: 'Rest Url Id',
            restRefId: 'Ref ID',
            address: '地址',
            type: 'Type',
            mediaUrl: 'Media',
            media: 'Media',
            startTimestamp: 'Start Timestamp',
            endTimestamp: 'End Timestamp',
            sequence: '顺序',
            status: '状态',
            editTitle: 'Slideshow Edit',
            createTitle: 'Slideshow Create',
            info: '资料',
            startDisplayTimestamp: 'Start Display Timestamp',
            endDisplayTimestamp: 'End Display Timestamp',
            id: 'ID',
            name: '名称',
            showExpired: 'Show Expired',
            deactivate: 'Delete',
            pending: 'Pending',
            active: 'Active',
            showDefaultSlideshow: 'Show Default Slideshow',
            addRestaurantMap: 'Add Restaurant Map',
            enName: 'En Name',
            tcName: 'Tc Name',
            scName: 'Sc Name',
        },

        //urlMap
        UrlMap: {
            mainTitle: 'Url Map',
            type: '种类',
            mapCode: 'Map Code',
            mapValue: 'Map Value',
            agentType: '代理类型',
            url: 'URL',
            serviceType: '服务类型',
            parameter: 'Parameter',
            status: '状态',
            restUrlIdQRCode: 'Rest Url ID (反扫用)',
            actionType: 'Action Type',
            action: 'Action',
            weChat: '微信',
            productCode: 'Product Code',
            categoryCode: 'Category Code',
            brandCode: 'Brand Code',
            folderCode: 'Folder Code',
            folderType: 'Folder Type',
            restUrlId: 'Rest Url Id',
            pixelName: 'Pixel Name',
            haveRef: 'Pixel Have Ref',
            from: 'From',
            title: 'Title',
            query: 'Query',
            refId: 'Ref Id',
            ticketId: 'Ticket Id',
            infoId: 'Info Id',
            groupCode: 'Group Code',
            ecouponId: 'ECoupon Id',
            code: 'Code',
            channel: '频道',
            name: '名称',
            tcName: '产品名称(繁体中文)',
            enName: '产品名称(英文)',
            scName: '产品名称(简体中文)',
            productChannel: '产品频道',
            productEnName: '产品名称(英文)',
            productTcName: '产品名称(繁体中文)',
            productScName: '产品名称(简体中文)',
            productStatus: '产品状态',
            editTitle: 'UrlMap编辑',
            createTitle: '新增UrlMap',
            info: '资料',
            productParent: '选择品牌/类别',
            eCouponParent: '选择品牌/类别',
            ecouponCode: 'E-Coupon ',
            customUrl: 'Custom URL',
            restaurantUrlType: 'Restaurant URL Type',
        },

        //status
        Status: {
            mainTitle: '状态',
            orderStatus: '订单状态',
            queueStatus: '排队状态',
            queueVisible: 'Queue Visible',
            queueSpringboardAvailable: 'Queue Springboard Available',
            queueWebTvAvailable: 'Queue Web TV Available',
            queueTicketDeletable: 'Queue Ticket Deletable',
            reserveStatus: '备用状态',
            reservationVisible: 'Reservation Visible',
            takeawayStatus: '外卖状态',
            takeawayVisible: 'Takeaway Visible',
            takeawayDeliveryStatus: '外卖交货状态',
            takeawayPickupStatus: '外卖接送状态',
            takeawayPreorderStatus: '外卖预订状态',
            wxTakeawayStatus: 'wx外卖状态',
            wxProductStatus: 'wx产品状态',
            menuStatus: '菜单状态',
            menuVisible: 'Menu Visible',
            ecouponDistributeStatus: 'ecoupon分配状态',
            banquetStatus: '宴会状态',
            banquetVisible: 'Banquet Visible',
            havePreorder: '有预购',
            autoSwitchTimeSection: '自动切换时间',
            adminLiteStatus: '管理员精简版状态',
            vipStatus: 'VIP状态',
            turboStatus: 'Turbo状态',
            bookmarkStatus: '书签状态',
            reviewStatus: '评论状态',
            socialPhotoStatus: '社交照片状态',
            shareRestStatus: 'Restaurant Share Status',
            gpsRequired: '需要 GPS',
            backList: '返回餐厅目录',
            editTitle: '状态编辑',
            retaurantBannerList: '餐厅横幅列表',
            info: '资料',
            restaurant: '餐厅',
            reserve: '备用',
            queue: '排队',
            takeaway: '外卖',
            product: '产品',
            banquet: '宴会',
            other: '其他',
            queueWebAvailable: 'Queue Web Available',
            pickup: '自取',
            pickupVisible: 'Pickup Visible',
            guest: 'Guest',
            guestReservationStatus: 'Guest Reservation Status',
            guestTicketStatus: 'Guest Ticket Status',
            notifier: '通知方法',
            email: '电邮',
            sms: 'SMS',
            whatsapp: 'Whatsapp',
            reservationWebAvailable: 'Reservation Web Available',
            queueWebReadOnly: 'Queue Web Read Only',
            queueWebOfficialReadOnly: 'Queue GULU Web Read Only',
            pickupStatus: 'Pickup Status',
        },

        QueueConfig: {
            mainTitle: '取票配置',
            showTableNo: 'Show Table No',
            timeSectionQuota: 'Time Section Concurrent Quota',
            rollTagShiftValue: 'Roll Tag Shift Value',
            ticketExpiredMinute: 'Ticket Expired Minute',
            repeatQueue: 'Repeat Queue',
            checksumExpiryLimit: 'Checksum Expiry Limit',
            queueSteps: 'Queue Steps',
            addQueueSteps: 'Add Queue Steps',
            removeQueueSteps: 'Remove Queue Steps',
            campaign: 'Campaign',
            campaignQueue: 'Campaign Queue',
            tagSequenceDisplay: 'Tag Sequence Display',
            genFromTemplate: 'Gen From Template',
            campaignStartTimestamp: 'Campaign Start Timestamp',
            campaignEndTimestamp: 'Campaign End Timestamp',
            preparationDay: 'Preparation Day',
            delay: 'Delay',
            delayEnable: 'Delay Enable',
            delayMaxMinute: 'Delay Max Minute',
            delayReminderAnswerSecond: 'Delay Reminder Answer Second',
            delayReminderTriggerSecond: 'Delay Reminder Trigger Second',
            releaseQuotaOnDelete: 'Release Quota On Delete',
            counterProfileCode: 'Default Counter Profile Code',
            requiredPass: 'Pass Required',
            limitDistance: 'Limit Distance',
            queuePeriod: 'Queue Period',
            customData: 'Custom Data',
            email: '电子邮件',
            mobile: '手机',
            personalLimitType: 'Personal Limit Type',
            queueType: 'Queue Type',
            displayType: 'Display Type',
            counterTagType: 'Counter Tag Type',
            deliveryType: 'Delivery Type',
            requiredOtp: 'Required OTP',
            redisSequence: 'Redis Sequence',
            quickQueueGroupName: 'Quick Queue Group Name',
            quotaType: 'Quota Type',
            rollTagType: 'Roll Tag Type',
            inputCheckInSize: 'Input Check In Size',
            minCheckInSize: 'Min Check In Size',
            maxCheckInSize: 'Max Check In Size',
            showTableType: 'Show Table Type',
            checkInType: 'Check In Type',
            eventCheckInType: 'Event Check In Type',
            reset: 'Reset Current Time Section',
            resetConfrim: 'Are you confirm to reset current time section?',
            editTitle: 'Queue Config Edit',
            info: '资料',
            dailyResetTime: 'Daily Reset Time',
            campaignType: 'Campaign Type',
            addResourcesSteps: 'Add Resources Steps',
            resourcesSteps: 'Resources Steps',
            autoTimeType: 'Auto Time Type',
            checkOutType: 'Check Out Type',
            checkInBeforeMinute: 'Check In Before Minute',
            checkInLateMinute: 'Check In Late Minute',
            webShare: 'Web Share',
            mobileShare: 'Mobile Share',
            pleaseEnter: '请输入',
            enterPassCode: '以重置时段',
            passCode: 'PassCode',
            dailyReset: 'Daily Reset',
            resetQueueStatus: 'Reset Queue Status',
            transferable: 'Transferable',
            useTransferableTicketLabel: 'Use Transferable Ticket Label',
            autoRequestTicketStatus: 'Auto Request Ticket Status',
            autoTask: 'Auto Task',
            autoSwitchTimeSection: 'Auto Switch Time Section',
            maxTicketPerSection: 'Max Ticket Per Section',
            limitTicketInMinute: 'Limit Ticket In Minute',
            vipType: 'VIP Type',
            prioritize: 'Prioritize',
        },

        PrintConfig: {
            mainTitle: '打印配置',
            printTemplateCode: '打印模板代码',
            deviceType: '设备类型',
            ticketRemarks: '条款及细则',
            printQrcodeType: '二维码打印类型',
            logoImage: 'Logo 图像',
            mallLogoImage: 'Mall Logo 图像',
            mallKeypadButtonImage: 'Mall Keypad Button 图像',
            headerImage: 'Header 图像',
            footerImage: 'Footer 图像',
            rightLargeImage: 'Right Large 图像',
            rightSmallImage: 'Right Small 图像',
            qrcodeImage: '二维码图像',
            portraitRectangleImage: 'Portrait Rectangle 图像',
            portraitSquareImage: 'Portrait Square 图像',
            landscapeRectangleImage: 'Landscape Rectangle 图像',
            landscapeSquareImage: 'Landscape Square 图像',
            portraitHeaderImage: 'Portrait Header 图像',
            portraitFooterImage: 'Portrait Footer 图像',
            landscapeHeaderImage: 'Landscape Header 图像',
            landscapeFooterImage: 'Landscape Footer 图像',
            displayQrcodeUrl: 'Display QR Code URL',
            qrCodePreview: '屏幕二维码预览',
            printTemplateType: '打印模板类型',
            shortTicketTnc: 'Short Ticket Tnc (Max 500 Characters)',
            shortPrintTemplateCode: 'Short Print Template Code',
            service: 'Service',
            from: 'From',
            addMoreParam: 'Add More Param To URL',
        },

        QueueTemplate: {
            mainTitle: 'Queue Template',
            timeSectionList: 'Time Section List',
            timeSection: 'Time Section',
            templateCode: 'Template Code',
            description: '描述',
            status: '状态',
            id: 'ID',
            timeSectionId: 'Time Section ID',
            tableType: 'Table Type',
            label: '标签',
            addRestaurant: '添加餐厅列表',
            addQueueTemplateMap: 'Add Queue Template Map',
            resetCurrentTimeSection: 'Reset Current Time Section',
            editTitle: 'Queue Template Edit',
            addQueueTemplate: 'Add Time Section',
            autoOrderTimeSection: 'Order Time Section',
            autoOrderTableType: 'Order Table Type',
            createTitle: 'Queue Template Create',
            info: '资料',
            createQueueTemplate: 'Create Ticket Time Section',
            cloneQueueTemplate: 'Clone Queue Template: ',
            applyQueueTemplate: 'Apply Queue Template: ',
        },

        TimeSection: {
            mainTitle: 'Time Section',
            title: 'Time Section',
            timeSectionList: 'Time Section',
            timeSectionId: '时间段 ID',
            groupedSection: 'Grouped Section',
            type: '类型',
            timeSectionDescription: '时间段描述',
            startTime: '开始时间',
            endTime: '结束时间',
            timeSectionEnLabel: '时间段标签(英文)',
            timeSectionTcLabel: '时间段标签(繁体中文)',
            timeSectionScLabel: '时间段标签(简体中文)',
            isAutoTranslateTimeSectionLabel: '时间段标签（自动将繁体翻译成简体）',
            status: '状态',
            editTitle: 'Time Section Edit',
            createTitle: 'Time Section Create',
            info: '资料',
            defaultTimeSectionSetting: 'Default Time Section Setting',
            isSyncTimeFieldsWithAllTableTypes: 'Sync Time Fields With All Table Types',
            isSyncRequestTicketTimeFieldsWithAllTableTypes:
                'Sync Request Ticket Time Fields With All Table Types',
            kioskRequestTicketStartTime: 'Kiosk Request Ticket Start Time',
            mobileRequestTicketStartTime: 'Mobile Request Ticket Start Time',
            kioskRequestTicketEndTime: 'Kiosk Request Ticket End Time',
            mobileRequestTicketEndTime: 'Mobile Request Ticket End Time',
        },

        TableType: {
            mainTitle: 'Table Type',
            title: 'Table Type',
            status: '状态',
            defaultStatus: '默认状态',
            tableTypeList: 'Table Type 列表',
            tableType: 'Table Type',
            parentTableType: 'Parent Table Type',
            tableTypeEnLabel: 'Table Type 标签(英文)',
            tableTypeTcLabel: 'Table Type 标签(繁体中文)',
            tableTypeScLabel: 'Table Type 标签(简体中文)',
            isAutoTranslateTableTypeScLabel: 'Table Type 标签（自动将繁体翻译成简体）',
            tableTypeMobileEnLabel: 'Table Type Mobile 标签(英文)',
            tableTypeMobileTcLabel: 'Table Type Mobile 标签(繁体中文)',
            tableTypeMobileScLabel: 'Table Type Mobile 标签(简体中文)',
            isAutoTranslateTableTypeMobileScLabel: 'Table Type Mobile 标签（自动将繁体翻译成简体）',
            tableTypeMobileBackgroundColor: 'Mobile BackgroundColor',
            tableTypeMobileForegroundColor: 'Mobile Foreground Color',
            applyTableTypeBackgroundColor: 'Apply Default Color Set',
            applyTableTypeMobileBackgroundColor: 'Apply Default Mobile Color Set',
            minSize: 'Min Size',
            maxSize: 'Max Size',
            sizeSelectable: 'Size Selectable',
            ticketType: 'Ticket Type',
            ticketTypeEnLabel: 'Ticket Type 标签(英文)',
            ticketTypeTcLabel: 'Ticket Type 标签(繁体中文)',
            ticketTypeScLabel: 'Ticket Type 标签(简体中文)',
            nextRemind: '下一个提醒',
            dailyQuota: '每日配额',
            personalQuota: '个人配额',
            mobileDisplay: 'Mobile Get Ticket 显示',
            mobileTagDisplay: 'Mobile Tag 显示',
            mobileReadOnly: 'Mobile Read Only',
            kioskDisplay: 'Kiosk Get Ticket 显示',
            kioskTagDisplay: 'Kiosk Tag 显示',
            kioskReadOnly: 'Kiosk Read Only',
            editTitle: 'Table Type 编辑',
            createTitle: 'Table Type Create',
            info: '资料',
            //Reservation
            groupedSection: 'Grouped Section',
            reservationType: 'Reservation Type',
            autoAcceptFlag: 'AutoAccept Flag',
            autoRejectFlag: 'autoRejectFlag',
            limitMinute: 'Limit Minute',
            reminderMinute: 'Reminder Minute',
            quota: 'Quota',
            cutOffHour: 'Cut Off Hour',
            cutOffMinute: 'Cut Off Minute',
            cutOffTime: 'Cut Off Time',
            startTime: '开始时间',
            endTime: '结束时间',
            type: '类型',
            timeSectionDescription: '时间段描述',
            isSyncTimeFieldsWithAllTableTypes: 'Sync Time Fields With All Table Types',
            isSyncRequestTicketTimeFieldsWithAllTableTypes:
                'Sync Request Ticket Time Fields With All Table Types',
            queueType: 'Queue Type',
            displayType: 'Display Type',
            rollTagType: 'Roll Tag Type',
            tableTypeBackgroundColor: 'Table Type Background Color',
            tableTypeForegroundColor: 'Table Type Foreground Color',
            tableTypeTicketEnLabel: 'Table Type Ticket En Label',
            tableTypeTicketTcLabel: 'Table Type Ticket Tc Label',
            tableTypeTicketScLabel: 'Table Type Ticket Sc Label',
            isAutoTranslateTableTypeTicketScLabel:
                'Table Type Ticket Label (Auto Translate TC to SC)',
            groupedSectionEnLabel: 'Grouped Section En Label',
            groupedSectionTcLabel: 'Grouped Section Tc Label',
            groupedSectionScLabel: 'Grouped Section Sc Label',
            isAutoTranslateGroupedSectionLabel: 'Grouped Section Label (Auto Translate TC to SC)',
            groupedSectionEnDescription: 'Grouped Section En Description',
            groupedSectionTcDescription: 'Grouped Section Tc Description',
            groupedSectionScDescription: 'Grouped Section Sc Description',
            isAutoTranslateGroupedSectionDescription:
                'Grouped Section Description (Auto Translate TC to SC)',
            displaySequence: 'Display Sequence',
            voiceCode: 'Voice Code',
            kioskRequestTicketStartTime: 'Kiosk Request Ticket Start Time',
            mobileRequestTicketStartTime: 'Mobile Request Ticket Start Time',
            kioskRequestTicketEndTime: 'Kiosk Request Ticket End Time',
            mobileRequestTicketEndTime: 'Mobile Request Ticket End Time',
        },
        groupedSection: {
            editTitle: 'Grouped Section Edit',
            groupedSection: 'Grouped Section',
            groupedSectionEnLabel: 'Grouped Section En Label',
            groupedSectionTcLabel: 'Grouped Section Tc Label',
            groupedSectionScLabel: 'Grouped Section Sc Label',
            isAutoTranslateGroupedSectionLabel: 'Grouped Section Label (Auto Translate TC to SC)',
            groupedSectionEnDescription: 'Grouped Section En Description',
            groupedSectionTcDescription: 'Grouped Section Tc Description',
            groupedSectionScDescription: 'Grouped Section Sc Description',
            isAutoTranslateGroupedSectionDescription:
                'Grouped Section Description (Auto Translate TC to SC)',
        },
        TicketType: {
            mainTitle: 'Ticket Type',
            ticketType: 'Ticket Type Code',
            name: '名称',
            enName: '名称(英文)',
            tcName: '名称(繁体中文)',
            scName: '名称(简体中文)',
            status: '状态',
            editTitle: 'Ticket Type Edit',
            createTitle: 'Ticket Type Create',
            info: '资料',
            foregroundColor: 'Foreground Color',
            backgroundColor: 'Background Color',
            mobileImage: 'Mobile Image',
            kioskImage: 'Kiosk Image',
            ticketTypeMapList: 'Ticket Type Map List',
            queueTemplateCode: 'Queue Template Code',
            restUrlId: 'Rest Url Id',
            restName: 'Restaurant Name',
            queueTemplateTimeSectionLabel: 'Queue Template Time Section Label',
            queueTemplateTableTypeLabel: 'Queue Template Table Type Label',
            queueTemplateTimeSectionId: 'Queue Template Time Section Id',
            queueTemplateTableType: 'Queue Template Table Type',
        },

        QueueTemplateMap: {
            mainTitle: 'Queue Template Map',
            type: '类型',
            addQueueTemplateMap: 'Add Queue Template Map',
            timeSectionList: 'Time Section List',
            timeSection: 'Time Section',
            templateCode: 'Template Code',
            templateDescription: 'Template Description',
            dayOfWeek: '星期几',
            description: '描述',
            startDate: '开始日期',
            endDate: '结束日期',
            date: '日期',
            status: '状态',
            id: 'ID',
            timeSectionId: 'Time Section ID',
            tableType: 'Table Type',
            label: '标签',
            editTitle: 'Queue Template Map Edit',
            addQueueTemplate: 'Add Ticket Time Section',
            createTitle: 'Queue Template Map Create',
            info: '资料',
            createQueueTemplate: 'Create Ticket Time Section',
            Mon: 'Mon',
            Tue: 'Tue',
            Wed: 'Wed',
            Thu: 'Thu',
            Fri: 'Fri',
            Sat: 'Sat',
            Sun: 'Sun',
            PH: 'PH',
            selectAll: '全选',
            clearAll: '移除所有选项',
            weekday: '平日',
            holidays: '假日',
            dateRangeListTitle: 'Date Range List',
            dayOfWeekListTitle: 'Day of Week List',
            queueTemplateForToday: "Today's Queue Template",
        },
        QueueRejectReason: {
            mainTitle: 'Queue Reject Reason',
            reasonCode: 'Reason Code',
            reason: 'Reason',
            status: '状态',
            id: 'id',
            enReason: 'En Reason',
            tcReason: 'Tc Reason',
            scReason: 'Sc Reason',
            info: '资料',
            reasonCustomize: 'Reason (Auto Translate TC to SC)',
            editTitle: 'Queue Reject Reason Edit',
            createTitle: 'Queue Reject Reason Create',
        },
        ReservationConfig: {
            mainTitle: '预约配置',
            remarksEnMessage: '备注信息(英文)',
            remarksTcMessage: '备注信息(繁体中文)',
            remarksScMessage: '备注信息(简体中文)',
            remarksMessageCustomize: '备注信息（自动将繁体翻译成简体）',
            disableEnMessage: '禁用消息(英文)',
            disableTcMessage: '禁用消息(繁体中文)',
            disableScMessage: '禁用消息(简体中文)',
            disableMessageCustomize: '禁用消息（自动将繁体翻译成简体）',
            reminderEnMessage: '提醒消息(英文)',
            reminderTcMessage: '提醒消息(繁体中文)',
            reminderScMessage: '提醒消息(简体中文)',
            reminderMessageCustomize: '提醒消息（自动将繁体翻译成简体）',
            noTimeSectionEnMessage: '无时间段消息(英文)',
            noTimeSectionTcMessage: '无时间段消息(繁体中文)',
            noTimeSectionScMessage: '无时间段消息(简体中文)',
            noTimeSectionMessageCustomize: '无时间段消息（自动将繁体翻译成简体）',
            afterDay: 'After Day',
            reserveDay: '预约日期',
            timeInterval: '时间间隔',
            haveConfirm: '有确认',
            adminNotice: 'Admin Notice',
            showTableNumber: 'Show Table Number',
            maxConcurrentReservation: 'Max Concurrent Reservation',
            showRemarks: '显示备注',
            haveCharge: '有收费',
            chargeType: '收费类型',
            chargeByDefault: '默认收费',
            chargeCode: '收费代码',
            defaultCharge: '默认收费($)',
            chargeEnDescription: '收费描述(英文)',
            chargeTcDescription: '收费描述(繁体中文)',
            chargeScDescription: '收费描述(简体中文)',
            chargeDescriptionCustomize: '收费描述(自动将繁体翻译成简体)',
            startTimestamp: '开始时间',
            expiredTimestamp: '过期时间',
            notificationEmail: '通知邮件',
            sendQrcodeToEmail: '发送二维码到邮箱',
            reserveStatus: '预约状态',
            emailError: '请输入正确的电子邮件格式！',
            defaultChargeError: '请输入正确的默认收费格式！',
            message: '信息',
            charge: '收费',
            other: '其他',
            deliveryType: 'Delivery Type',
            requiredOtp: 'Required OTP',
            mode: 'Mode',
            webShare: 'Web Share',
            editable: 'Editable',
            checkInBeforeMinute: 'Check In Before Minute',
            checkInLateMinute: 'Check In Late Minute',
            webMultiReserve: 'Web Multi Reserve',
            groupedSectionLimit: 'Grouped Section Limit',
        },
        ReservationRejectReason: {
            mainTitle: 'Reservation Reject Reason',
            reasonCode: 'Reason Code',
            reason: 'Reason',
            status: '状态',
            id: 'id',
            enReason: 'En Reason',
            tcReason: 'Tc Reason',
            scReason: 'Sc Reason',
            info: '资料',
            reasonCustomize: 'Reason (Auto Translate TC to SC)',
            editTitle: 'Reservation Reject Reason Edit',
            createTitle: 'Reservation Reject Reason Create',
        },
        ReservationTemplate: {
            mainTitle: 'Reservation Template',
            timeSectionList: 'Time Section List',
            timeSection: 'Time Section',
            templateCode: 'Template Code',
            description: '描述',
            inUse: '使用中',
            status: '状态',
            effectiveDate: '生效日期',
            id: 'ID',
            timeSectionId: 'Time Section ID',
            tableType: 'Table Type',
            label: '标签',
            addRestaurant: '添加餐厅列表',
            addReservationTemplateMap: 'Add Reservation Template Map List',
            resetCurrentTimeSection: 'Reset Current Time Section',
            editTitle: 'Reservation Template Edit',
            addReservationTemplate: 'Add Ticket Time Section',
            createTitle: 'Reservation Template Create',
            info: '资料',
            createReservationTemplate: 'Create Ticket Time Section',
            cloneReservationTemplate: 'Clone Reservation Template: ',
            applyReservationTemplate: 'Apply Reservation Template: ',
        },

        ReservationTemplateMap: {
            mainTitle: 'Reservation Template Map List',
            type: '类型',
            addReservationTemplateMap: 'Add Reservation Template Map List',
            timeSectionList: 'Time Section List',
            timeSection: 'Time Section',
            templateCode: 'Template Code',
            templateDescription: 'Template Description',
            dayOfWeek: '星期几',
            description: '描述',
            startDate: '开始日期',
            endDate: '结束日期',
            date: '日期',
            status: '状态',
            id: 'ID',
            timeSectionId: 'Time Section ID',
            tableType: 'Table Type',
            label: '标签',
            editTitle: 'Reservation Template Map List Edit',
            addReservationTemplate: 'Add Ticket Time Section',
            createTitle: 'Reservation Template Map List Create',
            info: '资料',
            createReservationTemplate: 'Create Ticket Time Section',
        },

        ChargeDate: {
            mainTitle: '收费日期',
            chargeCode: 'Charge Code',
            date: '日期',
            addChargeDate: '添加收费日期',
            startDate: '开始日期',
            endDate: '结束日期',
            id: 'ID',
            editTitle: '收费日期编辑',
            createTitle: '新增收费日期',
            info: '资料',
        },

        AdminDevice: {
            mainTitle: '管理设备',
            troubleShooting: 'Trouble Shooting',
            //AdminDeviceList
            deviceId: '设备ID',
            devicenName: '设备名称',
            activationCode: '激活码',
            deviceType: '设备类型',
            activationStatus: '激活状态',
            deactivate: '停用',
            deactivateSuccess: '停用成功',
            adminDeviceListTitle: '管理设备列表：',
            addAdminDeviceTitle: '添加管理设备： ',
            //AdminDeviceAdd
            kioskLabel: '2 (Kiosk)',
            prLabel: '3 (PR)',
            tvLabel: '5 (TV)',
            backList: '返回餐厅目录',
            //AdminDevice Edit
            info: '资料',
            editTitle: '管理设备编辑',
            reportStatus: '报告',
            takeawayStatus: '外卖',
            queueStatus: '排队',
            preorderStatus: '预购',
            tagStatus: '标签',
            reservationStatus: '保留',
            banquetStatus: '宴会',
            ecouponStatus: '环保',
            menuStatus: '菜单',
            ecouponDistributeStatus: '分发 Ecoupon',
            createTitle: '新增管理设备',
            confirmDeactivate: '确认停用管理设备？',
        },

        CampaignDescription: {
            mainTitle: '信息显示',
            //CampaignDescriptionList
            type: '类型',
            messageCode: 'Message Code',
            title: '标题',
            enTitle: '标题(英文)',
            tcTitle: '标题(繁体中文)',
            scTitle: '标题(简体中文)',
            sequence: '序列',
            status: '状态',
            message: '信息',
            tcMessage: '信息(繁体中文)',
            enMessage: '信息(英文)',
            scMessage: '信息(简体中文)',
            titleCustomize: '标题（自动将繁体翻译成简体）',
            messageCustomize: '信息（自动将繁体翻译成简体）',
            restUrlId: 'Rest Url ID',
            info: '资料',
            editTitle: '信息显示编辑',
            createTitle: '新增信息显示',
        },

        TnC: {
            mainTitle: '条款和条件',
            //Terms & ConditionsList
            type: '类型',
            enDescription: '描述(英文)',
            tcDescription: '描述(繁体中文)',
            scDescription: '描述(简体中文)',
            status: '状态',
            descriptionCustomize: '描述（自动将繁体翻译成简体）',
            restUrlId: 'Rest Url ID',
            info: '资料',
            editTitle: '条款和条件编辑',
            createTitle: '新增条款和条件',
            filterKey: '关键字',
        },

        EmailTemplate: {
            mainTitle: '电子邮件模板',
            //EmailTemplateList
            restUrlId: 'Rest Url Id',
            type: '类型',
            receiver: '接收人',
            subject: '主题',
            enSubject: '主题(英文)',
            tcSubject: '主题(繁体中文)',
            scSubject: '主题(简体中文)',
            subjectCustomize: '主题（自动将繁体翻译成简体）',
            status: '状态',
            htmlContent: 'HTML内容',
            htmlEnContent: 'HTML内容(英文)',
            htmlTcContent: 'HTML内容(繁体中文)',
            htmlScContent: 'HTML内容(简体中文)',
            htmlContentCustomize: 'HTML内容（自动将繁体翻译成简体）',
            plainTextContent: '纯文本内容',
            plainTextEnContent: '纯文本内容(英文)',
            plainTextTcContent: '纯文本内容(繁体中文)',
            plainTextScContent: '纯文本内容(简体中文)',
            plainTextContentCustomize: '纯文本内容（自动将繁体翻译成简体）',
            addReceiver: 'Add Receiver',
            removeReceiver: 'Remove Receiver',
            info: '资料',
            editTitle: '电子邮件模板编辑',
            createTitle: '新增电子邮件模板',
        },

        PrintTemplate: {
            mainTitle: '打印模板',
            //EmailTemplateList
            id: 'Id',
            code: 'Code',
            type: '类型',
            status: '状态',
            templateLabels: '模板标签',
            template: '模板',
            value: 'Value',
            addTemplate: '添加模板',
            addText: 'Add Text',
            addDateFormat: 'Add Date Format',
            addTemplateLabel: '添加模板标签',
            labelTitle: '标签标题',
            labelEn: '标题(英文)',
            labelTc: '标题(繁体中文)',
            labelSc: '标题(简体中文)',
            year: '年',
            month: '月',
            date: '日',
            hour: '小时',
            minute: '分钟',
            seconds: '秒',
            timeFormat: '时间格式',
            time: '时间',
            week: '周',
            middleSymbol: '中间符号',
            withBracket: '带括号',
            info: '资料',
            editTitle: '打印模板编辑',
            createTitle: '新增模板编辑',
            addLabel: 'Add Label',
            printTemplateSection: 'Print Template Section',
            kioskSection: 'Kiosk Section',
            shopAppSection: 'Shop App Version 1.1.8 (46)+',
        },

        restaurant_map: {
            mainTitle: 'Restaurant Map List',
            //restaurant_map
            tagCode: '标签代码',
            name: '餐厅名称',
            restName: '餐厅名称',
            restUrlId: 'Rest Url ID',
            status: 'Restaurant Map状态',
            enName: '餐厅名称(英文)',
            tcName: '餐厅名称(繁体中文)',
            scName: '餐厅名称(简体中文)',
            orderSequence: '订单序列',
            restaurantMapList: '餐厅列表',
            addRestaurantMap: '添加餐厅',
            restEnName: '餐厅名称(英文)',
            restTcName: '餐厅名称(繁体中文)',
            restScName: '餐厅名称(简体中文)',
            startTimestamp: '开始时间',
            endTimestamp: '结束时间',
            sequence: '序列',
            nameCustomize: '名称（自动将繁体翻译成简体）',
            tcPictures: '图片(繁体中文)',
            enPictures: '图片(英文)',
            scPictures: '图片(简体中文)',
            editTitle: 'Restaurant Map编辑',
            info: '资料',
            createTitle: 'Restaurant Map新增',
        },

        restaurant_category: {
            mainTitle: '类别',
            //restaurantCategoryList
            tagCode: '标签代码',
            name: '名称',
            restName: '餐厅名称',
            restUrlId: 'Rest Url ID',
            status: '状态',
            enName: '名称(英文)',
            tcName: '名称(繁体中文)',
            scName: '名称(简体中文)',
            orderSequence: '订单序列',
            restaurantMapList: '餐厅列表',
            addRestaurantMap: '添加餐厅',
            restEnName: '餐厅名称(英文)',
            restTcName: '餐厅名称(繁体中文)',
            restScName: '餐厅名称(简体中文)',
            startTimestamp: '开始时间',
            endTimestamp: '结束时间',
            sequence: '序列',
            nameCustomize: '名称（自动将繁体翻译成简体）',
            tcPictures: '图片(繁体中文)',
            enPictures: '图片(英文)',
            scPictures: '图片(简体中文)',
            editTitle: '类别编辑',
            info: '资料',
            createTitle: '类别新增',
        },

        restaurant_cuisine: {
            mainTitle: '菜式',
            //restaurantCuisineList
            tagCode: '标签代码',
            name: '名称',
            restName: '餐厅名称',
            restUrlId: 'Rest Url ID',
            status: '状态',
            enName: '名称(英文)',
            tcName: '名称(繁体中文)',
            scName: '名称(简体中文)',
            orderSequence: '订单序列',
            restaurantMapList: '餐厅列表',
            addRestaurantMap: '添加餐厅',
            restEnName: '餐厅名称(英文)',
            restTcName: '餐厅名称(繁体中文)',
            restScName: '餐厅名称(简体中文)',
            startTimestamp: '开始时间',
            endTimestamp: '结束时间',
            sequence: '序列',
            nameCustomize: '名称（自动将繁体翻译成简体）',
            tcPictures: '图片(繁体中文)',
            enPictures: '图片(英文)',
            scPictures: '图片(简体中文)',
            editTitle: '菜式编辑',
            info: '资料',
            createTitle: '菜式新增',
        },

        restaurant_keyword: {
            mainTitle: '关键词',
            //restaurantKeywordList
            tagCode: '标签代码',
            name: '名称',
            restName: '餐厅名称',
            restUrlId: 'Rest Url ID',
            status: '状态',
            enName: '名称(英文)',
            tcName: '名称(繁体中文)',
            scName: '名称(简体中文)',
            orderSequence: '订单序列',
            restaurantMapList: '餐厅列表',
            addRestaurantMap: '添加餐厅',
            restEnName: '餐厅名称(英文)',
            restTcName: '餐厅名称(繁体中文)',
            restScName: '餐厅名称(简体中文)',
            startTimestamp: '开始时间',
            endTimestamp: '结束时间',
            sequence: '序列',
            nameCustomize: '名称（自动将繁体翻译成简体）',
            tcPictures: '图片(繁体中文)',
            enPictures: '图片(英文)',
            scPictures: '图片(简体中文)',
            editTitle: '关键词编辑',
            info: '资料',
            createTitle: '关键词新增',
        },

        BrandGroup: {
            mainTitle: '餐饮集团',
            groupId: '集团ID',
            enName: '集团名称(英文)',
            tcName: '集团名称(繁体中文)',
            scName: '集团名称(简体中文)',
            status: '状态',
            update_timestamp: '更新时间',
            //BrandGroupList
            BrandGroupListTitle: '餐饮集团列表：',
            addBrandGroupTitle: '添加餐饮集团： ',
            group_name: '集团名称',
            //BrandGroup Edit
            editTitle: '编辑餐饮集团',
            nameCustomize: '名称（自动将繁体翻译成简体）',
            createTitle: '新增餐饮集团',
            info: '资料',
        },

        Brand: {
            mainTitle: '餐饮品牌',
            brandCode: '品牌ID',
            enName: '品牌名称(英文)',
            tcName: '品牌名称(繁体中文)',
            scName: '品牌名称(简体中文)',
            enDescription: '详细资料(英文)',
            tcDescription: '详细资料(繁体中文)',
            scDescription: '详细资料(简体中文)',
            descriptionCustomize: '详细资料（自动将繁体翻译成简体）',
            groupId: '集团ID',
            groupEnName: '集团名称(英文)',
            groupTcName: '集团名称(繁体中文)',
            groupScName: '集团名称(简体中文)',
            logoImage: 'Logo Image',
            uploadImage: '上传图片',
            imageList: '图片列表',
            type: '类型',
            cosFileUrl: '媒体',
            fileType: '文件类型',
            displaySequence: '显示顺序',
            status: '状态',
            update_timestamp: '更新时间',
            //BrandList
            BrandListTitle: '餐饮品牌列表：',
            addBrandTitle: '添加品牌： ',
            brand_name: '品牌名称',
            //Brand Edit
            editTitle: '编辑餐饮品牌',
            nameCustomize: '名称（自动将繁体翻译成简体）',
            createTitle: '新增餐饮品牌',
            info: '资料',
        },

        BrandProduct: {
            mainTitle: '品牌产品',
            productCode: 'Product Code',
            type: '类型',
            name: '名称',
            channel: '频道',
            merchantProduct: 'Merchant Product',
            status: '状态',
        },

        Folder: {
            mainTitle: '文件夹',
            folderId: '文件夹 Id',
            folderCode: '文件夹代码',
            type: '文件夹类型',
            name: '文件夹名称',
            enName: '文件夹名称(英文)',
            tcName: '文件夹名称(繁体中文)',
            scName: '文件夹名称(简体中文)',
            enDescriptionHtml: 'Html描述(英文)',
            tcDescriptionHtml: 'Html描述(繁体中文)',
            scDescriptionHtml: 'Html描述(简体中文)',
            descriptionCustomize: '描述（自动将繁体翻译成简体）',
            tcImage: '图像(繁体中文)',
            enImage: '图像(英文)',
            scImage: '图像(简体中文)',
            status: '文件夹状态',
            editTitle: '编辑文件夹',
            folderMapList: 'Folder Map List',
            bannerList: '横幅列表',
            folderBannerList: '文件夹横幅列表',
            landmarkOrder: 'Landmark Order',
            createTitle: '新增文件夹',
            info: '资料',
            addFolderMap: 'Add Folder',
            showHidden: 'Show Hidden',
            regionName: 'Region Name',
            cityName: 'City Name',
        },

        FolderMap: {
            mainTitle: 'Folder Map List',
            folderId: 'Folder Map Id',
            refCode: 'Folder Map Ref Code',
            type: 'Folder Map Type',
            name: '文件夹/餐厅名称',
            refEnName: '文件夹/餐厅名称(英文)',
            refTcName: '文件夹/餐厅名称(繁体中文)',
            refScName: '文件夹/餐厅名称(简体中文)',
            sequence: '顺序',
            status: 'Folder Map List Status',
            editTitle: 'Folder Map List Edit',
            createTitle: 'Folder Map List Create',
            info: '资料',
        },

        FolderBanner: {
            mainTitle: '横幅',
            id: 'ID',
            refCode: 'Ref Code',
            tcImage: '横幅图像(繁体中文)',
            enImage: '横幅图像(英文)',
            scImage: '横幅图像(简体中文)',
            enName: '横幅名称(英文)',
            tcName: '横幅名称(繁体中文)',
            scName: '横幅名称(简体中文)',
            name: '横幅名称',
            type: '横幅类型',
            webViewType: '类型',
            actionType: 'Action Type',
            action: 'Action',
            productCode: 'Product Code',
            folderCode: 'Folder Code',
            folderType: 'Folder Type',
            restUrlId: 'Rest Url Id',
            actionParameters: 'Action Parameters',
            addParameter: 'Add Parameter',
            otherParameterName: 'Other Parameter Name',
            otherParameterValue: 'Other Parameter Value',
            startDisplayDate: '开始显示日期',
            endDisplayDate: '结束显示日期',
            sequence: '序列',
            status: '横幅状态',
            pixelName: 'Pixel Name',
            haveRef: 'Pixel Have Ref',
            imageError: '**横幅大小不相等！**',
            from: '来自',
            title: '标题',
            query: 'Query',
            editTitle: '编辑横幅',
            createTitle: '新增横幅',
            info: '资料',
            addFolderBanner: '新增文件夹横幅',
        },

        Landing: {
            mainTitle: '登陆页面',
            landingBannerList: '登陆页面横幅列表',
        },

        Ticket: {
            mainTitle: '取票',
            ticketBannerList: '取票横幅列表',
        },

        Slideshow: {
            mainTitle: '幻灯片',
            all_slideshow: '幻灯片',
            default_slideshow: 'GULU默认',
        },

        ConfigMap: {
            mainTitle: '配置',
            serviceType: '服务类型',
            ticketStatus: '票务状态',
            restUrlId: 'Rest Url Id',
            clearRestUrlId: 'Clear Rest Url Id',
            restName: '餐厅名称',
            restTcName: '餐厅名称(繁体中文)',
            restEnName: '餐厅名称(英文)',
            restScName: '餐厅名称(简体中文)',
            channel: '频道',
            actionType: 'Action Type',
            startDisplayDate: '开始显示日期',
            endDisplayDate: '结束显示日期',
            status: '状态',
            editTitle: '编辑配置',
            configMapList: '配置列表',
            createTitle: '新增配置列表',
            addConfigMap: '新增配置',
            info: '资料',
            addRestaurant: 'Add Restaurant',
        },

        Banner: {
            mainTitle: '横幅',
            id: 'ID',
            bannerId: 'Banner Id',
            refCode: 'Ref Code',
            tcImage: '横幅图像(繁体中文)',
            enImage: '横幅图像(英文)',
            scImage: '横幅图像(简体中文)',
            enName: '横幅名称(英文)',
            tcName: '横幅名称(繁体中文)',
            scName: '横幅名称(简体中文)',
            name: '名称',
            type: '横幅类型',
            webViewType: '类型',
            actionType: 'Action Type',
            action: 'Action',
            productCode: 'Product Code',
            categoryCode: 'Category Code',
            brandCode: 'Brand Code',
            folderCode: 'Folder Code',
            folderType: 'Folder Type',
            restUrlId: 'Rest Url Id',
            actionParameters: 'Action Parameters',
            addParameter: 'Add Parameter',
            otherParameterName: 'Other Parameter Name',
            otherParameterValue: 'Other Parameter Value',
            startDisplayDate: '开始显示日期',
            endDisplayDate: '结束显示日期',
            sequence: '序列',
            status: '横幅状态',
            pixelName: 'Pixel Name',
            haveRef: 'Pixel Have Ref',
            imageError: '**Banners size are not equal!**',
            from: 'From',
            title: 'Title',
            query: 'Query',
            editTitle: '编辑横幅',
            createTitle: '新增横幅',
            info: '资料',
            addBanner: '新增横幅',
            productParent: '选择品牌/类别',
            eCouponParent: '选择E-COUPON品牌/类别',
            ecouponCode: 'E-Coupon ',
            deactivate: 'Delete',
            pending: 'Pending',
            active: 'Active',
            cityName: 'City Name',
            regionName: 'Region Name',
        },

        MerchantConfig: {
            mainTitle: '商户配置',
            queue: '排队',
            queueRemindResetQuota: '提醒排队重置配额',
            queueChannelStatusControl: '排队频道状态控制',
            queueQuickCheckIn: '排队快速报到',
            product: '产品',
            publishProduct: '发布产品',
            createProduct: '新增产品',
            sendProduct: '发送产品',
            counterCallEnable: 'Counter Call Enable',
            queueRejectTicketEnable: 'Queue Reject Ticket Enable',
            queueStatusPerQueueControl: 'Queue Status Per Queue Control',
            queueMultipleStatusControl: 'Queue Multiple Status Control',
            queueDisplayControl: 'Queue Display Control',
            slideshow: '幻灯片',
            guluSlideshowEnable: '使用GULU幻灯片',
            device: '设备',
            customPassword: '自定义密码',
            shopAppSoundSpeed: 'Shop App 语速',
            voiceCode: '声音代号',
            clearSoundFiles: '清除音档',
            preorderProduct: 'Pre-order Product',
        },
        MerchantDevice: {
            mainTitle: 'Device',
            deviceType: 'Device Type',
            deviceName: 'Device Name',
            appVersion: '应用版本',
            activationStatus: 'Activation Status',
            info: '资料',
            editTitle: 'Device Edit',
            appId: 'App ID',
            deviceIp: 'Device IP',
            deviceModel: 'Device Model',
            manufacturer: 'Manufacturer',
            os: 'OS',
            osId: 'OS ID',
            pushNotificationToken: 'Push Notification Token',
        },
        DeviceLog: {
            mainTitle: 'Device Log',
            uploadTimestamp: 'Upload Timestamp',
            appVersion: 'App Version',
            osVersion: 'OS Version',
            status: '状态',
            fileUrl: 'File URL',
        },
        QueueProfile: {
            mainTitle: 'Queue Profile',
            profileCode: 'Profile Code',
            tcName: 'Tc name',
            enName: 'En name',
            scName: 'Sc name',
            name: 'name',
            createTitle: 'Queue Profile Create',
            refTemplateCode: 'Template Code',
            timeSectionId: 'Time Section Id',
            tableType: 'Table Type',
            timeSectionList: 'Time Section List',
            status: '状态',
            editTitle: 'Queue Profile Edit',
        },

        QueueTransferProfile: {
            mainTitle: 'Queue Transfer Profile',
            profileCode: 'Profile Code',
            tcName: 'Tc name',
            enName: 'En name',
            scName: 'Sc name',
            name: 'name',
            createTitle: 'Queue Transfer Profile Create',
            refTemplateCode: 'Template Code',
            timeSectionId: 'Time Section Id',
            tableType: 'Table Type',
            timeSectionList: 'Time Section List',
            status: '状态',
            editTitle: 'Queue Transfer Profile Edit',
        },

        SoundTemplate: {
            mainTitle: 'Sound Template',
            soundTemplateCode: 'Sound Template Code',
            name: 'Name',
            createTitle: 'Sound Template Create',
            editTitle: 'Sound Template Edit',
            status: '状态',
            restUrlId: 'Rest Url Id',
            code: 'Code',
        },

        Counter: {
            mainTitle: 'Counter',
            createTitle: 'Counter Create',
            editTitle: 'Counter Edit',
            name: '名称',
            soundTemplateCode: 'Sound Template Code',
            status: '状态',
            soundTemplateCodeRestUrlId: 'Sound Template Code Rest Url Id',
            enDescription: 'En Description',
            tcDescription: 'Tc Description',
            scDescription: 'Sc Description',
        },

        CounterProfile: {
            mainTitle: 'Counter Profile',
            profileCode: 'Profile Code',
            tcName: 'Tc name',
            enName: 'En name',
            scName: 'Sc name',
            name: 'name',
            createTitle: 'Counter Profile Create',
            refTemplateCode: 'Template Code',
            timeSectionId: 'Time Section Id',
            tableType: 'Table Type',
            timeSectionList: 'Time Section List',
            status: '状态',
            editTitle: 'Counter Profile Edit',
            refCounterList: 'Ref Counter List',
            counterSequence: 'Counter Sequence',
            selectCounter: 'Select Counter',
            addCounter: 'Add Counter',
        },

        MerchantUser: {
            mainTitle: '商户用户',
            userRole: '用户角色',
            username: '用户名',
            password: '密码',
            newPassword: '新密码',
            email: '电子邮件',
            channel: '频道',
            enabled: '启用',
            enableMultiLogin: '启用多重登录',
            restUrlIdList: 'RestUrlId 列表',
            permission: '权限',
            reportPermission: '报告',
            deviceManagementPermission: '设备管理',
            featurePermission: '功能',
            restaurantManagementPermission: '餐厅管理',
            userGroupManagementPermission: '用户组管理',
            read: 'Read',
            write: 'Write',
            editTitle: '编辑商家用户',
            info: '资料',
            createTitle: '新增商家用户',
            emailError: '请输入正确的电子邮件格式！',
            usernameUpperCaseError: '不支持大写字母！',
            usernameLengthError: '用户名长度应小于45个字母！',
            privilegeAttribute: 'Privilege Attribute',
            privilegeRestUrlId: 'Privilege Rest Url Id',
            privilegeGroupCode: 'Privilege Group Code',
            merchantRole: 'Merchant Role',
            merchantUserList: 'Merchant User List',
            userGroupCode: 'User Group Code',
            changeUsername: '更改用户名称',
        },

        MerchantSalesUser: {
            mainTitle: '商戶銷售用戶',
            username: '用户名',
            password: '密码',
            info: '资料',
            createTitle: '新增商家銷售用户',
            usernameUpperCaseError: '不支持大写字母！',
            usernameLengthError: '用户名长度应小于45个字母！',
        },

        MerchantDraft: {
            mainTitle: 'Merchant Create',
            //merchantDraftList
            draftName: '草稿名稱',
            status: '狀態',
            draftVersion: '草稿版本',
            name: '名稱',
            enName: '名稱(英文)',
            tcName: '名稱(繁體中文)',
            scName: '名稱(簡體中文)',
            nameCustomize: '名稱（自動將繁體翻譯成簡體）',
            tcAddress: '地址(繁體中文)',
            enAddress: '地址(英文)',
            scAddress: '地址(簡體中文)',
            addressCustomize: '地址（自動將繁體翻譯成簡體）',
            phoneAddress: '電話及地址',
            phone: '電話',
            shopLogo: 'Logo Image',
            shopFrontImage: 'Shop Front Image',
            shopFrontImageList: 'Shop Front Image List',
            br: 'BR',
            planCode: 'Plan Code',
            servicePlan: '服務計劃',
            shopInfo: '店鋪信息',
            image: '圖像',
            uploadImage: '上傳圖片',
            enterDraftName: '輸入草稿名稱',
        },

        Area: {
            mainTitle: '区域',
            areaId: '区域Id',
            name: '区域名称',
            enName: '区域名称(英文)',
            tcName: '区域名称(繁体中文)',
            scName: '区域名称(简体中文)',
            status: '区域状态',
            editTitle: '区域编辑',
            info: '资料',
            districtList: '地区列表',
            landmarkOrder: '订单顺序',
            createTitle: '区域新增',
        },

        RestaurantDistrict: {
            mainTitle: '地区',
            hk: '香港岛 (1)',
            kln: '九龙 (2)',
            nt: '新界 (3)',
            island: '离岛 (4)',
            area: '区域',
            areaId: '区域ID',
            name: '地区名称',
            status: '地区状态',
            districtStatus: '地区状态',
            districtId: '地区ID',
            enName: '地区名称(英文)',
            tcName: '地区名称(繁体中文)',
            scName: '地区名称(简体中文)',
            districtTcName: '地区名称(繁体中文)',
            districtEnName: '地区名称(英文)',
            districtScName: '地区名称(简体中文)',
            areaTcName: '区域名称(繁体中文)',
            areaEnName: '区域名称(英文)',
            areaScName: '区域名称(简体中文)',
            addDistrictMap: '添加地区',
            //RestaurantDistrictList
            RestaurantDistrictListTitle: '地区列表：',
            addRestaurantDistrictTitle: '添加地区： ',
            district_name: '地区名称',
            //RestaurantDistrict Edit
            editTitle: '地区编辑',
            info: '资料',
            restaurantMapList: '餐厅列表',
            landMarkMapList: '地标列表',
            districtLevel: '地区级别',
            menuChiOrder: '菜单顺序',
            latitude: '纬度',
            longitude: '经度',
            nameCustomize: '名称（自动将繁体翻译成简体）',
            createTitle: '地区新增',
                        regionName: 'Region Name',
            cityName: 'City Name',
            cityCode: 'City Code',
            city: 'City'
        },

        //RestaurantDistrictRestMap
        RestaurantDistrictRestMap: {
            mainTitle: '餐厅列表',
            restUrlId: 'Rest Url ID',
            restRefId: 'Ref ID',
            name: '餐厅名称',
            restTcName: '餐厅名称(繁体中文)',
            restEnName: '餐厅名称(英文)',
            restScName: '餐厅名称(简体中文)',
            address: '地址',
            restStatus: '餐厅状态',
        },

        //RestaurantLandmark
        RestaurantLandmark: {
            mainTitle: '地标列表',
            landmarkId: '地标Id',
            tcName: '地标名称(繁体中文)',
            enName: '地标名称(英文)',
            scName: '地标名称(简体中文)',
            landmarkTcName: '地标名称(繁体中文)',
            landmarkEnName: '地标名称(英文)',
            landmarkScName: '地标名称(简体中文)',
            name: '地标名称',
            status: '地标状态',
            districtList: '地区列表',
            restaurantList: '餐厅列表',
            editTitle: '地标编辑',
            landmarkOrder: '地标顺序',
            favorite: '最喜欢的',
            createTitle: '地标新增',
            info: '资料',
        },

        //RestaurantDistrictMap
        RestaurantDistrictMap: {
            mainTitle: 'District Map List',
            districtId: '地区ID',
            enName: '地区名称(英文)',
            tcName: '地区名称(繁体中文)',
            scName: '地区名称(简体中文)',
            status: '状态',
            districtstatus: '地区状态',
            addDistrictMap: '添加地区',
            //RestaurantDistrictMap Edit
            editTitle: '地区编辑',
            info: '资料',
            createTitle: '地区新增',
            addRestaurantMapList: '添加餐厅',
        },

        //UploadImage
        UploadImage: {
            mainTitle: '上传图片',
            imageAdd: '新增图片',
            image: '图片',
            recordId: 'Record ID',
            tablenName: 'Table Name',
            fieldName: 'Field Name',
            pressUploadImage: '按这里上传图片',
            relatedPictures: '相关图片',
            dropFile: '按此上载图片',
            Adv: 'Advance',
        },

        //push
        Push: {
            mainTitle: 'Push',
            name: '名称',
            messageTitle: '信息标题',
            message: '信息',
            messageTitleCustomize: '信息标题（自动将繁体翻译成简体）',
            messageCustomize: '信息（自动将繁体翻译成简体）',
            tcMessageTitle: '信息标题(繁体中文)',
            enMessageTitle: '信息标题(英文)',
            scMessageTitle: '信息标题(简体中文)',
            enMessage: '信息(英文)',
            tcMessage: '信息(繁体中文)',
            scMessage: '信息(简体中文)',
            type: '种类',
            actionType: 'Action Type',
            startDeliveryDate: '开始发送日期',
            endDeliveryDate: '结束发送日期',
            deliveryDate: '发送日期',
            deliveryDateWarning: '由于发送日期早于今日日期，发送将设置为现在。 你还确认发布吗？',
            messageGen: '讯息加载状态',
            sendWarning: 'Should set one of the field as true!',
            notification: '发送到通知',
            inbox: '发送到收件箱',
            enterMessageContent: 'Behaviour',
            actionParameters: 'Action Parameters',
            addParameter: 'Add Parameter',
            otherParameterName: 'Other Parameter Name',
            otherParameterValue: 'Other Parameter Value',
            filterParameters: 'Filter Parameters',
            messageContent: 'Behaviour',
            enterParameter: 'Parameter',
            query: 'Query',
            gender: '性别',
            female: '女',
            male: '男',
            all: '全部',
            os: 'OS',
            iOS: 'iOS',
            Android: 'Android',
            GMS: 'GMS (Google)',
            HMS: 'HMS (华为)',
            ageGroupList: '年龄',
            addMobile: '添加手机号码',
            mobileList: '手机号码',
            enterservice: 'Type',
            ticket: '取票',
            takeaway: '外卖',
            reservation: '预约',
            social_media: '社交媒体',
            start_date: '开始日期',
            end_date: '结束日期',
            consume: '消费',
            times: '消费次数',
            Header: '资讯',
            parameterHeader: 'Condition',
            parameter: 'Parameter',
            promotion: '推广',
            webView: '网页浏览',
            deepLink: '连结',
            AdsCode: 'Ads Code',
            website: '网站',
            restaurant: '餐厅',
            search: '搜索页',
            brandCode: 'Brand Code',
            folderEngName: 'Folder名称(英文)',
            folderTCName: 'Folder名称(繁体中文)',
            folder: 'Folder',
            folderName: 'Folder名称',
            folderCode: 'Folder Code',
            folderType: 'Folder Type',
            restUrlId: 'Rest Url Id',
            pixelName: 'Pixel Name',
            haveRef: 'Pixel Have Ref',
            categoryCode: 'Category Code',
            productCat: '产品分类',
            productCatName: '产品分类名称',
            productCatEngName: '产品分类名称(英文)',
            productCatTCName: '产品分类名称(繁体中文)',
            productCatCode: '产品分类Code',
            productDetail: '产品详情',
            productDetailName: '产品详情名称',
            productDetailEngName: '产品详情名称(英文)',
            productDetailTCName: '产品详情名称(繁体中文)',
            productCode: '产品详情Code',
            ECoupon: 'ECoupon',
            ECouponTitle: 'ECoupon 标题',
            ECouponEngTitle: 'ECoupon标题(英文)',
            ECouponTCTitle: 'ECoupon标题(繁体中文)',
            ECouponCode: 'ECoupon Code',
            from: 'From',
            action: 'Action',
            systemMessage: '系统讯息',
            status: '状态',
            draft: '草稿',
            testing: '测试',
            scheduled: '预定',
            sending: '发送中',
            finished: '完成',
            sql: 'SQL',
            sqlButton: 'Preview',
            Adv: 'Advance',
            total: 'Total',
            title: 'Title',
            editTitle: 'Push编辑',
            ClearCat: '清除产品分类',
            ClearDetail: '清除产品详情',
            createTitle: 'Push新增',
            info: '资料',
            productParent: '选择品牌/类别',
            eCouponParent: '选择E-COUPON品牌/类别',
            ecouponCode: 'E-Coupon ',
        },

        //Select Tag
        SelectTag: {
            mainTitle: 'Select Tag',
            name: '名称',
            messageTitle: '信息标题',
            message: '信息',
            messageTitleCustomize: '信息标题（自动将繁体翻译成简体）',
            messageCustomize: '信息（自动将繁体翻译成简体）',
            tcMessageTitle: '信息标题(繁体中文)',
            enMessageTitle: '信息标题(英文)',
            scMessageTitle: '信息标题(简体中文)',
            enMessage: '信息(英文)',
            tcMessage: '信息(繁体中文)',
            scMessage: '信息(简体中文)',
            type: '种类',
            tag_code: 'Tag Code',
            select_tag_name: 'Select Tag 名称',
            enName: '名称(英文)',
            tcName: '名称(繁体中文)',
            scName: '名称(简体中文)',
            editTitle: '编辑Select Tag',
            info: '资料',
            createTitle: '新增Select Tag',
            tag_name: '名称',
            tagCode: 'Tag Code',
            addSelectTag: '添加 Select Tag',
            status: '状态',
        },

        SelectTagConfig: {
            mainTitle: 'Select Tag 配置',
            tag_name: '名称',
            tagEnName: '名称(英文)',
            tagTcName: '名称(繁体中文)',
            tagScName: '名称(简体中文)',
            select_tag_name: 'Select Tag 名称',
            tagCode: 'Tag Code',
            status: '状态',
            tag_code: 'Tag Code',
            sequence: '序列',
            restUrlId: 'Rest Url ID',
            id: 'ID',
            type: '类型',
            info: '资料',
            editTitle: '编辑Select Tag',
            createTitle: '新增Select Tag',
            addSelectTag: '添加 select Tag',
        },

        //LandingSection
        LandingSection: {
            mainTitle: '编辑Landing Section',
            editTitle: '编辑Landing Section',
            createTitle: 'Landing Section Create',
            title: '标题',
            info: '资料',
            serviceType: '服务类型',
            mainSection: 'Main Section',
            sectionType: 'Section Type',
            tcTitle: '标题(繁体中文)',
            enTitle: '标题(英文)',
            scTitle: '标题(简体中文)',
            titleColor: '标题颜色',
            startDisplayTimestamp: '开始显示时间',
            endDisplayTimestamp: '结束显示时间',
            sequence: '序列',
            status: '状态',
            action: 'Action Type',
            displayActionType: 'Action Type',
            actionParameters: 'Action Parameters',
            itemType: 'Item Type',
            tcSubTitle: '副标题(繁体中文)',
            enSubTitle: '副标题(英文)',
            scSubTitle: '副标题(简体中文)',
            serviceTypeList: '服务类型列表',
            foregroundColor: '前景色',
            backgroundColor: '背景色',
            addItem: '添加项目',
            addSection: '添加Section',
            url: 'URL',
            addParameter: '添加Parameter',
            type: '类型',
            query: 'Query',
            name: '名称',
            folderType: 'Folder Type',
            brandCode: '品牌代码',
            categoryCode: '类别代码',
            ecouponCode: 'Ecoupon代码',
            couponCode: 'Coupon代码',
            folderCode: 'Folder代码',
            //showCategoryList: "显示类别列表",
            productCode: '产品代码',
            restUrlId: 'restUrl Id',
            from: 'From',
            showCategoryBrandList: 'Show Category/Brand List',
            refId: 'Ref Id',
            subAction: 'Action',
            ticketId: 'Ticket Id',
            infoId: 'Info Id',
            groupCode: 'Group Code',
            code: 'Code',
            nameCustomize: '名称（自动将繁体翻译成简体）',
            nameCustomizeSubTitle: '名称（自动将繁体翻译成简体）',
            tcImage: '图像(繁体中文)',
            enImage: '图像(英文)',
            scImage: '图像(简体中文)',
            channel: 'Channel',
            sequenceAfter: '序列 (往后插入)',
            more: '更多',
            freeCollect: '免费领取',
            youtubeCode: 'Youtube Code',
            tagCode: 'Tag Code',
            copyDataFrom: '复制信息',
            publishing: 'View Only Publishing Item',
            city: 'City',
            region: 'Region',
        },

        // Landing Item
        LandingItem: {
            mainTitle: 'Landing Item Edit',
            editTitle: 'Landing Item Edit',
            createTitle: 'Landing Section Create',
            title: 'title',
            info: 'Info',
            serviceType: 'Service Type',
            mainSection: 'Main Section',
            sectionType: 'Section Type',
            tcTitle: 'Tc Title',
            enTitle: 'En Title',
            scTitle: 'Sc Title',
            titleColor: 'Title Color',
            startDisplayTimestamp: 'Start Display Time',
            endDisplayTimestamp: 'End Display Time',
            sequence: 'Sequence',
            status: 'Status',
            action: 'Action Type',
            displayActionType: 'Action Type',
            actionParameters: 'Action Parameters',
            itemType: 'Item Type',
            tcSubTitle: 'Tc Sub Title',
            enSubTitle: 'En Sub Title',
            scSubTitle: 'Sc Sub Title',
            serviceTypeList: 'Service Type List',
            foregroundColor: 'Foreground Color',
            backgroundColor: 'Background Color',
            addItem: 'Add Item',
            addSection: 'Add Section',
            url: 'URL',
            addParameter: 'Add Parameter',
            type: 'Type',
            query: 'Query',
            name: 'Name',
            folderType: 'Folder Type',
            brandCode: 'Brand Code',
            categoryCode: 'Category Code',
            productParent: 'Product Parent',
            ecouponCode: 'Ecoupon Code',
            couponCode: 'Coupon Code',
            folderCode: 'Folder Code',
            showCategoryList: 'Show Category List',
            productCode: 'Product Code',
            restUrlId: 'restUrl Id',
            from: 'From',
            showCategoryBrandList: 'Show Category/Brand List',
            refId: 'Ref Id',
            subAction: 'Action',
            ticketId: 'Ticket Id',
            infoId: 'Info Id',
            groupCode: 'Group Code',
            code: 'Code',
            nameCustomize: 'Name (Auto Translate TC to SC)',
            nameCustomizeSubTitle: 'Name (Auto Translate TC to SC)',
            tcImage: 'Tc Image',
            enImage: 'En Image',
            scImage: 'Sc Image',
            channel: 'Channel',
            sequenceAfter: 'Sequence (Insert After)',
            more: 'More',
            freeCollect: 'Free Collect',
            youtubeCode: 'Youtube Code',
            tagCode: 'Tag Code',
            copyDataFrom: 'Copy Data From',
            publishing: 'View Only Publishing Item',
        },

        brandApprove: {
            mainTitle: 'Brand Approve',
            restUrlId: 'Rest Url ID',
            name: '品牌名称',
            enName: '品牌名称(英文)',
            tcName: '品牌名称(繁体中文)',
            scName: '品牌名称(简体中文)',
            enDescription: '详细资料(英文)',
            tcDescription: '详细资料(繁体中文)',
            scDescription: '详细资料(简体中文)',
            logoImage: 'Logo Image',
            status: '状态',
            imageList: '图像列表',
            rejectReason: '拒绝原因',
            rejectReasonTip: '(如果要按下拒绝按钮，请先填写拒绝原因!)',
            editTitle: 'Brand Approve Edit',
            createTitle: 'Brand Approve Create',
            info: '资料',
        },

        restaurantApprove: {
            mainTitle: '餐厅',
            channel: '频道',
            restUrlId: 'Rest Url ID',
            email: '电子邮件',
            salesName: '销售员名称',
            salesCreateTimestamp: '销售员创建时间',
            approvalStatus: '批准状态',
            approvalDate: '上一次批准时间',
            name: '餐厅名称',
            address: '地址',
            uploadedBankInfo: '银行信息已上传',
            uploadedBr: 'Br图片已上传',
            imageUrl: 'imageUrl',
            refID: 'Ref ID',
            uploadedContractSignature: '上传合同签名',
            updateTimestamp: '更新时间',
            //restaurantApproveEdit
            restaurantInfo: '餐厅资料',
            salesInfo: '销售信息',
            bank: '银行',
            bankAccount: '银行帐户',
            bankAccountName: '银行帐户名',
            bankCode: '银行代码',
            bankContact: '银行联络电话',
            bankContactPerson: '银行联系人',
            bankName: '银行名称',
            image: '文档媒体图像（按图缩放）',
            otherDocument: '其他文件 (按图缩放)',
            contact: '联络电话',
            contactPerson: '联系人',
            jobTitle: '职称',
            enName: '餐厅名称(英文)',
            tcName: '餐厅名称(繁体中文)',
            scName: '餐厅名称(简体中文)',
            tcAddress: '地址(繁体中文)',
            enAddress: '地址(英文)',
            scAddress: '地址(简体中文)',
            rejectReason: '拒绝原因',
            rejectReasonTip: '(如果要按下拒绝按钮，请先填写拒绝原因!)',
            merchantInfo: '商户信息',
            editTitle: 'Restaurant Approve Edit',
            createTitle: 'Restaurant Approve Create',
            info: '资料',
        },

        payment: {
            mainTitle: '付款',
            channel: '频道',
            restUrlId: 'Rest Url ID',
            email: '电子邮件',
            tranRef: 'TranRef',
            tranDate: '交易日期',
            planCode: 'Plan Code',
            paymentCode: '付款代码',
            paymentDate: '付款日期',
            status: '交易状态',
            approvalStatus: '审批状态',
            cheque: '支票',
            bankAccountNumber: '银行帐号',
            chequeNumber: '支票号码',
            name: '餐厅名称',
            sale: 'SALES',
            self: 'SELF',
            hsbc: 'HSBC',
            //paymentEdit
            editTitle: '编辑产付款',
            order: '订单',
            plan: '计划',
            restaurant: '餐厅',
            restEnName: '餐厅名称(英文)',
            restTcName: '餐厅名称(繁体中文)',
            restScName: '餐厅名称(简体中文)',
            orderChargePrice: '订单收费价格',
            bankTcName: '银行名称(繁体中文)',
            bankEnName: '银行名称(英文)',
            bankScName: '银行名称(简体中文)',
            bankCode: '银行代码',
            bankInDate: 'Bank In Date',
            planPeriodType: '计划周期类型',
            planTargetDate: '计划目标日期',
            planEnName: '计划名称(英文)',
            planTcName: '计划名称(繁体中文)',
            planScName: '计划名称(简体中文)',
            planPeriod: '计划周期',
            expiryDate: '到期日',
            planEcouponPublishLimit: 'Plan Ecoupon Publish Limit',
            contractToPdf: '将合同导出为PDF',
            //PaymentCreate
            createTitle: '新增产付款',
            info: '资料',
            servicePlan: '服务计划',
            paymentMethod: '付款方式',
            cash: '现金',
            price: '价钱',
            issueChequeBank: '签发支票银行',
            chequeImage: '支票图片',
        },

        uploadPayment: {
            mainTitle: '上传付款',
            //paymentList
            restTcName: '餐厅名称',
            restUrlId: 'Rest Url ID',
            tranRef: 'TranRef',
            tranDate: '交易日期',
            chequeNumber: '支票号码',
            matchStatus: 'Match Status',
            approvalStatus: '批准状态',
        },

        salesRestaurant: {
            mainTitle: '餐厅',
            //restaurantApproveList
            name: '餐厅名称',
            tcName: '餐厅名称',
            logoImage: 'Logo Image',
            shopFrontImage: 'Shop Front Image',
            hkId: 'HKId Card',
            br: 'BR',
            authorisationLetter: 'Authorisation Letter',
            bankInfoSignature: 'Bank Info Signature',
            contractSignature: 'Contract Signature',
            otherDocuments: 'Documents',
            restUrlId: 'Rest Url ID',
            address: '地址',
            tcAddress: '地址',
            salesCreateTimestamp: '销售员创建时间',
            salesName: '销售员名称',
            approvalStatus: '批准状态',
            paymentStatus: '付款状态',
            uploadedContractSignature: '上传合同签名',
            updateTimestamp: '更新时间',
        },

        salesFrontImage: {
            mainTitle: 'Shop Front Gallery',
            //salesFrontImageList
            name: '餐厅名称',
        },

        salesRackProduct: {
            mainTitle: '产品',
            //restaurantApproveList
            restUrlId: 'Rest Url ID',
            restName: '餐厅名称',
            restTcName: '餐厅名称',
            productCode: '产品代码',
            productName: '产品名称',
            productTcName: '产品名称',
            salesCreateTimestamp: '销售员创建时间',
            salesName: '销售员名称',
            startDisplayTimestamp: '开始显示时间',
            endDisplayTimestamp: '结束显示时间',
            published: '已发布',
            updateTimestamp: '更新时间',
        },

        restaurantApprovalSalesRackProduct: {
            mainTitle: '产品',
            //restaurantApproveList
            restUrlId: 'Rest Url ID',
            restTcName: '餐厅名称',
            restName: '餐厅名称',
            productCode: '产品代码',
            productName: '产品名称',
            productTcName: '产品名称',
            salesCreateTimestamp: '销售员创建时间',
            salesName: '销售员名称',
            startDisplayTimestamp: '开始显示时间',
            endDisplayTimestamp: '结束显示时间',
            status: '状态',
            updateTimestamp: 'Update Time',
        },

        productCreateDraft: {
            mainTitle: '产品草稿列表',
            //productCreateDraftList
            listName: '产品草稿',
            draftName: '草稿名称',
            status: '状态',
            draftVersion: '草稿版本',
        },

        productCreateList: {
            mainTitle: '产品列表',
            //productCreateList
            userGroup: '用户组',
            productCat: '产品类别',
            redeemGroup: '产品兑换',
            productDetail: '产品详情',
            lotInventory: '批次库存',
            enterDraftName: '输入草稿名称',
        },

        productCreateUserGroupList: {
            mainTitle: '产品用户组',
            //ProductCreateUserGroupList
            listName: '产品用户组列表',
            groupCode: '用户组代码',
            groupDesc: '用户组描述',
            clearUserGroup: '清除用户组',
        },

        productCreateProductCategoryList: {
            mainTitle: '产品类别',
            menuName: '类别',
            channel: '频道',
            categoryType: '类别类型',
            typeList: '类别类型',
            categoryChannel: '频道',
            displaySequence: '显示顺序',
            codeDescription: '类别代码说明',
            categoryCodeDescription: '类别代码说明',
            status: '状态',
            categoryCode: '类别代码',
            name: '类别名称',
            tcName: '类别名称(繁体中文)',
            enName: '类别名称(英文)',
            scName: '类别名称(简体中文)',
            enDescription: '描述(英文)',
            tcDescription: '描述(繁体中文)',
            scDescription: '描述(简体中文)',
            //ProductCreateProductCategoryList
            createnName: '新增产品类别',
            refCategoryCode: '参考类别代码',
            handleClearRefCategoryCode: '清除参考类别代码',
            startDisplayTimestamp: '开始显示时间',
            endDisplayTimestamp: '结束显示时间',
            imageList: '图像列表',
            uploadImage: '上传图片',
            type: '类型',
            autoDisplaySequence: '自动更新显示顺序',
            categoryInfoNotFind:
                '草稿版本不正确，因此找不到类别信息！如果需要查看类别信息，请再次按一下列表中的类别！',
            addProduct: '添加产品',
            productMapList: '产品列表',
            productCode: '产品代码',
            productChannel: '产品频道',
            productEnName: '产品名称(英文)',
            productTcName: '产品名称(繁体中文)',
            productScName: '产品名称(简体中文)',
            editName: '产品类别',
            createTitle: '新增产品类别',
            editTitle: '编辑产品类别',
            info: '资料',
            cityName: 'City Name',
            regionName: 'Region Name',
        },

        ProductMapList: {
            mainTitle: '产品列表',
            productCode: '产品代码',
            productType: '产品类型',
            productChannel: '产品频道',
            productName: 'Product Name',
            productEnName: '产品名称(英文)',
            productTcName: '产品名称(繁体中文)',
            productScName: '产品名称(简体中文)',
            productStatus: '产品状态',
            displaySequence: '显示顺序',
            addProductMap: '添加产品',
            status: '状态',
            editTitle: '编辑产品',
            info: '资料',
            createTitle: '新增产品',
        },

        productCreateRedeemGroupList: {
            mainTitle: '产品兑换',
            redeemGroupCode: 'Redeem Group Code',
            codeDescription: '产品兑换说明',
            status: '状态',
            //ProductCreateRedeemGroupList
            listName: '产品兑换列表',
            addExistingRedeemGroup: '添加现有兑换',
            title: '产品兑换',
            info: '资料',
            createnName: '新增产品兑换',
            selectLocation: '选择位置',
            selectDate: '选择日期',
            selectTime: '选择时间',
            type: '兑换类型',
            startRedeemTimestamp: '开始兑换时间',
            endRedeemTimestamp: '结束兑换时间',
            startAfterDay: 'Start After Day (*For pre-order use)',
            endAfterDay: 'End After Day',
            redeemLocationList: '兑换位置列表',
            total: '总数',
            addRedeemLocation: '增加兑换位置',
            restUrlId: 'Rest Url Id',
            restEnName: '餐厅名称(英文)',
            restTcName: '餐厅名称(繁体中文)',
            restScName: '餐厅名称(简体中文)',
            startTime: '开始时间',
            endTime: '结束时间',
            interval: '时间间隔',
            selectAllRedeemLocation: 'Select All Redeem Location',
        },

        productCreateProductList: {
            mainTitle: '产品',
            categoryType: '类别类型',
            channel: '频道',
            name: '名称',
            status: '状态',
            type: '类型',
            productCode: '产品代码',
            tcName: '产品名称(繁体中文)',
            enName: '产品名称(英文)',
            scName: '产品名称(简体中文)',
            productChannel: '产品频道',
            productEnName: '产品名称(英文)',
            productTcName: '产品名称(繁体中文)',
            productScName: '产品名称(简体中文)',
            productStatus: '产品状态',
            groupEmails: 'Group Emails',
            merchantProduct: 'Merchant Product',
            //ProductCreateProductpList
            listName: '新增产品列表',
            all: 'All',
            eCoupon: 'ECoupon',
            preOrder: 'PreOrder',
            package: 'Package',
            packageSubItem: 'Package Sub Item',
            freeEcouponItem: 'Free Ecoupon Item',
            publishStatus: '发布状态',
            addMezzofyProduct: 'Add Mezzofy Product',
            mezzofyCouponID: 'Mezzofy Coupon ID',
            productSystemGroupListTitle: 'Product System Group List',
            createnName: '新增产品',
            product: '产品',
            nameCustomize: '名称（自动将繁体翻译成简体）',
            redeemGroupCode: 'Redeem Group Code',
            withoutRedeemGroupCode: '未有合适既Redeem group',
            brandCode: '品牌',
            systemGroup: 'System Group',
            description: '说明',
            tcDescription: '产品说明(繁体中文)',
            enDescription: '产品说明(英文)',
            scDescription: '产品说明(简体中文)',
            descriptionCustomize: '产品说明（自动将繁体翻译成简体）',
            tcTac: 'Tc Tac',
            enTac: 'En Tac',
            scTac: 'Sc Tac',
            tacCustomize: 'Tac（自动将繁体翻译成简体）',
            tcRedeemDescription: '兑换说明(繁体中文)',
            enRedeemDescription: '兑换说明(英文)',
            scRedeemDescription: '兑换说明(简体中文)',
            redeemDescriptionCustomize: '兑换说明（自动将繁体翻译成简体）',
            tcDiscountDescription: '折扣说明(繁体中文)',
            enDiscountDescription: '折扣说明(英文)',
            scDiscountDescription: '折扣说明(简体中文)',
            discountDescriptionCustomize: '折扣说明（自动将繁体翻译成简体）',
            tcTag: 'Tc Tag',
            enTag: 'En Tag',
            scTag: 'Sc Tag',
            display: '显示',
            startDisplayTimestamp: '开始显示时间',
            endDisplayTimestamp: '结束显示时间',
            displaySequence: '显示顺序',
            sellingInfo: '产品销售信息',
            originalPrice: '原价',
            sellingPrice: '销售价格',
            personLimit: '人员限制',
            quantityPerOrder: '每个订单数量',
            dailyQuota: '每日配额',
            bufferRedeemDay: 'Buffer Redeem Day',
            selfRedeem: '自我兑换',
            selfRefund: '自我退款',
            searchable: '可搜索',
            shareable: '可共享',
            packageCashCoupon: 'Package Cash Coupon',
            requireFillInContactPerson: 'Require Fill In Contact Person',
            requireFillInMobileNumber: 'Require Fill In Mobile Number',
            requireFillInEmail: 'Require Fill In Email',
            addSubProductMap: '添加 Sub Product Map',
            subProductMapList: 'Sub Product Map List',
            subProductMap: 'Sub Product Map',
            distributeMinute: '发放分钟',
            quantity: '数量',
            categoryMapList: 'Category Map List',
            addCategory: '增加类别',
            categoryCode: '类别代码',
            codeDescription: '类别代码说明',
            categoryName: '类别名称',
            categoryEnName: '类别名称(英文)',
            categoryTcName: '类别名称(繁体中文)',
            categoryScName: '类别名称(简体中文)',
            imageList: '图像列表',
            uploadImage: '上传图片',
            autoDisplaySequence: '自动更新显示顺序',
            settlementConfig: 'Settlement Config',
            addBlackoutPeriod: '添加无法使用时间',
            blackoutPeriodList: '无法使用期间列表',
            restUrlId: 'Rest Url Id',
            startTimestamp: '开始时间',
            endTimestamp: '结束时间',
            attributeList: '属性列表',
            addAttributeList: '添加属性',
            key: 'Attribute Key',
            attributeType: '属性类型',
            criteria: '标准',
            min: '最小值',
            max: '最大值',
            optionList: '选项列表',
            code: 'Option Code',
            addOptionList: '添加选项',
            tcValue: '值(繁体中文)',
            enValue: '值(英文)',
            scValue: '值(简体中文)',
            valueCustomize: '值（自动将繁体翻译成简体）',
            defaultValue: '默认值',
            typeChange: '更改类型',
            typeChangeMessage: '该产品的所有输入数据将被清除，您要继续更改类型吗？',
            editName: '产品',
            info: '资料',
            cityName: 'City Name',
            regionName: 'Region Name',
        },

        productCreateSystemGroupList: {
            mainTitle: 'System Group',
            name: '名称',
            groupEmails: '团体电邮',
            info: '资料',
            editTitle: 'System Group 编辑',
            addEmail: '添加电邮',
            removeEmail: '移除电邮',
            createTitle: 'System Group Create',
            restaurantList: '餐厅列表',
            restUrlId: 'restUrlId',
            enName: '餐厅名称(英文)',
            tcName: '餐厅名称(繁体中文)',
            scName: '餐厅名称(简体中文)',
            addRestaurant: '添加餐厅',
            restRefId: 'Ref ID',
            address: '地址',
            systemUser: 'System User',
            addRestaurantList: 'Add Restaurant List',
            RestaurantList: 'Restaurant List',
            systemGroupInfo: 'System Group 资料',
        },

        systemUser: {
            mainTitle: 'System User',
            editTitle: 'System User Edit',
            username: 'Username',
            userRole: 'User Role',
            restUrlId: 'Rest Url Id',
            privilegeAttribute: 'Privilege Attribute',
            status: '状态',
            info: '资料',
            newPassword: 'New Password',
            email: 'E-mail',
            apiGroup: 'API Group',
            password: 'Password',
            createTitle: 'System User Create',
            systemGroupId: 'System Group Id',
        },

        productCreateProductLotList: {
            mainTitle: '产品批次库存',
            //ProductCreateProductLotList
            listName: '产品批次库存清单列表',
            addLotInventory: '添加批次库存',
            lotCode: '批次代码',
            quantity: '数量',
            type: '类型',
            uploadLotFile: '上传文件',
            mezzofyCouponID: 'Mezzofy Coupon ID',
            mezzofyCouponCode: 'Mezzofy Coupon Code',
            mezzofyCouponQuantitySync: 'Mezzofy Coupon Quantity Sync',
            mezzofySubItemWarning: 'Mezzofy does not include Sub Item Lot Inventory!',
            massCode: 'Mass Code',
            enName: '名称(英文)',
            tcName: '名称(繁体中文)',
            scName: '名称(简体中文)',
            remainingQuota: '剩余配额',
            totalRemainingQuota: '总剩余配额',
        },

        contactUs: {
            mainTitle: '联系我们',
            topic: '主题',
            caseCode: '案例代码',
            contactPerson: '联系人',
            contactNumber: '联系电话',
            email: '电子邮件',
            refId: '参考编号',
            messageType: '频道',
            tagList: '标签',
            tagListDisplay: '标签',
            previewMessage: '消息预览',
            appMemberId: '应用程序会员编号',
            appVersion: '应用程序版本',
            osVersion: '系统版本',
            orderId: '订单编号',
            companyType: '公司类型',
            restaurantName: '餐厅名称',
            message: '消息',
            mediaList: '媒体',
            remarks: '备注',
            status: '状态',
            editTitle: '编辑联系我们',
            info: '资料',
            createTimestamp: '日期',
            tagInputLabel: '增加标籤',
        },

        imageWarning: {
            mainTitle: 'Image Size:',
            weChat: '4BY3 = IMAGE (480 X 360)',
            icon: 'ICON = SQUARE IMAGE (500 X 500)',
            detail: 'DETAIL = BANNER IMAGE (500 x 500)',
        },

        MerchantUserGroup: {
            mainTitle: 'Merchant User Group',
            groupCode: 'Group Code',
            groupDesc: 'Group Description',
            editTitle: 'Merchant User Group Edit',
            info: '资料',
            restaurantMap: 'Restaurant Map List',
            name: '名称',
            RestaurantList: 'Restaurant List',

            address: '地址',
            restUrlId: 'restUrlId',
            groupEmails: 'Group Emails',
            addEmail: '增加电邮',
            removeEmail: '移除电邮',
            createTitle: 'Merchant User Group Create',
            restaurantList: 'Restaurant List',
            enName: 'Restaurant En Name',
            tcName: 'Restaurant Tc Name',
            scName: 'Restaurant Sc Name',
            addRestaurant: 'Add Restaurant',
            systemUser: 'System User',
            addRestaurantList: 'Add Restaurant List',
            systemGroupInfo: 'System Group 资料',
            emails: '电邮',
            addSettlementConfigList: 'Add Settlement Config List',
            SettlementConfigList: 'Settlement Config List',

            serviceType: 'Service Type',
            description: 'Description',
            profile: 'Profile',
            alipayHkRate: 'AlipayHk Rate',
            wechatPayRate: 'WechatPay Rate',
            stripeRate: 'Stripe Rate',
            yedRate: 'Yed Rate',
            mpayRate: 'Mpay Rate',
            guluRate: 'Gulu Rate',
            saleSettlementProportion: 'Sale Settlement Proportion',
            redemptionSettlementProportion: 'Redemption Settlement Proportion',
            expiredSettlementRate: 'Expired Settlement Rate',
            startTimestamp: 'Start Timestamp',
            endTimestamp: 'End Timestamp',
        },

        restaurantMap: {
            restRefId: 'Ref ID',
            address: 'Address',
            restUrlId: 'restUrlId',
            name: 'name',
            wholeQrCodeColor: 'Qr Code Color',
            download: 'Download',
        },

        RestaurantPayment: {
            mainTitle: 'Payment',
            serviceType: 'Service Type',
            systemGroupName: 'System Group Name',
            paymentConfigName: 'Payment Config Name',
            paymentCode: 'Payment Code',
            cardType: 'Card Type',
            sequence: '顺序',
            status: '状态',
            editTitle: 'Payment Edit',
            info: '资料',
            restUrlId: 'Rest Url Id',
            createTitle: 'Payment Create',
        },

        RestaurantPaymentConfigList: {
            systemGroupName: 'System Group Name',
            name: '名称',
            paymentCode: 'Payment Code',
            merchantId: 'merchantId',
            paymentState: 'paymentState',
        },

        RestaurantPaymentTypeList: {
            paymentCode: 'Payment Code',
            cardType: 'Card Type',
            name: '名称',
        },

        SystemGroupField: {
            systemGroupId: 'System Group Id',
            systemGroupName: 'System Group Name',
        },

        password: {
            newPassword: 'New Password',
            enterAgainPassword: 'Enter New Password Again',
        },

        tools: {
            mainTitle: '工具',
            qrCodeGenerator: 'GULU 二维码生成器',
            qrCodeUrl: '二维码链接',
            errorCorrectionLevel: 'Error Correction Level',
            cornersSquareOptions: 'Corner Square Options',
            cornersDotOptions: 'Corner dot Options',
            dotsOptions: 'Dots Options',
            width: '宽',
            height: '高',
            image: '图片',
            wholeQrCodeColor: '二维码颜色',
            logo: 'Logo',
            download: '下载',
            downloadFormat: '下载格式',
        },

        Staff: {
            mainTitle: '员工',
            username: 'Username',
            id: 'Id',
            editTitle: 'Staff Edit',
            info: '资料',
            staffRole: 'Staff Role',
            refCode: 'Ref Code',
            surname: 'Surname',
            firstName: 'First Name',
            displayName: 'Display Name',
            email: '电邮',
            countryCode: 'Country Code',
            mobile: 'Mobile',
            createTitle: 'Staff Create',
            password: 'Password',
            newPassword: 'New Password',
            queueProfileCode: 'Queue Profile Code',
            queueTransferProfileCode: 'Queue Transfer Profile Code',
            counterProfileCode: 'Counter Profile Code',
            syncNameFieldsWithUsername: 'Sync Name Fields With Username',
            enabled: '显示已启用员工',
            resetAccountNameAndPassword: '重设账户名称及密码',
        },

        RestaurantMap: {
            restUrlId: 'Rest Url Id',
            name: '名称',
            restaurantStatus: 'Restaurant Status',
            mainTitle: 'Restaurant List',
            enName: '名称(英文)',
            scName: '名称(简体中文)',
            tcName: '名称(繁体中文)',
            createTitle: 'Restaurant Map Create',
            editTitle: 'Restaurant Map Edit',
            landmarkId: 'Landmark Id',
            addRestaurantMapList: 'Add Restaurant Map List',
            status: '状态',
        },

        Pickup: {
            mainTitle: '提取叫号',
            editTitle: '提取叫号编辑',
            createTitle: '新增提取叫号',
            restUrlId: 'Rest Url ID',
            typeCode: '类型代号',
            name: '名称',
            status: '状态',
            info: '资料',
            enTypeName: '类型名称(英文)',
            tcTypeName: '类型名称(繁体中文)',
            scTypeName: '类型名称(简体中文)',
            sequence: '顺序',
            reverseSequence: '反序',
            foregroundColor: '前景色',
            backgroundColor: '背景色',
            soundTemplateCode: '声音范本代号',
            voiceCode: '声音代号',
        },

        city: {
            cityCode: 'City Code',
            cityName: 'City Name',
        },
    },
}
