import { RESTAURANT_URL_TYPE, URL_MAP_ACTION_TYPE } from '../Constants/constants'
import { urlMapValidationConstants as cons } from '../Constants/validationConstants'

class UrlMapValidation {
  version
  errMsgMap
  valid

  constructor() {
    if (!UrlMapValidation.instance) {
      this.version = 0
      this.valid = true
      this.errMsgMap = new Map()
      UrlMapValidation.instance = this
    }
    return UrlMapValidation.instance
  }

  validateCreateUrlMap(urlObj, inputObj) {
    this.resetErrMsg()
    this.validateUrlObj(urlObj, inputObj)
    this.version++
  }

  validateEditUrlMap(urlObj, inputObj) {
    this.resetErrMsg()
    this.validateUrlObj(urlObj, inputObj)
    this.version++
  }

  resetErrMsg(type) {
    switch (type) {
      case cons.KEY_CHILDCODE:
        this.errMsgMap.delete(cons.KEY_CHILDCODE)
        break
      case cons.KEY_CUSTOM_FIELD:
        this.errMsgMap.delete(cons.KEY_CUSTOM_FIELD)
        break
      default:
        this.errMsgMap = new Map()
        this.valid = true
        break
    }
  }

  getVersion() {
    return this.version
  }

  isValid() {
    // console.log('this.errMsgMap', this.errMsgMap)
    return this.valid
  }

  getErrMsg(key, uniqueCode) {
    if (!uniqueCode) {
      return this.errMsgMap.has(key) ? this.errMsgMap.get(key) : []
    }
  }

  validateUrlObj(urlObj, inputObj) {
    if (!urlObj.agentType) {
      this.setErrMsgMap(cons.KEY_AGENT_TYPE, 'Missing Agent Type')
    }
    if (urlObj.agentType !== 'weChat' && !urlObj.actionType) {
      this.setErrMsgMap(cons.KEY_ACTION_TYPE, 'Missing Action Type')
    } else {
      if (
        !urlObj.parameters[urlObj.customFieldName] &&
        urlObj.actionType !== URL_MAP_ACTION_TYPE.CUSTOM_URL &&
        urlObj.actionType !== URL_MAP_ACTION_TYPE.SEARCH_NEAR_BY &&
        urlObj.actionType !== URL_MAP_ACTION_TYPE.SEARCH &&
        urlObj.actionType !== URL_MAP_ACTION_TYPE.BOOKMARK &&
        urlObj.actionType !== URL_MAP_ACTION_TYPE.LANDING &&
        urlObj.actionType !== URL_MAP_ACTION_TYPE.DOWNLOAD &&
        urlObj.actionType !== URL_MAP_ACTION_TYPE.REST
      ) {
        this.setErrMsgMap(cons.KEY_CUSTOM_FIELD, 'Missing Custom Field')
      }
      switch (urlObj.actionType) {
        case URL_MAP_ACTION_TYPE.CUSTOM_URL:
          if (!urlObj.customUrl) {
            this.setErrMsgMap(cons.KEY_CUSTOM_URL, 'Missing Custom URL')
          }
          break
        case URL_MAP_ACTION_TYPE.CATEGORY_PRODUCT:
        case URL_MAP_ACTION_TYPE.CATEGORY_PRODUCT_MAP:
        case URL_MAP_ACTION_TYPE.BRAND_PRODUCT:
        case URL_MAP_ACTION_TYPE.BRAND_PRODUCT_MAP:
          if (!urlObj.parameters['productCode']) {
            this.setErrMsgMap(cons.KEY_PRODUCT_CODE, 'Missing Product Code')
          }
          break
        case URL_MAP_ACTION_TYPE.PIXEL:
          if (!urlObj.parameters['name']) {
            this.setErrMsgMap(cons.KEY_PIXEL_NAME, 'Missing Pixel Name')
          }
          if (urlObj.parameters['haveRef'] !== true && urlObj.parameters['haveRef'] !== false) {
            this.setErrMsgMap(cons.KEY_PIXEL_HAVE_REF, 'Missing Pixel Have Ref')
          }
          break
        case URL_MAP_ACTION_TYPE.MY_TICKET:
          if (!urlObj.parameters['serviceType']) {
            this.setErrMsgMap(cons.KEY_SERVICE_TYPE, 'Missing Service Type')
          } else if (urlObj.parameters['serviceType'] === 'RACK_PRODUCT' && !urlObj.parameters['type']) {
            this.setErrMsgMap(cons.KEY_TYPE, 'Missing Type')
          }
          break
        case URL_MAP_ACTION_TYPE.GIFT:
          if (!urlObj.parameters['code']) {
            this.setErrMsgMap(cons.KEY_CODE, 'Missing Code')
          }
          if (!urlObj.parameters['serviceType']) {
            this.setErrMsgMap(cons.KEY_SERVICE_TYPE, 'Missing Service Type')
          } else if (urlObj.parameters['serviceType'] === 'RACK_PRODUCT' && !urlObj.parameters['type']) {
            this.setErrMsgMap(cons.KEY_TYPE, 'Missing Type')
          }
          break
        case URL_MAP_ACTION_TYPE.SHARE:
          if (!urlObj.parameters['serviceType']) {
            this.setErrMsgMap(cons.KEY_SERVICE_TYPE, 'Missing Service Type')
          }
          break
        case URL_MAP_ACTION_TYPE.WEB_VIEW:
        case URL_MAP_ACTION_TYPE.LINK:
          if (urlObj.parameters[urlObj.customFieldName].match('^https?://+?[-a-zA-Z0-9@:%_+.~#?&//=]+')) {
            this.setErrMsgMap(cons.KEY_CUSTOM_FIELD, 'Invalid Custom Field')
          }
          break
        case URL_MAP_ACTION_TYPE.PRODUCT:
        case URL_MAP_ACTION_TYPE.E_COUPON:
          if (!urlObj.parameters[urlObj.customFieldName]) {
            this.setErrMsgMap(cons.KEY_CUSTOM_FIELD, 'Missing Code')
          }

          if (urlObj.parameters.productParent) {
            if (
              (urlObj.parameters.productParent === 'BRAND' && !urlObj.parameters.brandCode) ||
              (urlObj.parameters.productParent === 'CATEGORY' && !urlObj.parameters.categoryCode)
            ) {
              this.setErrMsgMap(cons.KEY_CHILDCODE, 'Missing Code')
            }
          }
          break
        case URL_MAP_ACTION_TYPE.REST:
          if (
            (urlObj.restaurantUrlType === RESTAURANT_URL_TYPE.DOWNLOAD_HTML || urlObj.restaurantUrlType === RESTAURANT_URL_TYPE.UNIVERSAL) &&
            !inputObj.realRestUrlId
          ) {
            this.setErrMsgMap(cons.KEY_REAL_REST_URL_ID, 'Missing Rest Url Id')
          }
          if (!urlObj.restaurantUrlType) {
            this.setErrMsgMap(cons.KEY_RESTAURANT_URL_TYPE, 'Missing Restaurant Url Type')
          }
          if (urlObj.restaurantUrlType === RESTAURANT_URL_TYPE.OTHER && !urlObj.customUrl) {
            this.setErrMsgMap(cons.KEY_CUSTOM_URL, 'Missing Custom URL')
          }
          console.log('this.errMsgMap', this.errMsgMap)
          break
        default:
      }
    }
    if (urlObj.mapCode && !urlObj.mapCode.match('^[A-Z0-9_]+$')) {
      this.setErrMsgMap(cons.KEY_MAP_CODE, 'Invalid Map Code')
    }
  }

  setErrMsgMap(key, errorMessages, valid = false) {
    this.errMsgMap.set(key, errorMessages)
    if (!valid) {
      this.valid = false
    }
  }
}

const validation = new UrlMapValidation()
export default validation
