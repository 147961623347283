import { useState, useEffect } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import moment from 'moment';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import LowPriorityIcon from '@mui/icons-material/LowPriority';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { makeStyles } from '@mui/styles';

import { constants, IMAGE_TYPE } from '../../Constants/constants';
import { getOne, put } from "../../helper/baseFetch";
import { setRestaurantApprove, setRestaurantApproveValue } from "../../actions/restaurantApproveAction";
import { EditCreateDisplay } from '../../Component/EditCreateDisplay/EditCreateDisplay';
import { TempTextInput } from "../../Component/Input/TempTextInput";
import { UploadImage } from "../../Component/UploadImage/UploadImage"
import { ContractToPdf } from "../../Component/Restaurant/ContractToPdf";
import { PaymentList } from '../Payment/PaymentList';
import { MerchantInfoEdit } from './MerchantInfoEdit';
import { getString } from "../../helper/util";
import { GlobalDialog } from "../../Component/GlobalDialog";
import RestaurantApproveValidation from "../../validations/restaurantApproveValidation";
import { restaurantApproveValidationConstants } from '../../Constants/validationConstants';

const helperTextStyles = makeStyles(theme => ({
  root: {
    margin: 4,
    color: "red",
    fontSize: "15px"
  },
}));

export const RestaurantApproveEdit = () => {
  const dispatch = useDispatch();
  const store = useStore();
  const params = useParams();
  const history = useHistory();
  const [ready, setReady] = useState(false);
  const resource = "restaurantApprove";
  const savedRestaurantApprove = useSelector(state => state.restaurantApprove.ui.restaurantApprove)
  const uploadRestaurantApproveChosenTranDate = localStorage.getItem(constants.LOCAL_STORAGE_KEY.UPLOAD_RESTAURANT_APPROVE_CHOSEN_TRANDATE);
  const uploadRestaurantApproveChosenMatchStatus = localStorage.getItem(constants.LOCAL_STORAGE_KEY.UPLOAD_RESTAURANT_APPROVE_CHOSEN_MATCHSTATUS);
  const lang = useSelector(state => state.lang.ui.lang);
  const theme = useSelector(state => state.theme.ui.theme);
  const helperTextStylesClasses = helperTextStyles();
  const [clear, setClear] = useState(true);
  const [dialog, setDialog] = useState({
    open: false,
    dialogActions: []
  });
  const [, setErrorVersion] = useState(RestaurantApproveValidation.getVersion());

  const tabs = [
    {
      label: { resource: resource, key: "info" },
      tabIndex: constants.RESTAURANT_APPROVE_EDIT_TAB.INFO,
    },
    {
      label: { resource: "payment", key: "mainTitle" },
      tabIndex: constants.RESTAURANT_APPROVE_EDIT_TAB.PAYMENT_LIST
    },
    {
      label: { resource: "restaurantApprove", key: "merchantInfo" },
      tabIndex: constants.RESTAURANT_APPROVE_EDIT_TAB.MERCHANT_INFO
    },
  ]
  const tempRestaurantApproveEditList = [
    {
      type: constants.TYPE.TITLE,
      value: { resource: resource, key: "name" },
      className: "sub-title",
    },
    {
      className: "edit-create-input",
      variant: constants.STYLE.VARIANT.STANDARD,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "restUrlId" },
      value: savedRestaurantApprove.restUrlId,
      name: "restUrlId",
      type: constants.TYPE.TEXT,
      disabled: true,
      isEndOfLine: false,
    },
    {
      className: "edit-create-input",
      variant: constants.STYLE.VARIANT.STANDARD,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "channel" },
      value: savedRestaurantApprove.channel,
      name: "channel",
      type: constants.TYPE.TEXT,
      disabled: true,
      isEndOfLine: false,
    },
    {
      className: "edit-create-input",
      variant: constants.STYLE.VARIANT.STANDARD,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "approvalStatus" },
      value: savedRestaurantApprove.approvalStatus,
      name: "approvalStatus",
      type: constants.TYPE.TEXT,
      disabled: true,
      isEndOfLine: false,
    },
    savedRestaurantApprove.approvalStatus !== constants.APPROVAL_STATUS.PENDING &&
    {
      className: "edit-create-input",
      variant: constants.STYLE.VARIANT.STANDARD,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "approvalDate" },
      value: savedRestaurantApprove.approvalDate ? moment(savedRestaurantApprove.approvalDate).format("YYYY-MM-DD HH:mm") : savedRestaurantApprove.approvalDate,
      name: "approvalDate",
      type: constants.TYPE.TEXT,
      disabled: true,
      isEndOfLine: false,
    },
    {
      className: "edit-create-input",
      variant: constants.STYLE.VARIANT.STANDARD,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "email" },
      value: savedRestaurantApprove.email,
      name: "email",
      type: constants.TYPE.TEXT,
      disabled: true,
      isEndOfLine: true,
    },
    {
      className: "edit-create-input",
      variant: constants.STYLE.VARIANT.STANDARD,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "enName" },
      value: savedRestaurantApprove.enName,
      name: "enName",
      type: constants.TYPE.TEXT,
      disabled: true,
      isEndOfLine: false,
    },
    {
      className: "edit-create-input",
      variant: constants.STYLE.VARIANT.STANDARD,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "tcName" },
      value: savedRestaurantApprove.tcName,
      name: "tcName",
      type: constants.TYPE.TEXT,
      disabled: true,
      isEndOfLine: false,
    },
    {
      className: "edit-create-input",
      variant: constants.STYLE.VARIANT.STANDARD,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "scName" },
      value: savedRestaurantApprove.scName,
      name: "scName",
      type: constants.TYPE.TEXT,
      disabled: true,
      isEndOfLine: true,
    },
    {
      className: "edit-create-input",
      variant: constants.STYLE.VARIANT.STANDARD,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "enAddress" },
      value: savedRestaurantApprove.enAddress,
      name: "enAddress",
      type: constants.TYPE.TEXT,
      disabled: true,
      isEndOfLine: false,
    },
    {
      className: "edit-create-input",
      variant: constants.STYLE.VARIANT.STANDARD,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "tcAddress" },
      value: savedRestaurantApprove.tcAddress,
      name: "tcAddress",
      type: constants.TYPE.TEXT,
      disabled: true,
      isEndOfLine: false,
    },
    {
      className: "edit-create-input",
      variant: constants.STYLE.VARIANT.STANDARD,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "scAddress" },
      value: savedRestaurantApprove.scAddress,
      name: "scAddress",
      type: constants.TYPE.TEXT,
      disabled: true,
      isEndOfLine: false,
    },
    {
      type: constants.TYPE.IMAGE,
      picture: savedRestaurantApprove.logoImage,
      size: constants.SIZE.SMALL,
      label: { resource: "salesRestaurant", key: "logoImage" },
      name: "logoImage",
      disableInput: true,
      isEndOfLine: false,
    },
    {
      type: constants.TYPE.IMAGE_MULTI,
      picture: savedRestaurantApprove.shopFrontImages,
      size: constants.SIZE.SMALL,
      label: { resource: "salesRestaurant", key: "shopFrontImage" },
      name: "shopFrontImages",
      disableInput: true,
      isEndOfLine: false,
    },
    {
      type: constants.TYPE.TITLE,
      value: { resource: resource, key: "contact" },
    },
    {
      className: "edit-create-input",
      variant: constants.STYLE.VARIANT.STANDARD,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "contactPerson" },
      value: savedRestaurantApprove.contactPerson,
      name: "contactPerson",
      type: constants.TYPE.TEXT,
      disabled: true,
      isEndOfLine: false,
    },
    {
      className: "edit-create-input",
      variant: constants.STYLE.VARIANT.STANDARD,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "contact" },
      value: savedRestaurantApprove.contact,
      name: "contact",
      type: constants.TYPE.TEXT,
      disabled: true,
      isEndOfLine: false,
    },
    {
      className: "edit-create-input",
      variant: constants.STYLE.VARIANT.STANDARD,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "jobTitle" },
      value: savedRestaurantApprove.jobTitle,
      name: "jobTitle",
      type: constants.TYPE.TEXT,
      disabled: true,
      isEndOfLine: false,
    },
    {
      type: constants.TYPE.TITLE,
      value: { resource: resource, key: "bank" },
    },
    {
      className: "edit-create-input",
      variant: constants.STYLE.VARIANT.STANDARD,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "bankAccount" },
      value: savedRestaurantApprove.bankAccount,
      name: "bankAccount",
      type: constants.TYPE.TEXT,
      disabled: true,
      isEndOfLine: false,
    },
    {
      className: "edit-create-input",
      variant: constants.STYLE.VARIANT.STANDARD,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "bankAccountName" },
      value: savedRestaurantApprove.bankAccountName,
      name: "bankAccountName",
      type: constants.TYPE.TEXT,
      disabled: true,
      isEndOfLine: false,
    },
    {
      className: "edit-create-input",
      variant: constants.STYLE.VARIANT.STANDARD,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "bankCode" },
      value: savedRestaurantApprove.bankCode,
      name: "bankCode",
      type: constants.TYPE.TEXT,
      disabled: true,
      isEndOfLine: false,
    },
    {
      className: "edit-create-input",
      variant: constants.STYLE.VARIANT.STANDARD,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "bankContactPerson" },
      value: savedRestaurantApprove.bankContactPerson,
      name: "bankContactPerson",
      type: constants.TYPE.TEXT,
      disabled: true,
      isEndOfLine: false,
    },
    {
      className: "edit-create-input",
      variant: constants.STYLE.VARIANT.STANDARD,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "bankName" },
      value: savedRestaurantApprove.bankName,
      name: "bankName",
      type: constants.TYPE.TEXT,
      disabled: true,
      isEndOfLine: false,
    },
    {
      className: "edit-create-input",
      variant: constants.STYLE.VARIANT.STANDARD,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "bankContact" },
      value: savedRestaurantApprove.bankContact,
      name: "bankContact",
      type: constants.TYPE.TEXT,
      disabled: true,
      isEndOfLine: false,
    },
    {
      type: constants.TYPE.TITLE,
      value: { resource: resource, key: "image" },
    },
    {
      type: constants.TYPE.IMAGE,
      picture: savedRestaurantApprove.hkIdCard,
      size: constants.SIZE.SMALL,
      label: { resource: "salesRestaurant", key: "hkId" },
      name: "hkIdCard",
      disableInput: true,
      isEndOfLine: false,
    },
    {
      type: constants.TYPE.IMAGE,
      picture: savedRestaurantApprove.br,
      size: constants.SIZE.SMALL,
      label: { resource: "salesRestaurant", key: "br" },
      name: "br",
      disableInput: true,
      isEndOfLine: false,
    },
    {
      type: constants.TYPE.IMAGE,
      picture: savedRestaurantApprove.authorisationLetter,
      size: constants.SIZE.SMALL,
      label: { resource: "salesRestaurant", key: "authorisationLetter" },
      name: "authorisationLetter",
      disableInput: true,
      isEndOfLine: false,
    },
    {
      type: constants.TYPE.IMAGE,
      picture: savedRestaurantApprove.bankInfoSignature,
      size: constants.SIZE.SMALL,
      label: { resource: "salesRestaurant", key: "bankInfoSignature" },
      name: "bankInfoSignature",
      disableInput: true,
      isEndOfLine: false,
    },
    {
      type: constants.TYPE.IMAGE,
      picture: savedRestaurantApprove.contractSignature,
      size: constants.SIZE.SMALL,
      label: { resource: "salesRestaurant", key: "contractSignature" },
      name: "contractSignature",
      disableInput: true,
      isEndOfLine: false,
    },
    {
      type: constants.TYPE.TITLE,
      value: { resource: resource, key: "otherDocument" },
    },
    {
      type: constants.TYPE.IMAGE_MULTI,
      picture: savedRestaurantApprove.otherDocuments,
      size: constants.SIZE.SMALL,
      label: { resource: "salesRestaurant", key: "otherDocuments" },
      name: "otherDocuments",
      disableInput: true,
      isEndOfLine: false,
    },
    {
      type: constants.TYPE.TITLE,
      value: { resource: resource, key: "salesInfo" },
    },
    {
      className: "edit-create-input",
      variant: constants.STYLE.VARIANT.STANDARD,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "salesName" },
      value: savedRestaurantApprove.salesName,
      name: "salesName",
      type: constants.TYPE.TEXT,
      disabled: true,
      isEndOfLine: false,
    },
    {
      className: "edit-create-input",
      variant: constants.STYLE.VARIANT.STANDARD,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "salesCreateTimestamp" },
      value: savedRestaurantApprove.salesCreateTimestamp ? moment(savedRestaurantApprove.salesCreateTimestamp).format("YYYY-MM-DD HH:mm") : savedRestaurantApprove.salesCreateTimestamp,
      name: "salesCreateTimestamp",
      type: constants.TYPE.TEXT,
      disabled: true,
      isEndOfLine: true,
    },
    {
      type: constants.TYPE.FUNCTION,
      value: <ContractToPdf restUrlId={savedRestaurantApprove.restUrlId} />
    },
    {
      type: constants.TYPE.TITLE,
      value: { resource: resource, key: "rejectReason" },
    },
    {
      className: "full-size-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "rejectReason" },
      value: savedRestaurantApprove.rejectReason,
      name: "rejectReason",
      type: constants.TYPE.TEXT,
      backgroundColorClass: true,
      helperTextIcon: true,
      helperText: RestaurantApproveValidation.getErrorMessages(restaurantApproveValidationConstants.KEY_REJECT_REASON),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
  ]
  const content = [
    {
      tab: tempRestaurantApproveEditList.map((inputSetting, index) => {
        let content = <></>;
        switch (inputSetting.type) {
          case constants.TYPE.TITLE:
            content =
              inputSetting.className ?
                <div className={`${inputSetting.className + " sub-title"}`}>{getString(lang, inputSetting.value.resource, inputSetting.value.key, theme)}</div>
                : <div className="line"><div className="sub-title">{getString(lang, inputSetting.value.resource, inputSetting.value.key, theme)}</div></div>
            break;
          case constants.TYPE.FUNCTION:
            content = inputSetting.value
            break;
          case constants.TYPE.TEXT:
            content =
              <TempTextInput inputSetting={inputSetting} handleChange={onChangeInput} />
            break;
          case constants.TYPE.DATETIME_LOCAL:
            content =
              <TempTextInput inputSetting={inputSetting} handleChange={onChangeInput} />
            break;
          case constants.TYPE.IMAGE:
            content =
              <div className="image">
                <UploadImage
                  picture={inputSetting.picture}
                  title={inputSetting.label}
                  name={inputSetting.name}
                  uploadImagePath={inputSetting.uploadImagePath}
                  saveImage={inputSetting.saveImage}
                  disableInput={inputSetting.disableInput} 
                  type={IMAGE_TYPE.RESTAURANT_APPROVE_LIST}/>
              </div>
            break;
          case constants.TYPE.IMAGE_MULTI:
            function handleContent(newPicture) {
              return <div className="image">
                <UploadImage
                  picture={newPicture}
                  title={inputSetting.label}
                  name={inputSetting.name}
                  uploadImagePath={inputSetting.uploadImagePath}
                  saveImage={inputSetting.saveImage}
                  disableInput={inputSetting.disableInput} 
                  type={IMAGE_TYPE.RESTAURANT_APPROVE_LIST}/>
              </div>
            }
            content = inputSetting.picture && inputSetting.picture.length > 0
              ? inputSetting.picture.map((pictureValue) => {
                return handleContent(pictureValue);
              })
              : handleContent();
            break;
          default:
            <></>
        }
        return <div className={inputSetting.backgroundColorClass ? "image-card-background card-border" : "edit-display-wrapper"} key={index}>
          {content}
          {inputSetting.isEndOfLine && <div></div>}
        </div>
      }),
      btn: true
    },
    {
      tab: <PaymentList
        newListTitles={
          [
            {
              key: constants.LIST_DISPLAY_TYPE.STRING,
              value: "channel",
              sort: true
            }, {
              key: constants.LIST_DISPLAY_TYPE.STRING,
              value: "email",
              sort: true
            }, {
              key: constants.LIST_DISPLAY_TYPE.STRING,
              value: "tranRef",
              sort: true
            }, {
              key: constants.LIST_DISPLAY_TYPE.DATE_TIME,
              value: "tranDate",
              sort: true
            }, {
              key: constants.LIST_DISPLAY_TYPE.STRING,
              value: "planCode",
              sort: true
            }, {
              key: constants.LIST_DISPLAY_TYPE.STRING,
              value: "paymentCode",
              sort: true
            }, {
              key: constants.LIST_DISPLAY_TYPE.STRING,
              value: "status",
              sort: true
            }, {
              key: constants.LIST_DISPLAY_TYPE.STRING,
              value: "approvalStatus",
              sort: true
            },
          ]
        }
        newFilter={
          [
            {
              className: "filter-input",
              label: { resource: "payment", key: "channel" },
              value: '',
              type: constants.TYPE.SELECT,
              selectValue: constants.CHOICES.PAYMENT.CHANNEL,
              name: "channel",
              size: constants.SIZE.SMALL
            },
            {
              className: "filter-input",
              label: { resource: "payment", key: "email" },
              clear: true,
              value: '',
              name: "email",
              size: constants.SIZE.SMALL
            },
            {
              className: "filter-input",
              label: { resource: "payment", key: "tranRef" },
              clear: true,
              value: '',
              name: "tranRef",
              size: constants.SIZE.SMALL
            },
            {
              className: "filter-input",
              label: { resource: "payment", key: "status" },
              value: '',
              type: constants.TYPE.SELECT,
              selectValue: constants.CHOICES.PAYMENT.TRAN_STATUS,
              name: "status",
              size: constants.SIZE.SMALL
            },
            {
              className: "filter-input",
              label: { resource: "payment", key: "approvalStatus" },
              value: '',
              type: constants.TYPE.SELECT,
              selectValue: constants.CHOICES.PAYMENT.APPROVAL_STATUS,
              name: "approvalStatus",
              size: constants.SIZE.SMALL
            }
          ]
        }
        restUrlId={savedRestaurantApprove.id}
        createBtn={true} />,
      btn: false
    },
    {
      tab: <MerchantInfoEdit newResource={constants.PATH.APPROVAL.MERCHANT_INFO} newBasePath={constants.PATH.APPROVAL.MERCHANT_INFO} />,
      btn: false,
    }
  ]
  const bottomBtns = [
    {
      className: "margin-left-16",
      disabled: savedRestaurantApprove.approvalStatus === constants.APPROVAL_STATUS.ACTIVE,
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: "approve" },
      onClick: handleApproveRestaurant,
      icon: <CheckIcon />
    },
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: "rejectBtn" },
      disabled: savedRestaurantApprove.approvalStatus === constants.APPROVAL_STATUS.REJECT,
      onClick: handleRejectRestaurant,
      className: "margin-left-16",
      icon: <CloseIcon />
    },
    {
      className: "margin-left-16",
      type: constants.TYPE.TEXT,
      label: { key: "updateTimestamp" },
      value: ": " + moment(savedRestaurantApprove.updateTimestamp).format('YYYY-MM-DD HH:mm:ss'),
    },
  ]
  const onClickBackToEdit = () => {
    setDialog({
      open: false,
      dialogActions: []
    });
    onClickClear();
  };
  const onClickBackToList = () => {
    setDialog({
      open: false,
      dialogActions: []
    })
    history.push("/" + constants.PATH.APPROVAL.RESTAURANT);
  };

  function onChangeInput(name, e) {
    dispatch(setRestaurantApproveValue(name, savedRestaurantApprove, "", e));
  }

  function handleApproveRestaurant() {
    put(constants.PATH.APPROVAL.RESTAURANT_APPROVE + "/" + params.id, { previousData: "" }, (payload) => {
      setDialog({
        open: true,
        onClose: onClickBackToEdit,
        dialogContentText: { key: "approveSuccess" },
        dialogActions: [
          { label: { key: "backEdit" }, onClick: onClickBackToEdit, icon: <ArrowBackIcon /> },
          { label: { key: "backList" }, onClick: onClickBackToList, icon: <LowPriorityIcon /> }
        ]
      })
      setClear(false)
    }, undefined, store);
  }

  function handleRejectRestaurant() {
    RestaurantApproveValidation.validateEditRestaurantApprove(savedRestaurantApprove);
    if (RestaurantApproveValidation.isValid()) {
      put(constants.PATH.APPROVAL.RESTAURANT_REJECT + "/" + params.id, { rejectReason: savedRestaurantApprove.rejectReason }, (payload) => {
        setDialog({
          open: true,
          onClose: onClickBackToEdit,
          dialogContentText: { key: "rejectSuccess" },
          dialogActions: [
            { label: { key: "backEdit" }, onClick: onClickBackToEdit, icon: <ArrowBackIcon /> },
            { label: { key: "backList" }, onClick: onClickBackToList, icon: <LowPriorityIcon /> }
          ]
        })
        setClear(false)
      }, undefined, store);
      RestaurantApproveValidation.resetErrorMessages();
    } else {
      setErrorVersion(RestaurantApproveValidation.getVersion());
    }
  }

  function onClickClear() {
    setClear(true)
  }

  useEffect(() => {
    if (clear === true) {
      getOne(constants.PATH.APPROVAL.RESTAURANT + "/" + params.id, undefined, (payload) => {
        dispatch(setRestaurantApprove(payload));
        setReady(true)
        setClear(false)
      }, undefined, store);
    }
    RestaurantApproveValidation.resetErrorMessages();
  }, [clear]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    ready &&
    <>
      <EditCreateDisplay
        code={savedRestaurantApprove && savedRestaurantApprove.id ? savedRestaurantApprove.id : ""}
        mapName={null}
        mapCode={<>
          {uploadRestaurantApproveChosenTranDate &&
            <h3 className="chosen-data">{getString(lang, "uploadRestaurantApprove", "tranDate", theme)}: {uploadRestaurantApproveChosenTranDate}</h3>
          }
          {uploadRestaurantApproveChosenMatchStatus &&
            <h3 className="chosen-data">{getString(lang, "uploadRestaurantApprove", "matchStatus", theme)}: {uploadRestaurantApproveChosenMatchStatus}</h3>
          }</>}
        resource={resource}
        tabs={tabs}
        content={content}
        onChangeInput={onChangeInput}
        bottomBtns={bottomBtns}
        display="edit"
      />
      {dialog && dialog.open &&
        <GlobalDialog dialogInfo={dialog} />
      }
    </>
  )
};

