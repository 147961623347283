import { Box, Button, Drawer, IconButton, Stack } from '@mui/material'
import SharedDialog from '../../Component/common/dialog/SharedDialog'
import MUIInput from '../../Component/common/field/MUIInput'
import GridContainer from '../../Component/common/field/GridContainer'
import { useEffect, useRef, useState } from 'react'
import MultiLangInput from '../../Component/common/field/MultiLangInput'
import { getOne, newPut, post, put } from '../../helper/baseFetch'
import { API_PATH } from '../../Constants/path'
import { useStore } from 'react-redux'
import { Clear, Save } from '@mui/icons-material'
import MenuButton from '../../Component/common/button/MenuButton'
import { LANG } from '../../Constants/lang'
import RegionList from './RegionList'
import Iconify from '../../Component/common/iconify/Iconify'
import { ICONIFY } from '../../Constants/iconify'
import MUISelect from '../../Component/common/field/MUISelect'
import { STATUS_OPTIONS } from '../../Constants/option'
import { Icon } from '@iconify/react'

const CITY_DEFAULT = { regionCode: '', name: LANG, sequence: '', status: 'A' }

export default function City({
    id,
    isCreateMode,
    showCity,
    setShowCity,
    getCityList,
    setShowSaveDialog,
}) {
    const store = useStore()
    const [showStatusDialog, setShowStatusDialog] = useState(false)
    const [showRegionDrawer, setShowRegionDrawer] = useState(false)
    const [city, setCity] = useState(CITY_DEFAULT)
    const statusRef = useRef()

    const [error, setError] = useState({})

    const onChangeInput = (key, value) => {
        setCity({ ...city, [key]: value })
    }

    const onChangeMultiLangInput = (objKey, key, value) => {
        setCity({ ...city, [objKey]: { ...city[objKey], [key]: value } })
    }

    const onClickSave = () => {
        if (isCreateMode) {
            post(
                API_PATH.city,
                city,
                () => {
                    setShowSaveDialog(true)
                    setShowCity(false)
                    getCityList()
                },
                undefined,
                store
            )
        } else {
            put(
                `${API_PATH.city}/${id}`,
                city,
                () => {
                    setShowSaveDialog(true)
                    setShowCity(false)
                    getCityList()
                },
                undefined,
                store
            )
        }
    }

    const onClickClear = () => {
        if (isCreateMode) {
            setCity(CITY_DEFAULT)
        } else {
            getCity()
        }
    }

    const onClickClose = () => {
        setShowCity(false)
        setCity(CITY_DEFAULT)
    }

    const getCity = () => {
        getOne(
            `${API_PATH.city}/${id}`,
            undefined,
            (payload) => {
                setCity(payload)
            },
            undefined,
            store
        )
    }

    const validateCity = () => {
        let newError = {}

        if (!city.regionCode) {
            newError.regionCode = 'Required'
        }

        if (!city.sequence) {
            newError.sequence = 'Required'
        }

        setError(newError)
    }

    useEffect(() => {
        validateCity()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [city])

    useEffect(() => {
        if (!isCreateMode) {
            getCity()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onClickListRow = (row) => {
        setCity({ ...city, regionCode: row.regionCode })
        setShowRegionDrawer(false)
    }

    return (
        <>
            <SharedDialog
                open={showCity}
                onClose={onClickClose}
                title={`City ${isCreateMode ? 'Create' : 'Edit'}`}
                content={
                    <GridContainer>
                        <MUIInput
                            label='Region Code'
                            value={city.regionCode}
                            name='regionCode'
                            onChange={(e) => onChangeInput('sequence', e.target.value)}
                            endAdornment={
                                <IconButton onClick={() => setShowRegionDrawer(true)}>
                                    <Iconify icon={ICONIFY.edit} />
                                </IconButton>
                            }
                            error={error.regionCode}
                        />
                        {!isCreateMode && (
                            <MUIInput
                                label='City Code'
                                value={city.cityCode}
                                name='cityCode'
                                disabled
                            />
                        )}
                        <MultiLangInput
                            langObj={city.name}
                            onChangeMultiLangInput={onChangeMultiLangInput}
                            objKey='name'
                            label='Name'
                        />
                        <MUIInput
                            label='Sequence'
                            value={city.sequence}
                            onChange={(e) => onChangeInput('sequence', e.target.value)}
                            name='sequence'
                            error={error.sequence}
                        />
                        {isCreateMode && (
                            <MUISelect
                                onChange={(option) => onChangeInput('status', option.value)}
                                label='Status'
                                name='status'
                                value={city.status}
                                options={STATUS_OPTIONS}
                            />
                        )}
                    </GridContainer>
                }
                actions={
                    <Stack
                        justifyContent={isCreateMode ? 'flex-end' : 'space-between'}
                        direction='row'
                        sx={{ width: '100%' }}
                    >
                        {!isCreateMode && (
                            <MenuButton
                                button={{
                                    variant: 'contained',
                                    label: `Switch Status: ${city.status}`,
                                }}
                                options={STATUS_OPTIONS}
                                onChange={(value) => {
                                    statusRef.current = value
                                    setShowStatusDialog(true)
                                }}
                                value={city.status}
                            />
                        )}
                        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
                            <Button
                                variant='contained'
                                onClick={onClickSave}
                                startIcon={<Save />}
                                disabled={Object.keys(error).length > 0}
                            >
                                Save
                            </Button>
                            <Button
                                variant='contained'
                                onClick={onClickClear}
                                startIcon={<Clear />}
                            >
                                Clear
                            </Button>
                        </Box>
                    </Stack>
                }
                maxWidth='lg'
            />
            <SharedDialog
                open={showStatusDialog}
                onClose={() => setShowStatusDialog(false)}
                title=''
                content={
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        Are you sure to change the status?
                    </Box>
                }
                maxWidth='md'
                actions={
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
                        <Button
                            variant='contained'
                            onClick={() => {
                                newPut({
                                    path: `${API_PATH.cityStatus}/${id}`,
                                    body: {
                                        status: statusRef.current,
                                    },
                                    successCallback: () => {
                                        setShowStatusDialog(false)
                                        setCity({ ...city, status: statusRef.current })
                                        getCityList()
                                    },
                                    store: store,
                                })
                            }}
                            startIcon={<Iconify icon={ICONIFY.tick} />}
                        >
                            OK
                        </Button>
                        <Button
                            variant='contained'
                            onClick={() => setShowStatusDialog(false)}
                            startIcon={<Iconify icon={ICONIFY.close} />}
                        >
                            Discard
                        </Button>
                    </Box>
                }
            />
            <Drawer
                open={showRegionDrawer}
                onClose={() => setShowRegionDrawer(false)}
                anchor='right'
            >
                <Box sx={{ p: 2 }}>
                    <RegionList onClickListRow={onClickListRow} />
                </Box>
            </Drawer>
        </>
    )
}
