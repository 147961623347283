import { useState, useEffect } from 'react'
import { useSelector, useStore } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import _ from 'lodash'

import { Card } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'

import { ListDisplay } from '../../Component/ListDisplay/ListDisplay'

import { constants as cons } from '../../Constants/constants'
import { getList } from '../../helper/baseFetch'
import { TempBtn } from '../../Component/Input/TempBtn'
import { getParamQueryString, getQuery, getQueryParamObject, getString } from '../../helper/util'
import { generateTableCellToMultiLanguageName } from '../../helper/util'

export const TicketTypeList = ({ editBtn, createBtn, onClickListRow, dialogFooterBtn, customCreate, reload, setReload, changeUrlQueryString }) => {
  const store = useStore()
  const [ticketTypeList, setTicketTypeList] = useState([])
  const [ready, setReady] = useState(false)
  const [clear, setClear] = useState(false)
  const resource = 'TicketType'
  const savedRestaurant = useSelector((state) => state.restaurant.ui.restaurant)
  const lang = useSelector((state) => state.lang.ui.lang)
  const theme = useSelector((state) => state.theme.ui.theme)
  const history = useHistory()
  const location = useLocation()
  const filterParams = getQuery()

  const listTitles = [
    {
      key: cons.LIST_DISPLAY_TYPE.STRING,
      value: 'ticketType',
      sort: true,
    },
    {
      key: cons.LIST_DISPLAY_TYPE.STRING,
      value: 'name',
      sort: false,
    },
    {
      key: cons.LIST_DISPLAY_TYPE.STRING,
      value: 'status',
      sort: true,
    },
    {
      key: cons.LIST_DISPLAY_TYPE.STRING,
      value: 'queueTemplateId',
      sort: true,
    },
    {
      key: cons.LIST_DISPLAY_TYPE.STRING,
      value: 'restUrlId',
      sort: true,
    },
  ]

  const listFilters = [
    {
      className: 'filter-input',
      label: { resource: resource, key: 'ticketType' },
      clear: true,
      value: '',
      name: 'ticketType',
      size: cons.SIZE.SMALL,
    },
    {
      className: 'filter-input',
      label: { resource: resource, key: 'name' },
      clear: true,
      value: '',
      name: 'name',
      size: cons.SIZE.SMALL,
    },
    {
      className: 'filter-input',
      label: { resource: resource, key: 'status' },
      value: '',
      type: cons.TYPE.SELECT,
      selectValue: cons.CHOICES.TICKET_TYPE.STATUS,
      name: 'status',
      size: cons.SIZE.SMALL,
    },
  ]

  const [param, setParam] = useState(
    !changeUrlQueryString
      ? {
          path: '',
          page: 0,
          rowsPerPage: 10,
          sort: 'updateTimestamp',
          direction: 'DESC',
          listFilters: _.cloneDeep(listFilters),
          count: 0,
        }
      : getQueryParamObject(
          {
            path: '',
            page: 0,
            rowsPerPage: 10,
            sort: 'updateTimestamp',
            direction: 'DESC',
            listFilters: _.cloneDeep(listFilters),
            count: 0,
          },
          _.cloneDeep(listFilters),
          listTitles
        )
  )

  useEffect(() => {
    if (!changeUrlQueryString) {
      return
    }

    const queryString = getParamQueryString(
      {
        path: '',
        page: 0,
        rowsPerPage: 10,
        sort: 'updateTimestamp',
        direction: 'DESC',
        listFilters: _.cloneDeep(listFilters),
        count: param.count,
      },
      param
    )

    const currentQueryString = window.location.search?.substring(1)

    if (queryString === currentQueryString) {
      return
    }

    const newParam = getQueryParamObject(
      {
        path: '',
        page: 0,
        rowsPerPage: 10,
        sort: 'updateTimestamp',
        direction: 'DESC',
        listFilters: _.cloneDeep(listFilters),
        count: param.count + 1,
      },
      _.cloneDeep(listFilters),
      listTitles
    )

    setParam(newParam)

    const newQuery = getParamQueryString(
      {
        path: '',
        page: 0,
        rowsPerPage: 10,
        sort: 'updateTimestamp',
        direction: 'DESC',
        listFilters: _.cloneDeep(listFilters),
        count: 0,
      },
      newParam
    )

    history.replace({ pathname: location.pathname, search: newQuery })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterParams])

  function onChangeParam(value) {
    value.count = value.count + 1

    if (!changeUrlQueryString) {
      setParam(value)
      return
    }

    const querySyring = getParamQueryString(
      {
        path: '',
        page: 0,
        rowsPerPage: 10,
        sort: 'updateTimestamp',
        direction: 'DESC',
        listFilters: _.cloneDeep(listFilters),
        count: 0,
      },
      value
    )

    history.push({ pathname: location.pathname, search: querySyring })
  }

  function onChangeListFilters(list) {
    let tempParam = _.cloneDeep(param)
    tempParam.listFilters = list
    tempParam.page = 0
    onChangeParam(tempParam)
  }

  function onChangeListSort(sortItem) {
    let tempParam = _.cloneDeep(param)
    tempParam.sort = sortItem
    tempParam.direction = tempParam.direction === 'DESC' ? 'ASC' : 'DESC'
    onChangeParam(tempParam)
  }

  function onChangeListPage(page) {
    let tempParam = _.cloneDeep(param)
    tempParam.page = page
    onChangeParam(tempParam)
  }

  function onChangeListRowsPerPage(rowsPerPage) {
    let tempParam = _.cloneDeep(param)
    tempParam.page = 0
    tempParam.rowsPerPage = rowsPerPage
    onChangeParam(tempParam)
  }

  useEffect(() => {
    let tempParam = _.cloneDeep(param)
    const filter = { id: savedRestaurant.restUrlId }
    tempParam.listFilters.forEach((filterItem) => {
      if (filterItem.value !== '' && filterItem.value !== undefined) {
        filter[filterItem.name] = filterItem.value
      }
    })

    getList(
      cons.PATH.SHOP.QUEUE.TICKET_TYPE,
      {
        sort: { field: tempParam.sort, order: tempParam.direction },
        filter: filter,
        pagination: { page: tempParam.page + 1, perPage: tempParam.rowsPerPage },
      },
      (payload) => {
        payload.map((item) => {
          item.name = generateTableCellToMultiLanguageName(item.tcName, item.enName, item.scName)
          return item
        })
        setTicketTypeList(payload)
        setReady(true)
        setClear(false)
        if (setReload) {
          setReload(false)
        }
      },
      undefined,
      store
    )
  }, [param.count, clear === true, reload]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    ready && (
      <Card className="card-wrapper overflow">
        {createBtn === 'custom' && (
          <h3 className="title">
            {getString(lang, resource, 'name', theme)}{' '}
            <div className="create-btn">
              <TempBtn
                btnSetting={{
                  onClick: customCreate,
                  color: cons.STYLE.PRIMARY,
                  label: { key: 'create' },
                  icon: <AddIcon />,
                }}
              ></TempBtn>
            </div>
          </h3>
        )}
        <ListDisplay
          newListMainTitle={createBtn === 'custom' ? ' ' : ''}
          onClickListRow={onClickListRow}
          page={param.page}
          rowsPerPage={param.rowsPerPage}
          onChangeListSort={onChangeListSort}
          resource={resource}
          dataList={ticketTypeList}
          sort={param.sort}
          direction={param.direction}
          listTitles={listTitles}
          listFilters={param.listFilters}
          onChangeListPage={onChangeListPage}
          onChangeListRowsPerPage={onChangeListRowsPerPage}
          editBtn={editBtn}
          createBtn={createBtn === 'custom' ? false : createBtn}
          onChangeListFilters={onChangeListFilters}
          dialogFooterBtn={dialogFooterBtn}
          redirectPath={'/' + cons.PATH.SHOP.QUEUE.TICKET_TYPE + '/'}
        />
      </Card>
    )
  )
}
