import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector, useStore } from 'react-redux'
import { useHistory } from 'react-router-dom'
import _ from 'lodash'

import SaveIcon from '@mui/icons-material/Save'
import ClearIcon from '@mui/icons-material/Clear'
import LowPriorityIcon from '@mui/icons-material/LowPriority'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CachedIcon from '@mui/icons-material/Cached'
// import { makeStyles } from '@mui/styles';

import { constants, MERCHANT_USER_ROLE } from '../../../Constants/constants'
import { put } from '../../../helper/baseFetch'
import { getOne } from '../../../helper/baseFetch'
import { setMerchantUser, setMerchantUserValue } from '../../../actions/merchantUserAction'
import { getString, helperTextStyles } from '../../../helper/util'
import { EditCreateDisplay } from '../../../Component/EditCreateDisplay/EditCreateDisplay'
import { TempTextInput } from '../../../Component/Input/TempTextInput'
import { TempAutoSelectInput } from '../../../Component/Input/TempAutoSelectInput'
import { TempCheckBox } from '../../../Component/Input/TempCheckBox'
import { GlobalDialog } from '../../../Component/GlobalDialog'
import MerchantUserValidation from '../../../validations/merchantUserValidation'
import { merchantUserValidationConstants } from '../../../Constants/validationConstants'
import { PrivilegeSetting } from './PrivilegeSetting'
import '../../../styles/merchantUser.scss'
import { ResetUsernameAndPasswordDialog } from './ResetUsernameAndPasswordDialog'

const resource = 'MerchantUser'

export const MerchantUserEdit = () => {
  const dispatch = useDispatch()
  const store = useStore()
  const params = useParams()
  const history = useHistory()
  const helperTextStylesClasses = helperTextStyles()
  const [ready, setReady] = useState(false)
  const [clear, setClear] = useState(true)
  const [dialog, setDialog] = useState({
    open: false,
    dialogActions: [],
  })
  const savedMerchantUser = useSelector((state) => state.merchantUser.ui.merchantUser)
  const lang = useSelector((state) => state.lang.ui.lang)
  const theme = useSelector((state) => state.theme.ui.theme)
  const [, setErrorVersion] = useState(MerchantUserValidation.getVersion())
  const [openResetDialog, setOpenResetDialog] = useState(false)
  const id = savedMerchantUser.id

  const tabs = [
    {
      label: { resource: resource, key: 'info' },
      tabIndex: constants.MERCHANT_USER_EDIT_TAB.INFO,
    },
  ]
  const tempMerchantUserEditList = [
    {
      type: constants.TYPE.TITLE,
      value: { resource: resource, key: 'mainTitle' },
      className: 'sub-title',
    },
    {
      className: 'edit-create-input',
      size: constants.SIZE.SMALL,
      variant: savedMerchantUser.userRole === MERCHANT_USER_ROLE.ROLE_MERCHANT_USER_GUEST ? constants.STYLE.VARIANT.STANDARD : null,
      label: { resource: resource, key: 'userRole' },
      value: savedMerchantUser.userRole,
      disableClearable: true,
      type: savedMerchantUser.userRole === MERCHANT_USER_ROLE.ROLE_MERCHANT_USER_GUEST ? constants.TYPE.TEXT : constants.TYPE.SELECT,
      selectValue: constants.CHOICES.MERCHANT_USER.USER_ROLE,
      name: 'userRole',
      disabled: savedMerchantUser.userRole === MERCHANT_USER_ROLE.ROLE_MERCHANT_USER_GUEST ? true : false,
      isEndOfLine: true,
      required: true,
      helperTextIcon: true,
      helperText: MerchantUserValidation.getErrorMessages(`${id}-${merchantUserValidationConstants.KEY_USER_ROLE}`),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: 'edit-create-input',
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'enabled' },
      value: savedMerchantUser.enabled,
      disableClearable: true,
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.MERCHANT_USER.ENABLED,
      name: 'enabled',
      isEndOfLine: false,
      required: true,
      helperTextIcon: true,
      helperText: MerchantUserValidation.getErrorMessages(`${id}-${merchantUserValidationConstants.KEY_ENABLED}`),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: 'edit-create-input',
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'enableMultiLogin' },
      value: savedMerchantUser.enableMultiLogin,
      disableClearable: true,
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.MERCHANT_USER.ENABLED_MULTI_LOGIN,
      name: 'enableMultiLogin',
      isEndOfLine: true,
      required: true,
      helperTextIcon: true,
      helperText: MerchantUserValidation.getErrorMessages(`${id}-${merchantUserValidationConstants.KEY_ENABLED_MULTI_LOGIN}`),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: `edit-create-input`,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'merchantRole' },
      value: savedMerchantUser.merchantRole,
      name: 'merchantRole',
      type: constants.TYPE.SELECT,
      disableClearable: true,
      selectValue: constants.CHOICES.MERCHANT_USER.MERCHANT_ROLE,
      isEndOfLine: true,
      required: true,
      helperTextIcon: true,
      helperText: MerchantUserValidation.getErrorMessages(`${id}-${merchantUserValidationConstants.KEY_MERCHANT_ROLE}`),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: 'edit-create-input',
      size: constants.SIZE.SMALL,
      type: constants.TYPE.FUNCTION,
      value: <PrivilegeSetting onChangeInput={onChangeInput} id={id} />,
    },
    {
      className: 'edit-create-input',
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'email' },
      value: savedMerchantUser.email,
      name: 'email',
      isEndOfLine: true,
      helperTextIcon: true,
      helperText: MerchantUserValidation.getErrorMessages(`${id}-${merchantUserValidationConstants.KEY_EMAIL}`),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    // {
    //   type: constants.TYPE.TITLE,
    //   value: { resource: resource, key: "permission" },
    //   className: "sub-title",
    // },
    // {
    //   className: "text",
    //   size: constants.SIZE.SMALL,
    //   label: { resource: resource, key: "reportPermission" },
    //   value: savedMerchantUser.reportPermission?.read,
    //   name: "reportPermission",
    //   type: constants.TYPE.CHECKBOX,
    //   isEndOfLine: false
    // },
    // {
    //   className: "text",
    //   size: constants.SIZE.SMALL,
    //   label: { resource: resource, key: "deviceManagementPermission" },
    //   value: savedMerchantUser.deviceManagementPermission?.read,
    //   name: "deviceManagementPermission",
    //   type: constants.TYPE.CHECKBOX,
    //   isEndOfLine: false
    // },
    // {
    //   className: "text",
    //   size: constants.SIZE.SMALL,
    //   label: { resource: resource, key: "featurePermission" },
    //   value: savedMerchantUser.featurePermission?.read,
    //   name: "featurePermission",
    //   type: constants.TYPE.CHECKBOX,
    //   isEndOfLine: false
    // },
    // {
    //   className: "text",
    //   size: constants.SIZE.SMALL,
    //   label: { resource: resource, key: "restaurantManagementPermission" },
    //   value: savedMerchantUser.restaurantManagementPermission?.read,
    //   name: "restaurantManagementPermission",
    //   type: constants.TYPE.CHECKBOX,
    //   isEndOfLine: false
    // },
    // {
    //   className: "text",
    //   size: constants.SIZE.SMALL,
    //   label: { resource: resource, key: "userGroupManagementPermission" },
    //   value: savedMerchantUser.userGroupManagementPermission?.read,
    //   name: "userGroupManagementPermission",
    //   type: constants.TYPE.CHECKBOX,
    //   isEndOfLine: false
    // },
  ]
  const content = [
    {
      tab: tempMerchantUserEditList.map((inputSetting, index) => {
        let content
        switch (inputSetting.type) {
          case constants.TYPE.SELECT:
            content = <TempAutoSelectInput inputSetting={inputSetting} handleChange={onChangeInput} />
            break
          case constants.TYPE.CHECKBOX:
            content = (
              <span className="merchant-user-check-box-wrapper">
                <TempCheckBox
                  checkBoxSetting={{
                    className: inputSetting.className,
                    name: inputSetting.name,
                    label: inputSetting.label,
                    value: inputSetting.value,
                  }}
                  handleChange={handleCheckBoxChange}
                />
              </span>
            )
            break
          case constants.TYPE.TITLE:
            content = (
              <div className={`${inputSetting.className + ' sub-title'}`}>
                {getString(lang, inputSetting.value.resource, inputSetting.value.key, theme)}
              </div>
            )
            break
          case constants.TYPE.FUNCTION:
            content = inputSetting.value
            break
          default:
            content = <TempTextInput inputSetting={inputSetting} handleChange={onChangeInput} />
        }
        return (
          <div className="edit-display-wrapper" key={index}>
            {content}
            {inputSetting.isEndOfLine && <div></div>}
          </div>
        )
      }),
      btn: true,
    },
  ]

  const bottomBtns = [
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: 'save' },
      onClick: () => onClickSave(),
      className: 'margin-left-16',
      icon: <SaveIcon />,
    },
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: 'clear' },
      disabled: false,
      onClick: onClickClear,
      className: 'margin-left-16',
      icon: <ClearIcon />,
    },
    {
      className: 'special-button special-button-margin-left',
      variant: constants.STYLE.VARIANT.CONTAINED,
      icon: <CachedIcon />,
      label: { key: 'resetAccountNameAndPassword' },
      onClick: () => setOpenResetDialog(true),
    },
  ]

  const onClickBackToEdit = () => {
    setOpenResetDialog(false)
    setDialog({
      open: false,
      dialogActions: [],
    })
    onClickClear()
  }
  const onClickBackToList = () => {
    setOpenResetDialog(false)
    setDialog({
      open: false,
      dialogActions: [],
    })
    history.push('/' + constants.PATH.MERCHANT.MERCHANT_USER)
  }

  function onChangeInput(name, e) {
    switch (name) {
      default:
        dispatch(setMerchantUserValue(name, savedMerchantUser, '', e))
    }
  }

  function handleCheckBoxChange(name, e) {
    let tempMerchantUser = _.cloneDeep(savedMerchantUser)
    let permission = tempMerchantUser[name]
    if (!e) {
      permission['read'] = true
      permission['write'] = true
    } else {
      permission['read'] = false
      permission['write'] = false
    }
    dispatch(setMerchantUserValue(name, savedMerchantUser, '', permission))
  }

  function onClickReset(username, password) {
    put(
      constants.PATH.MERCHANT.UPDATE_MERCHANT_USER_PASSWORD + '/' + params.id,
      { password, username },
      () => {
        setOpenResetDialog(false)
        setDialog({
          open: true,
          onClose: onClickBackToEdit,
          dialogContentText: { key: 'editSuccess' },
          dialogActions: [
            { label: { key: 'backEdit' }, onClick: onClickBackToEdit, icon: <ArrowBackIcon /> },
            { label: { key: 'backList' }, onClick: onClickBackToList, icon: <LowPriorityIcon /> },
          ],
        })
        setClear(false)
      },
      undefined,
      store
    )
  }

  function onClickSave() {
    MerchantUserValidation.validateEditMerchantUser(savedMerchantUser)

    if (MerchantUserValidation.isValid()) {
      put(
        constants.PATH.MERCHANT.MERCHANT_USER + '/' + params.id,
        savedMerchantUser,
        () => {
          setDialog({
            open: true,
            onClose: onClickBackToEdit,
            dialogContentText: { key: 'editSuccess' },
            dialogActions: [
              { label: { key: 'backEdit' }, onClick: onClickBackToEdit, icon: <ArrowBackIcon /> },
              { label: { key: 'backList' }, onClick: onClickBackToList, icon: <LowPriorityIcon /> },
            ],
          })
          setClear(false)
        },
        undefined,
        store
      )
      MerchantUserValidation.resetErrorMessages()
    } else {
      setErrorVersion(MerchantUserValidation.getVersion())
    }
  }

  function onClickClear() {
    setClear(true)
  }

  useEffect(() => {
    if (clear === true) {
      getOne(
        constants.PATH.MERCHANT.MERCHANT_USER + '/' + params.id,
        undefined,
        (payload) => {
          dispatch(setMerchantUser(payload))
          setReady(true)
          setClear(false)
        },
        undefined,
        store
      )
    }
    MerchantUserValidation.resetErrorMessages()
  }, [clear]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    ready && (
      <>
        <EditCreateDisplay
          code={savedMerchantUser && savedMerchantUser.username ? savedMerchantUser.username : ''}
          resource={resource}
          tabs={tabs}
          content={content}
          onChangeInput={onChangeInput}
          bottomBtns={bottomBtns}
          display="edit"
        />
        {dialog && dialog.open && <GlobalDialog dialogInfo={dialog} />}
        <ResetUsernameAndPasswordDialog
          prevUsername={savedMerchantUser.username}
          onChangeInput={onChangeInput}
          openResetDialog={openResetDialog}
          setOpenResetDialog={setOpenResetDialog}
          onClickReset={onClickReset}
          resource={resource}
        />
      </>
    )
  )
}
