import { constants } from '../../Constants/constants'
import { BannerList } from '../Banner/BannerList'

export const LandingBannerList = ({ changeUrlQueryString }) => {
  return (
    <BannerList
      changeUrlQueryString={changeUrlQueryString}
      newListMainTitle={{ resource: 'Landing', key: 'landingBannerList' }}
      newPath={constants.PATH.LANDING.LANDING_BANNER}
      newListTitles={[
        {
          key: constants.LIST_DISPLAY_TYPE.STRING,
          value: 'regionName',
          sort: false,
        },
        {
          key: constants.LIST_DISPLAY_TYPE.STRING,
          value: 'cityName',
          sort: false,
        },
        {
          key: constants.LIST_DISPLAY_TYPE.STRING,
          value: 'type',
          sort: true,
        },
        {
          key: constants.LIST_DISPLAY_TYPE.IMAGE,
          value: 'enImage',
          sort: false,
        },
        {
          key: constants.LIST_DISPLAY_TYPE.IMAGE,
          value: 'tcImage',
          sort: false,
        },
        {
          key: constants.LIST_DISPLAY_TYPE.IMAGE,
          value: 'scImage',
          sort: false,
        },
        // {
        //   key: constants.LIST_DISPLAY_TYPE.STRING,
        //   value: "name",
        //   sort: false
        // },
        {
          key: constants.LIST_DISPLAY_TYPE.STRING,
          value: 'actionType',
          sort: true,
        },
        {
          key: constants.LIST_DISPLAY_TYPE.DATE_TIME,
          value: 'startDisplayDate',
          sort: false,
        },
        {
          key: constants.LIST_DISPLAY_TYPE.DATE_TIME,
          value: 'endDisplayDate',
          sort: false,
        },
        {
          key: constants.LIST_DISPLAY_TYPE.STRING,
          value: 'sequence',
          sort: true,
        },
        {
          key: constants.LIST_DISPLAY_TYPE.STRING,
          value: 'status',
          sort: true,
        },
      ]}
      editBtn={true}
      createBtn={true}
    />
  )
}
