import { PRODUCT } from './types'
import { sify } from 'chinese-conv'
import _ from 'lodash'

import { productSave } from '../Component/ProductCreate/Product/productSave'
import store from '../store'
import { getI18nObjValue } from '../helper/i18n'

export const PRODUCT_CATEGORY_ACTIONS = {
    SET_CITY: 'SET_CITY',
    SET_REGION: 'SET_REGION',
}

export const setProductDefault = () => {
    return {
        type: PRODUCT.SET_PRODUCT_DEFAULT,
    }
}

export const setProduct = (product) => {
    return {
        type: PRODUCT.SET_PRODUCT,
        product: product,
    }
}

export const setProductStepError = (productStepError) => {
    return {
        type: PRODUCT.SET_PRODUCT_STEP_ERROR_VALUE,
        productStepError: productStepError,
    }
}

export const setProductValue = (draftType, originalProduct, key, value, index, type) => {
    let product = _.cloneDeep(originalProduct)

    switch (draftType) {
        case 'CAT':
            // Moved Function to setProductCategory
            product.draft.category = setProductCategory(product.draft.category, key, value)
            break
        case 'REDEEM':
            product = setProductRedeem(product, key, value)
            break
        case 'PRODUCT':
            product = setProductProduct(product, key, value, index)
            break
        case 'REDEEM_INFO':
            product.draft.redeemGroup = value.redeemGroup
            product.draft.redeemGroupList = value.redeemGroupList
            break
        case 'EXISTING_REDEEM_GROUP':
            product.draft.redeemGroup = value.redeemGroup
            product.draft.existingRedeemGroupList = value.existingRedeemGroupList
            break
        case 'PRODUCT_INFO':
            product.draft.product = value.product
            product.draft.productList = value.productList
            product.draft.subProductList = value.subProductList
            product.draft.productCode = ''
            product.draft.lotInventorySet = false
            product.draft.lotInventory = null
            break
        case 'LOT_INVENTORY':
            product.draft.productList = value.productList
            product.draft.productCode = value.productCode
            product.draft.productType = value.productType
            product.draft.lotInventory = value.lotInventory
            product.draft.lotInventorySet = value.lotInventorySet
            product.draft.redeemCodeApiType = value.redeemCodeApiType
            break
        case 'LOT_INVENTORY_SUB':
            product.draft.subProductList = value.subProductList
            product.draft.productCode = value.productCode
            product.draft.productType = value.productType
            product.draft.lotInventory = value.lotInventory
            product.draft.lotInventorySet = value.lotInventorySet
            break
        case 'DRAFT':
            product.draft[key] = value
            break
        default:
            product.draft = value
    }
    if (type !== 'delete' && product.draft.product && product.draft.product.productCode) {
        product.draft = productSave(_.cloneDeep(product))
    }

    return {
        type: PRODUCT.SET_PRODUCT_VALUE,
        product: product,
    }
}

export const setProductCategory = (category, key, value) => {
    const guluLang = store.getState().lang.ui.lang
    const newCategory = _.cloneDeep(category)
    const isSelectedPreRegion = newCategory.regionCode === value?.regionCode

    switch (key) {
        case 'channelNameTranslate':
            newCategory.tcName = value.tcName
            newCategory.scName = value.scName
            break
        case 'descriptionTranslate':
            newCategory.tcDescription = value.tcDescription
            newCategory.scDescription = value.scDescription
            break
        case 'scNameDisable':
            newCategory[key] = value

            if (value) {
                newCategory.scName = newCategory.tcName
                    ? sify(newCategory.tcName)
                    : newCategory.tcName
            }

            break
        case 'scDescriptionDisable':
            newCategory[key] = value
            if (value) {
                newCategory.scDescription = newCategory.tcDescription
                    ? sify(newCategory.tcDescription)
                    : newCategory.tcDescription
            }
            break
        case 'channelSelect':
            newCategory.categoryCode = value.categoryCode
            newCategory.channelSave = value.channelSave
            break
        case 'channelCreate':
            newCategory.channel = value.channel
            newCategory.channelSave = value.channelSave
            break
        case 'refCategory':
            newCategory.refCategoryCode = value.refCategoryCode
            newCategory.refCategoryTcName = value.refCategoryTcName
            newCategory.refCategoryId = value.refCategoryId
            break
        case PRODUCT_CATEGORY_ACTIONS.SET_REGION:
            newCategory.regionCode = value?.regionCode || ''
            newCategory.regionName = getI18nObjValue(guluLang, value?.name) || ''
            newCategory.cityCode = isSelectedPreRegion ? newCategory?.cityCode : null
            newCategory.cityName = isSelectedPreRegion ? newCategory?.cityName : null
            break
        case PRODUCT_CATEGORY_ACTIONS.SET_CITY:
            newCategory.cityCode = value?.cityCode || ''
            newCategory.cityName = getI18nObjValue(guluLang, value?.name) || ''
            break
        default:
            newCategory[key] = value
    }

    return {
        type: PRODUCT.SET_PRODUCT_CATEGORY,
        category: newCategory,
    }
}

const setProductRedeem = (product, key, value) => {
    const originalProduct = _.cloneDeep(product)
    const redeem = _.cloneDeep(originalProduct.draft.redeemGroup)

    switch (key) {
        // case "changeRedeemType":
        //     redeem.type = value.type;
        //     redeem.startRedeemTimestamp = value.startRedeemTimestamp;
        //     redeem.endRedeemTimestamp = value.endRedeemTimestamp;
        //     redeem.startAfterDay = value.startAfterDay;
        //     redeem.endAfterDay = value.endAfterDay;
        //     break;
        case 'changeRedeemType':
            redeem.type = value.type
            redeem.startRedeemTimestamp = value.startRedeemTimestamp
            redeem.endRedeemTimestamp = value.endRedeemTimestamp
            redeem.startAfterDay = value.startAfterDay
            redeem.endAfterDay = value.endAfterDay
            redeem.selectLocation = value.selectLocation
            redeem.selectDate = value.selectDate
            redeem.selectTime = value.selectTime
            redeem.redeemLocationList = value.redeemLocationList
            break
        // case "changeSelectLocation":
        //     redeem.selectLocation = value.selectLocation;
        //     redeem.selectDate = value.selectDate;
        //     redeem.selectTime = value.selectTime;
        //     redeem.redeemLocationList = value.redeemLocationList;
        //     break;
        default:
            redeem[key] = value
    }
    originalProduct.draft.redeemGroup = redeem

    originalProduct.draft.redeemGroupList = originalProduct.draft.redeemGroupList.map(
        (tempRedeemGroup) => {
            if (tempRedeemGroup.redeemGroupCode === redeem.redeemGroupCode) {
                tempRedeemGroup = redeem
            }

            return tempRedeemGroup
        }
    )

    return originalProduct
}

const setProductProduct = (product, key, value, index) => {
    const originalProduct = _.cloneDeep(product)
    const tempProductDraft = _.cloneDeep(product.draft.product)
    switch (key) {
        case 'brandCode':
            tempProductDraft.brandCode = value.brandCode
            tempProductDraft.brandTcName = value.brandTcName
            tempProductDraft.brandName = value.brandTcName
            break
        case 'systemGroupId':
            tempProductDraft.systemGroupId = value.systemGroupId
            tempProductDraft.systemGroupName = value.systemGroupName
            break
        case 'packageType':
            tempProductDraft.type = value.type
            tempProductDraft.subProductMapList = value.subProductMapList
            tempProductDraft.redeemGroupCode = value.redeemGroupCode
            tempProductDraft.searchable = value.searchable
            tempProductDraft.shareable = value.shareable
            tempProductDraft.productCode = value.productCode
            tempProductDraft.packageCashCoupon = value.packageCashCoupon
            break
        case 'productNameTranslate':
            tempProductDraft.tcName = value
            tempProductDraft.scName = sify(value)
            break
        case 'productDescriptionTranslate':
            tempProductDraft.tcDescription = value
            tempProductDraft.scDescription = sify(value)
            break
        case 'productTacTranslate':
            tempProductDraft.tcTac = value
            tempProductDraft.scTac = sify(value)
            break
        case 'productRedeemDescriptionTranslate':
            tempProductDraft.tcRedeemDescription = value
            tempProductDraft.scRedeemDescription = sify(value)
            break
        case 'productDiscountDescriptionTranslate':
            tempProductDraft.tcDiscountDescription = value
            tempProductDraft.scDiscountDescription = sify(value)
            break
        case 'scNameDisable':
            tempProductDraft[key] = value
            if (value) {
                tempProductDraft.scName = tempProductDraft.tcName
                    ? sify(tempProductDraft.tcName)
                    : tempProductDraft.tcName
            }
            break
        case 'scDescriptionDisable':
            tempProductDraft[key] = value
            if (value) {
                tempProductDraft.scDescription = tempProductDraft.tcDescription
                    ? sify(tempProductDraft.tcDescription)
                    : tempProductDraft.tcDescription
            }
            break
        case 'scTacDisable':
            tempProductDraft[key] = value
            if (value) {
                tempProductDraft.scTac = tempProductDraft.tcTac
                    ? sify(tempProductDraft.tcTac)
                    : tempProductDraft.tcTac
            }
            break
        case 'scRedeemDescriptionDisable':
            tempProductDraft[key] = value
            if (value) {
                tempProductDraft.scRedeemDescription = tempProductDraft.tcRedeemDescription
                    ? sify(tempProductDraft.tcRedeemDescription)
                    : tempProductDraft.tcRedeemDescription
            }
            break
        case 'scDiscountDescriptionDisable':
            tempProductDraft[key] = value
            if (value) {
                tempProductDraft.scDiscountDescription = tempProductDraft.tcDiscountDescription
                    ? sify(tempProductDraft.tcDiscountDescription)
                    : tempProductDraft.tcDiscountDescription
            }
            break
        default:
            tempProductDraft[key] = value
    }

    originalProduct.draft.product = tempProductDraft

    originalProduct.draft.productList = originalProduct.draft.productList.map((tempProduct) => {
        if (tempProduct.productCode === tempProductDraft.productCode) {
            tempProduct = tempProductDraft
        }

        return tempProduct
    })

    originalProduct.draft.subProductList = originalProduct.draft.subProductList.map(
        (tempSubProduct) => {
            if (tempSubProduct.productCode === tempProductDraft.productCode) {
                tempSubProduct = tempProductDraft
            }

            return tempSubProduct
        }
    )

    return originalProduct
}
