import { Box, Button, Stack } from '@mui/material'
import SharedDialog from '../../Component/common/dialog/SharedDialog'
import MUIInput from '../../Component/common/field/MUIInput'
import GridContainer from '../../Component/common/field/GridContainer'
import ColorPicker from '../../Component/common/field/ColorPicker'
import { useEffect, useRef, useState } from 'react'
import MultiLangInput from '../../Component/common/field/MultiLangInput'
import { getOne, newPut, post, put } from '../../helper/baseFetch'
import { API_PATH } from '../../Constants/path'
import { useStore } from 'react-redux'
import { Clear, Save } from '@mui/icons-material'
import MenuButton from '../../Component/common/button/MenuButton'
import { LANG } from '../../Constants/lang'
import { STATUS_OPTIONS } from '../../Constants/option'
import MUISelect from '../../Component/common/field/MUISelect'
import { Icon } from '@iconify/react'
import { ICONIFY } from '../../Constants/iconify'
import Iconify from '../../Component/common/iconify/Iconify'

const REGION_DEFAULT = {
    regionCode: '',
    name: LANG,
    backgroundColor: '#ffffff',
    sequence: '',
    status: 'A',
}

export default function Region({
    id,
    isCreateMode,
    showRegion,
    setShowRegion,
    getRegionList,
    setShowSaveDialog,
}) {
    const store = useStore()
    const [showStatusDialog, setShowStatusDialog] = useState(false)
    const statusRef = useRef()

    const [region, setRegion] = useState(REGION_DEFAULT)

    const [error, setError] = useState({})

    const onChangeInput = (key, value) => {
        setRegion({ ...region, [key]: value })
    }

    const onChangeMultiLangInput = (objKey, key, value) => {
        setRegion({ ...region, [objKey]: { ...region[objKey], [key]: value } })
    }

    const onClickSave = () => {
        if (isCreateMode) {
            post(
                API_PATH.region,
                region,
                () => {
                    setShowRegion(false)
                    setShowSaveDialog(true)
                    getRegionList()
                },
                undefined,
                store
            )
        } else {
            put(
                `${API_PATH.region}/${id}`,
                region,
                () => {
                    setShowRegion(false)
                    setShowSaveDialog(true)
                    getRegionList()
                },
                undefined,
                store
            )
        }
    }

    const onClickClear = () => {
        if (isCreateMode) {
            setRegion(REGION_DEFAULT)
        } else {
            getRegion()
        }
    }

    const onClickClose = () => {
        setShowRegion(false)
    }

    const getRegion = () => {
        getOne(
            `${API_PATH.region}/${id}`,
            undefined,
            (payload) => {
                setRegion(payload)
            },
            undefined,
            store
        )
    }

    const validateRegion = () => {
        let newError = {}

        if (!region.regionCode) {
            newError.regionCode = 'Required'
        }

        if (!region.sequence) {
            newError.sequence = 'Required'
        }

        setError(newError)
    }

    useEffect(() => {
        if (!isCreateMode) {
            getRegion()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        validateRegion()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [region])

    return (
        <>
            <SharedDialog
                open={showRegion}
                onClose={onClickClose}
                title={`Region ${isCreateMode ? 'Create' : 'Edit'}`}
                content={
                    <GridContainer>
                        <MUIInput
                            label='Region Code'
                            value={region.regionCode}
                            onChange={(e) => onChangeInput('regionCode', e.target.value)}
                            name='regionCode'
                            disabled={!isCreateMode}
                            required
                            error={error.regionCode}
                        />
                        <MultiLangInput
                            langObj={region.name}
                            onChangeMultiLangInput={onChangeMultiLangInput}
                            objKey='name'
                            label='Name'
                        />
                        <MUIInput
                            label='Background Color'
                            endAdornment={
                                <ColorPicker
                                    color={region.backgroundColor}
                                    onChangeColor={(color) =>
                                        setRegion({ ...region, backgroundColor: color })
                                    }
                                />
                            }
                            value={region.backgroundColor}
                            onChange={(e) => onChangeInput('backgroundColor', e.target.value)}
                            name='backgroundColor'
                        />
                        <MUIInput
                            label='Sequence'
                            value={region.sequence}
                            onChange={(e) => onChangeInput('sequence', e.target.value)}
                            name='sequence'
                            error={error.sequence}
                        />
                        {isCreateMode && (
                            <MUISelect
                                onChange={(option) => onChangeInput('status', option.value)}
                                label='Status'
                                name='status'
                                value={region.status}
                                options={STATUS_OPTIONS}
                            />
                        )}
                    </GridContainer>
                }
                actions={
                    <Stack
                        justifyContent={isCreateMode ? 'flex-end' : 'space-between'}
                        direction='row'
                        sx={{ width: '100%' }}
                    >
                        {!isCreateMode && (
                            <MenuButton
                                button={{
                                    variant: 'contained',
                                    label: `Switch Status: ${region.status}`,
                                }}
                                options={STATUS_OPTIONS}
                                onChange={(value) => {
                                    statusRef.current = value
                                    setShowStatusDialog(true)
                                }}
                                value={region.status}
                            />
                        )}
                        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
                            <Button
                                variant='contained'
                                onClick={onClickSave}
                                startIcon={<Save />}
                                disabled={Object.keys(error).length > 0}
                            >
                                Save
                            </Button>
                            <Button
                                variant='contained'
                                onClick={onClickClear}
                                startIcon={<Clear />}
                            >
                                Clear
                            </Button>
                        </Box>
                    </Stack>
                }
                maxWidth='lg'
            />
            {showStatusDialog && (
                <SharedDialog
                    open={showStatusDialog}
                    onClose={() => setShowStatusDialog(false)}
                    title=''
                    content={
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            Are you sure to change the status?
                        </Box>
                    }
                    maxWidth='md'
                    actions={
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
                            <Button
                                startIcon={<Iconify icon={ICONIFY.tick} />}
                                variant='contained'
                                onClick={() => {
                                    newPut({
                                        path: `${API_PATH.regionStatus}/${id}`,
                                        body: {
                                            status: statusRef.current,
                                        },
                                        successCallback: () => {
                                            setShowStatusDialog(false)
                                            setRegion({ ...region, status: statusRef.current })
                                            getRegionList()
                                        },
                                        store: store,
                                    })
                                }}
                            >
                                OK
                            </Button>
                            <Button
                                variant='contained'
                                onClick={() => setShowStatusDialog(false)}
                                startIcon={<Iconify icon={ICONIFY.close} />}
                            >
                                Discard
                            </Button>
                        </Box>
                    }
                />
            )}
        </>
    )
}
