import { Box } from '@mui/material'

export default function GridContainer({ children }) {
    return (
        <Box
            sx={{
                gridColumn: '1 / -1',
                display: 'grid',
                gridTemplateColumns: {
                    xs: 'repeat(1, 1fr)',
                    sm: 'repeat(2, 1fr)',
                    md: 'repeat(3, 1fr)',
                },
                columnGap: 1,
                rowGap: 4,
                py: 1,
                pl: '3.5px',
                flex: 1,
                pr: '8.34%',
            }}
        >
            {children}
        </Box>
    )
}
