// import { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { useHistory } from 'react-router-dom'

import AddIcon from '@mui/icons-material/Add'
import Card from '@mui/material/Card'
import SwapVertIcon from '@mui/icons-material/SwapVert'
import EditIcon from '@mui/icons-material/Edit'
import CancelIcon from '@mui/icons-material/Cancel'

import { constants as cons, LANDING_ACTION } from '../../../../Constants/constants'
import { setLandingSection } from '../../../../actions/landingSectionAction'
import { setLoading } from '../../../../actions/systemAction'
import { TempBtn } from '../../../../Component/Input/TempBtn'
import validation from '../../../../validations/landingSectionValidation'
import { landingSectionValidationConstants as validationCons } from '../../../../Constants/validationConstants'
import { renderLandingSectionListItem } from './landingSectionUtil'
import Switch from '@mui/material/Switch'
import { getString } from '../../../../helper/util'
import FormControlLabel from '@mui/material/FormControlLabel'

const resource = 'LandingSection'

const DragnDropList = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const savedLandingSection = useSelector((state) => state.landingSection.ui.landingSection)

    function onDragEnd(result) {
        dispatch(setLoading(true))

        let newLandingSection = _.cloneDeep(savedLandingSection)

        if (!result.destination) return

        const [reorderedItem] = newLandingSection.splice(result.source.index, 1)
        newLandingSection.splice(result.destination.index, 0, reorderedItem)

        dispatch(setLandingSection(newLandingSection))
        dispatch(setLoading(false))
    }

    const onClickEdit = (item) => {
        history.push(`/${cons.PATH.LANDING.LANDING_SECTION}/${item.id}/0`)
    }

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId='templateValue'>
                {(provided) => {
                    return (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={{ overflowY: 'scroll', maxHeight: '80vh' }}
                        >
                            {savedLandingSection &&
                                savedLandingSection.length > 0 &&
                                savedLandingSection.map((landingSection, idx) => {
                                    const id = landingSection.id || landingSection.customId
                                    return (
                                        <Draggable
                                            key={landingSection.id}
                                            draggableId={'' + landingSection.id}
                                            index={idx}
                                        >
                                            {(provided) => {
                                                return (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                    >
                                                        <Card
                                                            key={idx}
                                                            className='landing-image-list image-card-background'
                                                        >
                                                            {validation.getErrorMessages(
                                                                `${id}-${validationCons.KEY_ERROR}`
                                                            ) && (
                                                                <div className='error-message'>
                                                                    <CancelIcon
                                                                        className='error-message-icon'
                                                                        fontSize={cons.SIZE.SMALL}
                                                                    />
                                                                    {validation.getErrorMessages(
                                                                        `${id}-${validationCons.KEY_ERROR}`
                                                                    )}
                                                                </div>
                                                            )}
                                                            <div className='landing-image-list-content'>
                                                                <SwapVertIcon
                                                                    color={cons.STYLE.PRIMARY}
                                                                    fontSize={cons.SIZE.LARGE}
                                                                />
                                                                <div style={{ width: '100%' }}>
                                                                    {renderLandingSectionListItem(landingSection)}
                                                                </div>
                                                                {landingSection.action !==
                                                                    LANDING_ACTION.HISTORICAL_SHOP &&
                                                                    landingSection.action !==
                                                                        LANDING_ACTION.SEARCH_SUGGESTION &&
                                                                    landingSection.sectionType !==
                                                                        'ADS' && (
                                                                        <div className='landing-image-btn'>
                                                                            <TempBtn
                                                                                btnSetting={{
                                                                                    onClick: () =>
                                                                                        onClickEdit(
                                                                                            landingSection,
                                                                                            idx
                                                                                        ),
                                                                                    color: cons
                                                                                        .STYLE
                                                                                        .PRIMARY,
                                                                                    label: {
                                                                                        key: 'edit',
                                                                                    },
                                                                                    icon: (
                                                                                        <EditIcon />
                                                                                    ),
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    )}
                                                            </div>
                                                        </Card>
                                                    </div>
                                                )
                                            }}
                                        </Draggable>
                                    )
                                })}
                            {provided.placeholder}
                        </div>
                    )
                }}
            </Droppable>
        </DragDropContext>
    )
}

export const LandingSectionPreview = ({
    setCurrentEdit,
    currentEdit,
    onlyPublishing,
    setOnlyPublishing,
    onClickClear,
}) => {
    const history = useHistory()
    const lang = useSelector((state) => state.lang.ui.lang)
    const theme = useSelector((state) => state.theme.ui.theme)

    const handleAddItem = () => {
        history.push(`/${cons.PATH.LANDING.LANDING_SECTION}/create/0`)
    }

    const onTogglePublishing = () => {
        setOnlyPublishing((prev) => !prev)
        onClickClear()
    }

    return (
        <>
            <div className='landing-section-preview-wrapper'>
                <div className='title'>
                    <FormControlLabel
                        control={<Switch checked={onlyPublishing} onChange={onTogglePublishing} />}
                        label={getString(lang, resource, 'publishing', theme)}
                    />
                    <TempBtn
                        btnSetting={{
                            color: cons.STYLE.PRIMARY,
                            className: 'brand-btn margin-16-4',
                            label: { key: 'create' },
                            //disabled: disableInput,
                            onClick: handleAddItem,
                            icon: <AddIcon />,
                        }}
                    />
                </div>
                <DragnDropList setCurrentEdit={setCurrentEdit} currentEdit={currentEdit} />
            </div>
        </>
    )
}
