import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'

import { Drawer } from '@mui/material'

import ClearIcon from '@mui/icons-material/Clear'

import { setProduct, setProductValue } from '../../../actions/productCreateAction'
import { constants as cons } from '../../../Constants/constants'
import ProductCreateValidation from '../../../validations/productCreateValidation'
import { productValidationConstants } from '../../../Constants/validationConstants'
import { setLoading } from '../../../actions/systemAction'
import { getRedeemGroup, ProductExistingRedeemGroupList } from '../Redeem/ProductExistingRedeemGroupList'
import { TempAutoSelectInput } from '../../Input/TempAutoSelectInput'
import { TempTextInput } from '../../Input/TempTextInput'
import { TempBtn } from '../../Input/TempBtn'
import { getString } from '../../../helper/util'
import { Launch } from '@mui/icons-material'
import { useStore } from 'react-redux'
import { PRODUCT_STEPS } from '../ProductSteps'

export const ProductRedeemGroup = ({ disableInput, isEmbeded }) => {
  const dispatch = useDispatch()
  const savedProduct = useSelector((state) => state.product.ui.product)
  const lang = useSelector((state) => state.lang.ui.lang)
  const theme = useSelector((state) => state.theme.ui.theme)
  const store = useStore()

  const [showRedeemGroupCodeDrawer, setShowRedeemGroupCodeDrawer] = useState(false)

  const onClickOpenRedeemGroupDrawer = () => {
    dispatch(setLoading(true))
    setShowRedeemGroupCodeDrawer(true)
    dispatch(setLoading(false))
  }

  const handleRedeemGroupCloseClick = () => {
    dispatch(setLoading(true))
    setShowRedeemGroupCodeDrawer(false)
    dispatch(setLoading(false))
  }

  function onChangeRedeemGroup(name, redeemGroupCode) {
    if (savedProduct.draft.product.id) {
      let newDraft = _.cloneDeep(savedProduct.draft)
      newDraft.product.redeemGroupCodeDescription = redeemGroupCode.codeDescription
      newDraft.product.redeemGroupCode = redeemGroupCode.redeemGroupCode
      newDraft.product.redeemGroupId = redeemGroupCode.id
      dispatch(setProductValue('', savedProduct, '', newDraft))
    } else {
      dispatch(setProductValue(cons.ACTION_KEY.PRODUCT.PRODUCT, savedProduct, 'redeemGroupCode', redeemGroupCode))
    }
  }

  const selectableRedeemGroupList = []

  if (savedProduct.draft.product) {
    const tempRedeemGroupList = savedProduct.draft.existingRedeemGroupList
      ? savedProduct.draft.redeemGroupList.concat(savedProduct.draft.existingRedeemGroupList)
      : savedProduct.draft.redeemGroupList
    tempRedeemGroupList.forEach((item) => {
      if (
        (savedProduct.draft.product.type === cons.PRODUCT_TYPE.PREORDER_ITEM && item.selectLocation) ||
        (savedProduct.draft.product.type !== cons.PRODUCT_TYPE.PREORDER_ITEM && !item.selectLocation)
      ) {
        selectableRedeemGroupList.push({ name: item.redeemGroupCode + '   ' + item.codeDescription, value: item.redeemGroupCode, id: item.id })
      }
    })
  }

  // useEffect(() => {
  //   console.log('savedProduct', savedProduct)
  // }, [])

  const onBackToRedeemGroup = async () => {
    const newSavedProduct = _.cloneDeep(savedProduct)
    const id = selectableRedeemGroupList.find((item) => item.value === savedProduct.draft.redeemGroup.redeemGroupCode).id
    const data = await getRedeemGroup(id, store)
    data.redeemDisable = true
    dispatch(setProductValue(cons.ACTION_KEY.PRODUCT.DRAFT, savedProduct, 'redeemGroup', data))
    const redeemGroupStepNum = PRODUCT_STEPS.findIndex((item) => item === 'Redeem Group')
    dispatch(setProduct({ ...newSavedProduct, draft: { ...newSavedProduct.draft, step: redeemGroupStepNum } }))
  }

  return (
    <>
      {!savedProduct.draft.product.id ? (
        <TempAutoSelectInput
          inputSetting={{
            className: 'edit-create-input',
            variant: cons.STYLE.VARIANT.OUTLINED,
            type: cons.TYPE.SELECT,
            selectValue: selectableRedeemGroupList,
            label: { resource: 'productCreateProductList', key: 'redeemGroupCode' },
            value: savedProduct.draft.product.redeemGroupCode,
            name: 'redeemGroupCode',
            disabled: disableInput,
            helperTextIcon: true,
            helperText:
              selectableRedeemGroupList.length === 0
                ? getString(lang, 'productCreateProductList', 'withoutRedeemGroupCode', theme)
                : ProductCreateValidation.getErrorMessages(
                    productValidationConstants.KEY_PRODUCT_REDEEM_GROUP_CODE,
                    savedProduct.draft.product.productCode
                  ).map((error) => {
                    return error
                  }),
            multiline: true,
            endAdornmentIconButton:
              (savedProduct.draft.product.redeemGroupId || savedProduct.draft.product.redeemGroupCode) && !isEmbeded ? (
                <Launch
                  fontSize={cons.SIZE.SMALL}
                  onClick={() =>
                    window.open(
                      `${window.location.origin}/rack_product/redeem_group/${
                        selectableRedeemGroupList.find((item) => item.value === savedProduct.draft.product.redeemGroupCode).id || ''
                      }/0`,
                      '_blank'
                    )
                  }
                />
              ) : (
                <></>
              ),
          }}
          handleChange={onChangeRedeemGroup}
        />
      ) : (
        <>
          <TempTextInput
            inputSetting={{
              className: 'edit-create-input',
              variant: disableInput ? cons.STYLE.VARIANT.STANDARD : null,
              label: { resource: 'productCreateProductList', key: 'redeemGroupCode' },
              value: savedProduct.draft.product.redeemGroupCode + '   ' + savedProduct.draft.product.redeemGroupCodeDescription,
              onClickEndAdornmentIconButton: !disableInput ? onClickOpenRedeemGroupDrawer : undefined,
              clear: !disableInput,
              helperTextIcon: true,
              helperText: ProductCreateValidation.getErrorMessages(
                productValidationConstants.KEY_PRODUCT_REDEEM_GROUP_CODE,
                savedProduct.draft.product.productCode
              ).map((error) => {
                return <div>{error}</div>
              }),
              disabled: true,
              multiline: true,
              endAdornmentIconButtonList:
                savedProduct.draft.product.redeemGroupId && !isEmbeded
                  ? [
                      <Launch
                        fontSize={cons.SIZE.SMALL}
                        onClick={() =>
                          window.open(
                            `${window.location.origin}/rack_product/redeem_group/${savedProduct.draft.product.redeemGroupId || ''}/0`,
                            '_blank'
                          )
                        }
                      />,
                    ]
                  : [],
            }}
            handleChange={onChangeRedeemGroup}
          />
          <Drawer classes={{ paper: 'drawer-margin-left' }} anchor="right" open={showRedeemGroupCodeDrawer} onClose={handleRedeemGroupCloseClick}>
            <ProductExistingRedeemGroupList
              onClickListRow={(item) => {
                onChangeRedeemGroup('redeemGroupCode', item)
                handleRedeemGroupCloseClick()
              }}
              editBtn={false}
              createBtn={false}
              newFilter={{ selectLocation: savedProduct.draft.product.type === cons.PRODUCT_TYPE.PREORDER_ITEM ? true : false }}
              dialogFooterBtn={
                <div className="get-push-card-wrapper">
                  <TempBtn
                    btnSetting={{
                      className: 'edit-create-btn',
                      variant: cons.STYLE.VARIANT.CONTAINED,
                      color: cons.STYLE.PRIMARY,
                      onClick: handleRedeemGroupCloseClick,
                      label: { key: 'close' },
                      disabled: disableInput,
                      icon: <ClearIcon />,
                    }}
                  />
                </div>
              }
            />
          </Drawer>
        </>
      )}
    </>
  )
}
