import { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useDispatch, useSelector, useStore } from 'react-redux'
import _ from 'lodash'

import { makeStyles } from '@mui/styles'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import Typography from '@mui/material/Typography'
import LowPriorityIcon from '@mui/icons-material/LowPriority'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import SaveIcon from '@mui/icons-material/Save'
import DoneIcon from '@mui/icons-material/Done'
import ClearIcon from '@mui/icons-material/Clear'

import { setProduct, setProductValue } from '../../../actions/productCreateAction'
import { ProductTabs } from './ProductTabs'
import { ProductCreateProductLotCreateEdit } from '../ProductLot/ProductLotCreateEdit'
import { constants as cons } from '../../../Constants/constants'
import { put } from '../../../helper/baseFetch'
import { permissionsCheck } from '../../../helper/util'
import { BaseWrapper } from '../../BaseWrapper'
import { productValidationConstants } from '../../../Constants/validationConstants'
import { TempBtn } from '../../../Component/Input/TempBtn'
import { getProduct } from './getProduct'
import { GlobalDialog } from '../../GlobalDialog'

import ProductCreateValidation from '../../../validations/productCreateValidation'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: '100vh',
        overflow: 'none',
    },
    button: {
        marginRight: theme.spacing(1),
    },
    completed: {
        display: 'inline-block',
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    error: {
        display: 'flex',
        justifyContent: 'center',
    },
}))

function getSteps(savedProduct) {
    if (savedProduct?.draft?.product?.type === cons.PRODUCT_TYPE.PACKAGE_SUB_ITEM) {
        return ['Product Detail']
    } else {
        return ['Product Detail', 'Lot Inventory']
    }
}

// Product > Product > Edit Button
export const ProductEdit = () => {
    const savedProduct = useSelector((state) => state.product.ui.product)
    const dispatch = useDispatch()
    const store = useStore()
    const params = useParams()
    const classes = useStyles()
    // const [activeStep, setActiveStep] = useState(0)
    const [completed, setCompleted] = useState(new Set())
    const [ready, setReady] = useState(false)
    const [clear, setClear] = useState(false)
    const [dialog, setDialog] = useState({
        open: false,
        dialogActions: [],
    })
    const steps = getSteps(savedProduct)
    const [errorPage, setErrorPage] = useState([])
    const history = useHistory()
    const [errorVersion, setErrorVersion] = useState(ProductCreateValidation.getVersion())
    const [error, setError] = useState({})

    function renderStep(step) {
        switch (step) {
            case 0:
                return (
                    <div className='step-content-wrapper'>
                        <ProductTabs error={error} />
                    </div>
                )
            case 1:
                return (
                    <div className='step-content-wrappe'>
                        <ProductCreateProductLotCreateEdit />
                    </div>
                )
            default:
                return 'Unknown step'
        }
    }

    const totalSteps = () => {
        return getSteps(savedProduct).length
    }

    const saveDraftDialogClose = () => {
        handleDialog(false)
        history.push('/' + cons.PATH.PRODUCT.PRODUCT)
    }

    const backToEdit = () => {
        setClear(true)
        handleDialog(false)
    }

    const saveDraftFailDialogClose = () => {
        handleDialog(false)
    }

    const lastStep = totalSteps() - 1

    const onClickNextButton = () => {
        const activeStep = savedProduct.draft.step
        const newActiveStep =
            activeStep === lastStep
                ? steps.findIndex((step, i) => !completed.has(i))
                : activeStep + 1

        const newSavedProduct = _.cloneDeep(savedProduct)

        dispatch(
            setProduct({
                ...newSavedProduct,
                draft: { ...newSavedProduct.draft, step: newActiveStep },
            })
        )

        // setActiveStep(newActiveStep);
    }

    const onClickBackButton = () => {
        const newSavedProduct = _.cloneDeep(savedProduct)
        const prevActiveStep = newSavedProduct.draft.step
        dispatch(
            setProduct({
                ...newSavedProduct,
                draft: { ...newSavedProduct.draft, step: prevActiveStep - 1 },
            })
        )

        // setActiveStep((prevActiveStep) => prevActiveStep - 1)
    }

    const onClickStep = (step) => () => {
        const newSavedProduct = _.cloneDeep(savedProduct)
        dispatch(
            setProduct({ ...newSavedProduct, draft: { ...newSavedProduct.draft, step: step } })
        )

        // setActiveStep(step)
    }

    const handleComplete = () => {
        let tempSavedProduct = _.cloneDeep(savedProduct)
        tempSavedProduct.draft.product.lotInventoryList = tempSavedProduct.draft.lotInventory

        ProductCreateValidation.validateProductOnly(tempSavedProduct.draft)

        if (ProductCreateValidation.isValid()) {
            const newCompleted = new Set(completed)
            const activeStep = savedProduct.draft.step
            newCompleted.add(activeStep)
            setCompleted(newCompleted)
            put(
                cons.PATH.PRODUCT.PRODUCT + '/' + tempSavedProduct.draft.product.id,
                tempSavedProduct.draft.product,
                () => {
                    if (savedProduct.draft.product.status !== 'D') {
                        handleDialog(
                            true,
                            { key: 'editSuccess' },
                            'saveDraftDialogClose',
                            'backToEdit'
                        )
                    } else {
                        handleDialog(true, { key: 'editSuccess' }, 'saveDraftDialogClose')
                    }
                },
                undefined,
                store
            )
        } else {
            setErrorVersion(ProductCreateValidation.getVersion())
        }
    }

    const handleUpdateStatus = (status) => {
        put(
            cons.PATH.PRODUCT.PRODUCT_STATUS_UPDATE + '/' + savedProduct.draft.product.id,
            { status: status },
            () => {
                handleDialog(true, { key: 'editSuccess' }, 'saveDraftDialogClose', 'backToEdit')
            },
            undefined,
            store
        )
    }

    const edit = async (productCode) => {
        const data = await getProduct(productCode, store)

        let tempSavedProduct = _.cloneDeep(savedProduct.draft)
        tempSavedProduct.product = data
        tempSavedProduct.lotInventory = data.lotInventoryList
        tempSavedProduct.lotInventorySet = true
        tempSavedProduct.productType = data.type
        tempSavedProduct.productCode = data.productCode
        tempSavedProduct.redeemCodeApiType = data.redeemCodeApiType
        if (tempSavedProduct.product.attributeList.length > 0) {
            tempSavedProduct.product.attributeList.map((attribute, attributeIndex) => {
                if (attribute.optionList && attribute.optionList.length > 0) {
                    attribute.optionList.map((optionItem, optionIndex) => {
                        optionItem.optionValiCode = 'Existing_Option_' + optionIndex
                        return {}
                    })
                }
                return {}
            })
        }
        dispatch(setProductValue('', savedProduct, '', tempSavedProduct))
        setReady(true)
        setClear(false)
    }

    function handleDialog(
        openSaveDraftDialog,
        saveDraftPopUpMessage,
        saveDraftPopUpClose,
        backToEditPopUpClose
    ) {
        setDialog({
            open: openSaveDraftDialog,
            onClose:
                saveDraftPopUpClose === 'saveDraftDialogClose'
                    ? saveDraftDialogClose
                    : saveDraftFailDialogClose,
            dialogTitle: { key: 'dialogTitle' },
            dialogContentText: saveDraftPopUpMessage,
            dialogActions: [
                {
                    label: { key: 'backList' },
                    onClick:
                        saveDraftPopUpClose === 'saveDraftDialogClose'
                            ? saveDraftDialogClose
                            : saveDraftFailDialogClose,
                    icon: <LowPriorityIcon />,
                },
                backToEditPopUpClose !== null && {
                    label: { key: 'backEdit' },
                    onClick: backToEdit,
                    icon: <ArrowBackIcon />,
                },
            ],
        })
    }

    useEffect(() => {
        let error = []
        if (
            ProductCreateValidation.getErrorMessages(productValidationConstants.KEY_PRODUCT_LIST)
                .length > 0 ||
            ProductCreateValidation.productErrorMap.size > 0
        ) {
            error.push(0)
        }
        if (
            ProductCreateValidation.getErrorMessages(productValidationConstants.KEY_PRODUCT_LIST)
                .length > 0 ||
            ProductCreateValidation.productLotErrorMap.size > 0
        ) {
            error.push(1)
        }
        setErrorPage(error)
    }, [errorVersion])

    const validateProduct = () => {
        let newError = {}

        if (!savedProduct.draft.product.regionCode) {
            newError.regionCode = 'Missing Region Code'
        }

        if (!savedProduct.draft.product.cityCode) {
            newError.cityCode = 'Missing City Code'
        }

        setError(newError)
    }

    useEffect(() => {
        ProductCreateValidation.resetErrorMessages()
        edit(params.id)
    }, [clear]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        validateProduct()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [savedProduct])

    return (
        ready && (
            <BaseWrapper>
                <div className={classes.root}>
                    <Stepper
                        className='stepper'
                        alternativeLabel
                        nonLinear
                        activeStep={savedProduct.draft.step}
                    >
                        {steps.map((label, index) => {
                            const stepProps = {}
                            const labelProps = {}
                            for (let i = 0; i < errorPage.length; i++) {
                                if (index === errorPage[i]) {
                                    labelProps.optional = (
                                        <Typography
                                            key={index}
                                            className={classes.error}
                                            variant='caption'
                                            color='error'
                                        >
                                            (Missing/Error Info)
                                        </Typography>
                                    )
                                    if (
                                        index === errorPage[i] &&
                                        index !== savedProduct.draft.step
                                    ) {
                                        labelProps.error = true
                                    }
                                }
                            }
                            return (
                                <Step key={label} {...stepProps} className='step-button'>
                                    <StepLabel onClick={onClickStep(index)} {...labelProps}>
                                        {label}
                                    </StepLabel>
                                </Step>
                            )
                        })}
                    </Stepper>
                    <div>
                        <div className={classes.instructions}>
                            {renderStep(savedProduct.draft.step)}
                        </div>
                        <div className='footer-buttons'>
                            <TempBtn
                                btnSetting={{
                                    className: classes.button,
                                    variant: cons.STYLE.VARIANT.CONTAINED,
                                    color: cons.STYLE.PRIMARY,
                                    onClick: onClickBackButton,
                                    icon: <ArrowBackIcon />,
                                    label: { key: 'back' },
                                    disabled: savedProduct.draft.step === 0,
                                }}
                            />
                            <TempBtn
                                btnSetting={{
                                    className: classes.button,
                                    variant: cons.STYLE.VARIANT.CONTAINED,
                                    color: cons.STYLE.PRIMARY,
                                    onClick: onClickNextButton,
                                    icon: <ArrowForwardIcon />,
                                    label: { key: 'next' },
                                    disabled:
                                        savedProduct.draft.step === lastStep ||
                                        Object.keys(error).length > 0,
                                }}
                            />
                            {permissionsCheck(cons.PERMISSION_KEY.PRODUCT_EDIT_SYSTEM_ADMIN) && (
                                <>
                                    {savedProduct.draft.product.status !==
                                        cons.PRODUCT_EDIT_STATUS.ACTIVE &&
                                        //&& savedProduct.draft.product.type !== constants.PRODUCT_TYPE.VALUE_UP_ECOUPON_ITEM
                                        //&& savedProduct.draft.product.type !== constants.PRODUCT_TYPE.FREE_ECOUPON_ITEM
                                        !savedProduct.draft.product.merchantProduct && (
                                            <TempBtn
                                                btnSetting={{
                                                    className: classes.button,
                                                    variant: cons.STYLE.VARIANT.CONTAINED,
                                                    color: cons.STYLE.PRIMARY,
                                                    onClick: handleComplete,
                                                    icon: <SaveIcon />,
                                                    label: { key: 'save' },
                                                }}
                                            />
                                        )}
                                    {savedProduct.draft.product.status !==
                                        cons.PRODUCT_EDIT_STATUS.TO_BE_APPROVE && (
                                        <TempBtn
                                            btnSetting={{
                                                className: 'publish-button',
                                                variant: cons.STYLE.VARIANT.CONTAINED,
                                                color: cons.STYLE.PRIMARY,
                                                onClick: () => {
                                                    if (
                                                        savedProduct.draft.product.status ===
                                                        cons.PRODUCT_EDIT_STATUS.ACTIVE
                                                    ) {
                                                        handleUpdateStatus(
                                                            cons.PRODUCT_EDIT_STATUS.TO_BE_EDIT
                                                        )
                                                    } else {
                                                        handleUpdateStatus(
                                                            cons.PRODUCT_EDIT_STATUS.ACTIVE
                                                        )
                                                    }
                                                },
                                                icon:
                                                    savedProduct.draft.product.status ===
                                                    cons.PRODUCT_EDIT_STATUS.ACTIVE ? (
                                                        <ClearIcon />
                                                    ) : (
                                                        <DoneIcon />
                                                    ),
                                                label:
                                                    savedProduct.draft.product.status ===
                                                    cons.PRODUCT_EDIT_STATUS.ACTIVE
                                                        ? { key: 'unpublish' }
                                                        : { key: 'publish' },
                                            }}
                                        />
                                    )}
                                </>
                            )}
                            {permissionsCheck(cons.PERMISSION_KEY.PRODUCT_EDIT_FINANCE) &&
                                savedProduct.draft.product.status ===
                                    cons.PRODUCT_EDIT_STATUS.TO_BE_APPROVE && (
                                    <TempBtn
                                        btnSetting={{
                                            className: 'publish-button',
                                            variant: cons.STYLE.VARIANT.CONTAINED,
                                            color: cons.STYLE.PRIMARY,
                                            onClick: () =>
                                                handleUpdateStatus(cons.PRODUCT_EDIT_STATUS.PENDING),
                                            icon: <DoneIcon />,
                                            label: { key: 'approve' },
                                        }}
                                    />
                                )}
                        </div>
                    </div>
                </div>
                {dialog && dialog.open && <GlobalDialog dialogInfo={dialog} />}
            </BaseWrapper>
        )
    )
}
