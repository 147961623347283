import { BANNER_ACTIONS } from '../reducers/bannerReducer'
import { BANNER } from './types'
import _ from 'lodash'

export const setBannerDefault = () => {
    return {
        type: BANNER.SET_BANNER_DEFAULT,
    }
}

export const setBannerList = (bannerList) => {
    return {
        type: BANNER.SET_BANNER_LIST,
        bannerList: bannerList,
    }
}

export const setBanner = (banner) => {
    return {
        type: BANNER.SET_BANNER,
        banner: banner,
    }
}

export const setBannerValue = (key, value) => {
    return {
        type: BANNER_ACTIONS.SET_BANNER_VALUE,
        key,
        value,
    }
}
