import { FOLDER_FIELDS_KEY } from '../Constants/validationConstants'

class FolderValidation {
    version
    errorMap
    folderErrorMap
    valid

    constructor() {
        if (!FolderValidation.instance) {
            this.version = 0
            this.valid = true
            this.errorMap = new Map()
            this.folderErrorMap = new Map()
            FolderValidation.instance = this
        }
        return FolderValidation.instance
    }

    validateEditFolder(folder) {
        this.resetErrorMessages()
        this.handleValidateEditFolder(folder)
        this.version++
    }

    validateCreateFolder(folder) {
        this.resetErrorMessages()
        this.handleValidateCreateFolder(folder)
        this.version++
    }

    resetErrorMessages() {
        this.errorMap = new Map()
        this.folderErrorMap = new Map()
        this.valid = true
    }

    getVersion() {
        return this.version
    }

    isValid() {
        return this.valid
    }

    getErrorMessages(key, uniqueCode) {
        // TODO: Check what is the use of uniqueCode
        if (!uniqueCode) {
            return this.folderErrorMap.has(key) ? this.folderErrorMap.get(key) : ''
        }
    }

    handleValidateEditFolder(folder) {
        if (!folder.enName) {
            this.setFolderErrorMapMessages(FOLDER_FIELDS_KEY.enName, 'Missing EN Name')
        }
        if (!folder.tcName) {
            this.setFolderErrorMapMessages(FOLDER_FIELDS_KEY.tcName, 'Missing TC Name')
        }
        if (!folder.scName) {
            this.setFolderErrorMapMessages(FOLDER_FIELDS_KEY.scName, 'Missing SC Name')
        }
        if (!folder.tcImage.cosFileUrl) {
            this.setFolderErrorMapMessages(FOLDER_FIELDS_KEY.tcImage, 'Missing TC Image')
        }
        if (!folder.scImage.cosFileUrl) {
            this.setFolderErrorMapMessages(FOLDER_FIELDS_KEY.scImage, 'Missing SC Image')
        }
        if (!folder.enImage.cosFileUrl) {
            this.setFolderErrorMapMessages(FOLDER_FIELDS_KEY.enImage, 'Missing EN Image')
        }
        if (!folder.status) {
            this.setFolderErrorMapMessages(FOLDER_FIELDS_KEY.status, 'Missing Status')
        }
    }

    handleValidateCreateFolder(folder) {
        const folderCodeRegex = /^[A-Z\d_]{1,}$/

        if (!folder.folderCode) {
            this.setFolderErrorMapMessages(FOLDER_FIELDS_KEY.folderCode, 'Missing Folder Code')
        } else if (!folder.folderCode.match(folderCodeRegex)) {
            this.setFolderErrorMapMessages(
                FOLDER_FIELDS_KEY.folderCode,
                'Invalid Folder Code (Format: A-Z0-9_)'
            )
        } else if (folder.folderCode.length > 20) {
            this.setFolderErrorMapMessages(
                FOLDER_FIELDS_KEY.folderCode,
                'Username length should be smaller than 20 letter!'
            )
        }
        
        if (!folder.enName) {
            this.setFolderErrorMapMessages(FOLDER_FIELDS_KEY.enName, 'Missing EN Name')
        }
        if (!folder.tcName) {
            this.setFolderErrorMapMessages(FOLDER_FIELDS_KEY.tcName, 'Missing TC Name')
        }
        if (!folder.scName) {
            this.setFolderErrorMapMessages(FOLDER_FIELDS_KEY.scName, 'Missing SC Name')
        }
        if (!folder.tcImage || !folder.tcImage.cosFileUrl) {
            this.setFolderErrorMapMessages(FOLDER_FIELDS_KEY.tcImage, 'Missing TC Image')
        }
        if (!folder.scImage || !folder.scImage.cosFileUrl) {
            this.setFolderErrorMapMessages(FOLDER_FIELDS_KEY.scImage, 'Missing SC Image')
        }
        if (!folder.enImage || !folder.enImage.cosFileUrl) {
            this.setFolderErrorMapMessages(FOLDER_FIELDS_KEY.enImage, 'Missing EN Image')
        }
    }

    setFolderErrorMapMessages(key, errorMessages, valid = false) {
        this.folderErrorMap.set(key, errorMessages)
        // console.log("folderErrorMap", this.folderErrorMap)
        if (!valid) {
            this.valid = false
        }
    }
}

const validation = new FolderValidation()
export default validation
