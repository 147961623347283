import { Card } from '@mui/material'
import { TempBtn } from '../../../Component/Input/TempBtn'
import { constants as cons } from '../../../Constants/constants'
import { TempTextInput } from '../../../Component/Input/TempTextInput'
import { Delete } from '@mui/icons-material'
import { TempAutoSelectInput } from '../../../Component/Input/TempAutoSelectInput'
import { STATUS_OPTIONS } from '../../../Constants/type'

export default function RestaurantCard({ restaurantList, onDeleteRestaurantCard, contentType, restaurant, onChangeInput }) {
  const resource = 'RestaurantMap'

  if (contentType === 'create') {
    return restaurantList.map((restaurant, i) => {
      return (
        <Card key={i} className="image-list">
          <div className="delete-icon">
            <TempBtn
              btnSetting={{
                className: 'edit-create-btn',
                variant: cons.STYLE.VARIANT.CONTAINED,
                color: cons.STYLE.PRIMARY,
                label: { key: 'deleteBtn' },
                onClick: () => onDeleteRestaurantCard(restaurant.restUrlId),
                icon: <Delete />,
              }}
            />
          </div>
          <div className="edit-display-wrapper" key={i}>
            <div>
              <TempTextInput
                inputSetting={{
                  className: 'edit-create-input',
                  variant: cons.STYLE.VARIANT.STANDARD,
                  label: { resource: resource, key: 'restUrlId' },
                  value: restaurant.restUrlId,
                  name: 'restUrlId',
                  isEndOfLine: false,
                  disabled: true,
                }}
              />
            </div>
            <TempTextInput
              inputSetting={{
                className: 'edit-create-input',
                variant: cons.STYLE.VARIANT.STANDARD,
                label: { resource: resource, key: 'enName' },
                value: restaurant.enName,
                name: 'enName',
                isEndOfLine: false,
                disabled: true,
              }}
            />
            <TempTextInput
              inputSetting={{
                className: 'edit-create-input',
                variant: cons.STYLE.VARIANT.STANDARD,
                label: { resource: resource, key: 'tcName' },
                value: restaurant.tcName,
                name: 'tcName',
                isEndOfLine: false,
                disabled: true,
              }}
            />
            <TempTextInput
              inputSetting={{
                className: 'edit-create-input',
                variant: cons.STYLE.VARIANT.STANDARD,
                label: { resource: resource, key: 'scName' },
                value: restaurant.scName,
                name: 'scName',
                isEndOfLine: false,
                disabled: true,
              }}
            />
          </div>
        </Card>
      )
    })
  }

  if (contentType === 'edit') {
    return (
      <Card className="image-list">
        <div className="edit-display-wrapper">
          <div>
            <TempTextInput
              inputSetting={{
                className: 'edit-create-input',
                variant: cons.STYLE.VARIANT.STANDARD,
                size: cons.SIZE.SMALL,
                label: { resource: resource, key: 'restUrlId' },
                value: restaurant.restUrlId,
                name: 'restUrlId',
                isEndOfLine: false,
                disabled: true,
              }}
            />
          </div>
          <TempTextInput
            inputSetting={{
              className: 'edit-create-input',
              variant: cons.STYLE.VARIANT.STANDARD,
              label: { resource: resource, key: 'enName' },
              value: restaurant.enName,
              name: 'enName',
              isEndOfLine: false,
              disabled: true,
            }}
          />
          <TempTextInput
            inputSetting={{
              className: 'edit-create-input',
              variant: cons.STYLE.VARIANT.STANDARD,
              label: { resource: resource, key: 'tcName' },
              value: restaurant.tcName,
              name: 'tcName',
              isEndOfLine: false,
              disabled: true,
            }}
          />
          <TempTextInput
            inputSetting={{
              className: 'edit-create-input',
              variant: cons.STYLE.VARIANT.STANDARD,
              label: { resource: resource, key: 'scName' },
              value: restaurant.scName,
              name: 'scName',
              isEndOfLine: false,
              disabled: true,
            }}
          />
          <TempAutoSelectInput
            inputSetting={{
              label: { resource: resource, key: 'status' },
              value: restaurant.status,
              selectValue: STATUS_OPTIONS,
              name: 'status',
              disableClearable: true,
            }}
            handleChange={onChangeInput}
          />
        </div>
      </Card>
    )
  }
}
