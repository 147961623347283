import { useState, useEffect } from 'react'
import _ from 'lodash'
import { useStore } from 'react-redux'

import AddIcon from '@mui/icons-material/Add'
import Card from '@mui/material/Card'
import CloseIcon from '@mui/icons-material/Close'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import EditIcon from '@mui/icons-material/Edit'
import CancelIcon from '@mui/icons-material/Cancel'
import { Drawer } from '@mui/material'
import Paper from '@mui/material/Paper'
import ClearIcon from '@mui/icons-material/Clear'
// import { makeStyles } from '@mui/styles';
// import { Drawer } from "@mui/material";
// import Paper from '@mui/material/Paper';
// import ClearIcon from '@mui/icons-material/Clear';
import { constants, IMAGE_TYPE, LANDING_ACTION_PARAMETER, PICTURE_SIZE } from '../../../Constants/constants'
import { landingItemValidationConstants } from '../../../Constants/validationConstants'
import { EditCreateDisplay } from '../../../Component/EditCreateDisplay/EditCreateDisplay'
import { TempTextInput } from '../../../Component/Input/TempTextInput'
import { TempAutoSelectInput } from '../../../Component/Input/TempAutoSelectInput'
import { TempBtn } from '../../../Component/Input/TempBtn'
import { TempCustomCheckBox } from '../../../Component/Custom/TempCustomCheckBox'
import { UploadImage } from '../../../Component/UploadImage/UploadImage'
import { translateName, helperTextStyles, getDisplayActionType, isDeepLinkApplicable } from './landingUtil'
import landingItemValidation from '../../../validations/landingItemValidation'
import { LandingAction } from './LandingAction'
import { LandingItem } from './LandingItem'
import { QueueTemplateColorPicker } from '../../QueueTemplate/QueueTemplateColorPicker'
import { handleLandingActionParameters, parseLandingParameters } from './handleLandingActionParameters'
import { RestaurantList } from '../../Restaurant/RestaurantList'
import { ProductList } from '../../../Component/ProductCreate/Product/ProductList'
import { getOne } from '../../../helper/baseFetch'
import moment from 'moment'

const LOCAL_CONSTANT = {
  ALL_IMAGE: 'allImage',
  EN_IMAGE: 'enImage',
  TC_IMAGE: 'tcImage',
  SC_IMAGE: 'scImage',
}

const ItemPreview = ({ item }) => {
  return (
    <Card key={item.id} className="landing-image-list landing-image-list-edit image-card-background">
      <div className="landing-image-list-content">
        <LandingItem item={item} isPreview={false} hasMinWidth={true} />
      </div>
    </Card>
  )
}

export const LandingItemEdit = ({ item, setItem }) => {
  const helperTextStylesClasses = helperTextStyles()
  const resource = 'LandingItem'
  const [dialog, setDialog] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [errorVersion, setErrorVersion] = useState(landingItemValidation.getVersion())
  const [drawerType, setDrawerType] = useState(null)
  const [drawerOpen, setDrawerOpen] = useState(false)
  const store = useStore()
  const id = item.id ? item.id : item.customId

  const handleDialog = () => {
    setDialog(true)
  }
  const [tempItem, setTempItem] = useState(item)

  function onClickCloseDrawer() {
    setDrawerOpen(false)
  }

  const handleOnClickDrawer = (type) => {
    setDrawerType(type)
    setDrawerOpen(true)
    handleDrawer()
  }

  const handleCopyShopData = (restUrlId) => {
    getOne(
      constants.PATH.LANDING.LANDING_GET_SHOP + '/' + restUrlId,
      null,
      (payload) => {
        let tempt = _.cloneDeep(tempItem)
        tempt = { ...tempt, ...payload }
        tempt.parameters = payload.actionParameters ? parseLandingParameters(payload.actionParameters) : payload.actionParameters
        tempt.displayActionType = getDisplayActionType(JSON.parse(payload.actionParameters))
        setTempItem(tempt)
      },
      undefined,
      store
    )
  }

  const handleCopyProductData = (id) => {
    getOne(
      constants.PATH.LANDING.LANDING_GET_PRODUCT + '/' + id,
      null,
      (payload) => {
        let tempt = _.cloneDeep(tempItem)
        tempt = { ...tempt, ...payload }
        tempt.parameters = payload.actionParameters ? parseLandingParameters(payload.actionParameters) : payload.actionParameters
        tempt.displayActionType = getDisplayActionType(JSON.parse(payload.actionParameters))
        setTempItem(tempt)
      },
      undefined,
      store
    )
  }

  const onClickListRow = (data, type) => {
    switch (drawerType) {
      case 'SHOP':
        handleCopyShopData(data)
        break
      case 'PRODUCT':
        handleCopyProductData(data)
        break
      default:
    }
    //call api
    onClickCloseDrawer()
  }

  const handleDrawer = () => {
    let drawer
    switch (drawerType) {
      case 'SHOP':
        drawer = (
          <Paper>
            <RestaurantList
              editBtn={false}
              createBtn={false}
              btnList={false}
              onClickListRow={(item) => onClickListRow(item.restUrlId, drawerType)}
              dialogFooterBtn={
                <>
                  <TempBtn
                    btnSetting={{
                      variant: constants.STYLE.VARIANT.CONTAINED,
                      color: constants.STYLE.PRIMARY,
                      onClick: onClickCloseDrawer,
                      icon: <ClearIcon />,
                      label: { key: 'close' },
                    }}
                  />
                </>
              }
            />
          </Paper>
        )
        break
      case 'PRODUCT':
        drawer = (
          <Paper>
            <ProductList
              editBtn={false}
              createBtn={false}
              onClickListRow={(item) => onClickListRow(item.id, drawerType)}
              dialogFooterBtn={
                <>
                  <TempBtn
                    btnSetting={{
                      variant: constants.STYLE.VARIANT.CONTAINED,
                      color: constants.STYLE.PRIMARY,
                      onClick: onClickCloseDrawer,
                      icon: <ClearIcon />,
                      label: { key: 'close' },
                    }}
                  />
                </>
              }
            />
          </Paper>
        )
        break
      default:
        break
    }
    return drawer
  }

  const onSelectCheckbox = (type) => {
    const tempt = _.cloneDeep(tempItem)
    switch (type.key) {
      case 'nameCustomize':
        tempt.scTitleDisable = !tempt.scTitleDisable
        if (tempt.scTitleDisable) tempt.scTitle = translateName(tempt.tcTitle)
        break
      case 'nameCustomizeSubTitle':
        tempt.scSubTitleDisable = !tempt.scSubTitleDisable
        if (tempt.scSubTitleDisable) tempt.scSubTitle = translateName(tempt.tcSubTitle)
        break
      default:
        break
    }
    setTempItem(tempt)
  }

  const onChangeInput = (name, e) => {
    let tempt = _.cloneDeep(tempItem)

    if (!tempt.parameters) {
      tempt.parameters = {}
    }

    switch (name) {
      case 'url':
      case 'type':
      case 'title':
      case 'query':
      case 'restUrlId':
      case 'folderCode':
      case 'folderType':
      case 'from':
      case 'categoryCode':
      case 'brandCode':
      case 'name':
      case 'refId':
      case 'code':
      case 'ticketId':
      case 'infoId':
      case 'groupCode':
      case 'youtubeCode':
        tempt.parameters[name] = e
        Object.keys(tempt.parameters).forEach((k) => (tempt.parameters[k] === null || tempt.parameters[k] === '') && delete tempt.parameters[k])
        // tempt.actionParameters = JSON.stringify(tempt.parameters)
        tempItem.action = LANDING_ACTION_PARAMETER.WEB_VIEW
        tempt.actionParameters = handleLandingActionParameters(
          {
            url: tempt.parameters.url,
            action: tempt.parameters.action,
            restUrlId: tempt.parameters.restUrlId,
            ecouponCode: tempt.parameters.ecouponCode,
            folderCode: tempt.parameters.folderCode,
            productParent: tempt.parameters.productParent,
            categoryCode: tempt.parameters.categoryCode,
            brandCode: tempt.parameters.brandCode,
            catProductCode: tempt.parameters.productCode,
            brandProductCode: tempt.parameters.productCode,
            folderType: tempt.parameters.folderType,
            title: tempt.parameters.title,
            type: tempt.parameters.type,
            name: tempt.parameters.name,
            query: tempt.parameters.query,
            from: tempt.parameters.from,
            refId: tempt.parameters.refId,
            code: tempt.parameters.code,
            ticketId: tempt.parameters.ticketId,
            infoId: tempt.parameters.infoId,
            groupCode: tempt.parameters.groupCode,
            showCategoryList: tempt.parameters.showCategoryList,
            showBrandList: tempt.parameters.showBrandList,
            videoId: tempt.parameters.youtubeCode,
          },
          tempt
        )
        // tempt.displayActionType = getDisplayActionType(JSON.parse(tempt.actionParameters))
        break
      case 'urlAction':
        // console.log('e', e)
        tempt.parameters.action = e
        // console.log('tempt.parameters', tempt.parameters)
        // tempt.actionParameters = JSON.stringify(tempt.parameters)
        tempItem.action = LANDING_ACTION_PARAMETER.WEB_VIEW

        const newActionParameters = handleLandingActionParameters(
          {
            url: tempt.parameters.url,
            action: tempt.parameters.action,
            restUrlId: tempt.parameters.restUrlId,
            ecouponCode: tempt.parameters.ecouponCode,
            folderCode: tempt.parameters.folderCode,
            productParent: tempt.parameters.productParent,
            categoryCode: tempt.parameters.categoryCode,
            brandCode: tempt.parameters.brandCode,
            catProductCode: tempt.parameters.productCode,
            brandProductCode: tempt.parameters.productCode,
            folderType: tempt.parameters.folderType,
            title: tempt.parameters.title,
            type: tempt.parameters.type,
            name: tempt.parameters.name,
            query: tempt.parameters.query,
            from: tempt.parameters.from,
            refId: tempt.parameters.refId,
            code: tempt.parameters.code,
            ticketId: tempt.parameters.ticketId,
            infoId: tempt.parameters.infoId,
            groupCode: tempt.parameters.groupCode,
            showCategoryList: tempt.parameters.showCategoryList,
            showBrandList: tempt.parameters.showBrandList,
            videoId: tempt.parameters.youtubeCode,
          },
          tempt
        )

        tempt.actionParameters = newActionParameters
        Object.keys(tempt.parameters).forEach(
          (param) => (tempt.parameters[param] === null || tempt.parameters[param] === '') && delete tempt.parameters[param]
        )
        tempt.parameters = { ...tempt.parameters, ...JSON.parse(newActionParameters) }
        break
      case 'productCode':
      case 'ecouponCode':
        tempt.parameters[`${name}`] = e
        tempt.parameters.brandCode = null
        tempt.parameters.categoryCode = null
        Object.keys(tempt.parameters).forEach((k) => (tempt.parameters[k] === null || tempt.parameters[k] === '') && delete tempt.parameters[k])
        // tempt.actionParameters = JSON.stringify(tempt.parameters)
        tempItem.action = LANDING_ACTION_PARAMETER.WEB_VIEW
        tempt.actionParameters = handleLandingActionParameters(
          {
            url: tempt.parameters.url,
            action: tempt.parameters.action,
            restUrlId: tempt.parameters.restUrlId,
            ecouponCode: tempt.parameters.ecouponCode,
            folderCode: tempt.parameters.folderCode,
            productParent: tempt.parameters.productParent,
            categoryCode: tempt.parameters.categoryCode,
            brandCode: tempt.parameters.brandCode,
            catProductCode: tempt.parameters.productCode,
            brandProductCode: tempt.parameters.productCode,
            folderType: tempt.parameters.folderType,
            title: tempt.parameters.title,
            type: tempt.parameters.type,
            name: tempt.parameters.name,
            query: tempt.parameters.query,
            from: tempt.parameters.from,
            refId: tempt.parameters.refId,
            code: tempt.parameters.code,
            ticketId: tempt.parameters.ticketId,
            infoId: tempt.parameters.infoId,
            groupCode: tempt.parameters.groupCode,
            showCategoryList: tempt.parameters.showCategoryList,
            showBrandList: tempt.parameters.showBrandList,
            videoId: tempt.parameters.youtubeCode,
          },
          tempt
        )
        // tempt.displayActionType = getDisplayActionType(JSON.parse(tempt.actionParameters))
        break
      case 'serviceType':
        tempt.parameters[`${name}`] = e
        if (!e) tempt.parameters.type = null
        Object.keys(tempt.parameters).forEach((k) => (tempt.parameters[k] === null || tempt.parameters[k] === '') && delete tempt.parameters[k])
        tempt.actionParameters = JSON.stringify(tempt.parameters)
        tempt.displayActionType = getDisplayActionType(JSON.parse(tempt.actionParameters))
        break
      case 'subAction':
        tempt.parameters.action = e
        Object.keys(tempt.parameters).forEach((k) => (tempt.parameters[k] === null || tempt.parameters[k] === '') && delete tempt.parameters[k])
        tempt.actionParameters = JSON.stringify(tempt.parameters)
        tempt.displayActionType = getDisplayActionType(JSON.parse(tempt.actionParameters))
        break
      case 'showCategoryBrandList':
        if (e === 'SHOW_CATEGORY_LIST') {
          tempt.parameters.showCategoryList = true
          tempt.parameters.showBrandList = null
          tempt.parameters.brandCode = null
        } else if (e === 'SHOW_BRAND_LIST') {
          tempt.parameters.showBrandList = true
          tempt.parameters.showCategoryList = null
          tempt.parameters.categoryCode = null
        } else {
          tempt.parameters.showBrandList = null
          tempt.parameters.showCategoryList = null
          tempt.parameters.brandCode = null
          tempt.parameters.categoryCode = null
        }
        Object.keys(tempt.parameters).forEach(
          (param) => (tempt.parameters[param] === null || tempt.parameters[param] === '') && delete tempt.parameters[param]
        )
        // tempt.actionParameters = JSON.stringify(tempt.parameters)
        tempItem.action = LANDING_ACTION_PARAMETER.WEB_VIEW
        tempt.actionParameters = handleLandingActionParameters(
          {
            url: tempt.parameters.url,
            action: tempt.parameters.action,
            restUrlId: tempt.parameters.restUrlId,
            ecouponCode: tempt.parameters.ecouponCode,
            folderCode: tempt.parameters.folderCode,
            productParent: tempt.parameters.productParent,
            categoryCode: tempt.parameters.categoryCode,
            brandCode: tempt.parameters.brandCode,
            catProductCode: tempt.parameters.productCode,
            brandProductCode: tempt.parameters.productCode,
            folderType: tempt.parameters.folderType,
            title: tempt.parameters.title,
            type: tempt.parameters.type,
            name: tempt.parameters.name,
            query: tempt.parameters.query,
            from: tempt.parameters.from,
            refId: tempt.parameters.refId,
            code: tempt.parameters.code,
            ticketId: tempt.parameters.ticketId,
            infoId: tempt.parameters.infoId,
            groupCode: tempt.parameters.groupCode,
            showCategoryList: tempt.parameters.showCategoryList,
            showBrandList: tempt.parameters.showBrandList,
            videoId: tempt.parameters.youtubeCode,
          },
          tempt
        )
        break
      case 'displayActionType':
        tempt[name] = e || null
        tempt.action = e ? (isDeepLinkApplicable(e) ? LANDING_ACTION_PARAMETER.WEB_VIEW : e) : null
        tempt.actionParameters = handleLandingActionParameters(
          {
            action: tempt.parameters.displayActionType,
          },
          tempt
        )
        tempt.parameters = tempt.actionParameters ? JSON.parse(tempt.actionParameters) : {}
        landingItemValidation.resetErrorMessages('reset_param')
        break
      case 'tcTitle':
        tempt[name] = e
        if (tempt.scTitleDisable) tempt.scTitle = translateName(e)
        break
      case 'tcSubTitle':
        tempt[name] = e
        if (tempt.scSubTitleDisable) tempt.scSubTitle = translateName(e)
        break
      case 'copyDataFrom':
        if (e === 'SHOP') {
          handleOnClickDrawer('SHOP')
        }

        if (e === 'PRODUCT') {
          handleOnClickDrawer('PRODUCT')
        }

        break
      case 'endDisplayTimestamp':
      case 'startDisplayTimestamp':
        const date = e ? Number(moment(e).format('x')) : null
        tempt[name] = date
        break
      default:
        tempt[name] = e
        break
    }

    setTempItem(tempt)
  }

  const tempSectionEditList = [
    {
      label: { resource: resource, key: 'status' },
      value: tempItem?.status,
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.LANDING_SECTION.STATUS,
      name: 'status',
      disableClearable: true,
      isEndOfLine: true,
      helperTextIcon: true,
      helperText: landingItemValidation.getErrorMessages(`${id}-${landingItemValidationConstants.KEY_STATUS}`),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      label: { resource: resource, key: 'copyDataFrom' },
      value: '',
      type: constants.TYPE.SELECT,
      selectValue: constants.CHOICES.LANDING_SECTION.COPY_DATA_SOURCE,
      name: 'copyDataFrom',
      isEndOfLine: true,
    },
    {
      label: { resource: resource, key: 'enTitle' },
      value: tempItem?.enTitle,
      name: 'enTitle',
      isEndOfLine: false,
      custom: true,
      customName: { key: 'nameCustomize' },
      customValue: tempItem.scTitleDisable,
      helperTextIcon: true,
      helperText: landingItemValidation.getErrorMessages(`${id}-${landingItemValidationConstants.KEY_EN_TITLE}`),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      label: { resource: resource, key: 'tcTitle' },
      value: tempItem?.tcTitle,
      name: 'tcTitle',
      isEndOfLine: false,
      helperTextIcon: true,
      helperText: landingItemValidation.getErrorMessages(`${id}-${landingItemValidationConstants.KEY_TC_TITLE}`),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      label: { resource: resource, key: 'scTitle' },
      value: tempItem?.scTitle,
      name: 'scTitle',
      isEndOfLine: true,
      disabled: tempItem.scTitleDisable,
      helperTextIcon: true,
      helperText: landingItemValidation.getErrorMessages(`${id}-${landingItemValidationConstants.KEY_SC_TITLE}`),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      label: { resource: resource, key: 'enSubTitle' },
      value: tempItem?.enSubTitle,
      name: 'enSubTitle',
      isEndOfLine: false,
      custom: true,
      customName: { resource: resource, key: 'nameCustomizeSubTitle' },
      customValue: tempItem.scSubTitleDisable,
      helperTextIcon: true,
      helperText: landingItemValidation.getErrorMessages(`${id}-${landingItemValidationConstants.KEY_EN_SUB_TITLE}`),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      label: { resource: resource, key: 'tcSubTitle' },
      value: tempItem?.tcSubTitle,
      name: 'tcSubTitle',
      isEndOfLine: false,
      helperTextIcon: true,
      helperText: landingItemValidation.getErrorMessages(`${id}-${landingItemValidationConstants.KEY_TC_SUB_TITLE}`),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      label: { resource: resource, key: 'scSubTitle' },
      value: tempItem?.scSubTitle,
      name: 'scSubTitle',
      isEndOfLine: true,
      disabled: tempItem.scSubTitleDisable,
      helperTextIcon: true,
      helperText: landingItemValidation.getErrorMessages(`${id}-${landingItemValidationConstants.KEY_SC_SUB_TITLE}`),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      picture: tempItem.enImage,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'enImage' },
      name: 'enImage',
      type: constants.TYPE.IMAGE,
      uploadImagePath: constants.PATH.LANDING.LANDING_SECTION_UPLOAD_MEDIA,
      saveImage: handleSaveImage,
      isEndOfLine: false,
    },
    {
      picture: tempItem.tcImage,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'tcImage' },
      name: 'tcImage',
      type: constants.TYPE.IMAGE,
      uploadImagePath: constants.PATH.LANDING.LANDING_SECTION_UPLOAD_MEDIA,
      saveImage: handleSaveImage,
      isEndOfLine: false,
    },
    {
      picture: tempItem.scImage,
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: 'scImage' },
      name: 'scImage',
      type: constants.TYPE.IMAGE,
      uploadImagePath: constants.PATH.LANDING.LANDING_SECTION_UPLOAD_MEDIA,
      saveImage: handleSaveImage,
      isEndOfLine: true,
    },
    {
      label: { resource: resource, key: 'startDisplayTimestamp' },
      value: tempItem?.startDisplayTimestamp,
      type: constants.TYPE.DATETIME_LOCAL,
      step: 1,
      name: 'startDisplayTimestamp',
      isEndOfLine: false,
      helperTextIcon: true,
      helperText: landingItemValidation.getErrorMessages(`${id}-${landingItemValidationConstants.KEY_START_DISPLAY_TIMESTAMP}`),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      label: { resource: resource, key: 'endDisplayTimestamp' },
      value: tempItem?.endDisplayTimestamp,
      type: constants.TYPE.DATETIME_LOCAL,
      step: 1,
      name: 'endDisplayTimestamp',
      isEndOfLine: true,
      helperTextIcon: true,
      helperText: landingItemValidation.getErrorMessages(`${id}-${landingItemValidationConstants.KEY_END_DISPLAY_TIMESTAMP}`),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },

    {
      label: { resource: resource, key: 'foregroundColor' },
      value: tempItem?.foregroundColor,
      name: 'foregroundColor',
      onClickEndAdornmentIconButton: () => {},
      endAdornmentIconButton: (
        <QueueTemplateColorPicker color={tempItem?.foregroundColor} setColor={(color) => onChangeInput('foregroundColor', color)} />
      ),
      isEndOfLine: false,
    },
    {
      label: { resource: resource, key: 'backgroundColor' },
      value: tempItem?.backgroundColor,
      name: 'backgroundColor',
      onClickEndAdornmentIconButton: () => {},
      endAdornmentIconButton: (
        <QueueTemplateColorPicker color={tempItem?.backgroundColor} setColor={(color) => onChangeInput('backgroundColor', color)} />
      ),
      isEndOfLine: true,
    },
    {
      type: constants.TYPE.FUNCTION,
      value: (
        <LandingAction
          actionSelectList={constants.CHOICES.LANDING_SECTION.ITEM_ACTION_TYPE}
          resource={resource}
          tempItem={tempItem}
          onChangeInput={onChangeInput}
          validation={landingItemValidation}
          id={id}
        />
      ),
      isEndOfLine: true,
    },
  ]

  const content = [
    {
      tab: (
        <>
          {tempSectionEditList.map((inputSetting, index) => {
            let content = <></>
            switch (inputSetting.type) {
              case constants.TYPE.SELECT:
                content = <TempAutoSelectInput inputSetting={inputSetting} handleChange={onChangeInput} />
                break
              case constants.TYPE.IMAGE:
                content = (
                  <div className="image">
                    <UploadImage
                      picture={inputSetting.picture}
                      title={inputSetting.label}
                      name={inputSetting.name}
                      uploadImagePath={inputSetting.uploadImagePath}
                      showApplyToAll={true}
                      saveImage={inputSetting.saveImage}
                      //index={savedBanner.customId}
                      required={inputSetting.required}
                      type={IMAGE_TYPE.LANDING_SECTION}
                      size={PICTURE_SIZE.MID}
                    />
                  </div>
                )
                break
              case constants.TYPE.FUNCTION:
                content = inputSetting.value
                break
              default:
                content = <TempTextInput inputSetting={inputSetting} handleChange={onChangeInput} />
            }
            return (
              <div className="edit-display-wrapper" key={index}>
                {inputSetting.name === 'tcImage' && (
                  <>
                    <>
                      {landingItemValidation.getErrorMessages(landingItemValidationConstants.KEY_TC_IMAGE) && (
                        <div className="error-message">
                          <CancelIcon className="error-message-icon" fontSize={constants.SIZE.SMALL} />
                          {landingItemValidation.getErrorMessages(landingItemValidationConstants.KEY_TC_IMAGE)}
                        </div>
                      )}
                    </>
                    <>
                      {landingItemValidation.getErrorMessages(landingItemValidationConstants.KEY_EN_IMAGE) && (
                        <div className="error-message">
                          <CancelIcon className="error-message-icon" fontSize={constants.SIZE.SMALL} />
                          {landingItemValidation.getErrorMessages(landingItemValidationConstants.KEY_EN_IMAGE)}
                        </div>
                      )}
                    </>
                    <>
                      {landingItemValidation.getErrorMessages(landingItemValidationConstants.KEY_SC_IMAGE) && (
                        <div className="error-message">
                          <CancelIcon className="error-message-icon" fontSize={constants.SIZE.SMALL} />
                          {landingItemValidation.getErrorMessages(landingItemValidationConstants.KEY_SC_IMAGE)}
                        </div>
                      )}
                    </>
                  </>
                )}
                {inputSetting.custom && (
                  <TempCustomCheckBox
                    className="customize-btn"
                    value={inputSetting.customValue}
                    resource={resource}
                    customName={inputSetting.customName}
                    onSelectCheckbox={() => onSelectCheckbox(inputSetting.customName)}
                  />
                )}
                {content}
                {inputSetting.isEndOfLine && <div></div>}
              </div>
            )
          })}
        </>
      ),
      btn: false,
    },
  ]

  const handleSubmit = () => {
    landingItemValidation.validateLandingItem(tempItem)
    if (landingItemValidation.isValid()) {
      setItem(tempItem)
      landingItemValidation.resetErrorMessages()
      handleListCloseClick()
    } else {
      setErrorVersion(landingItemValidation.getVersion())
    }
  }

  const handleListCloseClick = () => {
    setDialog(false)
  }

  function handleSaveImage(name, image, index, applyToAll) {
    const tempt = _.cloneDeep(tempItem)

    if (applyToAll) {
      tempt[LOCAL_CONSTANT.EN_IMAGE] = image
      tempt[LOCAL_CONSTANT.SC_IMAGE] = image
      tempt[LOCAL_CONSTANT.TC_IMAGE] = image
    } else {
      tempt[`${name}`] = image
    }

    setTempItem(tempt)
  }

  useEffect(() => {
    setTempItem(item)

    return () => {
      landingItemValidation.resetErrorMessages()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialog])

  return (
    <>
      <TempBtn
        btnSetting={{
          onClick: () => handleDialog(),
          color: constants.STYLE.PRIMARY,
          label: { key: 'edit' },
          icon: <EditIcon />,
        }}
      />
      {dialog && (
        <>
          <Dialog
            open={dialog}
            onClose={handleListCloseClick}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
            maxWidth={'lg'}
            sx={{ zIndex: 100 }}
          >
            <DialogContent>
              <>
                <ItemPreview item={tempItem} />
                <EditCreateDisplay
                  code={item.id}
                  resource={resource}
                  tabs={false}
                  //tabs={tabs}
                  content={content}
                  onChangeInput={onChangeInput}
                  // bottomBtns={bottomBtns}
                  display="edit"
                />
              </>
            </DialogContent>
            <DialogActions>
              <TempBtn btnSetting={{ label: { key: 'next' }, onClick: () => handleSubmit(), icon: <AddIcon /> }} />
              <TempBtn btnSetting={{ label: { key: 'close' }, onClick: () => handleListCloseClick(), icon: <CloseIcon /> }} />
            </DialogActions>
          </Dialog>
        </>
      )}
      <Drawer classes={{ paper: 'drawer-margin-left' }} anchor="right" open={drawerOpen} onClose={onClickCloseDrawer}>
        {handleDrawer()}
      </Drawer>
    </>
  )
}
