import CardTitle from '../../Component/common/card/CardTitle'
import { Box, Tab, Tabs } from '@mui/material'
import { useState } from 'react'
import RegionList from './RegionList'
import CityList from './CityList'
import MUICard from '../../Component/common/card/MUICard'

export default function RegionCity() {
    const [tabIdx, setTabIdx] = useState(0)
    const [filterRegionCode, setFilterRegionCode] = useState('')

    const onChangeTab = (e, newTabIdx) => {
        setTabIdx(newTabIdx)
    }

    const onClickLaunchCityList = (regionCode) => {
        setFilterRegionCode(regionCode)
        setTabIdx(1)
    }

    return (
        <MUICard header={<CardTitle>Region / City</CardTitle>}>
            <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                <Tabs value={tabIdx} onChange={onChangeTab} aria-label=''>
                    <Tab
                        label='Region'
                        sx={{ fontSize: '14px', fontWeight: 700, textTransform: 'none' }}
                    />
                    <Tab
                        label='City'
                        sx={{ fontSize: '14px', fontWeight: 700, textTransform: 'none' }}
                    />
                </Tabs>
                <Box
                    sx={{
                        flex: 1,
                        minHeight: 0,
                        overflow: 'hidden',
                    }}
                >
                    {tabIdx === 0 && <RegionList onClickLaunchCityList={onClickLaunchCityList} />}
                    {tabIdx === 1 && (
                        <CityList
                            newFilter={filterRegionCode ? { regionCode: filterRegionCode } : {}}
                        />
                    )}
                </Box>
            </Box>
        </MUICard>
    )
}
