import { Switch, Route } from 'react-router-dom'
import { useHistory, useLocation } from 'react-router-dom'
import { useEffect } from 'react'

import { Login } from './Login'
import { RestaurantList } from './Page/Restaurant/RestaurantList'
import { RestaurantEdit } from './Page/Restaurant/RestaurantEdit'
import { RestaurantClone } from './Page/Restaurant/RestaurantClone'
import { RestaurantBannerEdit } from './Page/Restaurant/RestaurantBannerEdit'
import { RestaurantBannerCreate } from './Page/Restaurant/RestaurantBannerCreate'
import { BrandList } from './Page/Brand/BrandList'
import { BrandEdit } from './Page/Brand/BrandEdit'
import { BrandCreate } from './Page/Brand/BrandCreate'
import { BrandGroupList } from './Page/BrandGroup/BrandGroupList'
import { BrandGroupEdit } from './Page/BrandGroup/BrandGroupEdit'
import { BrandGroupCreate } from './Page/BrandGroup/BrandGroupCreate'
import { UrlMapList } from './Page/UrlMap/UrlMapList'
import { UrlMapEdit } from './Page/UrlMap/UrlMapEdit'
import { UrlMapCreate } from './Page/UrlMap/UrlMapCreate'
import { AdminDeviceEdit } from './Page/AdminDevice/AdminDeviceEdit'
import { AdminDeviceCreate } from './Page/AdminDevice/AdminDeviceCreate'
import { DisplayMessageEdit } from './Page/CampaignDescription/DisplayMessageEdit'
import { DisplayMessageCreate } from './Page/CampaignDescription/DisplayMessageCreate'
import { TnCEdit } from './Page/TnC/TnCEdit'
import { TnCCreate } from './Page/TnC/TnCCreate'
import { QueueTemplateEdit } from './Page/QueueTemplate/QueueTemplateEdit'
import { QueueTemplateCreate } from './Page/QueueTemplate/QueueTemplateCreate'
import { QueueTemplateMapEdit } from './Page/QueueTemplateMap/QueueTemplateMapEdit'
import { QueueTemplateMapCreate } from './Page/QueueTemplateMap/QueueTemplateMapCreate'
import { RestaurantMediaEdit } from './Page/RestaurantMedia/RestaurantMediaEdit'
import { RestaurantMediaCreate } from './Page/RestaurantMedia/RestaurantMediaCreate'
import { PrintTemplateList } from './Page/PrintTemplate/PrintTemplateList'
import { PrintTemplateEdit } from './Page/PrintTemplate/PrintTemplateEdit'
import { PrintTemplateCreate } from './Page/PrintTemplate/PrintTemplateCreate'
import { TicketTypeList } from './Page/TicketType/TicketTypeList'
import { TicketTypeEdit } from './Page/TicketType/TicketTypeEdit'
import { TicketTypeCreate } from './Page/TicketType/TicketTypeCreate'
import { EmailTemplateList } from './Page/EmailTemplate/EmailTemplateList'
import { EmailTemplateEdit } from './Page/EmailTemplate/EmailTemplateEdit'
import { EmailTemplateCreate } from './Page/EmailTemplate/EmailTemplateCreate'
import { TakeawayList } from './Page/Takeaway/Takeaway'
import { ConfigurationList } from './Page/Configuration/Configuration'
import { UploadImageList } from './Page/UploadImage/UploadImage'
import { PushList } from './Page/Push/PushList'
import { PushEdit } from './Page/Push/PushEdit'
import { PushCreate } from './Page/Push/PushCreate'
import { RestaurantApproveList } from './Page/RestaurantApprove/RestaurantApproveList'
import { RestaurantApproveEdit } from './Page/RestaurantApprove/RestaurantApproveEdit'
import { PaymentList } from './Page/Payment/PaymentList'
import { PaymentEdit } from './Page/Payment/PaymentEdit'
import { PaymentCreate } from './Page/Payment/PaymentCreate'
import { UploadPaymentList } from './Page/UploadPayment/UploadPaymentList'
import { SalesRestaurantList } from './Page/SalesRestaurant/SalesRestaurantList'
import { SalesShopFrontImageList } from './Page/SalesShopFrontImage/SalesShopFrontImageList'
import { SalesRackProductList } from './Page/SalesRackProduct/SalesRackProductList'
import { RestaurantApprovalSalesRackProductList } from './Page/RestaurantApprovalSalesRackProduct/RestaurantApprovalSalesRackProductList'
import { ProductCreateDraftList } from './Page/ProductCreateList/ProductCreateDraftList'
import { ProductSteps } from './Component/ProductCreate/ProductSteps'
import { UserGroupList } from './Component/ProductCreate/UserGroup/UserGroupList'
import { ProductCategoryList } from './Component/ProductCreate/Category/ProductCategoryList'
import { ProductCategoryEdit } from './Component/ProductCreate/Category/ProductCategoryEdit'
import { ProductMapEdit } from './Component/ProductCreate/Category/ProductMapEdit'
import { ProductMapCreate } from './Component/ProductCreate/Category/ProductMapCreate'
import { ProductExistingRedeemGroupList } from './Component/ProductCreate/Redeem/ProductExistingRedeemGroupList'
import { RedeemGroupCreateEdit } from './Component/ProductCreate/Redeem/RedeemGroupCreateEdit'
import { ProductList } from './Component/ProductCreate/Product/ProductList'
import { ProductEdit } from './Component/ProductCreate/Product/ProductEdit'
import { RestaurantCategoryList } from './Page/RestaurantCategory/RestaurantCategoryList'
import { RestaurantCategoryEdit } from './Page/RestaurantCategory/RestaurantCategoryEdit'
import { RestaurantCategoryCreate } from './Page/RestaurantCategory/RestaurantCategoryCreate'
import { RestaurantCuisineList } from './Page/RestaurantCuisine/RestaurantCuisineList'
import { RestaurantCuisineEdit } from './Page/RestaurantCuisine/RestaurantCuisineEdit'
import { RestaurantCuisineCreate } from './Page/RestaurantCuisine/RestaurantCuisineCreate'
import { RestaurantKeywordList } from './Page/RestaurantKeyword/RestaurantKeywordList'
import { RestaurantKeywordEdit } from './Page/RestaurantKeyword/RestaurantKeywordEdit'
import { RestaurantKeywordCreate } from './Page/RestaurantKeyword/RestaurantKeywordCreate'
import { CategoryResturantMapEdit } from './Page/RestaurantCategory/CategoryResturantMapEdit'
import { CategoryResturantMapCreate } from './Page/RestaurantCategory/CategoryResturantMapCreate'
import { CuisineResturantMapEdit } from './Page/RestaurantCuisine/CuisineResturantMapEdit'
import { CuisineResturantMapCreate } from './Page/RestaurantCuisine/CuisineResturantMapCreate'
import { KeywordResturantMapEdit } from './Page/RestaurantKeyword/KeywordResturantMapEdit'
import { KeywordResturantMapCreate } from './Page/RestaurantKeyword/KeywordResturantMapCreate'
import { FolderList } from './Page/Folder/FolderList'
import { FolderEdit } from './Page/Folder/FolderEdit'
import { FolderMapEdit } from './Page/Folder/FolderMapEdit'
import { FolderMapCreate } from './Page/Folder/FolderMapCreate'
import { FolderBannerEdit } from './Page/Folder/FolderBannerEdit'
import { FolderBannerCreate } from './Page/Folder/FolderBannerCreate'
import { AreaList } from './Page/Area/AreaList'
import { AreaEdit } from './Page/Area/AreaEdit'
import { AreaCreate } from './Page/Area/AreaCreate'
import { DistrictList } from './Page/District/DistrictList'
import { DistrictEdit } from './Page/District/DistrictEdit'
import { DistrictCreate } from './Page/District/DistrictCreate'
import { LandMarkList } from './Page/LandMark/LandMarkList'
import { LandMarkEdit } from './Page/LandMark/LandMarkEdit'
import { LandMarkCreate } from './Page/LandMark/LandMarkCreate'
import { LandMarkDistrictMapEdit } from './Page/LandMark/LandMarkDistrictMapEdit'
import { LandMarkDistrictMapCreate } from './Page/LandMark/LandMarkDistrictMapCreate'
import { LandingBannerList } from './Page/Landing/LandingBannerList'
import { LandingBannerEdit } from './Page/Landing/LandingBannerEdit'
import { LandingBannerCreate } from './Page/Landing/LandingBannerCreate'
import { TicketBannerList } from './Page/Ticket/TicketBannerList'
import { TicketBannerEdit } from './Page/Ticket/TicketBannerEdit'
import { TicketBannerCreate } from './Page/Ticket/TicketBannerCreate'
import { ConfigMapEdit } from './Page/Ticket/ConfigMapEdit'
import { ConfigMapCreate } from './Page/Ticket/ConfigMapCreate'
import { ReservationTemplateEdit } from './Page/ReservationTemplate/ReservationTemplateEdit'
import { ReservationTemplateCreate } from './Page/ReservationTemplate/ReservationTemplateCreate'
import { ReservationTemplateMapEdit } from './Page/ReservationTemplateMap/ReservationTemplateMapEdit'
import { ReservationTemplateMapCreate } from './Page/ReservationTemplateMap/ReservationTemplateMapCreate'
import { ChargeDateEdit } from './Page/ChargeDate/ChargeDateEdit'
import { ChargeDateCreate } from './Page/ChargeDate/ChargeDateCreate'
import { MerchantUserList } from './Page/MerchantUser/MerchantUserList'
import { MerchantUserEdit } from './Page/MerchantUser/MerchantUserEdit/MerchantUserEdit'
import { MerchantUserCreate } from './Page/MerchantUser/MerchantUserCreate'
import { MerchantSalesUserList } from './Page/MerchantSalesUser/MerchantSalesUserList'
import { MerchantSalesUserEdit } from './Page/MerchantSalesUser/MerchantSalesUserEdit'
import { MerchantSalesUserCreate } from './Page/MerchantSalesUser/MerchantSalesUserCreate'
import { TroubleShootingList } from './Page/TroubleShooting/TroubleShootingList'
import { ContactUsList } from './Page/ContactUs/ContactUsList'
import { ContactUsEdit } from './Page/ContactUs/ContactUsEdit'
import { MerchantShopCreateList } from './Page/MerchantShopCreate/MerchantShopCreateList'
import { MerchantShopCreate } from './Component/MerchantShop/MerchantShopCreate'
import { BrandApproveList } from './Page/BrandApprove/BrandApproveList'
import { BrandApproveEdit } from './Page/BrandApprove/BrandApproveEdit'
import { SelectTagList } from './Page/SelectTag/SelectTagList'
import { SelectTagCreate } from './Page/SelectTag/SelectTagCreate'
import { SelectTagEdit } from './Page/SelectTag/SelectTagEdit'
import { SelectTagConfigCreate } from './Page/SelectTagConfig/SelectTagConfigCreate'
import { SelectTagConfigEdit } from './Page/SelectTagConfig/SelectTagConfigEdit'
// import { LandingSection } from "./Page/Landing/LandingSection/LandingSection";
import { ProductSystemGroupList } from './Component/ProductCreate/Product/ProductSystemGroupList/ProductSystemGroupList'
import { ProductSystemGroupEdit } from './Component/ProductCreate/Product/ProductSystemGroupList/ProductSystemGroupEdit/ProductSystemGroupEdit'
import { ProductSystemGroupCreate } from './Component/ProductCreate/Product/ProductSystemGroupList/ProductSystemGroupCreate'
import { SystemUserEdit } from './Component/ProductCreate/Product/ProductSystemGroupList/ProductSystemGroupEdit/SystemUser/SystemUserEdit'
import { SystemUserCreate } from './Component/ProductCreate/Product/ProductSystemGroupList/ProductSystemGroupEdit/SystemUser/SystemUserCreate'
import { MerchantUserGroupList } from './Page/MerchantUserGroup/MerchantUserGroupList'
import { MerchantUserGroupEdit } from './Page/MerchantUserGroup/MerchantUserGroupEdit'
import { MerchantUserGroupCreate } from './Page/MerchantUserGroup/MerchantUserGroupCreate/MerchantUserGroupCreate'
import { RestaurantPaymentEdit } from './Page/RestaurantPayment/RestaurantPaymentEdit/RestaurantPaymentEdit'
import { RestaurantPaymentCreate } from './Page/RestaurantPayment/RestaurantPaymentCreate/RestaurantPaymentCreate'
import { RestaurantBlackoutEdit } from './Page/RestaurantBlackout/RestaurantBlackoutEdit'
import { RestaurantBlackoutCreate } from './Page/RestaurantBlackout/RestaurantBlackoutCreate'
import { RestaurantSlideshowEdit } from './Page/RestaurantSlideshow/RestaurantSlideshowEdit'
import { RestaurantSlideshowCreate } from './Page/RestaurantSlideshow/RestaurantSlideshowCreate'
import { AllSlideshowList } from './Page/Slideshow/Default/AllSlideshowList'
import { AllSlideshowEdit } from './Page/Slideshow/Default/AllSlideshowEdit'
import { AllSlideshowCreate } from './Page/Slideshow/Default/AllSlideshowCreate'

import dashboard from './Dashboard'
import { constants } from './Constants/constants'
import { BannerEdit } from './Page/Banner/BannerEdit'
import { BannerCreate } from './Page/Banner/BannerCreate'
import { BannerList } from './Page/Banner/BannerList'
import { ResetPassword } from './Page/Configuration/ResetPassword/ResetPassword'
import { QueueRejectReasonCreate } from './Component/QueueRejectReason/QueueRejectReasonCreate'
import { QueueRejectReasonEdit } from './Component/QueueRejectReason/QueueRejectReasonEdit'
import { ReservationRejectReasonEdit } from './Page/ReservationRejectReason/ReservationRejectReasonEdit'
import { ReservationRejectReasonCreate } from './Page/ReservationRejectReason/ReservationRejectReasonCreate'
import { ProductCategoryCreate } from './Component/ProductCreate/Category/ProductCategoryCreate'
import { LandingSectionList } from './Page/Landing/LandingSection/LandingSectionList'
import { LandingSectionCreate } from './Page/Landing/LandingSection/LandingSectionCreateEdit'
import { LandingSectionEdit } from './Page/Landing/LandingSection/LandingSectionEdit'
import GenericQueueProfileCreate from './Page/GenericQueueProfile/GenericQueueProfileCreate'
import GenericQueueProfileEdit from './Page/GenericQueueProfile/GenericQueueProfileEdit'
import CounterProfileCreate from './Page/CounterProfile/CounterProfileCreate'
import CounterEdit from './Page/Counter/CounterEdit'
import CounterCreate from './Page/Counter/CounterCreate'
import CounterProfileEdit from './Page/CounterProfile/CounterProfileEdit'
import { ToolsList } from './Page/Tools/ToolsList'
import { StaffCreate } from './Page/Staff/StaffCreate'
import { StaffEdit } from './Page/Staff/StaffEdit'
import { MerchantDeviceEdit } from './Page/MerchantDevice/MerchantDeviceEdit'
import RestaurantMapEdit from './Page/LandMark/RestaurantMap/RestaurantMapEdit'
import RestaurantMapCreate from './Page/LandMark/RestaurantMap/RestaurantMapCreate'
import { PickupEdit } from './Page/Pickup/PickupEdit'
import { PickupCreate } from './Page/Pickup/PickupCreate'
import UrlMap from './Page/UrlMap/UrlMap'
import RegionCity from './Page/Region/RegionCity'
import FolderCreateEdit from './Page/Folder/Folder'

const Routes = () => {
  const history = useHistory()
  const location = useLocation()
  const token = localStorage.getItem(constants.LOCAL_STORAGE_KEY.TOKEN)

  useEffect(() => {
    if (!token) {
      history.push('/login')
    }
  }, [token]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [location.pathname]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Switch>
      <Route exact path={'/'} component={dashboard} />,
      <Route exact path={'/login'} component={Login} />,
      <Route exact path={'/' + constants.PATH.SHOP.SYSTEM_GROUP_RESTAURANT} render={(props) => <RestaurantList changeUrlQueryString={true} />} />,
      <Route exact path={'/' + constants.PATH.SHOP.RESTAURANT_CLONE + '/:id/:tab'} component={RestaurantClone} />,
      <Route exact path={'/' + constants.PATH.SHOP.SYSTEM_GROUP_RESTAURANT + '/:id/:tab/:subTab'} component={RestaurantEdit} />,
      <Route exact path={'/' + constants.PATH.SHOP.RESTAURANT_MEDIA + '/create/:restUrlId/:tab'} component={RestaurantMediaCreate} />,
      <Route exact path={'/' + constants.PATH.SHOP.RESTAURANT_MEDIA + '/:id/:tab'} component={RestaurantMediaEdit} />,
      <Route exact path={'/' + constants.PATH.SHOP.RESTAURANT_BLACKOUT + '/create/:restUrlId/:tab'} component={RestaurantBlackoutCreate} />,
      <Route exact path={'/' + constants.PATH.SHOP.RESTAURANT_BLACKOUT + '/:id/:tab'} component={RestaurantBlackoutEdit} />,
      <Route exact path={'/' + constants.PATH.SHOP.RESTAURANT_SLIDESHOW + '/create/:restUrlId/:tab'} component={RestaurantSlideshowCreate} />,
      <Route exact path={'/' + constants.PATH.SHOP.RESTAURANT_SLIDESHOW + '/:id/:tab'} component={RestaurantSlideshowEdit} />,
      <Route exact path={'/' + constants.PATH.SELECTED_TAG.SELECT_TAG_CONFIG + '/create/:restUrlId/:tab'} component={SelectTagConfigCreate} />,
      <Route exact path={'/' + constants.PATH.SELECTED_TAG.SELECT_TAG_CONFIG + '/:id/:tab'} component={SelectTagConfigEdit} />,
      <Route exact path={'/' + constants.PATH.SHOP.PICKUP.PICKUP_TAG_LIST + '/create/:restUrlId/:tab'} component={PickupCreate} />,
      <Route exact path={'/' + constants.PATH.SHOP.PICKUP.PICKUP_TAG_LIST + '/:id/:tab'} component={PickupEdit} />,
      <Route exact path={'/' + constants.PATH.SHOP.ADMIN_DEVICE + '/create/:restUrlId/:tab'} component={AdminDeviceCreate} />,
      <Route exact path={'/' + constants.PATH.SHOP.ADMIN_DEVICE + '/:id/:tab'} component={AdminDeviceEdit} />,
      <Route exact path={'/' + constants.PATH.SHOP.RESTAURANT_BANNER + '/create/:restUrlId/:tab'} component={RestaurantBannerCreate} />,
      <Route exact path={'/' + constants.PATH.SHOP.RESTAURANT_BANNER + '/:id/:tab'} component={RestaurantBannerEdit} />,
      <Route exact path={'/' + constants.PATH.SHOP.CAMPAIGN_DESCRIPTION + '/create/:restUrlId/:tab'} component={DisplayMessageCreate} />,
      <Route exact path={'/' + constants.PATH.SHOP.CAMPAIGN_DESCRIPTION + '/:id/:tab'} component={DisplayMessageEdit} />,
      <Route exact path={'/' + constants.PATH.SHOP.TNC + '/create/:restUrlId/:tab'} component={TnCCreate} />,
      <Route exact path={'/' + constants.PATH.SHOP.TNC + '/:id/:tab'} component={TnCEdit} />,
      <Route exact path={'/' + constants.PATH.SHOP.PAYMENT.PAYMENT_CONFIG_MAP + '/create/:restUrlId/:tab'} component={RestaurantPaymentCreate} />,
      <Route exact path={'/' + constants.PATH.SHOP.PAYMENT.PAYMENT_CONFIG_MAP + '/:id/:tab'} component={RestaurantPaymentEdit} />,
      <Route exact path={'/' + constants.PATH.SHOP.QUEUE.QUEUE_TEMPLATE + '/create/:restUrlId/:tab'} component={QueueTemplateCreate} />,
      <Route exact path={'/' + constants.PATH.SHOP.QUEUE.QUEUE_TEMPLATE + '/:id/:tab'} component={QueueTemplateEdit} />,
      <Route exact path={'/' + constants.PATH.SHOP.QUEUE.QUEUE_TEMPLATE_MAP + '/create/:restUrlId/:tab'} component={QueueTemplateMapCreate} />,
      <Route exact path={'/' + constants.PATH.SHOP.QUEUE.QUEUE_TEMPLATE_MAP + '/:id/:tab'} component={QueueTemplateMapEdit} />,
      <Route exact path={'/' + constants.PATH.SHOP.QUEUE.QUEUE_REJECT_REASON + '/create/:restUrlId/:tab'} component={QueueRejectReasonCreate} />,
      <Route exact path={'/' + constants.PATH.SHOP.QUEUE.QUEUE_REJECT_REASON + '/:id/:tab'} component={QueueRejectReasonEdit} />,
      <Route exact path={'/' + constants.PATH.SHOP.RESERVATION.RESERVATION_TEMPLATE + '/create/:tab'} component={ReservationTemplateCreate} />,
      <Route exact path={'/' + constants.PATH.SHOP.RESERVATION.RESERVATION_TEMPLATE + '/:id/:tab'} component={ReservationTemplateEdit} />,
      <Route exact path={'/' + constants.PATH.SHOP.RESERVATION.RESERVATION_TEMPLATE_MAP + '/create/:tab'} component={ReservationTemplateMapCreate} />,
      <Route exact path={'/' + constants.PATH.SHOP.RESERVATION.RESERVATION_TEMPLATE_MAP + '/:id/:tab'} component={ReservationTemplateMapEdit} />,
      <Route exact path={'/' + constants.PATH.SHOP.RESERVATION.CHARGE_DATE + '/create/:tab'} component={ChargeDateCreate} />,
      <Route exact path={'/' + constants.PATH.SHOP.RESERVATION.CHARGE_DATE + '/:id/:tab'} component={ChargeDateEdit} />,
      <Route
        exact
        path={'/' + constants.PATH.SHOP.RESERVATION.RESERVATION_REJECT_REASON + '/create/:restUrlId/:tab'}
        component={ReservationRejectReasonCreate}
      />
      ,
      <Route exact path={'/' + constants.PATH.SHOP.RESERVATION.RESERVATION_REJECT_REASON + '/:id/:tab'} component={ReservationRejectReasonEdit} />,
      <Route exact path={'/' + constants.PATH.TAKEAWAY} component={TakeawayList} />,
      <Route exact path={'/' + constants.PATH.PRODUCT.DRAFT} component={ProductCreateDraftList} />,
      <Route exact path={'/' + constants.PATH.PRODUCT.DRAFT + '/create/:tab'} component={ProductSteps} />,
      {/* <Route exact path={"/" + constants.PATH.PRODUCT.DRAFT + "/clone/:tab"} component={ProductCreate} />, */}
      <Route exact path={'/' + constants.PATH.PRODUCT.DRAFT + '/:draftId/:tab'} component={ProductSteps} />,
      <Route exact path={'/' + constants.PATH.PRODUCT.USER_GROUP} component={UserGroupList} />,
      <Route exact path={'/' + constants.PATH.PRODUCT.PRODUCT_CATEGORY} render={(props) => <ProductCategoryList changeUrlQueryString={true} />} />,
      <Route exact path={'/' + constants.PATH.PRODUCT.PRODUCT_CATEGORY + '/create/:tab'} component={ProductCategoryCreate} />,
      <Route exact path={'/' + constants.PATH.PRODUCT.PRODUCT_CATEGORY + '/:id/:tab'} component={ProductCategoryEdit} />,
      <Route exact path={'/' + constants.PATH.PRODUCT.CATEGORY_PRODUCT_MAP + '/create/:tab'} component={ProductMapCreate} />,
      <Route exact path={'/' + constants.PATH.PRODUCT.CATEGORY_PRODUCT_MAP + '/:id/:tab'} component={ProductMapEdit} />,
      <Route
        exact
        path={'/' + constants.PATH.PRODUCT.REDEEM_GROUP}
        render={(props) => <ProductExistingRedeemGroupList changeUrlQueryString={true} />}
      />
      ,
      <Route exact path={'/' + constants.PATH.PRODUCT.REDEEM_GROUP + '/:id/:tab'} component={RedeemGroupCreateEdit} />,
      <Route exact path={'/' + constants.PATH.PRODUCT.PRODUCT} render={(props) => <ProductList changeUrlQueryString={true} />} />,
      <Route exact path={'/' + constants.PATH.PRODUCT.PRODUCT + '/:id/:tab'} component={ProductEdit} />,
      <Route
        exact
        path={'/' + constants.PATH.RESTAURANT_TAG.RESTAURANT_CATEGORY}
        render={(props) => <RestaurantCategoryList changeUrlQueryString={true} />}
      />
      ,
      <Route exact path={'/' + constants.PATH.RESTAURANT_TAG.RESTAURANT_CATEGORY + '/create/:tab'} component={RestaurantCategoryCreate} />,
      <Route exact path={'/' + constants.PATH.RESTAURANT_TAG.RESTAURANT_CATEGORY + '/:id/:tab'} component={RestaurantCategoryEdit} />,
      <Route exact path={'/' + constants.PATH.RESTAURANT_TAG.RESTAURANT_CATEGORY_MAP + '/create/:tab'} component={CategoryResturantMapCreate} />,
      <Route exact path={'/' + constants.PATH.RESTAURANT_TAG.RESTAURANT_CATEGORY_MAP + '/:id/:tab'} component={CategoryResturantMapEdit} />,
      <Route
        exact
        path={'/' + constants.PATH.RESTAURANT_TAG.RESTAURANT_CUISINE}
        render={(props) => <RestaurantCuisineList changeUrlQueryString={true} />}
      />
      ,
      <Route exact path={'/' + constants.PATH.RESTAURANT_TAG.RESTAURANT_CUISINE + '/create/:tab'} component={RestaurantCuisineCreate} />,
      <Route exact path={'/' + constants.PATH.RESTAURANT_TAG.RESTAURANT_CUISINE + '/:id/:tab'} component={RestaurantCuisineEdit} />,
      <Route exact path={'/' + constants.PATH.RESTAURANT_TAG.RESTAURANT_CUISINE_MAP + '/create/:tab'} component={CuisineResturantMapCreate} />,
      <Route exact path={'/' + constants.PATH.RESTAURANT_TAG.RESTAURANT_CUISINE_MAP + '/:id/:tab'} component={CuisineResturantMapEdit} />,
      <Route
        exact
        path={'/' + constants.PATH.RESTAURANT_TAG.RESTAURANT_KEYWORD}
        render={(props) => <RestaurantKeywordList changeUrlQueryString={true} />}
      />
      ,
      <Route exact path={'/' + constants.PATH.RESTAURANT_TAG.RESTAURANT_KEYWORD + '/create/:tab'} component={RestaurantKeywordCreate} />,
      <Route exact path={'/' + constants.PATH.RESTAURANT_TAG.RESTAURANT_KEYWORD + '/:id/:tab'} component={RestaurantKeywordEdit} />,
      <Route exact path={'/' + constants.PATH.RESTAURANT_TAG.RESTAURANT_KEYWORD_MAP + '/create/:tab'} component={KeywordResturantMapCreate} />,
      <Route exact path={'/' + constants.PATH.RESTAURANT_TAG.RESTAURANT_KEYWORD_MAP + '/:id/:tab'} component={KeywordResturantMapEdit} />,
      <Route exact path={'/' + constants.PATH.MERCHANT.MERCHANT_USER} render={(props) => <MerchantUserList changeUrlQueryString={true} />} />,
      <Route exact path={'/' + constants.PATH.MERCHANT.MERCHANT_USER + '/create/:tab'} component={MerchantUserCreate} />,
      <Route exact path={'/' + constants.PATH.MERCHANT.MERCHANT_USER + '/:id/:tab'} component={MerchantUserEdit} />,
      <Route exact path={'/' + constants.PATH.MERCHANT.MERCHANT_SALES_USER} component={MerchantSalesUserList} />,
      <Route exact path={'/' + constants.PATH.MERCHANT.MERCHANT_SALES_USER + '/create/:tab'} component={MerchantSalesUserCreate} />,
      <Route exact path={'/' + constants.PATH.MERCHANT.MERCHANT_SALES_USER + '/:id/:tab'} component={MerchantSalesUserEdit} />,
      <Route exact path={'/' + constants.PATH.MERCHANT.DRAFT} component={MerchantShopCreateList} />,
      <Route exact path={'/' + constants.PATH.MERCHANT.DRAFT + '/create/:tab'} component={MerchantShopCreate} />,
      <Route exact path={'/' + constants.PATH.MERCHANT.DRAFT + '/:id/:tab'} component={MerchantShopCreate} />,
      <Route
        exact
        path={'/' + constants.PATH.MERCHANT.MERCHANT_USER_GROUP}
        render={(props) => <MerchantUserGroupList changeUrlQueryString={true} />}
      />
      ,
      <Route exact path={'/' + constants.PATH.MERCHANT.MERCHANT_USER_GROUP + '/create/:tab'} component={MerchantUserGroupCreate} />,
      <Route exact path={'/' + constants.PATH.MERCHANT.MERCHANT_USER_GROUP + '/:id/:tab'} component={MerchantUserGroupEdit} />,
      <Route exact path={'/' + constants.PATH.MERCHANT.BRAND} render={(props) => <BrandApproveList changeUrlQueryString={true} />} />,
      <Route exact path={'/' + constants.PATH.MERCHANT.BRAND + '/:id/:tab'} component={BrandApproveEdit} />,
      <Route
        exact
        path={'/' + constants.PATH.MERCHANT.QUEUE_PROFILE + '/create/:restUrlId/:tab'}
        render={() => <GenericQueueProfileCreate resource="QueueProfile" />}
      />
      <Route
        exact
        path={'/' + constants.PATH.MERCHANT.QUEUE_PROFILE + '/edit/:restUrlId/:id/:tab'}
        render={() => <GenericQueueProfileEdit resource="QueueProfile" />}
      />
      <Route
        exact
        path={'/' + constants.PATH.MERCHANT.QUEUE_TRANSFER_PROFILE + '/create/:restUrlId/:tab'}
        render={() => <GenericQueueProfileCreate resource="QueueTransferProfile" />}
      />
      <Route
        exact
        path={'/' + constants.PATH.MERCHANT.QUEUE_TRANSFER_PROFILE + '/edit/:restUrlId/:id/:tab'}
        render={() => <GenericQueueProfileEdit resource="QueueTransferProfile" />}
      />
      <Route exact path={'/' + constants.PATH.MERCHANT.COUNTER + '/create/:restUrlId/:tab'} component={CounterCreate} />
      <Route exact path={'/' + constants.PATH.MERCHANT.COUNTER + '/edit/:restUrlId/:id/:tab'} component={CounterEdit} />
      <Route exact path={'/' + constants.PATH.MERCHANT.MERCHANT_DEVICE + '/edit/:restUrlId/:id/:tab'} component={MerchantDeviceEdit} />
      <Route exact path={'/' + constants.PATH.MERCHANT.STAFF + '/create/:restUrlId/:tab'} component={StaffCreate} />
      <Route exact path={'/' + constants.PATH.MERCHANT.STAFF + '/edit/:restUrlId/:id/:tab'} component={StaffEdit} />
      <Route exact path={'/' + constants.PATH.MERCHANT.COUNTER_PROFILE + '/create/:restUrlId/:tab'} component={CounterProfileCreate} />
      <Route exact path={'/' + constants.PATH.MERCHANT.COUNTER_PROFILE + '/edit/:restUrlId/:id/:tab'} component={CounterProfileEdit} />
      {/* <Route exact path={"/" + constants.PATH.MERCHANT.COUNTER_PROFILE + "/:id/:tab"} render={() => 
                <CounterProfileEdit 
                    restUrlId={':restUrlId'}
                />} 
            /> */}
      <Route exact path={'/' + constants.PATH.SHOP.URL_MAP} render={(props) => <UrlMapList changeUrlQueryString={true} />} />,
      <Route exact path={'/' + constants.PATH.SHOP.URL_MAP + '/create/:tab'} render={() => <UrlMap isCreateMode={true} />} />,
      <Route exact path={'/' + constants.PATH.SHOP.URL_MAP + '/:id/:tab'} render={() => <UrlMap isCreateMode={false} />} />,
      <Route exact path={'/' + constants.PATH.SHOP.QUEUE.PRINT_TEMPLATE} render={(props) => <PrintTemplateList changeUrlQueryString={true} />} />,
      <Route exact path={'/' + constants.PATH.SHOP.QUEUE.PRINT_TEMPLATE + '/create/:tab'} component={PrintTemplateCreate} />,
      <Route exact path={'/' + constants.PATH.SHOP.QUEUE.PRINT_TEMPLATE + '/clone/:id/:tab'} component={PrintTemplateCreate} />,
      <Route exact path={'/' + constants.PATH.SHOP.QUEUE.PRINT_TEMPLATE + '/:id/:tab'} component={PrintTemplateEdit} />,
      <Route exact path={'/' + constants.PATH.SHOP.EMAIL_TEMPLATE} render={(props) => <EmailTemplateList changeUrlQueryString={true} />} />,
      <Route exact path={'/' + constants.PATH.SHOP.EMAIL_TEMPLATE + '/create/:tab'} component={EmailTemplateCreate} />,
      <Route exact path={'/' + constants.PATH.SHOP.EMAIL_TEMPLATE + '/:id/:tab'} component={EmailTemplateEdit} />,
      <Route exact path={'/' + constants.PATH.SHOP.BRAND} render={(props) => <BrandList changeUrlQueryString={true} />} />,
      <Route exact path={'/' + constants.PATH.SHOP.BRAND + '/create/:tab'} component={BrandCreate} />,
      <Route exact path={'/' + constants.PATH.SHOP.BRAND + '/:id/:tab'} component={BrandEdit} />,
      <Route exact path={'/' + constants.PATH.SHOP.BRAND_GROUP} component={BrandGroupList} />,
      <Route exact path={'/' + constants.PATH.SHOP.BRAND_GROUP + '/create/:tab'} component={BrandGroupCreate} />,
      <Route exact path={'/' + constants.PATH.SHOP.BRAND_GROUP + '/:id/:tab'} component={BrandGroupEdit} />,
      <Route exact path={'/' + constants.PATH.BANNER.BANNER} render={(props) => <BannerList changeUrlQueryString={true} />} />,
      <Route exact path={'/' + constants.PATH.BANNER.BANNER + '/create/:tab'} component={BannerCreate} />,
      <Route exact path={'/' + constants.PATH.BANNER.BANNER + '/:id/:tab'} component={BannerEdit} />,
      <Route exact path={'/' + constants.PATH.FOLDER.FOLDER} render={(props) => <FolderList changeUrlQueryString={true} />} />,
      <Route exact path={'/' + constants.PATH.FOLDER.FOLDER + '/create/:tab'} render={() => <FolderCreateEdit />} />,
      <Route exact path={'/' + constants.PATH.FOLDER.FOLDER + '/:id/:tab'} render={() => <FolderCreateEdit isCreateMode={false} />} />,
      <Route exact path={'/' + constants.PATH.FOLDER.FOLDER_MAP + '/create/:tab'} component={FolderMapCreate} />,
      <Route exact path={'/' + constants.PATH.FOLDER.FOLDER_MAP + '/:id/:tab'} component={FolderMapEdit} />,
      <Route exact path={'/' + constants.PATH.FOLDER.FOLDER_BANNER + '/create/:tab'} component={FolderBannerCreate} />,
      <Route exact path={'/' + constants.PATH.FOLDER.FOLDER_BANNER + '/:id/:tab'} component={FolderBannerEdit} />,
      <Route exact path={'/' + constants.PATH.SHOP.REGION} render={() => <RegionCity />} />,
      <Route exact path={'/' + constants.PATH.SHOP.RESTAURANT_AREA} render={(props) => <AreaList changeUrlQueryString={true} />} />,
      <Route exact path={'/' + constants.PATH.SHOP.RESTAURANT_AREA + '/create/:tab'} component={AreaCreate} />,
      <Route exact path={'/' + constants.PATH.SHOP.RESTAURANT_AREA + '/:id/:tab'} component={AreaEdit} />,
      <Route exact path={'/' + constants.PATH.SHOP.RESTAURANT_DISTRICT_WRAPPER} render={(props) => <DistrictList changeUrlQueryString={true} />} />,
      <Route exact path={'/' + constants.PATH.SHOP.RESTAURANT_DISTRICT + '/create/:tab'} component={DistrictCreate} />,
      <Route exact path={'/' + constants.PATH.SHOP.RESTAURANT_DISTRICT + '/:id/:tab'} component={DistrictEdit} />,
      <Route exact path={'/' + constants.PATH.SHOP.RESTAURANT_LANDMARK} render={(props) => <LandMarkList changeUrlQueryString={true} />} />,
      <Route exact path={'/' + constants.PATH.SHOP.RESTAURANT_LANDMARK + '/create/:tab'} component={LandMarkCreate} />,
      <Route exact path={'/' + constants.PATH.SHOP.RESTAURANT_LANDMARK + '/:id/:tab'} component={LandMarkEdit} />,
      <Route exact path={'/' + constants.PATH.SHOP.RESTAURANT_LANDMARK_DISTRICT_MAP + '/create/:tab'} component={LandMarkDistrictMapCreate} />,
      <Route exact path={'/' + constants.PATH.SHOP.RESTAURANT_LANDMARK_DISTRICT_MAP + '/:id/:tab'} component={LandMarkDistrictMapEdit} />,
      <Route exact path={'/' + constants.PATH.SHOP.RESTAURANT_LANDMARK_RESTAURANT_MAP + '/create/:tab'} component={RestaurantMapCreate} />,
      <Route exact path={'/' + constants.PATH.SHOP.RESTAURANT_LANDMARK_RESTAURANT_MAP + '/:id/:tab'} component={RestaurantMapEdit} />,
      <Route exact path={'/' + constants.PATH.LANDING.LANDING_BANNER} render={(props) => <LandingBannerList changeUrlQueryString={true} />} />,
      <Route exact path={'/' + constants.PATH.LANDING.LANDING_BANNER + '/create/:tab'} component={LandingBannerCreate} />,
      <Route exact path={'/' + constants.PATH.LANDING.LANDING_BANNER + '/:id/:tab'} component={LandingBannerEdit} />,
      {/* <Route exact path={"/" + constants.PATH.LANDING.LANDING_SECTION + "/:tab"} component={LandingSection} />, */}
      <Route exact path={'/' + constants.PATH.LANDING.LANDING_SECTION + '/:tab'} component={LandingSectionList} />,
      <Route exact path={'/' + constants.PATH.LANDING.LANDING_SECTION + '/create/:tab'} component={LandingSectionCreate} />,
      <Route exact path={'/' + constants.PATH.LANDING.LANDING_SECTION + '/:id/:tab'} component={LandingSectionEdit} />,
      <Route exact path={'/' + constants.PATH.TICKET.TICKET_BANNER} render={(props) => <TicketBannerList changeUrlQueryString={true} />} />,
      <Route exact path={'/' + constants.PATH.TICKET.TICKET_BANNER + '/create/:tab'} component={TicketBannerCreate} />,
      <Route exact path={'/' + constants.PATH.TICKET.TICKET_BANNER + '/:id/:tab'} component={TicketBannerEdit} />,
      <Route exact path={'/' + constants.PATH.TICKET.TICKET_BANNER_MAP + '/create/:tab'} component={ConfigMapCreate} />,
      <Route exact path={'/' + constants.PATH.TICKET.TICKET_BANNER_MAP + '/:id/:tab'} component={ConfigMapEdit} />,
      <Route exact path={'/' + constants.PATH.SHOP.QUEUE.TICKET_TYPE} render={(props) => <TicketTypeList changeUrlQueryString={true} />} />,
      <Route exact path={'/' + constants.PATH.SHOP.QUEUE.TICKET_TYPE + '/create/:tab'} component={TicketTypeCreate} />,
      <Route exact path={'/' + constants.PATH.SHOP.QUEUE.TICKET_TYPE + '/:id/:tab'} component={TicketTypeEdit} />,
      <Route exact path={'/' + constants.PATH.SLIDESHOW.ALL_SLIDESHOW} render={(props) => <AllSlideshowList changeUrlQueryString={true} />} />,
      <Route exact path={'/' + constants.PATH.SLIDESHOW.ALL_SLIDESHOW + '/create/:tab'} component={AllSlideshowCreate} />,
      <Route exact path={'/' + constants.PATH.SLIDESHOW.ALL_SLIDESHOW + '/:id/:tab'} component={AllSlideshowEdit} />,
      <Route exact path={'/' + constants.PATH.PUSH.PUSH} render={(props) => <PushList changeUrlQueryString={true} />} />,
      <Route exact path={'/' + constants.PATH.PUSH.PUSH + '/create/:tab'} component={PushCreate} />,
      <Route exact path={'/' + constants.PATH.PUSH.PUSH + '/:id/:tab'} component={PushEdit} />,
      <Route exact path={'/' + constants.PATH.SELECTED_TAG.SELECTED_TAG} render={(props) => <SelectTagList changeUrlQueryString={true} />} />,
      <Route exact path={'/' + constants.PATH.SELECTED_TAG.SELECTED_TAG + '/create/:tab'} component={SelectTagCreate} />,
      <Route exact path={'/' + constants.PATH.SELECTED_TAG.SELECTED_TAG + '/:id/:tab'} component={SelectTagEdit} />,
      <Route exact path={'/' + constants.PATH.PRODUCT.SYSTEM_GROUP} render={(props) => <ProductSystemGroupList changeUrlQueryString={true} />} />,
      <Route exact path={'/' + constants.PATH.PRODUCT.SYSTEM_GROUP + '/create/:tab'} component={ProductSystemGroupCreate} />,
      <Route exact path={'/' + constants.PATH.PRODUCT.SYSTEM_GROUP + '/:id/:tab'} component={ProductSystemGroupEdit} />,
      <Route exact path={'/' + constants.PATH.SYSTEM_GROUP.SYSTEM_USER + '/create/:tab'} component={SystemUserCreate} />,
      <Route exact path={'/' + constants.PATH.SYSTEM_GROUP.SYSTEM_USER + '/:id/:tab'} component={SystemUserEdit} />,
      <Route exact path={'/' + constants.PATH.UPLOAD_IMAGE} component={UploadImageList} />,
      <Route exact path={'/' + constants.PATH.CONTACT_US} render={(props) => <ContactUsList changeUrlQueryString={true} />} />,
      <Route exact path={'/' + constants.PATH.CONTACT_US + '/:id/:tab'} component={ContactUsEdit} />,
      <Route exact path={'/' + constants.PATH.CONFIGURATION} component={ConfigurationList} />,
      <Route exact path={'/' + constants.PATH.PASSWORD.LANDING} component={ResetPassword} />,
      <Route exact path={'/' + constants.PATH.TOOLS} component={ToolsList} />,{/* finance */}
      <Route exact path={'/' + constants.PATH.APPROVAL.RESTAURANT} component={RestaurantApproveList} />,
      <Route exact path={'/' + constants.PATH.APPROVAL.RESTAURANT + '/:id/:tab'} component={RestaurantApproveEdit} />,
      <Route exact path={'/' + constants.PATH.APPROVAL.PAYMENT + '/create/:tab'} component={PaymentCreate} />
      <Route exact path={'/' + constants.PATH.APPROVAL.PAYMENT + '/:id/:tab'} component={PaymentEdit} />,
      <Route exact path={'/' + constants.PATH.APPROVAL.PAYMENT} component={PaymentList} />,
      <Route exact path={'/' + constants.PATH.APPROVAL.PAYMENT + '/create/:tab'} component={PaymentCreate} />,
      <Route exact path={'/' + constants.PATH.APPROVAL.PAYMENT + '/:id/:tab'} component={PaymentEdit} />,
      <Route exact path={'/' + constants.PATH.APPROVAL.UPLOADPAYMENT} component={UploadPaymentList} />,
      <Route exact path={'/' + constants.PATH.SALES.SALES_RESTAURANT} component={SalesRestaurantList} />,
      <Route exact path={'/' + constants.PATH.SALES.SALES_SHOP_FRONT_IMAGE} component={SalesShopFrontImageList} />,
      <Route exact path={'/' + constants.PATH.SALES.SALES_RACK_PRODUCT} component={SalesRackProductList} />,
      <Route exact path={'/' + constants.PATH.APPROVAL.RESTAURANT_APPROVAL_SALES_RACK_PRODUCT} component={RestaurantApprovalSalesRackProductList} />,
      {/* support */}
      <Route exact path={'/' + constants.PATH.TROUBLE_SHOOTING} component={TroubleShootingList} />,
    </Switch>
  )
}

export default Routes
