import { alpha, Box } from '@mui/material'
import { styled } from '@mui/styles'

const StyledLabel = styled('span')(({ theme, ownerstate }) => {
    const isLight = theme.palette.mode === 'light'
    const { color, variant } = ownerstate

    const styleFilled = (color) => ({
        color: theme.palette[color].contrastText,
        backgroundColor: theme.palette[color].main,
    })

    const styleOutlined = (color) => ({
        color: theme.palette[color].main,
        backgroundColor: 'transparent',
        border: `1px solid ${theme.palette[color].main}`,
    })

    const styleGhost = (color) => ({
        color: theme.palette[color][isLight ? 'dark' : 'light'],
        backgroundColor: alpha(theme.palette[color].main, 0.16),
    })

    return {
        height: 22,
        minWidth: 30,
        lineHeight: 0,
        borderRadius: 6,
        cursor: 'default',
        alignItems: 'center',
        whiteSpace: 'nowrap',
        display: 'inline-flex',
        justifyContent: 'center',
        padding: theme.spacing(0, 1),
        color: theme.palette.grey[800],
        fontSize: '12px',
        fontFamily: 'Segoe UI',
        backgroundColor: theme.palette.grey[300],
        fontWeight: 700,
        width: 'fit-content',
        ...(color !== 'default'
            ? {
                  ...(variant === 'filled' && { ...styleFilled(color) }),
                  ...(variant === 'outlined' && { ...styleOutlined(color) }),
                  ...(variant === 'ghost' && { ...styleGhost(color) }),
              }
            : {
                  ...(variant === 'outlined' && {
                      backgroundColor: 'transparent',
                      color: theme.palette.text.primary,
                      border: `1px solid ${theme.palette.grey[500_32]}`,
                  }),
                  ...(variant === 'ghost' && {
                      color: isLight ? theme.palette.text.secondary : theme.palette.common.white,
                      backgroundColor: theme.palette.grey[500_16],
                  }),
              }),
    }
})

export default function MUILabel({
    children,
    color = 'default',
    variant = 'ghost',
    startIcon,
    endIcon,
    sx,
}) {
    const iconRootStyle = {
        minWidth: 20,
        width: 16,
        height: 16,
        '& svg, img': { width: 1, height: 1, objectFit: 'cover' },
    }

    return (
        <StyledLabel
            ownerstate={{ color, variant }}
            sx={{
                ...(startIcon && { pl: 0.75 }),
                ...(endIcon && { pr: 0.75 }),
                ...sx,
            }}
        >
            {startIcon && <Box sx={{ mr: 0.75, ...iconRootStyle }}>{startIcon}</Box>}
            {children}
            {endIcon && <Box sx={{ ml: 0.75, ...iconRootStyle }}>{endIcon}</Box>}
        </StyledLabel>
    )
}
