import { useEffect, useState } from 'react'
import { useStore } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { helperTextStyles } from '../Landing/LandingSection/landingUtil'
import pickupValidation from '../../validations/pickupValidation'
import { pickupValidationConstants } from '../../Constants/validationConstants'
import { constants as cons } from '../../Constants/constants'
import { getContent } from '../../Component/EditCreateDisplay/EditUtil'
import _ from 'lodash'

import SaveIcon from '@mui/icons-material/Save'
import ClearIcon from '@mui/icons-material/Clear'
import LowPriorityIcon from '@mui/icons-material/LowPriority'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CancelIcon from '@mui/icons-material/Cancel'
import { getOne, put } from '../../helper/baseFetch'
import useRestaurant from '../../hooks/Restaurant/useRestaurant'
import { EditCreateDisplay } from '../../Component/EditCreateDisplay/EditCreateDisplay'
import { GlobalDialog } from '../../Component/GlobalDialog'
import { QueueTemplateColorPicker } from '../QueueTemplate/QueueTemplateColorPicker'
import { SoundInfoVoiceDropdown } from '../MerchantConfig/SoundInfoVoiceDropdown'
import { TypeCodeField } from './TypeCodeField'

export const PickupEdit = () => {
  const store = useStore()
  const params = useParams()
  const history = useHistory()
  const [ready, setReady] = useState(false)
  const [clear, setClear] = useState(true)
  const [dialog, setDialog] = useState({
    open: false,
    dialogActions: [],
  })
  const resource = 'Pickup'
  const [savedPickup, setSavedPickup] = useState({})
  const [errorVersion, setErrorVersion] = useState(pickupValidation.getVersion())

  const tabs = [
    {
      label: { resource: resource, key: 'info' },
      tabIndex: cons.ADMIN_DEVICE_EDIT_TAB.INFO,
    },
  ]
  const fields = [
    {
      className: 'edit-create-input',
      label: { resource: resource, key: 'status' },
      value: savedPickup.status,
      type: cons.TYPE.SELECT,
      selectValue: cons.CHOICES.PICKUP.STATUS,
      name: 'status',
      isEndOfLine: true,
      disableClearable: true,
      required: true,
      helperTextIcon: true,
      helperText: pickupValidation.getErrMsg(pickupValidationConstants.KEY_STATUS),
    },
    {
      className: 'edit-create-input',
      type: cons.TYPE.FUNCTION,
      value: (
        <TypeCodeField
          value={savedPickup.typeCode}
          clear
          resource={resource}
          key={'typeCode'}
          onChangeInput={onChangeInput}
          filter={{ type: 'PICKUP' }}
        />
      ),
    },
    {
      className: 'edit-create-input',
      label: { resource: resource, key: 'enTypeName' },
      value: savedPickup.enTypeName,
      placeholder: savedPickup.enDefaultTypeName,
      name: 'enTypeName',
      isEndOfLine: false,
      clear: true,
    },
    {
      className: 'edit-create-input',
      label: { resource: resource, key: 'tcTypeName' },
      value: savedPickup.tcTypeName,
      placeholder: savedPickup.tcDefaultTypeName,
      name: 'tcTypeName',
      isEndOfLine: false,
      clear: true,
    },
    {
      className: 'edit-create-input',
      label: { resource: resource, key: 'scTypeName' },
      value: savedPickup.scTypeName,
      placeholder: savedPickup.scDefaultTypeName,
      name: 'scTypeName',
      isEndOfLine: true,
      clear: true,
    },
    {
      className: 'edit-create-input',
      label: { resource: resource, key: 'soundTemplateCode' },
      value: savedPickup.templateCode,
      name: 'soundTemplateCode',
      isEndOfLine: true,
      disabled: true,
      variant: cons.STYLE.VARIANT.STANDARD,
    },
    {
      className: 'edit-create-input',
      label: { resource: resource, key: 'sequence' },
      value: savedPickup.sequence,
      name: 'sequence',
      type: cons.TYPE.NUMBER,
      isEndOfLine: false,
      disabled: false,
      required: true,
      disableClearable: true,
      helperTextIcon: true,
      helperText: pickupValidation.getErrMsg(pickupValidationConstants.KEY_SEQUENCE),
    },
    {
      className: 'edit-create-input',
      label: { resource: resource, key: 'reverseSequence' },
      value: savedPickup.reverseSequence,
      type: cons.TYPE.SELECT,
      selectValue: cons.CHOICES.PICKUP.REVERSE_SEQUENCE,
      name: 'reverseSequence',
      isEndOfLine: true,
      disableClearable: true,
      required: true,
      helperTextIcon: true,
      helperText: pickupValidation.getErrMsg(pickupValidationConstants.KEY_STATUS),
    },
    {
      className: 'edit-create-input',
      type: cons.TYPE.FUNCTION,
      value: <SoundInfoVoiceDropdown value={savedPickup.voiceCode} resource={resource} onChangeInput={onChangeInput} disableClearable />,
    },
    {
      className: 'edit-create-input',
      label: { resource: resource, key: 'foregroundColor' },
      value: savedPickup.foregroundColor,
      name: 'foregroundColor',
      freeSolo: true,
      customEndAdornmentClearIcon: true,
      endAdornmentIconButton: (
        <QueueTemplateColorPicker color={savedPickup.foregroundColor} setColor={(color) => onChangeInput('foregroundColor', color)} />
      ),
      onClickEndAdornmentIconButton: () => {},
      type: cons.TYPE.SELECT,
      selectValue: cons.CHOICES.QUEUE_TEMPLATE.DEFAULT_TABLE_TYPE_FOREGROUND_COLOR,
      helperTextIcon: true,
      helperText: pickupValidation.getErrMsg(pickupValidationConstants.KEY_TABLE_TYPE_BACKGROUND_COLOR),
      freeSoloUppercase: true,
      isColorFieldSpecialCase: true,
    },
    {
      className: 'edit-create-input',
      label: { resource: resource, key: 'backgroundColor' },
      value: savedPickup.backgroundColor,
      name: 'backgroundColor',
      freeSolo: true,
      customEndAdornmentClearIcon: true,
      endAdornmentIconButton: (
        <QueueTemplateColorPicker color={savedPickup.backgroundColor} setColor={(color) => onChangeInput('backgroundColor', color)} />
      ),
      onClickEndAdornmentIconButton: () => {},
      type: cons.TYPE.SELECT,
      selectValue: cons.CHOICES.QUEUE_TEMPLATE.DEFAULT_TABLE_TYPE_FOREGROUND_COLOR,
      helperTextIcon: true,
      helperText: pickupValidation.getErrMsg(pickupValidationConstants.KEY_TABLE_TYPE_FOREGROUND_COLOR),
      freeSoloUppercase: true,
      isEndOfLine: true,
      isColorFieldSpecialCase: true,
    },
  ]
  const content = [
    {
      tab: (
        <>
          {pickupValidation.getErrMsg(pickupValidationConstants.KEY_REST_URL_ID) &&
            pickupValidation.getErrMsg(pickupValidationConstants.KEY_REST_URL_ID).length > 0 && (
              <div className="error-message">
                <CancelIcon className="error-message-icon" fontSize={cons.SIZE.SMALL} />
                {pickupValidation.getErrMsg(pickupValidationConstants.KEY_REST_URL_ID)}
              </div>
            )}
          {fields.map((inputSetting, index) => {
            let content = getContent(inputSetting, index, onChangeInput)
            return (
              <div className="edit-display-wrapper" key={index}>
                {content}
                {inputSetting.isEndOfLine && <div></div>}
              </div>
            )
          })}
        </>
      ),
      btn: true,
    },
  ]
  const bottomBtns = [
    {
      variant: cons.STYLE.VARIANT.CONTAINED,
      color: cons.STYLE.PRIMARY,
      label: { key: 'save' },
      onClick: onClickSave,
      className: 'margin-left-16',
      icon: <SaveIcon />,
    },
    {
      variant: cons.STYLE.VARIANT.CONTAINED,
      color: cons.STYLE.PRIMARY,
      label: { key: 'clear' },
      disabled: false,
      onClick: onClickClear,
      className: 'margin-left-16',
      icon: <ClearIcon />,
    },
  ]

  const onClickBackToEdit = () => {
    setDialog({
      open: false,
      dialogActions: [],
    })
    onClickClear()
  }
  const onClickBackToList = () => {
    setDialog({
      open: false,
      dialogActions: [],
    })
    history.push('/' + cons.PATH.SHOP.SYSTEM_GROUP_RESTAURANT + '/' + savedPickup.restUrlId + '/' + cons.RESTAURANT_TAB.PICKUP + '/' + cons.PICKUP_TAB.INFO)
  }

  function onChangeInput(name, e) {
    const newSavedPickup = _.cloneDeep(savedPickup)

    if (e === '') {
      e = null
    }

    switch (name) {
      case 'sequence':
        newSavedPickup.sequence = Number.isInteger(Number(e)) ? (Number(e) > 0 ? Number(e) : 0) : 0
        break
      case 'typeCode':
        if (e) {
          newSavedPickup.typeCode = e.code
          newSavedPickup.templateCode = e.soundTemplateCode
          newSavedPickup.enTypeName = e.enName
          newSavedPickup.tcTypeName = e.tcName
          newSavedPickup.scTypeName = e.scName
        } else {
          newSavedPickup.typeCode = 'DEFAULT'
          newSavedPickup.templateCode = null
          newSavedPickup.enTypeName = null
          newSavedPickup.tcTypeName = null
          newSavedPickup.scTypeName = null
        }

        break
      default:
        newSavedPickup[`${name}`] = e
    }

    setSavedPickup(newSavedPickup)
  }

  function onClickSave() {
    pickupValidation.validateEditAdminDevice(savedPickup)
    if (pickupValidation.isValid()) {
      put(
        cons.PATH.SHOP.PICKUP.PICKUP_TAG_LIST + '/' + params.id,
        savedPickup,
        () => {
          setDialog({
            open: true,
            onClose: onClickBackToEdit,
            dialogContentText: { key: 'editSuccess' },
            dialogActions: [
              { label: { key: 'backEdit' }, onClick: onClickBackToEdit, icon: <ArrowBackIcon /> },
              { label: { key: 'backList' }, onClick: onClickBackToList, icon: <LowPriorityIcon /> },
            ],
          })
          setClear(false)
        },
        undefined,
        store
      )
      pickupValidation.resetErrMsg()
    } else {
      setErrorVersion(pickupValidation.getVersion())
    }
  }
  function onClickClear() {
    setClear(true)
  }

  useEffect(() => {
    pickupValidation.resetErrMsg()
    if (clear === true) {
      getOne(
        cons.PATH.SHOP.PICKUP.PICKUP_TAG_LIST + '/' + params.id,
        undefined,
        (payload) => {
          setSavedPickup(payload)
          setReady(true)
          setClear(false)
        },
        undefined,
        store
      )
    }
  }, [clear]) // eslint-disable-line react-hooks/exhaustive-deps

  const { getRestaurantIdAndName } = useRestaurant(savedPickup.restUrlId)
  const mapCode = getRestaurantIdAndName()

  // useEffect(() => {
  //   console.log('savedPickup', savedPickup)
  // }, [savedPickup])

  return (
    ready && (
      <>
        <EditCreateDisplay
          code={savedPickup && savedPickup.id ? savedPickup.id : ''}
          resource={resource}
          tabs={tabs}
          content={content}
          onChangeInput={onChangeInput}
          bottomBtns={bottomBtns}
          mapName={{ resource: 'Pickup', key: 'restUrlId' }}
          mapCode={mapCode}
          display="edit"
        />
        {dialog && dialog.open && <GlobalDialog dialogInfo={dialog} />}
      </>
    )
  )
}
