import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useStore } from 'react-redux'
import { Add, ArrowBack, LowPriority } from '@mui/icons-material'
import RestaurantCard from './RestaurantCard'
import { Box } from '@mui/material'
import { TempBtn } from '../../../Component/Input/TempBtn'
import useLandmark from '../../../hooks/Landmark/useLandmark'
import { constants as cons } from '../../../Constants/constants'
import { post } from '../../../helper/baseFetch'
import { EditCreateDisplay } from '../../../Component/EditCreateDisplay/EditCreateDisplay'
import { getDefaultBottomBtnList } from '../../../Component/EditCreateDisplay/EditUtil'
import { GlobalDialog } from '../../../Component/GlobalDialog'
import RestaurantListDrawer from './RestaurantListDrawer'

export default function RestaurantMapCreate() {
  const resource = 'RestaurantMap'

  const { getLandmarkIdAndName, getLandmark } = useLandmark()
  const landmarkIdAndName = getLandmarkIdAndName()
  const { id: landmarkId } = getLandmark()

  const history = useHistory()
  const store = useStore()

  const [idList, setIdList] = useState([])
  const [objList, setObjList] = useState([])
  const [showRestaurantMapList, setShowRestaurantMapList] = useState(true)

  const [dialogInfo, setDialogInfo] = useState({
    open: false,
  })

  let apiPath = cons.PATH.SHOP.RESTAURANT_LANDMARK_RESTAURANT_MAP_LIST,
    listPath = '/' + cons.PATH.SHOP.RESTAURANT_LANDMARK + '/' + landmarkId + '/' + cons.LANDMARK_TAB.RESTAURANT_LIST

  const onCloseDialog = () => {
    setDialogInfo({
      open: false,
    })
  }

  const onDeleteRestaurantCard = (restUrlId) => {
    const newObjList = objList.filter((item) => item.restUrlId !== restUrlId)
    const newIdList = idList.filter((item) => item !== restUrlId)
    setObjList(newObjList)
    setIdList(newIdList)
  }

  const redirectToCreateEdit = () => {
    onCloseDialog()
    onClickClear()
  }

  const redirectToList = () => {
    setDialogInfo({
      open: false,
    })
    history.push(listPath)
  }

  const onClickSave = () => {
    post(
      apiPath,
      { landmarkId: landmarkId, restaurantMapList: idList.map((item) => ({ restUrlId: item })) },
      () => {
        setDialogInfo({
          open: true,
          onClose: redirectToCreateEdit,
          dialogContentText: { key: 'editSuccess' },
          dialogActions: [
            { label: { key: 'backEdit' }, onClick: redirectToCreateEdit, icon: <ArrowBack /> },
            { label: { key: 'backList' }, onClick: redirectToList, icon: <LowPriority /> },
          ],
        })
      },
      undefined,
      store
    )
  }

  const onClickClear = () => {
    setIdList([])
    setObjList([])
  }

  const content = [
    {
      tab: (
        <>
          <Box>
            <TempBtn
              btnSetting={{
                onClick: () => setShowRestaurantMapList(true),
                variant: cons.STYLE.VARIANT.CONTAINED,
                color: cons.STYLE.PRIMARY,
                label: { resource: resource, key: 'addRestaurantMapList' },
                icon: <Add />,
              }}
            />
            {objList && objList.length > 0 && (
              <RestaurantCard restaurantList={objList} onDeleteRestaurantCard={onDeleteRestaurantCard} contentType="create" />
            )}
          </Box>
          <RestaurantListDrawer
            idList={idList}
            setIdList={setIdList}
            objList={objList}
            setObjList={setObjList}
            showRestaurantMapList={showRestaurantMapList}
            setShowRestaurantMapList={setShowRestaurantMapList}
          />
        </>
      ),
      btn: true,
    },
  ]

  // useEffect(() => {
  //   console.log('savedData', savedData)
  // }, [savedData])

  return (
    <>
      <EditCreateDisplay
        resource={resource}
        content={content}
        mapName={{ resource: resource, key: 'landmarkId' }}
        mapCode={landmarkIdAndName}
        display="create"
        bottomBtns={getDefaultBottomBtnList(onClickSave, onClickClear)}
      />
      {dialogInfo.open && <GlobalDialog dialogInfo={dialogInfo} />}
    </>
  )
}
