import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector, useStore } from "react-redux";

import SaveIcon from '@mui/icons-material/Save';
import ClearIcon from '@mui/icons-material/Clear';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { makeStyles } from '@mui/styles';

import { constants } from '../../Constants/constants';
import { getList, getOne, put } from "../../helper/baseFetch";
import { setMerchantInfo, setMerchantInfoValue } from "../../actions/merchantInfoAction";
import { EditCreateDisplay } from '../../Component/EditCreateDisplay/EditCreateDisplay';
import { TempTextInput } from "../../Component/Input/TempTextInput";
import { TempAutoSelectInput } from "../../Component/Input/TempAutoSelectInput";
import { getString } from "../../helper/util";
import { GlobalDialog } from "../../Component/GlobalDialog";
import MerchantInfoValidation from "../../validations/merchantInfoValidation";
import { merchantInfoValidationConstants } from '../../Constants/validationConstants';

const helperTextStyles = makeStyles(theme => ({
  root: {
    color: "red !important",
  },
}));

export const MerchantInfoEdit = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const store = useStore();
  const [ready, setReady] = useState(false);
  const [clear, setClear] = useState(true);
  const [dialog, setDialog] = useState({
    open: false,
    dialogActions: []
  });
  const [bankList, setBankList] = useState([]);
  const resource = "restaurantApprove";
  const savedMerchantInfo = useSelector(state => state.merchantInfo.ui.merchantInfo);
  const lang = useSelector(state => state.lang.ui.lang);
  const theme = useSelector(state => state.theme.ui.theme);
  const helperTextStylesClasses = helperTextStyles();
  const [, setErrorVersion] = useState(MerchantInfoValidation.getVersion());

  const tempMerchantInfoEditList = [
    {
      type: constants.TYPE.TITLE,
      value: { resource: resource, key: "merchantInfo" },
      className: "sub-title",
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "contactPerson" },
      value: savedMerchantInfo.contactPerson,
      name: "contactPerson",
      isEndOfLine: false,
      required: true,
      helperTextIcon: true,
      helperText: MerchantInfoValidation.getErrorMessages(merchantInfoValidationConstants.KEY_CONTACT_PERSON),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "contact" },
      value: savedMerchantInfo.contact,
      name: "contact",
      isEndOfLine: false,
      required: true,
      helperTextIcon: true,
      helperText: MerchantInfoValidation.getErrorMessages(merchantInfoValidationConstants.KEY_CONTACT),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "jobTitle" },
      value: savedMerchantInfo.jobTitle,
      name: "jobTitle",
      isEndOfLine: true,
      required: true,
      helperTextIcon: true,
      helperText: MerchantInfoValidation.getErrorMessages(merchantInfoValidationConstants.KEY_JOB_TITLE),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      type: constants.TYPE.TITLE,
      value: { resource: resource, key: "bank" },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "bankAccount" },
      value: savedMerchantInfo.bankAccount,
      name: "bankAccount",
      isEndOfLine: false,
      required: true,
      helperTextIcon: true,
      helperText: MerchantInfoValidation.getErrorMessages(merchantInfoValidationConstants.KEY_BANK_ACCOUNT),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "bankAccountName" },
      value: savedMerchantInfo.bankAccountName,
      name: "bankAccountName",
      isEndOfLine: true,
      required: true,
      helperTextIcon: true,
      helperText: MerchantInfoValidation.getErrorMessages(merchantInfoValidationConstants.KEY_BANK_ACCOUNT_NAME),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "bankCode" },
      value: savedMerchantInfo.bankCode,
      type: constants.TYPE.SELECT,
      selectValue: bankList,
      name: "bankCode",
      isEndOfLine: false,
      required: true,
      helperTextIcon: true,
      helperText: MerchantInfoValidation.getErrorMessages(merchantInfoValidationConstants.KEY_BANK_CODE),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "bankContactPerson" },
      value: savedMerchantInfo.bankContactPerson,
      name: "bankContactPerson",
      isEndOfLine: false,
      required: true,
      helperTextIcon: true,
      helperText: MerchantInfoValidation.getErrorMessages(merchantInfoValidationConstants.KEY_BANK_CONTACT_PERSON),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
    {
      className: "edit-create-input",
      size: constants.SIZE.SMALL,
      label: { resource: resource, key: "bankContact" },
      value: savedMerchantInfo.bankContact,
      name: "bankContact",
      isEndOfLine: false,
      required: true,
      helperTextIcon: true,
      helperText: MerchantInfoValidation.getErrorMessages(merchantInfoValidationConstants.KEY_BANK_CONTACT),
      FormHelperTextProps: { className: helperTextStylesClasses.root },
    },
  ]
  const content = [
    {
      tab: tempMerchantInfoEditList.map((inputSetting, index) => {
        let content;
        switch (inputSetting.type) {
          case constants.TYPE.SELECT:
            content =
              <TempAutoSelectInput inputSetting={inputSetting} handleChange={onChangeInput} />
            break;
          case constants.TYPE.TITLE:
            content = <div className={`${inputSetting.className + " sub-title"}`}>{getString(lang, inputSetting.value.resource, inputSetting.value.key, theme)}</div>;
            break;
          default:
            content =
              <TempTextInput inputSetting={inputSetting} handleChange={onChangeInput} />
        }
        return <div className="edit-display-wrapper" key={index}>
          {content}
          {inputSetting.isEndOfLine && <div></div>}
        </div>
      }),
      btn: true
    },
  ]

  const bottomBtns = [
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: "save" },
      onClick: onClickSave,
      className: "margin-left-16",
      icon: <SaveIcon />
    },
    {
      variant: constants.STYLE.VARIANT.CONTAINED,
      color: constants.STYLE.PRIMARY,
      label: { key: "clear" },
      disabled: false,
      onClick: onClickClear,
      className: "margin-left-16",
      icon: <ClearIcon />
    }
  ]
  const onClickBackToEdit = () => {
    setDialog({
      open: false,
      dialogActions: []
    });
    onClickClear();
  };

  function onChangeInput(name, e) {
    if (name === "bankCode") {
      let bankName = "";
      bankList.map((bank) => {
        if (bank.value === e) {
          bankName = bank.bankName;
        }
        return bank
      })
      dispatch(setMerchantInfoValue("CHANGE_BANK", savedMerchantInfo, "", {
        bankCode: e,
        bankName: bankName
      }));
    } else {
      dispatch(setMerchantInfoValue(name, savedMerchantInfo, "", e));
    }
  }

  function onClickSave() {
    MerchantInfoValidation.validateEditMerchantInfo(savedMerchantInfo);
    if (MerchantInfoValidation.isValid()) {
      put(constants.PATH.APPROVAL.MERCHANT_INFO + "/" + params.id, savedMerchantInfo, (payload) => {
        setDialog({
          open: true,
          onClose: onClickBackToEdit,
          dialogContentText: { key: "editSuccess" },
          dialogActions: [
            { label: { key: "backEdit" }, onClick: onClickBackToEdit, icon: <ArrowBackIcon /> },
          ]
        })
        setClear(false)
      }, undefined, store);
      MerchantInfoValidation.resetErrorMessages();
    } else {
      setErrorVersion(MerchantInfoValidation.getVersion());
    }
  }

  function onClickClear() {
    setClear(true)
  }

  useEffect(() => {
    getList(constants.PATH.MERCHANT.PAYMENT_RECORD_OPTIONS, undefined, (payload) => {
      let newBankList = [];
      for (let i = 0; i < payload.bankList.length; i++) {
        newBankList.push({ value: payload.bankList[i].bankCode, name: "(" + payload.bankList[i].bankCode + ") " + payload.bankList[i].bankName, bankName: payload.bankList[i].bankName })
      }
      setBankList(newBankList)
    }, undefined, store);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (clear === true) {
      getOne(constants.PATH.APPROVAL.MERCHANT_INFO + "/" + params.id, undefined, (payload) => {
        dispatch(setMerchantInfo(payload));
        setReady(true)
        setClear(false)
      }, undefined, store);
    }
    MerchantInfoValidation.resetErrorMessages();
  }, [clear]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    ready &&
    <>
      <EditCreateDisplay
        code={savedMerchantInfo && savedMerchantInfo.merchantInfoId ? savedMerchantInfo.merchantInfoId : ""}
        resource={resource}
        tabs={""}
        content={content}
        onChangeInput={onChangeInput}
        bottomBtns={bottomBtns}
        display="none"
      />
      {dialog && dialog.open &&
        <GlobalDialog dialogInfo={dialog} />
      }
    </>
  )
};