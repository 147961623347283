import { useState, useEffect } from 'react'
import { useSelector, useStore } from 'react-redux'
import _ from 'lodash'
import MP4Box from 'mp4box'

import Resizer from 'react-image-file-resizer'

import CloseIcon from '@mui/icons-material/Close'
import DoneIcon from '@mui/icons-material/Done'
import { Card } from '@mui/material'
import { Image as ImageIcon } from '@mui/icons-material'
import AspectRatioIcon from '@mui/icons-material/AspectRatio'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import DeleteIcon from '@mui/icons-material/Delete'
import DoneAllIcon from '@mui/icons-material/DoneAll'

import { constants, IMAGE_TYPE, PICTURE_SIZE, UPLOAD_TYPE } from '../../Constants/constants'
import { getMetaAsync } from '../ProductCreate/ImageInfo'
import { getString } from '../../helper/util'
import { post, postFormData } from '../../helper/baseFetch'
import { GlobalDialog } from '../GlobalDialog'
import { DropzoneAreaBase } from 'mui-file-dropzone'

export const UploadImage = ({
  picture,
  type,
  mediaType,
  fileType,
  title,
  required = false,
  uploadImagePath,
  saveImage,
  name,
  index,
  withoutDisplay,
  openUploadImage,
  filesLimit,
  close,
  disableInput,
  size,
  allowedFileExtensionList = [],
  helperText,
  helperDialog,
  removeImage,
  showApplyToAll,
  uploadType,
}) => {
  const [open, setOpen] = useState(false)
  const [openHelperDialog, setOpenHelperDialog] = useState(false)
  const [fileList, setFileList] = useState([])
  const store = useStore()
  const [imageSize, setImageSize] = useState(null)
  const [dialog, setDialog] = useState({
    open: false,
    dialogActions: [],
  })
  const lang = useSelector((state) => state.lang.ui.lang)
  const theme = useSelector((state) => state.theme.ui.theme)

  const getImageSize = (uri) => {
    let newImageSize = []
    getMetaAsync(uri, fileType).then((imageSize) => {
      newImageSize = { width: imageSize.width, height: imageSize.height }
      setImageSize(newImageSize)
    })
    return newImageSize
  }

  function onClickImageNewTab(uri) {
    let image = new Image()
    image.src = uri
    let w = window.open('')
    w.document.write(
      '<head><title>' +
        getString(lang, title.resource ? title.resource : '', title.key ? title.key : '', theme) +
        '</title></head><html> <body> ' +
        image.outerHTML +
        '</body>  </html>'
    )
    w.document.close()
  }

  function onClickVideoNewTab(uri) {
    const video = document.createElement('video')
    video.src = uri
    let w = window.open('')
    w.document.write(
      '<head><title>' +
        getString(lang, title.resource ? title.resource : '', title.key ? title.key : '', theme) +
        '</title></head><html> <body> ' +
        `
      <video controls>
        <source src=${picture.cosFileUrl} type="video/mp4" />
      </video>
      ` +
        '</body>  </html>'
    )
    w.document.close()
  }

  useEffect(() => {
    if (!withoutDisplay && picture && picture.cosFileUrl) {
      getImageSize(picture.cosFileUrl)
    }
  }, [picture && picture.cosFileUrl]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (picture) {
      switch (type) {
        case IMAGE_TYPE.RESTAURANT_IMAGE:
        case IMAGE_TYPE.PRODUCT:
        case IMAGE_TYPE.CATEGORY:
        case IMAGE_TYPE.CATEGORY_CREATE:
        case IMAGE_TYPE.BRAND:
        case IMAGE_TYPE.MERCHANT_SHOP:
        case IMAGE_TYPE.CONTACT_US:
        case IMAGE_TYPE.BRAND_APPROVE:
        case IMAGE_TYPE.RESTAURANT_APPROVE_LIST:
        case IMAGE_TYPE.PAYMENT:
        case IMAGE_TYPE.LANDING_SECTION:
        case IMAGE_TYPE.SLIDESHOW:
        case IMAGE_TYPE.MEDIA:
          break
        default:
          if (typeof saveImage === 'function') {
            saveImage(name, Object.assign(_.cloneDeep(picture), _.cloneDeep(imageSize)))
          }
      }
    }
  }, [imageSize]) // eslint-disable-line react-hooks/exhaustive-deps

  const onCloseDialog = () => {
    setOpen(false)
  }

  const processImages = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })
  }

  const convertResizeImagesToFile = (file) => {
    return new Promise((resolve, reject) => {
      Resizer.imageFileResizer(
        file,
        500,
        500,
        'PNG',
        100,
        0,
        (uri) => {
          resolve(uri)
        },
        'blob'
      )
    })
  }

  const onClickConfirm = async (fileList, applyToAll) => {
    const tempFileList = fileList.map((fileSource) => fileSource.file)
    // console.log('confirm', tempFileList)
    const uri = await processImages(tempFileList[0])
    // console.log('confirm', uri)
    const formData = new FormData()

    let files = []
    switch (uploadType) {
      case UPLOAD_TYPE.FORMDATA:
        switch (type) {
          case IMAGE_TYPE.RESTAURANT_IMAGE:
            const resizeImageUri = await convertResizeImagesToFile(tempFileList[0])
            formData.append('mediaFiles', tempFileList[0])
            formData.append('mediaFiles', resizeImageUri)

            postFormData(
              uploadImagePath,
              formData,
              (payload) => {
                saveImage({
                  picture: {
                    cosFileKey: payload[0].cosFileKey,
                    cosFileUrl: payload[0].cosFileUrl,
                  },
                  thumbnail: {
                    cosFileKey: payload[1].cosFileKey,
                    cosFileUrl: payload[1].cosFileUrl,
                  },
                })
                setOpen(false)
              },
              undefined,
              store
            )
            break
          case IMAGE_TYPE.SLIDESHOW:
            formData.append('mediaFiles', tempFileList[0])
            postFormData(
              uploadImagePath,
              formData,
              (payload) => {
                getMetaAsync(uri, tempFileList[0].type).then((imageSize) => {
                  if (tempFileList[0].type !== 'video/mp4') {
                    saveImage(
                      name,
                      {
                        cosFileKey: payload[0].cosFileKey,
                        cosFileUrl: payload[0].cosFileUrl,
                        width: imageSize.width,
                        height: imageSize.height,
                        type: tempFileList[0].type,
                      },
                      index
                    )
                    setOpen(false)
                    return
                  }

                  getMimeInfo(uri).then(({ mime }) => {
                    saveImage(
                      name,
                      {
                        cosFileKey: payload[0].cosFileKey,
                        cosFileUrl: payload[0].cosFileUrl,
                        width: imageSize.width,
                        height: imageSize.height,
                        duration: imageSize.duration,
                        type: tempFileList[0].type,
                        mime: mime,
                      },
                      index
                    )

                    setOpen(false)
                  })
                })
              },
              undefined,
              store
            )
            break
          default:
            formData.append('mediaFiles', tempFileList[0])
            formData.append('mediaType', mediaType)

            postFormData(
              uploadImagePath,
              formData,
              (payload) => {
                getMetaAsync(uri).then((imageSize) => {
                  saveImage(
                    name,
                    {
                      cosFileKey: payload[0].cosFileKey,
                      cosFileUrl: payload[0].cosFileUrl,
                      width: imageSize.width,
                      height: imageSize.height,
                    },
                    index,
                    applyToAll
                  )
                  // console.log(imageSize)
                  // console.log('getmeta', payload, payload[0].cosFileKey)
                })
                setOpen(false)
              },
              undefined,
              store
            )
        }
        break
      default:
        switch (type) {
          case IMAGE_TYPE.PRODUCT:
          case IMAGE_TYPE.CATEGORY:
          case IMAGE_TYPE.CATEGORY_CREATE:
          case IMAGE_TYPE.BRAND:
            tempFileList.map(async (fileValue) => {
              let productUrl = await processImages(fileValue)
              post(
                uploadImagePath,
                { imageFiles: [productUrl] },
                (payload) => {
                  getMetaAsync(productUrl).then((imageSize) => {
                    files.push({
                      name: name,
                      cosFileKey: payload[0].cosFileKey,
                      cosFileUrl: payload[0].cosFileUrl,
                      width: imageSize.width,
                      height: imageSize.height,
                      index: index,
                    })
                    saveImage(files)
                  })
                  setOpen(false)
                },
                undefined,
                store
              )
            })
            break
          case IMAGE_TYPE.MERCHANT_SHOP:
            tempFileList.map(async (fileValue) => {
              let productUrl = await processImages(fileValue)
              post(
                uploadImagePath,
                { imageFiles: [productUrl], mediaType: mediaType },
                (payload) => {
                  getMetaAsync(productUrl).then((imageSize) => {
                    files.push({
                      name: name,
                      cosFileKey: payload[0].cosFileKey,
                      cosFileUrl: payload[0].cosFileUrl,
                      width: imageSize.width,
                      height: imageSize.height,
                      index: index,
                    })
                    saveImage(files)
                  })
                  setOpen(false)
                },
                undefined,
                store
              )
            })
            break
          default:
            let param = { imageFiles: [uri] }
            if (mediaType) {
              Object.assign(param, { mediaType: mediaType })
            }
            post(
              uploadImagePath,
              param,
              (payload) => {
                getMetaAsync(uri).then((imageSize) => {
                  saveImage(
                    name,
                    {
                      cosFileKey: payload[0].cosFileKey,
                      cosFileUrl: payload[0].cosFileUrl,
                      width: imageSize.width,
                      height: imageSize.height,
                    },
                    index,
                    applyToAll
                  )
                  // console.log(imageSize)
                  // console.log('getmeta', payload, payload[0].cosFileKey)
                })
                setOpen(false)
              },
              undefined,
              store
            )
        }
    }
  }

  function onOpenUploadFileDialog() {
    setFileList([])
    setOpen(true)
  }

  function onClickUploadFile(fileList) {
    setFileList(fileList)
  }

  const getMimeInfo = (base64) => {
    return new Promise((resolve, reject) => {
      const ab = convertToArrayBuffer(base64)
      ab.fileStart = 0
      const mp4boxfile = MP4Box.createFile()
      mp4boxfile.onReady = function (info) {
        // console.log("Received File Information", info);
        // console.log(info.mime)
        resolve({ mime: info.mime })
      }
      mp4boxfile.appendBuffer(ab)
    })
  }

  const convertToArrayBuffer = (base64) => {
    const base64String = base64.substring(base64.indexOf(',') + 1)
    var binary_string = window.atob(base64String)
    var len = binary_string.length
    var bytes = new Uint8Array(len)
    for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i)
    }
    return bytes.buffer
  }

  useEffect(() => {
    setOpen(openUploadImage)
  }, [openUploadImage]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const dialogActions = [
      {
        label: { key: 'confirm' },
        onClick: () => onClickConfirm(fileList),
        icon: <DoneIcon />,
        disabled: fileList && fileList.length === 0,
      },
      { label: { key: 'close' }, onClick: close ? close : () => onCloseDialog(), icon: <CloseIcon /> },
    ]

    if (showApplyToAll) {
      dialogActions.unshift({
        label: { key: 'applyToAll' },
        onClick: () => onClickConfirm(fileList, true),
        icon: <DoneAllIcon />,
        disabled: fileList && fileList.length === 0,
      })
    }

    setDialog({
      open: open,
      onClose: close ? close : () => onCloseDialog(),
      dialogTitle: { key: 'dialogTitle' },
      dialogContent: (
        <DropzoneAreaBase
          fileObjects={fileList}
          showPreviews={true}
          showPreviewsInDropzone={false}
          previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
          previewText="Selected files"
          filesLimit={filesLimit ? filesLimit : 1}
          maxFileSize={type === IMAGE_TYPE.SLIDESHOW ? 1e9 : 409600}
          onAdd={(file) => {
            onClickUploadFile(file)
          }}
          acceptedFiles={allowedFileExtensionList}
          onDelete={() => setFileList([])}
        />
      ),
      dialogActions: dialogActions,
    })
  }, [open, fileList]) // eslint-disable-line react-hooks/exhaustive-deps

  // useEffect(() => {
  //   console.log('fileList', fileList)
  // }, [fileList])

  const isClickable = () => {
    if (typeof saveImage === 'function') {
      return 'cursor'
    }

    return ''
  }

  const getImageSizeClassName = () => {
    if (size) {
      switch (size) {
        case PICTURE_SIZE.MID:
          return PICTURE_SIZE.MID
        default:
          return 'pictures'
      }
    } else {
      switch (type) {
        case IMAGE_TYPE.RESTAURANT_IMAGE:
        case IMAGE_TYPE.PRODUCT:
        case IMAGE_TYPE.CATEGORY:
        case IMAGE_TYPE.CATEGORY_CREATE:
        case IMAGE_TYPE.BRAND:
        case IMAGE_TYPE.MERCHANT_SHOP:
          return 'picture'
        default:
          return 'pictures'
      }
    }
  }

  const onOpenHelperDialog = () => {
    setOpenHelperDialog(true)
  }

  const onCloseHelperDialog = () => {
    setOpenHelperDialog(false)
  }

  useEffect(() => {
    setDialog({
      open: openHelperDialog,
      onClose: () => onCloseHelperDialog(),
      dialogTitle: { key: 'dialogTitle' },
      dialogContent: helperDialog,
      dialogActions: [],
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openHelperDialog])

  return (
    <>
      {!withoutDisplay &&
        (picture ? (
          <Card className={getImageSizeClassName()}>
            <div className={`sub-title ${helperDialog || removeImage ? 'upload-image-toolbar' : ''}`}>
              {helperDialog && (
                <span className="guide">
                  <InfoOutlinedIcon style={{ color: 'rebeccapurple' }} onClick={onOpenHelperDialog} />
                </span>
              )}
              <span className="title">
                {getString(lang, title.resource ? title.resource : '', title.key ? title.key : '', theme)}
                {required ? '*' : ''}
              </span>
              {removeImage && (
                <span className="delete">
                  <DeleteIcon color={constants.STYLE.PRIMARY} onClick={removeImage} />
                </span>
              )}
            </div>
            <div className={`${isClickable()} upload-image`} onClick={disableInput ? () => {} : () => onOpenUploadFileDialog()}>
              {fileType === 'VIDEO' ? (
                <div className="upload-image-video">
                  <video src={picture.cosFileUrl} type="video/mp4" controls width="100%" />
                  <div className="upload-image-video-cover"></div>
                </div>
              ) : (
                <img className="card-img-top" width="100%" src={picture.cosFileUrl} title="Image" alt="img" />
              )}
            </div>
            <div className="imageInfo">
              <div>
                <div className="image-enlarge-icon cursor">
                  <AspectRatioIcon
                    color={constants.STYLE.PRIMARY}
                    fontSize={constants.SIZE.MEDIUM}
                    onClick={() => {
                      fileType === 'VIDEO' ? onClickVideoNewTab(picture.cosFileUrl) : onClickImageNewTab(picture.cosFileUrl)
                    }}
                  />
                </div>
              </div>
              Width: {imageSize && imageSize.width} Height: {imageSize && imageSize.height}
              <div className="helperText">{helperText ? helperText : null}</div>
            </div>
          </Card>
        ) : (
          <Card className={getImageSizeClassName()}>
            <div className={`sub-title ${helperDialog || removeImage ? 'upload-image-toolbar' : ''}`}>
              {helperDialog && (
                <span className="guide">
                  <InfoOutlinedIcon style={{ color: 'rebeccapurple' }} onClick={onOpenHelperDialog} />
                </span>
              )}
              <span className="title">
                {getString(lang, title.resource ? title.resource : '', title.key ? title.key : '', theme)}
                {required ? '*' : ''}
              </span>
            </div>
            <div onClick={disableInput ? () => {} : () => onOpenUploadFileDialog()}>
              <ImageIcon className="imageIcon" />
            </div>
            <div className="helperText">{helperText ? helperText : null}</div>
          </Card>
        ))}
      {dialog && dialog.open && <GlobalDialog dialogInfo={dialog} />}
    </>
  )
}
